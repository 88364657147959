import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Card, CircularProgress, Dialog, DialogContent, Table, TableBody, TableHead, TableCell, TableRow } from '@material-ui/core'
import { updateTitle } from '../actions/application'
import { push } from 'react-router-redux'
import { get, post } from '../actions/base'
import DataList from '../components/Layout/DataList'
import moment from 'moment'
import Invoice from '../components/Invoice'
import numeral from 'numeral'

class Invoices extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      invoices: [],
      invoiceToShow: null,
      invoiceLineItems: {},
      invoiceLineItemsLoaded: false,
      account: [],
      accountLoaded: false
    }
  }

  componentDidMount () {
    this.updateTitle()

    this.fetchAccountInformation()
  }

  componentDidUpdate (prevProps, prevState) {
    const { invoiceToShow, invoices } = this.state
    this.updateTitle()

    if (prevState.invoiceToShow === null && this.state.invoiceToShow !== null) { this.fetchInvoice(invoices[invoiceToShow].Invoice_Number) }
  }

  updateTitle () {
    const { language } = this.context

    this.props.dispatch(updateTitle(language.translate('application.invoice', [], true)))
  }

  dataLoadCallback (data) {
    this.setState({
      invoices: data
    })
  }

  fetchAccountInformation () {
    this.props.get('account', {
      onSuccess: (response) => {
        this.setState({
          account: response.Account,
          accountLoaded: true
        })
      }
    })
  }

  fetchInvoice (id) {
    this.props.get(`account/invoice/${id}/lineitem/list`, {
      onSuccess: (response) => {
        this.setState({
          invoiceLineItemsLoaded: true,
          invoiceLineItems: response.InvoiceLineItemList
        })
      }
    })
  }

  render () {
    const { language } = this.context
    const { mainContentWidth, user, location } = this.props
    const { invoices, invoiceToShow, invoiceLineItems, invoiceLineItemsLoaded, account, accountLoaded } = this.state

    return (
      <div>
        <DataList
          url='account/invoice/list'
          dataCallback={this.dataLoadCallback.bind(this)}
          fetchAll
          responseProperty='InvoiceList'
          localSearch
          filterableColumns={[{
            name: language.translate('application.invoiceNumber'),
            property: 'Invoice_Number'
          }]}
          noDataText={language.translate('application.noInvoices')}
          location={location}
        >
          <Card
            style={{ padding: '0px', marginBottom: '10px', width: '100%' }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{
                    fontSize: '16px',
                    fontWeight: 600
                  }}
                  >{language.translate('application.invoiceDate')}</TableCell>
                  {(mainContentWidth > 600)
                    ? <TableCell style={{
                      textAlign: 'center',
                      fontSize: '16px',
                      fontWeight: 600
                    }}
                    >{language.translate('application.description')}</TableCell>
                    : null}
                  <TableCell style={{
                    textAlign: 'center',
                    fontSize: '16px',
                    fontWeight: 600
                  }}
                  >{language.translate('application.invoiceNumber')}</TableCell>
                  <TableCell style={{
                    textAlign: 'right',
                    fontSize: '16px',
                    fontWeight: 600
                  }}
                  >{language.translate('application.total')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoices.map((invoice, index) => (
                  <TableRow
                    hover
                    key={index}
                    className='clickable'
                    onClick={() => {
                      this.setState({ invoiceToShow: index })
                    }}
                  >
                    <TableCell>{moment(invoice.Invoice_Date).format(user.dateFormat)}</TableCell>
                    {(mainContentWidth > 600)
                      ? <TableCell
                        style={{ textAlign: 'center' }}
                      >{invoice.Invoice_Description}</TableCell>
                      : null}
                    <TableCell
                      style={{ textAlign: 'center' }}
                    >{invoice.Invoice_Number}</TableCell>
                    <TableCell
                      style={{ textAlign: 'right' }}
                    >{numeral(invoice.Invoice_Amount / 100).format('$0,0.00')}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
        </DataList>
        {invoiceToShow !== null &&
          <Dialog
            open
            onClose={() => this.setState({ invoiceToShow: null, invoice: {}, invoiceLoaded: false })}
          >
            <DialogContent style={{ width: '600px' }}>
              {
                (!invoiceLineItemsLoaded || !accountLoaded)
                  ? <CircularProgress className='loader' />
                  : <Invoice
                    invoice={invoices[invoiceToShow]}
                    account={account}
                    lineItems={invoiceLineItems}
                  />
              }
            </DialogContent>
          </Dialog>
        }
      </div>
    )
  }
}

Invoices.propTypes = {
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.string,
  get: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  mainContentWidth: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired,
  location: PropTypes.object
}

Invoices.contextTypes = {
  muiTheme: PropTypes.object,
  language: PropTypes.object
}

const mapStateToProps = (state, ownProps) => ({
  title: state.application.title,
  mainContentWidth: state.application.mainContentWidth,
  user: state.auth,
  location: ownProps.location
})

const mapDispatchToProps = dispatch => ({
  get: bindActionCreators(get, dispatch),
  post: bindActionCreators(post, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Invoices)
