import React from 'react'
import PropTypes from 'prop-types'
import { Button, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  label: {
    display: 'initial'
  }
}

class ProcessPeople extends React.Component {
  render () {
    const { language, muiTheme: { palette } } = this.context
    const { classes } = this.props

    return (
      <Button
        style={{
          margin: 'auto',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
        classes={{ label: classes.label }}
        onClick={() => {
          window.open(`https://www.processpeople.com`, '_blank')
        }}
        size='large'
      >
        <Typography variant='h5' style={{
          color: '#163F5B',
          fontWeight: 900
        }}>{language.translate('application.processPeople')}</Typography>
        <Typography variant='h5' style={{
          color: palette.primary1Color,
          fontWeight: 900
        }}>{language.translate('application.onDemandOutsourcing')}</Typography>
        <img width={271} src='../images/processpeople.png' />
        <Typography variant='h5' style={{
          color: '#163F5B',
          fontWeight: 900
        }}>{language.translate('application.clickHereToLearnMore')}</Typography>
      </Button>
    )
  }
}

ProcessPeople.propTypes = {
  classes: PropTypes.object
}

ProcessPeople.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default withStyles(styles)(ProcessPeople)
