import PropTypes from 'prop-types'
import React from 'react'
import { FormsyText } from 'formsy-material-ui'
import { IconButton, Divider, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Menu, MenuItem } from '@material-ui/core'
import FormHelper from '~/businessLogic/formHelper'
import { Form } from 'formsy-react'
import { Check, MoreVert } from '@material-ui/icons'
import ColorPicker from '../Forms/ColorPicker'
import DeleteMenuItem from '../Layout/DeleteMenuItem'
import DeleteIconButton from '../Layout/DeleteIconButton'

class TagListItem extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      editMode: false,
      canSubmit: true,
      value: null,
      item: null,
      menuAnchor: null
    }
  }

  componentDidMount () {
    if (this.props.item.TagName === '') { this.setState({ editMode: true }) }

    this.setState({ item: this.props.item })
  }

  componentDidUpdate (prevProps, prevState) {
    if (JSON.stringify(this.props.item) !== JSON.stringify(prevProps.item)) { this.setState({ item: this.props.item }) }
  }

  enableButton () {
    this.setState({ canSubmit: true })
  }

  disableButton () {
    this.setState({ canSubmit: false })
  }

  render () {
    const { language, muiTheme } = this.context
    const { onSave } = this.props
    const { item, menuAnchor } = this.state

    if (!item) { return null }

    const noneSVG = `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 10 10'> <path d='M-1 0 L9 10 L11 10 L1 0' fill='red' /></svg>")`

    return (
      (!this.state.editMode)
        ? <ListItem>
          <ListItemIcon>
            <div style={{
              marginRight: '10px',
              height: '15px',
              width: '30px',
              border: `1px solid ${muiTheme.palette.borderColor}`,
              borderRadius: '10px',
              background: item.BackgroundColor || noneSVG
            }} />
          </ListItemIcon>
          <ListItemText primary={FormHelper.decodeHTML(item.TagName)} />
          <ListItemSecondaryAction>
            <IconButton onClick={(e) => { this.setState({ menuAnchor: e.currentTarget }) }}><MoreVert /></IconButton>
            <Menu
              anchorEl={menuAnchor}
              open={Boolean(menuAnchor)}
              onClose={() => { this.setState({ menuAnchor: null }) }}
              getContentAnchorEl={null}
              anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
              <MenuItem
                onClick={() => this.setState({ editMode: true, menuAnchor: null })}
              >{language.translate('application.edit')}</MenuItem>
              <DeleteMenuItem
                onDelete={() => {
                  this.setState({ menuAnchor: null })
                  this.props.deleteTag(item)
                }}
              />
            </Menu>
          </ListItemSecondaryAction>
        </ListItem>
        : <Form onValid={this.enableButton.bind(this)} onInvalid={this.disableButton.bind(this)}>
          <ListItem style={{ padding: '0px 0px 16px 16px' }}>
            <ListItemText primary={
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around'
                }}
                >
                  <FormsyText
                    floatingLabelText={language.translate('application.tagName')}
                    required
                    name={`tag-name-${item.ID}`}
                    defaultValue={FormHelper.decodeHTML(item.TagName)}
                    onChange={(e) => {
                      item.TagName = e.currentTarget.value
                      this.setState({ item })
                    }}
                    style={{ marginRight: '15px' }}
                    validationErrors={language.messages.validationErrors}
                  />
                  <div style={{ width: '180px' }}>
                    <ColorPicker
                      label={language.translate('application.backgroundColor')}
                      color={item.BackgroundColor}
                      onChange={color => {
                        item.BackgroundColor = color
                        this.setState({ item })
                      }}
                      requireHex
                    />
                    <ColorPicker
                      label={language.translate('application.fontColor')}
                      color={item.ForegroundColor}
                      onChange={color => {
                        item.ForegroundColor = color
                        this.setState({ item })
                      }}
                      requireHex
                    />
                  </div>
                  <div style={{
                    backgroundColor: item.BackgroundColor,
                    color: item.ForegroundColor,
                    padding: '20px',
                    borderRadius: '5px'
                  }}>{language.translate('application.sampleText')}</div>
                </div>
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <DeleteIconButton onDelete={() => {
                    this.props.deleteTag(item)
                  }} />
                  <IconButton
                    disabled={!this.state.canSubmit}
                    onClick={() => {
                      onSave(this.state.item)
                      this.setState({ editMode: false })
                    }}
                  >
                    <Check />
                  </IconButton>
                </div>
              </div>} />
          </ListItem>
          <Divider />
        </Form>
    )
  }
}

TagListItem.propTypes = {
  item: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  deleteTag: PropTypes.func.isRequired
}

TagListItem.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object,
  user: PropTypes.object
}

export default TagListItem
