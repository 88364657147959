import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import routes from './routes'
import configureStore from './store/configureStore'
import './styles/styles.scss' // Yep, that's right. You can import SASS/CSS files too! Webpack will run the associated loader and plug this into the page.
import { Router, useRouterHistory } from 'react-router'
import { syncHistoryWithStore } from 'react-router-redux'
import createBrowserHistory from 'history/lib/createBrowserHistory'
import RequireHttps from './components/Authentication/RequireHttps'
import './businessLogic/polyfills'
import 'typeface-source-sans-pro'
import 'typeface-roboto'

const browserHistory = useRouterHistory(createBrowserHistory)({
  basename: ''
})

const initialState = window.__INITIAL_STATE__
const store = configureStore(initialState, browserHistory)

const router = routes(store)

const history = syncHistoryWithStore(browserHistory, store)

const node = (
  <Provider store={store}>
    <RequireHttps>
      <Router history={history}>
        {router}
      </Router>
    </RequireHttps>
  </Provider>
)

render(node, document.getElementById('app'))
