// This file merely configures the store for hot reloading.
// This boilerplate file is likely to be the same for each project that uses Redux.
// With Redux, the actual stores are in /reducers.

import { applyMiddleware, createStore } from 'redux'
import rootReducer from '../reducers'
import thunk from 'redux-thunk'
// import { syncHistory } from 'react-router-redux';
import { routerMiddleware } from 'react-router-redux'

export default function configureStore (initialState, history) {
  const middleware = [thunk]
  // Installs hooks that always keep react-router and redux
  // store in sync
  // const router = syncHistory(history);
  const router = routerMiddleware(history)

  middleware.push(router)

  const finalCreateStore = applyMiddleware(...middleware)(createStore)

  const store = finalCreateStore(rootReducer, initialState)

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextReducer = require('../reducers')
      store.replaceReducer(nextReducer)
    })
  }

  return store
}
/*
 Fix this file, the router is erroring out due to the history stuff changing in react-router-redux v4
 */
