import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get, post } from '../../actions/base'
import { showUndoMessage } from '../../actions/snackbar'
import { Button, Card, Divider, List } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { push } from 'react-router-redux'
import WorkScheduleListItem from './WorkScheduleListItem'
import RightPanelContent from '~/components/Layout/RightPanelContent'

class WorkSchedules extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      schedules: null,
      isLoaded: false
    }
  }

  // MIGRATED TO componentDidMount
  // componentWillMount () {
  //   this.fetchAllData()
  // }

  componentDidMount() {
    if (this.state.isLoaded === false) {
      this.fetchAllData()
    }
  }

  fetchAllData() {
    this.fetchSchedules()
  }

  fetchSchedules() {
    this.props.get('workschedule/list', {
      onSuccess: (response) => {
        this.setState({
          isLoaded: true,
          schedules: response.WorkScheduleList
        })
      }
    })
  }

  addNewItem() {
    const newItem = {
      ScheduleName: '',
      Schedule_Local: []
    }

    const newSchedules = this.state.schedules.concat(newItem)

    this.setState({ schedules: newSchedules })
  }

  deleteSchedule(index, item) {
    const { language } = this.context
    const body = JSON.stringify({})

    if (!item.ID) {
      const { schedules } = this.state
      schedules.splice(index, 1)

      this.setState({ schedules })
    } else {
      this.props.post(`workschedule/${item.ID}/delete`, body, {
        onSuccess: (response) => {
          this.fetchSchedules()

          this.props.showUndoMessage(
            language.translate('application.workScheduleRemoved'),
            () => {
              this.props.post(`workschedule/${item.ID}/delete/undo`, body, {
                onSuccess: (response) => {
                  this.fetchSchedules()
                }
              })
            }
          )
        }
      })
    }
  }

  saveSchedule(index, schedule) {
    const { schedules } = this.state
    const endpoint = (!schedule.ID) ? 'workschedule' : `workschedule/${schedule.ID}`

    const body = JSON.stringify({
      WorkSchedule: schedule
    })

    this.props.post(endpoint, body, {
      onSuccess: (response) => {
        schedules[index] = response.WorkSchedule

        this.setState({ schedules })
      }
    })
  }

  render() {
    const { language } = this.context
    const { isLoaded, schedules } = this.state

    return (
      <RightPanelContent
        closePanel={() => this.props.close()}
        title={language.translate('application.workSchedule', '', [], true)}
      >
        {(isLoaded)
          ? <div style={{ padding: '15px' }}>
            <Card className='card'>
              <List style={{ padding: '0px' }}>
                {schedules.map((schedule, index) => (
                  React.Children.toArray([
                    <WorkScheduleListItem
                      key={schedule.ID || index}
                      item={schedule}
                      deleteSchedule={schedule => this.deleteSchedule(index, schedule)}
                      onSave={schedule => this.saveSchedule(index, schedule)}
                    />,
                    (index < schedules.length - 1) ? <Divider key={index + 1000} /> : null
                  ])
                ))}
              </List>
            </Card>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
              <Button
                onClick={this.addNewItem.bind(this)}
                color='primary'
                variant='contained'
              >
                <Add />
                {language.translate('application.add')}
              </Button>
            </div>
          </div>
          : null}
      </RightPanelContent>
    )
  }
}

WorkSchedules.propTypes = {
  dispatch: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  showUndoMessage: PropTypes.func.isRequired
}

WorkSchedules.contextTypes = {
  location: PropTypes.object,
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  dispatch,
  post: bindActionCreators(post, dispatch),
  get: bindActionCreators(get, dispatch),
  push: bindActionCreators(push, dispatch),
  showUndoMessage: bindActionCreators(showUndoMessage, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(WorkSchedules)
