import PropTypes from 'prop-types'
import React from 'react'
import { FormsySelect, FormsyText } from 'formsy-material-ui'
import { Divider, IconButton, ListItem, ListItemSecondaryAction, ListItemText, Menu, MenuItem } from '@material-ui/core'
import { MenuItem as OldMenuItem } from 'material-ui'
import { Check, MoreVert } from '@material-ui/icons'
import FormHelper from '~/businessLogic/formHelper'
import GroupUserSelect from '../../Forms/GroupUserSelect'
import { Form } from 'formsy-react'
import DeleteIconButton from '../../Layout/DeleteIconButton'
import DeleteMenuItem from '../../Layout/DeleteMenuItem'

class ActionConditionListItem extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      editMode: false,
      canSubmit: true,
      value: null,
      item: null,
      editingItem: null,
      menuAnchor: null,
      conditionTypeSelected: {},
      processFieldSelected: {},
      responseList: [],
      instanceFieldOptions: [],
      instanceFieldOptionsLoaded: false,
      instanceFieldOptionsLoading: false
    }
  }

  componentDidMount () {
    const { item, onRequestTaskResponses } = this.props

    if (this.props.item.new === true) {
      this.setState({ editMode: true, editingItem: this.props.item }, () => this.props.isEditing(true))
    }

    this.setState({ item: this.props.item }, () => this.initState())

    if (item.Condition_ProcessTemplateTaskID) { onRequestTaskResponses(item.Condition_ProcessTemplateTaskID, response => this.setState({ responseList: response })) }
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.item !== prevProps.item) {
      this.setState({
        item: this.props.item
      }, () => this.initState())
    }

    if (prevState.editMode !== this.state.editMode) { this.props.isEditing(this.state.editMode) }

    const { processFieldSelected } = this.state
    if (processFieldSelected &&
      (processFieldSelected.FieldType === 'INSTCHLS' || processFieldSelected.FieldType === 'INSTANCE') &&
      !this.state.instanceFieldOptionsLoading &&
      !this.state.instanceFieldOptionsLoaded
    ) {
      this.setState({ instanceFieldOptionsLoading: true }, () => {
        this.props.getInstanceFieldOptions(processFieldSelected.ProcessTemplateID, processFieldSelected.ID, (optionList) => {
          this.setState({
            instanceFieldOptions: optionList,
            instanceFieldOptionsLoaded: true,
            instanceFieldOptionsLoading: false
          })
        })
      })
    }
  }

  initState () {
    this.initConditionType()
    this.initProcessField()
  }

  initConditionType () {
    const { item } = this.state
    const { conditionTypes } = this.props
    let conditionSelected = {}

    conditionTypes.map((condition) => {
      if (item.IntegrationConditionTypeID === condition.ID) { conditionSelected = condition }
    })

    this.setState({ conditionTypeSelected: conditionSelected })
  }

  initProcessField () {
    const { item } = this.state
    const { processFieldList } = this.props

    processFieldList.map((field) => {
      if (field.ID === item.Condition_ProcessTemplateFieldID) { this.setState({ processFieldSelected: field }) }
    })
  }

  enableButton () {
    this.setState({ canSubmit: true })
  }

  disableButton () {
    this.setState({ canSubmit: false })
  }

  conditionTypeMenu () {
    const { conditionTypes } = this.props
    const { language } = this.context

    const options = [<OldMenuItem value={false} key={0} primaryText={language.translate('application.pleaseSelect')} />]

    conditionTypes.map((option) => {
      options.push(<OldMenuItem value={option.ID} key={option.ID} primaryText={option.Name} />)
    })

    return options
  }

  processFieldMenu () {
    const { processFieldList } = this.props
    const { language } = this.context

    const options = [<OldMenuItem
      value={false}
      key={0}
      primaryText={language.translate('application.selectProcessField')}
    />]

    processFieldList.map((option) => {
      options.push(<OldMenuItem
        value={option.ID}
        key={option.ID}
        primaryText={FormHelper.decodeHTML(option.FieldName)}
      />)
    })

    return options
  }

  processFieldOptionMenu (optionList) {
    const { language } = this.context

    const options = [<OldMenuItem
      value={false}
      key={0}
      primaryText={language.translate('application.selectProcessFieldOption')}
    />]

    if (optionList && optionList.length) {
      optionList.map((option) => {
        options.push(<OldMenuItem
          value={option.ID}
          key={option.ID}
          primaryText={FormHelper.decodeHTML(option.OptionDescription)}
        />)
      })
    }

    return options
  }

  userFieldMenu () {
    const { userFieldList } = this.props
    const { language } = this.context

    const options = [<OldMenuItem value={false} key={0}
                                  primaryText={language.translate('application.selectUserField')} />]

    userFieldList.map((option) => {
      options.push(<OldMenuItem
        value={option.ID}
        key={option.ID}
        primaryText={FormHelper.decodeHTML(option.FieldName)}
      />)
    })

    return options
  }

  templateTaskMenu () {
    const { templateTasks } = this.props
    const { language } = this.context

    const options = [<OldMenuItem value={false} key={0} primaryText={language.translate('application.selectTask')} />]

    templateTasks.map((option) => {
      options.push(<OldMenuItem
        value={option.ID}
        key={option.ID}
        primaryText={FormHelper.decodeHTML(option.TaskText)}
      />)
    })

    return options
  }

  templateTaskResponseMenu () {
    const { responseList } = this.state
    const { language } = this.context

    if (!responseList) { return [] }

    const options = [<OldMenuItem value={false} key={0}
                                  primaryText={language.translate('application.selectResponse')} />]

    responseList.map((option) => {
      options.push(<OldMenuItem
        value={option.ID}
        key={option.ID}
        primaryText={FormHelper.decodeHTML(option.ResponseText)}
      />)
    })

    return options
  }

  handleConditionTypeChange (typeId) {
    const { conditionTypes } = this.props
    let { editingItem, conditionTypeSelected, processFieldSelected } = this.state

    conditionTypes.map((condition) => {
      if (condition.ID === typeId) {
        editingItem.IntegrationConditionTypeID = condition.ID
        editingItem.ConditionType_Identifier = condition.Identifier
        editingItem.ConditionType_Name = condition.Name
        conditionTypeSelected = condition

        if (!condition.ProcessTemplateFieldID_Required) { processFieldSelected = {} }
      }
    })

    this.setState({
      editingItem,
      conditionTypeSelected,
      processFieldSelected
    })
  }

  handleProcessFieldChange (value) {
    const { editingItem } = this.state
    const { processFieldList } = this.props
    let processField = {}

    editingItem.Condition_ProcessTemplateFieldID = value

    processFieldList.map((field) => {
      if (field.ID === value) { processField = field }
    })

    if (editingItem.Condition_ProcessInstanceID) {
      editingItem.Condition_ProcessInstanceID = ''
    }

    this.setState({
      editingItem,
      processFieldSelected: processField,
      instanceFieldOptionsLoading: false,
      instanceFieldOptionsLoaded: false
    })
  }

  shouldShowConditionText () {
    const { conditionTypeSelected, processFieldSelected } = this.state

    if (conditionTypeSelected.Text_Required) { return true }

    return !!(conditionTypeSelected.ProcessTemplateFieldID_Required && !processFieldSelected.FieldOptionList &&
      processFieldSelected.FieldType !== 'N' && processFieldSelected.FieldType !== 'G' &&
      processFieldSelected.FieldType !== 'P' && conditionTypeSelected.Identifier !== 'FLD_EMPTY' &&
      processFieldSelected.FieldType !== 'INSTANCE' && processFieldSelected.FieldType !== 'INSTCHLS' &&
      conditionTypeSelected.Identifier !== 'FLD_NOT_EMPTY' && conditionTypeSelected.Identifier !== 'FLD_USERINGROUP')
  }

  shouldShowConditionNumber () {
    const { conditionTypeSelected, processFieldSelected } = this.state

    if (conditionTypeSelected.Identifier === 'FLD_EMPTY' || conditionTypeSelected.Identifier === 'FLD_NOT_EMPTY') { return false }

    return !!(conditionTypeSelected.Number_Required || processFieldSelected.FieldType === 'N')
  }

  shouldShowGroupUserInput () {
    const { conditionTypeSelected, processFieldSelected } = this.state

    if (conditionTypeSelected.Identifier === 'FLD_EMPTY' || conditionTypeSelected.Identifier === 'FLD_NOT_EMPTY') { return false }

    return !!(conditionTypeSelected.UserGroupID_Required || conditionTypeSelected.UserID_Required || processFieldSelected.FieldType === 'G' || processFieldSelected.FieldType === 'P')
  }

  render () {
    const { language, muiTheme } = this.context
    const { onSave, onRemoveField, dispatch, onRequestTaskResponses, onCopy, processFieldList } = this.props
    const { item, conditionTypeSelected, processFieldSelected, editingItem, instanceFieldOptions, menuAnchor } = this.state

    if (!item) { return null }

    return (
      <div>
        {(!this.state.editMode)
          ? <ListItem>
            <ListItemText primary={item.Description || item.ConditionType_Name} />
            <ListItemSecondaryAction>
              <IconButton onClick={(e) => { this.setState({ menuAnchor: e.currentTarget }) }}><MoreVert /></IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          : <Form onValid={this.enableButton.bind(this)} onInvalid={this.disableButton.bind(this)}>
            <ListItem
              style={{
                backgroundColor: muiTheme.palette.canvasColor,
                border: `1px solid ${muiTheme.palette.borderColor}`
              }}
            >
              <ListItemText primary={
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                  alignItems: 'flex-start'
                }}
                >
                  <FormsyText
                    floatingLabelText={language.translate('application.conditionDescription')}
                    required
                    name={`conditionDescription-${editingItem.ID}`}
                    defaultValue={editingItem.Description}
                    onChange={(e) => {
                      editingItem.Description = e.currentTarget.value
                      this.setState({ editingItem })
                    }}
                    validationErrors={language.messages.validationErrors}
                  />
                  <FormsySelect
                    floatingLabelText={language.translate('application.conditionType')}
                    value={conditionTypeSelected.ID || ''}
                    onChange={(e, value) => {
                      this.handleConditionTypeChange(value)
                    }}
                    inputStyle={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                    name='condition_type'
                    required
                    fullWidth
                    validationErrors={language.messages.validationErrors}
                  >
                    {this.conditionTypeMenu()}
                  </FormsySelect>
                  {(conditionTypeSelected.ProcessTemplateFieldID_Required)
                    ? <div style={{ width: '100%' }}>
                      <FormsySelect
                        floatingLabelText={language.translate('application.processField')}
                        value={editingItem.Condition_ProcessTemplateFieldID || ''}
                        onChange={(e, value) => {
                          this.handleProcessFieldChange(value)
                        }}
                        inputStyle={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                        name='process_field'
                        required={conditionTypeSelected.ProcessTemplateFieldID_Required}
                        fullWidth
                        validationErrors={language.messages.validationErrors}
                      >
                        {this.processFieldMenu()}
                      </FormsySelect>
                      {(processFieldSelected.FieldOptionList && conditionTypeSelected.Identifier !== 'FLD_EMPTY' && conditionTypeSelected.Identifier !== 'FLD_NOT_EMPTY' && processFieldSelected.FieldType !== 'INSTCHLS')
                        ? <FormsySelect
                          floatingLabelText={language.translate('application.processFieldOption')}
                          value={editingItem.Condition_ProcessTemplateFieldOptionID || ''}
                          onChange={(e, value) => {
                            editingItem.Condition_ProcessTemplateFieldOptionID = value
                            this.setState({ editingItem })
                          }}
                          inputStyle={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                          name='process_field'
                          required
                          fullWidth
                          validationErrors={language.messages.validationErrors}
                        >
                          {this.processFieldOptionMenu(processFieldList.filter(field => (field.ID === editingItem.Condition_ProcessTemplateFieldID))[0].FieldOptionList)}
                        </FormsySelect>
                        : null}
                      {(conditionTypeSelected.Identifier !== 'FLD_EMPTY' && conditionTypeSelected.Identifier !== 'FLD_NOT_EMPTY' && (processFieldSelected.FieldType === 'INSTCHLS' || processFieldSelected.FieldType === 'INSTANCE'))
                        ? <FormsySelect
                          floatingLabelText={language.translate('application.processFieldOption')}
                          value={editingItem.Condition_ProcessInstanceID || ''}
                          onChange={(e, value) => {
                            editingItem.Condition_ProcessInstanceID = value
                            this.setState({ editingItem })
                          }}
                          inputStyle={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                          name='process_field'
                          required
                          fullWidth
                          validationErrors={language.messages.validationErrors}
                        >
                          {this.processFieldOptionMenu(instanceFieldOptions)}
                        </FormsySelect>
                        : null}
                    </div>
                    : null}
                  {(conditionTypeSelected.ProcessTemplateTaskID_Required)
                    ? <FormsySelect
                      floatingLabelText={language.translate('application.task')}
                      value={editingItem.Condition_ProcessTemplateTaskID || ''}
                      onChange={(e, value) => {
                        editingItem.Condition_ProcessTemplateTaskID = value

                        if (conditionTypeSelected.ProcessTemplateTaskResponseID_Required && value) {
                          onRequestTaskResponses(value, (response) => {
                            editingItem.Condition_ProcessTemplateTaskResponseID = null

                            this.setState({
                              responseList: response,
                              editingItem
                            })
                          })
                        }

                        this.setState({ editingItem })
                      }}
                      inputStyle={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                      name='template_task'
                      required
                      fullWidth
                      validationErrors={language.messages.validationErrors}
                    >
                      {this.templateTaskMenu()}
                    </FormsySelect>
                    : null}
                  {(conditionTypeSelected.ProcessTemplateTaskResponseID_Required && editingItem.Condition_ProcessTemplateTaskID)
                    ? <FormsySelect
                      floatingLabelText={language.translate('application.response')}
                      value={editingItem.Condition_ProcessTemplateTaskResponseID || ''}
                      onChange={(e, value) => {
                        editingItem.Condition_ProcessTemplateTaskResponseID = value
                        this.setState({ editingItem })
                      }}
                      inputStyle={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                      name='template_task_response'
                      required
                      fullWidth
                      validationErrors={language.messages.validationErrors}
                    >
                      {this.templateTaskResponseMenu()}
                    </FormsySelect>
                    : null}
                  {(conditionTypeSelected.UserFieldID_Required)
                    ? <FormsySelect
                      floatingLabelText={language.translate('application.customUserFields')}
                      value={editingItem.Condition_UserFieldID || ''}
                      onChange={(e, value) => {
                        editingItem.Condition_UserFieldID = value
                        this.setState({ editingItem })
                      }}
                      inputStyle={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                      name='user_field'
                      required
                      fullWidth
                      validationErrors={language.messages.validationErrors}
                    >
                      {this.userFieldMenu()}
                    </FormsySelect>
                    : null}
                  {(this.shouldShowConditionText())
                    ? <FormsyText
                      floatingLabelText={language.translate('application.conditionText')}
                      required
                      name={`conditionText-${editingItem.ID}`}
                      defaultValue={editingItem.Condition_Text}
                      onChange={(e) => {
                        editingItem.Condition_Text = e.currentTarget.value
                        this.setState({ editingItem })
                      }}
                      validationErrors={language.messages.validationErrors}
                    />
                    : null}
                  {(this.shouldShowConditionNumber())
                    ? <FormsyText
                      floatingLabelText={language.translate('application.conditionNumber')}
                      required
                      name={`conditionNumber-${editingItem.ID}`}
                      defaultValue={editingItem.Condition_Number}
                      onChange={(e) => {
                        editingItem.Condition_Number = parseInt(e.currentTarget.value)
                        this.setState({ editingItem })
                      }}
                      type='tel'
                      style={{ width: '200px' }}
                      validations={'isNumeric:true'}
                      validationErrors={language.messages.validationErrors}
                    />
                    : null}
                  {(this.shouldShowGroupUserInput())
                    ? <GroupUserSelect
                      name={language.translate('application.selectGroupUser')}
                      style={{ width: '100%' }}
                      required
                      allowAllUsers
                      allowAllGroups
                      dispatch={dispatch}
                      hideUserField={!(conditionTypeSelected.UserID_Required)}
                      lockGroup={processFieldSelected.FieldType === 'P'}
                      groupValue={(processFieldSelected.FieldType === 'P') ? processFieldSelected.UserGroupID : editingItem.Condition_UserGroupID}
                      userValue={editingItem.Condition_UserID}
                      onGroupChange={(groupId) => {
                        if (processFieldSelected.FieldType !== 'P') {
                          editingItem.Condition_UserGroupID = groupId
                          this.setState({ editingItem })
                        }
                      }}
                      onUserChange={(userId) => {
                        editingItem.Condition_UserID = userId
                        this.setState({ editingItem })
                      }}
                    />
                    : null}
                </div>} />
              <ListItemSecondaryAction style={{ top: '10%' }}>
                <div style={{ height: 'auto' }}>
                  <DeleteIconButton onDelete={() => {
                    this.props.isEditing(false)
                    this.setState({ editMode: false, editingItem: null })
                    this.props.onRemoveField(!!(editingItem.ID))
                  }} />
                  <IconButton
                    disabled={!this.state.canSubmit}
                    onClick={() => {
                      onSave(this.state.editingItem)
                      this.setState({ editMode: false, editingItem: null })
                    }}
                  >
                    <Check nativeColor={muiTheme.palette.primary1Color} />
                  </IconButton>
                </div>
              </ListItemSecondaryAction>
            </ListItem>
          </Form>}
        <Divider />
        <Menu
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClose={() => { this.setState({ menuAnchor: null }) }}
          getContentAnchorEl={null}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
          <MenuItem onClick={() => {
            this.setState({ editMode: true, editingItem: JSON.parse(JSON.stringify(item)), menuAnchor: null })
            this.props.isEditing()
          }}
          >Edit</MenuItem>
          <MenuItem onClick={() => {
            this.setState({ menuAnchor: null })
            onCopy()
          }}>Copy</MenuItem>
          <DeleteMenuItem
            onDelete={() => {
              this.setState({ menuAnchor: null })
              onRemoveField(true)
            }}
          />
        </Menu>
      </div>
    )
  }
}

ActionConditionListItem.propTypes = {
  item: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  onRemoveField: PropTypes.func.isRequired,
  conditionTypes: PropTypes.array,
  processFieldList: PropTypes.array,
  userFieldList: PropTypes.array,
  templateTasks: PropTypes.array,
  onRequestTaskResponses: PropTypes.func,
  getInstanceFieldOptions: PropTypes.func,
  isEditing: PropTypes.func,
  dispatch: PropTypes.func.isRequired
}

ActionConditionListItem.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object,
  user: PropTypes.object
}

export default ActionConditionListItem
