import PropTypes from 'prop-types'
import React from 'react'
import TinyMCEInput from 'react-tinymce-input'
import { fade } from '@material-ui/core/styles/colorManipulator'
import FormHelper from '../../businessLogic/formHelper'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import { Input } from '@material-ui/icons'
import FieldTokenList from '../ProcessTemplate/FieldTokenList'

class FullScreenEditor extends React.Component {
  constructor(props) {
    super(props)

    this.state = ({
      showTokenDialog: false,
      editor: null,
      value: props.editor.getContent()
    })

    //componentWillMount migration start
    if (window.tinymce) {
      window.tinymce.PluginManager.add('pplan', (activeEditor) => {
        this.setState({
          editor: activeEditor
        })
      })
    }
    //componentWillMount migration end
  }

  // componentWillMount () {
  //   if (window.tinymce) {
  //     window.tinymce.PluginManager.add('pplan', (activeEditor) => {
  //       this.setState({
  //         editor: activeEditor
  //       })
  //     })
  //   }
  // }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.editor.getContent() !== prevProps.editor.getContent()) { this.setState({ value: this.props.editor.getContent() }) }
  }

  render() {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const { tokens, onRequestClose, open, title } = this.props
    const { showTokenDialog, editor, value } = this.state

    if (!window.tinymce || !this.props.editor) { return null }

    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        PaperProps={{ style: { width: '90%', maxWidth: '1000px' } }}
        open={open}
        disableEnforceFocus
      >
        <DialogTitle>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div>{title || language.translate('application.editor')}</div>
            <div>
              <Button
                onClick={() => {
                  this.setState({
                    showTokenDialog: true
                  })
                }}
              >
                <Input nativeColor={palette.accent4Color} style={{ transform: 'rotate(180deg)' }} />
              </Button>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <FieldTokenList
            tokens={tokens}
            open={showTokenDialog}
            close={() => this.setState({ showTokenDialog: false })}
            onSelect={(tokenValue) => {
              const tokenElement = FormHelper.decodeHTML(tokenValue)
              editor.execCommand('mceInsertContent', false, `<span class="field-token" contenteditable="false">${tokenElement}</span>`)

              this.setState({
                showTokenDialog: false
              })
            }}
          />
          <TinyMCEInput
            value={value}
            tinymceConfig={{
              menubar: false,
              relative_urls: false,
              document_base_url: null,
              convert_urls: false,
              statusbar: false,
              plugins: 'link image code textcolor colorpicker pplan',
              toolbar: 'fontsizeselect fontselect forecolor backcolor | bold italic | bullist numlist outdent indent | alignleft aligncenter alignright | link code',
              fontsize_formats: '8px 10px 12px 14px 18px 24px 36px',
              content_style: `.field-token {border-radius: 10px; padding: 0px 5px; background-color: ${fade(palette.primary1Color, 0.25)};} .invalid-token {background-color: ${fade(palette.errorColor, 0.25)};}`,
              height: 250
            }}
            onChange={value => this.setState({ value })}
          />
        </DialogContent>
        <DialogActions>
          <Button
            key='done'
            color='primary'
            onClick={() => onRequestClose(editor.getContent())}
          >
            {language.translate('application.done')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

FullScreenEditor.propTypes = {
  title: PropTypes.string,
  editor: PropTypes.object.isRequired,
  open: PropTypes.bool,
  tokens: PropTypes.array.isRequired,
  onRequestClose: PropTypes.func.isRequired
}

FullScreenEditor.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default FullScreenEditor
