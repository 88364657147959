import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'react-router-redux'
import { Base64 } from 'js-base64'
import { CircularProgress, IconButton, Menu, MenuItem, Paper, TextField, Tooltip } from '@material-ui/core'
import { Storage, MoreVert, Save, CallSplit } from '@material-ui/icons'
import { disableFullScreen, enableFullScreen, updateTitle } from '../actions/application'
import { showSnackbar } from '../actions/snackbar'
import { get, post } from '../actions/base'
import ContactCardDialog from '../components/User/ContactCardDialog'
import GroupCardDialog from '../components/User/GroupCardDialog'
import MapSubProcessDialog from '../components/ProcessTemplate/MapSubProcessDialog'
import FormHelper from '../businessLogic/formHelper'
import moment from 'moment'
import { GeneratedIcon, Icon } from '../components/Icon'
import objectAssign from 'object-assign'
import Subscriber from '../components/Layout/Notifications/Subscriber'
import MessageNotificationIcon from '../components/Messages/MessageNotificationIcon'
import Diagram from '../components/Diagram/Diagram'
import DeleteMenuItem from '../components/Layout/DeleteMenuItem'
import ImpromptuTaskDialog from '../components/Process/ImpromptuTaskDialog'

class ProcessVisualProgress extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      processInstance: [],
      isLoading: true,
      isSaving: false,
      diagramModel: {},
      diagramNeedsToBeSaved: false,
      contactCardOpen: false,
      contactCardUserID: '',
      groupCardOpen: false,
      groupCardGroupID: '',
      showMapSubProcessDialog: false,
      subProcessResponseID: null,
      menuAnchor: null,
      showImpromptuTaskModal: false,
      taskList: [],
      taskListLoaded: false,
      searchText: ''
    }
  }

  componentDidMount() {
    const { processid } = this.props.params

    this.fetchProcessData(processid)

    this.updateTitle()

    this.props.enableFullScreen()
  }

  // MIGRATING componentWillUpdate TO componentDidUpdate
  // componentWillUpdate (nextProps, nextState) {
  //   if (this.props.params.processid !== nextProps.params.processid) {
  //     this.fetchProcessData(nextProps.params.processid)
  //   }
  // }

  componentDidUpdate(prevProps) {
    if (prevProps.params.processid !== this.props.params.processid) {
      this.fetchProcessData(this.props.params.processid)
    }

    // if (this.props.params.processid !== nextProps.params.processid) {
    //   this.fetchProcessData(nextProps.params.processid)
    // }
    this.updateTitle()
  }

  componentWillUnmount() {
    this.props.disableFullScreen()
  }

  fetchProcessData(processid, showLoadingIndicator = true) {
    this.setState({ isLoading: showLoadingIndicator })

    /*
     *  GET PROCESS INFORMATION
     */
    this.props.dispatch(get(`processinstance/${processid}`, {
      onSuccess: (response) => {
        this.setState({
          processInstance: response.ProcessInstance
        }, () => this.fetchDiagram(processid))
      }
    }))
  }

  fetchDiagram(processId) {
    /*
     * GET PROCESS FLOW MODEL
     */
    const url = `processinstance/${processId}/diagrammodel`
    this.props.dispatch(get(url, {
      onSuccess: (response) => {
        this.setState({
          diagramModel: response.DiagramModel,
          isLoading: false
        })
      }
    }))
  }

  saveDiagram() {
    this.setState({ isSaving: true })
    const { post } = this.props
    const { diagramModel, processInstance } = this.state
    const body = JSON.stringify({
      DiagramModel: diagramModel
    })

    post(`processinstance/${processInstance.ID}/diagrammodel`, body, {
      onSuccess: (response) => {
        const data = response.DiagramModel

        this.setState({
          processInstance: response.ProcessInstance,
          diagramModel: data,
          diagramNeedsToBeSaved: false,
          isSaving: false
        })
      }
    })
  }

  goToTask(taskID) {
    const location = this.props.location

    this.props.push({ pathname: location.pathname, query: { task: taskID, ptype: 'task', pid: taskID } })
  }

  goToProcess(processID) {
    this.props.push(`/process-visual-progress/${processID}`)
  }

  goToTemplate(templateID) {
    this.props.push(`/process-template/${templateID}`)
  }

  startTask(processInstanceID, templateTaskID) {
    const body = JSON.stringify({
      POSTOptions: {
        CancelPendingTasks: false,
        ManuallyStartedTask: true
      }
    })

    this.props.dispatch(post(
      `processinstance/${processInstanceID}/templatetask/${templateTaskID}/start`,
      body,
      {
        onSuccess: (response) => {
        }
      }
    ))
  }

  cancelTask(taskID) {
    const processInstanceID = this.props.params.processid

    const body = JSON.stringify({})

    this.props.dispatch(post(`processinstance/${processInstanceID}/task/${taskID}/cancel`, body, {
      onSuccess: (response) => {
      }
    }))
  }

  cancelProcess() {
    const { post } = this.props
    const processInstanceID = this.props.params.processid

    const body = JSON.stringify({})

    post(`processinstance/${processInstanceID}/cancel`, body, {
      onSuccess: (response) => {
      }
    })
  }

  submitResponse(taskID, responseID) {
    const processInstanceID = this.props.params.processid

    const body = JSON.stringify({
      POSTOptions: {
        ProcessTemplateTaskResponseID: responseID,
        SendNewTaskEmailNotification: false
      }
    })

    this.props.dispatch(post(`processinstance/${processInstanceID}/task/${taskID}/respond`, body, {
      onSuccess: (response) => {
        if (response.ProcessInstance.CompletedDate_UTC !== '') {
          // take them to a process receipt screen
          this.props.push({
            pathname: this.context.location.pathname,
            query: { ptype: 'process', pid: processInstanceID }
          })
        } else if (response.ProcessInstanceTask) {
          const query = this.props.location.query

          // make sure you stay with the processes the task represents (sub-processes)
          const newPath = `/process-visual-progress/${response.ProcessInstanceTask.ProcessInstanceID}`

          if (processInstanceID !== response.ProcessInstanceTask.ProcessInstanceID) {
            this.props.push(newPath)
          } else {
            this.props.push({
              pathname: newPath,
              query
            })
          }
        }
      }
    }))
  }

  linkResponseToSubProcess(subProcessId) {
    const { post, push, location, params } = this.props
    const { processInstance, subProcessResponseID } = this.state

    const body = JSON.stringify({})

    post(
      `processtemplate/${processInstance.ProcessTemplateID}/version/${processInstance.VersionTimeStamp}/response/${subProcessResponseID}/linktomigrationplan/${subProcessId}`,
      body,
      {
        onSuccess: (response) => {
          this.fetchProcessData(params.processid, true)

          push({
            pathname: location.pathname,
            query: objectAssign({}, location.query, {
              ptype: 'impmap',
              pid: subProcessId,
              ptsid: processInstance.ID
            })
          })
        }
      }
    )
  }

  createMigrationPlan(ProcessTemplateMigrationPlan) {
    const body = JSON.stringify({ ProcessTemplateMigrationPlan })

    this.props.dispatch(post(`processinstance/${this.props.params.processid}/templatemigrationplan`, body, {
      onSuccess: (response) => {
        this.linkResponseToSubProcess(response.ProcessTemplateMigrationPlan.ID)
      }
    }))
  }

  updateTitle() {
    const { language } = this.context
    const title = language.translate('application.visualProgress')
    this.props.dispatch(updateTitle(title))
  }

  raiseError(message) {
    this.props.dispatch(showSnackbar(message))
  }

  displayContactCard(userID) {
    this.setState({
      contactCardOpen: true,
      contactCardUserID: userID
    })
  }

  displayGroupCard(groupID) {
    this.setState({
      groupCardOpen: true,
      groupCardGroupID: groupID
    })
  }

  openFieldMappingPanel(subProcessId) {
    this.props.push({
      pathname: this.context.location.pathname,
      query: {
        ptype: 'impmap',
        pid: subProcessId,
        ptsid: this.state.processInstance.ID
      }
    })
  }

  openProcessFields() {
    const processInstanceID = this.props.params.processid

    this.props.push({
      pathname: this.context.location.pathname,
      query: objectAssign({},
        this.context.location.query,
        {
          ptype: 'pifields',
          pid: processInstanceID
        })
    })
  }

  goToTaskStatistics(taskId) {
    const processTemplateID = this.state.processInstance.ProcessTemplateID

    this.props.push({
      pathname: '/statistics',
      query: {
        by: 'month',
        taskId,
        templateId: processTemplateID,
        time: 'days',
        type: 'task'
      }
    })
  }

  subscriptionCallback(response) {
    this.fetchProcessData(response.ProcessInstance.ID, false)
  }

  showInstanceMemos() {
    const { processInstance } = this.state

    this.props.push({
      pathname: this.context.location.pathname,
      query: objectAssign({},
        this.context.location.query,
        {
          ptype: 'memos',
          memoType: 'instance',
          pid: processInstance.ID
        })
    })
  }

  downloadDiagramImage(svg) {
    const { processInstance } = this.state

    const svgString = new window.XMLSerializer().serializeToString(svg)
    const svgBlob = new window.Blob([svgString], { type: 'image/svg+xml;charset=utf-8' })

    const svgUrl = window.URL.createObjectURL(svgBlob)
    const filename = `${processInstance.ProcessTemplateTitle}.svg`
    const downloadLink = document.createElement('a')
    downloadLink.href = svgUrl
    downloadLink.download = filename

    // IE 11
    if (window.navigator.msSaveBlob !== undefined) {
      window.navigator.msSaveBlob(svgBlob, filename)
      return
    }

    document.body.appendChild(downloadLink)
    window.requestAnimationFrame(() => {
      downloadLink.click()
      window.URL.revokeObjectURL(svgUrl)
      document.body.removeChild(downloadLink)
    })
  }

  showResponseSettings(responseId) {
    const { push, location } = this.props

    push({
      pathname: location.pathname,
      query: objectAssign({},
        location.query,
        {
          ptype: 'ptrsettings',
          pid: responseId
        })
    })
  }

  showTaskSettings(taskId) {
    const { push, location } = this.props

    push({
      pathname: location.pathname,
      query: objectAssign({},
        location.query,
        {
          ptype: 'pttsettings',
          pid: taskId
        })
    })
  }

  createImpromptuTask(task, afterTemplateTaskId, errorCallback) {
    const { post, showSnackbar, push, location } = this.props
    const { language } = this.context

    task.ProcessInstanceID = this.props.params.processid

    const body = JSON.stringify({
      ProcessInstanceTask: task,
      POSTOptions: {
        ProcessTemplateTaskID: afterTemplateTaskId
      }
    })

    post(`processinstance/${task.ProcessInstanceID}/task`, body, {
      onSuccess: (response) => {
        this.setState({ showImpromptuTaskModal: false })
        showSnackbar({
          message: language.translate('application.impromptuTaskCreated'),
          action: language.translate('application.openThisTask'),
          actionEvent: () => {
            push({
              pathname: location.pathname,
              query: objectAssign(
                {},
                location.query,
                {
                  ptype: 'task',
                  task: response.ProcessInstanceTask.ID,
                  pid: response.ProcessInstanceTask.ID
                })
            })
          }
        })
      },
      onError: errorCallback
    })
  }

  getTemplateTasks() {
    const { processInstance } = this.state

    this.props.get(`processtemplate/${processInstance.ProcessTemplateID}/task/list`, {
      onSuccess: (response) => {
        this.setState({
          taskList: response.ProcessTemplateTaskList,
          taskListLoaded: true
        })
      }
    })
  }

  render() {
    const { language, location, user } = this.context
    const { push, get, mainContentWidth } = this.props
    const { diagramModel, diagramNeedsToBeSaved, showImpromptuTaskModal, menuAnchor, taskList, taskListLoaded, searchText } = this.state
    const palette = this.context.muiTheme.palette
    const process = this.state.processInstance

    return (
      (this.state.isLoading)
        ? <CircularProgress className='loader' />

        : <Paper elevation={4} style={{ height: '100%', position: 'relative' }}>
          <div style={{
            padding: '10px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start'
          }}
          >
            <div style={{
              fontSize: '16px',
              color: palette.accent3Color,
              float: 'left',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
            >
              {(process.ProcessTemplateIconName)
                ? <Icon icon={process.ProcessTemplateIconName} size={40} style={{ marginRight: '10px' }} />
                : <GeneratedIcon
                  text={process.ProcessTemplateTitle}
                  randomizer={process.ProcessTemplateID}
                  style={{ marginRight: '10px' }}
                />}
              <strong
                style={{
                  maxWidth: '300px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }}
              >
                {process.ProcessTemplateTitle}
              </strong>
            </div>
            {(mainContentWidth > 1100) &&
              <div>
                <TextField
                  id='search'
                  style={{ marginTop: '0px', minWidth: '250px' }}
                  placeholder={language.translate('application.search')}
                  value={searchText}
                  onChange={(e) => { this.setState({ searchText: e.target.value }) }}
                  margin='normal'
                />
              </div>}
            {(mainContentWidth > 1500)
              ? <div style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                flexWrap: 'wrap',
                justifyContent: 'flex-end'
              }}
              >
                <Paper
                  elevation={2}
                  style={{
                    padding: '5px',
                    backgroundColor: palette.primary3Color,
                    float: 'right',
                    margin: '5px'
                  }}
                >
                  {language.translate('application.notStarted')}
                </Paper>
                <Paper
                  elevation={2}
                  style={{
                    padding: '5px',
                    backgroundColor: palette.errorColor,
                    float: 'right',
                    margin: '5px',
                    color: palette.canvasColor
                  }}
                >
                  {language.translate('application.pastDue')}
                </Paper>
                <Paper
                  elevation={2}
                  style={{
                    padding: '5px',
                    backgroundColor: palette.successColor,
                    float: 'right',
                    margin: '5px',
                    color: palette.canvasColor
                  }}
                >
                  {language.translate('application.pendingResponse')}
                </Paper>
                <Paper
                  elevation={2}
                  style={{
                    padding: '5px',
                    backgroundColor: palette.primary2Color,
                    float: 'right',
                    margin: '5px',
                    color: palette.canvasColor
                  }}
                >
                  {language.translate('application.pendingTask')}
                </Paper>
                <Paper
                  elevation={2}
                  style={{
                    padding: '5px',
                    backgroundColor: palette.primary1Color,
                    float: 'right',
                    margin: '5px',
                    color: palette.canvasColor
                  }}
                >
                  {language.translate('application.completed')}
                </Paper>
              </div>
              : null}
            <div>
              {(mainContentWidth > 600)
                ? <Tooltip title={language.translate('application.saveDiagram', [], true)}>
                  <IconButton
                    onClick={() => this.saveDiagram()}
                  >
                    <Save nativeColor={palette.accent6Color} />
                  </IconButton>
                </Tooltip> : null}
              {(mainContentWidth > 600)
                ? <Tooltip title={language.translate('application.processField', [], true)}>
                  <IconButton
                    onClick={() => this.openProcessFields()}
                  >
                    <Storage nativeColor={palette.accent6Color} />
                  </IconButton>
                </Tooltip> : null}
              {(mainContentWidth > 600)
                ? <Tooltip title={language.translate('application.processTemplate')}>
                  <IconButton
                    onClick={() => push(`/process-template/${process.ProcessTemplateID}`)}
                  >
                    <CallSplit nativeColor={palette.accent6Color} style={{ transform: 'rotate(90deg)' }} />
                  </IconButton>
                </Tooltip> : null}
              {(mainContentWidth > 600)
                ? <MessageNotificationIcon
                  onClick={() => this.showInstanceMemos()}
                  memoType='ProcessInstance'
                  memoTypeID={process.ID}
                  get={get}
                /> : null}
              <IconButton onClick={(e) => { this.setState({ menuAnchor: e.currentTarget }) }}><MoreVert /></IconButton>
              <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={() => { this.setState({ menuAnchor: null }) }}
                getContentAnchorEl={null}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              >
                {(mainContentWidth <= 600)
                  ? <MenuItem
                    onClick={() => {
                      this.setState({ menuAnchor: null })
                      this.openProcessFields()
                    }}
                  >
                    {language.translate('application.processField', [], true)}
                  </MenuItem>
                  : null}
                {(mainContentWidth <= 600)
                  ? <MenuItem
                    onClick={() => {
                      this.setState({ menuAnchor: null })
                      this.showInstanceMemos()
                    }}
                  >
                    {language.translate('application.messages')}
                  </MenuItem>
                  : null}
                <MenuItem
                  onClick={() => {
                    this.setState({ menuAnchor: null })
                    push(`/process-history/${process.ID}`)
                  }}
                  key={2}
                >
                  {language.translate('application.history')}
                </MenuItem>
                {(mainContentWidth <= 600 &&
                  <MenuItem
                    onClick={() => {
                      this.setState({ menuAnchor: null })
                      push(`/process-template/${process.ProcessTemplateID}`)
                    }}
                  >
                    {language.translate('application.processTemplate')}
                  </MenuItem>)}
                <MenuItem
                  onClick={() => {
                    this.setState({ menuAnchor: null })
                    push({
                      pathname: location.pathname,
                      query: { ptype: 'pisettings', pid: process.ID }
                    })
                  }}
                >
                  {language.translate('application.processSettings')}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    this.setState({ menuAnchor: null })
                    let query = {}

                    query.search = Base64.encodeURI(JSON.stringify({
                      property: 'ID',
                      value: process.ID,
                      displayValue: process.HeaderField1_Value,
                      displayText: process.HeaderField1_Name,
                      operator: '(EQUALTO_ID)'
                    }))

                    push({
                      pathname: `/process-instances-grid/${process.ProcessTemplateID}`,
                      query
                    })
                  }}
                >
                  {language.translate('application.processTable')}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    this.setState({ menuAnchor: null })
                    push(`/relationships/${process.ProcessTemplateID}/${process.ID}`)
                  }}
                >
                  {language.translate('application.relationships')}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    this.getTemplateTasks()
                    this.setState({ showImpromptuTaskModal: true, menuAnchor: null })
                  }}
                >
                  {language.translate('application.addImpromptuTask')}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    this.setState({ menuAnchor: null })

                    push({
                      pathname: '/audit-trail',
                      query: {
                        endpoint: `processinstance/${process.ID}/auditentry/list`,
                        title: process.ProcessTemplateTitle
                      }
                    })
                  }}
                >
                  {language.translate('application.auditTrail')}
                </MenuItem>
                {(!process.CanceledDate_Local)
                  ? <DeleteMenuItem
                    onDelete={() => {
                      this.setState({ menuAnchor: null })
                      this.cancelProcess()
                    }}
                    deleteLabel={language.translate('application.cancelThisProcess')}
                  />
                  : null}
              </Menu>
            </div>
          </div>
          {/* HAVE THIS INFO RIDE JUST BELOW THE TOP LEFT TITLE OF DIAGRAM */}
          <div style={{
            position: 'absolute',
            paddingLeft: '10px',
            fontSize: '11px',
            color: palette.accent4Color
          }}
          >
            <div>
              {FormHelper.decodeHTML(process.HeaderField1_Name)}: {FormHelper.decodeHTML(process.HeaderField1_Value)}
            </div>
            <div>
              {language.translate('application.processTargetDate')}: {(process.TargetDate_Local) ? moment(process.TargetDate_Local).format((user.dateFormat) ? user.dateFormat : 'YYYY-MM-DD') : 'None'}
            </div>
            <div>
              {language.translate('application.actualHours')}: {process.Tasks_ActualHours}
            </div>
            <div>
              {language.translate('application.estimatedHours')}: {process.Tasks_EstimatedHours}
            </div>
          </div>
          <div style={{ height: 'calc(100% - 50px)', margin: '0px', position: 'absolute', width: '100%' }}>
            <Diagram
              diagramModel={diagramModel}
              diagramType='instance'
              searchString={searchText}
              fastRender={process.FastRender}
              disableEditing={(!user.canEditInstanceDiagrams)}
              canCopyPaste={(user.canEditInstanceDiagrams)}
              diagramNeedsToBeSaved={diagramNeedsToBeSaved}
              onChange={() => { this.setState({ diagramNeedsToBeSaved: true }) }}
              showErrorMessage={this.raiseError.bind(this)}
              onNodeSelected={(node) => { console.log('selected: ', node) }}
              onTaskSingleClicked={(nodeData) => {
                if (nodeData.instanceTaskID) {
                  this.goToTask(nodeData.instanceTaskID)
                }
              }}
              onProcessDoubleClicked={(nodeData) => {
                if (nodeData.instanceID) {
                  push(`/process-visual-progress/${nodeData.instanceID}`)
                }
              }}
              displayContactCard={this.displayContactCard.bind(this)}
              displayGroupCard={this.displayGroupCard.bind(this)}
              downloadDiagramImage={this.downloadDiagramImage.bind(this)}
              taskMenuItems={[
                {
                  label: language.translate('application.openThisTask'),
                  action: (nodeObject) => { this.goToTask(nodeObject.instanceTaskID) },
                  shouldDisplay: (data) => (typeof data.instanceTaskID === 'string')
                }, {
                  label: language.translate('application.startThisTask'),
                  action: (nodeObject) => { this.startTask(this.state.processInstance.ID, nodeObject.templateTaskID) },
                  shouldDisplay: (data) => (data.executionStatus !== 'pending' && !['new', 'copy'].includes(data.status) && user.canRestartInstanceTasks)
                }, {
                  label: language.translate('application.cancelThisTask'),
                  action: (nodeObject) => { this.cancelTask(nodeObject.instanceTaskID) },
                  shouldDisplay: (data) => (data.executionStatus === 'pending')
                }, {
                  label: language.translate('application.settings'),
                  action: (nodeObject) => { this.showTaskSettings(nodeObject.key) },
                  shouldDisplay: (data) => (data.isEditable && !['new', 'copy'].includes(data.status) && user.canEditInstanceDiagrams)
                }, {
                  label: language.translate('application.processField', [], true),
                  action: () => { this.openProcessFields() }
                }, {
                  label: language.translate('application.taskStatistics'),
                  action: (nodeObject) => { this.goToTaskStatistics(nodeObject.templateTaskID) },
                  shouldDisplay: (data) => (!['new', 'copy'].includes(data.status))
                }, {
                  label: language.translate('application.processTemplate'),
                  action: () => { this.goToTemplate(this.state.processInstance.ProcessTemplateID) }
                }
              ]}
              diagramMenuItems={[
                {
                  label: language.translate('application.saveDiagram'),
                  action: this.saveDiagram.bind(this)
                }, {
                  label: language.translate('application.processField', [], true),
                  action: this.openProcessFields.bind(this)
                }, {
                  label: language.translate('application.processTemplate'),
                  action: () => { this.goToTemplate(this.state.processInstance.ProcessTemplateID) }
                }
              ]}
              subProcessMenuItems={[
                {
                  label: language.translate('application.processTemplate'),
                  action: (nodeData) => { this.goToTemplate(nodeData.templateID) }
                }, {
                  label: language.translate('application.openProcess'),
                  action: (nodeData) => {
                    if (nodeData.isExternalProcess) {
                      this.raiseError(language.translate('application.youCannotOpenOtherTemplateAccounts'))
                      return false
                    }

                    this.goToProcess(nodeData.instanceID)
                  },
                  shouldDisplay: (data) => (typeof data.instanceID === 'string'),
                  validateSave: true
                }, {
                  label: language.translate('application.fieldMapping'),
                  action: (nodeData) => { this.openFieldMappingPanel(nodeData.key) },
                  shouldDisplay: (data) => (data.isEditable)
                }
              ]}
              startNodeMenuItems={[
                {
                  label: language.translate('application.processField', [], true),
                  action: () => { this.openProcessFields() }
                }, {
                  label: language.translate('application.processTemplate'),
                  action: () => { this.goToTemplate(this.state.processInstance.ProcessTemplateID) }
                }
              ]}
              responseMenuItems={[
                {
                  label: language.translate('application.submitResponse'),
                  action: (nodeObject) => { this.submitResponse(nodeObject.instanceTaskID, nodeObject.key) },
                  shouldDisplay: (data) => !!(data.isResponseAllowed)
                }, {
                  label: language.translate('application.openResponse'),
                  action: (nodeObject) => { this.goToTask(nodeObject.instanceTaskID) },
                  shouldDisplay: (data) => (!!data.instanceTaskID && typeof data.instanceTaskID === 'string')
                }, {
                  label: language.translate('application.settings'),
                  action: (nodeObject) => { this.showResponseSettings(nodeObject.key) },
                  shouldDisplay: (data) => (data.isEditable && user.canEditInstanceDiagrams)
                }, {
                  label: language.translate('application.processField', [], true),
                  action: () => { this.openProcessFields() }
                }, {
                  label: language.translate('application.processTemplate'),
                  action: () => { this.goToTemplate(this.state.processInstance.ProcessTemplateID) }
                }, {
                  label: language.translate('application.launchAnotherProcess'),
                  action: (nodeData) => {
                    if (this.state.diagramNeedsToBeSaved) {
                      this.raiseError(language.translate('application.youMustSaveYourChangesFirst'))
                      return false
                    }

                    this.setState({
                      showMapSubProcessDialog: true,
                      subProcessResponseID: nodeData.key
                    })
                  },
                  shouldDisplay: () => (user.canEditInstanceDiagrams)
                }
              ]}
            />
          </div>
          {(mainContentWidth > 600)
            ? <div
              style={{
                bottom: '25px',
                position: 'absolute',
                width: '100%',
                height: '25px',
                padding: '0px 3px'
              }}
            >
              <div
                style={{ textAlign: 'center' }}
              >{language.translate('application.progressViewHelpText')}</div>
            </div> : null}
          <ContactCardDialog
            open={this.state.contactCardOpen}
            userID={this.state.contactCardUserID}
            close={() => this.setState({ contactCardOpen: false })}
          />
          <GroupCardDialog
            open={this.state.groupCardOpen}
            groupID={this.state.groupCardGroupID}
            close={() => this.setState({ groupCardOpen: false })}
          />
          <Subscriber
            channelName={`Private-${user.accountID}-ProcessInstance-${process.ID}`}
            events={['InstanceUpdated', 'InstanceCompleted', 'InstanceCanceled']}
            callback={this.subscriptionCallback.bind(this)}
          />
          <MapSubProcessDialog
            open={this.state.showMapSubProcessDialog}
            close={() => this.setState({ showMapSubProcessDialog: false })}
            onSubmit={this.createMigrationPlan.bind(this)}
          />
          {(showImpromptuTaskModal && taskListLoaded)
            ? <ImpromptuTaskDialog
              onCancel={() => this.setState({ showImpromptuTaskModal: false })}
              onSubmit={this.createImpromptuTask.bind(this)}
              dispatch={this.props.dispatch}
              taskList={taskList}
            />
            : null}
        </Paper>
    )
  }
}

ProcessVisualProgress.propTypes = {
  params: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.string,
  email: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  enableFullScreen: PropTypes.func.isRequired,
  disableFullScreen: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  mainContentWidth: PropTypes.number.isRequired
}

ProcessVisualProgress.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object,
  location: PropTypes.object,
  user: PropTypes.object
}

const mapStateToProps = state => ({
  title: state.application.title,
  email: state.auth.userName,
  token: state.auth.token,
  mainContentWidth: state.application.mainContentWidth
})

const mapDispatchToProps = dispatch => ({
  dispatch,
  enableFullScreen: bindActionCreators(enableFullScreen, dispatch),
  disableFullScreen: bindActionCreators(disableFullScreen, dispatch),
  showSnackbar: bindActionCreators(showSnackbar, dispatch),
  push: bindActionCreators(push, dispatch),
  get: bindActionCreators(get, dispatch),
  post: bindActionCreators(post, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ProcessVisualProgress)
