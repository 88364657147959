import moment from 'moment'

class DateHelper {
  /**
   * Converts the provided date/time to utc
   *
   * @param date
   * @param utcOffset
   * @returns {*}
   */
  static convertDateTimeToUTC (date, utcOffset) {
    return moment(date).subtract(utcOffset, 'm')
  }

  /**
   * Converts the provided date/time from utc to local
   *
   * @param date
   * @param utcOffset
   * @returns {*}
   */
  static convertDateTimeToLocal (date, utcOffset) {
    return moment(date).add(utcOffset, 'm')
  }

  static getFormattedDateTime (date) {
    return `${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:${this.padLeadingZero(date.getMinutes())}:${this.padLeadingZero(date.getSeconds())}`
  }

  static padLeadingZero (value) {
    return value > 9 ? value : `0${value}`
  }

  static formatForAPI (date, format, zeroOutTime = true) {
    // reset the utc offset to 0 so time shows midnight for their local timezone
    const userTimezoneOffset = moment(date, format).utcOffset() * 60000
    let dateString = moment(date, format).add(userTimezoneOffset).toISOString().replace('Z', '')

    if (zeroOutTime) { dateString = this.removeTimeOffsetFromISO(dateString) }

    return dateString.substring(0, dateString.length - 1)
  }

  static removeTimeOffsetFromISO (isoDate) {
    const dateArray = isoDate.split('T')

    return `${dateArray[0]}T00:00:00.000`
  }
}

export default DateHelper
