import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'react-router-redux'
import objectAssign from 'object-assign'
import { Base64 } from 'js-base64'
import { fade } from '@material-ui/core/styles/colorManipulator'
import * as actionCreators from '~/actions/header'
import * as navigationActions from '~/actions/navigation'
import { IconButton } from '@material-ui/core'
import { MenuItem } from 'material-ui'
import { Search, ArrowBack } from '@material-ui/icons'
import AutoComplete from '../../Forms/AutoComplete'
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import theme from '~/styles/theme'

const newTheme = JSON.parse(JSON.stringify(theme))
newTheme.palette.primary.main = '#fff'
newTheme.overrides.MuiInput = {
  underline: {
    '&:before': {
      borderColor: '#fff',
      borderBottom: '1px solid #fff'
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: '2px solid #fff'
    }
  }
}

newTheme.overrides.MuiSelect = {
  icon: {
    color: '#fff'
  }
}

const mStyles = {
  labelStyle: {
    color: '#fff',
    WebkitTextFillColor: '#fff'
  }
}

const styles = theme => ({
  root: {
    width: '100%',
    color: '#fff',
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderColor: '#fff !important'
    }
  },
  underline: {
    '&:before': {
      borderBottom: '1.2px solid #fff'
    }
  },
  disabled: {},
  focused: {},
  error: {}
})

class SearchField extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.clearValue = this.clearValue.bind(this)
    this.detectHotKey = this.detectHotKey.bind(this)

    this.state = {
      isMobile: false,
      searchOverride: false,
      inputValue: '',
      pageChangeListener: null
    }
  }

  componentDidMount () {
    this.checkWidth()
    window.addEventListener('resize', this.checkWidth.bind(this))
    window.addEventListener('keypress', this.detectHotKey)
    this.setState({ pageChangeListener: this.context.router.listen(this.clearValue) })
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.checkWidth.bind(this))
    window.removeEventListener('keypress', this.detectHotKey)
    this.removeSearchOverride()
    this.isUnmounted = true

    // calling the function removes the listener
    if (this.state.pageChangeListener) { this.state.pageChangeListener() }
  }

  clearValue () {
    this.setState({
      inputValue: ''
    })
  }

  detectHotKey (e) {
    if (e.target.tagName !== 'INPUT' && e.target.tagName !== 'TEXTAREA' && e.charCode === 92 && this.searchField) {
      setTimeout(() => {
        this.searchField.focus()
      }, 50)
    }
  }

  checkWidth () {
    if (!this.isUnmounted) {
      this.setState({
        isMobile: (document.body.clientWidth < 850)
      })
    }
  }

  handleTextChange (text, dataSource, params) {
    if (!this.isUnmounted && params.source === 'change') {
      this.setState({
        inputValue: text
      })
    }
  }

  handleSearchItemSelect (selectedItem) {
    const { header, push, location } = this.props

    let { location: { query: { search = [] } } } = this.props

    const dataSource = header.filterable_columns.filter((filter) => (filter.property === selectedItem.value))[0]

    if (selectedItem.value < 0) { return false }

    const { inputValue } = this.state

    if (!inputValue || inputValue.startsWith('...')) {
      return false
    }

    const newSearchItem = Base64.encodeURI(JSON.stringify({
      property: dataSource.property,
      value: inputValue,
      displayText: dataSource.name
    }))

    if (!Array.isArray(search)) {
      search = [search]
    }

    search.push(newSearchItem)

    this.setState({
      inputValue: ''
    }, () => {
      push({
        pathname: location.pathname,
        query: objectAssign({}, location.query, { search })
      })
    })
  }

  showMobileSearch () {
    this.props.actions.hideTitle()
    this.props.navActions.hideNavigation()

    this.setState({
      searchOverride: true
    })
  }

  removeSearchOverride () {
    this.props.actions.showTitle()

    this.setState({
      searchOverride: false
    })
  }

  render () {
    const { language, muiTheme } = this.context
    const { header, classes } = this.props
    const { inputValue } = this.state

    if (!header.showSearch) { return null }

    const searchDataSource = []

    header.filterable_columns.map((column) => {
      searchDataSource.push({ value: column.property, label: `...in ${column.name}` })
    })

    return (
      <div style={{ textAlign: 'right', display: 'flex' }}>
        {this.state.searchOverride
          ? <IconButton
            onClick={this.removeSearchOverride.bind(this)}
          >
            <ArrowBack nativeColor={muiTheme.palette.headerFontColor} />
          </IconButton>
          : ''}

        {this.state.isMobile && !this.state.searchOverride
          ? <IconButton
            onClick={this.showMobileSearch.bind(this)}
          >
            <Search nativeColor={muiTheme.palette.headerFontColor} />
          </IconButton>
          : <div style={{ width: '250px' }}>
            <form autoComplete='off' onSubmit={e => e.preventDefault()}>
              <MuiThemeProvider theme={createMuiTheme(newTheme)}>
                <AutoComplete
                  inputRef={(field) => { this.searchField = field }}
                  onChange={this.handleSearchItemSelect.bind(this)}
                  menuHeight={300}
                  inputOverride={(inputValue) => {
                    if (!inputValue.startsWith('...')) {
                      this.setState({
                        inputValue: inputValue
                      })
                    }
                  }}
                  items={searchDataSource}
                  defaultHighlightedIndex={0}
                  hintText={language.translate('application.search')}
                  searchText={inputValue}
                  openOnFocus={!!(inputValue)}
                  inputProps={{
                    classes: { root: classes.root, underline: classes.underline },
                    style: { marginTop: '5px' }
                  }}
                />
              </MuiThemeProvider>
              {/*
              <AutoComplete
                ref={(field) => { this.searchField = field }}
                dataSource={searchDataSource}
                dataSourceConfig={{text: 'key', value: 'text'}}
                onNewRequest={this.handleSearchItemSelect.bind(this)}
                searchText={inputValue}
                openOnFocus={!!(inputValue)}
                fullWidth
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && inputValue) {
                    this.handleSearchItemSelect(e, 0)
                  }
                }}
                onUpdateInput={this.handleTextChange.bind(this)}
                menuProps={{maxHeight: 300, width: '100%'}}
                popoverProps={{style: {width: '300px'}}}
                hintText={language.translate('application.search')}
                hintStyle={{color: fade(muiTheme.palette.headerFontColor, 0.5)}}
                underlineStyle={{borderColor: fade(muiTheme.palette.headerFontColor, 0.5)}}
                underlineFocusStyle={{borderColor: muiTheme.palette.headerFontColor}}
                inputStyle={{
                  color: muiTheme.palette.headerFontColor,
                  WebkitTextFillColor: muiTheme.palette.headerFontColor
                }}
              />*/}
            </form>
          </div>}
      </div>
    )
  }
}

SearchField.propTypes = {
  actions: PropTypes.object.isRequired,
  navActions: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  header: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}

SearchField.contextTypes = {
  router: PropTypes.object,
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

const mapStateToProps = state => ({
  header: state.header
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
  navActions: bindActionCreators(navigationActions, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SearchField))
