import PropTypes from 'prop-types'
import React from 'react'
import objectAssign from 'object-assign'
import FavoriteItem from './FavoriteItem'

class FavoriteList extends React.Component {
  render () {
    const { favorites, onChange, enableRename, ...rest } = this.props

    if (!favorites.length) {
      return null
    }

    return (
      favorites.map((favorite, index) => {
        return (
          <FavoriteItem
            favorite={objectAssign({}, favorite)}
            key={favorite.url}
            onChange={(value) => { onChange(value, index) }}
            enableRename={() => { enableRename(index) }}
            index={index}
            hideMoveUp={(index === 0)}
            hideMoveDown={(index === favorites.length - 1)}
            {...rest} />
        )
      }))
  }
}

FavoriteList.propTypes = {
  favorites: PropTypes.array,
  onChange: PropTypes.func,
  enableRename: PropTypes.func.isRequired
}

FavoriteList.defaultProps = {
  favorites: []
}

FavoriteList.contextTypes = {
  muiTheme: PropTypes.object,
  language: PropTypes.object,
  location: PropTypes.object
}

export default FavoriteList
