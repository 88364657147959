import { createReducer } from '../utils'
import {
  ADD_MENU_ITEM, HIDE_FILTER_LIST, HIDE_SEARCH_FIELD, REMOVE_MENU_ITEM, SET_SELECTED_FILTER,
  SET_SELECTED_MENU_ITEM, SHOW_FILTER_LIST, SHOW_SEARCH_FIELD, TOGGLE_TITLE
} from '../constants/HeaderTypes'
import objectAssign from 'object-assign'

const initialState = {
  showSearch: false,
  filterable_columns: [],
  showFilter: false,
  filterItems: [],
  selectedFilterValue: '',
  onFilterSelect: '',
  menuItems: [],
  selectedMenuItemValue: '',
  onMenuItemSelect: '',
  showTitle: true
}

export default createReducer(initialState, {
  [SHOW_SEARCH_FIELD]: (state, payload) => {
    return objectAssign({}, state, {
      showSearch: true,
      filterable_columns: payload.filterableColumns
    })
  },

  [HIDE_SEARCH_FIELD]: state => objectAssign({}, state, {
    showSearch: false,
    filterable_columns: []
  }),

  [SHOW_FILTER_LIST]: (state, payload) => objectAssign({}, state, {
    showFilter: true,
    filterItems: payload.items,
    onFilterSelect: payload.callback
  }),

  [HIDE_FILTER_LIST]: state => objectAssign({}, state, {
    showFilter: false
  }),

  [SET_SELECTED_FILTER]: (state, payload) => objectAssign({}, state, {
    selectedFilterValue: payload.text
  }),

  [ADD_MENU_ITEM]: (state, payload) => {
    let exists = false
    state.menuItems.map((menuItem, index) => {
      if (menuItem.title === payload.title) {
        state.menuItems[index].callback = payload.callback
        exists = true
      }
    })

    return objectAssign({}, state, {
      menuItems: (exists) ? state.menuItems : state.menuItems.concat({ title: payload.title, callback: payload.callback })
    })
  },

  [REMOVE_MENU_ITEM]: (state, payload) => {
    state.menuItems.map((menuItem, index) => {
      if (menuItem.title === payload.title) {
        state.menuItems.splice(index, 1)
      }
    })

    return objectAssign({}, state, {
      menuItems: state.menuItems
    })
  },

  [SET_SELECTED_MENU_ITEM]: (state, payload) => objectAssign({}, state, {
    selectedMenuItemValue: payload.text
  }),

  [TOGGLE_TITLE]: state => objectAssign({}, state, {
    showTitle: !state.showTitle
  })
})
