import PropTypes from 'prop-types'
import React from 'react'
import { Button } from '@material-ui/core'
import FormHelper from '../../businessLogic/formHelper'
import FieldWrapper from './FieldWrapper'

class InternalLink extends React.Component {
  render () {
    const { field, wrapperProps, onClick } = this.props

    return (
      <FieldWrapper
        label={FormHelper.decodeHTML(field.FieldName)}
        required={field.RequiredField}
        showDivider
        {...wrapperProps}
      >
        <Button
          onClick={onClick}
        >
          {FormHelper.decodeHTML(field.Value)}
        </Button>
      </FieldWrapper>
    )
  }
}

InternalLink.propTypes = {
  field: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  wrapperProps: PropTypes.object
}

export default InternalLink
