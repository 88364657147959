import PropTypes from 'prop-types'
import React from 'react'
import { Button, List } from '@material-ui/core'
import LaunchCodeListItem from './LaunchCodeListItem'
import { Add } from '@material-ui/icons'

class LaunchCodeList extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      launchCodeList: null
    }
  }

  componentDidMount () {
    this.setState({ launchCodeList: this.props.launchCodeList })
  }

  componentDidUpdate (prevProps) {
    if (this.props.launchCodeList !== prevProps.launchCodeList) { this.setState({ launchCodeList: this.props.launchCodeList }) }
  }

  addNewItem () {
    const newItem = {
      PurposeDescription: '',
      ProcessTemplateID: this.props.templateId
    }

    const newLaunchCodeList = this.state.launchCodeList.concat(newItem)

    this.props.onSaveList(newLaunchCodeList)
  }

  handleChange (index, item) {
    const launchCodeList = this.state.launchCodeList
    launchCodeList[index] = item

    this.props.onSaveList(launchCodeList)
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const { launchCodeList } = this.state
    const { onDelete, displayMessage } = this.props

    return (
      <div>
        {(!launchCodeList || !launchCodeList.length)
          ? <div style={{ margin: '10px 20px' }}>{language.translate('application.noLaunchCodes')}</div>
          : <div style={{ padding: '0px 20px', backgroundColor: palette.accent2Color }}>
            <List>
              {launchCodeList.map((item, index) => (
                <LaunchCodeListItem
                  item={item}
                  key={item.ID}
                  onRemoveField={() => onDelete(item)}
                  onSave={value => this.handleChange(index, value)}
                  style={{ backgroundColor: (index % 2 === 0) ? palette.accent2Color : palette.canvasColor }}
                  displayMessage={message => displayMessage(message)}
                />
              ))}
            </List>
          </div>}
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
          <Button
            onClick={this.addNewItem.bind(this)}
            color='primary'
            variant='contained'
            style={{ margin: '10px' }}
          >
            <Add />
            {language.translate('application.addItem')}
          </Button>
        </div>
      </div>
    )
  }
}

LaunchCodeList.propTypes = {
  templateId: PropTypes.string.isRequired,
  launchCodeList: PropTypes.array,
  onSaveList: PropTypes.func,
  onDelete: PropTypes.func,
  displayMessage: PropTypes.func
}

LaunchCodeList.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default LaunchCodeList
