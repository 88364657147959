import PropTypes from 'prop-types'
import React from 'react'
import FormHelper from '../../businessLogic/formHelper'
import objectAssign from 'object-assign'
import { Button, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Menu, MenuItem } from '@material-ui/core'
import { Add, MoreVert } from '@material-ui/icons'
import PublicFormEdit from './PublicFormEdit'
import CopyToClipboard from 'react-copy-to-clipboard'
import DeleteMenuItem from '../Layout/DeleteMenuItem'
import HelpIcon from '../Layout/HelpIcon'

class PublicFormList extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      isLoaded: false,
      editDialogOpen: false,
      editingItem: null,
      editingItemIndex: null,
      menuAnchor: null,
      menuAnchorIndex: null
    }
  }

  handleDelete (index) {
    const list = this.props.publicFormList

    this.props.onDelete(list[index])
  }

  handleSave (item, callback) {
    // TODO: editing an item updates the props directly instead of waiting for the api response to update them
    const list = this.props.publicFormList.slice(0)

    if (item.ID) {
      list[this.state.editingItemIndex] = objectAssign({}, item)
    } else {
      list.push(item)
    }

    this.props.onSaveList(list, (newList) => {
      if (callback) {
        callback(newList)
      }
    })
  }

  addNew () {
    const { language } = this.context
    const list = this.props.publicFormList

    const newItem = {
      ProcessTemplateID: this.props.templateId,
      ProcessTemplateFieldID_ToDisplay: '',
      ProcessTemplateFieldID_Required: '',
      DisplayHumanVerification: true,
      PublicDescription: language.translate('application.newPublicForm')
    }

    this.setState({
      editDialogOpen: true,
      editingItem: newItem,
      editingItemIndex: list.length
    }, () => {
      this.handleSave(newItem, (newList) => {
        this.setState({ editingItem: newList[newList.length - 1] })
      })
    })
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const { publicFormList, displayMessage, onDelete, onSaveList, onSaveLogo, onDeleteLogo } = this.props
    const { menuAnchor, menuAnchorIndex } = this.state

    return (
      <List style={{ padding: '0px 20px', backgroundColor: palette.accent2Color }}>
        {
          publicFormList.map((item, index) => (
            <CopyToClipboard
              text={`${window.location.origin}/public-form?token=${item.Token}`}
              key={index}
              onCopy={() => displayMessage('Form URL copied to clipboard')}
            >
              <ListItem button>
                <ListItemText
                  primary={FormHelper.decodeHTML((item.PublicDescription))}
                  secondary={language.translate('application.copyLinkToClipboard')}
                />
                <ListItemSecondaryAction>
                  {(onDelete || onSaveList)
                    ? <div>
                      <IconButton
                        onClick={(e) => {
                          this.setState({
                            menuAnchor: e.currentTarget,
                            menuAnchorIndex: index
                          })
                        }}><MoreVert /></IconButton>
                      <Menu
                        anchorEl={menuAnchor}
                        open={Boolean(menuAnchor) && menuAnchorIndex === index}
                        onClose={() => { this.setState({ menuAnchor: null, menuAnchorIndex: null }) }}
                        getContentAnchorEl={null}
                        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                      >
                        <MenuItem onClick={() => this.setState({
                          editDialogOpen: true,
                          editingItem: item,
                          editingItemIndex: index,
                          menuAnchor: null,
                          menuAnchorIndex: null
                        })}
                        >{language.translate('application.edit')}</MenuItem>
                        <DeleteMenuItem
                          onDelete={() => {
                            this.setState({ menuAnchor: null, menuAnchorIndex: null })
                            this.handleDelete(index)
                          }}
                        />
                      </Menu>
                    </div>
                    : null}
                </ListItemSecondaryAction>
              </ListItem>
            </CopyToClipboard>
          ))
        }
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Button
            onClick={this.addNew.bind(this)}
            color='primary'
            variant='contained'
            style={{ margin: '10px', display: (!onSaveList) && 'none' }}
          >
            <Add />
            {language.translate('application.add')}
          </Button>
          <HelpIcon helpUrl={'editing-a-process-template$PublicForms'} />
        </div>
        <PublicFormEdit
          publicFormItem={this.state.editingItem}
          processFields={this.props.processFields}
          onSave={this.handleSave.bind(this)}
          onSaveLogo={onSaveLogo}
          onDeleteLogo={onDeleteLogo}
          isOpen={this.state.editDialogOpen}
          onCloseRequest={() => this.setState({
            editDialogOpen: false,
            editingItem: null,
            editingItemIndex: null
          })}
        />
      </List>
    )
  }
}

PublicFormList.propTypes = {
  publicFormList: PropTypes.array,
  processFields: PropTypes.array,
  templateId: PropTypes.string,
  onSaveList: PropTypes.func,
  onDelete: PropTypes.func,
  onSaveLogo: PropTypes.func,
  onDeleteLogo: PropTypes.func,
  displayMessage: PropTypes.func.isRequired
}

PublicFormList.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default PublicFormList
