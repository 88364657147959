import PropTypes from 'prop-types'
import React from 'react'
import { List, ListItem, ListItemSecondaryAction, ListItemText, Divider, Switch } from '@material-ui/core'

class UserSecurity extends React.Component {
  shouldComponentUpdate (nextProps, nextState) {
    return this.props.user !== nextProps.user
  }

  render () {
    const { language, muiTheme: { palette } } = this.context
    const { user, onSave } = this.props

    return (
      <List style={{ padding: '0px 20px', backgroundColor: palette.accent2Color }}>
        <ListItem>
          <ListItemText primary={
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
              <div>{language.translate('application.viewTasksAssignedToMe')}</div>
              <div style={{ fontSize: '11px', marginLeft: '5px' }}>
                ({language.translate('application.alwaysEnabled').lcFirst()})
              </div>
            </div>
          }
          />
          <ListItemSecondaryAction>
            <Switch
              color='primary'
              checked
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary={language.translate('application.viewProcessesImParticipatingIn')} />
          <ListItemSecondaryAction>
            <Switch
              color='primary'
              checked={(user.AccessControl_ViewMyProcesses)}
              onChange={(obj, value) => {
                user.AccessControl_ViewMyProcesses = !user.AccessControl_ViewMyProcesses
                onSave(user)
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary={language.translate('application.viewOtherProcessesAccessToSee')} />
          <ListItemSecondaryAction>
            <Switch
              color='primary'
              checked={(user.AccessControl_ViewAllProcesses)}
              onChange={(obj, value) => {
                user.AccessControl_ViewAllProcesses = !user.AccessControl_ViewAllProcesses
                onSave(user)
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary={language.translate('application.changeTaskAssignments')} />
          <ListItemSecondaryAction>
            <Switch
              color='primary'
              checked={(user.AccessControl_ChangeTaskAssignments)}
              onChange={(obj, value) => {
                user.AccessControl_ChangeTaskAssignments = !user.AccessControl_ChangeTaskAssignments
                onSave(user)
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary={language.translate('application.editInstanceDiagrams')} />
          <ListItemSecondaryAction>
            <Switch
              color='primary'
              checked={(user.AccessControl_EditInstanceDiagrams)}
              onChange={(obj, value) => {
                user.AccessControl_EditInstanceDiagrams = !user.AccessControl_EditInstanceDiagrams
                onSave(user)
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary={language.translate('application.scheduleOrLaunchMultipleProcesses')} />
          <ListItemSecondaryAction>
            <Switch
              color='primary'
              checked={(user.AccessControl_BatchProcessing)}
              onChange={(obj, value) => {
                user.AccessControl_BatchProcessing = !user.AccessControl_BatchProcessing
                onSave(user)
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary={language.translate('application.restartInstanceTasks')} />
          <ListItemSecondaryAction>
            <Switch
              color='primary'
              checked={(user.AccessControl_RestartInstanceTasks)}
              onChange={(obj, value) => {
                user.AccessControl_RestartInstanceTasks = !user.AccessControl_RestartInstanceTasks
                onSave(user)
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary={language.translate('application.launchProcesses')} />
          <ListItemSecondaryAction>
            <Switch
              color='primary'
              checked={(user.AccessControl_InitiateProcesses)}
              onChange={(obj, value) => {
                user.AccessControl_InitiateProcesses = !user.AccessControl_InitiateProcesses
                onSave(user)
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary={language.translate('application.cancelProcesses')} />
          <ListItemSecondaryAction>
            <Switch
              color='primary'
              checked={(user.AccessControl_CancelProcesses)}
              onChange={(obj, value) => {
                user.AccessControl_CancelProcesses = !user.AccessControl_CancelProcesses
                onSave(user)
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary={language.translate('application.viewProcessReports')} />
          <ListItemSecondaryAction>
            <Switch
              color='primary'
              checked={(user.AccessControl_ViewProcessReports)}
              onChange={(obj, value) => {
                user.AccessControl_ViewProcessReports = !user.AccessControl_ViewProcessReports
                onSave(user)
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary={language.translate('application.createProcessTemplates')} />
          <ListItemSecondaryAction>
            <Switch
              color='primary'
              checked={(user.AccessControl_CreateProcessTemplates)}
              onChange={(obj, value) => {
                user.AccessControl_CreateProcessTemplates = !user.AccessControl_CreateProcessTemplates
                onSave(user)
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary={language.translate('application.editProcessTemplates')} />
          <ListItemSecondaryAction>
            <Switch
              color='primary'
              checked={(user.AccessControl_ManageProcessTemplates)}
              onChange={(obj, value) => {
                user.AccessControl_ManageProcessTemplates = !user.AccessControl_ManageProcessTemplates
                onSave(user)
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary={language.translate('application.editAccountInformation')} />
          <ListItemSecondaryAction>
            <Switch
              color='primary'
              checked={(user.AccessControl_ManageAccountInformation)}
              onChange={(obj, value) => {
                user.AccessControl_ManageAccountInformation = !user.AccessControl_ManageAccountInformation
                onSave(user)
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary={language.translate('application.editSubscriptionPlan')} />
          <ListItemSecondaryAction>
            <Switch
              color='primary'
              checked={(user.AccessControl_ManageSubscriptionLevel)}
              onChange={(obj, value) => {
                user.AccessControl_ManageSubscriptionLevel = !user.AccessControl_ManageSubscriptionLevel
                onSave(user)
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary={language.translate('application.editUserAccounts')} />
          <ListItemSecondaryAction>
            <Switch
              color='primary'
              checked={(user.AccessControl_ManageUsers)}
              onChange={(obj, value) => {
                user.AccessControl_ManageUsers = !user.AccessControl_ManageUsers
                onSave(user)
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    )
  }
}

UserSecurity.propTypes = {
  user: PropTypes.object,
  onSave: PropTypes.func
}

UserSecurity.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default UserSecurity
