import PropTypes from 'prop-types'
import React from 'react'
import { Avatar } from '@material-ui/core'
import { MenuItem } from 'material-ui'
import FormHelper from '../../businessLogic/formHelper'
import { get } from '../../actions/base'
import { FormsySelect } from 'formsy-material-ui'

class GroupUserSelect extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      groupValue: this.props.groupValue || '',
      userValue: this.initUserValue(),
      groups: [],
      users: [],
      dynamicGroupId: ''
    }
  }

  componentDidMount () {
    if (this.props.withDynamic) { this.fetchGroupsWithDynamic() } else { this.fetchGroups() }

    if (this.state.groupValue && this.state.groupValue !== '0' && !this.props.hideUserField) { this.fetchGroupUsers(this.state.groupValue) }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.groupValue !== this.props.groupValue) {
      this.setState({ groupValue: this.props.groupValue, users: [] },
        () => {
          if (!this.props.hideUserField) {
            this.fetchGroupUsers(this.props.groupValue)
          }
        })
    }

    if (prevProps.userValue !== this.props.userValue) {
      this.setState({ userValue: this.initUserValue() })
    }
  }

  initUserValue () {
    const { userValue, allowAllUsers, groupValue } = this.props

    if (userValue) { return userValue }
    if (allowAllUsers && groupValue) { return '0' }

    return ''
  }

  fetchGroups () {
    this.props.dispatch(get('usergroup/list', {
      onSuccess: (response) => {
        this.setState({
          groups: response.UserGroupList
        })
      }
    }))
  }

  fetchGroupsWithDynamic () {
    this.props.dispatch(get('usergroup/list/withdynamicassigntogroup', {
      onSuccess: (response) => {
        this.setState({
          dynamicGroupId: response.UserGroupList[0].ID,
          groups: response.UserGroupList
        })
      }
    }))
  }

  fetchGroupUsers (groupID) {
    if (!groupID || groupID === '0') {
      return false
    }

    const { afterUsersLoaded, userListEndpoint } = this.props

    let endpoint = (userListEndpoint) ? userListEndpoint(groupID) : `usergroup/${groupID}/user/list`

    this.props.dispatch(get(endpoint, {
      onSuccess: (response) => {
        this.setState({
          users: response.UserList
        }, () => {
          if (afterUsersLoaded) {
            afterUsersLoaded(response.UserList)
          }
        })
      },

      headers: [],
      isHandshake: null,
      isSecondary: true
    }))
  }

  handleGroupChange (event, groupValue, index) {
    let { hideUserField } = this.props
    this.setState({ groupValue, userValue: (!groupValue) ? '' : '0' })
    const { groups } = this.state

    if (!hideUserField) {
      this.fetchGroupUsers(groupValue)
    }

    if (!groupValue) { groupValue = '0' }

    if (this.props.onGroupChange) { this.props.onGroupChange(groupValue, groups[index - 1]) }
  }

  handleUserChange (event, userValue, index) {
    const { users } = this.state
    this.setState({ userValue })

    if (this.props.onUserChange) { this.props.onUserChange(userValue, users[index - 1]) }
  }

  getPicture (users, userID) {
    if (userID === '0') return 'https://res.cloudinary.com/dubj1ir57/image/upload/v1445263318/group.png'
    if (!userID) return 'https://res.cloudinary.com/dubj1ir57/image/upload/v1445263318/person.png'

    let picture = ''

    users.map((user) => {
      if (user.ID === userID) { picture = user.ProfilePicURL }
    })

    return picture
  }

  getValue () {
    return {
      UserGroupID: this.state.groupValue,
      UserID: this.state.userValue
    }
  }

  render () {
    const {
      name, required, allowAllUsers, groupValue, userValue, dispatch, onGroupChange, onUserChange,
      withDynamic, lockGroup, isSecondary, afterUsersLoaded, allowAllGroups, hideUserField, allUsersLabel, ...rest
    } = this.props
    const { dynamicGroupId } = this.state
    const { language } = this.context
    const userOptions = []

    const groupOptions = []

    if (allowAllGroups) {
      groupOptions.push(<MenuItem
        value=''
        key={'0'}
        primaryText={(this.state.groupValue === '') ? '' : language.translate('application.selectAGroup')}
      />)
    }

    if (allowAllUsers) {
      userOptions.push(<MenuItem
        value={'0'}
        key={'0'}
        primaryText={
          <div style={{ padding: '5px 0px 10px 5px' }}>
            {(this.state.groupValue && this.state.groupValue !== '0') ? allUsersLabel : ''}
          </div>
        }
        leftIcon={(this.state.groupValue === dynamicGroupId) ? null
          : <Avatar
            src={'https://res.cloudinary.com/dubj1ir57/image/upload/v1445263318/group.png'}
            style={{ height: '40px', width: '40px' }}
          />}
      />)
    }

    // Separate out ODS so a divider can be injected
    if (this.state.groups) {
      let userGroups = this.state.groups.filter((group) => (!group.IsOnDemand))
      let odsGroups = this.state.groups.filter((group) => (group.IsOnDemand))
      let odsGroupsGrouped = odsGroups.reduce((acc, group) => {
        const odsCompanyName = group.AccountID
        acc[odsCompanyName] = acc[odsCompanyName] || []
        acc[odsCompanyName].push(group)
        return acc
      }, {})

      userGroups.map((group) => {
        groupOptions.push(<MenuItem value={group.ID} key={group.ID} primaryText={group.GroupName} />)
      })

      if (odsGroups.length) {
        Object.keys(odsGroupsGrouped).sort().map((groupId, index) => {
          let groups = odsGroupsGrouped[groupId]

          groupOptions.push(
            <MenuItem
              style={{ color: 'rgba(0,0,0,0.6)' }}
              disabled
              value={'ODS'}
              key={`ODS-${groupId}`}
              primaryText={`-- ${language.translate('application.servicesBy')} ${groups[0].AccountName} --`} />)

          groups.map((group) => {
            groupOptions.push(<MenuItem value={group.ID} key={group.ID} primaryText={group.GroupName} />)
          })
        })
      }
    }

    if (this.state.users) {
      this.state.users.map((user) => {
        userOptions.push(
          <MenuItem
            value={user.ID}
            key={user.ID}
            primaryText={<div style={{
              padding: '5px 0px 10px 5px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}>{user.FullName}</div>}
            leftIcon={(this.state.groupValue === dynamicGroupId) ? null
              : <Avatar src={user.ProfilePicURL} style={{ height: '40px', width: '40px' }} />}
          />)
      })
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }} {...rest}>
        <div
          className='field-label'
          style={{
            fontSize: '14px',
            paddingTop: '10px'
          }}
        >{FormHelper.decodeHTML(name)}{(required) ? '*' : ''}</div>
        <div>
          <FormsySelect
            name={`${name}-group`}
            autoWidth
            disabled={lockGroup}
            value={this.state.groupValue}
            onChange={this.handleGroupChange.bind(this)}
            floatingLabelText={language.translate('application.group')}
            validationErrors={language.messages.validationErrors}
            required={required}
            fullWidth
          >
            {groupOptions}
          </FormsySelect>
          <br />
          {!hideUserField
            ? <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div style={{ flex: 3, width: '100%' }}>
                <FormsySelect
                  name={`${name}-users`}
                  value={this.state.userValue}
                  onChange={this.handleUserChange.bind(this)}
                  floatingLabelText={language.translate('application.user')}
                  inputStyle={{ width: '100%', maxWidth: '260px' }}
                  underlineStyle={{ width: '100%', maxWidth: '260px' }}
                  validationErrors={language.messages.validationErrors}
                  required={required}
                  autoWidth
                  fullWidth
                >
                  {userOptions}
                </FormsySelect>
              </div>
              {(this.state.groupValue !== dynamicGroupId)
                ? <div style={{ textAlign: 'right', flex: 1 }}>
                  <Avatar
                    src={this.getPicture(this.state.users, this.state.userValue)}
                    style={{ height: '60px', width: '60px', marginTop: '10px' }}
                  />
                </div>
                : null}
            </div>
            : ''
          }
        </div>
      </div>
    )
  }
}

GroupUserSelect.propTypes = {
  required: PropTypes.bool,
  groupValue: PropTypes.string,
  userValue: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  withDynamic: PropTypes.bool,
  onGroupChange: PropTypes.func,
  onUserChange: PropTypes.func,
  lockGroup: PropTypes.bool,
  allowAllUsers: PropTypes.bool,
  allowAllGroups: PropTypes.bool,
  isSecondary: PropTypes.bool,
  hideUserField: PropTypes.bool,
  afterUsersLoaded: PropTypes.func,
  userListEndpoint: PropTypes.func,
  allUsersLabel: PropTypes.string
}

GroupUserSelect.defaultProps = {
  allUsersLabel: 'All users in this group'
}

GroupUserSelect.contextTypes = {
  language: PropTypes.object
}

export default GroupUserSelect
