import PropTypes from 'prop-types'
import React from 'react'
import { Button, List } from '@material-ui/core'
import LaunchEmailListItem from './LaunchEmailListItem'
import { Add } from '@material-ui/icons'

class LaunchEmailList extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      launchEmailList: null
    }
  }

  componentDidMount () {
    this.setState({ launchEmailList: this.props.launchEmailList })
  }

  componentDidUpdate (prevProps) {
    if (this.props.launchEmailList !== prevProps.launchEmailList) { this.setState({ launchEmailList: this.props.launchEmailList }) }
  }

  addNewItem () {
    const newItem = {
      PurposeDescription: '',
      ProcessTemplateID: this.props.templateId
    }

    const newLaunchEmailList = this.state.launchEmailList.concat(newItem)

    this.props.onSaveList(newLaunchEmailList)
  }

  handleChange (index, item) {
    const launchEmailList = this.state.launchEmailList
    launchEmailList[index] = item

    this.props.onSaveList(launchEmailList)
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const { launchEmailList } = this.state
    const { onDelete, displayMessage } = this.props

    return (
      <div>
        {(!launchEmailList || !launchEmailList.length)
          ? <div style={{ margin: '10px 20px' }}>{language.translate('application.noLaunchEmails')}</div>
          : <div style={{ padding: '0px 20px', backgroundColor: palette.accent2Color }}>
            <List>
              {launchEmailList.map((item, index) => (
                <LaunchEmailListItem
                  item={item}
                  key={item.ID}
                  onRemoveField={() => onDelete(item)}
                  onSave={value => this.handleChange(index, value)}
                  style={{ backgroundColor: (index % 2 === 0) ? palette.accent2Color : palette.canvasColor }}
                  displayMessage={message => displayMessage(message)}
                />
              ))}
            </List>
          </div>}
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
          <Button
            onClick={this.addNewItem.bind(this)}
            color='primary'
            variant='contained'
            style={{ margin: '10px' }}
          >
            <Add />
            {language.translate('application.addItem')}
          </Button>
        </div>
      </div>
    )
  }
}

LaunchEmailList.propTypes = {
  templateId: PropTypes.string.isRequired,
  launchEmailList: PropTypes.array,
  onSaveList: PropTypes.func,
  onDelete: PropTypes.func,
  displayMessage: PropTypes.func
}

LaunchEmailList.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default LaunchEmailList
