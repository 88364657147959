import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { HOC } from 'formsy-react'
import SignatureCanvas from 'react-signature-canvas'
import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import CancelButton from './CancelButton'

class FormsySignature extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: this.props.defaultValue || this.props.value || '',
      showSignatureDialog: !!(props.autoFocus)
    }

    //componentWillMount migration start
    this.props.setValue(this.props.defaultValue || this.props.value || '')
    //componentWillMount migration end

  }

  // componentWillMount() {
  //   this.props.setValue(this.props.defaultValue || this.props.value || '')
  // }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.autoFocus && this.props.autoFocus) {
      this.setState({ showSignatureDialog: true })
    }
  }

  handleChange(event) {
    this.setState({
      value: event.value
    })

    if (this.props.onChange) this.props.onChange(event)
  }

  showSignDialogIfAllowed() {
    const { value } = this.state
    const { readOnly, isPublic, apiServer } = this.props
    const { user } = this.context

    if (!readOnly) {
      this.setState({ showSignatureDialog: true }, () => {
        const signature = value

        if (signature) {
          if (signature.ID && isPublic) {
            this.toDataUrl(`${signature.PublicURL}&token=${user.token}&email=${encodeURIComponent(user.userName)}&`, (base64) => { this.signaturePad.fromDataURL(base64) })
          } else if (signature.ID && !isPublic) {
            this.toDataUrl(`${apiServer}/open.aspx?filekey=${value.PrivateFileKey}&token=${user.token}&email=${encodeURIComponent(user.userName)}&`, (base64) => { this.signaturePad.fromDataURL(base64) })
          } else { this.signaturePad.fromDataURL(`data:image/png;base64,${signature.Base64EncodedFile}`) }
        }
      })
    }
  }

  toDataUrl(url, callback) {
    const xhr = new XMLHttpRequest()
    xhr.onload = function () {
      const reader = new FileReader()
      reader.onloadend = function () {
        callback(reader.result)
      }
      reader.readAsDataURL(xhr.response)
    }
    xhr.open('GET', url)
    xhr.responseType = 'blob'
    xhr.send()
  }

  _handleChange(e) {
    const { onChange } = this.props

    if (this.signaturePad.isEmpty()) {
      if (onChange) { onChange(null) }
      return
    }

    const signature = this.signaturePad.getTrimmedCanvas().toDataURL()

    const value = {
      FileName: 'signature.png',
      Base64EncodedFile: signature.split(',')[1],
      FileType: 'image/png'
    }

    this.setState({ value }, () => {
      if (onChange) { onChange(value) }
    })
  }

  render() {
    const {
      name,
      defaultValue, // eslint-disable-line no-unused-vars
      value, // eslint-disable-line no-unused-vars
      validationError, // eslint-disable-line no-unused-vars
      validationErrors, // eslint-disable-line no-unused-vars
      isRequired,
      setValue, // eslint-disable-line no-unused-vars
      setValidations, // eslint-disable-line no-unused-vars
      resetValue, // eslint-disable-line no-unused-vars
      getValue, // eslint-disable-line no-unused-vars
      hasValue, // eslint-disable-line no-unused-vars
      getErrorMessage, // eslint-disable-line no-unused-vars
      getErrorMessages, // eslint-disable-line no-unused-vars
      isFormDisabled, // eslint-disable-line no-unused-vars
      isValid, // eslint-disable-line no-unused-vars
      isPristine, // eslint-disable-line no-unused-vars
      isFormSubmitted, // eslint-disable-line no-unused-vars
      showRequired, // eslint-disable-line no-unused-vars
      showError, // eslint-disable-line no-unused-vars
      isValidValue, // eslint-disable-line no-unused-vars
      apiServer,
      isPublic,
      ...rest
    } = this.props

    const { language, muiTheme: { palette }, user } = this.context

    const signatureContentStyle = {}

    if (document.body.clientHeight < 400) { signatureContentStyle.transform = 'translate(0px, 0px)' }
    if (document.body.clientWidth < 900) { signatureContentStyle.width = `${document.body.clientWidth - 100}px` } else { signatureContentStyle.width = '800px' }

    return (
      <div>
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%'
            }}
          >
            {(value)
              ? (value.ID)
                ? <img
                  style={{ width: '90%', margin: '5px' }}
                  onClick={this.showSignDialogIfAllowed.bind(this)}
                  src={(isPublic) ? value.PublicURL + '&thumbnail=1&' : `${apiServer}/open.aspx?filekey=${value.PrivateFileKey}&token=${user.token}&email=${encodeURIComponent(user.userName)}&thumbnail=1&`}
                />
                : <img
                  style={{ width: '100%', margin: '5px' }}
                  onClick={this.showSignDialogIfAllowed.bind(this)}
                  src={`data:image/png;base64,${value.Base64EncodedFile}`}
                />
              : null}
          </div>
          {(!value)
            ? <div>
              <div
                style={{
                  height: '100px',
                  backgroundColor: palette.borderColor,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '10px'
                }}
                onClick={this.showSignDialogIfAllowed.bind(this)}
              >
                <div style={{ fontSize: '18px' }}>{language.translate('application.clickTapToSign')}</div>
              </div>
              {(isRequired())
                ? <div
                  style={{
                    color: palette.errorColor,
                    fontSize: '12px',
                    textAlign: 'center'
                  }}
                >
                  {language.messages.validationErrors.isDefaultRequiredValue}
                </div>
                : null}
            </div>
            : null}
        </div>
        {this.state.showSignatureDialog && <Dialog
          open
          onClose={() => this.setState({ showSignatureDialog: false })}
          PaperProps={{ style: { maxWidth: '800px' } }}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <DialogTitle
            style={{
              backgroundColor: palette.headerBackgroundColor
            }}
            disableTypography
          >
            <Typography
              variant='h6'
              style={{ color: palette.alternateTextColor }}>
              {name}
            </Typography>
          </DialogTitle>
          <DialogContent style={signatureContentStyle}>
            <div style={{ border: `1px solid ${palette.borderColor}`, marginTop: '20px', minWidth: '350px' }}>
              <SignatureCanvas
                canvasProps={{ style: { width: '100%', height: '100%', minHeight: '150px' } }}
                ref={(ref) => { this.signaturePad = ref }}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
              <Button
                onClick={() => {
                  this.signaturePad.clear()
                }}
                style={{ margin: '10px' }}
                variant='contained'
              >
                {language.translate('application.clear')}
              </Button>
              <Button
                onClick={() => {
                  this._handleChange()
                  this.setState({ showSignatureDialog: false })
                }}
                disabled={this.signaturePad && !this.signaturePad.getCanvas().toDataURL()}
                color='primary'
                style={{ margin: '10px' }}
                variant='contained'
              >
                {language.translate('application.done')}
              </Button>
              <CancelButton
                style={{ margin: '10px' }}
                onClick={() => this.setState({ showSignatureDialog: false })}
                variant='contained'
              />
            </div>
          </DialogContent>
        </Dialog>}
      </div>
    )
  }
}

FormsySignature.propTypes = {
  defaultValue: PropTypes.any,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  autoFocus: PropTypes.bool,
  value: PropTypes.any,
  validationError: PropTypes.string,
  validationErrors: PropTypes.object,
  setValue: PropTypes.func,
  isRequired: PropTypes.func,
  setValidations: PropTypes.func,
  resetValue: PropTypes.func,
  getValue: PropTypes.func,
  hasValue: PropTypes.func,
  getErrorMessage: PropTypes.func,
  getErrorMessages: PropTypes.func,
  isFormDisabled: PropTypes.func,
  isValid: PropTypes.func,
  isPristine: PropTypes.func,
  isFormSubmitted: PropTypes.func,
  isPublic: PropTypes.bool,
  showRequired: PropTypes.func,
  showError: PropTypes.func,
  isValidValue: PropTypes.func,
  screenWidth: PropTypes.number,
  apiServer: PropTypes.string
}

FormsySignature.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object,
  user: PropTypes.object
}

const mapStateToProps = state => ({
  screenWidth: state.application.screenWidth
})

export default connect(mapStateToProps)(HOC(FormsySignature))
