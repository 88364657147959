import PropTypes from 'prop-types'
import React from 'react'
import { CircularProgress, Dialog, DialogContent } from '@material-ui/core'
import TransitionGroup from 'react-addons-transition-group'
import WaitingMessage from './WaitingMessage'

class WaitingDialog extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      currentMessage: '',
      counter: 0,
      show: true
    }
  }

  componentDidMount () {
    this.cycleMessages()
  }

  cycleMessages () {
    const { messages } = this.props
    const numberOfMessages = messages.length
    const { counter } = this.state
    const pauseTime = this.props.pauseTime || 3000

    this.setState({
      currentMessage: messages[counter % numberOfMessages],
      counter: counter + 1
    })

    setTimeout(() => {
      this.setState({ show: true })
    }, 500)

    setTimeout(() => {
      this.setState({ show: false }, () => this.cycleMessages())
    }, pauseTime)
  }

  render () {
    const { currentMessage, show } = this.state

    return (
      <Dialog
        open
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogContent style={{ width: '350px' }}>
          <div style={{ height: '120px', overflow: 'hidden' }}>
            <CircularProgress className='loader' />
            <TransitionGroup>
              {(show)
                ? <WaitingMessage message={currentMessage} />
                : null}
            </TransitionGroup>
          </div>
        </DialogContent>
      </Dialog>
    )
  }
}

WaitingDialog.propTypes = {
  messages: PropTypes.array.isRequired,
  pauseTime: PropTypes.number
}

export default WaitingDialog
