import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get, post } from '../../actions/base'
import { showUndoMessage } from '../../actions/snackbar'
import {
  Button, Card, CardContent, CardHeader, Collapse, Divider, IconButton, List, ListItem, ListItemSecondaryAction,
  ListItemText,
  Switch, TextField, Typography
} from '@material-ui/core'
import { Icon, GeneratedIcon } from '../Icon'
import IconSelectorDialog from '../Icon/IconSelectorDialog'
import { Add, Check, Edit, ExpandMore } from '@material-ui/icons'
import { push, goBack } from 'react-router-redux'
import Autolinker from 'autolinker'
import UserListItem from './UserListItem'
import { Form } from 'formsy-react'
import { FormsyText } from 'formsy-material-ui'
import FormHelper from '../../businessLogic/formHelper'
import objectAssign from 'object-assign'
import RightPanelContent from '../Layout/RightPanelContent'

class GroupSettings extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      canSubmit: true,
      group: null,
      isLoaded: false,
      members: null,
      membersLoaded: false,
      showIconSelector: false,
      editGroupName: false,
      canSubmitGroupName: true,
      groupSettingsExpanded: false
    }
  }

  // MIGRATED TO componentDidMount
  // componentWillMount() {
  //   if (!this.props.groupId) { this.createGroup() } else { this.fetchAllData() }
  // }

  componentDidMount() {
    if (this.state.isLoaded === false) {
      if (!this.props.groupId) { this.createGroup() } else { this.fetchAllData() }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.groupId && prevProps.groupId !== this.props.groupId) {
      this.fetchAllData()
    }

    if (!this.props.groupId && prevProps.groupId) {
      this.createGroup()
    }
  }

  fetchAllData() {
    this.fetchGroup()
    this.fetchGroupUsers()
  }

  fetchGroup() {
    const { groupId } = this.props
    this.setState({ isLoaded: false })

    this.props.dispatch(get(`usergroup/${groupId}`, {
      onSuccess: (response) => {
        this.setState({
          group: response.UserGroup,
          isLoaded: true
        })
      }
    }))
  }

  createGroup() {
    const { language } = this.context

    const body = JSON.stringify({
      UserGroup: { GroupName: language.translate('application.newGroup') }
    })

    this.props.post('usergroup', body, {
      onSuccess: (response) => {
        this.props.push({
          pathname: this.context.location.pathname,
          query: objectAssign({},
            this.context.location.query,
            {
              ptype: 'group',
              pid: response.UserGroup.ID,
              gsid: response.UserGroup.ID
            })
        })
      }
    })
  }

  saveUserGroup(group) {
    const body = JSON.stringify({
      UserGroup: group
    })

    this.props.post(`usergroup/${this.state.group.ID}`, body, {
      onSuccess: (response) => {
        this.setState({
          group: response.UserGroup
        })
      }
    })
  }

  fetchGroupUsers() {
    const { groupId } = this.props

    if (!groupId || groupId === '0') {
      return false
    }

    this.props.dispatch(get(`usergroup/${groupId}/user/list`, {
      onSuccess: (response) => {
        this.setState({
          members: response.UserList,
          membersLoaded: true
        })
      }
    }))
  }

  addUserToGroup(userId) {
    const body = JSON.stringify({})

    this.props.post(`usergroup/${this.props.groupId}/adduser/${userId}`, body, {
      onSuccess: (response) => {
        this.fetchGroupUsers()
      }
    })
  }

  deleteGroup() {
    const { language } = this.context

    const body = JSON.stringify({})

    this.props.post(`usergroup/${this.props.groupId}/delete`, body, {
      onSuccess: (response) => {
        this.props.close()

        this.props.showUndoMessage(
          language.translate('application.userGroupRemoved'),
          () => {
            this.props.post(`usergroup/${this.props.groupId}/delete/undo`, body, {
              onSuccess: (response) => {
                this.props.goBack()
              }
            })
          }
        )
      }
    })
  }

  removeUserFromGroup(userId, userIndex) {
    const body = JSON.stringify({})

    if (userId) {
      this.props.post(`usergroup/${this.props.groupId}/removeuser/${userId}`, body, {
        onSuccess: (response) => {
          this.fetchGroupUsers()
        }
      })
    } else {
      let newMembers = JSON.parse(JSON.stringify(this.state.members))
      newMembers.splice(userIndex, 1)

      this.setState({ members: newMembers })
    }
  }

  generateNewMember() {
    const { members } = this.state

    members.push({})

    this.setState({ members })
  }

  openUserPanel(userId) {
    const { query } = this.context.location
    delete query.gsid

    this.props.push({
      pathname: this.context.location.pathname,
      query: objectAssign({},
        query,
        {
          ptype: 'user',
          pid: userId,
          usid: userId
        })
    })
  }

  render() {
    const palette = this.context.muiTheme.palette
    const { language, user } = this.context
    const { isLoaded, members, membersLoaded, group, showIconSelector, editGroupName, canSubmitGroupName, groupSettingsExpanded } = this.state

    return (
      <RightPanelContent
        closePanel={() => this.props.close()}
        title={
          (isLoaded)
            ? (editGroupName)
              ? <Form
                onValid={() => this.setState({ canSubmitGroupName: true })}
                onInvalid={() => this.setState({ canSubmitGroupName: false })}
              >
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  padding: '0px 20px'
                }}
                >
                  <FormsyText
                    required
                    name='group_name'
                    value={FormHelper.decodeHTML(group.GroupName)}
                    onChange={(event) => {
                      group.GroupName = event.currentTarget.value
                    }}
                    validationErrors={language.messages.validationErrors}
                    errorStyle={{ display: 'none' }}
                  />
                  <IconButton
                    onClick={() => {
                      this.setState({ editGroupName: false })
                      this.saveUserGroup(group)
                    }}
                    disabled={!canSubmitGroupName}
                  >
                    <Check nativeColor={palette.accent3Color} />
                  </IconButton>
                </div>
              </Form>
              : <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start'
              }}
              >
                <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {FormHelper.decodeHTML(group.GroupName)}
                </div>
                {group.AccountID === user.accountID &&
                  <IconButton
                    onClick={() => this.setState({ editGroupName: true })}
                  >
                    <Edit nativeColor={palette.accent3Color} fontSize='small' />
                  </IconButton>}
              </div>
            : null
        }
        titleStyle={{ paddingRight: '0px', paddingLeft: '15px' }}
        titleContainerStyle={{ width: '60%', justifyContent: 'center' }}
        leftToolbarAvatar={
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '5px' }}>
            {
              (isLoaded)
                ? (group.IconName)
                  ? <IconButton
                    style={{ padding: '0px', width: '40px', height: '40px' }}
                    onClick={() => this.setState({ showIconSelector: true })}
                  >
                    <Icon icon={group.IconName} size={40} />
                  </IconButton>
                  : <GeneratedIcon
                    className='clickable'
                    text={group.GroupName}
                    randomizer={group.ID}
                    onClick={() => this.setState({ showIconSelector: true })}
                  />
                : null}
            <div style={{
              fontSize: '10px',
              color: palette.accent2Color,
              float: 'left',
              position: 'absolute',
              backgroundColor: palette.disabledColor,
              top: '35px',
              whiteSpace: 'nowrap'
            }}
            >{language.translate('application.updateIcon')}
            </div>
          </div>
        }
        toolbarMenuItems={[
          (isLoaded)
            ? {
              onClick: () => this.deleteGroup(),
              label: language.translate('application.deleteGroup'),
              isDelete: true
            } : null
        ].filter(Boolean)}
      >
        {(isLoaded && membersLoaded)
          ? <div style={{ padding: '15px' }}>
            {(group.AccountID !== user.accountID &&
              <Card className='card' style={{ padding: '10px', textAlign: 'center' }}>
                <Typography variant='subtitle1'>
                  {language.translate('application.onDemandServicesNotEditable')}
                </Typography>
              </Card>)}
            {(group.GroupName !== language.translate('application.newGroup') && group.AccountID === user.accountID &&
              <Card className='card'>
                <CardHeader
                  title={language.translate('application.groupSettings')}
                  titleTypographyProps={{ style: { fontSize: '20px' } }}
                  action={
                    <IconButton
                      style={{
                        transform: (groupSettingsExpanded) ? 'rotate(180deg)' : 'rotate(0deg)',
                        marginLeft: 'auto'
                      }}
                      onClick={() => this.setState({ groupSettingsExpanded: !groupSettingsExpanded })}
                      aria-expanded={groupSettingsExpanded}
                      aria-label='Show more'
                    >
                      <ExpandMore />
                    </IconButton>
                  }
                />
                <Collapse in={groupSettingsExpanded} timeout='auto' unmountOnExit>
                  <CardContent style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                    <List>
                      <TextField
                        name='description'
                        label={language.translate('application.groupDescription')}
                        multiline
                        rowsMax='3'
                        fullWidth
                        style={{ margin: '10px', paddingRight: '20px' }}
                        defaultValue={group.GroupDescription}
                        disabled={(group.AccountID !== user.accountID)}
                        onBlur={(e) => {
                          group.GroupDescription = e.currentTarget.value
                          this.setState({ group }, () => {
                            this.saveUserGroup(group)
                          })
                        }}
                      />
                    </List>
                  </CardContent>
                </Collapse>
              </Card>
            )}
            {(group.AccountID !== user.accountID) &&
              <Card className='card'>
                <CardContent style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                  <Typography variant='body2'>
                    {Autolinker.link(group.GroupDescription)}
                  </Typography>
                </CardContent>
              </Card>
            }
            {(members.length > 0 &&
              <Card className='card'>
                <CardContent>
                  <List style={{ padding: '0px' }}>
                    {members.map((member, index) => (
                      React.Children.toArray([
                        <UserListItem
                          key={index}
                          member={member}
                          removeUserFromGroup={(userId) => { this.removeUserFromGroup(userId, index) }}
                          addUserToGroup={this.addUserToGroup.bind(this)}
                          onMemberSelect={this.openUserPanel.bind(this)}
                          disableEdit={(group.AccountID !== user.accountID)}
                        />,
                        (index < members.length - 1) ? <Divider key={member.ID} /> : null
                      ])
                    ))}
                  </List>
                </CardContent>
              </Card>)}
            {group.AccountID === user.accountID &&
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                <Button
                  onClick={this.generateNewMember.bind(this)}
                  color='primary'
                  variant='contained'
                >
                  <Add />
                  {language.translate('application.addMemberToGroup')}
                </Button>
              </div>}
            <IconSelectorDialog
              onRequestClose={() => this.setState({ showIconSelector: false })}
              selectedIcon={group.IconName}
              open={showIconSelector}
              onSelect={(icon) => {
                group.IconName = icon

                this.saveUserGroup(group)

                this.setState({
                  showIconSelector: false
                })
              }}
            />
          </div>
          : null}
      </RightPanelContent>
    )
  }
}

GroupSettings.propTypes = {
  groupId: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  showUndoMessage: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired
}

GroupSettings.contextTypes = {
  location: PropTypes.object,
  language: PropTypes.object,
  muiTheme: PropTypes.object,
  user: PropTypes.object
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  dispatch,
  post: bindActionCreators(post, dispatch),
  get: bindActionCreators(get, dispatch),
  push: bindActionCreators(push, dispatch),
  showUndoMessage: bindActionCreators(showUndoMessage, dispatch),
  goBack: bindActionCreators(goBack, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(GroupSettings)
