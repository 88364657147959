import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import theme from '../../styles/theme'

const newTheme = JSON.parse(JSON.stringify(theme))
newTheme.palette.primary.main = 'rgb(242, 102, 89)'
const cancelButtonTheme = createMuiTheme(newTheme)

class CancelButton extends Component {
  render () {
    const { ...rest } = this.props
    const { language } = this.context

    return (
      <MuiThemeProvider
        theme={cancelButtonTheme}>
        <Button
          {...rest}
          color='primary'
        >
          {language.translate('application.cancel')}
        </Button>
      </MuiThemeProvider>
    )
  }
}

CancelButton.propTypes = {}

CancelButton.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default CancelButton
