import {
  ADD_PENDING_MESSAGE, HIDE_SNACKBAR_REQUEST, REMOVE_FIRST_PENDING_MESSAGE, SHOW_SNACKBAR_REQUEST
} from '../constants/SnackbarActionTypes'
import * as languages from '../lang'

export function showSnackbar (message) {
  // if message is string convert it to object
  const messageObject = {
    message: message.message || message,
    action: message.action || null,
    actionEvent: message.actionEvent || null,
    duration: (message.duration === 0) ? 0 : (message.duration) ? message.duration : 4000
  }

  return function (dispatch, state) {
    if (state().snackbar.isOpen && !messageIsPending(messageObject.message, state().snackbar.pending) && !messageCurrentlyShowing(messageObject.message, state().snackbar)) { dispatch(addPendingSnackbarMessage(messageObject)) } else { dispatch(showSnackbarRequest(messageObject)) }
  }
}

function messageIsPending (message, pendingMessages) {
  return (pendingMessages.filter(pendingMessage => (pendingMessage === message)).length)
}

function messageCurrentlyShowing (message, snackbar) {
  return (message === snackbar.message.message && snackbar.isOpen === true)
}

export function addPendingSnackbarMessage (message) {
  return {
    type: ADD_PENDING_MESSAGE,
    payload: {
      message
    }
  }
}

export function showSnackbarRequest (message) {
  return {
    type: SHOW_SNACKBAR_REQUEST,
    payload: {
      message
    }
  }
}

export function removeFirstPendingMessage () {
  return {
    type: REMOVE_FIRST_PENDING_MESSAGE
  }
}

export function hideSnackbar () {
  return function (dispatch, state) {
    dispatch(hideSnackbarRequest())

    if (state().snackbar.pending.length) {
      setTimeout(() => {
        const nextMessage = state().snackbar.pending[0]
        dispatch(showSnackbar(nextMessage))
        dispatch(removeFirstPendingMessage())
      }, 300)
    }
  }
}

export function hideSnackbarRequest () {
  return {
    type: HIDE_SNACKBAR_REQUEST
  }
}

export function showUndoMessage (message, callback, duration = 20000) {
  return function (dispatch, state) {
    const action = languages[state().auth.language].application.undo
    dispatch(showSnackbar({ message, action, actionEvent: callback, duration }))
  }
}
