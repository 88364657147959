import { createReducer } from '../utils'
import { CLEAR_STEPS, SET_JOYRIDE_OBJECT, SET_STEPS } from '../constants/JoyrideTypes'
import objectAssign from 'object-assign'

const initialState = {
  overlay: true,
  type: 'continuous',
  steps: [],
  joyride: {}
}

export default createReducer(initialState, {
  [SET_STEPS]: (state, payload) => objectAssign({}, state, {
    steps: payload.steps
  }),

  [CLEAR_STEPS]: state => objectAssign({}, state, {
    steps: []
  }),

  [SET_JOYRIDE_OBJECT]: (state, payload) => objectAssign({}, state, {
    joyride: payload.joyride
  })
})
