import PropTypes from 'prop-types'
import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import {
  Button, Divider, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Menu, TextField, Tooltip
} from '@material-ui/core'
import { Add, MoreVert } from '@material-ui/icons'
import moment from 'moment'
import FormHelper from '~/businessLogic/formHelper'
import DeleteMenuItem from '../Layout/DeleteMenuItem'

class ApplicationPasswords extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      description: '',
      menuAnchor: null,
      menuAnchorIndex: null
    }
  }

  handleDelete (index) {
    const list = this.props.applicationPasswords

    this.props.onDelete(list[index])
  }

  handleSave () {
    const list = this.props.applicationPasswords.slice(0)

    const item = {
      UserID: this.props.userId,
      Description: this.state.description
    }

    list.push(item)

    this.props.onSaveList(list)

    this.setState({ description: '' })
  }

  render () {
    const { palette } = this.context.muiTheme
    const { applicationPasswords, showSnackbar } = this.props
    const { description, menuAnchor, menuAnchorIndex } = this.state
    const { user, language } = this.context

    return (
      <List style={{ padding: '10px 20px', backgroundColor: palette.accent2Color }}>
        {
          applicationPasswords.map((item, index) => (
            React.Children.toArray([
              <CopyToClipboard
                key={index}
                text={item.Password}
                onCopy={() => showSnackbar(language.translate('application.applicationPasswordCopiedToClipboard'))}
              >
                <ListItem button>
                  <Tooltip title={language.translate('application.copyLinkToClipboard')}>
                    <ListItemText
                      primary={FormHelper.decodeHTML(item.Description)}
                      secondary={
                        <p style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          <span style={{ color: palette.textColor }}>{item.Password}</span><br />
                          {language.translate('application.lastUsed')}: {(item.LastUsedDate_Local) ? moment(item.LastUsedDate_Local).format((user.dateFormat) ? `${user.dateFormat} [at] h:mma` : 'YYYY-MM-DD [at] h:mma') : 'never'}
                        </p>}
                      secondaryTypographyProps={{ component: 'div' }}
                    />
                  </Tooltip>
                  <ListItemSecondaryAction>
                    <IconButton onClick={(e) => {
                      this.setState({
                        menuAnchor: e.currentTarget,
                        menuAnchorIndex: index
                      })
                    }}><MoreVert /></IconButton>
                    <Menu
                      anchorEl={menuAnchor}
                      open={Boolean(menuAnchor) && menuAnchorIndex === index}
                      onClose={() => { this.setState({ menuAnchor: null, menuAnchorIndex: null }) }}
                      getContentAnchorEl={null}
                      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    >
                      <DeleteMenuItem
                        onDelete={() => {
                          this.setState({ menuAnchor: null, menuAnchorIndex: null })
                          this.handleDelete(index)
                        }}
                      />
                    </Menu>
                  </ListItemSecondaryAction>
                </ListItem>
              </CopyToClipboard>,
              (index < applicationPasswords.length - 1) ? <Divider key={item.ID} /> : null
            ])

          ))
        }
        <ListItem style={{ paddingTop: '0px', border: `1px solid ${palette.borderColor}` }}>
          <ListItemText>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              paddingTop: '10px'
            }}
            >
              <div>{language.translate('application.generateNewApplicationPassword')}</div>
              <TextField
                name='description'
                label={language.translate('application.description')}
                value={this.state.description}
                style={{ marginTop: '10px' }}
                onChange={(e) => {
                  this.setState({ description: e.currentTarget.value })
                }}
              />
              <Button
                onClick={this.handleSave.bind(this)}
                disabled={(!description)}
                color='primary'
                style={{ marginTop: '10px' }}
                variant='contained'
              >
                <Add />
                {language.translate('application.generate')}
              </Button>
            </div>
          </ListItemText>
        </ListItem>
      </List>
    )
  }
}

ApplicationPasswords.propTypes = {
  applicationPasswords: PropTypes.array,
  userId: PropTypes.string.isRequired,
  onSaveList: PropTypes.func,
  onDelete: PropTypes.func,
  showSnackbar: PropTypes.func
}

ApplicationPasswords.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object,
  user: PropTypes.object
}

export default ApplicationPasswords
