import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get } from '../../actions/base'
import { Card, CardContent, CardHeader, CircularProgress, Table, TableBody, TableRow, TableCell } from '@material-ui/core'
import Masonry from 'react-masonry-component'
import BarCard from './BarCard'
import AreaCard from './AreaCard'

const masonryOptions = {
  transitionDuration: '0.0s'
}

class Task extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      templateId: props.templateId,
      taskId: props.taskId,
      averageResponse: [],
      averageResponseLoaded: false,
      completedCount: [],
      completedCountLoaded: false,
      userAverageResponseTime: [],
      userAverageResponseTimeLoaded: false,
      usersResponse: [],
      usersResponseLoaded: false,
      instanceCount: null,
      instanceCountLoaded: false,
      task: null,
      taskLoaded: false,
      template: null,
      templateLoaded: false,
      responseFrequency: [],
      responseFrequencyLoaded: false
    }
  }

  componentDidMount () {
    this.fetchData()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.templateId && this.props.templateId !== prevProps.templateId) {
      this.setState({ templateId: this.props.templateId }, this.fetchData)
    }

    if (this.props.taskId && this.props.taskId !== prevProps.taskId) {
      this.setState({ taskId: this.props.taskId }, this.fetchData)
    }

    if (this.props.endDate !== prevProps.endDate ||
      this.props.startDate !== prevProps.startDate ||
      this.props.by !== prevProps.by ||
      this.props.time !== prevProps.time) {
      this.fetchData()
    }
  }

  fetchData () {
    const { startDate, endDate, by, time, taskId, templateId } = this.props
    const dateFilter = this.props.parseDateFilter(startDate, endDate)

    if (!templateId || !taskId) {
      return
    }

    this.fetchTemplateData(`/task/${taskId}/report/averageresponsetime/in${time}/by${by}${dateFilter}`, 'averageResponse')
    this.fetchTemplateData(`/task/${taskId}/report/completedcount/bymonth${dateFilter}`, 'completedCount')
    this.fetchTemplateData(`/task/${taskId}/report/averageresponsetime/in${time}/byuser${dateFilter}`, 'userAverageResponseTime')
    this.fetchTemplateData(`/task/${taskId}/report/responsecount/byuser${dateFilter}`, 'usersResponse')
    this.fetchTemplateData(`/task/${taskId}`, 'task', 'ProcessTemplateTask')
    this.fetchTemplateData('', 'template', 'ProcessTemplate')
    this.fetchTemplateData(`/task/${taskId}/report/responsefrequency/byresponse${dateFilter}`, 'responseFrequency')
  }

  fetchTemplateData (endpoint, stateAttribute, responseAttribute = 'ReportSeriesList') {
    const { templateId } = this.props

    this.setState({ [`${stateAttribute}Loaded`]: false })

    this.props.get(`processtemplate/${templateId}${endpoint}`, {
      onSuccess: (response) => {
        this.setState({
          [stateAttribute]: response[responseAttribute],
          [`${stateAttribute}Loaded`]: true
        })
      }
    })
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const {
      averageResponse, averageResponseLoaded, completedCount, completedCountLoaded, userAverageResponseTime,
      userAverageResponseTimeLoaded, usersResponse, usersResponseLoaded, task, taskLoaded, template,
      templateLoaded, responseFrequency, responseFrequencyLoaded
    } = this.state
    const { mainContentWidth, time, handleUserClick } = this.props

    const styles = {
      cardStyles: {
        width: (mainContentWidth < 800) ? '95%' : '45%',
        margin: '10px'
      },
      processInfoTableRow: {
        border: 'none',
        height: '30px'
      },
      processInfoTableColumn: {
        textAlign: 'right',
        width: '110px',
        height: '30px'
      }
    }

    return (
      <Masonry options={masonryOptions}>
        <Card style={styles.cardStyles}>
          <CardHeader
            title={language.translate('application.processInformation')}
          />
          <CardContent>
            {(taskLoaded && templateLoaded)
              ? <Table>
                <TableBody>
                  <TableRow style={styles.processInfoTableRow}>
                    <TableCell style={styles.processInfoTableColumn}>
                      {language.translate('application.process')}:
                    </TableCell>
                    <TableCell>
                      {template.Title}
                    </TableCell>
                  </TableRow>
                  <TableRow style={styles.processInfoTableRow}>
                    <TableCell style={styles.processInfoTableColumn}>
                      {language.translate('application.task')}:
                    </TableCell>
                    <TableCell>
                      {task.TaskText}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              : <CircularProgress className='loader' />}
          </CardContent>
        </Card>

        <AreaCard
          title={language.translate('application.averageCompletionTimeIn', [time])}
          subtitle={language.translate('application.averageCompletionTimeInMessage')}
          data={averageResponse}
          isDataLoaded={averageResponseLoaded}
          tooltipPrefix={time}
          mainContentWidth={mainContentWidth}
        />

        <AreaCard
          title={language.translate('application.completedByMonth')}
          subtitle={language.translate('application.completedByMonthMessage')}
          data={completedCount}
          isDataLoaded={completedCountLoaded}
          tooltipPrefix={language.translate('application.executions')}
          mainContentWidth={mainContentWidth}
          fillColor={palette.primary2Color}
        />

        <BarCard
          title={language.translate('application.averageResponseTimeIn', [time])}
          subtitle={language.translate('application.averageResponseTimeInMessage')}
          data={userAverageResponseTime}
          isDataLoaded={userAverageResponseTimeLoaded}
          onClick={handleUserClick}
          tooltipPrefix={time}
          mainContentWidth={mainContentWidth}
        />

        <BarCard
          title={language.translate('application.responseCountByUser')}
          subtitle={language.translate('application.responseCountByUserMessage')}
          data={usersResponse}
          isDataLoaded={usersResponseLoaded}
          onClick={handleUserClick}
          tooltipPrefix={language.translate('application.executions')}
          mainContentWidth={mainContentWidth}
        />

        <BarCard
          title={language.translate('application.responseFrequency')}
          subtitle={language.translate('application.responseFrequencyMessage')}
          data={responseFrequency}
          isDataLoaded={responseFrequencyLoaded}
          tooltipPrefix={language.translate('application.percentage')}
          mainContentWidth={mainContentWidth}
          formatXTick={data => `${data}%`}
        />
      </Masonry>
    )
  }
}

Task.propTypes = {
  templateId: PropTypes.string,
  taskId: PropTypes.string,
  get: PropTypes.func.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  by: PropTypes.string,
  time: PropTypes.string,
  parseDateFilter: PropTypes.func.isRequired,
  mainContentWidth: PropTypes.number,
  handleUserClick: PropTypes.func.isRequired
}

Task.contextTypes = {
  muiTheme: PropTypes.object,
  location: PropTypes.object,
  language: PropTypes.object
}

const mapStateToProps = state => ({
  mainContentWidth: state.application.mainContentWidth
})

const mapDispatchToProps = dispatch => ({
  get: bindActionCreators(get, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Task)
