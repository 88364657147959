import PropTypes from 'prop-types'
import React from 'react'
import TinyMCEInput from 'react-tinymce-input'
import FormHelper from '../../businessLogic/formHelper'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { Button } from '@material-ui/core'
import { Input } from '@material-ui/icons'
import FieldTokenList from '../ProcessTemplate/FieldTokenList'
import FullScreenEditor from './FullScreenEditor'

class HTMLEditor extends React.Component {
  constructor(props) {
    super(props)

    this.state = ({
      value: this.props.value,
      editor: null,
      showTokenDialog: false,
      isFullScreen: false
    })

    //componentWillMount migration start
    if (window.tinymce) {
      window.tinymce.PluginManager.add('pplan', (activeEditor) => {
        activeEditor.addButton('fullscreen', {
          icon: 'mce-ico mce-i-fullscreen',
          onclick: function () {
            this.setState({ isFullScreen: true })
          }.bind(this)
        })

        this.setState({
          editor: activeEditor
        })
      })
    }
    //componentWillMount migration end

  }

  // componentWillMount () {
  //   if (window.tinymce) {
  //     window.tinymce.PluginManager.add('pplan', (activeEditor) => {
  //       activeEditor.addButton('fullscreen', {
  //         icon: 'mce-ico mce-i-fullscreen',
  //         onclick: function () {
  //           this.setState({ isFullScreen: true })
  //         }.bind(this)
  //       })

  //       this.setState({
  //         editor: activeEditor
  //       })
  //     })
  //   }
  // }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.value !== prevProps.value) { this.setState({ value: this.props.value }) }
  }

  handleChange(e) {
    if (e) { this.props.onBlur(e.target.getContent()) }
  }

  render() {
    const palette = this.context.muiTheme.palette
    const { labelText, tokens, onBlur, toolbar } = this.props
    const { value, editor, showTokenDialog, isFullScreen } = this.state

    if (!window.tinymce) { return null }

    return (
      <div style={{
        width: '100%',
        margin: '10px 2px 40px'
      }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div style={{
            color: 'rgba(0, 0, 0, 0.45)',
            fontWeight: '700',
            fontSize: '12px',
            fontFamily: '"Source Sans Pro", Roboto, sans-serif',
            marginBottom: '5px'
          }}
          >{labelText}</div>
          <Button
            onClick={() => {
              this.setState({
                showTokenDialog: true
              })
            }}
          >
            <Input nativeColor={palette.accent4Color} style={{ transform: 'rotate(180deg)' }} />
          </Button>
        </div>
        <TinyMCEInput
          value={value}
          tinymceConfig={{
            menubar: false,
            relative_urls: false,
            document_base_url: null,
            convert_urls: false,
            statusbar: false,
            plugins: 'link image code textcolor colorpicker pplan table',
            toolbar: toolbar || 'fontsizeselect fontselect forecolor backcolor | bold italic | code fullscreen',
            fontsize_formats: '8px 10px 12px 14px 18px 24px 36px',
            content_style: `.field-token {border-radius: 10px; padding: 0px 5px; background-color: ${fade(palette.primary1Color, 0.25)};} .invalid-token {background-color: ${fade(palette.errorColor, 0.25)};}`,
            height: 250
          }}
          onChange={value => this.setState({ value })}
          onBlur={this.handleChange.bind(this)}
        />
        <FieldTokenList
          tokens={tokens}
          open={showTokenDialog}
          close={() => this.setState({ showTokenDialog: false })}
          onSelect={(tokenValue) => {
            const tokenElement = FormHelper.decodeHTML(tokenValue)
            editor.execCommand('mceInsertContent', false, `<span class="field-token" contenteditable="false">${tokenElement}</span>`)

            this.setState({
              showTokenDialog: false
            })
          }}
        />
        {(isFullScreen)
          ? <FullScreenEditor
            title={labelText}
            editor={editor}
            tokens={tokens}
            onRequestClose={(html) => {
              editor.setContent(html)
              onBlur(html)
              this.setState({ isFullScreen: false })
            }}
            open={isFullScreen}
          />
          : null}
      </div>
    )
  }
}

HTMLEditor.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  toolbar: PropTypes.string,
  children: PropTypes.object,
  tokens: PropTypes.array,
  onBlur: PropTypes.func
}

HTMLEditor.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default HTMLEditor
