import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get, post } from '~/actions/base'
import { push } from 'react-router-redux'
import {
  Card, CircularProgress, Button, Divider, List
} from '@material-ui/core'
import { Add } from '@material-ui/icons'
import RightPanelContent from '~/components/Layout/RightPanelContent'
import { showUndoMessage } from '~/actions/snackbar'
import OfflineDocumentListItem from './OfflineDocumentListItem'

class OfflineDocumentTemplates extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      documentTemplates: [],
      documentTemplatesLoaded: false,
      fieldTokens: {},
      fieldTokensLoaded: false
    }
  }

  componentDidMount () {
    this.fetchDocumentTemplates()
    this.fetchDocumentTemplateTokens()
  }

  fetchDocumentTemplates () {
    this.props.get('documenttemplate/list', {
      onSuccess: (response) => {
        this.setState({
          documentTemplates: response.DocumentTemplateList,
          documentTemplatesLoaded: true
        })
      }
    })
  }

  fetchDocumentTemplateTokens () {
    this.props.get('documenttemplate/fieldtoken/list', {
      onSuccess: (response) => {
        this.setState({
          fieldTokens: response.TextValueList,
          fieldTokensLoaded: true
        })
      }
    })
  }

  addNewDocumentTemplate () {
    const { documentTemplates } = this.state
    const newItem = {
      TemplateTitle: ''
    }

    const newDocumentTemplates = documentTemplates.concat(newItem)

    this.setState({ documentTemplates: newDocumentTemplates })
  }

  saveDocumentTemplate (index, template) {
    const { documentTemplates } = this.state
    const endpoint = (!template.ID) ? 'documenttemplate' : `documenttemplate/${template.ID}`

    const body = JSON.stringify({
      DocumentTemplate: template
    })

    this.props.post(endpoint, body, {
      onSuccess: (response) => {
        documentTemplates[index] = response.DocumentTemplate

        this.setState({ documentTemplates })
      }
    })
  }

  deleteDocumentTemplate (index, item) {
    const { language } = this.context
    const body = JSON.stringify({})

    if (!item.ID) {
      const { documentTemplates } = this.state
      documentTemplates.splice(index, 1)

      this.setState({ documentTemplates })
    } else {
      this.props.post(`documenttemplate/${item.ID}/delete`, body, {
        onSuccess: (response) => {
          this.fetchDocumentTemplates()

          this.props.showUndoMessage(
            language.translate('application.sopTemplateRemoved'),
            () => {
              this.props.post(`documenttemplate/${item.ID}/delete/undo`, body, {
                onSuccess: (response) => {
                  this.fetchDocumentTemplates()
                }
              })
            }
          )
        }
      })
    }
  }

  updateField (fieldName, value) {
    const { editingItemIndex, documentTemplates } = this.state

    documentTemplates[editingItemIndex][fieldName] = value

    this.setState({ documentTemplates })
  }

  render () {
    const { language } = this.context
    const { documentTemplates, documentTemplatesLoaded, fieldTokens, fieldTokensLoaded } = this.state
    const { close } = this.props

    return (
      <RightPanelContent
        title={language.translate('application.sopTemplate', [], true)}
        closePanel={() => close()}
      >
        {(documentTemplatesLoaded)
          ? <div style={{ padding: '15px' }}>
            <Card className='card'>
              <List style={{ padding: '0px' }}>
                {documentTemplates.map((template, index) => (
                  React.Children.toArray([
                    <OfflineDocumentListItem
                      key={template.ID || index}
                      item={template}
                      onDelete={template => this.deleteDocumentTemplate(index, template)}
                      onSave={template => this.saveDocumentTemplate(index, template)}
                      fieldTokens={fieldTokens}
                    />,
                    (index < documentTemplates.length - 1) ? <Divider key={`${documentTemplates.ID}-div`} /> : null
                  ])
                ))}
              </List>
            </Card>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
              <Button
                onClick={this.addNewDocumentTemplate.bind(this)}
                color='primary'
                variant='contained'
              >
                <Add />
                {language.translate('application.add')}
              </Button>
            </div>
          </div>
          : null}
      </RightPanelContent>
    )
  }
}

OfflineDocumentTemplates.propTypes = {
  dispatch: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  user: PropTypes.object,
  push: PropTypes.func.isRequired,
  showUndoMessage: PropTypes.func.isRequired
}

OfflineDocumentTemplates.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object,
  location: PropTypes.object
}

const mapStateToProps = state => ({
  user: state.auth
})

const mapDispatchToProps = dispatch => ({
  dispatch,
  get: bindActionCreators(get, dispatch),
  post: bindActionCreators(post, dispatch),
  push: bindActionCreators(push, dispatch),
  showUndoMessage: bindActionCreators(showUndoMessage, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(OfflineDocumentTemplates)
