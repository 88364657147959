import PropTypes from 'prop-types'
import React from 'react'
import { Button, List } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import CustomFieldInstanceFilter from './CustomFieldInstanceFilter'

class CustomFieldInstanceFilterList extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      filters: []
    }
  }

  componentDidMount () {
    this.parseFilters(this.props.filters)
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.filters !== this.props.filters) {
      this.parseFilters(this.props.filters)
    }
  }

  parseFilters (filters) {
    let filterArray = filters.split('&')

    filterArray = filterArray.map((filterString) => {
      return filterString.split('=')[1]
    })

    filterArray = filterArray.filter(Boolean)

    this.setState({ filters: filterArray })
  }

  onChange (newValue, editingIndex) {
    const { onChange } = this.props
    let { filters } = this.state

    filters[editingIndex] = newValue

    filters = filters.filter(Boolean)

    this.setState({ filters }, () => {
      filters = filters.map((filter, index) => {
        return `filter${index + 1}=${filter}`
      })

      onChange(filters.join('&'))
    })
  }

  render () {
    const { processFields } = this.props
    const { filters } = this.state
    const { language, muiTheme: { palette } } = this.context

    return (
      <div style={{ padding: '0px 10px', backgroundColor: palette.accent2Color }}>
        <List>
          {(filters.length)
            ? filters.map((filter, index) => {
              return (<CustomFieldInstanceFilter
                value={filter}
                key={filter}
                processFields={processFields}
                onChange={(value) => this.onChange(value, index)}
                onRemoveField={() => this.onChange(null, index)}
              />)
            })
            : <div style={{ margin: '10px' }}>{language.translate('application.noFiltersToShow')}</div>}
        </List>
        <Button
          onClick={() => {
            filters.push('')
            this.setState({ filters })
          }}
          color='primary'
          style={{ margin: '10px' }}
          variant='contained'
        >
          <Add />
          {language.translate('application.add')}
        </Button>
      </div>
    )
  }
}

CustomFieldInstanceFilterList.propTypes = {
  filters: PropTypes.string,
  processFields: PropTypes.array,
  onChange: PropTypes.func
}

CustomFieldInstanceFilterList.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default CustomFieldInstanceFilterList
