import PropTypes from 'prop-types'
import React from 'react'
import { IconButton, Tooltip } from '@material-ui/core'
import { Help } from '@material-ui/icons'

class HelpIcon extends React.Component {
  render () {
    const { language, muiTheme: { palette } } = this.context
    const { helpUrl, size, helpText, ...rest } = this.props

    return (
      <Tooltip title={helpText || language.translate('application.help')}>
        <IconButton
          onClick={(e) => {
            const url = `http://help.processplan.com/${helpUrl}`
            window.open(url, '_blank')
          }}
          {...rest}
        >
          <Help fontSize={size} />
        </IconButton>
      </Tooltip>
    )
  }
}
 // WHEN HOVERING THIS IN TEMPLATE SETTINGS PANEL, THE HOVER IS TOO TALL, PROBABLY ALIGNITEMS: CENTER WILL FIX ON PARENT DIV
HelpIcon.propTypes = {
  helpUrl: PropTypes.string.isRequired,
  size: PropTypes.string,
  helpText: PropTypes.string
}

HelpIcon.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default HelpIcon
