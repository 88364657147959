import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ReactMarkdown from 'react-markdown'
import { Error } from '@material-ui/icons'
import { Button, Card, CardContent, CardHeader } from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import ReCAPTCHA from 'react-google-recaptcha'
import { push } from 'react-router-redux'
import { Form } from 'formsy-react'
import { get, post } from '../actions/base'
import { updateTitle } from '../actions/application'
import { setAccountData } from '../actions/authentication'
import CustomField from '../components/CustomField'
import NoDataToShow from '../components/Layout/NoDataToShow'
import Copyright from '../components/Layout/Copyright'
import ProcessFieldHelper from '../businessLogic/processFieldHelper'

class PublicForm extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      account: {},
      fieldList: [],
      publicForm: {},
      token: context.location.query.token || null,
      isInstance: true,
      canSubmit: true,
      isComplete: false,
      accessDenied: false
    }
  }

  componentDidMount () {
    const { language } = this.context

    this.getProcessInfo()
    this.props.updateTitle(language.translate('application.publicForm'))
  }

  getProcessInfo () {
    const { get, setAccountData } = this.props
    const { location } = this.context
    const { token } = this.state
    const { user } = this.props

    get('public/publicform', {
      onSuccess: (response) => {
        const fieldList = response.ProcessTemplateFieldList || response.ProcessInstanceFieldList

        const fields = fieldList.map((field, index) => {
          index += 1
          if (location.query[`fld${index}`]) {
            if (field.FieldOptionList) {
              field.ValueID = this.parseIdsFromArray(location.query[`fld${index}`], field)
            } else {
              field.Value = location.query[`fld${index}`]
              field.ValueID = location.query[`fld${index}`]
            }
            field.isDirty = true
          }

          return field
        })

        this.setState({
          fieldList: fields,
          publicForm: response.ProcessTemplatePublicForm,
          isInstance: !!(response.ProcessInstanceFieldList),
          account: response.Account
        }, () => {
          this.props.updateTitle(response.ProcessTemplatePublicForm.PublicDescription)
          if (!user.isAuthenticated) { setAccountData(response.Account, true) }
        })
      },
      onError: (error, body) => {
        if (body.ResultList.filter((error) => (error.Code === 403027))) {
          this.setState({ accessDenied: true })
        }
      },
      headers: { token }
    })
  }

  parseIdsFromArray (values, field) {
    const { FieldOptionList } = field

    if (!Array.isArray(values)) { values = [values] }

    const idArray = []

    values.map((value) => {
      const foundOption = FieldOptionList.filter(option => (option.OptionDescription === value))[0]

      // make sure we don't allow multiple values to be set for drop down lists or radio groups
      if (['R', 'L'].indexOf(field.FieldType) < 0 || idArray.length < 1) {
        idArray.push(foundOption.ID)
      }
    })

    return idArray.toString()
  }

  submitForm () {
    const { token, fieldList, publicForm } = this.state
    const { post, push } = this.props

    this.setState({ canSubmit: false })

    // gather form data
    const processFieldValues = JSON.parse(JSON.stringify(fieldList))

    // convert the field ID's to ProcessTemplateFieldID and delete the ID's since this is creating new instances
    processFieldValues.map((field) => {
      if (!field.ProcessTemplateFieldID) {
        field.ProcessTemplateFieldID = field.ID
        field.ProcessTemplateFieldID = field.ID
        delete field.ID
        delete field.ID
      }
    })

    const body = JSON.stringify({
      ProcessInstanceFieldList: this.getDirtyFields(processFieldValues)
    })

    post('public/publicform', body, {
      onSuccess: (response) => {
        this.setState({ isComplete: true }, () => {
          if (response.ProcessInstanceTask) {
            push(`/public-task/${response.ProcessInstanceTask.ID}?token=${response.ProcessInstance.Token}`)
          } else if (response.ProcessInstanceTaskList && response.ProcessInstanceTaskList.length) { push(`/milestone-report?token=${response.ProcessInstance.Token}`) }
        })
      },

      headers: { token },

      onError: (error) => {
        if (publicForm.DisplayHumanVerification) {
          this.recaptcha.reset()
        }

        this.setState({ canSubmit: true })
      }
    })
  }

  getDirtyFields (fields) {
    const dirtyFields = fields.filter(field => (field.isDirty || !field.ID))

    dirtyFields.map(field => delete field.isDirty)

    return dirtyFields
  }

  render () {
    const { publicForm, fieldList, canSubmit, isComplete, token, account, accessDenied } = this.state
    const { muiTheme, language } = this.context
    const { palette } = muiTheme

    if (accessDenied) {
      let textStyle = { fontSize: '25px', color: grey[400], textAlign: 'center' }

      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Error
            style={{ height: '150px', width: '150px', margin: 'auto', display: 'block' }}
            nativeColor={grey[400]}
          />
          <div style={textStyle}>
            {language.translate('application.accessDeniedPublicForm')}
          </div>
        </div>
      )
    }

    return (
      <div
        style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <div style={{ textAlign: 'center', margin: '30px 0px' }}>
          <img src={publicForm.PublicFormLogo_URL || account.HeaderLogoWhiteBG_URL} style={{ maxWidth: '600px' }} />
        </div>
        <Form
          style={{ width: '100%' }}
          onValid={() => this.setState({ canSubmit: true })}
          onInvalid={() => this.setState({ canSubmit: false })}
          autoComplete='off'
        >
          <Card
            className='card'
            style={{
              maxWidth: '600px',
              margin: 'auto'
            }}
          >
            <CardHeader
              title={publicForm.PublicDescription}
              titleTypographyProps={{ style: { fontSize: '20px', color: muiTheme.palette.headerFontColor } }}
              subheader={<ReactMarkdown source={publicForm.PublicInstructions} escapeHtml linkTarget='_blank' />}
              subheaderTypographyProps={{ style: { color: muiTheme.palette.headerFontColor } }}
              style={{
                borderBottom: `1px solid ${muiTheme.palette.borderColor}`,
                backgroundColor: muiTheme.palette.headerBackgroundColor
              }}
            />
            <CardContent>
              {(isComplete)
                ? <NoDataToShow
                  textStyle={(publicForm.SuccessfulSubmitMessage) ? { color: muiTheme.palette.accent3Color } : null}
                  noDataText={(publicForm.SuccessfulSubmitMessage)
                    ? <ReactMarkdown source={publicForm.SuccessfulSubmitMessage} escapeHtml linkTarget='_blank' />
                    : language.translate('application.formSubmitted')}
                />
                : <div>

                  {fieldList.map((field, index) => {
                    let filter = null

                    if (field.DependantOnFieldType) {
                      filter = ProcessFieldHelper.createFilterFromDependantFieldValue(field.DependantOnFieldType, fieldList)
                    }

                    if (ProcessFieldHelper.isInstanceLookupField(field.FieldType) && field.MetaData) {
                      filter = ProcessFieldHelper.createFilterFromParsedMetaData(field.MetaData, fieldList)
                    }

                    return <CustomField
                      key={field.ID || field.ProcessTemplateFieldID}
                      field={field}
                      fieldList={fieldList}
                      dispatch={this.props.dispatch}
                      filter={filter}
                      publicFormToken={token}
                      onUpdate={(field) => {
                        fieldList[index] = field
                        fieldList[index].isDirty = true

                        this.setState({ fieldList })
                      }}
                    />
                  })}
                  {(publicForm.DisplayHumanVerification)
                  ? <ReCAPTCHA
                    ref={(el) => { this.recaptcha = el }}
                    size='invisible'
                    sitekey='6Ld0lTAUAAAAAOT-3h8Nzt2lnGrB4bITdjTLRUnA'
                    onChange={this.submitForm.bind(this)}
                  />
                  : null}
                  <Button
                    disabled={!canSubmit}
                    fullWidth
                    type='submit'
                    variant='contained'
                    style={{ margin: '10px 0px', backgroundColor: palette.successColor, color: palette.alternateTextColor }}
                    onClick={() => {
                      if (publicForm.DisplayHumanVerification) {
                        this.recaptcha.execute()
                      } else {
                        this.submitForm()
                      }
                    }}
                  >
                    {language.translate('application.submit')}
                  </Button>
                </div>}
            </CardContent>
          </Card>
        </Form>
        <Copyright />
      </div>
    )
  }
}

PublicForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  updateTitle: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  setAccountData: PropTypes.func.isRequired,
  user: PropTypes.object
}

PublicForm.contextTypes = {
  location: PropTypes.object,
  muiTheme: PropTypes.object,
  language: PropTypes.object
}

const mapStateToProps = state => ({
  user: state.auth
})

const mapDispatchToProps = dispatch => ({
  dispatch,
  get: bindActionCreators(get, dispatch),
  post: bindActionCreators(post, dispatch),
  updateTitle: bindActionCreators(updateTitle, dispatch),
  push: bindActionCreators(push, dispatch),
  setAccountData: bindActionCreators(setAccountData, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(PublicForm)
