import PropTypes from 'prop-types'
import React from 'react'
import { Button, Card, CardHeader, CardContent, IconButton, Menu, MenuItem } from '@material-ui/core'
import { ExpandLess, ExpandMore, MoreVert } from '@material-ui/icons'
import FormHelper from '../../businessLogic/formHelper'
import { GeneratedIcon, Icon } from '../Icon'
import PrintableTemplateDocumentDialog from './PrintableTemplateDocumentDialog'

class TemplatesByGroup extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      showAll: false,
      menuAnchor: null,
      menuId: null,
      documentTemplates: [],
      documentTemplatesLoaded: false,
      showDocumentTemplateDialog: false
    }
  }

  numberToDisplay () {
    const { mainContentWidth } = this.props

    if (mainContentWidth > 900) { return 6 }

    if (mainContentWidth > 600) { return 4 }

    return 3
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { user, language } = this.context
    const {
      mainContentWidth, templates, groupName, onClick, onGroupToggle, editTemplateClick, fetchPrintableDocument,
      sopClick, onSchedule, onStartViaImport, onStatisticsClick, onInstancesClick, alwaysShowAll, hideMenu
    } = this.props
    const { showAll, menuAnchor, menuId, documentTemplates, documentTemplatesLoaded, showDocumentTemplateDialog } = this.state
    const numberToDisplay = this.numberToDisplay()
    const hasMoreToShow = (templates.length > numberToDisplay)

    return (

      <div
        style={{
          width: '100%'
        }}
      >
        {(groupName &&
          <div style={{
            width: '93%',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            alignItems: 'flex-end',
            marginBottom: '15px',
            marginTop: '20px'
          }}
          >
            <Button
              className={(hasMoreToShow) ? 'show-more' : null}
              size='large'
              style={{ fontWeight: '900' }}
              onClick={() => {
                onGroupToggle(!showAll)
                this.setState({ showAll: !showAll })
              }}
            >
              {groupName}
              {hasMoreToShow ? (showAll) ? <ExpandLess />
                : <ExpandMore /> : null}
            </Button>
            <div>{hasMoreToShow
              ? <a
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  onGroupToggle(!showAll)
                  this.setState({ showAll: !showAll })
                }}
              >{(showAll) ? language.translate('application.less') : language.translate('application.showAll')}</a>
              : null}</div>
          </div>
        )}
        <div style={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'row',
          flexWrap: 'wrap'
        }}
        >
          {templates.map((template, templateIndex) => {
            if (!showAll && !alwaysShowAll && templates.length !== numberToDisplay && templateIndex >= numberToDisplay - 1) return null

            return (
              <Card
                key={template.ID}
                className='card'
                style={{
                  width: (mainContentWidth > 900) ? '30%' : (mainContentWidth > 600) ? '45%' : '90%',
                  marginLeft: '10px',
                  marginRight: '10px',
                  height: '100%',
                  padding: '0px',
                  backgroundColor: (this.context.location.query.ptsid === template.ID)
                    ? palette.borderColor
                    : palette.canvasColor
                }}
              >
                <CardHeader
                  className='clickable'
                  title={FormHelper.decodeHTML(template.Title)}
                  subheader={template.Description}
                  subheaderTypographyProps={{
                    style: {
                      maxHeight: '45px',
                      overflow: 'hidden',
                      display: '-webkit-box',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                      textOverflow: 'ellipsis'
                    }
                  }}
                  avatar={
                    (template.IconName)
                      ? <Icon icon={template.IconName} size={40} />
                      : <GeneratedIcon
                        text={template.Title}
                        randomizer={template.ID}
                      />
                  }
                  style={{ alignItems: 'start' }}
                  titleTypographyProps={{
                    style: {
                      fontSize: '16px',
                      lineHeight: '22px',
                      fontWeight: '600',
                      width: '100%'
                    }
                  }}
                  onClick={(e) => {
                    onClick(template.ID)
                  }}
                  action={(!hideMenu &&
                    <div className='templateCardContextMenu' onClick={(e) => {
                      // prevent menu click from bubbling up and triggering card click event
                      e.stopPropagation()
                    }}>
                      <IconButton
                        style={{
                          padding: '3px'
                        }}
                        onClick={(e) => {
                          this.setState({ menuAnchor: e.currentTarget, menuId: template.ID })
                        }}
                      >
                        <MoreVert />
                      </IconButton>
                      <Menu
                        className='menu'
                        anchorEl={menuAnchor}
                        open={Boolean(menuAnchor) && menuId === template.ID}
                        onClose={() => { this.setState({ menuAnchor: null }) }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        getContentAnchorEl={null}
                      >
                        {(editTemplateClick && user.canManageProcessTemplates)
                          ? <MenuItem
                            onClick={() => {
                              this.setState({ menuAnchor: null })
                              editTemplateClick(template.ID)
                            }}
                          >{language.translate('application.editTemplate')}</MenuItem>
                          : null}
                        {(user.canViewProcessReports)
                          ? <MenuItem
                            onClick={() => {
                              this.setState({ menuAnchor: null })
                              onInstancesClick(template.ID)
                            }}
                          >{language.translate('application.processTable')}</MenuItem>
                          : null}
                        {(sopClick && user.canManageProcessTemplates)
                          ? <MenuItem
                            onClick={() => {
                              this.setState({ menuAnchor: null })
                              sopClick((response) => {
                                this.setState({
                                  documentTemplates: response.DocumentTemplateList,
                                  documentTemplatesLoaded: true,
                                  showDocumentTemplateDialog: true
                                })
                              })
                            }}
                          >{language.translate('application.printSopDocument')}</MenuItem>
                          : null}
                        {(user.canViewProcessReports)
                          ? <MenuItem
                            onClick={() => {
                              this.setState({ menuAnchor: null })
                              onStatisticsClick(template.ID)
                            }}
                          >{language.translate('application.statistics')}</MenuItem>
                          : null}
                        {(user.canInitiateProcesses && user.canScheduleOrLaunchMultipleProcesses)
                          ? <MenuItem
                            onClick={() => {
                              this.setState({ menuAnchor: null })
                              onSchedule(template.ID)
                            }}
                          >{language.translate('application.schedule')}</MenuItem>
                          : null}
                        {(user.canInitiateProcesses && user.canScheduleOrLaunchMultipleProcesses)
                          ? <MenuItem
                            onClick={() => {
                              this.setState({ menuAnchor: null })
                              onStartViaImport(template.ID)
                            }}
                          >{language.translate('application.startMultiple')}</MenuItem>
                          : null}
                      </Menu>
                    </div>
                  )}
                />
              </Card>
            )
          })}
          {
            (templates.length > numberToDisplay && !alwaysShowAll)
              ? <Card
                className='card clickable'
                onClick={() => {
                  onGroupToggle(!showAll)
                  this.setState({ showAll: !showAll })
                }}
                style={{
                  width: (mainContentWidth > 900) ? '30%' : (mainContentWidth > 600) ? '45%' : '90%',
                  marginLeft: '10px',
                  marginRight: '10px',
                  height: '100%',
                  padding: '0px',
                  backgroundColor: palette.accent7Color
                }}
              >
                <CardContent style={{ padding: '20px' }}>
                  <div style={{ fontSize: '24px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div>
                      {(showAll) ? language.translate('application.less') : language.translate('application.showAll')}
                    </div>
                    {hasMoreToShow ? (showAll) ? <ExpandLess />
                      : <ExpandMore /> : null}
                  </div>
                </CardContent>
              </Card>
              : null
          }
        </div>
        {(showDocumentTemplateDialog && documentTemplatesLoaded &&
          <PrintableTemplateDocumentDialog
            onClose={() => { this.setState({ showDocumentTemplateDialog: false }) }}
            documentTemplates={documentTemplates}
            fetchPrintableDocument={(documentTemplateId, callback) => { fetchPrintableDocument(menuId, documentTemplateId, callback) }}
          />)}
      </div>
    )
  }
}

TemplatesByGroup.propTypes = {
  groupName: PropTypes.string,
  templates: PropTypes.array.isRequired,
  mainContentWidth: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  onGroupToggle: PropTypes.func,
  editTemplateClick: PropTypes.func,
  sopClick: PropTypes.func,
  fetchPrintableDocument: PropTypes.func,
  showDescription: PropTypes.bool,
  onSchedule: PropTypes.func,
  onStartViaImport: PropTypes.func,
  onStatisticsClick: PropTypes.func,
  onInstancesClick: PropTypes.func,
  alwaysShowAll: PropTypes.bool,
  hideMenu: PropTypes.bool
}

TemplatesByGroup.contextTypes = {
  muiTheme: PropTypes.object,
  location: PropTypes.object,
  user: PropTypes.object,
  language: PropTypes.object
}

export default TemplatesByGroup
