import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import update from 'immutability-helper'
import { bindActionCreators } from 'redux'
import { get, post } from '../../actions/base'
import { Button, Card } from '@material-ui/core'
import CustomFieldItem from '../CustomFields/CustomFieldItem'
import { Add } from '@material-ui/icons'
import ReorderableList from '../Layout/ReorderableList'
import RightPanelContent from '../Layout/RightPanelContent'
import { showUndoMessage } from '../../actions/snackbar'

class UserCustomFieldPanel extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      isLoaded: false,
      fields: [],
      userGroups: [],
      userGroupsLoaded: false,
      userFieldTypes: [],
      userFieldTypesLoaded: true,
      editingItem: null
    }
  }

  componentDidMount () {
    this.fetchUserFields()
    this.fetchUserGroups()
    this.fetchUserFieldTypes()
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (this.state.fields.length !== nextState.fields.length) { return true }

    if (this.state.isLoaded !== nextState.isLoaded) { return true }

    return true
  }

  fetchUserFields () {
    this.props.get('user/field/list', {
      onSuccess: (response) => {
        this.setState({
          isLoaded: true,
          fields: response.UserFieldList
        })
      }
    })
  }

  fetchUserFieldTypes () {
    this.props.get('user/field/type/list', {
      onSuccess: (response) => {
        this.setState({
          userFieldTypesLoaded: true,
          userFieldTypes: response.UserFieldTypeList
        })
      }
    })
  }

  fetchUserGroups () {
    this.props.get('usergroup/list', {
      onSuccess: (response) => {
        this.setState({
          userGroupsLoaded: true,
          userGroups: response.UserGroupList
        })
      }
    })
  }

  addNewField () {
    const { fields } = this.state
    const { language } = this.context

    const newItem = {
      FieldName: language.translate('application.newField'),
      FieldType: '1',
      ID: null,
      UniqueField: false,
      SortNum: (fields.length < 1) ? 1 : fields[fields.length - 1].SortNum + 1
    }

    this.setState({
      fields: this.state.fields.concat(newItem),
      editingItem: fields.length
    }, () => this.saveField(fields.length))
  }

  addNewFieldOption (index, name) {
    const field = this.state.fields[index]
    const options = field.FieldOptionList.slice()
    const displayOrder = (options.length) ? options[options.length - 1].DisplayOrder + 1 : 1

    options.push({
      OptionDescription: name,
      UserFieldID: field.ID,
      DisplayOrder: displayOrder
    })

    this.handleChange(index, 'FieldOptionList', options)
  }

  handleChange (index, property, value, save = true) {
    const fields = this.state.fields
    fields[index][property] = value

    this.setState({ fields }, () => {
      if (save && fields[index].FieldName) { this.saveField(index) }
    })
  }

  removeField (index) {
    const fields = this.state.fields.slice()
    const { language } = this.context

    const fieldsToRemove = fields.splice(index, 1)

    const body = JSON.stringify({})

    if (fieldsToRemove[0].ID) {
      this.props.post(`user/field/${fieldsToRemove[0].ID}/delete`, body, {
        onSuccess: (response) => {
          this.setState({ fields }, () => {
            this.props.showUndoMessage(
              language.translate('application.userFieldRemoved'),
              () => {
                this.props.post(`user/field/${fieldsToRemove[0].ID}/delete/undo`, body, {
                  onSuccess: (response) => {
                    this.fetchUserFields()
                  }
                })
              }
            )
          })
        }
      })
    } else {
      this.setState({ fields })
    }
  }

  reorderFields (fromIndex, toIndex) {
    const fields = this.state.fields
    const dragField = fields[fromIndex]

    this.setState(update(this.state, {
      fields: {
        $splice: [
          [fromIndex, 1],
          [toIndex, 0, dragField]
        ]
      }
    }))
  }

  reorderOptions (fieldIndex, optionFromIndex, optionToIndex) {
    const fields = this.state.fields.slice()
    const options = fields[fieldIndex].FieldOptionList
    const dragField = options[optionFromIndex]

    options.splice(optionFromIndex, 1)
    options.splice(optionToIndex, 0, dragField)

    fields[fieldIndex].FieldOptionList = options

    this.setState({ fields })
  }

  deleteFieldOption (fieldId, optionIndex) {
    const fields = this.state.fields.slice()
    const { language } = this.context

    let fieldIndex = null
    fields.some((field, index) => {
      if (field.ID === fieldId) {
        fieldIndex = index
        return true
      }
    })

    const optionToRemove = fields[fieldIndex].FieldOptionList[optionIndex]

    const body = JSON.stringify({})

    if (optionToRemove.ID) {
      this.props.post(
        `user/field/${fields[fieldIndex].ID}/option/${optionToRemove.ID}/delete`,
        body,
        {
          onSuccess: (response) => {
            this.fetchUserFields()

            this.props.showUndoMessage(
              language.translate('application.optionRemoved'),
              () => {
                this.props.post(
                  `user/field/${fields[fieldIndex].ID}/option/${optionToRemove.ID}/delete/undo`,
                  body,
                  {
                    onSuccess: (response) => {
                      this.fetchUserFields()
                    }
                  }
                )
              }
            )
          }
        }
      )
    } else {
      const options = fields[fieldIndex].FieldOptionList
      options.splice(optionIndex, 1)
      fields[fieldIndex].FieldOptionList = options

      this.setState({ fields })
    }
  }

  updateDisplayProperties () {
    const { fields } = this.state

    fields.map((item, index) => {
      item.SortNum = index + 1
    })

    this.saveFields()
  }

  saveFields () {
    const body = JSON.stringify({
      UserFieldList: this.state.fields
    })

    this.props.post('user/field/list', body, {
      onSuccess: (response) => {
        this.setState({
          isLoaded: true,
          fields: response.UserFieldList
        })
      },

      onError: (error) => {
        this.fetchUserFields()
      }
    })
  }

  saveField (index) {
    const { fields } = this.state
    const field = fields[index]

    const body = JSON.stringify({
      UserField: field
    })

    const endpoint = (field.ID) ? `/${field.ID}` : ''

    this.props.post(`user/field${endpoint}`, body, {
      onSuccess: (response) => {
        fields[index] = response.UserField

        this.setState({
          isLoaded: true,
          fields
        })
      },

      onError: (error) => {
        this.fetchUserFields()
      }
    })
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const { userGroups, fields, userFieldTypes, editingItem } = this.state

    return (
      <RightPanelContent
        title={language.translate('application.customUserFields')}
        closePanel={() => this.props.close()}
      >
        <div style={{ padding: '15px' }}>
          <Card className='card'>
            {(!this.state.fields.length)
              ? <div style={{ margin: '10px' }}>{language.translate('application.noUserFields')}</div>
              : <ReorderableList style={{ padding: '0px' }}>
                {fields.map((field, index) => (
                  <CustomFieldItem
                    field={field}
                    key={field.ID || `new-${field.SortNum}`}
                    index={index}
                    ref='user-field'
                    editMode={(editingItem === index)}
                    onItemClick={() => this.setState({ editingItem: index })}
                    onEndEdit={() => this.setState({ editingItem: null })}
                    onAddFieldOption={name => this.addNewFieldOption(index, name)}
                    customFieldTypes={userFieldTypes}
                    onReorderOptionsRequest={this.reorderOptions.bind(this)}
                    onReorderRequest={this.reorderFields.bind(this)}
                    onRemoveField={() => this.removeField(index)}
                    onRemoveFieldOption={this.deleteFieldOption.bind(this)}
                    onChange={(property, value, save) => this.handleChange(index, property, value, save)}
                    onSave={() => this.saveField(index)}
                    droppedItem={this.updateDisplayProperties.bind(this)}
                    style={{
                      borderBottom: `1px solid ${palette.borderColor}`
                    }}
                    userGroups={userGroups}
                    disableReadOnlyOption
                    disableSecureOption
                    disableRequiredOption
                  />
                ))}
              </ReorderableList>}
          </Card>
        </div>
        <Button
          onClick={this.addNewField.bind(this)}
          color='primary'
          style={{ marginLeft: '15px' }}
          variant='contained'
        >
          <Add />
          {language.translate('application.addField')}
        </Button>
      </RightPanelContent>
    )
  }
}

UserCustomFieldPanel.propTypes = {
  fields: PropTypes.array,
  dispatch: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  showUndoMessage: PropTypes.func.isRequired
}

UserCustomFieldPanel.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  dispatch,
  post: bindActionCreators(post, dispatch),
  get: bindActionCreators(get, dispatch),
  showUndoMessage: bindActionCreators(showUndoMessage, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(UserCustomFieldPanel)
