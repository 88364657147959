import PropTypes from 'prop-types'
import React from 'react'
import {
  Button, Card, CardActions, CardContent, CardHeader, Checkbox, Divider, FormControlLabel, IconButton
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles'
import FormHelper from '../../businessLogic/formHelper'
import { apiServer } from '../../config'
import { FormsyText } from 'formsy-material-ui'
import ReactMarkdown from 'react-markdown'
import theme from '../../styles/theme'

const newTheme = JSON.parse(JSON.stringify(theme))
newTheme.palette.primary.main = 'rgb(60, 184, 117)'
const greenButtonTheme = createMuiTheme(newTheme)

const styles = theme => ({
  label: {
    paddingTop: '6px'
  }
})

class ResponseCard extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      taskFieldsExpanded: (props.initiallyExpanded)
    }
  }

  getResourceLink (resource) {
    const { user } = this.context
    let href = resource.ResourceURL

    if (resource.PrivateFileKey) { href = `${apiServer}/open.aspx?filekey=${resource.PrivateFileKey}&token=${user.token}&email=${encodeURIComponent(user.userName)}&` } else if (resource.ResourceURL.substring(0, 2) === '\\\\') { href = resource.ResourceURL } else if (resource.ResourceURL.substring(0, 4) !== 'http') { href = `http://${resource.ResourceURL}` }

    return (
      <a
        href={resource.PublicURL || href}
        style={{ display: 'flex', alignItems: 'center' }}
        target='_blank'
      >
        {(resource.ResourceFileName && resource.ResourceFileName.match(/.(jpg|jpeg|png|gif)$/i) &&
          <img
            style={{ paddingRight: '5px' }}
            src={(resource.PublicURL || href) + '&thumbnail=1&'}
            width={'50px'} />
        )}
        {resource.ResourceDescription}
      </a>)
  }

  checklistRequirementsMet () {
    const { checklist, task } = this.props

    if (!checklist.length) { return true }

    switch (task.ChecklistRequirementOption) {
      case 'ALL':
        return !(checklist.filter(item => (item.Checked === false)).length)
      case 'ANY':
        return !!(checklist.filter(item => (item.Checked === true)).length)
      default:
        return true
    }
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { taskFieldsExpanded } = this.state
    const { language } = this.context
    const {
      task, checklist, resources, responses, canRespond, canSave, canSubmitTaskInfo, onChecklistChange, onTimeSpentChange,
      onResponseSubmit, onSaveAndClose, onTaskChange, hideExpander, cardStyle, hideTimeSpent, user, disabled, classes
    } = this.props

    return (
      <Card
        className='card'
        style={cardStyle}
      >
        <CardHeader
          title={<div
            dangerouslySetInnerHTML={{ __html: FormHelper.encodeHTML(task.TaskText_Display) }}
          />}
          subheader={
            <div
              dangerouslySetInnerHTML={{ __html: task.ProcessTemplateTitle }}
            />
          }
        />
        <CardContent>

          {/** DISPLAY TASK INSTRUCTIONS **/}
          {(task.Instructions)
            ? <div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div
                  style={{ flex: 1, marginTop: '15px' }}
                >{language.translate('application.instructions')}:
                </div>
                <div style={{ flex: 3 }}>
                  <ReactMarkdown source={task.Instructions} escapeHtml linkTarget='_blank' />
                </div>
              </div>
              <div style={{ margin: '10px 0px 10px 0px' }}>
                <Divider />
              </div>
            </div>
            : null}

          {/** DISPLAY TASK CHECKLIST **/}
          {(checklist && checklist.length)
            ? <div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ flex: 1 }}>{language.translate('application.checklist')}:</div>
                <div style={{ flex: 3, display: 'flex', flexDirection: 'column' }}>
                  {checklist.map((item, itemIndex) => (
                    <FormControlLabel
                      style={{ alignItems: 'flex-start' }}
                      key={item.ProcessTemplateTaskChecklistID}
                      classes={{ label: classes.label }}
                      control={<Checkbox
                        style={{ padding: '5px' }}
                        checked={item.Checked}
                        color='primary'
                        disabled={(disabled)}
                        onChange={() => {
                          item.Checked = !item.Checked

                          checklist[itemIndex] = item
                          onChecklistChange(checklist)
                        }}
                      />}
                      label={item.ItemDescription}
                    />
                  ))}
                </div>
              </div>
              <div style={{ margin: '10px 0px 10px 0px' }}>
                <Divider />
              </div>
            </div>
            : null
          }

          {/** DISPLAY TASK RESOURCES **/}
          {(resources && resources.length)
            ? <div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ flex: 1 }}>{language.translate('application.resources')}:</div>
                <div style={{ flex: 3 }}>
                  {resources.map(resource => (
                    <div key={resource.ID}>
                      {this.getResourceLink(resource)}
                    </div>
                  ))}
                </div>
              </div>
              <div style={{ margin: '10px 0px 10px 0px' }}>
                <Divider />
              </div>
            </div>
            : null
          }

          <div
            style={{ color: palette.errorColor }}
          >
            {(!canRespond) ? `*${language.messages.validationErrors.pleaseComplete}` : ''}
          </div>

          {responses.map(response => {
            let isDisabled = !canRespond || !canSave || disabled || !this.checklistRequirementsMet()
            return (
              <MuiThemeProvider
                theme={greenButtonTheme}
                key={response.ProcessTemplateTaskResponseID || response.ResponseText}>
                <Button
                  fullWidth
                  variant='contained'
                  color='primary'
                  style={{
                    marginBottom: '10px',
                    // fontSize: '1.2rem',
                    color: palette.alternateTextColor,
                    backgroundColor: (!isDisabled && response.DisplayColor) ? response.DisplayColor : null
                  }}
                  type='submit'
                  disabled={isDisabled}
                  onClick={() => { onResponseSubmit(response.ProcessTemplateTaskResponseID || response.ResponseText) }}
                >
                  {response.ResponseText}
                </Button>
              </MuiThemeProvider>
            )
          })}

          <Button
            fullWidth
            color='primary'
            variant='contained'
            style={{ display: 'block' }}
            onClick={() => { onResponseSubmit(false) }}
            disabled={!canSave || disabled}
          >
            {language.translate('application.saveCloseNoResponse')}
          </Button>

          <div
            className={(taskFieldsExpanded || hideExpander) ? '' : 'hidden'}
            style={{ paddingTop: '20px' }}
          >
            <FormsyText
              fullWidth
              floatingLabelText={`${language.translate('application.notesLabel')}...`}
              multiLine
              rows={2}
              ref='notes'
              disabled={(disabled)}
              name='task-notes'
              required={(task.TaskNoteRequired)}
              defaultValue={FormHelper.decodeHTML(task.Notes)}
              validationErrors={this.context.language.messages.validationErrors}
              onBlur={() => {
                task.Notes = this.refs.notes.getValue()
                onTaskChange(task)
              }}
            />
            {(!hideTimeSpent && user.timeTrackingUnit === 'H')
              ? <FormsyText
                name='time_spent_hours'
                required={task.TimeEntryRequired}
                validations='isFloat'
                validationErrors={language.messages.validationErrors}
                type='number'
                disabled={(disabled)}
                fullWidth
                value={task.HoursSpent}
                floatingLabelText={language.translate('application.hoursSpentOnTask')}
                hintText={language.translate('application.timeSpentHint')}
                onBlur={(e) => {
                  let timeSpent = e.currentTarget.value
                  let hoursSpent = parseFloat(timeSpent)
                  let minutesSpent = hoursSpent * 60

                  if (timeSpent) {
                    task.HoursSpent = hoursSpent
                    task.MinutesSpent = parseInt(minutesSpent)
                  } else {
                    task.HoursSpent = 0
                    task.MinutesSpent = 0
                  }

                  onTaskChange(task)
                  onTimeSpentChange(parseInt(minutesSpent))
                }}
              />
              : null}
            {(!hideTimeSpent && user.timeTrackingUnit === 'M')
              ? <FormsyText
                name='time_spent_minutes'
                validations='isFloat'
                validationErrors={language.messages.validationErrors}
                type='number'
                disabled={(disabled)}
                fullWidth
                value={task.MinutesSpent}
                floatingLabelText={language.translate('application.minutesSpentOnTask')}
                onBlur={(e) => {
                  let minutesSpent = parseInt(e.currentTarget.value)
                  let hoursSpent = minutesSpent / 60

                  if (minutesSpent) {
                    task.MinutesSpent = minutesSpent
                    task.HoursSpent = hoursSpent
                  } else {
                    task.MinutesSpent = 0
                    task.HoursSpent = 0
                  }

                  onTaskChange(task)
                  onTimeSpentChange(minutesSpent)
                }}
              />
              : null}
            {(task.IsOnDemand)
              ? <FormsyText
                name='additional_expense'
                validations='isFloat'
                validationErrors={language.messages.validationErrors}
                type='number'
                disabled={(disabled)}
                fullWidth
                value={task.ExpenseInUSDollars}
                floatingLabelText={language.translate('application.additionalExpenseInUSDollars')}
                onBlur={(e) => {
                  task.ExpenseInUSDollars = parseFloat(e.currentTarget.value)

                  onTaskChange(task)
                }}
              />
              : null}
          </div>
        </CardContent>
        {(!hideExpander)
          ? <CardActions>
            <Button
              style={(!canSubmitTaskInfo && !this.state.taskFieldsExpanded) ? { color: palette.errorColor } : {}}
              onClick={() => { this.setState({ taskFieldsExpanded: !taskFieldsExpanded }) }}
            >
              {(this.state.taskFieldsExpanded)
                ? language.translate('application.less')
                : (!canSubmitTaskInfo) ? `${language.translate('application.more')}*` : language.translate('application.more')}
            </Button>
            <IconButton
              style={{ transform: (taskFieldsExpanded) ? 'rotate(180deg)' : 'rotate(0deg)', marginLeft: 'auto' }}
              onClick={() => { this.setState({ taskFieldsExpanded: !taskFieldsExpanded }) }}
              aria-expanded={this.state.taskFieldsExpanded}
              aria-label='Show more'
            >
              <ExpandMore />
            </IconButton>
          </CardActions>
          : null}
      </Card>
    )
  }
}

ResponseCard.propTypes = {
  task: PropTypes.object.isRequired,
  checklist: PropTypes.array.isRequired,
  resources: PropTypes.array.isRequired,
  responses: PropTypes.array.isRequired,
  canRespond: PropTypes.bool,
  canSave: PropTypes.bool,
  canSubmitTaskInfo: PropTypes.bool,
  hideExpander: PropTypes.bool,
  initiallyExpanded: PropTypes.bool,
  hideTimeSpent: PropTypes.bool,
  onResponseSubmit: PropTypes.func.isRequired,
  onSaveAndClose: PropTypes.func,
  onChecklistChange: PropTypes.func,
  onTaskChange: PropTypes.func,
  onTimeSpentChange: PropTypes.func,
  cardStyle: PropTypes.object,
  user: PropTypes.object,
  disabled: PropTypes.bool,
  classes: PropTypes.object.isRequired
}

ResponseCard.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object,
  user: PropTypes.object
}

export default withStyles(styles)(ResponseCard)
