import PropTypes from 'prop-types'
import React from 'react'
import { IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'
import Icon from './Icon'
import * as Icons from './selection.json'
import ReactPaginate from 'react-paginate'
import './IconPagination.scss'

const styles = {
  button: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}

const perPage = 25

class IconSelector extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      selectedIcon: this.props.selectedIcon,
      icons: Icons.icons,
      offset: 0,
      pageNum: Math.floor(Icons.icons.length / perPage + 1)
    }
  }

  componentDidMount () {
    if (this.props.searchText) {
      this.filterIcons(this.props.searchText)
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.searchText !== this.props.searchText) {
      this.filterIcons(this.props.searchText)
      this.handlePageClick({ selected: 0 })
    }
  }

  filterIcons (filter) {
    const filtered = Icons.icons.filter((icon) => {
      let found = false

      if (filter === 'all') return true

      icon.icon.tags.map((tag) => {
        if (tag.includes(filter)) { found = true }
      })

      return found
    })

    this.setState({
      icons: filtered,
      pageNum: Math.floor(filtered.length / perPage + 1)
    })
  }

  handlePageClick (data) {
    const selected = data.selected
    const offset = Math.ceil(selected * perPage)

    this.setState({
      offset
    })
  }

  render () {
    const { icons, offset } = this.state
    const { onSelect, classes } = this.props
    const { language } = this.context

    const pageNum = offset / perPage

    return (
      (icons.length)
        ? <div style={{
          minHeight: '340px',
          display: 'flex',
          marginTop: '10px',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
        >
          <div style={{
            display: 'flex',
            padding: '0px 10px',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'center',
            flexWrap: 'wrap'
          }}
          >
            {icons.map((icon, index) => (
              (index < offset || index > offset + perPage - 1) ? null
                : <IconButton
                  style={{ padding: '0px', margin: '5px' }}
                  key={index}
                  onClick={() => onSelect(icon.properties.name)}
                >
                  <Icon
                    style={{ margin: '0px' }}
                    size={45}
                    icon={icon.properties.name}
                  />
                </IconButton>
            ))}
          </div>
          <div id='react-paginate'>
            <ReactPaginate
              previousLabel={<IconButton
                className={classes.button}
                style={{ padding: '12px 0px' }}
              ><ChevronLeft /></IconButton>}
              nextLabel={<IconButton
                className={classes.button}
                style={{ padding: '12px 0px' }}
              ><ChevronRight /></IconButton>}
              breakLabel={<IconButton
                style={{ fontSize: '1.1em', cursor: 'auto', padding: '12px 0px' }}
              >...</IconButton>}
              breakClassName={'break'}
              pageCount={this.state.pageNum}
              forcePage={pageNum}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={this.handlePageClick.bind(this)}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          </div>
        </div>
        : <div>{language.translate('icon.noIconsMatchYourSearch')}</div>
    )
  }
}

IconSelector.propTypes = {
  selectedIcon: PropTypes.string,
  searchText: PropTypes.string,
  onSelect: PropTypes.func,
  classes: PropTypes.object
}

IconSelector.contextTypes = {
  muiTheme: PropTypes.object,
  language: PropTypes.object
}

export default withStyles(styles)(IconSelector)
