if (!String.prototype.splitOnUppercase) {
  String.prototype.splitOnUppercase = function () {
    const string = Object(this)

    let newString = ''

    const stringArray = string.split(/(?=[A-Z])/)

    stringArray.map((word, index) => {
      newString += word

      if (stringArray.length - 1 > index) { newString += ' ' }
    })

    return newString
  }
}
if (!String.prototype.ucFirst) {
  String.prototype.ucFirst = function () {
    const string = Object(this)

    return string.charAt(0).toUpperCase() + string.substring(1)
  }
}
if (!String.prototype.lcFirst) {
  String.prototype.lcFirst = function () {
    const string = Object(this)

    return string.charAt(0).toLowerCase() + string.substring(1)
  }
}
