import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { updateScrollBarWidth } from '../../actions/application'
import { bindActionCreators } from 'redux'

class ScrollBarDetector extends React.Component {
  componentDidMount () {
    const scrollbarElement = document.createElement('div')
    scrollbarElement.style.width = '100px'
    scrollbarElement.style.height = '100px'
    scrollbarElement.style.overflow = 'scroll'
    scrollbarElement.style.position = 'absolute'
    scrollbarElement.style.top = '-100%'
    scrollbarElement.style.left = '-100%'
    document.body.appendChild(scrollbarElement)

    const scrollBarWidth = scrollbarElement.offsetWidth - scrollbarElement.clientWidth
    this.props.updateScrollBarWidth(scrollBarWidth)
    document.body.removeChild(scrollbarElement)
  }

  render () {
    return null
  }
}

ScrollBarDetector.propTypes = {
  dispatch: PropTypes.func.isRequired,
  updateScrollBarWidth: PropTypes.func.isRequired
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  updateScrollBarWidth: bindActionCreators(updateScrollBarWidth, dispatch),
  dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(ScrollBarDetector)
