import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get, post } from '../../actions/base'
import { IconButton, Tooltip } from '@material-ui/core'
import { Edit, ArrowBack } from '@material-ui/icons'
import { goBack, push } from 'react-router-redux'
import TransitionGroup from 'react-addons-transition-group'
import DataList from '../Layout/DataList'
import { showSnackbar, showUndoMessage } from '../../actions/snackbar'
import RightPanelContent from '../Layout/RightPanelContent'
import MessageItem from './MessageItem'
import NewMessageForm from './NewMessageForm'

class MessagePanel extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      isLoaded: false,
      memoList: [],
      composeMemo: false,
      forceDataRefresh: 0
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { type, typeId } = this.props

    if (prevProps.type !== type || prevProps.typeId !== typeId) { this.setState({ composeMemo: false }) }
  }

  dataLoadCallback (data) {
    this.setState({
      memoList: data
    })
  }

  postMemo (memo, onSuccess, onFail) {
    const { type } = this.props

    const body = JSON.stringify({
      Memo: memo
    })

    this.props.post('memo', body, {
      onSuccess: (response) => {
        if (onSuccess) { onSuccess() }

        // need to refresh message list if this was sent from the user panel
        if (type === 'user') { this.setState({ forceDataRefresh: this.state.forceDataRefresh + 1 }) }
      },

      onError: (error) => {
        if (onFail) { onFail() }
      }
    })
  }

  handleNewMemo () {
    this.setState({ composeMemo: true })
  }

  handleCancelMemo () {
    this.setState({ composeMemo: false })
  }

  getEndpoint () {
    const { type, typeId } = this.props

    if (type === 'template') { return `processtemplate/${typeId}/memo/list` }

    if (type === 'instance') { return `processinstance/${typeId}/memo/list` }

    if (type === 'user') { return `user/${typeId}/memo/list` }
  }

  getChannel () {
    const { type, typeId } = this.props

    if (type === 'template') { return `ProcessTemplate-${typeId}` }

    if (type === 'instance') { return `ProcessInstance-${typeId}` }

    if (type === 'user') { return `User-${typeId}` }
  }

  fetchGroupUsers (groupId, callback) {
    if (!groupId || groupId === '0') {
      return false
    }

    this.props.get(`usergroup/${groupId}/user/list`, {
      onSuccess: (response) => {
        callback(response.UserList)
      }
    })
  }

  render () {
    const { language } = this.context
    const { memoList, composeMemo, forceDataRefresh } = this.state
    const { dispatch, user, type, typeId, push, goBack, location, rightPanelRef } = this.props

    const memoEndpoint = this.getEndpoint()
    const channel = this.getChannel()

    return (
      <RightPanelContent
        title={(composeMemo) ? `New ${type} message` : `${type.ucFirst()} ${language.translate('application.messages')}`} // TODO: handle translation
        closePanel={() => this.props.close()}
        leftToolbarAvatar={
          <div style={{ display: 'flex' }}>
            <Tooltip title={language.translate('application.backToTask')}>
              <IconButton
                onClick={() => goBack()}
                style={{ display: (location.query.task) ? 'inherit' : 'none' }}
              >
                <ArrowBack />
              </IconButton>
            </Tooltip>
            <Tooltip title={language.translate('application.newMessage')}>
              <IconButton
                onClick={this.handleNewMemo.bind(this)}
                style={{ display: (composeMemo) ? 'none' : 'inherit' }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
          </div>
        }
      >
        <div style={{ padding: '15px' }}>
          <TransitionGroup>
            {composeMemo &&
            <NewMessageForm
              onSubmit={this.postMemo.bind(this)}
              dispatch={dispatch}
              onClose={this.handleCancelMemo.bind(this)}
              memoType={type}
              memoTypeId={typeId}
              defaultToUserId={location.query.usid}
              defaultToGroupId={location.query.gsid}
            />}
          </TransitionGroup>
          <DataList
            url={memoEndpoint}
            dataCallback={this.dataLoadCallback.bind(this)}
            responseProperty='MemoList'
            noDataText={language.translate('application.noMessages')}
            disableMasonry
            channelName={`Private-${user.accountID}-${channel}`}
            events={['MemoCreated']}
            containerElement={rightPanelRef}
            disableSearch
            forceDataRefresh={forceDataRefresh}
            location={location}
            hideFilterChips
          >
            {memoList.map((memo, index) => (
              <MessageItem
                key={memo.ID}
                memo={memo}
                onReply={(memo, onSuccess, onFail) => {
                  this.postMemo(memo, () => {
                      onSuccess()
                      if (type === 'user') { this.setState({ forceDataRefresh: forceDataRefresh + 1 }) }
                    }, onFail
                  )
                }}
                onRequestGroupUsers={(groupId, callback) => {
                  this.fetchGroupUsers(groupId, callback)
                }}
                push={push}
              />
            ))}
          </DataList>
        </div>
      </RightPanelContent>
    )
  }
}

MessagePanel.propTypes = {
  type: PropTypes.string,
  typeId: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func,
  user: PropTypes.object,
  showUndoMessage: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  location: PropTypes.object,
  rightPanelRef: PropTypes.object
}

MessagePanel.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

const mapStateToProps = state => ({
  user: state.auth,
  rightPanelRef: state.application.rightPanelRef
})

const mapDispatchToProps = dispatch => ({
  dispatch,
  post: bindActionCreators(post, dispatch),
  get: bindActionCreators(get, dispatch),
  showSnackbar: bindActionCreators(showSnackbar, dispatch),
  showUndoMessage: bindActionCreators(showUndoMessage, dispatch),
  push: bindActionCreators(push, dispatch),
  goBack: bindActionCreators(goBack, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(MessagePanel)
