import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import objectAssign from 'object-assign'

export function requireAuthentication (Component) {
  class RequireAuth extends React.Component {

    componentDidMount () {
      this.checkAuth()
    }

    componentDidUpdate () {
      this.checkAuth()
    }

    checkAuth () {
      if (!this.props.user.isAuthenticated) {
        const redirectAfterLogin = this.props.location.pathname
        this.props
          .dispatch(push({
            pathname: '/login',
            query: objectAssign({}, this.props.location.query, { next: redirectAfterLogin })
          }))
      }
    }

    render () {
      return (
        this.props.user.isAuthenticated === true
          ? <Component {...this.props} />
          : null
      )
    }
  }

  const mapStateToProps = state => ({
    user: state.auth
  })

  RequireAuth.propTypes = {
    dispatch: PropTypes.func,
    user: PropTypes.object,
    location: PropTypes.object
  }

  return connect(mapStateToProps)(RequireAuth)
}
