import PropTypes from 'prop-types'
import React from 'react'
import FormHelper from '../../businessLogic/formHelper'
import FormsyAutoComplete from '../Forms/FormsyAutoComplete'
import { Button, IconButton, Tooltip } from '@material-ui/core'
import { Edit, Add } from '@material-ui/icons'
import { get } from '../../actions/base'
import FieldWrapper from './FieldWrapper'

class ExternalSource extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      dataList: [],
      isLoaded: false,
      itemClicked: false, // used to track if selection is made by clicking on menu item vs blurring away from field
      edit: (!this.props.field.ProcessInstanceID || !this.props.field.Value || !this.props.clickableLink) // if there is no instance ID, then default to showing the input field
    }
  }

  componentDidMount () {
    const { Value, ValueID } = this.props.field

    if (!this.props.field.ReadOnlyField) {
      if (!Value || Value === ValueID || !ValueID) {
        this.fetchData(Value || '', !!(Value && Value === ValueID))
      }
    }
  }

  componentDidUpdate (prevProps) {
    const { Value, ValueID, ReadOnlyField } = this.props.field

    if (prevProps.field.ReadOnlyField && !ReadOnlyField) {
      this.fetchData(Value || '')
    }

    if (prevProps.filter !== this.props.filter && !ReadOnlyField) {
      this.fetchData(Value || '')
    }
  }

  handleChange (option) {
    const { field, onSelect, keyProperty } = this.props

    if (typeof option !== 'string') {
      field.ValueID = option.key
      field.Value = option.text

      if (onSelect) { onSelect(field) }

      this.setState({ itemClicked: false })
    } else if (this.state.isLoaded && typeof option === 'string') {
      field.ValueID = (option && this.state.dataList.length) ? this.state.dataList[0][keyProperty] : ''
      field.Value = (option && this.state.dataList.length) ? this.state.dataList[0].OptionDescription : ''

      if (onSelect) { onSelect(field) }

      this.setState({ itemClicked: false })
    } else {
      setTimeout(() => { this.handleChange(option) }, 300)
    }
  }

  onBlur (value) {
    const { itemClicked } = this.state

    if (itemClicked) {
      return
    }

    const { field, onSelect, keyProperty } = this.props

    if (this.state.isLoaded) {
      field.ValueID = (value && this.state.dataList.length) ? this.state.dataList[0][keyProperty] : ''
      field.Value = (value && this.state.dataList.length) ? this.state.dataList[0].OptionDescription : ''

      if (onSelect) { onSelect(field) }
    } else {
      setTimeout(() => { this.onBlur(value) }, 300)
    }
  }

  fetchData (text, autoSelectIfPossible = false) {
    const { field, publicFormToken, filter, keyProperty, onSelect, onChange, externalSourceEndpoint } = this.props
    const FieldID = field.ProcessTemplateFieldID || field.ID // grab the correct ID field depending on if it's a template or instance
    const ProcessID = field.ProcessTemplateID
    this.setState({ isLoaded: false })

    let endpoint = ''

    if (externalSourceEndpoint) {
      endpoint = externalSourceEndpoint
    } else {
      endpoint = (publicFormToken) ? `public/publicform/field/${FieldID}/option/list` : `processtemplate/${ProcessID}/field/${FieldID}/externaloption/list`
    }

    const headers = (publicFormToken) ? { token: publicFormToken } : {}

    let sanitizedText = text.replace('&', '%26')

    endpoint += `?text=${sanitizedText}`

    if (filter) {
      if (filter.charAt(0) !== '&') {
        endpoint += '&'
      }

      endpoint += `${filter}`
    }

    this.props.dispatch(get(endpoint,
      {
        onSuccess: (response) => {
          let processTemplateFieldOptionList = response.ProcessTemplateFieldOptionList || []
          this.setState({
            dataList: processTemplateFieldOptionList,
            isLoaded: true
          }, () => {
            if (autoSelectIfPossible) {
              if (processTemplateFieldOptionList.length === 1) {
                field.ValueID = processTemplateFieldOptionList[0][keyProperty]
                field.Value = processTemplateFieldOptionList[0].OptionDescription

                if (onSelect && onChange) {
                  onChange(field)
                  setTimeout(() => { onSelect(field) }, 200)
                }
              } else {
                this.setState({ edit: true })
              }
            }
          })
        },
        headers
      }
    ))
  }

  render () {
    const { field, wrapperProps, onChange, onSelect, clickableLink, keyProperty, actionButton, hintText } = this.props
    const { dataList, edit } = this.state
    const { muiTheme: { palette } } = this.context
    const fieldOptions = []
    const fieldID = field.ProcessTemplateFieldID || field.ID

    dataList.map((option) => {
      fieldOptions.push(
        { key: option[keyProperty], text: option.OptionDescription, value: option.OptionDescription }
      )
    })

    return (
      <FieldWrapper
        label={FormHelper.decodeHTML(field.FieldName)}
        required={field.RequiredField}
        showDivider={(!edit)}
        {...wrapperProps}
      >
        {(edit && !field.ReadOnlyField)
          ? <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormsyAutoComplete
              fullWidth
              name={field.FieldName + fieldID}
              dataSource={fieldOptions}
              onNewRequest={this.handleChange.bind(this)}
              onUpdateInput={(text) => {
                this.setState({ isLoaded: false })
                setTimeout(() => {
                  var lastCharacterOfSearch = text.substr(text.length - 1)
                  var searchText = field.Value || ''

                  if (lastCharacterOfSearch === searchText.substr(searchText.length - 1) && text.length === searchText.length) {
                    this.fetchData(text)
                  }
                }, 800)

                field.Value = text

                if (text === '') {
                  field.ValueID = ''
                }

                if (onChange) {
                  onChange(field)
                }
              }}
              searchText={field.Value}
              hintText={hintText || null}
              onBlur={(e) => this.onBlur(e.currentTarget.value)}
              onKeyDown={(e) => {
                if (e.key === 'Tab') {
                  this.onBlur(e.currentTarget.value)
                }
              }}
              value={(field.ValueID) ? field.ValueID : null}
              required={field.RequiredField}
              validationErrors={this.context.language.messages.validationErrors}
              menuProps={{ onClick: () => this.setState({ itemClicked: true }) }}
            />
            {(actionButton && field.CanAddProcessInstanceLookup)
              ? <Button
                onClick={actionButton}
                color='primary'
                variant='contained'
                style={{
                  //position: 'absolute',
                  right: '4px',
                  width: '24px',
                  minWidth: '48px',
                  margin: '10px'
                }}
                //buttonStyle={{ lineHeight: '33px' }}
              >
                <Add />
              </Button>
              : null}
          </div>
          : <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ flex: 5, paddingTop: '15px', width: '80%' }}>
              <a target='_blank' style={{ wordWrap: 'break-word', cursor: 'pointer' }}
                 onClick={clickableLink}>{field.Value}</a>
            </div>
            {(!field.ReadOnlyField)
              ? <div style={{ flex: 1 }}>
                <Tooltip title='Edit'>
                  <IconButton
                    onClick={() => this.setState({ edit: true })}
                  >
                    <Edit nativeColor={palette.accent3Color} />
                  </IconButton>
                </Tooltip>
              </div>
              : null}
          </div>
        }
      </FieldWrapper>
    )
  }
}

ExternalSource.propTypes = {
  field: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  clickableLink: PropTypes.func,
  actionButton: PropTypes.func,
  publicFormToken: PropTypes.string,
  filter: PropTypes.string,
  keyProperty: PropTypes.string,
  externalSourceEndpoint: PropTypes.string,
  hintText: PropTypes.string,
  wrapperProps: PropTypes.object
}

ExternalSource.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default ExternalSource
