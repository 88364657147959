import PropTypes from 'prop-types'
import React from 'react'
import { Button, TextField } from '@material-ui/core'
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import theme from '~/styles/theme'

const newTheme = JSON.parse(JSON.stringify(theme))
newTheme.palette.primary.main = '#fff'
newTheme.overrides.MuiInput = {
  underline: {
    '&:before': {
      borderColor: '#fff',
      borderBottom: '1px solid #fff'
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: '2px solid #fff'
    }
  }
}

newTheme.overrides.MuiSelect = {
  icon: {
    color: '#fff'
  }
}

const mStyles = {
  labelStyle: {
    color: '#fff',
    WebkitTextFillColor: '#fff'
  }
}

const styles = theme => ({
  root: {
    width: '100%',
    color: '#fff',
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderColor: '#fff !important'
    }
  },
  underline: {
    '&:before': {
      borderBottom: '1.2px solid #fff'
    },
    '&:after': {
      borderBottom: '2px solid #fff'
    }
  },
  disabled: {},
  focused: {},
  error: {}
})

class ForgotPassword extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      email: ''
    }
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const { handleSubmit, cancel, isAuthenticating, classes } = this.props
    const { email } = this.state

    return (
      <form
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '300px' }}
        autoComplete='off'
      >
        <div style={{ textAlign: 'center', marginBottom: '10px' }}>Enter your email address and we will send you a password reset email</div>
        <MuiThemeProvider theme={createMuiTheme(newTheme)}>
          <TextField
            name='email'
            type='email'
            label={language.translate('application.emailAddress')}
            InputLabelProps={{ style: mStyles.labelStyle }}
            InputProps={{ classes: { root: classes.root, underline: classes.underline } }}
            fullWidth
            onChange={e => this.setState({ email: e.currentTarget.value })}
          />
        </MuiThemeProvider>
        <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%', marginTop: '20px' }}>
          <Button
            color='primary'
            onClick={() => {
              handleSubmit(email)
            }}
            disabled={!email || isAuthenticating}
            variant='contained'
          >
            {language.translate('application.reset')}
          </Button>
          <Button
            style={{ backgroundColor: palette.errorColor, color: palette.canvasColor }}
            onClick={cancel}
            variant='contained'
          >
            {language.translate('application.cancel')}
          </Button>
        </div>
      </form>
    )
  }
}

ForgotPassword.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  cancel: PropTypes.func,
  isAuthenticating: PropTypes.bool,
  classes: PropTypes.object.isRequired
}

ForgotPassword.contextTypes = {
  muiTheme: PropTypes.object,
  language: PropTypes.object
}

export default withStyles(styles)(ForgotPassword)
