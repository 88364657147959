import PropTypes from 'prop-types'
import React from 'react'
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core'
import { Clear } from '@material-ui/icons'
import { Form } from 'formsy-react'
import { FormsyDate } from 'formsy-material-ui'
import moment from 'moment'
import CancelButton from '../Forms/CancelButton'

class FilterPanel extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      startDate: props.startDate,
      endDate: props.endDate,
      canSubmit: true
    }
  }

  render () {
    const { onSubmit, close } = this.props
    const { startDate, endDate } = this.state
    const palette = this.context.muiTheme.palette
    const { language, user } = this.context

    return (
      <Dialog
        open={this.props.open}
        onClose={() => this.props.close()}
      >
        <DialogTitle
          style={{
            backgroundColor: palette.headerBackgroundColor
          }}
          disableTypography
        >
          <Typography
            variant='h6'
            style={{ color: palette.alternateTextColor }}>
            {language.translate('application.filter')}
          </Typography>
        </DialogTitle>
        <DialogContent style={{ width: '350px' }}>
          <Form
            onValid={() => this.setState({ canSubmit: true })}
            onInvalid={() => this.setState({ canSubmit: false })}
            style={{ display: 'flex', flexDirection: 'column' }}
            autoComplete='off'
          >
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center'
            }}
            >
              <FormsyDate
                floatingLabelText={language.translate('application.startDateLabel')}
                firstDayOfWeek={0}
                name={'start_date'}
                value={(startDate) ? moment(startDate, user.dateFormat).toDate() : null}
                validationErrors={language.messages.validationErrors}
                formatDate={date => moment(date).format(user.dateFormat)}
                onChange={(event, date) => {
                  this.setState({ startDate: moment(date).format(`${user.dateFormat} HH:mm`) })
                }}
              />
              {(startDate)
                ? <IconButton
                  onClick={() => this.setState({ startDate: null })}
                >
                  <Clear />
                </IconButton>
                : null}
            </div>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center'
            }}
            >
              <FormsyDate
                floatingLabelText={language.translate('application.endDateLabel')}
                firstDayOfWeek={0}
                name={'end_date'}
                value={(endDate) ? moment(endDate, user.dateFormat).toDate() : null}
                validationErrors={language.messages.validationErrors}
                formatDate={date => moment(date).format(user.dateFormat)}
                onChange={(event, date) => {
                  this.setState({ endDate: moment(date).add(1, 'day').subtract(1, 'minute').format(`${user.dateFormat} HH:mm`) })
                }}
              />
              {(endDate)
                ? <IconButton
                  onClick={() => this.setState({ endDate: null })}
                >
                  <Clear />
                </IconButton>
                : null}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}>
              <Button
                color='primary'
                variant='contained'
                onClick={() => {
                  onSubmit(this.state)
                  close()
                }}
              >
                {language.translate('application.submit')}
              </Button>
              <CancelButton
                onClick={() => close()}
                variant='contained'
              />
            </div>
          </Form>
        </DialogContent>
      </Dialog>
    )
  }
}

FilterPanel.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  onSubmit: PropTypes.func
}

FilterPanel.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object,
  user: PropTypes.object
}

export default FilterPanel
