import PropTypes from 'prop-types'
import React from 'react'
import { findDOMNode } from 'react-dom'
import { Button, Card, CardContent } from '@material-ui/core'
import { TimelineMax, TweenMax } from 'gsap'
import { Formik, Form } from 'formik'
import GroupUserSelect from '../Forms/GroupUserSelect'
import CancelButton from '../Forms/CancelButton'
import Checkbox from '../Forms/Checkbox'
import TextField from '../Forms/TextField'
import * as Yup from 'yup'
import GroupUserSelectFields from '../Forms/GroupUserSelectFields'

class NewMessageForm extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      newMemo: this.initNewMemo(),
      canSubmit: true,
      showEmailInput: false,
      showGroupUserInput: !!(props.memoType === 'user' || props.defaultToUserId || props.defaultToGroupId)
    }
  }

  componentWillEnter (callback) {
    const el = findDOMNode(this)
    const t1 = new TimelineMax({ onComplete: callback })
    t1.set(el, { height: 'auto' })
      .from(el, 0.5, { height: 0, immediateRender: false })
  }

  componentWillLeave (callback) {
    const el = findDOMNode(this)
    TweenMax.fromTo(el, 0.2, {}, { height: 0, onComplete: callback })
  }

  initNewMemo () {
    let { defaultToGroupId, defaultToUserId } = this.props
    let newMemo = {}

    if (defaultToUserId || defaultToGroupId) {
      newMemo.ToUserGroupID = defaultToGroupId
      newMemo.ToUserID = defaultToUserId
    }

    return newMemo
  }

  sendMemoHandler (newMemo) {
    delete newMemo.postToProcess
    delete newMemo.showEmailInput
    delete newMemo.showGroupUserInput

    const { onSubmit, onClose, memoType, memoTypeId } = this.props

    this.setState({ canSubmit: false })

    if (memoType === 'template') { newMemo.ToProcessTemplateID = memoTypeId }

    if (memoType === 'instance') { newMemo.ToProcessInstanceID = memoTypeId }

    // keep userID and groupID independent of one another, don't submit both
    if (newMemo.ToUserID) { delete newMemo.ToUserGroupID }

    onSubmit(newMemo, () => {
      onClose()
      this.setState({ newMemo: {}, canSubmit: true })
    })
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const { newMemo, canSubmit, showEmailInput, showGroupUserInput } = this.state
    const { dispatch, onClose, memoType } = this.props

    const recipientSelected = showEmailInput || showGroupUserInput || memoType !== 'user'

    let validations = { Subject: Yup.string().required() }
    if (showEmailInput) {
      validations.ToEmailAddress = Yup
        .string()
        .email()
        .required()
    }

    return (
      <Card
        className='memo-form'
        style={{ overflow: 'hidden', marginBottom: '10px' }}
      >
        <CardContent style={{ paddingBottom: '20px', backgroundColor: palette.accent2Color }}>
          <Formik
            initialValues={{
              postToProcess: true,
              showGroupUserInput: showGroupUserInput,
              showEmailInput: showEmailInput,
              ToEmailAddress: '',
              Subject: '',
              Body: '',
              PrivateMemo: false
            }}
            onSubmit={(values) => {
              this.sendMemoHandler(values)
            }}
            validationSchema={Yup.object().shape(validations)}
          >
            {({ values, errors, touched, handleChange, setValues, isValid }) => (
              <Form>
                <div style={{ border: `1px solid ${palette.borderColor}`, padding: '5px' }}>
                  {(memoType === 'instance' || memoType === 'template')
                    ? <Checkbox
                      label={`Post to process ${memoType} (always checked)`}
                      name='postToProcess'
                      onChange={(e, checked) => {
                        // prevent value from changing
                      }}
                      FormControlProps={{ style: { marginTop: '0px', marginBottom: '0px' } }}
                      InputLabelProps={{ style: { marginBottom: '0px' } }}
                    /> : null}
                  <Checkbox
                    label={language.translate('application.notifyUserOrGroup')}
                    name='showGroupUserInput'
                    onChange={(e, checked) => {
                      if (!checked) {
                        delete values.ToUserGroupID
                        delete values.ToUserID
                        setValues(values)
                      }
                      this.setState({ showGroupUserInput: !showGroupUserInput })
                      handleChange(e)
                    }}
                    FormControlProps={{ style: { marginTop: '0px', marginBottom: '0px' } }}
                    InputLabelProps={{ style: { marginBottom: '0px' } }}
                  />
                  <Checkbox
                    label={language.translate('application.notifyViaEmail')}
                    name='showEmailInput'
                    onChange={(e, checked) => {
                      if (!checked) {
                        delete values.ToEmailAddress

                        setValues(values)
                      }

                      this.setState({ showEmailInput: !showEmailInput })

                      handleChange(e)
                    }}
                    FormControlProps={{ style: { marginTop: '0px', marginBottom: '0px' } }}
                    InputLabelProps={{ style: { marginBottom: '0px' } }}
                  />
                  {(!recipientSelected)
                    ? <div style={{ color: palette.errorColor }}>
                      {language.translate('application.atLeastOneRecipientType')}
                    </div>
                    : null}
                  {(showGroupUserInput)
                    ? <GroupUserSelectFields
                      label={language.translate('application.sendToProcessPlanUserOrGroup')}
                      groupFieldName='ToUserGroupID'
                      userFieldName='ToUserID'
                      dispatch={dispatch}
                      required
                      groupValue={newMemo.ToUserGroupID}
                      userValue={newMemo.ToUserID}
                      handleChange={handleChange}
                      allowAllUsers
                      allowAllGroups
                    /> : null}
                  {(showEmailInput)
                    ? <TextField
                      label={language.translate('application.sendToEmailAddress')}
                      name='ToEmailAddress'
                      required
                      fullWidth
                    />
                    : null}
                </div>
                <TextField
                  label={language.translate('application.subject')}
                  required
                  name='Subject'
                  fullWidth
                />
                <TextField
                  label={language.translate('application.typeMessageHere')}
                  name='Body'
                  multiline
                  rows={4}
                  fullWidth
                />
                {(memoType !== 'user' && showGroupUserInput)
                  ? <Checkbox
                    label={language.translate('application.keepMessagePrivate')}
                    name='PrivateMemo'
                    FormControlProps={{ style: { marginTop: '0px', marginBottom: '0px' } }}
                    InputLabelProps={{ style: { marginBottom: '0px' } }}
                  /> : null}
                <Button
                  //onClick={this.sendMemoHandler.bind(this)}
                  //disabled={!canSubmit || !recipientSelected}
                  type='submit'
                  disabled={!isValid}
                  color='primary'
                  variant='contained'
                >
                  {language.translate('application.send')}
                </Button>
                <CancelButton
                  variant='contained'
                  onClick={() => onClose()}
                  style={{ marginLeft: '20px' }}
                />
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    )
  }
}

NewMessageForm.propTypes = {
  onSubmit: PropTypes.func,
  dispatch: PropTypes.func,
  onClose: PropTypes.func,
  memoType: PropTypes.string,
  memoTypeId: PropTypes.string,
  defaultToUserId: PropTypes.string,
  defaultToGroupId: PropTypes.string
}

NewMessageForm.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default NewMessageForm
