import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'react-router-redux'
import { Avatar, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { get } from '../../actions/base'
import { Email, PermIdentity } from '@material-ui/icons'

class ContactCardDialog extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      user: [],
      isLoaded: false,
      pendingTasks: 0
    }
  }

  componentDidMount () {
    if (this.props.userID) {
      this.fetchUser()
      this.fetchPendingTaskCount()
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.userID !== prevProps.userID) {
      this.fetchUser()
      this.fetchPendingTaskCount()
    }
  }

  fetchUser () {
    this.props.dispatch(get(`user/${this.props.userID}`, {
      onSuccess: (response) => {
        if (!this.isUnmounted) {
          this.setState({
            user: response.User,
            isLoaded: true
          })
        }
      }
    }))
  }

  fetchPendingTaskCount () {
    this.props.dispatch(get(`user/${this.props.userID}/task/list/pending/count`, {
      onSuccess: (response) => {
        this.setState({
          pendingTasks: response.Count
        })
      }
    }))
  }

  render () {
    const { user, pendingTasks } = this.state
    const palette = this.context.muiTheme.palette
    const { push } = this.props
    const { language } = this.context

    return (
      <Dialog
        open={this.props.open}
        onClose={() => this.props.close()}
      >
        <DialogTitle
          style={{
            backgroundColor: palette.headerBackgroundColor
          }}
          disableTypography
        >
          <Typography
            variant='h6'
            style={{ color: palette.alternateTextColor }}>
            <div>
              <PermIdentity nativeColor={palette.alternateTextColor} style={{ marginBottom: '-3px' }} />
              <span style={{ paddingLeft: '5px' }}>{language.translate('application.contactCard')}</span>
            </div>
          </Typography>
        </DialogTitle>
        <DialogContent style={{ width: '350px' }}>
          {(!this.state.isLoaded)
            ? <CircularProgress className='loader' />
            : <div style={{ paddingTop: '20px' }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ flex: 1, textAlign: 'center' }}>
                  <Avatar src={user.ProfilePicURL} style={{ height: '70px', width: '70px' }} />
                </div>
                <div style={{ flex: 3 }}>
                  <ul style={{ listStyleType: 'none' }}>
                    <li><strong>{user.FullName}</strong></li>
                    <li>{user.Position}</li>
                    <li>{user.Department}</li>
                  </ul>
                  <Button
                    style={{ color: palette.accent3Color, marginLeft: '30px' }}
                    size='small'
                    onClick={() => {
                      push(`/reports?type=task&endpoint=user/${user.ID}/task/list/pending&tag_endpoint=user/${user.ID}/task/list/pending/tag/list`)
                    }}
                  >
                    {language.translate('application.tasksPending')}: {pendingTasks}
                  </Button>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ flex: 1, textAlign: 'center' }}>
                  <a href={`mailto:${user.EmailAddress}`}>
                    <Email nativeColor={palette.textColor} style={{ height: '50px', width: '50px' }} />
                  </a>
                </div>
                <div style={{ flex: 3 }}>
                  <ul style={{ listStyleType: 'none' }}>
                    <li><a href={`mailto:${user.EmailAddress}`}>{user.EmailAddress}</a></li>
                    <li>{language.translate('application.main')}: <a
                      href={`tel:${user.MainTelephone}`}
                    >{user.MainTelephone}</a></li>
                    <li>{language.translate('application.mobile')}: <a
                      href={`tel:${user.MobileTelephone}`}
                    >{user.MobileTelephone}</a></li>
                    <li>{user.TimeZone}</li>
                  </ul>
                </div>
              </div>
            </div>
          }
        </DialogContent>
      </Dialog>
    )
  }
}

ContactCardDialog.propTypes = {
  dispatch: PropTypes.func.isRequired,
  userID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired
}

ContactCardDialog.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  dispatch,
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactCardDialog)
