import {
  LOGOUT_USER, SET_ACCOUNT_DATA, SET_LOGGED_IN, SET_LOGIN_DATA, SET_USER_DATA, UPDATE_HELP_PAGES_VIEWED,
  UPDATE_PROFILE_PIC_URL, UPDATE_USER_SESSION
} from '../constants/AuthenticationTypes'
import { push } from 'react-router-redux'
import { post } from './base'
import { disconnectPusher } from './pusher'
import objectAssign from 'object-assign'
import { apiServer } from '../config'

export function loginUserSuccess (payload, rememberMe = false, accountList = []) {
  return function (dispatch) {
    window.localStorage.setItem('rememberMe', rememberMe)

    payload.User.Accounts = accountList

    dispatch(setLoginData(payload.User))
    dispatch(setUserData(payload.User))
    dispatch(setAccountData(payload.Account))
    dispatch(setLoggedIn())
  }
}

function setLoggedIn () {
  return {
    type: SET_LOGGED_IN
  }
}

export function setLoginData (user) {
  window.localStorage.setItem('token', user.SessionToken)
  window.localStorage.setItem('accountList', JSON.stringify(user.Accounts))
  document.cookie = `SessionToken=${user.SessionToken}; domain=processplan.com;path=/`

  return {
    type: SET_LOGIN_DATA,
    payload: user
  }
}

export function setUserData (user) {
  window.localStorage.setItem('username', user.EmailAddress)
  window.localStorage.setItem('userid', user.ID)
  window.localStorage.setItem('groupIDs', JSON.stringify(user.UserGroupIDList))
  window.localStorage.setItem('firstName', user.FirstName)
  window.localStorage.setItem('lastName', user.LastName)
  window.localStorage.setItem('fullName', user.FullName)
  window.localStorage.setItem('position', user.Position)
  window.localStorage.setItem('apiServer', `https://${user.DataServerRegion}.processplan.com`)
  window.localStorage.setItem('utcOffset', user.UTCOffset)
  window.localStorage.setItem('profilePicURL', user.ProfilePicURL)
  window.localStorage.setItem('language', user.Language.replace('-', '_') || 'en_US')
  window.localStorage.setItem('dateFormat', user.DateFormat || 'YYYY-MM-DD')
  window.localStorage.setItem('helpPagesViewed', JSON.stringify(user.HelpPagesViewed))
  window.localStorage.setItem('userCustomizations', (user.Customizations) ? user.Customizations : JSON.stringify({}))
  window.localStorage.setItem('canCancelProcesses', user.AccessControl_CancelProcesses)
  window.localStorage.setItem('canInitiateProcesses', user.AccessControl_InitiateProcesses)
  window.localStorage.setItem('canManageAccountInformation', user.AccessControl_ManageAccountInformation)
  window.localStorage.setItem('canManageProcessTemplates', user.AccessControl_ManageProcessTemplates)
  window.localStorage.setItem('canChangeTaskAssignments', user.AccessControl_ChangeTaskAssignments)
  window.localStorage.setItem('canManageSubscriptionLevel', user.AccessControl_ManageSubscriptionLevel)
  window.localStorage.setItem('canManageUsers', user.AccessControl_ManageUsers)
  window.localStorage.setItem('canViewAllProcesses', user.AccessControl_ViewAllProcesses)
  window.localStorage.setItem('canViewMyProcesses', user.AccessControl_ViewMyProcesses)
  window.localStorage.setItem('canViewProcessReports', user.AccessControl_ViewProcessReports)
  window.localStorage.setItem('canEditInstanceDiagrams', user.AccessControl_EditInstanceDiagrams)
  window.localStorage.setItem('canScheduleOrLaunchMultipleProcesses', user.AccessControl_BatchProcessing)
  window.localStorage.setItem('canRestartInstanceTasks', user.AccessControl_RestartInstanceTasks)
  window.localStorage.setItem('canCreateProcessTemplates', user.AccessControl_CreateProcessTemplates)
  window.localStorage.setItem('isOnDemandUser', user.IsOnDemand)
  document.cookie = `Username=${user.EmailAddress}; domain=processplan.com;path=/`

  return {
    type: SET_USER_DATA,
    payload: user
  }
}

export function setAccountData (account, setDateFormat = false) {
  account.Customizations = (account.Customizations) ? JSON.parse(account.Customizations) : {}

  window.localStorage.setItem('accountName', account.AccountName || '')
  window.localStorage.setItem('accountID', account.ID || '')
  window.localStorage.setItem('accountStatus', account.AccountStatus || '')
  window.localStorage.setItem('accountActive', account.AccountActive)
  window.localStorage.setItem('accountImageLight', account.HeaderLogoWhiteBG_URL || '')
  window.localStorage.setItem('accountImageDark', account.HeaderLogoDarkBG_URL || '')
  window.localStorage.setItem('partnerImageLight', account.PartnerHeaderLogoWhiteBG_URL || '')
  window.localStorage.setItem('partnerImageDark', account.PartnerHeaderLogoDarkBG_URL || '')
  window.localStorage.setItem('partnerWebsiteURL', account.PartnerHeaderWebsiteURL || '')
  window.localStorage.setItem('accountCreationDateUTC', account.CreatedDate_UTC || '')
  window.localStorage.setItem('templateLibraryAccount', account.TemplateLibraryAccount)
  window.localStorage.setItem('timeTrackingUnit', account.TimeTrackingUnit)
  window.localStorage.setItem('customizations', JSON.stringify(account.Customizations))
  window.localStorage.setItem('ssoEnabled', account.SSOEnabled)
  window.localStorage.setItem('samlSignOnURL', account.SAML_SingleSignOnUrl)
  window.localStorage.setItem('samlLogoutURL', account.SAML_SingleLogoutUrl)

  if (setDateFormat) {
    window.localStorage.setItem('dateFormat', account.DefaultDateFormat || 'YYYY-MM-DD')
  }

  account.setDateFormat = setDateFormat

  return {
    type: SET_ACCOUNT_DATA,
    payload: account
  }
}

export function parseCustomizations (userCustomizations) {
  const newTheme = JSON.parse(JSON.stringify(theme))
  let palette = newTheme.palette

  if (userCustomizations.theme && userCustomizations.theme.palette) { palette = objectAssign({}, palette, userCustomizations.theme.palette) }

  newTheme.palette = palette

  return { theme: newTheme }
}

export function logout () {
  // temporarily store users email and rememberMe option before blowing away window.localStorage
  const email = window.localStorage.getItem('username')
  const rememberMe = (window.localStorage.getItem('rememberMe') === 'true')
  const tempApiServer = window.localStorage.getItem('apiServer') || apiServer
  const twoFactorToken = window.localStorage.getItem('twofactortoken') || null

  // clean up window.localStorage and cookies
  window.localStorage.clear()
  document.cookie = 'Username='
  document.cookie = 'SessionToken='

  // persist user data if they selected rememberMe
  if (rememberMe) {
    window.localStorage.setItem('username', email)

    if (twoFactorToken) {
      window.localStorage.setItem('twofactortoken', twoFactorToken)
    }
  }

  window.localStorage.setItem('rememberMe', rememberMe)
  window.localStorage.setItem('apiServer', tempApiServer)

  return {
    type: LOGOUT_USER
  }
}

export function updateUserSession (property, value, isJson) {
  if (isJson) { window.localStorage.setItem(property, JSON.stringify(value)) } else { window.localStorage.setItem(property, value) }

  return {
    type: UPDATE_USER_SESSION,
    payload: {
      property,
      value
    }
  }
}

export function updateProfilePicURL (url) {
  window.localStorage.setItem('profilePicURL', url)

  return {
    type: UPDATE_PROFILE_PIC_URL,
    payload: url
  }
}

export function addViewedHelpPage (pages) {
  return function (dispatch, state) {
    const pagesViewed = (state().auth.helpPagesViewed) ? state().auth.helpPagesViewed : []

    pages.map((page) => {
      pagesViewed.push(page)
    })

    dispatch(updateUser({ HelpPagesViewed: pagesViewed }))
  }
}

export function removePageFromPagesViewed (pageInQuestion) {
  return function (dispatch, state) {
    const pagesViewed = state().auth.helpPagesViewed

    pagesViewed.map((page, index) => {
      if (pageInQuestion === page) { pagesViewed.splice(index, 1) }
    })

    dispatch(updateHelpPagesViewed(pagesViewed))
  }
}

function updateUser (data) {
  return function (dispatch, state) {
    const body = JSON.stringify({
      User: data
    })

    dispatch(post(`user/${state().auth.userid}`, body, {
      onSuccess: (response) => {
        window.localStorage.setItem('helpPagesViewed', JSON.stringify(response.User.HelpPagesViewed))
        dispatch(updateHelpPagesViewed(response.User.HelpPagesViewed))
      }
    }))
  }
}

export function hasViewedHelpPage (pageInQuestion) {
  return function (dispatch, state) {
    let hasViewedPage = false

    state().auth.helpPagesViewed.map((page) => {
      if (page === pageInQuestion) { hasViewedPage = true }
    })

    return hasViewedPage
  }
}

function updateHelpPagesViewed (pages) {
  return {
    type: UPDATE_HELP_PAGES_VIEWED,
    payload: pages
  }
}

export function logoutAndRedirect (redirect = '/', query = {}, disableRedirect = false) {
  return function (dispatch, state) {
    if (state().auth.isAuthenticated) {
      if (!disableRedirect) {
        dispatch(disconnectPusher())
        dispatch(logout())
        if (state().auth.ssoEnabled && state().auth.samlLogoutURL) {
          window.open(state().auth.samlLogoutURL, '_self')
        } else {
          const newQuery = objectAssign({},
            query,
            {
              next: redirect
            })

          dispatch(push({
            pathname: '/login',
            query: newQuery
          }))
        }
      }
    }
  }
}
