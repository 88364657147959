import PropTypes from 'prop-types'
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  Card, CardContent, LinearProgress, Typography
} from '@material-ui/core'
import FormHelper from '../../businessLogic/formHelper'
import { Icon, GeneratedIcon } from '~/components/Icon'

const styles = theme => ({
  colorSuccess: {
    backgroundColor: theme.palette.borderColor
  },
  barColorSuccess: {
    backgroundColor: theme.palette.successColor
  }
})

class RedGreenReportCard extends React.Component {
  getPriorityColor (pastDueTaskCount, proximityWarning, completedDate, cancelledDate) {
    const palette = this.context.muiTheme.palette

    if (completedDate) { return palette.primary3Color }

    if (cancelledDate) { return palette.textColor }

    if (pastDueTaskCount > 0) { return palette.errorColor }

    if (proximityWarning) { return palette.warningColor }

    return palette.successColor
  }

  render () {
    const palette = this.context.muiTheme.palette
    const process = this.props.process
    const { classes } = this.props

    return (
      <Card
        className='card clickable'
        onClick={() => this.props.onClick(process.ID)}
        style={{
          margin: '10px'
        }}
      >
        <LinearProgress
          variant='determinate'
          value={process.Tasks_ProgressPercentage || 0}
          classes={{ colorPrimary: classes.colorSuccess, barColorPrimary: classes.barColorSuccess }}
          style={{ height: '6px' }}
        />
        <CardContent style={{ padding: '0px' }}>
          <div style={{ display: 'flex' }}>
            <div
              style={{
                backgroundColor: `${this.getPriorityColor(process.Tasks_PastDueCount, process.OldestPendingTask_DueDateProximityWarning, process.CompletedDate_Local, process.CanceledDate_Local)}`,
                padding: '10px',
                display: 'flex',
                alignItems: 'center'
              }}>
              {(process.ProcessTemplateIconName)
                ? <Icon icon={process.ProcessTemplateIconName} size={30} />
                : <GeneratedIcon
                  text={process.ProcessTemplateTitle}
                  randomizer={process.ProcessTemplateID}
                />}
            </div>
            <div style={{ padding: '5px' }}>
              <Typography
                variant={'body2'}
                style={{ fontWeight: '600' }}>{FormHelper.decodeHTML(process.ProcessTemplateTitle)}</Typography>
              <Typography variant={'body2'} style={{
                color: palette.accent3Color,
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical'
              }}>{
                `${FormHelper.decodeHTML(process[`HeaderField1_Name`])}: ${
                  FormHelper.decodeHTML(process[`HeaderField1_Value`])}`
              }</Typography>
            </div>
          </div>
        </CardContent>
      </Card>
    )
  }
}

RedGreenReportCard.propTypes = {
  process: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
}

RedGreenReportCard.contextTypes = {
  muiTheme: PropTypes.object,
  user: PropTypes.object,
  language: PropTypes.object
}

export default withStyles(styles)(RedGreenReportCard)
