import { fade } from '@material-ui/core/styles/colorManipulator'
import { toggleNavigation } from '../../actions/navigation'
import { AppBar, IconButton, Toolbar, Typography, withStyles } from '@material-ui/core'
import Subscriber from './Notifications/Subscriber'
import MenuIcon from '@material-ui/icons/Menu'
import Notifications from './Notifications'
import { bindActionCreators } from 'redux'
import { push } from 'react-router-redux'
import objectAssign from 'object-assign'
import { get, post } from '../../actions/base'
import { updateUserSession } from '~/actions/authentication'
import ContentMenu from './ContentMenu'
import AccountMenu from './AccountMenu'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Icon from '../Icon/Icon'
import React from 'react'

const styles = theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  }
})

class Header extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      unviewedNotificationCount: 0,
      unviewedMessageCount: 0
    }
  }

  componentDidMount () {
    this.setNavPanel()
    this.fetchUnviewedNotificationCount()
    this.fetchUnviewedMessageCount()
  }

  fetchUnviewedNotificationCount () {
    const { user } = this.context

    this.props.get(`user/${user.userid}/notification/list/unviewed/count`, {
      onSuccess: (response) => {
        this.setState({
          unviewedNotificationCount: response.Count
        })
      },

      headers: {},
      isHandshake: null,
      isSecondary: true
    })
  }

  fetchUnviewedMessageCount () {
    const { user } = this.context
    const endpoint = `User/${user.userid}/memo/list/unviewed/count`

    this.props.get(endpoint, {
      onSuccess: (response) => {
        this.setState({
          unviewedMessageCount: response.Count
        })
      },

      headers: {},
      isHandshake: null,
      isSecondary: true
    })
  }

  showNotifications () {
    const { location } = this.context

    this.props.push({
      pathname: location.pathname,
      query: objectAssign({},
        location.query,
        {
          ptype: 'notifications'
        })
    })
  }

  showUserMessages () {
    const { user, location } = this.context

    this.props.push({
      pathname: location.pathname,
      query: objectAssign({},
        location.query,
        {
          ptype: 'memos',
          memoType: 'user',
          pid: user.userid
        })
    })
  }

  setNavPanel () {
    const { user, navigation } = this.props

    if (user.userCustomizations && user.userCustomizations.isNavOpen === false && navigation.open) {
      this.props.dispatch(toggleNavigation())
    }
  }

  handleToggle () {
    const { navigation, post, user } = this.props

    let customizations = user.userCustomizations || {}

    customizations.isNavOpen = !navigation.open

    const body = JSON.stringify({
      User: { Customizations: JSON.stringify(customizations) }
    })

    post(`user/${user.userid}`, body)

    updateUserSession('userCustomizations', customizations, true)

    this.props.dispatch(toggleNavigation())
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { location, user } = this.context
    const { header, dispatch, screenWidth, classes } = this.props
    const { unviewedNotificationCount, unviewedMessageCount } = this.state

    return (
      <AppBar
        className={classes.appBar}
        position='fixed'
        style={{ backgroundColor: palette.headerBackgroundColor }}
      >
        <Toolbar>
          {header.showTitle && (
            <IconButton color='inherit' aria-label='Menu' onClick={this.handleToggle.bind(this)}>
              <MenuIcon />
            </IconButton>
          )}
          {screenWidth > 400 && header.showTitle && (
            <IconButton
              onClick={() => dispatch(push('/'))}
              style={{ padding: '0px', marginLeft: '10px' }}
            >
              <Icon icon='PP' size={30} color={palette.headerFontColor} style={{ width: '40px', height: '40px' }} />
            </IconButton>
          )}
          {screenWidth > 400 && header.showTitle && (
            <div style={{
              borderRight: `1px solid ${fade(palette.headerFontColor, 0.2)}`,
              height: '35px',
              marginLeft: '20px'
            }}
            />
          )}
          <Typography
            variant='h5'
            style={{
              color: palette.headerFontColor,
              paddingLeft: (screenWidth > 500) ? '20px' : '0px',
              flexGrow: 1
            }}>
            {header.showTitle && screenWidth > 500 && this.props.title}
          </Typography>
          <div style={{ display: 'flex', marginRight: '20px' }}>
            <ContentMenu location={this.props.location} />
            <Notifications
              location={location}
              showNotifications={this.showNotifications.bind(this)}
              unviewedNotificationCount={unviewedNotificationCount}
              unviewedMessageCount={unviewedMessageCount}
              showUserMessages={this.showUserMessages.bind(this)}
              showIcons={(screenWidth >= 600)}
            />
            <AccountMenu
              currentPage={location.pathname}
              showNotifications={this.showNotifications.bind(this)}
              unviewedNotificationCount={unviewedNotificationCount}
              unviewedMessageCount={unviewedMessageCount}
              showUserMessages={this.showUserMessages.bind(this)}
              showIcons={(screenWidth < 600)}
            />
          </div>
        </Toolbar>
        <Subscriber
          channelName={`Private-${user.accountID}-User-${user.userid}`}
          events={['NotificationCreated', 'NotificationViewed', 'NotificationRead', 'NotificationArchived']}
          callback={this.fetchUnviewedNotificationCount.bind(this)}
        />
        <Subscriber
          channelName={`Private-${user.accountID}-User-${user.userid}`}
          events={['MemoCreated', 'MemoViewed', 'MemoRead', 'MemoArchived']}
          callback={() => {
            setTimeout(() => this.fetchUnviewedMessageCount(), 1000)
          }}
        />
      </AppBar>
    )
  }
}

Header.propTypes = {
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.string,
  header: PropTypes.object,
  location: PropTypes.object,
  user: PropTypes.object,
  navigation: PropTypes.object,
  push: PropTypes.func,
  get: PropTypes.func,
  post: PropTypes.func,
  mainContentWidth: PropTypes.number,
  screenWidth: PropTypes.number,
  classes: PropTypes.object.isRequired
}

Header.contextTypes = {
  muiTheme: PropTypes.object,
  location: PropTypes.object,
  user: PropTypes.object
}

const mapStateToProps = state => ({
  title: state.application.title,
  header: state.header,
  user: state.auth,
  navigation: state.navigation,
  mainContentWidth: state.application.mainContentWidth,
  screenWidth: state.application.screenWidth
})

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  get: bindActionCreators(get, dispatch),
  post: bindActionCreators(post, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Header))
