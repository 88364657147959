import PropTypes from 'prop-types'
import React from 'react'
import { ErrorOutline } from '@material-ui/icons'
import grey from '@material-ui/core/colors/grey'

const fontStyle = {
  color: grey[400]
}

class NotFound extends React.Component {
  render () {
    const { language } = this.context

    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
      }}
      >
        <div
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          <ErrorOutline
            style={{ height: '10em', width: '10em' }}
            nativeColor={fontStyle.color}
          />
          <div style={{ fontSize: '8em', color: fontStyle.color }}>
            404
          </div>
        </div>
        <div style={{ fontSize: '2em', color: fontStyle.color, textAlign: 'center' }}>
          {language.translate('application.404')}
        </div>
      </div>
    )
  }
}

NotFound.contextTypes = {
  language: PropTypes.object
}

export default NotFound
