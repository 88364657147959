import PropTypes from 'prop-types'
import React from 'react'
import {
  Divider, FormControl, InputLabel, List, ListItem, ListItemText, ListItemSecondaryAction, MenuItem, Select,
  Switch
} from '@material-ui/core'

class AdvancedSettings extends React.Component {
  shouldComponentUpdate (nextProps, nextState) {
    if (this.props.template !== nextProps.template) { return true }

    return true
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const { template, onSave } = this.props

    return (
      <List key={1} style={{ padding: '0px 20px', backgroundColor: palette.accent2Color }}>
        <ListItem>
          <ListItemText primary={language.translate('application.processPriorityHigh')} />
          <ListItemSecondaryAction>
            <Switch
              color='primary'
              checked={(template.Priority === 1)}
              onChange={(obj, value) => {
                template.Priority = (template.Priority === 1) ? 2 : 1
                onSave(template)
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <FormControl style={{ width: '260px', marginLeft: '14px' }}>
          <InputLabel style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {language.translate('application.numberProcessFieldsToDisplay')}
          </InputLabel>
          <Select
            name='number_fields_to_display'
            value={template.NumberOfHeaderFieldsToDisplay || ''}
            onChange={(e) => {
              template.NumberOfHeaderFieldsToDisplay = e.target.value
              onSave(template)
            }}
          >
            {[
              <MenuItem value={1} key={1}>1</MenuItem>,
              <MenuItem value={2} key={2}>2</MenuItem>,
              <MenuItem value={3} key={3}>3</MenuItem>
            ]}
          </Select>
        </FormControl>
        <Divider />
        <ListItem>
          <ListItemText primary={language.translate('application.sendConfirmationEmailText')} />
          <ListItemSecondaryAction>
            <Switch
              color='primary'
              checked={template.SendEmailLaunchReply}
              onChange={(obj, value) => {
                template.SendEmailLaunchReply = !template.SendEmailLaunchReply
                onSave(template)
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary={language.translate('application.requireTimeEntriesText')} />
          <ListItemSecondaryAction>
            <Switch
              color='primary'
              checked={template.TimeEntryRequired}
              onChange={(obj, value) => {
                template.TimeEntryRequired = !template.TimeEntryRequired
                onSave(template)
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <Divider />
        <div style={{
          fontSize: '16px',
          padding: '15px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
        >
          <div>{language.translate('application.processAvgDaysCompletion')}</div>
          <div>{template.AverageDaysToCompletion}</div>
        </div>
      </List>
    )
  }
}

AdvancedSettings.propTypes = {
  template: PropTypes.object,
  onSave: PropTypes.func
}

AdvancedSettings.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default AdvancedSettings
