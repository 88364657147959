import PropTypes from 'prop-types'
import React from 'react'
import { DatePicker, TimePicker } from 'material-ui'
import moment from 'moment'
import DateHelper from '../../businessLogic/dateHelper'

class DateField extends React.Component {
  constructor(props) {
    super(props)

    const { defaultDate, defaultValue } = this.props
    let date = defaultDate || defaultValue

    this.state = ({
      showTimePicker: false,
      date: (date) ? moment(date).toDate() : undefined,
      time: undefined
    });

    //componentWillMount migration start
    //this.initializeDateState()
    //componentWillMount migration end

  }

  // MIGRATED TO componentDidMount
  // componentWillMount () {
  //   this.initializeDateState()
  // }

  componentDidMount() {
    if (this.props.hideInput) {
      this.date_field.focus()
    }
  }

  initializeDateState() {
    const { defaultDate, defaultValue } = this.props
    let date = defaultDate || defaultValue
    this.setState({ date: (date) ? moment(date).toDate() : undefined })
  }

  fixDate(dateTime) {
    if (dateTime) {
      const newDate = moment(dateTime).toDate()
      const offset = newDate.getTimezoneOffset()

      return DateHelper.convertDateTimeToUTC(newDate, offset).toDate()
    }

    return dateTime
  }

  formatDate(date, includeTime = false) {
    const { user } = this.context

    if (includeTime) {
      let format = user.dateFormat || 'YYYY-MM-DD'
      format += 'THH:mm:ss'
      return moment(date).format(format)
    } else {
      return moment(date).format((user.dateFormat) ? user.dateFormat : 'YYYY-MM-DD')
    }
  }

  getDate() {
    if (this.date_field.getDate()) {
      return this.formatDate(this.date_field.getDate())
    }

    return null
  }

  getValue() {
    return this.getDate()
  }

  render() {
    const { label, defaultDate, defaultValue, hideInput, onChange, timePicker, onDismiss, ...rest } = this.props
    const { showTimePicker, date, time } = this.state

    return (
      <div>
        <DatePicker
          fullWidth
          floatingLabelText={label}
          ref={(input) => { this.date_field = input }}
          formatDate={this.formatDate.bind(this)}
          firstDayOfWeek={0}
          defaultDate={date}
          textFieldStyle={{ display: (hideInput) ? 'none' : false }}
          onDismiss={onDismiss}
          onChange={(e, value) => {
            if (timePicker) {
              this.setState({ showTimePicker: true, date: value })
            } else {
              onChange(this.formatDate(value))
            }
          }}
          {...rest}
        />
        {(showTimePicker)
          ? <TimePicker
            name='start_time'
            ref={(input) => { if (input) { input.focus() } }}
            textFieldStyle={{ display: (!showTimePicker) ? 'none' : '' }}
            pedantic
            defaultTime={date}
            onChange={(event, value) => {
              onChange(this.formatDate(value, true))
            }}
            onDismiss={onDismiss}
          />
          : null}
      </div>)
  }
}

DateField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  defaultDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  defaultValue: PropTypes.string,
  hideInput: PropTypes.bool,
  onChange: PropTypes.func,
  onDismiss: PropTypes.func,
  timePicker: PropTypes.bool
}

DateField.defaultProps = {
  hideInput: false
}

DateField.contextTypes = {
  user: PropTypes.object,
  onChange: () => { }
}

export default DateField
