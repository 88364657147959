import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Badge, IconButton, Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  primary: {
    backgroundColor: theme.palette.accent1Color
  },
  badge_hidden: {
    display: 'none'
  },
  badge_nothidden: {
  }
})

class NotificationIcon extends Component {
  render () {
    const { onClick, count, tooltip, icon, classes } = this.props

    return (
      <Tooltip title={tooltip}>
        <IconButton
          onClick={onClick}
        >
          <Badge
            badgeContent={count}
            classes={{
              colorPrimary: classes.primary,
              badge: (count === 0) ? classes.badge_hidden : classes.badge_nothidden
            }}
            color='primary'
          >
            {icon}
          </Badge>
        </IconButton>
      </Tooltip>
    )
  }
}

NotificationIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  count: PropTypes.number,
  tooltip: PropTypes.string,
  icon: PropTypes.object,
  classes: PropTypes.object.isRequired
}

NotificationIcon.contextTypes = {
  muiTheme: PropTypes.object
}

export default withStyles(styles)(NotificationIcon)
