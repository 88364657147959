import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

export function requireNoAuthentication (Component) {
  class RequireNoAuth extends React.Component {

    componentDidMount () {
      this.checkAuth()
    }

    componentDidUpdate () {
      this.checkAuth()
    }

    checkAuth () {
      if (this.props.user.isAuthenticated) {
        if (this.props.location.pathname === '/login') {
          let { next, ...query } = this.props.location.query

          this.props.dispatch(push({ pathname: next, query }))
        } else {
          this.props.dispatch(push('/'))
        }
      }
    }

    render () {
      return (
        this.props.user.isAuthenticated === false
          ? <Component {...this.props} />
          : null
      )
    }
  }

  const mapStateToProps = state => ({
    user: state.auth
  })

  RequireNoAuth.propTypes = {
    dispatch: PropTypes.func,
    user: PropTypes.object,
    location: PropTypes.object
  }

  return connect(mapStateToProps)(RequireNoAuth)
}
