import { ADD_NOTIFICATION, SET_NOTIFICATIONS } from '../constants/NotificationTypes'

export function setNotifications (notifications) {
  return {
    type: SET_NOTIFICATIONS,
    payload: {
      notifications
    }
  }
}

export function addNotification (notification) {
  return {
    type: ADD_NOTIFICATION,
    payload: {
      notification
    }
  }
}
