import PropTypes from 'prop-types'
import React from 'react'
import { Avatar, Button, Checkbox, FormControlLabel, IconButton, Paper, TextField, Tooltip } from '@material-ui/core'
import { Reply, Cancel } from '@material-ui/icons'
import { fade } from '@material-ui/core/styles/colorManipulator'
import moment from 'moment'
import Autolinker from 'autolinker'
import { GeneratedIcon, Icon } from '../Icon'

class MessageItem extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      reply: '',
      isExpanded: false,
      showReply: false,
      sendToAll: true,
      isPrivate: props.memo.PrivateMemo,
      isSubmitting: false,
      enableShowMore: false
    }
  }

  componentDidMount () {
    setTimeout(() => {
      if (this.messageBody && this.messageBody.scrollHeight - 1 > 40) { this.setState({ enableShowMore: true }) }
    }, 100)
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.isExpanded && !this.state.isExpanded) { this.setState({ reply: '' }) }
  }

  replyHandler () {
    const { reply, sendToAll, isPrivate } = this.state
    const { memo, onReply } = this.props

    this.setState({ isSubmitting: true })

    const replyMemo = {
      Body: reply,
      ParentMemoID: memo.ID,
      PrivateMemo: isPrivate
    }

    if (sendToAll) {
      replyMemo.ToEmailAddress = [memo.FromEmailAddress, memo.ToEmailAddress].filter(Boolean).join(';')
      replyMemo.ToUserID = memo.FromUserID
      replyMemo.ToUserGroupID = memo.ToUserGroupID
    } else {
      if (memo.FromEmailAddress) { replyMemo.ToEmailAddress = memo.FromEmailAddress }

      if (memo.FromUserID) { replyMemo.ToUserID = memo.FromUserID }
    }

    onReply(replyMemo,
      () => this.setState({ reply: '', showReply: false, isSubmitting: false }),
      () => this.setState({ isSubmitting: false }))
  }

  avatarElement (tooltip, avatar, generateFromString) {
    return (
      <Tooltip title={tooltip}>
        <IconButton
          style={{
            padding: '0px',
            height: 'inherit',
            width: 'inherit',
            cursor: 'default',
            marginLeft: '5px',
            zIndex: 'inherit'
          }}
        >
          {(generateFromString)
            ? <GeneratedIcon
              text={avatar}
              randomizer={tooltip}
              style={{ height: '20px', width: '20px' }}
            />
            : <Avatar
              src={avatar}
              style={{ height: '20px', width: '20px' }}
            />
          }
        </IconButton>
      </Tooltip>
    )
  }

  goToTemplate () {
    const { push, memo } = this.props
    const { location } = this.context

    push({
      pathname: `/process-template/${memo.ToProcessTemplateID}`,
      query: location.query
    })
  }

  goToInstance () {
    const { push, memo } = this.props
    const { location } = this.context

    push({
      pathname: `/process-visual-progress/${memo.ToProcessInstanceID}`,
      query: location.query
    })
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const { memo } = this.props
    const { reply, isExpanded, isPrivate, sendToAll, isSubmitting, showReply, enableShowMore } = this.state

    const fromName = memo.FromUserFullName || memo.FromEmailAddress
    const paperStyle = { borderRadius: 'inherit' }
    const avatarStyle = { margin: '0px 5px', height: '50px', width: '50px' }

    if (!memo.CurrentUser_ViewedDate_UTC) {
      paperStyle.boxShadow = 'none'
      paperStyle.backgroundColor = fade(palette.primary1Color, 0.05)
      paperStyle.borderBottom = `1px solid ${palette.borderColor}`
    }

    /*
    // commenting out in favor of rounded PPlan avatar
    if (memo.FromUserID === 1) {
      avatarStyle.borderRadius = 'none'
      avatarStyle.backgroundColor = 'transparent'
    } */

    if (showReply) { paperStyle.backgroundColor = palette.accent2Color }

    return (
      <Paper
        style={paperStyle}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
            <div style={{ flexShrink: 0 }}>
              <Avatar
                style={avatarStyle}
                src={memo.FromUserProfilePicURL}
              />
            </div>
            <div style={{ marginLeft: '10px' }}>
              <div style={{ display: 'flex' }}>
                <div
                  style={{ color: palette.accent6Color }}
                >{`${memo.FromUserFullName} - ${moment(memo.CreatedDate_Local).fromNow()}`}</div>
                {
                  (memo.ToProcessTemplateID && !memo.ToProcessInstanceID)
                    ? <Tooltip
                      title={`${language.translate('application.template')}: ${memo.ToProcessTemplateTitle}`}
                    >
                      <IconButton
                        onClick={this.goToTemplate.bind(this)}
                        style={{
                          padding: '0px',
                          height: 'inherit',
                          width: 'inherit',
                          marginLeft: '5px',
                          zIndex: 'inherit'
                        }}
                      >
                        {(memo.ToProcessTemplateIconName)
                          ? <Icon icon={memo.ToProcessTemplateIconName} size={20} />
                          : <GeneratedIcon
                            text={memo.ToProcessTemplateTitle}
                            randomizer={memo.ToProcessTemplateID}
                            style={{ height: '20px', width: '20px' }}
                          />}
                      </IconButton>
                    </Tooltip> : null}
                {
                  (memo.ToProcessInstanceID)
                    ? <Tooltip
                      title={
                        <div style={{ maxWidth: '200px' }}>
                          <div>
                            {`${language.translate('application.instance')}: ${memo.ToProcessTemplateTitle}`}
                          </div>
                          {(memo.ToProcessInstance_HeaderField1_Name && memo.ToProcessInstance_HeaderField1_Value)
                            ? <div>
                              {`${memo.ToProcessInstance_HeaderField1_Name}: ${memo.ToProcessInstance_HeaderField1_Value}`}
                            </div>
                            : null}
                        </div>
                      }
                    >
                      <IconButton
                        onClick={this.goToInstance.bind(this)}
                        style={{
                          padding: '0px',
                          height: 'inherit',
                          width: 'inherit',
                          marginLeft: '5px',
                          zIndex: 'inherit'
                        }}
                      >
                        {(memo.ToProcessTemplateIconName)
                          ? <Icon icon={memo.ToProcessTemplateIconName} size={20} />
                          : <GeneratedIcon
                            text={memo.ToProcessTemplateTitle}
                            randomizer={memo.ToProcessInstanceID}
                            style={{ height: '20px', width: '20px' }}
                          />}
                      </IconButton>
                    </Tooltip> : null}
                {(memo.ToUserFullName)
                  ? this.avatarElement(memo.ToUserFullName, memo.ToUserProfilePicURL)
                  : null}
                {(memo.FromEmailAddress)
                  ? this.avatarElement(memo.FromEmailAddress, memo.FromEmailAddress, true)
                  : null}
                {(memo.ToEmailAddress)
                  ? this.avatarElement(memo.ToEmailAddress, memo.ToEmailAddress, true)
                  : null}
                {(memo.ToUserGroupName)
                  ? <Tooltip
                    title={`${language.translate('application.group')}: ${memo.ToUserGroupName}`}
                  >
                    <IconButton
                      style={{
                        padding: '0px',
                        height: 'inherit',
                        width: 'inherit',
                        cursor: 'default',
                        marginLeft: '5px',
                        zIndex: 'inherit'
                      }}
                    >
                      {(memo.ToUserGroupIcon)
                        ? <Icon icon={memo.ToUserGroupIcon} size={20} />
                        : <GeneratedIcon
                          text={memo.ToUserGroupName}
                          randomizer={memo.ToUserGroupID}
                          style={{ height: '20px', width: '20px' }}
                        />}
                    </IconButton>
                  </Tooltip>
                  : null}
              </div>
              <div>
                <div>
                  <span
                    style={{ color: palette.textColor }}
                  >
                    {memo.Subject}
                  </span>
                  <div>
                    <div
                      style={{
                        maxHeight: (isExpanded) ? '10000px' : '40px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        color: palette.accent3Color
                      }}
                      ref={(div) => { this.messageBody = div }}
                      dangerouslySetInnerHTML={{ __html: Autolinker.link(memo.Body) }}
                    />
                    {(enableShowMore)
                      ? <Button
                        onClick={() => this.setState({ isExpanded: !isExpanded })}
                        style={{ minHeight: 'inherit', padding: '0px' }}
                      >
                        {(isExpanded) ? language.translate('application.less') : language.translate('application.more')}
                      </Button>
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            {(showReply)
              ? <Tooltip
                title={language.translate('application.cancel')}
              >
                <IconButton
                  onClick={() => this.setState({ showReply: false })}
                >
                  <Cancel />
                </IconButton>
              </Tooltip>
              : <Tooltip
                title={language.translate('application.reply')}
              >
                <IconButton
                  onClick={() => this.setState({ showReply: true })}
                >
                  <Reply />
                </IconButton>
              </Tooltip>}
          </div>
        </div>
        {(showReply)
          ? <div style={{ padding: '5px 25px 25px 25px' }}>
            <div style={{ padding: '10px', border: `1px solid ${palette.borderColor}` }}>
              <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                <TextField
                  required
                  label={language.translate('application.typeResponseHere')}
                  style={{ margin: '0px 10px 20px' }}
                  name='reply'
                  value={reply}
                  fullWidth
                  autoFocus
                  multiline
                  rows={4}
                  onChange={e => this.setState({ reply: e.currentTarget.value })}
                />
                <Button
                  onClick={this.replyHandler.bind(this)}
                  disabled={!reply || isSubmitting}
                  color='primary'
                  variant='contained'
                >
                  {language.translate('application.reply')}
                </Button>
              </div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!sendToAll}
                    color='primary'
                    onChange={(e) => {
                      this.setState({ sendToAll: !sendToAll })
                    }}
                    style={{ paddingTop: '0px', paddingBottom: '0px' }}
                  />}
                label={`${language.translate('application.replyOnlyTo')} ${fromName}`}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isPrivate}
                    color='primary'
                    onChange={(e) => {
                      this.setState({ isPrivate: !isPrivate })
                    }}
                    style={{ paddingTop: '0px', paddingBottom: '0px' }}
                  />}
                label={language.translate('application.keepMessagePrivate')}
              />
            </div>
          </div>
          : null}
      </Paper>
    )
  }
}

MessageItem.propTypes = {
  memo: PropTypes.object,
  onReply: PropTypes.func,
  onRequestGroupUsers: PropTypes.func,
  push: PropTypes.func
}

MessageItem.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object,
  location: PropTypes.object
}

export default MessageItem
