import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import CustomField from '../components/CustomField'
import { Card, CardContent, CardHeader, CircularProgress } from '@material-ui/core'
import { push } from 'react-router-redux'
import { Form } from 'formsy-react'
import { get, post } from '../actions/base'
import { showSnackbar } from '../actions/snackbar'
import ProcessFieldHelper from '../businessLogic/processFieldHelper'
import ResponseCard from '../components/Tasks/ResponseCard'
import NoDataToShow from '../components/Layout/NoDataToShow'

class PublicTask extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      task: null,
      isTaskLoaded: false,
      process: null,
      checklist: null,
      resources: null,
      responses: null,
      fields: null,
      canSubmitProcessFields: true,
      canSubmitTaskInfo: true,
      isSubmitting: false,
      token: props.location.query.token || null,
      isComplete: false
    }
  }

  // MIGRATED TO componentDidMount
  // componentWillMount () {
  //   this.fetchTask()
  // }

  componentDidMount() {
    if (this.state.isTaskLoaded == false) {
      this.fetchTask()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.params.taskId !== this.props.params.taskId) { this.fetchTask() }
  }

  fetchTask() {
    const { params: { taskId } } = this.props
    const { token } = this.state

    if (!taskId) {
      return false
    }

    this.setState({ isTaskLoaded: false })

    this.props.get(`public/processinstance/task/${taskId}`, {
      onSuccess: (response) => {
        this.setState({
          task: response.ProcessInstanceTask,
          isTaskLoaded: true,
          isSubmitting: false,
          process: response.ProcessInstance,
          checklist: response.ProcessInstanceTaskChecklistItemList,
          resources: response.ProcessTemplateTaskResourceList,
          responses: response.ProcessInstanceTaskResponseList,
          fields: response.ProcessInstanceFieldList
        })
      },
      headers: { token }
    })
  }

  submitResponse(responseID) {
    const { task, fields } = this.state
    this.setState({
      isSubmitting: true
    })

    const processFields = []

    fields.map((field) => {
      if (field.ProcessTemplateFieldID && !field.ReadOnlyField) {
        processFields.push(field)
      }
    })

    const taskPayload = {
      Notes: task.Notes,
      HoursSpent: task.HoursSpent,
      ID: task.ID,
      ProcessInstanceID: task.ProcessInstanceID
    }

    // Update the task on the API
    if (responseID) { this.submitTaskResponse(taskPayload, processFields, responseID) } else { this.updateTask(task.ID, taskPayload, processFields) }
  }

  updateTask(taskID, taskData, processData) {
    const body = JSON.stringify({
      ProcessInstanceTask: taskData,
      ProcessInstanceFieldList: this.getDirtyFields(processData),
      ProcessInstanceTaskChecklistItemList: this.getCheckListValues(),
      POSTOptions: {
        ValidateRequiredProcessFields: false
      }
    })

    this.props.dispatch(post(`processinstance/${taskData.ProcessInstanceID}/task/${taskID}`, body, {
      onSuccess: () => {
        this.setState({ isSubmitting: false })
        this.props.onRequestClose()
      },

      onError: () => {
        this.setState({
          isSubmitting: false,
          isComplete: false
        })
      }
    }))
  }

  getDirtyFields(fields) {
    const dirtyFields = fields.filter(field => (field.isDirty || !field.ID))

    dirtyFields.map(field => delete field.isDirty)

    return dirtyFields
  }

  getCheckListValues() {
    const selected = []

    if (!this.state.checklist) { return selected }

    this.state.checklist.map((option) => {
      selected.push({
        ProcessTemplateTaskChecklistID: option.ProcessTemplateTaskChecklistID,
        Checked: (option.Checked),
        ID: option.ID || null
      })
    })

    return selected
  }

  submitTaskResponse(taskData, processData, ResponseID) {
    const { task, responses, token } = this.state
    const { post, push } = this.props
    const TaskID = task.ID
    const responseAttribute = (responses[0].ProcessTemplateTaskResponseID) ? 'ProcessTemplateTaskResponseID' : 'ResponseText'
    const body = JSON.stringify({
      ProcessInstanceTask: taskData,
      ProcessInstanceFieldList: this.getDirtyFields(processData),
      ProcessInstanceTaskChecklistItemList: this.getCheckListValues(),
      POSTOptions: {
        [responseAttribute]: ResponseID,
        SendNewTaskEmailNotification: false
      }
    })

    post(`public/processinstance/task/${TaskID}/respond`, body, {
      onSuccess: (response) => {
        // If there are more child tasks for this user, then load the next task
        // so the user can just walk through them.  If there is no next task go to the milestone report

        if (response.CustomHTMLList && response.CustomHTMLList.length) {
          this.handleCustomHTML(response.CustomHTMLList)
        }

        if (response.ProcessInstanceTask) {
          push(`/public-task/${response.ProcessInstanceTask.ID}?token=${token}`)
        } else if (response.ProcessInstanceTaskList.length) {
          push(`/milestone-report?token=${token}`)
        } else {
          this.setState({ isComplete: true })
        }
      },

      onError: () => {
        this.setState({
          isSubmitting: false
        })
      },
      headers: { token }
    })
  }

  handleCustomHTML(list) {
    let hasPrinted = false

    list.map((htmlItem, index) => {
      if (htmlItem.AutoDisplay) {
        // open in new tab
        window.open(`/custom-html?src=processinstance/${htmlItem.ProcessInstanceID}/action/${htmlItem.IntegrationActionID}/customhtml/list&idx=${index}`, '_blank')
      }

      if (htmlItem.AutoPrint && !hasPrinted && !htmlItem.AutoDisplay) {
        const printContainer = document.getElementById('printContainer').contentWindow

        printContainer.document.open()
        printContainer.document.write(htmlItem.HTMLContent)
        printContainer.document.close()
        printContainer.focus()
        printContainer.print()
        printContainer.close()
        hasPrinted = true
      }
    })
  }

  isTaskCompleted() {
    const { task } = this.state

    return !!((task.Completed_Date_Local || task.Canceled_Date_Local))
  }

  render() {
    const { language } = this.context
    const { task, checklist, resources, responses, fields, isTaskLoaded, isComplete, token } = this.state
    const { mainContentWidth } = this.props

    let cardWidth = (mainContentWidth > 800) ? '47%' : '100%'

    if (isComplete) {
      return (<div
        style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <NoDataToShow
          noDataText={language.translate('application.formSubmitted')}
        />
      </div>)
    }

    return (
      (isTaskLoaded)
        ? <div style={{
          padding: '10px',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: (mainContentWidth > 800) ? 'row' : 'column'
        }}>
          {(!this.isTaskCompleted())
            ? <Form
              onValid={() => this.setState({ canSubmitTaskInfo: true })}
              onInvalid={() => this.setState({ canSubmitTaskInfo: false })}
              style={{ width: cardWidth, padding: '10px', maxWidth: 600 }}
            >
              <ResponseCard
                task={task}
                checklist={checklist}
                resources={resources}
                responses={responses}
                onResponseSubmit={this.submitResponse.bind(this)}
                canRespond={(this.state.canSubmitProcessFields && this.state.canSubmitTaskInfo)}
                canSave={(!this.state.isSubmitting)}
                canSubmitTaskInfo={this.state.canSubmitTaskInfo}
                onChecklistChange={(checklist) => { this.setState({ checklist }) }}
                onTaskChange={(task) => { this.setState({ task }) }}
                hideExpander
                hideTimeSpent
              />
            </Form>
            : null}

          {/*
           * PROCESS FIELDS
           */}

          <Form
            onValid={() => this.setState({ canSubmitProcessFields: true })}
            onInvalid={() => this.setState({ canSubmitProcessFields: false })}
            autoComplete='off'
            style={{ width: cardWidth, padding: '10px', maxWidth: 600 }}
          >
            <Card>
              <CardHeader
                title={language.translate('application.fieldsTitle')}
                subheader={language.translate('application.fieldsSubTitle')}
              />
              <CardContent>
                {fields.map((field, index) => {
                  let filter = null

                  if (field.DependantOnFieldType) {
                    filter = ProcessFieldHelper.createFilterFromDependantFieldValue(field.DependantOnFieldType, fields)
                  }

                  if (ProcessFieldHelper.isInstanceLookupField(field.FieldType) && field.MetaData) {
                    filter = ProcessFieldHelper.createFilterFromParsedMetaData(field.MetaData, fields)
                  }

                  return <CustomField
                    key={field.ProcessTemplateFieldID}
                    field={field}
                    fieldList={fields}
                    ref={field.ProcessTemplateFieldID}
                    dispatch={this.props.dispatch}
                    filter={filter}
                    publicFormToken={token}
                    onUpdate={(field) => {
                      fields[index] = field
                      fields[index].isDirty = true

                      this.setState({ fields })
                    }}
                  />
                })}
              </CardContent>
            </Card>
          </Form>
          <iframe
            id='printContainer'
            style={{ height: '0px', width: '0px', position: 'absolute', border: 'none' }}
          />
        </div>
        : <CircularProgress className='loader' />
    )
  }
}

PublicTask.propTypes = {
  params: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  get: PropTypes.func,
  post: PropTypes.func,
  showSnackbar: PropTypes.func,
  location: PropTypes.object,
  mainContentWidth: PropTypes.string
}

PublicTask.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

const mapStateToProps = state => ({
  mainContentWidth: state.application.mainContentWidth
})

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  get: bindActionCreators(get, dispatch),
  post: bindActionCreators(post, dispatch),
  showSnackbar: bindActionCreators(showSnackbar, dispatch),
  dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(PublicTask)
