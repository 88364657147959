import PropTypes from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { Drawer, withStyles } from '@material-ui/core'
import objectAssign from 'object-assign'
import { push } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { setRightPanel, setRightPanelRef } from '../../actions/application'
import TaskView from '../../views/TaskView'
import StartProcessView from '../../views/StartProcessView'
import EditProcessFields from '../ProcessTemplate/EditProcessFields'
import AutomatedActionList from '../ProcessTemplate/AutomatedActions/AutomatedActionList'
import ProcessTemplateSettings from '../ProcessTemplate/ProcessTemplateSettings'
import ProcessLaunchSettings from '../ProcessTemplate/ProcessLaunchSettings'
import TemplateTaskSettingsPanel from '../ProcessTemplate/TemplateTaskSettingsPanel'
import TemplateResponseSettings from '../ProcessTemplate/TemplateResponseSettings'
import ProcessInstanceSettings from '../Process/ProcessInstanceSettings'
import MapSubProcessFields from '../ProcessTemplate/MapSubProcessFields'
import UserSettings from '../User/UserSettings'
import GroupSettings from '../User/GroupSettings'
import MessagePanel from '../Messages/MessagePanel'
import NotificationPanel from '../Notifications/NotificationPanel'
import ScrollToTop from './ScrollToTop'
import ProcessInfo from '../../views/ProcessInfo'
import ProcessFieldForm from '../Process/ProcessFieldForm'
import TemplateGroups from '../ProcessTemplate/TemplateGroups'
import SAMLConfiguration from '../../views/SAMLConfiguration'
import ExternalDataSourcePanel from '../ExternalDataSource/ExternalDataSourcePanel'
import UserCustomFieldPanel from '../User/CustomFieldPanel'
import VerticalDragHandle from './VerticalDragHandle'
import IntegrationConfiguration from '../../views/IntegrationConfiguration'
import TagPanel from '../Tags/TagPanel'
import Panel from '../ProcessImportLaunch/Panel'
import OfflineDocumentTemplates from '../../views/OfflineDocumentTemplates/OfflineDocumentTemplates'
import TextBlockTemplates from '../../views/TextBlockTemplates/TextBlockTemplates'
import WorkSchedules from '../../views/WorkSchedules/WorkSchedules'
import DashboardGroupSettings from '../../views/DashboardGroups/DashboardGroupSettings'

const styles = theme => ({
  toolbar: theme.mixins.toolbar
})

class RightPanel extends React.Component {
  constructor (props) {
    super(props)

    this.state = {}
  }

  componentDidMount () {
    if (this.props.location.query.ptype) {
      this.openPanel()
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.location.query.ptype !== prevProps.location.query.ptype ||
      this.props.location.query.pid !== prevProps.location.query.pid) {
      if (!this.props.location.query.ptype) { this.closePanel() } else {
        this.openPanel()
      }
    }
  }

  getComponent () {
    const { location, location: { query } } = this.props

    switch (query.ptype) {
      case 'task':
        return (
          <TaskView
            taskId={query.pid}
            onResponseSubmit={(task) => {
              // close the panel and if a new task gets assigned by the api, load it
              this.closePanel()

              // manipulate the url based on whether the returned task is assigned to the user
              let newQuery = query
              if (task.AssignedTo_CurrentUser === true) {
                newQuery = objectAssign({}, query, {
                  task: task.ID,
                  pid: task.ID
                })
              } else {
                this.clearURL()
              }

              const pathnameArray = this.props.location.pathname.split('/')
              let newPath = ''

              // if they are on the progress screen, make sure you stay with
              // the processes the task represents (sub-processes)
              if (pathnameArray[1] === 'process-visual-progress') { newPath = `/process-visual-progress/${task.ProcessInstanceID}` } else { newPath = this.props.location.pathname }

              this.props.push({
                pathname: newPath,
                query: newQuery
              })
            }}
            onRequestClose={() => {
              // close the panel, remove the selection highlight and clear the url
              this.closePanel()
              this.clearURL()
            }}
            location={location}
          />
        )
      case 'process':
        return (
          <ProcessInfo
            processId={query.pid}
            onRequestClose={() => {
              this.closePanel()
              this.clearURL()
            }}
          />
        )
      case 'process_template':
        return (
          <StartProcessView
            templateId={query.pid}
            recurring={(query.recurring === 'true')}
            returnTo={(query.returnTo)}
            instanceId={query.piid}
            location={location}
            onRequestClose={() => {
              this.closePanel()
              this.clearURL()
            }}
          />
        )
      case 'process_template_import':
        return (
          <Panel
            templateId={query.pid}
            close={() => {
              this.closePanel()
              this.clearURL()
            }}
          />
        )
      case 'ptfields':
        return (
          <EditProcessFields
            templateId={query.pid}
            close={() => {
              this.closePanel()
              this.clearURL()
            }}
          />
        )
      case 'pifields':
        return (
          <ProcessFieldForm
            processId={query.pid}
            location={location}
            close={() => {
              this.closePanel()
              this.clearURL()
            }}
          />
        )
      case 'ptactions':
        return (
          <AutomatedActionList
            templateId={query.pid}
            taskId={(query.task) ? query.task : null}
            responseId={(query.response) ? query.response : null}
            close={() => {
              this.closePanel()
              this.clearURL()
            }}
          />
        )

      case 'ptsettings':
        return (
          <ProcessTemplateSettings
            templateId={query.pid}
            close={() => {
              this.closePanel()
              this.clearURL()
            }}
          />
        )

      case 'plsettings':
        return (
          <ProcessLaunchSettings
            templateId={query.pid}
            close={() => {
              this.closePanel()
              this.clearURL()
            }}
          />
        )

      case 'pttsettings':
        return (
          <TemplateTaskSettingsPanel
            templateTaskId={query.pid}
            close={() => {
              this.closePanel()
              this.clearURL()
            }}
            location={location}
          />
        )
      case 'ptrsettings':
        return (
          <TemplateResponseSettings
            templateResponseId={query.pid}
            close={() => {
              this.closePanel()
              this.clearURL()
            }}
          />
        )
      case 'pisettings':
        return (
          <ProcessInstanceSettings
            processId={query.pid}
            close={() => {
              this.closePanel()
              this.clearURL()
            }}
          />
        )
      case 'user':
        return (
          <UserSettings
            userId={query.pid}
            close={() => {
              this.closePanel()
              this.clearURL()
            }}
          />
        )
      case 'group':
        return (
          <GroupSettings
            groupId={query.pid}
            close={() => {
              this.closePanel()
              this.clearURL()
            }}
          />
        )
      case 'dashboard-group':
        return (
          <DashboardGroupSettings
            groupId={query.pid}
            close={() => {
              this.closePanel()
              this.clearURL()
            }}
          />
        )
      case 'tmpmap':
        return (
          <MapSubProcessFields
            mpId={query.pid}
            type='processtemplate'
            typeId={query.ptsid}
            close={() => {
              this.closePanel()
              this.clearURL()
            }}
          />
        )
      case 'impmap':
        return (
          <MapSubProcessFields
            mpId={query.pid}
            type='processinstance'
            typeId={query.ptsid}
            close={() => {
              this.closePanel()
              this.clearURL()
            }}
          />
        )
      case 'ptgroups':
        return (
          <TemplateGroups
            close={() => {
              this.closePanel()
              this.clearURL()
            }}
          />
        )
      case 'atags':
        return (
          <TagPanel
            close={() => {
              this.closePanel()
              this.clearURL()
            }}
          />
        )
      case 'memos':
        return (
          <MessagePanel
            type={query.memoType}
            typeId={query.pid}
            close={() => {
              this.closePanel()
              this.clearURL()
            }}
            location={location}
          />
        )
      case 'notifications':
        return (
          <NotificationPanel
            close={() => {
              this.closePanel()
              this.clearURL()
            }}
            location={location}
          />
        )
      case 'externalds':
        return (
          <ExternalDataSourcePanel
            dataSourceId={query.dsid}
            close={() => {
              this.closePanel()
              this.clearURL()
            }}
          />
        )
      case 'saml':
        return (
          <SAMLConfiguration
            close={() => {
              this.closePanel()
              this.clearURL()
            }}
          />
        )
      case 'cufields':
        return (
          <UserCustomFieldPanel
            close={() => {
              this.closePanel()
              this.clearURL()
            }}
          />
        )
      case 'offdocs':
        return (
          <OfflineDocumentTemplates
            close={() => {
              this.closePanel()
              this.clearURL()
            }}
          />
        )
      case 'textblocks':
        return (
          <TextBlockTemplates
            close={() => {
              this.closePanel()
              this.clearURL()
            }}
          />
        )
      case 'workschedules':
        return (
          <WorkSchedules
            close={() => {
              this.closePanel()
              this.clearURL()
            }}
          />
        )
      case 'integrations':
        return (
          <IntegrationConfiguration
            close={() => {
              this.closePanel()
              this.clearURL()
            }}
          />
        )

      default:
        return null
    }
  }

  openPanel () {
    this.props.setRightPanel(true)
  }

  closePanel () {
    this.props.setRightPanel(false)
  }

  clearURL () {
    const query = this.props.location.query
    delete query.task
    delete query.response
    delete query.ptsid
    delete query.pid
    delete query.piid
    delete query.ptype
    delete query.usid
    delete query.gsid
    delete query.recurring
    delete query.sid
    delete query.dsid
    delete query.memoType
    delete query.returnTo

    this.props.push({
      pathname: this.props.location.pathname,
      query
    })
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { rightPanelWidth, isRightPanelOpen, classes, setRightPanelRef } = this.props

    return (
      <div>
        <VerticalDragHandle name='rightPanelResize' />

        <Drawer
          variant='persistent'
          innerRef={(drawer) => {
            if (drawer) {
              let node = ReactDOM.findDOMNode(drawer).childNodes[0]

              this.rightPanelNode = node
              setRightPanelRef(node)
            }
          }}
          SlideProps={{
            unmountOnExit: true
          }}
          PaperProps={{
            style: {
              paddingBottom: '20px',
              backgroundColor: palette.testBg,
              width: rightPanelWidth
            }
          }}
          anchor='right'
          open={isRightPanelOpen}
        >
          <div className={classes.toolbar} />
          {this.getComponent()}
        </Drawer>
        {(this.rightPanelNode)
          ? <ScrollToTop scrollableElement={this.rightPanelNode} />
          : null}
      </div>
    )
  }
}

RightPanel.propTypes = {
  isRightPanelOpen: PropTypes.bool.isRequired,
  setRightPanel: PropTypes.func.isRequired,
  setRightPanelRef: PropTypes.func.isRequired,
  location: PropTypes.shape({
    query: PropTypes.object,
    pathname: PropTypes.string
  }).isRequired,
  push: PropTypes.func.isRequired,
  rightPanelWidth: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired
}

RightPanel.contextTypes = {
  muiTheme: PropTypes.object
}

const mapStateToProps = state => ({
  isRightPanelOpen: state.application.rightPanelOpen,
  rightPanelWidth: state.application.rightPanelWidth
})

const mapDispatchToProps = dispatch => ({
  setRightPanel: bindActionCreators(setRightPanel, dispatch),
  setRightPanelRef: bindActionCreators(setRightPanelRef, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(
  (withStyles(styles)(RightPanel)))
