import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import CustomField from '../CustomField'
import { Button, Card, CardHeader, CardContent, CircularProgress } from '@material-ui/core'
import { push } from 'react-router-redux'
import { Form } from 'formsy-react'
import { Base64 } from 'js-base64'
import { get, post } from '../../actions/base'
import RightPanelContent from '../Layout/RightPanelContent'
import ProcessFieldHelper from '../../businessLogic/processFieldHelper'

class ProcessFieldForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      canSubmit: true,
      fieldList: [],
      instance: {},
      isLoaded: false
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.processId !== this.props.processId) {
      this.fetchProcessFields()
    }
  }

  // migrated to componentDidMount
  // componentWillMount () { 
  //   this.fetchProcessFields()
  // }

  componentDidMount() {
    if (this.state.isLoaded === false) {
      this.fetchProcessFields()
    }
  }

  fetchProcessFields() {
    const { processId, location } = this.props

    this.props.dispatch(get(`processinstance/${processId}/field/list`, {
      onSuccess: (response) => {
        const fields = response.ProcessInstanceFieldList.map((field, index) => {
          index += 1
          if (location.query[`fld${index}`] || location.query[`fld${field.ID}`]) {
            let queryStringValue = location.query[`fld${index}`] || location.query[`fld${field.ID}`]
            if (field.FieldOptionList) {
              field.ValueID = ProcessFieldHelper.getIdsFromOptionListArray(queryStringValue, field)
            } else {
              field.Value = queryStringValue
              field.ValueID = queryStringValue
            }
            field.isDirty = true
          }

          return field
        })

        this.setState({
          fieldList: fields,
          instance: response.ProcessInstance,
          isLoaded: true
        })
      }
    }))
  }

  updateProcessFields(callback) {
    this.setState({ canSubmit: false })

    const { fieldList } = this.state
    const { location, processId, push } = this.props
    const body = JSON.stringify({
      ProcessInstanceTask: { ProcessInstanceID: processId },
      ProcessInstanceFieldList: this.getDirtyFields(fieldList),
      POSTOptions: {
        ValidateRequiredProcessFields: false
      }
    })

    this.props.dispatch(post(`processinstance/${this.props.processId}/field/list`, body, {
      onSuccess: (response) => {
        const queryString = location.query

        Object.keys(queryString).map((query) => {
          if (query.substr(0, 3) === 'fld') {
            delete queryString[query]
          }
        })

        push({
          pathname: location.pathname,
          query: queryString
        })

        if (callback) {
          callback()
        } else {
          this.props.close()
        }
      }
    }))
  }

  getDirtyFields(fields) {
    const dirtyFields = fields.filter(field => (field.isDirty || !field.ID))

    dirtyFields.map(field => delete field.isDirty)

    return dirtyFields
  }

  enableButton() {
    this.setState({
      canSubmit: true
    })
  }

  disableButton() {
    this.setState({
      canSubmit: false
    })
  }

  render() {
    const { language } = this.context
    const { isLoaded, fieldList, instance } = this.state
    const { location, push, user } = this.props

    return (
      <RightPanelContent
        closePanel={() => this.props.close()}
        title={instance.ProcessTemplateTitle}
        toolbarMenuItems={[
          ((user.canViewMyProcesses || user.canViewAllProcesses) && location.pathname !== `/process-visual-progress/${instance.ID}`)
            ? {
              onClick: () => push({
                pathname: `/process-visual-progress/${instance.ID}`,
                query: { pid: instance.ID, ptype: 'pifields' }
              }),
              label: language.translate('application.viewProgress')
            }
            : null
        ].filter(Boolean)}
      >
        {(!isLoaded)
          ? <CircularProgress className='loader' />
          : <Form
            style={{ margin: '10px 20px' }}
            onValid={this.enableButton.bind(this)}
            onInvalid={this.disableButton.bind(this)}
            autoComplete='off'
          >
            <Card>
              <CardHeader
                title={language.translate('application.fieldsTitle')}
                subheader={language.translate('application.fieldsSubTitle')}
              />
              <CardContent>
                {fieldList.map((field, index) => {
                  let filter = null

                  if (field.DependantOnFieldType) {
                    filter = ProcessFieldHelper.createFilterFromDependantFieldValue(field.DependantOnFieldType, fieldList)
                  }

                  if (ProcessFieldHelper.isInstanceLookupField(field.FieldType) && field.MetaData) {
                    filter = ProcessFieldHelper.createFilterFromParsedMetaData(field.MetaData, fieldList)
                  }

                  return <CustomField
                    key={field.ProcessTemplateFieldID}
                    field={field}
                    fieldList={fieldList}
                    ref={field.ProcessTemplateFieldID}
                    dispatch={this.props.dispatch}
                    filter={filter}
                    onUpdate={(field) => {
                      fieldList[index] = field
                      fieldList[index].isDirty = true

                      this.setState({ fieldList })
                    }}
                    actionButton={(field.FieldType === 'INSTANCE' || field.FieldType === 'INSTCHLS') ? () => {
                      this.updateProcessFields(() => {
                        let newReturnTo = {
                          ...this.props.location.query,
                          newFieldIndex: index + 1
                        }

                        let query = {
                          ptype: 'process_template',
                          pid: field.ProcessInstanceLookupTemplateID,
                          returnTo: Base64.encodeURI(JSON.stringify(newReturnTo))
                        }

                        // parse the correct ID's out of the MetaData and push them into the query string
                        let filterArray = ProcessFieldHelper.parseFiltersFromString(field.MetaData)
                        filterArray.map((filter) => {
                          if (filter.includes('ProcessInstanceFieldList')) {
                            let { instanceFieldId, templateFieldId } = ProcessFieldHelper.parseFieldDependencyFilter(filter)

                            let foundField = fieldList.filter((fieldToSearch) => (fieldToSearch.ID === instanceFieldId || fieldToSearch.ProcessTemplateFieldID === instanceFieldId))[0]

                            if (foundField.Value) {
                              query[`fld${templateFieldId}`] = foundField.Value
                            }
                          }
                        })

                        push({
                          pathname: location.pathname,
                          query
                        })
                      })
                    } : null}
                  />
                })}
              </CardContent>
            </Card>
            <Button
              color='primary'
              style={{ display: 'block', margin: '20px 0px' }}
              onClick={() => this.updateProcessFields()}
              variant='contained'
              fullWidth
            >
              {language.translate('application.submit')}
            </Button>
          </Form>
        }
      </RightPanelContent>
    )
  }
}

ProcessFieldForm.propTypes = {
  processId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  dispatch: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  location: PropTypes.object,
  close: PropTypes.func,
  user: PropTypes.object.isRequired
}

ProcessFieldForm.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

const mapStateToProps = state => ({
  user: state.auth
})

const mapDispatchToProps = dispatch => ({
  dispatch,
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ProcessFieldForm)
