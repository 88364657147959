import PropTypes from 'prop-types'
import React from 'react'
import { Fab } from '@material-ui/core'
import { ArrowUpward } from '@material-ui/icons'

class ScrollToTop extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      show: false
    }
  }

  componentDidMount () {
    this.props.scrollableElement.addEventListener('scroll', this.handleScroll)
  }

  shouldComponentUpdate (nextProps, nextState, nextContext) {
    return (nextState.show !== this.state.show)
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.scrollableElement !== this.props.scrollableElement) {
      prevProps.scrollableElement.removeEventListener('scroll', this.handleScroll)
      this.props.scrollableElement.addEventListener('scroll', this.handleScroll)
    }
  }

  componentWillUnmount () {
    if (this.props.scrollableElement) { this.props.scrollableElement.removeEventListener('scroll', this.handleScroll) }
  }

  handleScroll = () => {
    if (this.props.scrollableElement.scrollTop > 200) {
      this.setState({ show: true })
    } else {
      this.setState({ show: false })
    }
  }

  scrollTo = () => {
    const element = this.props.scrollableElement
    const to = 0
    const duration = 500
    const start = element.scrollTop
    const change = to - start
    const increment = 20

    const animateScroll = (elapsedTime) => {
      elapsedTime += increment
      element.scrollTop = this.easeInOut(elapsedTime, start, change, duration)
      if (elapsedTime < duration) {
        setTimeout(() => {
          animateScroll(elapsedTime)
        }, increment)
      }
    }

    animateScroll(0)
  }

  easeInOut (currentTime, start, change, duration) {
    currentTime /= duration / 2
    if (currentTime < 1) {
      return change / 2 * currentTime * currentTime + start
    }
    currentTime -= 1
    return -change / 2 * (currentTime * (currentTime - 2) - 1) + start
  }

  render () {
    return (
      <div
        style={{
          position: 'fixed',
          display: (this.state.show) ? 'inherit' : 'none',
          right: '20px',
          bottom: '20px',
          zIndex: 1300
        }}
      >
        <Fab
          onClick={this.scrollTo}
          size='small'
        >
          <ArrowUpward />
        </Fab>
      </div>
    )
  }
}

ScrollToTop.propTypes = {
  scrollableElement: PropTypes.instanceOf(Element).isRequired
}

ScrollToTop.contextTypes = {
  muiTheme: PropTypes.object
}

export default ScrollToTop
