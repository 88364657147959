import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import { DragSource, DropTarget } from 'react-dnd'
import { ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core'
import { DragHandle } from '@material-ui/icons'

const itemSource = {
  beginDrag (props) {
    const { id, index, primaryText } = props
    return {
      id,
      index,
      primaryText
    }
  },
  endDrag (props) {
    const { droppedItem, id, index } = props
    droppedItem(id, index)
  }
}

const itemTarget = {
  hover (props, monitor, component) {
    const dragIndex = monitor.getItem().index
    const hoverIndex = props.index

    if (dragIndex === hoverIndex) return // Don't replace items with themselves

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect()

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

    // Determine mouse position
    const clientOffset = monitor.getClientOffset()

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return
    }
    // Time to actually perform the action
    props.moveItem({ dragIndex, hoverIndex })

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex
  }
}

const dndType = props => props.listIdentifier || 'reorderableListItem'

class ReorderableListItem extends Component {
  render () {
    const palette = this.context.muiTheme.palette
    const {
      children,
      connectDragSource,
      connectDropTarget,
      isDragging,
      style,
      primaryText,
      secondaryText,
      rightIconButton,
      // eslint-disable-next-line no-unused-vars
      index, moveItem, droppedItem, connectDragPreview, listIdentifier, // omit pass hackAutoComplete
      ...props
    } = this.props

    return connectDragPreview(connectDropTarget(
      <div style={{ opacity: (isDragging) ? 0 : 1 }}>
        <ListItem
          {...props}
          style={{
            ...style,
            backgroundColor: isDragging && palette.accent2Color,
            cursor: isDragging && 'move'
          }}
        >
          {connectDragSource(<div><DragHandle style={{ cursor: 'move' }} /></div>)}
          <ListItemText primary={primaryText} secondar={secondaryText} />
          {(rightIconButton &&
            <ListItemSecondaryAction>
              {rightIconButton}
            </ListItemSecondaryAction>)}
          {children}
        </ListItem>
      </div>
    ))
  }
}

ReorderableListItem.propTypes = {
  connectDragSource: PropTypes.func.isRequired,
  connectDragPreview: PropTypes.func.isRequired,
  isDragging: PropTypes.bool,
  children: PropTypes.array,
  connectDropTarget: PropTypes.func,
  style: PropTypes.object,
  index: PropTypes.number.isRequired,
  moveItem: PropTypes.func,
  droppedItem: PropTypes.func,
  id: PropTypes.string,
  primaryText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  secondaryText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  rightIconButton: PropTypes.object
}

ReorderableListItem.contextTypes = {
  muiTheme: PropTypes.object
}

const connectTarget = connect => ({
  connectDropTarget: connect.dropTarget()
})

const connectSource = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging()
})

export default (
  DropTarget(dndType, itemTarget, connectTarget)(
    DragSource(dndType, itemSource, connectSource)(
      ReorderableListItem
    )
  )
)
