import PropTypes from 'prop-types'
import React from 'react'
import { Button } from '@material-ui/core'
import TextField from '~/components/Forms/TextField'
import SelectField from '~/components/Forms/SelectField'
import Checkbox from '~/components/Forms/Checkbox'
import { Person } from '@material-ui/icons'
import { Formik, Form } from 'formik'
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import theme from '~/styles/theme'

const newTheme = JSON.parse(JSON.stringify(theme))
newTheme.palette.primary.main = '#fff'
newTheme.overrides.MuiInput = {
  underline: {
    '&:before': {
      borderColor: '#fff',
      borderBottom: '1px solid #fff'
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: '2px solid #fff'
    }
  }
}

newTheme.overrides.MuiSelect = {
  icon: {
    color: '#fff'
  }
}

const mStyles = {
  labelStyle: {
    color: '#fff',
    WebkitTextFillColor: '#fff'
  }
}

const styles = theme => ({
  root: {
    width: '100%',
    color: '#fff',
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderColor: '#fff !important'
    }
  },
  underline: {
    '&:before': {
      borderBottom: '1.2px solid #fff'
    }
  },
  disabled: {},
  focused: {},
  error: {}
})

class LoginForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      serverRegion: null,
      isCapsLockOn: false,
      autoFilled: false
    }
  }

  componentDidMount () {
    setTimeout(() => { this.checkForAutofill() }, 1000)
  }

  checkForAutofill () {
    if (this.emailField && this.passwordField) {
      if (this.emailField.matches(':-webkit-autofill') && this.passwordField.matches(':-webkit-autofill')) {
        this.setState({ autoFilled: true })
      }
    } else {
      setTimeout(() => { this.checkForAutofill() }, 100)
    }
  }

  checkCapsLockState (event) {
    const key = event.keyCode ? event.keyCode : event.which
    const shiftPressed = event.shiftKey ? event.shiftKey : ((key === 16))

    if (((key >= 65 && key <= 90) && !shiftPressed) || ((key >= 97 && key <= 122) && shiftPressed)) {
      this.setState({ isCapsLockOn: true })
    } else {
      this.setState({ isCapsLockOn: false })
    }
  }

  disableSubmit (values) {
    const { autoFilled } = this.state
    const { isAuthenticating } = this.props

    if (autoFilled) {
      return false
    }

    return (isAuthenticating || !values.email || !values.password)
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const { handleSubmit, hasMultipleAccounts, accountList, classes } = this.props
    const { isCapsLockOn } = this.state
    const accountOptions = []

    if (hasMultipleAccounts) {
      accountList.map((option) => {
        accountOptions.push(
          { value: option.ID, label: option.AccountName }
        )
      })
    }

    return (
      <Formik
        initialValues={{
          email: (window.localStorage.getItem('username')) ? window.localStorage.getItem('username') : '',
          password: '',
          accountid: '',
          rememberMe: (window.localStorage.getItem('rememberMe') === 'true') ? true : (document.location.host === 'mobile.processplan.com')
        }}
        onSubmit={(values) => {
          let payload = { ...this.state, ...values }
          handleSubmit(payload)
        }}
        validate={(values) => {
          let errors = {}

          if (this.state.autoFilled) {
            this.setState({ autoFilled: false })
          }

          if (isCapsLockOn) {
            errors.password = language.translate('application.capsLockIsOn')
          }

          return errors
        }}
      >
        {({ values, errors, touched, handleChange }) => {
          return (
            <Form
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '250px' }}
            >
              {/* fake fields are a workaround for chrome autofill getting the wrong fields
         <input style={{display:'none'}} type="text" name="fakeusernameremembered"/>
         <input style={{display:'none'}} type="password" name="fakepasswordremembered"/> */}

              <MuiThemeProvider theme={createMuiTheme(newTheme)}>
                <TextField
                  inputRef={(field => { this.emailField = field })}
                  name='email'
                  type='email'
                  label={language.translate('application.emailAddress')}
                  InputLabelProps={{ style: mStyles.labelStyle, shrink: true }}
                  InputProps={{ classes: { root: classes.root, underline: classes.underline } }}
                  fullWidth
                />
                <TextField
                  inputRef={(field => { this.passwordField = field })}
                  name='password'
                  type='password'
                  label={language.translate('application.password')}
                  InputLabelProps={{ style: mStyles.labelStyle, shrink: true }}
                  InputProps={{ classes: { root: classes.root, underline: classes.underline } }}
                  fullWidth
                  onKeyPress={(e) => this.checkCapsLockState(e)}
                />
                <Checkbox
                  name='rememberMe'
                  label={language.translate('application.rememberMe')}
                  style={mStyles.labelStyle}
                  InputLabelProps={{ style: mStyles.labelStyle }}
                />
                {hasMultipleAccounts &&
                <SelectField
                  name='accountid'
                  label={language.translate('application.selectAccount')}
                  InputLabelProps={{ style: mStyles.labelStyle }}
                  className='login-select'
                  options={accountOptions}
                  onChange={(e, option) => {
                    let serverRegion = accountList.filter((account) => (option.props.value === account.ID))[0].DataServerRegion

                    this.setState({ serverRegion })

                    handleChange(e)
                  }}
                />}
              </MuiThemeProvider>
              <Button
                type='submit'
                color='primary'
                disabled={this.disableSubmit(values)}
                variant='contained'
              >
                <Person />
                {language.translate('application.signIn')}
              </Button>
            </Form>
          )
        }}
      </Formik>
    )
  }
}

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isAuthenticating: PropTypes.bool,
  hasMultipleAccounts: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  accountList: PropTypes.array
}

LoginForm.contextTypes = {
  muiTheme: PropTypes.object,
  language: PropTypes.object
}

export default withStyles(styles)(LoginForm)
