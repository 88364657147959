import PropTypes from 'prop-types'
import React from 'react'
import objectAssign from 'object-assign'
import { CheckBox } from '@material-ui/icons'
import grey from '@material-ui/core/colors/grey'

const fontStyle = {
  color: grey[400]
}

class NoDataToShow extends React.Component {
  render () {
    let { textStyle } = this.props
    const { language } = this.context

    textStyle = objectAssign({}, { fontSize: '25px', color: fontStyle.color, textAlign: 'center' }, textStyle)

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <CheckBox
          style={{ height: '150px', width: '150px', margin: 'auto', display: 'block' }}
          nativeColor={fontStyle.color}
        />
        <div style={textStyle}>
          {(this.props.noDataText) ? this.props.noDataText : language.translate('application.noDataToShow')}
        </div>
      </div>
    )
  }
}

NoDataToShow.propTypes = {
  noDataText: PropTypes.string,
  textStyle: PropTypes.object
}

NoDataToShow.contextTypes = {
  language: PropTypes.object
}

export default NoDataToShow
