import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Avatar, CircularProgress, Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import { Email, Group } from '@material-ui/icons'
import { get } from '../../actions/base'

class GroupCardDialog extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      users: [],
      isLoaded: false
    }
  }

  componentDidMount () {
    if (this.props.groupID) { this.fetchGroupUsers() }
  }

  componentDidUpdate (prevProps) {
    if (this.props.groupID !== prevProps.groupID) {
      this.fetchGroupUsers()
    }
  }

  fetchGroupUsers () {
    this.props.dispatch(get(`usergroup/${this.props.groupID}/user/list`, {
      onSuccess: (response) => {
        this.setState({
          users: response.UserList,
          isLoaded: true
        })
      }
    }))
  }

  render () {
    const users = this.state.users
    const palette = this.context.muiTheme.palette
    const { language } = this.context

    return (
      <Dialog
        open={this.props.open}
        onClose={() => this.props.close()}
      >
        <DialogTitle
          style={{ backgroundColor: palette.headerBackgroundColor }}>
          <div>
            <Group nativeColor={palette.alternateTextColor} style={{ marginBottom: '-4px' }} />
            <span style={{ paddingLeft: '5px', color: palette.alternateTextColor }}>
              {language.translate('application.groupMembers')}
            </span>
          </div>
        </DialogTitle>
        <DialogContent style={{ width: '370px' }}>
          {(!this.state.isLoaded)
            ? <CircularProgress style={{ marginTop: '20px', marginLeft: '45%' }} />
            : <div style={{ paddingTop: '20px' }}>
              <div style={{ textAlign: 'center' }}>
                <Email style={{ height: '50px', width: '50px' }} />
              </div>
              {
                users.map(user => (<div style={{ display: 'flex', flexDirection: 'row' }} key={user.ID}>
                  <div style={{ flex: 1, paddingLeft: '10px' }}>
                    <Avatar src={user.ProfilePicURL} style={{ height: '70px', width: '70px' }} />
                  </div>
                  <div style={{ flex: 4 }}>
                    <ul style={{ listStyleType: 'none' }}>
                      <li><strong>{user.FullName}</strong></li>
                      <li>{user.Position}</li>
                      <li><a href={`mailto:${user.EmailAddress}`}>{user.EmailAddress}</a></li>
                      <li><a href={`tel:${user.MobileTelephone}`}>{user.MobileTelephone}</a></li>
                    </ul>
                  </div>
                </div>))
              }
            </div>
          }
        </DialogContent>
      </Dialog>
    )
  }
}

GroupCardDialog.propTypes = {
  dispatch: PropTypes.func.isRequired,
  groupID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired
}

GroupCardDialog.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(GroupCardDialog)
