import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Form } from 'formsy-react'
import {
  Button, Collapse, Dialog, DialogContent, DialogTitle, Divider, List, ListItem, ListItemText, Switch, TextField,
  Typography
} from '@material-ui/core'
import { Delete, AddAPhoto, ExpandLess, ExpandMore } from '@material-ui/icons'
import { FormsyText } from 'formsy-material-ui'
import { get } from '~/actions/base'
import FormHelper from '~/businessLogic/formHelper'
import FormsyUpload from '../Forms/FormsyUpload'
import DeleteIconButton from '../Layout/DeleteIconButton'

class PublicFormEdit extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      canSubmit: true,
      isDirty: false,
      ipWhitelistFieldValue: '',
      isValidIP: true,
      item: {},
      displayFieldsCollapsed: true,
      requireFieldsCollapsed: true
    }

    this.setState({ item: this.props.publicFormItem })
  }

  // MIGRATED TO constructor
  // componentWillMount () {
  //   this.setState({ item: this.props.publicFormItem })
  // }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.publicFormItem !== prevProps.publicFormItem) { this.setState({ item: this.props.publicFormItem }) }
  }

  /*
   * Returns whether the field ID exists in the array by converting the string of
   * ID's into an array and seeing if the id of the toggle exists in the array
   */
  shouldItBeToggled(id, array) {
    // let arrayOfFieldsToShow = this.props.publicFormItem.ProcessTemplateFieldIDsToDisplayAtStart.split(",");
    if (array) {
      return (array.indexOf(id) >= 0);
    }
    else {
      return false;
    }
  }

  updateDisplayFields(id, shouldDisplay) {
    this.updateFieldValues(id, shouldDisplay, 'ProcessTemplateFieldID_ToDisplay')
  }

  updateRequiredFields(id, isRequired) {
    this.updateFieldValues(id, isRequired, 'ProcessTemplateFieldID_Required')
  }

  updateFieldValues(id, addField, attribute) {
    const item = this.state.item
    let array = (item[attribute]) ? item[attribute].filter(Boolean) : []

    if (addField) {
      array = array.concat([id])
    } else {
      const indexOfField = array.indexOf(id)
      if (indexOfField >= 0) array.splice(indexOfField, 1)
    }

    item[attribute] = array
    this.setState({ item, isDirty: true }, () => {
      this.props.onSave(this.state.item)
    })
  }

  enableButton() {
    this.setState({ canSubmit: true })
  }

  disableButton() {
    this.setState({ canSubmit: false })
  }

  parseImageUpload(event) {
    const { onSaveLogo } = this.props
    const { item } = this.state
    const files = event.target.files
    let uploadedFile = {}
    const reader = new window.FileReader()

    reader.onload = (function (file) {
      return function (e) {
        uploadedFile = {
          FileName: file.name,
          Base64EncodedFile: e.target.result.split(',')[1],
          FileType: file.type
        }
        onSaveLogo(uploadedFile, item.ID)
      }
    })(files[0])

    reader.readAsDataURL(files[0])
  }

  render() {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const { item, ipWhitelistFieldValue, isValidIP, displayFieldsCollapsed, requireFieldsCollapsed } = this.state
    const { processFields, onDeleteLogo } = this.props

    if (!item) { return null }

    let ipWhitelist = (item.IPWhitelist) ? item.IPWhitelist.split(',') : []

    return (
      <Dialog
        open={this.props.isOpen}
        onClose={this.props.onCloseRequest}
      >
        <DialogTitle
          style={{
            backgroundColor: palette.headerBackgroundColor
          }}
          disableTypography
        >
          <Typography
            variant='h6'
            style={{ color: palette.alternateTextColor }}>
            {language.translate('application.publicForm')}
          </Typography>
        </DialogTitle>
        <DialogContent style={{ width: '400px' }}>
          <Form onValid={this.enableButton.bind(this)} onInvalid={this.disableButton.bind(this)}>
            <FormsyText
              fullWidth
              floatingLabelText={language.translate('application.publicFormName')}
              name='name'
              required
              onBlur={(e) => {
                item.PublicDescription = e.currentTarget.value
                this.setState({ item, isDirty: true }, () => {
                  this.props.onSave(this.state.item)
                })
              }}
              defaultValue={FormHelper.decodeHTML(item.PublicDescription)}
              validationErrors={this.context.language.messages.validationErrors}
            />
            <div style={{ marginBottom: '5px' }}>
              <TextField
                fullWidth
                label={language.translate('application.publicFormInstructions')}
                multiline
                rows={4}
                onChange={(e) => {
                  item.PublicInstructions = e.target.value
                  this.setState({ item, isDirty: true })
                }}
                onBlur={() => {
                  this.props.onSave(this.state.item)
                }}
                value={FormHelper.decodeHTML(item.PublicInstructions)}
              />
              <Button
                target='_blank'
                href='https://commonmark.org/help/'
                color='primary'
              >
                {language.translate('application.markdownEnabled')}
              </Button>
            </div>
            <div style={{ marginBottom: '5px' }}>
              <TextField
                key='successMessage'
                name='successMessage'
                label={language.translate('application.successMessage')}
                placeholder={language.translate('application.publicFormSuccessMessageHint')}
                fullWidth
                multiline
                rows={3}
                defaultValue={FormHelper.decodeHTML(item.SuccessfulSubmitMessage)}
                onBlur={(e) => {
                  item.SuccessfulSubmitMessage = e.target.value
                  this.setState({ item, isDirty: true }, () => {
                    this.props.onSave(this.state.item)
                  })
                }}
              />
              <Button
                target='_blank'
                href='https://commonmark.org/help/'
                color='primary'
              >
                {language.translate('application.markdownEnabled')}
              </Button>
            </div>
            {(item.Token)
              ? <div>
                <Typography variant='subtitle1' style={{
                  paddingLeft: '0px',
                  fontSize: '12px',
                  color: palette.accent3Color
                }}>{language.translate('application.publicFormUrl')}</Typography>
                <div style={{ marginBottom: '5px' }}>{`${window.location.origin}/public-form?token=${item.Token}`}</div>
                <Divider />
              </div>
              : null}
            <div style={{
              color: 'rgba(0, 0, 0, 0.298039)',
              fontWeight: 700,
              fontSize: '12px',
              marginTop: '20px',
              textAlign: 'left'
            }}
            >
              {language.translate('application.publicFormLogo')}
            </div>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            >
              <div style={{
                backgroundColor: palette.accent2Color,
                width: '100%',
                textAlign: 'center',
                padding: '10px'
              }}
              >
                {(item.PublicFormLogo_URL)
                  ? <img
                    src={item.PublicFormLogo_URL}
                    style={{ maxWidth: '230px', width: '100%' }}
                  />
                  : <AddAPhoto
                    nativeColor={palette.accent7Color}
                    style={{ height: '80px', width: '80px' }}
                  />}
              </div>
              {(item.PublicFormLogo_FileName)
                ? <Button
                  onClick={() => { onDeleteLogo(item.ID) }}
                  style={{ marginLeft: '10px', minWidth: 'inherit', backgroundColor: palette.errorColor }}
                  variant='contained'
                >
                  <Delete nativeColor={palette.canvasColor} />
                </Button>
                : null}
              <FormsyUpload
                name='public_form_logo'
                style={{ marginLeft: '10px' }}
                onChange={e => this.parseImageUpload(e)}
                multiple={false}
                defaultValue={item.PublicFormLogo_FileName}
              />
            </div>
            <Divider style={{ marginTop: '5px' }} />
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '15px 5px'
            }}
            >
              <div>{language.translate('application.displayHumanVerification')}</div>
              <div>
                <Switch
                  color='primary'
                  checked={(item.DisplayHumanVerification)}
                  onChange={(obj, value) => {
                    item.DisplayHumanVerification = !item.DisplayHumanVerification
                    this.setState({ item, isDirty: true }, () => {
                      this.props.onSave(this.state.item)
                    })
                  }}
                />
              </div>
            </div>
            <Divider />
          </Form>
          <List style={{ padding: '0px' }}>
            <ListItem
              button
              disableGutters
              selected={false}
              style={{ backgroundColor: 'rgba(0,0,0,0)' }}
              onClick={() => { this.setState({ displayFieldsCollapsed: !displayFieldsCollapsed }) }}
            >
              <ListItemText
                primaryTypographyProps={{ style: { color: palette.accent3Color } }}
                primary={language.translate('application.displayFieldsOnPublicForm')} />
              {!displayFieldsCollapsed ? <ExpandLess style={{ color: palette.accent3Color }} /> : <ExpandMore
                style={{ color: palette.accent3Color }} />}
            </ListItem>
            <Collapse in={!displayFieldsCollapsed}>
              {processFields.map((field, index) => {
                const isToggled = (this.shouldItBeToggled(field.ID, item.ProcessTemplateFieldID_ToDisplay))
                return (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      margin: '5px'
                    }}
                  >
                    <div>{FormHelper.decodeHTML(field.FieldName)}</div>
                    <div>
                      <Switch
                        color='primary'
                        checked={isToggled}
                        onChange={(obj, value) => {
                          this.updateDisplayFields(field.ID, !isToggled)
                        }}
                      />
                    </div>
                  </div>
                )
              })}
            </Collapse>
            <Divider />
            <ListItem
              button
              disableGutters
              selected={false}
              style={{ backgroundColor: 'rgba(0,0,0,0)' }}
              onClick={() => { this.setState({ requireFieldsCollapsed: !requireFieldsCollapsed }) }}
            >
              <ListItemText
                primaryTypographyProps={{ style: { color: palette.accent3Color } }}
                primary={language.translate('application.requireTheseFieldsCompleted')} />
              {!requireFieldsCollapsed ? <ExpandLess style={{ color: palette.accent3Color }} /> : <ExpandMore
                style={{ color: palette.accent3Color }} />}
            </ListItem>
            <Collapse in={!requireFieldsCollapsed}>
              {processFields.map((field, index) => {
                const isToggled = (this.shouldItBeToggled(field.ID, item.ProcessTemplateFieldID_Required))
                return (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      margin: '5px'
                    }}
                  >
                    <div>{FormHelper.decodeHTML(field.FieldName)}</div>
                    <div>
                      <Switch
                        color='primary'
                        checked={isToggled}
                        onChange={(obj, value) => {
                          this.updateRequiredFields(field.ID, !isToggled)
                        }}
                      />
                    </div>
                  </div>
                )
              })}
            </Collapse>
          </List>
          <Divider />
          <Form onValid={() => { this.setState({ isValidIP: true }) }}
            onInvalid={() => { this.setState({ isValidIP: false }) }}>
            <div>
              <Typography variant='subtitle1'
                style={{ color: palette.accent3Color }}>{language.translate('application.ipWhitelist')}</Typography>
              <Typography variant='subtitle1' style={{
                fontSize: '12px',
                color: palette.accent3Color,
                lineHeight: 1
              }}>{language.translate('application.limitAccessToOnlySpecificIpAddresses')}</Typography>
              {ipWhitelist.map(ip => (
                <Typography key={ip} id={ip} style={{ paddingLeft: '10px' }}>{ip}<DeleteIconButton onDelete={() => {
                  let ips = ipWhitelist.filter(tempIp => ip !== tempIp)
                  item.IPWhitelist = ips.toString()
                  this.setState({ item, isDirty: true }, () => {
                    this.props.onSave(this.state.item)
                  })
                }} /></Typography>))}
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <FormsyText
                  key='addIpToWhitelist'
                  name='addIpToWhitelist'
                  floatingLabelText={language.translate('application.enterIPAddress')}
                  value={ipWhitelistFieldValue}
                  validations={'isIPAddress'}
                  validationErrors={this.context.language.messages.validationErrors}
                  onChange={(e) => {
                    this.setState({ ipWhitelistFieldValue: e.target.value })
                  }}
                  style={{ width: '200px' }}
                />
                <Button
                  color='primary'
                  variant='contained'
                  onClick={() => {
                    if (item.IPWhitelist) {
                      item.IPWhitelist += ','
                    }
                    item.IPWhitelist += ipWhitelistFieldValue

                    this.setState({ item, isDirty: true, ipWhitelistFieldValue: '' }, () => {
                      this.props.onSave(this.state.item)
                    })
                  }}
                  disabled={!isValidIP || !ipWhitelistFieldValue}
                >
                  {language.translate('application.add')}
                </Button>
              </div>
            </div>
          </Form>
        </DialogContent>
      </Dialog>
    )
  }
}

PublicFormEdit.propTypes = {
  publicFormItem: PropTypes.object,
  processFields: PropTypes.array,
  onSave: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  onCloseRequest: PropTypes.func.isRequired,
  onSaveLogo: PropTypes.func,
  onDeleteLogo: PropTypes.func
}

PublicFormEdit.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  dispatch,
  get: bindActionCreators(get, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(PublicFormEdit)
