import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateTitle } from '~/actions/application'
import { push } from 'react-router-redux'
import Masonry from 'react-masonry-component'
import { get } from '~/actions/base'
import DataList from '~/components/Layout/DataList'
import objectAssign from 'object-assign'
import AddIcon from '~/components/Layout/AddIcon'
import DashboardGroupCard from './DashboardGroupCard'

const masonryOptions = {
  transitionDuration: '0.0s'
}

class DashboardGroups extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      groups: [],
      update: false
    }
  }

  componentDidMount () {
    this.updateTitle()
  }

  componentDidUpdate () {
    this.updateTitle()
  }

  updateTitle () {
    const { language } = this.context
    this.props.dispatch(updateTitle(language.translate('application.dashboardGroup', [], true)))
  }

  dataLoadCallback (data) {
    this.setState({
      groups: data
    })
  }

  handleGroupSelect (groupId) {
    this.props.push({
      pathname: this.props.location.pathname,
      query: objectAssign({},
        this.props.location.query,
        {
          ptype: 'dashboard-group',
          pid: groupId,
          gsid: groupId
        })
    })
  }

  onCardClick (groupId) {
    this.props.push({
      pathname: '/reports',
      query: objectAssign({},
        this.props.location.query,
        {
          type: 'red-green',
          title: this.context.language.translate('application.dashboard'),
          endpoint: `dashboardgroup/${groupId}/instance/list`,
          tag_endpoint: `dashboardgroup/${groupId}/instance/list/tag/list`,
          channel: `DashboardGroup-${groupId}`
        })
    })
  }

  createNewGroup () {
    let query = objectAssign({},
      this.props.location.query,
      {
        ptype: 'dashboard-group'
      })

    delete query.pid
    delete query.gsid

    this.props.push({
      pathname: this.props.location.pathname,
      query
    })
  }

  forceUpdate () {
    this.setState({ update: !this.state.update })
  }

  render () {
    const { user, language, muiTheme: { palette } } = this.context
    const { mainContentWidth, get, location } = this.props
    const { groups } = this.state

    return (
      <div>
        <DataList
          url='dashboardgroup/list/withtemplatedata'
          pageSize={25}
          dataCallback={this.dataLoadCallback.bind(this)}
          responseProperty='DashboardGroupList'
          filterableColumns={[{
            name: language.translate('application.groupName'),
            property: 'GroupName'
          }, {
            name: language.translate('application.groupDescription'),
            property: 'GroupDescription'
          }]}
          noDataText={language.translate('application.noDashboards')}
          channelName={`Private-${user.accountID}-DashboardGroup-0`}
          events={['DashboardGroupCreated', 'DashboardGroupUpdated', 'DashboardGroupDeleted']}
          location={location}
          disableMasonry
        >
          <Masonry options={masonryOptions}>
            {groups.map((group, index) => (
              <DashboardGroupCard
                key={group.ID}
                group={group}
                width={(mainContentWidth > 1100) ? '30%' : (mainContentWidth > 750) ? '45%' : '90%'}
                forceUpdate={this.forceUpdate.bind(this)}
                get={get}
                onEditClick={this.handleGroupSelect.bind(this)}
                onClick={this.onCardClick.bind(this)}
                showMenu={(group.AccountID === user.accountID)}
              />))}
          </Masonry>
        </DataList>
        <AddIcon
          menuItems={[{ text: language.translate('application.newDashboard'), onClick: this.createNewGroup.bind(this) }]}
        />
      </div>
    )
  }
}

DashboardGroups.propTypes = {
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.string,
  get: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  mainContentWidth: PropTypes.number.isRequired,
  location: PropTypes.object
}

DashboardGroups.contextTypes = {
  muiTheme: PropTypes.object,
  language: PropTypes.object,
  user: PropTypes.object
}

const mapStateToProps = (state, ownProps) => ({
  title: state.application.title,
  mainContentWidth: state.application.mainContentWidth,
  location: ownProps.location
})

const mapDispatchToProps = dispatch => ({
  get: bindActionCreators(get, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardGroups)
