import PropTypes from 'prop-types'
import React from 'react'
import { Button, List } from '@material-ui/core'
import RecurringScheduleListItem from './RecurringScheduleListItem'
import { Add } from '@material-ui/icons'

class RecurringScheduleList extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      recurringScheduleList: null
    }
  }

  componentDidMount () {
    this.setState({ recurringScheduleList: this.props.recurringScheduleList })
  }

  componentDidUpdate (prevProps) {
    if (this.props.recurringScheduleList !== prevProps.recurringScheduleList) { this.setState({ recurringScheduleList: this.props.recurringScheduleList }) }
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const { recurringScheduleList } = this.state
    const { onDelete, pushToRecurring, pushToExpiredProcesses } = this.props

    return (
      <div style={{ padding: '10px 20px', backgroundColor: palette.accent2Color }}>
        {(!recurringScheduleList || !recurringScheduleList.length)
          ? <div style={{ margin: '10px 20px' }}>{language.translate('application.noSchedules')}</div>
          : <div style={{ padding: '0px 20px', backgroundColor: palette.accent2Color }}>
            <List>
              {recurringScheduleList.map((item, index) => (
                <RecurringScheduleListItem
                  item={item}
                  key={item.ID}
                  onRemoveField={() => onDelete(item)}
                  pushToRecurring={pushToRecurring}
                />
              ))}
            </List>
          </div>}
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
          <Button
            onClick={() => pushToRecurring()}
            color='primary'
            variant='contained'
            style={{ margin: '10px' }}
          >
            <Add />
            {language.translate('application.addItem')}
          </Button>
          <Button
            onClick={() => pushToExpiredProcesses()}
            style={{ margin: '10px' }}
            variant='contained'
          >
            {language.translate('application.viewExpired')}
          </Button>
        </div>
      </div>
    )
  }
}

RecurringScheduleList.propTypes = {
  recurringScheduleList: PropTypes.array,
  onSaveList: PropTypes.func,
  onDelete: PropTypes.func,
  pushToRecurring: PropTypes.func,
  pushToExpiredProcesses: PropTypes.func
}

RecurringScheduleList.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default RecurringScheduleList
