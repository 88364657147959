import PropTypes from 'prop-types'
import React from 'react'
import { FormsyText } from 'formsy-material-ui'
import { IconButton, Tooltip } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import objectAssign from 'object-assign'
import FormHelper from '../../businessLogic/formHelper'
import FieldWrapper from './FieldWrapper'

class Address extends React.Component {
  constructor (props, context) {
    super(props, context)
    this.state = {
      edit: (!this.props.field.ProcessInstanceID || !this.props.field.Value)
    }
  }

  getLink (location) {
    let link = 'https://www.google.com/maps/'
    location = location.replace(/(?:\r\n|\r|\n)/g, ' ')

    const { directions } = this.props

    if (directions) { link += `?saddr=My+Location&daddr=${location}` } else { link += `search/?api=1&query=${location}` }

    return link
  }

  render () {
    const { edit } = this.state
    const { field, onChange, onBlur, wrapperProps } = this.props
    const { language, muiTheme: { palette } } = this.context

    return (
      <FieldWrapper
        label={FormHelper.decodeHTML(field.FieldName)}
        required={field.RequiredField}
        showDivider={(!edit)}
        {...wrapperProps}
      >

        {(edit && !field.ReadOnlyField)
          ? <FormsyText
            fullWidth
            required={field.RequiredField}
            multiLine
            rows={2}
            name={field.FieldName}
            onChange={(event) => {
              let tempField = objectAssign({}, field, { Value: event.currentTarget.value })

              if (tempField.Value.replace(/(?:\r\n|\r|\n)/g, '') === '') { tempField.Value = '' }

              if (onChange) { onChange(tempField) }
            }}
            onBlur={(event) => {
              if (field.Value) { this.setState({ edit: false }) }

              if (onBlur) { onBlur(field) }
            }}
            value={FormHelper.decodeHTML(field.Value)}
            validationErrors={language.messages.validationErrors}
          />
          : <div className='input-style' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ flex: 5, paddingTop: '15px' }}>
              <a href={this.getLink(field.Value)} target='_blank'>
                <div
                  style={{ flex: 2, position: 'relative', padding: '5px 0px', fontSize: '15px' }}
                  dangerouslySetInnerHTML={{ __html: FormHelper.encodeHTML(field.Value) }}
                />
              </a>
            </div>
            {(!field.ReadOnlyField)
              ? <div style={{ flex: 1 }}>
                <Tooltip title={language.translate('application.edit')}>
                  <IconButton
                    onClick={() => this.setState({ edit: true })}
                  >
                    <Edit nativeColor={palette.accent3Color} />
                  </IconButton>
                </Tooltip>
              </div>
              : null}
          </div>
        }
      </FieldWrapper>
    )
  }
}

Address.propTypes = {
  field: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  directions: PropTypes.bool,
  wrapperProps: PropTypes.object
}

Address.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default Address
