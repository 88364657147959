import React from 'react'
import PropTypes from 'prop-types'
import { DragLayer } from 'react-dnd'

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 2500,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%'
}

function getItemStyles (props) {
  const { currentOffset } = props
  if (!currentOffset) {
    return {
      display: 'none'
    }
  }

  const { x } = currentOffset

  const transform = `translate(${x}px, 64px)`

  return {
    transform,
    WebkitTransform: transform,
    height: '100%'
  }
}

class VerticalDragHandle extends React.Component {
  render () {
    const { item, itemType, isDragging, name } = this.props
    const { muiTheme: { palette } } = this.context

    if (!isDragging || name !== itemType) {
      return null
    }

    return (
      <div style={layerStyles}>
        <div style={getItemStyles(this.props)}>
          <div style={{ width: '3px', height: '100%', backgroundColor: palette.accent3Color }} />
        </div>
      </div>
    )
  }
}

VerticalDragHandle.propTypes = {
  name: PropTypes.string.isRequired,
  item: PropTypes.object,
  itemType: PropTypes.string,
  currentOffset: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired
  }),
  isDragging: PropTypes.bool.isRequired
}

VerticalDragHandle.contextTypes = {
  muiTheme: PropTypes.object
}

function collect (monitor) {
  return {
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging()
  }
}

export default DragLayer(collect)(VerticalDragHandle)
