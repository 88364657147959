import PropTypes from 'prop-types'
import React from 'react'
import { TableCell } from '@material-ui/core'
import CustomFieldAdapter from '../../components/Process/InstanceGrid/CustomFieldAdapter'
import ProcessFieldHelper from '../../businessLogic/processFieldHelper'

class FieldCell extends React.PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      //isSelected: false,
      selectedFieldData: {},
      selectedFieldDataLoaded: false
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { field } = this.props

    if (!prevProps.isSelected && this.props.isSelected) {
      this.props.onSizeChange()
      this.props.fetchFieldData(field, (fieldData) => {
        this.setState({ selectedFieldData: fieldData, selectedFieldDataLoaded: true })
      })
    }

    if (!prevState.selectedFieldDataLoaded && this.state.selectedFieldDataLoaded) {
      this.props.onSizeChange()
    }

    if (prevProps.isSelected && !this.props.isSelected) {
      this.props.onSizeChange()
    }
  }

  render () {
    const { language, muiTheme: { palette } } = this.context
    const { field, dispatch, apiServer, processInstanceFieldList, templateId, onUpdate, style, isSelected, onClick, onSizeChange } = this.props
    const {
      selectedFieldDataLoaded, selectedFieldData
    } = this.state

    let tempField = field
    let border = 'none'
    let filter = null
    if (isSelected && selectedFieldDataLoaded) {
      tempField = selectedFieldData
      border = `1px solid ${palette.primary1Color}`

      if (!field.ID) {
        tempField.ProcessTemplateFieldID = field.ProcessTemplateFieldID
      }

      if (field.DependantOnFieldType) {
        filter = ProcessFieldHelper.createFilterFromDependantFieldValue(field.DependantOnFieldType, processInstanceFieldList)
      }

      if (ProcessFieldHelper.isInstanceLookupField(field.FieldType) && field.MetaData) {
        filter = ProcessFieldHelper.createFilterFromParsedMetaData(field.MetaData, processInstanceFieldList)
      }
    } else {
      tempField.ReadOnlyField = true
    }

    tempField.ProcessTemplateID = templateId

    return (
      <TableCell
        component='div'
        style={{
          ...style,
          border: `1px solid ${palette.borderColor}`,
          whiteSpace: 'initial',
          padding: '0px'
        }}
        onClick={() => {
          if (isSelected) {
            return
          }

          this.setState({
            selectedFieldDataLoaded: false
          }, () => {
            if (onClick) {
              onClick()
            }
          })
        }}
      >
        <div
          style={{
            width: '248px',
            border,
            padding: '0px 24px',
            height: '100%'
          }}>
          <CustomFieldAdapter
            field={tempField}
            fieldList={processInstanceFieldList}
            value={tempField.Value}
            dispatch={dispatch}
            isSelected={isSelected}
            filter={filter}
            apiServer={apiServer}
            onUpdate={(field) => {
              this.setState({ selectedFieldData: field })
              onUpdate(field)
            }}
            afterDataLoad={onSizeChange}
          />
        </div>
      </TableCell>
    )
  }
}

FieldCell.propTypes = {
  field: PropTypes.object.isRequired,
  style: PropTypes.object,
  processInstanceFieldList: PropTypes.array,
  isSelected: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onSizeChange: PropTypes.func,
  fetchFieldData: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  templateId: PropTypes.string.isRequired,
  apiServer: PropTypes.string.isRequired
}

FieldCell.contextTypes = {
  muiTheme: PropTypes.object,
  language: PropTypes.object
}

export default FieldCell
