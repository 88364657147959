import PropTypes from 'prop-types'
import React from 'react'
import { Collapse, ListItem } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'

class NestedListItem extends React.Component {
  constructor (props) {
    super(props)

    this.state = ({
      open: !!(props.defaultOpen)
    })
  }

  render () {
    const { children, nestedList, defaultOpen, open: openProp, hideRightIcon, onClick, ...rest } = this.props
    const { open } = this.state
    const { muiTheme: { palette } } = this.context

    const isOpen = (openProp !== undefined) ? openProp : open

    return (
      <React.Fragment>
        <ListItem {...rest} onClick={(e) => {
          this.setState({ open: !open })

          if (onClick) {
            onClick(e)
          }
        }}>
          {children}
          {(!hideRightIcon)
            ? (isOpen) ? <ExpandLess nativeColor={palette.navigationFontColor} /> : <ExpandMore
              nativeColor={palette.navigationFontColor} />
            : null}
        </ListItem>
        <Collapse in={isOpen} timeout='auto' unmountOnExit>
          {nestedList}
        </Collapse>
      </React.Fragment>
    )
  }
}

NestedListItem.propTypes = {
  nestedList: PropTypes.object,
  defaultOpen: PropTypes.bool,
  open: PropTypes.bool,
  hideRightIcon: PropTypes.bool,
  onClick: PropTypes.func
}

NestedListItem.contextTypes = {
  muiTheme: PropTypes.object
}

export default NestedListItem