import PropTypes from 'prop-types'
import React from 'react'
import { FormsyDate, FormsyText } from 'formsy-material-ui'
import { Divider, IconButton, ListItem, ListItemSecondaryAction, ListItemText, Menu, MenuItem } from '@material-ui/core'
import moment from 'moment'
import CopyToClipboard from 'react-copy-to-clipboard'
import FormHelper from '../../businessLogic/formHelper'
import { Form } from 'formsy-react'
import { Clear, Check, MoreVert } from '@material-ui/icons'
import DeleteMenuItem from '../Layout/DeleteMenuItem'
import DeleteIconButton from '../Layout/DeleteIconButton'

class LaunchCodeListItem extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      editMode: false,
      canSubmit: true,
      value: null,
      item: null,
      menuAnchor: null
    }
  }

  componentDidMount () {
    if (this.props.item.PurposeDescription === '') { this.setState({ editMode: true }) }

    this.setState({ item: this.props.item })
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.item !== prevProps.item) { this.setState({ item: this.props.item }) }
  }

  enableButton () {
    this.setState({ canSubmit: true })
  }

  disableButton () {
    this.setState({ canSubmit: false })
  }

  render () {
    const { language, muiTheme, user } = this.context
    const { onSave, displayMessage } = this.props
    const { item, menuAnchor } = this.state

    if (!item) { return null }

    return (
      (!this.state.editMode)
        ? <CopyToClipboard
          text={item.LaunchCode}
          onCopy={() => displayMessage(language.translate('application.launchCodeCopied'))}
        >
          <ListItem button>
            <ListItemText
              primary={
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <div>{`${FormHelper.decodeHTML(item.PurposeDescription)} -`}</div>
                  <div style={{
                    fontSize: '12px',
                    fontColor: muiTheme.palette.primary3Color,
                    marginLeft: '5px'
                  }}
                  >
                    {(item.ExpireDate_Local) ? `Expires ${moment(item.ExpireDate_Local).add(1, 'days').fromNow()}` : ' No Expiration'}
                  </div>
                </div>}
              secondary={item.LaunchCode}
            />
            <ListItemSecondaryAction>
              <IconButton onClick={(e) => { this.setState({ menuAnchor: e.currentTarget }) }}><MoreVert /></IconButton>
              <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={() => { this.setState({ menuAnchor: null }) }}
                getContentAnchorEl={null}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              >
                <MenuItem
                  onClick={() => this.setState({ editMode: true, menuAnchor: null })}
                >{language.translate('application.edit')}</MenuItem>
                <DeleteMenuItem
                  onDelete={() => {
                    this.setState({ menuAnchor: null })
                    this.props.onRemoveField()
                  }}
                />
              </Menu>
            </ListItemSecondaryAction>
          </ListItem>
        </CopyToClipboard>
        : <Form onValid={this.enableButton.bind(this)} onInvalid={this.disableButton.bind(this)}>
          <ListItem>
            <ListItemText primary={
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                alignItems: 'center'
              }}
              >
                <FormsyText
                  floatingLabelText={language.translate('application.description')}
                  required
                  name={`itemDescription-${item.ID}`}
                  defaultValue={FormHelper.decodeHTML(item.PurposeDescription)}
                  onChange={(e) => {
                    item.PurposeDescription = e.currentTarget.value
                    this.setState({ item })
                  }}
                  validationErrors={language.messages.validationErrors}
                />
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center'
                }}
                >
                  <FormsyDate
                    floatingLabelText={language.translate('application.expirationDate')}
                    name={`itemExpiration-${item.ID}`}
                    firstDayOfWeek={0}
                    style={{ marginLeft: (item.ExpireDate_Local) ? '48px' : '0px' }}
                    value={(item.ExpireDate_Local) ? moment(item.ExpireDate_Local).toDate() : undefined}
                    validationErrors={language.messages.validationErrors}
                    formatDate={date => moment(date).format((user.dateFormat) ? user.dateFormat : 'YYYY-MM-DD')}
                    onChange={(event, value) => {
                      item.ExpireDate_Local = moment(value).format('YYYY-MM-DD')
                      delete item.ExpireDate_UTC
                      this.setState({ item })
                    }}
                  />
                  {(item.ExpireDate_Local)
                    ? <IconButton
                      onClick={() => {
                        item.ExpireDate_Local = ''
                        this.setState({ item })
                      }}
                    >
                      <Clear />
                    </IconButton>
                    : null}
                </div>
              </div>} />
            <ListItemSecondaryAction style={{ top: '10%' }}>
              <div>
                <DeleteIconButton onDelete={this.props.onRemoveField} />
                <IconButton
                  disabled={!this.state.canSubmit}
                  onClick={() => {
                    onSave(this.state.item)
                    // forcing a delay to prevent triggering copy on touch devices
                    setTimeout(() => {
                      this.setState({ editMode: false })
                    }, 300)
                  }}
                >
                  <Check />
                </IconButton>
              </div>
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
        </Form>
    )
  }
}

LaunchCodeListItem.propTypes = {
  item: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onRemoveField: PropTypes.func.isRequired,
  displayMessage: PropTypes.func
}

LaunchCodeListItem.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object,
  user: PropTypes.object
}

export default LaunchCodeListItem
