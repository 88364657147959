import PropTypes from 'prop-types'
import React from 'react'
import { TweenMax } from 'gsap'
import { findDOMNode } from 'react-dom'

class WaitingMessage extends React.Component {
  componentWillEnter (callback) {
    const el = findDOMNode(this)
    TweenMax.fromTo(el, 0.3, { x: 200, opacity: 0 }, { x: 0, opacity: 1, onComplete: callback })
  }

  componentWillLeave (callback) {
    const el = findDOMNode(this)
    TweenMax.fromTo(el, 0.3, { x: 0, opacity: 1 }, { x: -200, opacity: 0, onComplete: callback })
  }

  render () {
    const { message } = this.props
    return (
      <div style={{ textAlign: 'center', marginTop: '10px' }}>{message}</div>
    )
  }
}

WaitingMessage.propTypes = {
  message: PropTypes.string.isRequired
}

export default WaitingMessage
