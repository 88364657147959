import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'

class Subscriber extends Component {
  constructor () {
    super()

    this.executeCallback = this.executeCallback.bind(this)
  }

  componentDidMount () {
    if (this.props.pusher) { this.connect() }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.pusher && this.props.pusher && prevProps.pusher.sessionID !== this.props.pusher.sessionID) {
      this.disconnect(prevProps.channelName)
      this.connect()
    }

    if (!prevProps.pusher && this.props.pusher) { this.connect() }

    if (prevProps.channelName !== this.props.channelName) {
      this.disconnect(prevProps.channelName)
      this.connect()
    }
  }

  componentWillUnmount () {
    this.disconnect(this.props.channelName)
  }

  connect () {
    const { pusher, channelName, events } = this.props

    const pusherChannel = pusher.subscribe(channelName)

    events.map((event) => {
      pusherChannel.bind(event, this.executeCallback)
    })
  }

  disconnect (channelName) {
    const { pusher, events } = this.props

    if (!pusher) { return false }

    const channels = pusher.allChannels()

    channels.map((channel) => {
      if (channel.name === channelName) {
        events.map((event) => {
          channel.unbind(event, this.executeCallback)
        })
      }
    })
  }

  executeCallback (payload) {
    const { callback } = this.props

    callback(payload)
  }

  render () {
    return (null)
  }
}

Subscriber.propTypes = {
  pusher: PropTypes.object,
  channelName: PropTypes.string.isRequired,
  events: PropTypes.array.isRequired,
  callback: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  pusher: state.pusher.pusher
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Subscriber)
