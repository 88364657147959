import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Button, Card, Divider, List, ListItem, ListItemText, Typography } from '@material-ui/core'
import { updateTitle } from '../actions/application'
import { push } from 'react-router-redux'
import DataList from '../components/Layout/DataList'

class APIEndpoints extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      endpoints: [],
      endpointsLoaded: false,
      update: false,
      forceDataRefresh: 0
    }
  }

  componentDidMount () {
    this.updateTitle()
  }

  componentDidUpdate (prevProps, prevState) {
    this.updateTitle()
  }

  updateTitle () {
    const { language } = this.context

    this.props.dispatch(updateTitle(language.translate('application.apiEndpoints')))
  }

  dataLoadCallback (data) {
    this.setState({
      endpoints: data,
      endpointsLoaded: true
    })
  }

  forceUpdate () {
    this.setState({ update: !this.state.update })
  }

  getRequestColor (type) {
    const palette = this.context.muiTheme.palette

    if (type === 'GET') { return palette.successColor }

    return palette.errorColor
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const { endpoints, forceDataRefresh } = this.state
    const { location } = this.props

    return (
      <DataList
        url='endpoint/list'
        dataCallback={this.dataLoadCallback.bind(this)}
        localSearch
        responseProperty='EndpointList'
        filterableColumns={[{
          name: language.translate('application.endpoint'),
          property: 'Endpoint'
        }, {
          name: language.translate('application.requestType'),
          property: 'RequestType'
        }]}
        noDataText={language.translate('application.noEndpoints')}
        forceDataRefresh={forceDataRefresh}
        disableMasonry
        location={location}
      >
        <Button
          variant='contained'
          style={{ marginBottom: '10px', backgroundColor: palette.canvasColor }}
          onClick={() => window.open('https://techdocs.processplan.com', '_blank')}
        >
          {language.translate('application.apiDocumentation')}
        </Button>
        <Card>
          <List disablePadding>
            {endpoints.map((endpoint, index) => (
              React.Children.toArray([
                <ListItem
                  key={index}
                  style={{ borderLeft: `7px solid ${this.getRequestColor(endpoint.RequestType)}` }}
                >
                  <div>
                    <Typography variant='caption'>{`${language.translate('application.requestType')}: ${endpoint.RequestType}`}</Typography>
                    <ListItemText
                      style={{ paddingLeft: '0px' }}
                      primary={`${language.translate('application.endpoint')}: ${endpoint.Endpoint}`}
                      secondary={(endpoint.PayloadRequired) ? language.translate('application.jsonPayloadRequired') : null}
                    />
                  </div>
                </ListItem>,
                (index < endpoints.length - 1) ? <Divider key={index + 10000} /> : null
              ])))}
          </List>
        </Card>
      </DataList>
    )
  }
}

APIEndpoints.propTypes = {
  dispatch: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  mainContentWidth: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

APIEndpoints.contextTypes = {
  muiTheme: PropTypes.object,
  location: PropTypes.object,
  language: PropTypes.object
}

const mapStateToProps = (state, ownProps) => ({
  title: state.application.title,
  mainContentWidth: state.application.mainContentWidth,
  user: state.auth,
  location: ownProps.location
})

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(APIEndpoints)
