import PropTypes from 'prop-types'
import React from 'react'
import { Button, Divider, List, ListItem, ListItemSecondaryAction, ListItemText, Switch, Typography } from '@material-ui/core'
import ShareCodeListItem from './ShareCodeListItem'
import { Add } from '@material-ui/icons'
import HelpIcon from '../Layout/HelpIcon'

class ShareCodeList extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      shareCodeList: null
    }
  }

  componentDidMount () {
    this.setState({ shareCodeList: this.props.shareCodeList })
  }

  componentDidUpdate (prevProps) {
    if (this.props.shareCodeList !== prevProps.shareCodeList) { this.setState({ shareCodeList: this.props.shareCodeList }) }
  }

  addNewItem () {
    // TODO: don't send new item to API until it's given a description, that way we can sort it last on the list
    const newItem = {
      PurposeDescription: '',
      ProcessTemplateID: this.props.templateId
    }

    const newShareCodeList = this.state.shareCodeList.concat(newItem)

    this.props.onSaveList(newShareCodeList)
  }

  handleChange (index, item) {
    const shareCodeList = this.state.shareCodeList
    shareCodeList[index] = item

    this.props.onSaveList(shareCodeList)
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const { shareCodeList } = this.state
    const { onDelete, displayMessage, template, onSaveTemplate } = this.props

    return (
      <div>
        <List style={{ padding: '0px 20px', backgroundColor: palette.accent2Color }}>
          <ListItem>
            <ListItemText primary={language.translate('application.autoShareWithPartners')} />
            <ListItemSecondaryAction>
              <Switch
                color='primary'
                checked={template.IsOnDemand}
                onChange={(obj, value) => {
                  template.IsOnDemand = !template.IsOnDemand
                  onSaveTemplate(template)
                }}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
          <Typography variant='subtitle1' style={{ marginTop: '10px', marginLeft: '5px', color: palette.accent4Color }}>{language.translate('application.shareCode', [], true)}</Typography>
          {(!shareCodeList || !shareCodeList.length)
            ? <Typography variant='body2' style={{ margin: '10px 20px' }}>{language.translate('application.noShareCodes')}</Typography>
            : shareCodeList.map((item, index) => (
              <ShareCodeListItem
                item={item}
                key={item.ID}
                onRemoveField={() => onDelete(item)}
                onSave={value => this.handleChange(index, value)}
                style={{ backgroundColor: (index % 2 === 0) ? palette.accent2Color : palette.canvasColor }}
                displayMessage={message => displayMessage(message)}
              />
            ))}
        </List>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Button
            onClick={this.addNewItem.bind(this)}
            color='primary'
            style={{ margin: '10px' }}
            variant='contained'
          >
            <Add />
            {language.translate('application.newShareCode')}
          </Button>
          <HelpIcon helpUrl={'editing-a-process-template$ShareCodes'} />
        </div>
      </div>
    )
  }
}

ShareCodeList.propTypes = {
  templateId: PropTypes.string.isRequired,
  shareCodeList: PropTypes.array,
  onSaveList: PropTypes.func,
  onDelete: PropTypes.func,
  displayMessage: PropTypes.func,
  template: PropTypes.object,
  onSaveTemplate: PropTypes.func
}

ShareCodeList.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default ShareCodeList
