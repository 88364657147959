import PropTypes from 'prop-types'
import React from 'react'
import { Dialog, DialogTitle, DialogContent, Checkbox, FormControlLabel } from '@material-ui/core'
import AutoComplete from '../Forms/AutoComplete'

class FieldTokenList extends React.Component {
  constructor (props) {
    super(props)

    this.state = ({
      token: null,
      inputValue: '',
      tokenArray: [],
      showAdvanced: false
    })
  }

  componentDidMount () {
    this.parseTokens()
  }

  componentDidUpdate (prevProps, prevState) {
    if (JSON.stringify(prevProps.tokens) !== JSON.stringify(this.props.tokens)) {
      this.parseTokens()
    }

    if (prevState.showAdvanced !== this.state.showAdvanced) {
      this.parseTokens()
    }
  }

  parseTokens () {
    const { tokens } = this.props
    const { showAdvanced } = this.state
    let tokenArray = []

    tokens.map((token) => {
      if (showAdvanced || !token.Advanced) {
        tokenArray.push({ label: token.Text, value: token.Value })
      }
    })

    this.setState({ tokenArray })
  }

  render () {
    const { open, close, onSelect } = this.props
    const { language } = this.context
    const { inputValue, showAdvanced, tokenArray } = this.state

    return (
      <Dialog
        PaperProps={{ style: { minWidth: '350px', width: '100%', maxWidth: '450px' } }}
        open={open}
        onClose={() => {
          this.setState({ inputValue: '' })
          close()
        }}
      >
        <DialogTitle>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>{language.translate('application.selectFieldToken')}</div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showAdvanced}
                  style={{ paddingTop: '0px', paddingBottom: '0px' }}
                  color='primary'
                  onChange={() => {
                    this.setState({ showAdvanced: !showAdvanced })
                  }}
                />}
              label={language.translate('application.showAdvanced')}
            />
          </div>
        </DialogTitle>
        <DialogContent>
          <AutoComplete
            onChange={(changes) => {
              onSelect(changes.label)
            }}
            menuHeight={300}
            value={inputValue}
            items={tokenArray}
            autoFocus
            hintText={language.translate('application.search')}
          />
        </DialogContent>
      </Dialog>
    )
  }
}

FieldTokenList.propTypes = {
  tokens: PropTypes.array,
  open: PropTypes.bool,
  close: PropTypes.func,
  onSelect: PropTypes.func
}

FieldTokenList.defaultProps = {
  tokens: []
}

FieldTokenList.contextTypes = {
  muiTheme: PropTypes.object,
  location: PropTypes.object,
  language: PropTypes.object
}

export default FieldTokenList
