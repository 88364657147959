import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { CircularProgress } from '@material-ui/core'
import { get } from '../../actions/base'
import Subscriber from './Notifications/Subscriber'

class InfiniteScroll extends React.Component {
  constructor(props) {
    super(props)

    this.checkLoadMore = this.checkLoadMore.bind(this) // binding here so the eventlisteners work correctly

    this.state = {
      isLoading: true,
      isLoadingMore: false,
      pageSize: (this.props.fetchAll) ? 0 : this.props.pageSize || 25,
      limitAttribute: this.props.limitAttribute || 'limit',
      offsetAttribute: this.props.offsetAttribute || 'offset',
      endOfData: false,
      responseProperty: this.props.responseProperty,
      pagesLoaded: 1,
      pagination: (this.props.fetchAll) ? 0 : this.props.pageSize || 25,
      cancelRequest: null
    }
  }

  componentDidMount() {
    this.getContentJson(0, this.props.sourceUrl)
  }

  // MIGRATED TO getDerivedStateFromProps and componentDidUpdate
  // componentWillReceiveProps (nextProps) {
  //   if (this.props.sourceUrl !== nextProps.sourceUrl) {
  //     if (this.state.cancelRequest && this.state.isLoading) {
  //       this.state.cancelRequest('Cancelling due to search update')
  //     }
  //     this.setState({ endOfData: false, isLoading: true })
  //     this.getContentJson(0, nextProps.sourceUrl)
  //   }
  // }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.sourceUrl !== prevState.sourceUrl) {
      return {
        endOfData: false,
        isLoading: true,
        sourceUrl: nextProps.sourceUrl
      };
    }
    else return null;
  }

  componentDidUpdate(prevProps, prevState) {

    if (prevProps.sourceUrl !== this.props.sourceUrl) {
      if (this.state.cancelRequest && this.state.isLoading) {
        this.state.cancelRequest('Cancelling due to search update')
      }
      this.setState({ endOfData: false, isLoading: true });
      this.getContentJson(0, this.state.sourceUrl)
    }

    if (prevProps.forceDataRefresh !== this.props.forceDataRefresh) { this.getContentJson(0, this.props.sourceUrl) }

    if (prevProps.containerElement !== this.props.containerElement) {
      if (!prevProps.containerElement && this.props.containerElement && this.props.mainAppRef) {
        this.props.mainAppRef.removeEventListener('scroll', this.checkLoadMore)
      }
      this.loadMore()
    }
  }

  componentWillUnmount() {
    const { containerElement, mainAppRef } = this.props
    const element = containerElement || mainAppRef

    if (element) {
      element.removeEventListener('scroll', this.checkLoadMore)
    }

    if (this.state.isLoading && this.state.cancelRequest) {
      this.state.cancelRequest('Cancelling due to page unloading')
    }
  }

  hideLoaders() {
    this.setState({
      isLoading: false,
      isLoadingMore: false
    })
  }

  getContentJson(startIndex, sourceUrl, fromScroll, pageSize = this.state.pageSize) {
    const { dispatch } = this.props
    const url = (sourceUrl.indexOf('?') > 0) ? `${sourceUrl}&` : `${sourceUrl}?`

    dispatch(get(
      `${url + this.state.limitAttribute}=${pageSize}&${this.state.offsetAttribute}=${startIndex}`,
      {
        onSuccess: (response) => {
          const responseList = response[this.state.responseProperty] || response

          if (responseList.length < this.state.pageSize) {
            this.setState({ endOfData: true })
          }

          this.hideLoaders()
          this.props.dataReceived(response, fromScroll, () => this.loadMore())
        },
        cancelRequest: (cancelRequest) => { this.setState({ cancelRequest }) }
      }
    ))
  }

  loadMore() {
    if (this.state.pageSize === 0) return
    const { containerElement, mainAppRef } = this.props

    const element = containerElement || mainAppRef

    if (!element && !mainAppRef) {
      setTimeout(() => (this.loadMore()), 50)
      return
    }

    element.removeEventListener('scroll', this.checkLoadMore)

    element.addEventListener('scroll', this.checkLoadMore)
  }

  checkLoadMore() {
    const buffer = this.state.pageSize * 20 // set a buffer so it loads data before the user hits the bottom of the page
    let { pagination } = this.state
    const { containerElement, mainAppRef } = this.props
    const scrollTop = (containerElement) ? containerElement.scrollTop : mainAppRef.scrollTop
    const scrollHeight = (containerElement) ? containerElement.scrollHeight : mainAppRef.scrollHeight
    const clientHeight = (containerElement) ? containerElement.clientHeight : mainAppRef.clientHeight

    if (scrollTop > scrollHeight - clientHeight - buffer && this.state.isLoadingMore === false) {
      const previousCount = pagination
      pagination += this.state.pageSize

      if (!this.state.endOfData) {
        this.setState({ isLoadingMore: true, pagesLoaded: this.state.pagesLoaded + 1, pagination }) // To show loader at the bottom
        this.getContentJson(previousCount, this.props.sourceUrl, true)
      }
    }
  }

  render() {
    const { channelName, events, containerStyle, eventCallback } = this.props

    return (
      <div className='content-container' style={containerStyle || {}}>
        {(this.state.isLoading &&
          <div>
            <CircularProgress style={{ margin: '10px auto 30px', display: 'block' }} />
          </div>
        )}
        {this.props.children}
        {
          (events)
            ? <Subscriber
              channelName={channelName}
              events={events}
              callback={() => {
                const itemCount = this.state.pageSize * this.state.pagesLoaded;
                if (itemCount > 50) { //DON'T AUTO REALOAD THE ENTIRE LIST IF IT IS DISPLAYING MORE THAN 50 RECORDS
                  //debugger;
                  return;
                }
                this.getContentJson(0, this.props.sourceUrl, false, itemCount)

                if (eventCallback) {
                  eventCallback()
                }
              }}
            />
            : null
        }
        {(this.state.isLoadingMore &&
          <div>
            <CircularProgress style={{ margin: '10px auto 30px', display: 'block' }} />
          </div>
        )}
      </div>
    )
  }
}

InfiniteScroll.propTypes = {
  dispatch: PropTypes.func.isRequired,
  sourceUrl: PropTypes.string.isRequired,
  pageSize: PropTypes.number,
  offsetAttribute: PropTypes.string,
  limitAttribute: PropTypes.string,
  dataReceived: PropTypes.func.isRequired,
  responseProperty: PropTypes.string,
  children: PropTypes.node.isRequired,
  fetchAll: PropTypes.bool,
  forceDataRefresh: PropTypes.number,
  channelName: PropTypes.string,
  events: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  eventCallback: PropTypes.func,
  containerElement: PropTypes.object,
  containerStyle: PropTypes.object,
  mainAppRef: PropTypes.object
}

const mapStateToProps = state => ({
  mainAppRef: state.application.mainAppRef
})

const mapDispatchToProps = dispatch => ({
  dispatch
})
export default connect(mapStateToProps, mapDispatchToProps)(InfiniteScroll)
