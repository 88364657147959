import PropTypes from 'prop-types'
import React from 'react'
import { Avatar, IconButton, Tooltip } from '@material-ui/core'
import { MenuItem } from 'material-ui'
import { Edit } from '@material-ui/icons'
import FormHelper from '../../businessLogic/formHelper'
import { FormsySelect } from 'formsy-material-ui'
import FieldWrapper from './FieldWrapper'
import ContactCardDialog from '../User/ContactCardDialog'

class PPlanUsersLimited extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      selectedValue: this.props.field.ValueID || '',
      contactCardOpen: false,
      edit: (!this.props.field.ProcessInstanceID || !this.props.field.Value)
    }
  }

  handleUserChange (event, selectedValue, index) {
    const { field, onChange } = this.props

    field.ValueID = selectedValue
    field.UserID = selectedValue

    this.setState({ selectedValue }, () => {
      if (onChange) { onChange(field) }
    })
  }

  render () {
    const { language, muiTheme: { palette } } = this.context
    const { edit } = this.state
    const { field, wrapperProps } = this.props
    const userOptions = [
      <MenuItem
        value='0'
        key={0}
        primaryText={(this.state.selectedValue === '') ? '' : language.translate('application.pleaseSelect')}
      />]

    if (field.UserOptionList && field.UserOptionList.length) {
      field.UserOptionList.map((option) => {
        userOptions.push(
          <MenuItem
            value={option.ID}
            key={option.ID}
            primaryText={option.FullName}
            leftIcon={<Avatar src={option.ProfilePicURL} style={{ height: '40px', width: '40px' }} />}
            innerDivStyle={{ padding: '5px 0px 10px 75px' }}
          />
        )
      })
    }

    return (
      <FieldWrapper
        label={FormHelper.decodeHTML(field.FieldName)}
        required={field.RequiredField}
        showDivider={(!edit)}
        {...wrapperProps}
      >
        {(!edit || field.ReadOnlyField)
          ? <div
            style={{
              position: 'relative',
              padding: '5px 0px',
              fontSize: '15px',
              display: 'flex',
              flexDirection: 'row'
            }}>
            <div style={{ flex: 5, paddingTop: '15px', width: '80%' }}>
              <a onClick={(e) => {
                this.setState({ contactCardOpen: true })
                e.preventDefault()
                e.stopPropagation()
              }}
                 style={{ cursor: 'pointer' }}>{field.Value}</a>
            </div>
            {(!field.ReadOnlyField)
              ? <div style={{ flex: 1 }}>
                <Tooltip title='Edit'>
                  <IconButton
                    onClick={() => this.setState({ edit: true })}
                  >
                    <Edit nativeColor={palette.accent3Color} />
                  </IconButton>
                </Tooltip>
              </div>
              : null}
          </div>
          : <FormsySelect
            value={this.state.selectedValue}
            onChange={this.handleUserChange.bind(this)}
            name={field.FieldName}
            validationErrors={this.context.language.messages.validationErrors}
            required={field.RequiredField}
            style={{ width: '100%', maxWidth: '256px' }}
          >
            {userOptions}
          </FormsySelect>}
        {(this.state.contactCardOpen)
          ? <ContactCardDialog
            open
            userID={this.state.selectedValue}
            close={() => this.setState({ contactCardOpen: false })}
          />
          : null}
      </FieldWrapper>
    )
  }
}

PPlanUsersLimited.propTypes = {
  field: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  wrapperProps: PropTypes.object
}

PPlanUsersLimited.contextTypes = {
  muiTheme: PropTypes.object,
  language: PropTypes.object
}

export default PPlanUsersLimited
