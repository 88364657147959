import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import {
  Button, ClickAwayListener, Dialog, DialogContent, DialogTitle, IconButton, Tooltip, Typography
} from '@material-ui/core'
import { Add, Close, Fullscreen } from '@material-ui/icons'
import ReactDataGrid from 'react-data-grid'
import objectAssign from 'object-assign'
import FormHelper from '../../businessLogic/formHelper'
import FieldWrapper from './FieldWrapper'
import './grid.scss'
import DeleteIconButton from '../Layout/DeleteIconButton'

class Grid extends React.Component {
  static convertGridToStringOfValues (grid) {
    let valuesString = ''

    grid.map((row) => {
      delete row.delete

      if (valuesString !== '') {
        valuesString += '\n'
      }

      valuesString += Object.values(row).toString()
    })

    return valuesString
  }

  static isValidGridValue (input) {
    try {
      if (input) {
        const o = JSON.parse(input)

        if (o && o.constructor === Array) {
          return o
        }
      }
    } catch (e) {}

    return false
  }

  constructor (props, context) {
    super(props, context)

    this.state = {
      grid: this.prepareGrid(),
      header: (props.field.FieldOptionList) ? this.prepareHeader() : [],
      show: true,
      autoFocus: false,
      popOut: false
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.rightPanelWidth !== this.props.rightPanelWidth) {
      this.setState({ show: false })

      setTimeout(() => {
        this.setState({ show: true })
      }, 50)
    }

    if (!prevProps.field.FieldOptionList && this.props.field.FieldOptionList) {
      this.setState({ header: this.prepareHeader() })
    }
  }

  prepareHeader () {
    const { field: { FieldOptionList, ReadOnlyField } } = this.props
    const header = []

    FieldOptionList.map((field) => {
      const metaData = JSON.parse(field.MetaData || '{}')

      header.push({
        editable: true,
        key: field.ID.toString(),
        name: field.OptionDescription,
        width: metaData.width || 100,
        resizable: true
      })
    })

    if (!ReadOnlyField) {
      header.push({ key: 'delete', width: 45, name: '', formatter: this.deleteColumn.bind(this) })
    }

    return header
  }

  deleteColumn ({ ...props }) {
    const { muiTheme: { palette } } = this.context

    return (
      <DeleteIconButton onDelete={() => this.removeRow(props.value)} style={{ padding: '0px', width: '20px' }} />
    )
  }

  onCellSelected ({ rowIdx, idx }) {
    const { popOut } = this.state

    if (popOut) {
      this.popOutGrid.openCellEditor(rowIdx, idx)
    } else {
      this.grid.openCellEditor(rowIdx, idx)
    }
  }

  prepareGrid () {
    const { field: { ValueID, FieldOptionList, ReadOnlyField } } = this.props
    let rows = []

    if (!ValueID) { return rows }

    // move invalid value, if present, to first cell
    if (Grid.isValidGridValue(ValueID)) {
      rows = JSON.parse(ValueID)
    } else {
      if (!FieldOptionList) { return rows }
      const newRow = {}

      FieldOptionList.map((header, index) => {
        newRow[header.ID] = (index === 0) ? ValueID : ''
      })

      rows.push(newRow)
    }

    if (!ReadOnlyField) {
      rows.map((row, index) => row.delete = index)
    }

    return rows
  }

  addRow () {
    const { field: { FieldOptionList } } = this.props
    const { grid } = this.state

    const newRow = { delete: grid.length }

    FieldOptionList.map((header) => {
      newRow[header.ID] = ''
    })

    grid[grid.length] = newRow

    this.setState({ grid }, () => {
      this.setState({ show: false })

      setTimeout(() => {
        this.setState({ show: true })
      }, 20)
    })
  }

  removeRow (index) {
    const { grid } = this.state
    const { onChange, field } = this.props

    grid.splice(index, 1)

    // update row index of delete buttons
    grid.map((row, index) => row.delete = index)

    this.setState({ grid }, () => {
      const gridToSave = JSON.parse(JSON.stringify(grid))

      gridToSave.map(row => delete row.delete)

      field.ValueID = JSON.stringify(grid)
      field.Value = Grid.convertGridToStringOfValues(JSON.parse(JSON.stringify(grid)))

      onChange(field)
    })
  }

  handleGridRowsUpdated ({ fromRow, toRow, updated }) {
    const { onChange, field } = this.props
    const grid = JSON.parse(JSON.stringify(this.state.grid))

    for (let i = fromRow; i <= toRow; i++) {
      grid[i] = objectAssign(grid[i], updated)
    }

    field.ValueID = JSON.stringify(grid)
    field.Value = Grid.convertGridToStringOfValues(JSON.parse(JSON.stringify(grid)))

    this.setState({ grid }, () => onChange(field))
  }

  render () {
    const { muiTheme: { palette }, language } = this.context
    const { autoFocus, popOut } = this.state

    const { header, grid, show } = this.state
    const { wrapperProps, field, screenWidth } = this.props

    const height = 54 + (grid.length * 35)

    return (
      <FieldWrapper
        label={FormHelper.decodeHTML(field.FieldName)}
        required={field.RequiredField}
        showDivider
        {...wrapperProps}
      >
        <ClickAwayListener
          onClickAway={() => {
            this.setState({ autoFocus: false })
          }}>
          {(!field.FieldOptionList)
            ? <div
              style={{ position: 'relative', padding: '5px 0px', fontSize: '15px' }}
              dangerouslySetInnerHTML={{ __html: FormHelper.encodeHTML(field.Value) }} />
            : <div>
              {(popOut)
                ? <Dialog
                  open
                  fullScreen
                  onClose={() => this.setState({ popOut: false })}
                >
                  <DialogTitle style={{ backgroundColor: palette.headerBackgroundColor, display: 'flex', justifyContent: 'space-between' }} disableTypography>
                    <Typography
                      variant='h4'
                      style={{ color: palette.alternateTextColor }}>{FormHelper.decodeHTML(field.FieldName)}</Typography>
                    <IconButton onClick={() => this.setState({ popOut: false })} aria-label='Close'>
                      <Close style={{ color: palette.alternateTextColor }} />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent>
                    <div className='Popout-DataGrid' style={{ height: `${height + 30}px`, paddingTop: '30px' }}>
                      {(show &&
                        <ReactDataGrid
                          ref={(node) => { if (node) { this.popOutGrid = node } }}
                          enableCellSelect={(!field.ReadOnlyField)}
                          columns={header}
                          rowGetter={row => (objectAssign({}, grid[row]))}
                          rowsCount={grid.length}
                          onGridRowsUpdated={this.handleGridRowsUpdated.bind(this)}
                          onColumnResize={(index, width) => {
                            let newHeader = JSON.parse(JSON.stringify(header))
                            newHeader[index].width = width

                            this.setState({ header: newHeader })
                          }}
                          minColumnWidth={40}
                          overScan={{ rowsStart: 0, rowsEnd: grid.length }}
                          enableCellAutoFocus
                          onCellSelected={this.onCellSelected.bind(this)}
                        />)}
                    </div>

                    {(!field.ReadOnlyField)
                      ? <Button
                        onClick={this.addRow.bind(this)}
                        color='primary'
                        style={{ margin: '10px' }}
                        variant='contained'
                      >
                        <Add />
                        {language.translate('application.add')}
                      </Button>
                      : null}
                  </DialogContent>
                </Dialog>
                : null}
              {(screenWidth > 500)
                ? <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                  <Tooltip title={language.translate('application.fullScreen')}>
                    <IconButton
                      onClick={() => this.setState({ popOut: true })}
                    >
                      <Fullscreen />
                    </IconButton>
                  </Tooltip>
                </div>
                : null}
              <div style={{ height: `${height}px` }} onClick={() => { this.setState({ autoFocus: true }) }}>
                {(show &&
                  <ReactDataGrid
                    ref={(node) => { if (node) { this.grid = node } }}
                    enableCellSelect={(!field.ReadOnlyField)}
                    columns={header}
                    rowGetter={row => (objectAssign({}, grid[row]))}
                    rowsCount={grid.length}
                    onGridRowsUpdated={this.handleGridRowsUpdated.bind(this)}
                    onColumnResize={(index, width) => {
                      let newHeader = JSON.parse(JSON.stringify(header))
                      newHeader[index].width = width

                      this.setState({ header: newHeader })
                    }}
                    minColumnWidth={40}
                    overScan={{ rowsStart: 0, rowsEnd: grid.length }}
                    enableCellAutoFocus={autoFocus}
                    onCellSelected={this.onCellSelected.bind(this)}
                  />)}
              </div>
              {(!field.ReadOnlyField)
                ? <Button
                  onClick={this.addRow.bind(this)}
                  color='primary'
                  style={{ margin: '10px' }}
                  variant='contained'
                >
                  <Add />
                  {language.translate('application.add')}
                </Button>
                : null}
            </div>
          }
        </ClickAwayListener>
      </FieldWrapper>
    )
  }
}

Grid.propTypes = {
  field: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  screenWidth: PropTypes.number,
  rightPanelWidth: PropTypes.number,
  wrapperProps: PropTypes.object
}

Grid.contextTypes = {
  muiTheme: PropTypes.object,
  language: PropTypes.object,
  user: PropTypes.object
}

const mapStateToProps = state => ({
  screenWidth: state.application.screenWidth,
  rightPanelWidth: state.application.rightPanelWidth
})

export default connect(mapStateToProps)(Grid)
