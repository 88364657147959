import React from 'react'
import PropTypes from 'prop-types'
import {
  Button, CircularProgress, Dialog, DialogActions, DialogTitle, DialogContent, MenuItem, TextField, Typography
} from '@material-ui/core'

class PrintableTemplateDocumentDialog extends React.Component {
  constructor(props) {
    super(props)

    this.imageLoaded = this.imageLoaded.bind(this)

    this.state = {
      selectedDocumentTemplate: (props.documentTemplates.length) ? props.documentTemplates[0].ID : '',
      fieldTokenList: [],
      imageCounter: 0,
      imagesToLoad: 0
    }
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    if (prevState.imageCounter !== this.state.imageCounter && this.state.imageCounter === this.state.imagesToLoad) {
      const printContainer = document.getElementById('printContainer').contentWindow
      printContainer.document.close()
      printContainer.focus()
      printContainer.print()
      printContainer.close()
    }
  }

  print(content) {
    const printContainer = document.getElementById('printContainer').contentWindow

    printContainer.document.open()
    printContainer.document.write(content)

    let images = printContainer.document.images

    if (images.length) {
      this.setState({ imagesToLoad: images.length })

      for (let image of images) {
        image.addEventListener('load', this.imageLoaded, false)
      }
    } else {
      printContainer.document.close()
      printContainer.focus()
      printContainer.print()
      printContainer.close()
    }
  }

  imageLoaded() {
    let { imageCounter } = this.state

    this.setState({ imageCounter: imageCounter + 1 })
  }

  render() {
    const { onClose, documentTemplates, fetchPrintableDocument } = this.props
    const { selectedDocumentTemplate, imageCounter, imagesToLoad } = this.state
    const { language, muiTheme: { palette } } = this.context

    return (
      <Dialog
        open
        onClose={onClose}
      >
        <DialogTitle
          style={{
            backgroundColor: palette.headerBackgroundColor
          }}
          disableTypography
        >
          <Typography
            variant='h6'
            style={{ color: palette.alternateTextColor }}>
            {language.translate('application.printSopDocument')}
          </Typography>
        </DialogTitle>
        <DialogContent style={{ width: '400px' }}>
          <div style={{ padding: '20px 0px' }}>
            <TextField
              fullWidth
              select
              label={language.translate('application.sopTemplate')}
              value={selectedDocumentTemplate}
              onChange={(e) => { this.setState({ selectedDocumentTemplate: e.target.value }) }}
            >
              {documentTemplates.map(option => (
                <MenuItem key={option.ID} value={option.ID}>
                  {option.TemplateTitle}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            onClick={() => {
              fetchPrintableDocument(selectedDocumentTemplate, (response) => {
                this.print(response.DocumentTemplate.HTMLTemplate)
              })
            }}
            variant='contained'
            disabled={(imagesToLoad !== imageCounter)}
          >
            {(imagesToLoad === imageCounter)
              ? language.translate('application.print')
              : <CircularProgress thickness={2} size={25} />}
          </Button>
        </DialogActions>
        <iframe
          id='printContainer'
          style={{ height: '0px', width: '0px', position: 'absolute', border: 'none' }}
        />
      </Dialog>
    )
  }
}

PrintableTemplateDocumentDialog.propTypes = {
  onClose: PropTypes.func,  //PropTypes.object.isRequired,
  documentTemplates: PropTypes.array,
  fetchPrintableDocument: PropTypes.func
}

PrintableTemplateDocumentDialog.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default PrintableTemplateDocumentDialog
