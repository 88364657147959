import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get, post } from '~/actions/base'
import {
  Button, Card, Collapse, Dialog, DialogContent, DialogTitle, Divider, FormControl, IconButton, InputLabel, List,
  ListItem,
  ListItemText, MenuItem, Select, TextField, Typography
} from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { Form } from 'formsy-react'
import { FormsyText } from 'formsy-material-ui'
import FormHelper from '~/businessLogic/formHelper'
import { Icon, GeneratedIcon } from '../Icon'
import IconSelector from '../Icon/IconSelector'
import { showSnackbar, showUndoMessage } from '~/actions/snackbar'
import AccessControlList from './AccessControlList'
import AdvancedSettings from './AdvancedSettings'
import PublicFormList from './PublicFormList'
import ShareCodeList from './ShareCodeList'
import ProcessFieldsSelectList from './ProcessFieldSelectList'
import DefaultVisibilityList from './DefaultVisibilityList'
import RightPanelContent from '../Layout/RightPanelContent'
import CancelButton from '../Forms/CancelButton'

class ProcessTemplateSettings extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      isLoaded: false,
      templateGroups: [],
      templateGroupsLoaded: false,
      templateFields: null,
      templateFieldsLoaded: false,
      template: null,
      accessControlLoaded: false,
      accessControlList: null,
      accessControlDialogOpen: false,
      publicFormListLoaded: false,
      publicFormList: null,
      shareCodesLoaded: false,
      shareCodes: null,
      defaultVisibilityLoaded: false,
      defaultVisibilityList: null,
      showIconSelector: false,
      iconCategorySelected: 'all',
      showNewGroupDialog: false,
      canSubmitNewGroup: true,
      newGroupName: null,
      descriptionExpanded: false,
      advancedSettingsExpanded: false,
      fieldsToDisplayExpanded: false,
      fieldsToShareExpanded: false,
      accessControlExpanded: false,
      shareCodeExpanded: false,
      defaultVisibilityExpanded: false,
      publicFormExpanded: false
    }
  }

  // MIGRATED TO componentDidMount
  // componentWillMount () {
  //   this.fetchAllData()
  // }

  componentDidMount() {
    if (this.state.isLoaded === false) {
      this.fetchAllData()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.templateId !== this.props.templateId) {
      this.fetchAllData()
    }
  }

  fetchAllData() {
    this.fetchProcessTemplateSettings()
    this.fetchTemplateGroups()
    this.fetchTemplateFields()
    this.fetchAccessControlList()
    this.fetchPublicFormList()
    this.fetchShareCodeList()
    this.fetchDefaultVisibilityList()
  }

  fetchProcessTemplateSettings() {
    const { templateId } = this.props

    this.props.get(`processtemplate/${templateId}`, {
      onSuccess: (response) => {
        this.setState({
          isLoaded: true,
          template: response.ProcessTemplate
        })
      }
    })
  }

  fetchTemplateGroups(afterSuccess) {
    this.props.get('processtemplategroup/list', {
      onSuccess: (response) => {
        this.setState({
          templateGroupsLoaded: true,
          templateGroups: response.ProcessTemplateGroupList
        }, () => {
          if (afterSuccess) { afterSuccess(response.ProcessTemplateGroupList) }
        })
      }
    })
  }

  saveNewTemplateGroup() {
    const { newGroupName, template } = this.state

    const body = JSON.stringify({
      ProcessTemplateGroup: {
        GroupName: newGroupName
      }
    })

    this.props.post('processtemplategroup', body, {
      onSuccess: (response) => {
        this.fetchTemplateGroups((groupList) => {
          template.ProcessGroupID = groupList.filter(group => (group.GroupName === newGroupName))[0].ID
          this.saveAdvancedSettings(template)
          this.setState({ newGroupName: null })
        })
      }
    })
  }

  fetchTemplateFields() {
    this.props.get(`processtemplate/${this.props.templateId}/field/list`, {
      onSuccess: (response) => {
        this.setState({
          templateFieldsLoaded: true,
          templateFields: response.ProcessTemplateFieldList
        })
      }
    })
  }

  fetchAccessControlList() {
    this.props.get(`processtemplate/${this.props.templateId}/accesscontrol/list`, {
      onSuccess: (response) => {
        this.setState({
          accessControlLoaded: true,
          accessControlList: response.ProcessTemplateAccessControlList
        })
      }
    })
  }

  fetchPublicFormList() {
    this.props.get(`processtemplate/${this.props.templateId}/publicform/list`, {
      onSuccess: (response) => {
        this.setState({
          publicFormListLoaded: true,
          publicFormList: response.ProcessTemplatePublicFormList
        })
      }
    })
  }

  savePublicFormList(list, callback) {
    const body = JSON.stringify({
      ProcessTemplatePublicFormList: list
    })

    this.props.post(`processtemplate/${this.props.templateId}/publicform/list`, body, {
      onSuccess: (response) => {
        this.setState({
          publicFormList: response.ProcessTemplatePublicFormList
        }, () => {
          if (callback) {
            callback(response.ProcessTemplatePublicFormList)
          }
        })
      }
    })
  }

  savePublicFormLogo(logo, publicFormId) {
    const body = JSON.stringify({
      ProcessTemplatePublicFormLogo: logo
    })

    this.props.post(`processtemplate/${this.props.templateId}/publicform/${publicFormId}/logo`, body, {
      onSuccess: (response) => {
        const { publicFormList } = this.state

        publicFormList.map((publicForm) => {
          if (publicForm.ID === publicFormId) {
            publicForm.PublicFormLogo_FileName = response.ProcessTemplatePublicForm.PublicFormLogo_FileName
            publicForm.PublicFormLogo_URL = response.ProcessTemplatePublicForm.PublicFormLogo_URL
          }
        })

        // make public form view pull this image if present and fallback to account logo

        this.setState({ publicFormList })
      }
    })
  }

  deletePublicFormLogo(publicFormId) {
    const body = JSON.stringify({})

    this.props.post(`processtemplate/${this.props.templateId}/publicform/${publicFormId}/logo/delete`, body, {
      onSuccess: (response) => {
        const { publicFormList } = this.state

        publicFormList.map((publicForm) => {
          if (publicForm.ID === publicFormId) {
            publicForm.PublicFormLogo_FileName = response.ProcessTemplatePublicForm.PublicFormLogo_FileName
            publicForm.PublicFormLogo_URL = null
          }
        })

        this.setState({ publicFormList })
      }
    })
  }

  deletePublicFormListItem(item) {
    const { language } = this.context

    const body = JSON.stringify({})

    this.props.post(
      `processtemplate/${this.props.templateId}/publicform/${item.ID}/delete`,
      body,
      {
        onSuccess: (response) => {
          this.fetchPublicFormList()

          this.props.showUndoMessage(
            language.translate('application.publicFormRemoved'),
            () => {
              this.props.post(
                `processtemplate/${this.props.templateId}/publicform/${item.ID}/delete/undo`,
                body,
                {
                  onSuccess: (response) => {
                    this.fetchPublicFormList()
                  }
                }
              )
            }
          )
        }
      }
    )
  }

  saveAccessControlList(list) {
    const body = JSON.stringify({
      ProcessTemplateAccessControlList: list
    })

    this.props.post(`processtemplate/${this.props.templateId}/accesscontrol/list`, body, {
      onSuccess: (response) => {
        this.setState({
          accessControlList: response.ProcessTemplateAccessControlList
        })
      }
    })
  }

  saveAdvancedSettings(settings) {
    const body = JSON.stringify({
      ProcessTemplate: settings
    })

    this.props.post(`processtemplate/${this.props.templateId}`, body, {
      onSuccess: (response) => {
        this.setState({
          template: response.ProcessTemplate
        })
      }
    })
  }

  deleteAccessControlListItem(item) {
    const { language } = this.context

    const body = JSON.stringify({})

    this.props.post(
      `processtemplate/${this.props.templateId}/accesscontrol/${item.ID}/delete`,
      body,
      {
        onSuccess: (response) => {
          this.fetchAccessControlList()

          this.props.showUndoMessage(
            language.translate('application.accessControlItemRemoved'),
            () => {
              this.props.post(
                `processtemplate/${this.props.templateId}/accesscontrol/${item.ID}/delete/undo`,
                body,
                {
                  onSuccess: (response) => {
                    this.fetchAccessControlList()
                  }
                }
              )
            }
          )
        }
      }
    )
  }

  fetchShareCodeList() {
    this.props.get(`processtemplate/${this.props.templateId}/sharecode/list`, {
      onSuccess: (response) => {
        this.setState({
          shareCodesLoaded: true,
          shareCodes: response.ProcessTemplateShareCodeList
        })
      }
    })
  }

  saveShareCodeList(list) {
    const body = JSON.stringify({
      ProcessTemplateShareCodeList: list
    })

    this.props.post(`processtemplate/${this.props.templateId}/sharecode/list`, body, {
      onSuccess: (response) => {
        this.setState({
          shareCodes: response.ProcessTemplateShareCodeList
        })
      }
    })
  }

  deleteShareCodeListItem(item) {
    const { language } = this.context

    const body = JSON.stringify({})

    this.props.post(
      `processtemplate/${this.props.templateId}/sharecode/${item.ID}/delete`,
      body,
      {
        onSuccess: (response) => {
          this.fetchShareCodeList()

          this.props.showUndoMessage(
            language.translate('application.shareCodeRemoved'),
            () => {
              this.props.post(
                `processtemplate/${this.props.templateId}/sharecode/${item.ID}/delete/undo`,
                body,
                {
                  onSuccess: (response) => {
                    this.fetchShareCodeList()
                  }
                }
              )
            }
          )
        }
      }
    )
  }

  fetchDefaultVisibilityList() {
    this.props.get(
      `processtemplate/${this.props.templateId}/defaultinstancevisibility/list`,
      {
        onSuccess: (response) => {
          this.setState({
            defaultVisibilityLoaded: true,
            defaultVisibilityList: response.ProcessTemplateDefaultInstanceVisibilityList
          })
        }
      }
    )
  }

  saveDefaultVisibilityList(list) {
    const body = JSON.stringify({
      ProcessTemplateDefaultInstanceVisibilityList: list
    })

    this.props.post(
      `processtemplate/${this.props.templateId}/defaultinstancevisibility/list`,
      body,
      {
        onSuccess: (response) => {
          this.setState({
            defaultVisibilityList: response.ProcessTemplateDefaultInstanceVisibilityList
          })
        }
      }
    )
  }

  deleteDefaultVisibilityListItem(item) {
    const { language } = this.context

    const body = JSON.stringify({})

    this.props.post(
      `processtemplate/${this.props.templateId}/defaultinstancevisibility/${item.ID}/delete`,
      body,
      {
        onSuccess: (response) => {
          this.fetchDefaultVisibilityList()

          this.props.showUndoMessage(
            language.translate('application.visibilityRemoved'),
            () => {
              this.props.post(
                `processtemplate/${this.props.templateId}/defaultinstancevisibility/${item.ID}/delete/undo`,
                body,
                {
                  onSuccess: (response) => {
                    this.fetchDefaultVisibilityList()
                  }
                }
              )
            }
          )
        }
      }
    )
  }

  saveTemplateLogo(image) {
    const body = JSON.stringify({
      ProcessTemplateLogo: image
    })

    this.props.post(`processtemplate/${this.props.templateId}/logo`, body, {
      onSuccess: (response) => {
        const { template } = this.state

        template.TemplateLogo_URL = response.ProcessTemplate.TemplateLogo_URL
        template.TemplateLogo_FileName = response.ProcessTemplate.TemplateLogo_FileName

        this.setState({ template })
      }
    })
  }

  deleteTemplateLogo() {
    const body = JSON.stringify({})

    this.props.post(`processtemplate/${this.props.templateId}/logo/delete`, body, {
      onSuccess: (response) => {
        this.fetchProcessTemplateSettings()
      }
    })
  }

  getIconCategories() {
    return {
      all: 'All',
      alerts: 'Alerts',
      avatar: 'Avatar',
      cloud: 'Cloud',
      commerce: 'Commerce',
      education: 'Education',
      electronics: 'Electronics',
      financial: 'Financial',
      internet: 'Internet',
      logo: 'Logo',
      marketing: 'Marketing',
      medical: 'Medical',
      office: 'Office',
      payment: 'Payment',
      realestate: 'Real Estate',
      science: 'Science',
      security: 'Security',
      sports: 'Sports',
      transport: 'Transport',
      travel: 'Travel',
      web: 'Web'
    }
  }

  getIconCategoryMenuItems() {
    const categories = this.getIconCategories()
    const menu = []

    Object.keys(categories).map((value, index) => {
      menu.push(<MenuItem value={value} key={value}>{categories[value]}</MenuItem>)
    })

    return menu
  }

  generateMenuItems() {
    const { templateGroups } = this.state
    const menu = []

    if (templateGroups.length) {
      templateGroups.map((option, index) => {
        menu.push(<MenuItem value={option.ID} key={option.ID}>{option.GroupName}</MenuItem>)
      })
    }

    return menu
  }

  render() {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const {
      template, templateFieldsLoaded, templateFields, accessControlList, accessControlLoaded, isLoaded,
      publicFormListLoaded, publicFormList, shareCodesLoaded, shareCodes, defaultVisibilityLoaded,
      defaultVisibilityList, templateGroups, templateGroupsLoaded, showNewGroupDialog, canSubmitNewGroup, newGroupName,
      descriptionExpanded, advancedSettingsExpanded, fieldsToDisplayExpanded, fieldsToShareExpanded,
      accessControlExpanded, shareCodeExpanded, defaultVisibilityExpanded, publicFormExpanded
    } =
      this.state
    const { showSnackbar, user } = this.props

    return (
      <RightPanelContent
        title={language.translate('application.templateSettings')}
        closePanel={() => this.props.close()}
        leftToolbarAvatar={
          (isLoaded)
            ? <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {(template.IconName)
                ? <IconButton
                  style={{ padding: '0px', width: '40px', height: '40px' }}
                  onClick={() => this.setState({ showIconSelector: true })}
                >
                  <Icon icon={template.IconName} size={40} />
                </IconButton>
                : <GeneratedIcon
                  className='clickable'
                  text={template.Title}
                  randomizer={template.ID}
                  onClick={() => this.setState({ showIconSelector: true })}
                />}
              <div style={{
                fontSize: '10px',
                color: palette.accent2Color,
                padding: '0px 7px',
                float: 'left',
                position: 'absolute',
                backgroundColor: palette.disabledColor,
                top: '35px',
                whiteSpace: 'nowrap',
                zIndex: 10
              }}
              >{language.translate('application.change')}
              </div>
            </div>
            : null
        }
      >
        <div style={{ padding: '15px' }}>
          {(isLoaded)
            ? <Card className='card'>
              <List style={{ padding: '0px' }}>
                <ListItem
                  button
                  onClick={() => { this.setState({ descriptionExpanded: !descriptionExpanded }) }}
                >
                  <ListItemText
                    primary={language.translate('application.description')} />
                  {descriptionExpanded ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={descriptionExpanded} timeout='auto' unmountOnExit>
                  <div style={{ padding: '10px 20px 0px 20px', backgroundColor: palette.accent2Color }}>
                    <TextField
                      key='description'
                      name='description'
                      label={language.translate('application.templateDescription')}
                      fullWidth
                      multiline
                      rows={3}
                      defaultValue={FormHelper.decodeHTML(template.Description)}
                      onBlur={(event) => {
                        if (template.Description !== event.currentTarget.value) {
                          template.Description = event.currentTarget.value
                          this.saveAdvancedSettings(template)
                        }
                      }}
                    />
                    <div key='instructions' style={{ fontSize: '16px', marginTop: '10px', marginBottom: '10px' }}>
                      <TextField
                        name='instructions'
                        label={language.translate('application.launchInstructions')}
                        fullWidth
                        multiline
                        rows={3}
                        defaultValue={FormHelper.decodeHTML(template.FormInstructions)}
                        onBlur={(event) => {
                          if (template.FormInstructions !== event.currentTarget.value) {
                            template.FormInstructions = event.currentTarget.value
                            this.saveAdvancedSettings(template)
                          }
                        }}
                      />
                      <Button
                        target='_blank'
                        href='https://commonmark.org/help/'
                        color='primary'
                      >
                        {language.translate('application.markdownEnabled')}
                      </Button>
                    </div>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}>
                      <FormControl>
                        <InputLabel style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {language.translate('application.templateGroup')}
                        </InputLabel>
                        <Select
                          name='template_group'
                          value={template.ProcessGroupID || ''}
                          style={{ minWidth: '250px' }}
                          MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
                          onChange={(e) => {
                            template.ProcessGroupID = e.target.value
                            this.saveAdvancedSettings(template)
                          }}
                        >
                          {
                            this.generateMenuItems()
                          }
                        </Select>
                      </FormControl>
                      <Button
                        style={{ minWidth: '155px' }}
                        onClick={() => this.setState({ showNewGroupDialog: true })}
                        color='primary'
                        variant='contained'
                      >
                        {language.translate('application.manageGroups')}
                      </Button>
                    </div>
                    <div key='milestoneInstructions'
                      style={{ fontSize: '16px', marginTop: '10px', marginBottom: '10px' }}>
                      <TextField
                        name='milestoneInstructions'
                        label={language.translate('application.milestoneInstructions')}
                        fullWidth
                        multiline
                        rows={3}
                        defaultValue={FormHelper.decodeHTML(template.MilestoneInstructions)}
                        onBlur={(event) => {
                          if (template.MilestoneInstructions !== event.currentTarget.value) {
                            template.MilestoneInstructions = event.currentTarget.value
                            this.saveAdvancedSettings(template)
                          }
                        }}
                      />
                      <Button
                        target='_blank'
                        href='https://commonmark.org/help/'
                        color='primary'
                      >
                        {language.translate('application.markdownEnabled')}
                      </Button>
                    </div>
                  </div>
                </Collapse>
                <Divider />
                <ListItem
                  button
                  onClick={() => { this.setState({ advancedSettingsExpanded: !advancedSettingsExpanded }) }}
                >
                  <ListItemText
                    primary={language.translate('application.advancedProcessSettings')} />
                  {advancedSettingsExpanded ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={advancedSettingsExpanded} timeout='auto' unmountOnExit>
                  {(templateGroupsLoaded)
                    ? <AdvancedSettings
                      key='advanced_settings'
                      template={template}
                      user={user}
                      saveTemplateLogo={this.saveTemplateLogo.bind(this)}
                      deleteTemplateLogo={this.deleteTemplateLogo.bind(this)}
                      onSave={this.saveAdvancedSettings.bind(this)}
                    />
                    : null}
                </Collapse>
                <Divider />
                <ListItem
                  button
                  onClick={() => { this.setState({ fieldsToDisplayExpanded: !fieldsToDisplayExpanded }) }}
                >
                  <ListItemText
                    primary={language.translate('application.processFieldsToDisplay')} />
                  {fieldsToDisplayExpanded ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={fieldsToDisplayExpanded} timeout='auto' unmountOnExit>
                  {(templateFieldsLoaded)
                    ? <ProcessFieldsSelectList
                      key='field-select'
                      toggledFields={template.ProcessTemplateFieldID_ToDisplayAtStart}
                      onSave={(requiredList) => {
                        template.ProcessTemplateFieldID_ToDisplayAtStart = requiredList
                        this.saveAdvancedSettings(template)
                      }}
                      templateFields={templateFields}
                    />
                    : null}
                </Collapse>
                <Divider />
                <ListItem
                  button
                  onClick={() => { this.setState({ fieldsToShareExpanded: !fieldsToShareExpanded }) }}
                >
                  <ListItemText
                    primary={language.translate('application.processFieldsToShareWithParent')} />
                  {fieldsToShareExpanded ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={fieldsToShareExpanded} timeout='auto' unmountOnExit>
                  {(templateFieldsLoaded)
                    ? <ProcessFieldsSelectList
                      key='field-select'
                      toggledFields={template.ProcessTemplateFieldID_ToShareWithParentProcesses}
                      onSave={(requiredList) => {
                        template.ProcessTemplateFieldID_ToShareWithParentProcesses = requiredList
                        this.saveAdvancedSettings(template)
                      }}
                      templateFields={templateFields}
                    />
                    : null}
                </Collapse>
                <Divider />
                <ListItem
                  button
                  onClick={() => { this.setState({ accessControlExpanded: !accessControlExpanded }) }}
                >
                  <ListItemText
                    primary={language.translate('application.accessControl')} />
                  {accessControlExpanded ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={accessControlExpanded} timeout='auto' unmountOnExit>
                  {(accessControlLoaded)
                    ? <AccessControlList
                      key='access-control'
                      accessControlList={accessControlList}
                      templateId={template.ID}
                      template={template}
                      onSaveTemplate={this.saveAdvancedSettings.bind(this)}
                      onSaveList={this.saveAccessControlList.bind(this)}
                      onDelete={this.deleteAccessControlListItem.bind(this)}
                    />
                    : null}
                </Collapse>
                <Divider />
                <ListItem
                  button
                  onClick={() => { this.setState({ shareCodeExpanded: !shareCodeExpanded }) }}
                >
                  <ListItemText
                    primary={language.translate('application.templateSharing', [], true)} />
                  {shareCodeExpanded ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={shareCodeExpanded} timeout='auto' unmountOnExit>
                  {(shareCodesLoaded)
                    ? <ShareCodeList
                      key='share-code'
                      templateId={template.ID}
                      template={template}
                      shareCodeList={shareCodes}
                      onSaveTemplate={this.saveAdvancedSettings.bind(this)}
                      onSaveList={this.saveShareCodeList.bind(this)}
                      onDelete={this.deleteShareCodeListItem.bind(this)}
                      displayMessage={message => showSnackbar(message)}
                    />
                    : null}
                </Collapse>
                <Divider />
                <ListItem
                  button
                  onClick={() => { this.setState({ defaultVisibilityExpanded: !defaultVisibilityExpanded }) }}
                >
                  <ListItemText
                    primary={language.translate('application.defaultTemplateVisibilityTitle')}
                    secondary={language.translate('application.defaultTemplateVisibilitySubtitle')} />
                  {defaultVisibilityExpanded ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={defaultVisibilityExpanded} timeout='auto' unmountOnExit>
                  {(defaultVisibilityLoaded)
                    ? <DefaultVisibilityList
                      key='default-visibility'
                      defaultVisibilityList={defaultVisibilityList}
                      templateId={template.ID}
                      onSaveList={this.saveDefaultVisibilityList.bind(this)}
                      onDelete={this.deleteDefaultVisibilityListItem.bind(this)}
                    />
                    : null}
                </Collapse>
                <Divider />
                <ListItem
                  button
                  onClick={() => { this.setState({ publicFormExpanded: !publicFormExpanded }) }}
                >
                  <ListItemText
                    primary={language.translate('application.publicForm', [], true)} />
                  {publicFormExpanded ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={publicFormExpanded} timeout='auto' unmountOnExit>
                  {(publicFormListLoaded && templateFieldsLoaded)
                    ? <PublicFormList
                      key='public-form'
                      publicFormList={publicFormList}
                      templateId={template.ID}
                      processFields={templateFields}
                      onSaveList={this.savePublicFormList.bind(this)}
                      onDelete={this.deletePublicFormListItem.bind(this)}
                      displayMessage={message => showSnackbar(message)}
                      onSaveLogo={this.savePublicFormLogo.bind(this)}
                      onDeleteLogo={this.deletePublicFormLogo.bind(this)}
                    />
                    : null}
                </Collapse>
              </List>
              <Dialog
                open={this.state.showIconSelector}
                onClose={() => this.setState({ showIconSelector: false })}
                style={{ top: '-30px' }}
              >
                <DialogTitle>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingBottom: '5px'
                  }}
                  >
                    <div>{language.translate('application.iconSelector')}</div>
                    <FormControl
                      style={{ width: '130px', margin: '10px 0px' }}
                    >
                      <InputLabel style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {`${language.translate('application.category')}...`}
                      </InputLabel>
                      <Select
                        name='categories'
                        MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
                        value={this.state.iconCategorySelected}
                        onChange={(e) => {
                          this.setState({ iconCategorySelected: e.target.value })
                        }}
                      >
                        {this.getIconCategoryMenuItems()}
                      </Select>
                    </FormControl>
                  </div>
                </DialogTitle>
                <DialogContent style={{ width: '350px' }}>
                  <IconSelector
                    selectedIcon={template.IconName}
                    searchText={this.state.iconCategorySelected}
                    onSelect={(icon) => {
                      template.IconName = icon
                      this.saveAdvancedSettings(template)

                      this.setState({ showIconSelector: false })
                    }}
                  />
                </DialogContent>
              </Dialog>
            </Card>
            : null}
          <Dialog
            open={showNewGroupDialog}
            onClose={() => this.setState({ showNewGroupDialog: false })}
          >
            <DialogTitle
              style={{
                backgroundColor: palette.headerBackgroundColor
              }}
              disableTypography
            >
              <Typography
                variant='h6'
                style={{ color: palette.alternateTextColor }}>
                {language.translate('application.createNewTemplateGroup')}
              </Typography>
            </DialogTitle>
            <DialogContent style={{ width: '300px' }}>
              <Form
                onValid={() => this.setState({ canSubmitNewGroup: true })}
                onInvalid={() => this.setState({ canSubmitNewGroup: false })}
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <FormsyText
                  fullWidth
                  required
                  floatingLabelText={language.translate('application.newGroupName')}
                  value={newGroupName}
                  onChange={(e) => {
                    this.setState({ newGroupName: e.currentTarget.value })
                  }}
                  name='pw'
                  validationErrors={language.messages.validationErrors}
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    paddingTop: '30px'
                  }}
                >
                  <Button
                    onClick={() => {
                      this.saveNewTemplateGroup()
                      this.setState({ showNewGroupDialog: false })
                    }}
                    disabled={!canSubmitNewGroup}
                    color='primary'
                    variant='contained'
                  >
                    {language.translate('application.save')}
                  </Button>
                  <CancelButton
                    onClick={() => this.setState({ showNewGroupDialog: false })}
                    variant='contained'
                  />
                </div>
              </Form>
            </DialogContent>
          </Dialog>
        </div>
      </RightPanelContent>
    )
  }
}

ProcessTemplateSettings.propTypes = {
  templateId: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func,
  user: PropTypes.object,
  showUndoMessage: PropTypes.func.isRequired
}

ProcessTemplateSettings.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

const mapStateToProps = state => ({
  user: state.auth
})

const mapDispatchToProps = dispatch => ({
  dispatch,
  post: bindActionCreators(post, dispatch),
  get: bindActionCreators(get, dispatch),
  showSnackbar: bindActionCreators(showSnackbar, dispatch),
  showUndoMessage: bindActionCreators(showUndoMessage, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ProcessTemplateSettings)
