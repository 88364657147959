import PropTypes from 'prop-types'
import React from 'react'
import { Button, List } from '@material-ui/core'
import { Settings } from '@material-ui/icons'
import { Form } from 'formsy-react'
import CustomField from '../CustomField'
import ProcessFieldHelper from '../../businessLogic/processFieldHelper'

class CustomFieldValueList extends React.Component {
  render () {
    const { language, muiTheme: { palette } } = this.context
    const { fields, onChange, manageCustomFields } = this.props

    return (
      <Form
        autoComplete='off'
      >
        {(fields.length)
          ? <List style={{ padding: '0px 20px', backgroundColor: palette.accent2Color }}>
            {fields.map((field, index) => {
              let filter = null

              if (field.DependantOnFieldType) {
                filter = ProcessFieldHelper.createFilterFromDependantFieldValue(field.DependantOnFieldType, fields)
              }

              if (ProcessFieldHelper.isInstanceLookupField(field.FieldType) && field.MetaData) {
                filter = ProcessFieldHelper.createFilterFromParsedMetaData(field.MetaData, fields)
              }

              return <CustomField
                key={field.FieldID}
                field={field}
                fieldList={fields}
                ref={field.FieldID}
                dispatch={this.props.dispatch}
                filter={filter}
                onUpdate={(field) => {
                  onChange(index, field)
                }}
                externalSourceEndpoint={(field.FieldType === 'INSTANCE' || field.FieldType === 'INSTCHLS') ? `user/field/${field.FieldID}/externaloption/list` : null}
              />
            })}
          </List>
          : <div style={{
            padding: '10px 0px'
          }}
          >
            {language.translate('application.noUserFields')}
          </div>
        }
        <Button
          onClick={() => manageCustomFields()}
          color='primary'
          variant='contained'
        >
          <Settings />
          {language.translate('application.manageCustomFields')}
        </Button>
      </Form>
    )
  }
}

CustomFieldValueList.propTypes = {
  fields: PropTypes.array,
  onChange: PropTypes.func,
  dispatch: PropTypes.func,
  manageCustomFields: PropTypes.func
}

CustomFieldValueList.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default CustomFieldValueList
