class TranslationHelper {
  static translate (messages, messageId, values = [], isPlural = false) {
    let message = this.getMessageFromObject(messageId, messages, isPlural)

    if (!values.length) { return message }

    message = this.replaceVariablesInMessage(message, values)

    return message
  }

  static getMessageFromObject (messageId, messages, isPlural) {
    const messageIdArray = messageId.split('.')
    messageIdArray.map((token) => { messages = messages[token] })

    if (!messages) {
      throw new Error(`No language entry found in file for: ${messageId}`)
    }

    const message = messages.split('|')

    if (isPlural && message[1]) { return message[1] } return message[0]
  }

  static replaceVariablesInMessage (message, values) {
    values.map((value, index) => {
      message = message.replace(`{${index}}`, value)
    })

    return message
  }
}

export default TranslationHelper
