import PropTypes from 'prop-types'
import React from 'react'
import { Avatar, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core'
import { MenuItem } from 'material-ui'
import { Form } from 'formsy-react'
import ExternalSource from '~components/Forms/ExternalSource'
import DeleteIconButton from '~/components/Layout/DeleteIconButton'
import Icon from '../../components/Icon/Icon'
import GeneratedIcon from '../../components/Icon/GeneratedIcon'
import FormsyTemplateAutoComplete from '../../components/Forms/FormsyTemplateAutoComplete'

class DashboardGroupTemplateListItem extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      canSubmit: true
    }
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const { template, removeTemplateFromGroup, addTemplateToGroup, onTemplateSelect, disableEdit } = this.props
    const { canSubmit } = this.state

    return (
      (template.Title)
        ? <ListItem button={(!disableEdit)} onClick={() => {
          if (!disableEdit) {
            onTemplateSelect(template.ID)
          }
        }}>
          {(template.IconName)
            ? <Icon icon={template.IconName} size={40} />
            : <GeneratedIcon
              text={template.Title}
              randomizer={template.ID}
            />}
          <ListItemText
            primary={template.Title} />
          {(!disableEdit &&
            <ListItemSecondaryAction>
              <DeleteIconButton onDelete={() => removeTemplateFromGroup(template.ID)} />
            </ListItemSecondaryAction>)}
        </ListItem>
        : <ListItem style={{ paddingLeft: '20px' }}>
          <ListItemText
            primary={
              <Form
                onValid={() => this.setState({ canSubmit: true })}
                onInvalid={() => this.setState({ canSubmit: false })}
              >
                <FormsyTemplateAutoComplete
                  required
                  name='template_select'
                  floatingLabelText={language.translate('application.selectTemplate')}
                  onTemplateChange={(selectedId) => { if (selectedId) { addTemplateToGroup(selectedId) } }}
                  sortString='Title ASC'
                />
              </Form>
            } />
          <ListItemSecondaryAction style={{ top: '20%' }}>
            <DeleteIconButton onDelete={() => removeTemplateFromGroup()} />
          </ListItemSecondaryAction>
        </ListItem>
    )
  }
}

DashboardGroupTemplateListItem.propTypes = {
  template: PropTypes.object.isRequired,
  removeTemplateFromGroup: PropTypes.func.isRequired,
  addTemplateToGroup: PropTypes.func.isRequired,
  onTemplateSelect: PropTypes.func
}

DashboardGroupTemplateListItem.contextTypes = {
  location: PropTypes.object,
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default DashboardGroupTemplateListItem
