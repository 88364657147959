import { createReducer } from '../utils'
import { TOGGLE_NAVIGATION_REQUEST } from '../constants/NavigationTypes'
import objectAssign from 'object-assign'

const initialState = {
  open: (document.body.clientWidth > 1199)
}

export default createReducer(initialState, {
  [TOGGLE_NAVIGATION_REQUEST]: state => objectAssign({}, state, {
    open: !state.open
  })
})
