import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'react-router-redux'
import { Base64 } from 'js-base64'
import objectAssign from 'object-assign'
import {
  Attachment, CheckBox, Date, Default, ExternalSource, Link, PPlanUsersAll, PPlanUsersLimited, Radio, Select, Static,
  Text, Time, Location, Signature, Address, UserLookup, Grid, ExternalSourceChecklist
} from './CustomFields'
import InternalLink from './CustomFields/InternalLink'

class CustomField extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      tempField: {}
    }
  }

  tempStoreField (field) {
    this.setState({ tempField: field })
  }

  updateAndClear () {
    const { onUpdate } = this.props
    const { tempField } = this.state

    if (Object.keys(tempField).length > 0) {
      onUpdate(tempField)
    }

    this.setState({ tempField: {} })
  }

  render () {
    const { tempField } = this.state
    const {
      onUpdate, publicFormToken, field, dispatch, apiServer, filter, push, autoFocus, actionButton,
      externalSourceEndpoint, afterDataLoad, fieldList, ...rest
    } = this.props
    const { language } = this.context
    let row = ''

    switch (field.FieldType) {
      case '1':
        row = (<Text
          field={(tempField.ID) ? tempField : field}
          onBlur={this.updateAndClear.bind(this)}
          onChange={this.tempStoreField.bind(this)}
          autoFocus={autoFocus}
          {...rest}
        />)
        break
      case '3':
      case '5':
        row = (<Text
          field={(tempField.ID) ? tempField : field}
          onBlur={this.updateAndClear.bind(this)}
          onChange={this.tempStoreField.bind(this)}
          autoFocus={autoFocus}
          rows={parseInt(field.FieldType)}
          multiLine
          {...rest}
        />)
        break
      case 'N':
      case 'CMWRISK':
      case 'ID':
        row = (<Text
          field={(tempField.ID) ? tempField : field}
          type='tel'
          onBlur={this.updateAndClear.bind(this)}
          onChange={this.tempStoreField.bind(this)}
          autoFocus={autoFocus}
          {...rest}
        />)
        break
      case 'L':
        row = <Select field={field} onChange={onUpdate} {...rest} />
        break
      case 'C':
        row = <CheckBox field={field} onChange={onUpdate} {...rest} />
        break
      case 'R':
        row = <Radio field={field} onChange={onUpdate} {...rest} />
        break
      case 'D':
      case 'PTD':
        row = <Date field={field} onChange={onUpdate} {...rest} />
        break
      case 'E':
        row = (<Link
          type='email'
          field={field}
          url={field.Value}
          label={field.FieldName}
          editable
          onBlur={this.updateAndClear.bind(this)}
          onChange={this.tempStoreField.bind(this)}
          apiServer={apiServer}
          {...rest}
        />)
        break
      case 'PH':
        row = (<Link
          type='phone'
          field={field}
          url={field.Value}
          label={field.FieldName}
          editable
          onBlur={this.updateAndClear.bind(this)}
          onChange={this.tempStoreField.bind(this)}
          apiServer={apiServer}
          {...rest}
        />)
        break
      case 'U':
        row = (<Link
          type='url'
          url={field.Value}
          field={field}
          label={field.FieldName}
          editable
          onBlur={this.updateAndClear.bind(this)}
          onChange={this.tempStoreField.bind(this)}
          apiServer={apiServer}
          {...rest}
        />)
        break
      case 'A':
        row = (<Attachment
          field={field}
          dispatch={dispatch}
          onChange={onUpdate}
          isPublic={!!(publicFormToken)}
          apiServer={apiServer}
          {...rest}
        />)
        break
      case 'G':
        row = (<PPlanUsersAll
          field={field}
          dispatch={dispatch}
          onChange={onUpdate}
          publicFormToken={publicFormToken}
          push={push}
          {...rest}
        />)
        break
      case 'P':
        row = <PPlanUsersLimited field={field} onChange={onUpdate} {...rest} />
        break
      case 'F':
        row = (<Link
          type='file'
          url={field.MetaData}
          field={field}
          label={field.FieldName}
          onBlur={this.updateAndClear.bind(this)}
          onChange={this.tempStoreField.bind(this)}
          isPublic={!!(publicFormToken)}
          apiServer={apiServer}
          {...rest}
        />)
        break
      case 'S':
        row = (<Link
          type='url'
          url={field.MetaData}
          field={field}
          label={field.FieldName}
          onBlur={this.updateAndClear.bind(this)}
          onChange={this.tempStoreField.bind(this)}
          apiServer={apiServer}
          {...rest}
        />)
        break
      case 'T':
        row = <Static field={field} {...rest} />
        break
      case 'X':
      case 'CWCL':
      case 'CWCSL':
      case 'CWCNL':
      case 'CWML':
      case 'CWOL':
      case 'CWTL':
      case 'CWAG':
      case 'CWAL':
      case 'CWOCL':
      case 'CWOTL':
      case 'CWORL':
      case 'CWOSL':
      case 'CWOPS':
      case 'CWOPP':
      case 'CWOPPB':
      case 'CWSB':
      case 'CWSS':
      case 'CWST':
      case 'CWSST':
      case 'CWSI':
      case 'CWAS':
      case 'CWAT':
      case 'CWCS':
      case 'CWCT':
      case 'CWCNTL':
      case 'CWT':
        row = (<ExternalSource
          field={field}
          dispatch={dispatch}
          onSelect={onUpdate}
          onChange={this.tempStoreField.bind(this)}
          publicFormToken={publicFormToken}
          filter={filter}
          keyProperty='ExternalID'
          {...rest}
        />)
        break
      case 'INSTANCE':
        row = (<ExternalSource
          field={field}
          dispatch={dispatch}
          onSelect={onUpdate}
          onChange={this.tempStoreField.bind(this)}
          publicFormToken={publicFormToken}
          filter={filter}
          keyProperty='ID'
          hintText={language.translate('application.search')}
          clickableLink={(field.ValueID) ? () => {
            let query = { ...this.context.location.query }

            query.pid = field.ValueID
            query.ptype = 'pifields'

            push({
              pathname: `/process-visual-progress/${field.ValueID}`,
              query
            })
          } : null}
          actionButton={actionButton}
          externalSourceEndpoint={externalSourceEndpoint}
          {...rest}
        />)
        break
      case 'INSTCHLS':
        row = <ExternalSourceChecklist
          field={field}
          dispatch={dispatch}
          onChange={onUpdate}
          publicFormToken={publicFormToken}
          filter={filter}
          actionButton={actionButton}
          externalSourceEndpoint={externalSourceEndpoint}
          afterDataLoad={afterDataLoad}
          {...rest} />
        break
      case 'INSTLINK':
        row = <InternalLink
          field={field}
          onClick={() => {
            let queryStrings = field.ValueID.split('?')[1]
            let queryStringArray = queryStrings.split('&')
            let query = {}

            queryStringArray.map((string) => {
              let [key, value] = string.split('=')

              // IF THE SEARCH VALUE HAS A FIELD ID IN IT, WE NEED TO DIG IT OUT AND GRAB THE VALUE OF THE REFERENCED FIELD
              let searchObject = JSON.parse(Base64.decode(value))

              if (searchObject.value.substr(0, 5) === '[fld_') {
                let parentFieldId = searchObject.value.split('_')[1]
                let parentField = fieldList.filter((field) => (field.ProcessTemplateFieldID === parentFieldId))[0]

                searchObject.displayValue = parentField.Value
                searchObject.value = parentField.ValueID
                value = Base64.encodeURI(JSON.stringify(searchObject))
              }

              // LOAD THE "SEARCH" QUERY PARAM, IF IT ISN'T AN ARRAY YET, MAKE IT ONE
              if (query[key]) {
                if (!Array.isArray(query[key])) {
                  query[key] = [query[key]]
                  query[key].push(value)
                } else {
                  query[key].push(value)
                }
              } else {
                query[key] = value
              }
            })

            push({
              pathname: `/process-instances-grid/${field.ProcessInstanceLookupTemplateID}`,
              query: objectAssign({},
                this.context.location.query,
                query)
            })
          }}
          {...rest} />
        break
      case 'UL':
        row = (<UserLookup
          field={field}
          dispatch={dispatch}
          onSelect={onUpdate}
          onChange={this.tempStoreField.bind(this)}
          publicFormToken={publicFormToken}
          filter={filter}
          actionButton={actionButton}
          {...rest}
        />)
        break
      case 'TIME':
        row = <Time field={field} onChange={onUpdate} {...rest} />
        break
      case 'LOC':
        row = <Location field={field} onChange={onUpdate} dispatch={dispatch} {...rest} />
        break
      case 'SIG':
        row = (<Signature field={field} onChange={onUpdate} dispatch={dispatch} apiServer={apiServer}
                          isPublic={!!(publicFormToken)} {...rest} />)
        break
      case 'ADDR':
        row = (<Address
          field={field}
          onBlur={this.updateAndClear.bind(this)}
          onChange={this.tempStoreField.bind(this)}
          {...rest}
        />)
        break
      case 'DIRCT':
        row = (<Address
          field={field}
          directions
          onBlur={this.updateAndClear.bind(this)}
          onChange={this.tempStoreField.bind(this)}
          {...rest}
        />)
        break
      case 'GRID':
        row = <Grid field={field} onChange={onUpdate} {...rest} />
        break

      default:
        row = (<Default field={field} {...rest} />)
    }

    return (row)
  }
}

CustomField.propTypes = {
  field: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  autoFocus: PropTypes.bool,
  onUpdate: PropTypes.func,
  push: PropTypes.func,
  actionButton: PropTypes.func,
  afterDataLoad: PropTypes.func,
  publicFormToken: PropTypes.string,
  wrapperProps: PropTypes.object,
  apiServer: PropTypes.string,
  externalSourceEndpoint: PropTypes.string,
  filter: PropTypes.string,
  fieldList: PropTypes.array
}

CustomField.contextTypes = {
  language: PropTypes.object,
  location: PropTypes.object
}

const mapStateToProps = state => ({
  apiServer: state.application.apiServer
})

const mapDispatchToProps = dispatch => ({
  dispatch,
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomField)
