import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get, post } from '../actions/base'
import { updateTitle } from '../actions/application'
import { Button, Card, CardContent, CardHeader, Checkbox, Divider, FormControlLabel } from '@material-ui/core'
import { ExitToApp } from '@material-ui/icons'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { Form } from 'formsy-react'
import { push } from 'react-router-redux'
import { FormsyText } from 'formsy-material-ui'
import FormHelper from '../businessLogic/formHelper'
import NoDataToShow from '../components/Layout/NoDataToShow'
import Copyright from '../components/Layout/Copyright'
import ReactMarkdown from 'react-markdown'
import theme from '../styles/theme'

const newTheme = JSON.parse(JSON.stringify(theme))
newTheme.palette.primary.main = 'rgb(60, 184, 117)'
const greenButtonTheme = createMuiTheme(newTheme)

class Response extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      account: {},
      task: {},
      checklist: [],
      resources: [],
      token: context.location.query.token || null,
      canSubmit: true,
      isComplete: false
    }
  }

  componentDidMount () {
    const { language } = this.context

    this.getTaskInfo()
    this.props.updateTitle(language.translate('application.submitResponse'))
  }

  getTaskInfo () {
    const { token } = this.state

    this.props.get('public/taskresponse', {
      onSuccess: (response) => {
        this.setState({
          task: response.ProcessInstanceTask,
          checklist: response.ProcessInstanceTaskChecklistItemList,
          resources: response.ProcessTemplateTaskResourceList,
          account: response.Account
        })
      },

      headers: { token },
      onError: (error, body) => {
        // if a process field is required, take user to that task in their task view
        body.ResultList.map((message) => {
          if (message.Code === 412002) { this.goToTaskInApp(body.ProcessInstanceTask.ID) }
        })

        // assume failure is relevant to page so save data to state
        this.setState({
          task: body.ProcessInstanceTask,
          checklist: body.ProcessInstanceTaskChecklistItemList,
          resources: body.ProcessTemplateTaskResourceList,
          account: body.Account
        })
      }
    })
  }

  goToTaskInApp (taskId) {
    const { push, apiServer } = this.props
    const { account } = this.state

    if (account.SEOEnabled && account.SAML_SingleSignOnUrl) { window.open(`${apiServer}/saml/login.aspx?aid=${account.ID}&redirect=/?ptype=task%26pid${taskId}%26task=${taskId}`, '_self') } else {
      push({
        pathname: '/',
        query: {
          ptype: 'task',
          pid: taskId,
          task: taskId
        }
      })
    }
  }

  submitTaskInfo () {
    const { token, task, checklist } = this.state

    this.setState({ canSubmit: false })

    const body = JSON.stringify({
      ProcessInstanceTask: task,
      ProcessInstanceTaskChecklistItemList: checklist
    })

    this.props.post('public/taskresponse', body, {
      onSuccess: (response) => {
        this.setState({ isComplete: true })
      },

      headers: { token },

      onError: (error) => {
        this.setState({ canSubmit: true })
      }
    })
  }

  getResourceLink (resource) {
    const { user } = this.context
    const { task } = this.state
    const { apiServer } = this.props
    let href = ''

    // if the resource is a file, force the user to login if they aren't already in
    // order to view the file. if it is a url, have the link behave as normal.
    if (resource.PrivateFileKey) {
      if (user.isAuthenticated) { href = `${apiServer}/open.aspx?filekey=${resource.PrivateFileKey}&token=${user.token}&email=${encodeURIComponent(user.userName)}&` } else { href = '/' }
    } else if (resource.ResourceURL.substring(0, 4) !== 'http') { href = `http://${resource.ResourceURL}` } else { href = resource.ResourceURL }

    return (
      <a
        href={href}
        target={(resource.PrivateFileKey && !user.isAuthenticated) ? '_self' : '_blank'}
        onClick={(e) => {
          if (resource.PrivateFileKey && !user.isAuthenticated) {
            e.preventDefault()

            this.goToTaskInApp(task.ID)
          }
        }}
      >
        {resource.ResourceDescription} {(resource.PrivateFileKey && !user.isAuthenticated) ? '(login required)' : null}
      </a>)
  }

  render () {
    const { task, checklist, resources, canSubmit, isComplete, account } = this.state
    const { muiTheme, language } = this.context
    const { palette } = muiTheme

    return (
      <div
        style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <div style={{ textAlign: 'center', margin: '30px 0px' }}>
          <img src={account.HeaderLogoWhiteBG_URL} style={{ maxWidth: '300px' }} />
        </div>
        <Form
          style={{ width: '100%' }}
          onValid={() => this.setState({ canSubmit: true })}
          onInvalid={() => this.setState({ canSubmit: false })}
        >
          <Card
            className='card'
            style={{
              maxWidth: '400px',
              margin: 'auto'
            }}
          >
            <CardHeader
              title={language.translate('application.publicTaskResponse')}
              titleTypographyProps={{ style: { fontSize: '20px', color: muiTheme.palette.alternateTextColor } }}
              style={{
                borderBottom: `1px solid ${muiTheme.palette.borderColor}`,
                backgroundColor: muiTheme.palette.accent1Color
              }}
            />
            <CardContent>
              {(isComplete)
                ? <NoDataToShow noDataText={language.translate('application.taskCompleted')} />
                : <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  {/** DISPLAY TASK INSTRUCTIONS **/}
                  {(task.Instructions)
                    ? <div style={{ width: '100%' }}>
                      <strong
                        style={{ paddingRight: '10px' }}
                      >{language.translate('application.instructions')}:</strong>
                      <ReactMarkdown source={task.Instructions} escapeHtml linkTarget='_blank' />
                      <div style={{ margin: '10px 0px 10px 0px' }}>
                        <Divider />
                      </div>
                    </div>
                    : null}

                  {/** DISPLAY TASK CHECKLIST **/}
                  {(checklist && checklist.length)
                    ? <div style={{ width: '100%' }}>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ flex: 1 }}>{language.translate('application.checklist')}</div>
                        <div style={{ flex: 3 }}>
                          {checklist.map((item, index) => (
                            <FormControlLabel
                              key={item.ProcessTemplateTaskChecklistID}
                              control={<Checkbox
                                checked={item.Checked}
                                color='primary'
                                onChange={() => {
                                  checklist[index].Checked = !item.Checked
                                  this.setState({ checklist })
                                }}
                              />}
                              label={item.ItemDescription}
                            />
                          ))}
                        </div>
                      </div>
                      <div style={{ margin: '10px 0px 10px 0px' }}>
                        <Divider />
                      </div>
                    </div>
                    : null
                  }

                  {/** DISPLAY TASK RESOURCES **/}
                  {(resources && resources.length)
                    ? <div style={{ width: '100%' }}>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ flex: 1 }}>{language.translate('application.resources')}</div>
                        <div style={{ flex: 3 }}>
                          {resources.map(resource => (
                            <div key={resource.ID}>
                              {this.getResourceLink(resource)}
                              <br />
                            </div>
                          ))}
                        </div>
                      </div>
                      <div style={{ margin: '10px 0px 10px 0px' }}>
                        <Divider />
                      </div>
                    </div>
                    : null
                  }
                  <FormsyText
                    fullWidth
                    floatingLabelText={`${language.translate('application.notesLabel')}...`}
                    multiLine
                    rows={2}
                    ref='notes'
                    name='task-notes'
                    required={(task.TaskNoteRequired)}
                    defaultValue={FormHelper.decodeHTML(task.Notes)}
                    onBlur={(e) => {
                      task.Notes = e.currentTarget.value
                      this.setState({ task })
                    }}
                    validationErrors={language.messages.validationErrors}
                  />
                  <FormsyText
                    name='time_spent'
                    required={(task.TimeEntryRequired)}
                    validations='isFloat'
                    validationErrors={language.messages.validationErrors}
                    type='number'
                    fullWidth
                    value={task.HoursSpent}
                    onBlur={(e) => {
                      task.HoursSpent = e.currentTarget.value
                      this.setState({ task })
                    }}
                    floatingLabelText={language.translate('application.hoursSpentOnTask')}
                    hintText={language.translate('application.timeSpentHint')}
                    ref='time_spent'
                  />
                  <MuiThemeProvider theme={greenButtonTheme}>
                    <Button
                      disabled={!canSubmit}
                      fullWidth
                      type='submit'
                      variant='contained'
                      color='primary'
                      style={{ margin: '10px 0px', color: palette.alternateTextColor }}
                      onClick={this.submitTaskInfo.bind(this)}
                    >
                      {language.translate('application.submit')}
                    </Button>
                  </MuiThemeProvider>
                  <Button
                    color='primary'
                    variant='contained'
                    style={{ margin: '10px 0px' }}
                    onClick={() => this.goToTaskInApp(task.ID)}
                  >
                    <ExitToApp />
                    Open task in app
                  </Button>
                </div>}
            </CardContent>
          </Card>
        </Form>
        <Copyright />
      </div>
    )
  }
}

Response.propTypes = {
  dispatch: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  updateTitle: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  apiServer: PropTypes.string.isRequired
}

Response.contextTypes = {
  location: PropTypes.object,
  muiTheme: PropTypes.object,
  language: PropTypes.object,
  user: PropTypes.object
}

const mapStateToProps = state => ({
  apiServer: state.application.apiServer
})

const mapDispatchToProps = dispatch => ({
  dispatch,
  get: bindActionCreators(get, dispatch),
  post: bindActionCreators(post, dispatch),
  updateTitle: bindActionCreators(updateTitle, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Response)
