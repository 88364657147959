import PropTypes from 'prop-types'
import React from 'react'
import IconSelector from './IconSelector'
import { Dialog, DialogContent, FormControl, InputLabel, MenuItem, Select, Typography } from '@material-ui/core'

class IconSelectorDialog extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      iconCategorySelected: 'all'
    }
  }

  getIconCategories () {
    return this.context.language.messages.icons.categories
  }

  getIconCategoryMenuItems () {
    const categories = this.getIconCategories()
    const menu = []

    Object.keys(categories).map((value, index) => {
      menu.push(<MenuItem value={value} key={value}>{categories[value]}</MenuItem>)
    })

    return menu
  }

  render () {
    const { open, title, onRequestClose, onSelect, selectedIcon } = this.props
    const { language } = this.context

    return (
      <Dialog
        open={open}
        onClose={onRequestClose}
        style={{ top: '-30px' }}
      >
        <DialogContent style={{ width: '370px' }}>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: '5px'
          }}
          >
            <Typography variant='h6'>{title || language.translate('icons.iconSelector')}</Typography>
            <FormControl>
              <InputLabel style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {language.translate('application.category')}
              </InputLabel>
              <Select
                value={this.state.iconCategorySelected}
                onChange={(e) => {
                  e.preventDefault()
                  this.setState({ iconCategorySelected: e.target.value })
                }}
                autoWidth
                style={{ width: '130px' }}
                name='icon-categories'
                MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
              >
                {this.getIconCategoryMenuItems()}
              </Select>
            </FormControl>
          </div>
          <IconSelector
            selectedIcon={selectedIcon}
            searchText={this.state.iconCategorySelected}
            onSelect={onSelect}
          />
        </DialogContent>
      </Dialog>
    )
  }
}

IconSelectorDialog.propTypes = {
  open: PropTypes.bool,
  selectedIcon: PropTypes.string,
  onSelect: PropTypes.func,
  onRequestClose: PropTypes.func.isRequired,
  title: PropTypes.string
}

IconSelectorDialog.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default IconSelectorDialog
