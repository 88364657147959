import PropTypes from 'prop-types'
import React from 'react'
import { Card, CardContent, CardHeader, CircularProgress } from '@material-ui/core'
import {
  XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar, Cell
} from 'recharts'
import CustomTooltip from './CustomTooltip'
import ColorHelper from '../../businessLogic/colorHelper'
import NoDataToShow from '../Layout/NoDataToShow'
import AreaCard from './AreaCard'

class BarCard extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      isHovering: false,
      animate: true
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.isDataLoaded === false && this.props.isDataLoaded === true) {
      setTimeout(() => {
        this.setState({ animate: false })
      }, 3000)
    }
  }

  trimLabel (label, length) {
    let newLabel = label.substring(0, length)

    if (label.length > length) { newLabel += '...' }

    return newLabel
  }

  render () {
    const { language } = this.context
    const { isHovering, animate } = this.state
    let { title, subtitle, data, isDataLoaded, onClick, limit, yWidth, xType, yType, tooltipPrefix, mainContentWidth, formatXTick } = this.props

    const labelUCFirst = tooltipPrefix.charAt(0).toUpperCase() + tooltipPrefix.substring(1)

    if (limit) { data = data.slice(0, limit) }

    const styles = {
      cardStyles: {
        width: (mainContentWidth < 800) ? '95%' : '45%',
        margin: '10px',
        zIndex: (isHovering) ? 2 : 1,
        overflow: 'inherit'
      }
    }

    return (
      <Card
        style={styles.cardStyles}
        onMouseEnter={() => this.setState({ isHovering: true })}
        onMouseLeave={() => this.setState({ isHovering: false })}
      >
        <CardHeader
          title={title}
          subheader={subtitle}
        />
        <CardContent>
          {(isDataLoaded)
            ? (data.length)
              ? <ResponsiveContainer
                width='100%'
                height={data.length * 60}
              >
                <BarChart
                  data={data}
                  layout='vertical'
                  margin={{ top: 5, right: 40, left: 20, bottom: 5 }}
                >
                  <YAxis
                    width={yWidth || 150}
                    type={yType || 'category'}
                    dataKey='SeriesLabel'
                    tickFormatter={data1 => this.trimLabel(data1, 35)}
                  />
                  <XAxis
                    type={xType || 'number'}
                    tickFormatter={data => ((formatXTick) ? formatXTick(data) : data)}
                  />
                  <CartesianGrid
                    strokeDasharray='3 3'
                  />
                  <Tooltip
                    content={<CustomTooltip labelPrefix={labelUCFirst || language.translate('application.value')} />}
                  />
                  <Bar
                    dataKey='SeriesValue'
                    onClick={(data) => {
                      if (onClick) { onClick(data) }
                    }}
                    isAnimationActive={animate}
                  >
                    {
                      data.map((entry, index) => (
                        <Cell
                          cursor={(onClick) ? 'pointer' : 'default'}
                          fill={ColorHelper.getRandom(index)}
                          key={`cell-${index}`}
                        />
                      ))
                    }
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
              : <NoDataToShow noDataText={language.translate('application.noDataAdjustFilter')} />
            : <CircularProgress className='loader' />}
        </CardContent>
      </Card>
    )
  }
}

BarCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  data: PropTypes.array,
  isDataLoaded: PropTypes.bool,
  onClick: PropTypes.func,
  limit: PropTypes.number,
  yWidth: PropTypes.number,
  yType: PropTypes.string,
  xType: PropTypes.string,
  tooltipPrefix: PropTypes.string,
  mainContentWidth: PropTypes.number,
  formatXTick: PropTypes.func
}

BarCard.defaultProps = {
  tooltipPrefix: ''
}

BarCard.contextTypes = {
  muiTheme: PropTypes.object,
  location: PropTypes.object,
  language: PropTypes.object
}

export default BarCard
