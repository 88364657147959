import PropTypes from 'prop-types'
import React from 'react'
import FormHelper from '../../businessLogic/formHelper'
import { IconButton, Tooltip } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import { get } from '../../actions/base'
import FieldWrapper from './FieldWrapper'
import FormsyAutoComplete from '../Forms/FormsyAutoComplete'
import ContactCardDialog from '../User/ContactCardDialog'

class UserLookup extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      dataList: [],
      isLoaded: false,
      itemClicked: false,
      contactCardOpen: false,
      edit: (!this.props.field.ProcessInstanceID || !this.props.field.Value) // if there is no instance ID, then default to showing the input field
    }
  }

  componentDidMount () {
    const { Value, ValueID } = this.props.field

    if (!this.props.field.ReadOnlyField) {
      if (!Value || Value === ValueID || !ValueID) {
        this.fetchData(Value || '', !!(Value && Value === ValueID))
      }
    }
  }

  componentDidUpdate (prevProps) {
    const { Value, ValueID, ReadOnlyField } = this.props.field

    if (prevProps.field.ReadOnlyField && !ReadOnlyField) {
      this.fetchData(Value || '')
    }

    if (prevProps.filter !== this.props.filter && !ReadOnlyField) {
      this.fetchData(Value || '')
    }
  }

  handleChange (option) {
    const { field, onChange, onSelect } = this.props

    if (typeof option !== 'string') {
      field.ValueID = option.key
      field.Value = option.value
      field.UserID = option.key

      if (onSelect) { onSelect(field) }

      this.setState({ itemClicked: false })
    } else if (this.state.isLoaded && typeof option === 'string') {
      field.ValueID = (option && this.state.dataList.length) ? this.state.dataList[0].ID : ''
      field.Value = (option && this.state.dataList.length) ? this.state.dataList[0].OptionDescription : ''

      if (onSelect) { onSelect(field) }

      this.setState({ itemClicked: false })
    } else {
      setTimeout(() => { this.handleChange(option) }, 300)
    }
  }

  fetchData (text, autoSelectIfPossible = false) {
    const { field, publicFormToken, onSelect, onChange } = this.props
    let { filter } = this.props
    this.setState({ isLoaded: false })

    // change to user user/list endpoint
    let endpoint = (publicFormToken) ? `public/publicform/user/list` : `user/list`
    const headers = (publicFormToken) ? { token: publicFormToken } : {}
    let filterCount = 0

    if (filter) {
      if (filter.charAt(0) === '&' || filter.charAt(0) === '?') {
        filter = filter.slice(1)
      }

      let filterArray = filter.split('&')

      filterArray.map((item, index) => {
        if (item.substr(0, 6) === 'filter') {
          filterCount++
        }
      })
    }

    if (publicFormToken) {
      endpoint += `?text=${text}&${filter}`
    } else {
      if (text) {
        filter += `&filter${filterCount + 1}=FullName(CONTAINS_TEXT)${text}`
      }

      if (filter) {
        endpoint += `?${filter}`
      }
    }

    this.props.dispatch(get(endpoint,
      {
        onSuccess: (response) => {
          let userList = response.UserList || []
          this.setState({
            dataList: userList,
            isLoaded: true
          }, () => {
            if (autoSelectIfPossible && userList.length === 1) {
              field.ValueID = userList[0].ID
              field.Value = userList[0].FullName

              if (onSelect && onChange) {
                onChange(field)
                setTimeout(() => { onSelect(field) }, 200)
              }
            }
          })
        },
        headers
      }
    ))
  }

  render () {
    const { field, wrapperProps, onChange, onSelect } = this.props
    const { dataList, edit } = this.state
    const { muiTheme: { palette }, language } = this.context
    const userOptions = []

    dataList.map((option) => {
      let name = option.FullName
      if (option.Position) {
        name += ` (${option.Position})`
      }
      userOptions.push({ key: option.ID, text: name, value: option.FullName })
    })

    return (
      <FieldWrapper
        label={FormHelper.decodeHTML(field.FieldName)}
        required={field.RequiredField}
        showDivider={(!edit)}
        {...wrapperProps}
      >
        {(!edit || field.ReadOnlyField)
          ? <div
            style={{
              position: 'relative',
              padding: '5px 0px',
              fontSize: '15px',
              display: 'flex',
              flexDirection: 'row'
            }}>
            <div style={{ flex: 5, paddingTop: '15px', width: '80%' }}>
              <a onClick={(e) => {
                this.setState({ contactCardOpen: true })
                e.preventDefault()
                e.stopPropagation()
              }}
                 style={{ cursor: 'pointer' }}>{field.Value}</a>
            </div>
            {(!field.ReadOnlyField)
              ? <div style={{ flex: 1 }}>
                <Tooltip title='Edit'>
                  <IconButton
                    onClick={() => this.setState({ edit: true })}
                  >
                    <Edit nativeColor={palette.accent3Color} />
                  </IconButton>
                </Tooltip>
              </div>
              : null}
          </div>
          : <FormsyAutoComplete
            fullWidth
            name={field.FieldName}
            dataSource={userOptions}
            onNewRequest={this.handleChange.bind(this)}
            onUpdateInput={(text) => {
              this.setState({ isLoaded: false })
              setTimeout(() => {
                var lastCharacterOfSearch = text.substr(text.length - 1)
                var searchText = field.Value || ''

                if (lastCharacterOfSearch === searchText.substr(searchText.length - 1) && text.length === searchText.length) {
                  this.fetchData(text)
                }
              }, 800)

              field.Value = text

              if (text === '') {
                field.ValueID = ''
                
                if (onSelect) {
                  onSelect(field)
                }
              }

              if (onChange) {
                onChange(field)
              }
            }}
            searchText={field.Value}
            onKeyDown={(e) => {
              if (e.key === 'Tab') {
                this.onBlur(e.currentTarget.value)
              }
            }}
            value={(field.ValueID) ? field.ValueID : null}
            required={field.RequiredField}
            validationErrors={this.context.language.messages.validationErrors}
            menuProps={{ onClick: () => this.setState({ itemClicked: true }) }}
          />}
        {(this.state.contactCardOpen)
          ? <ContactCardDialog
            open
            userID={field.ValueID}
            close={() => this.setState({ contactCardOpen: false })}
          />
          : null}
      </FieldWrapper>
    )
  }
}

UserLookup.propTypes = {
  field: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  publicFormToken: PropTypes.string,
  filter: PropTypes.string,
  wrapperProps: PropTypes.object
}

UserLookup.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default UserLookup