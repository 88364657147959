import React from 'react'
import PropTypes from 'prop-types'
import { AutoComplete } from 'material-ui'
import {
  Avatar, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Typography
} from '@material-ui/core'
import { Today } from '@material-ui/icons'
import { Form } from 'formsy-react'
import { FormsyText } from 'formsy-material-ui'
import moment from 'moment'
import GroupUserSelect from '../Forms/GroupUserSelect'
import DateField from '../Forms/DateField'
import DateHelper from '../../businessLogic/dateHelper'
import FormsyAutoComplete from '../Forms/FormsyAutoComplete'

class ImpromptuTaskDialog extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      taskDescription: null,
      assignedToUserId: '0',
      assignedToGroupId: '0',
      dueDate: '',
      startDate: '',
      canSubmit: true,
      taskDueDateDialogOpen: false,
      taskStartDateDialogOpen: false,
      isSubmitting: false,
      itemClicked: false,
      afterTaskId: '',
      taskSearchText: ''
    }
  }

  componentDidMount () {
    if (this.props.defaultTaskValue) {
      let referencedTask = this.props.taskList.filter((task) => (task.ID === this.props.defaultTaskValue))[0]

      this.setState({
        afterTaskId: (referencedTask) ? referencedTask.ID : '',
        taskSearchText: (referencedTask) ? referencedTask.TaskText : ``
      })
    }
  }

  componentDidUpdate (prevProps, prevState, prevContext) {
    if (prevProps.defaultTaskValue !== this.props.defaultTaskValue) {
      let referencedTask = this.props.taskList.filter((task) => (task.ID === this.props.defaultTaskValue))[0]

      this.setState({
        afterTaskId: this.props.defaultTaskValue || '',
        taskSearchText: (referencedTask) ? referencedTask.TaskText : `-- ${this.context.language.translate('application.assignNow')} --`
      })
    }
  }

  submitStartDate (date) {
    const { user } = this.context

    date = (date) ? DateHelper.formatForAPI(date, `${user.dateFormat}THH:mm:ss`, false) : ''

    this.setState({
      startDate: date,
      taskStartDateDialogOpen: false
    })
  }

  submitDueDate (date) {
    const { user } = this.context

    date = (date) ? DateHelper.formatForAPI(date, `${user.dateFormat}THH:mm:ss`, false) : ''

    this.setState({
      dueDate: date,
      taskDueDateDialogOpen: false
    })
  }

  render () {
    const { language, muiTheme: { palette }, user } = this.context
    const { onSubmit, onCancel, taskList } = this.props
    const {
      taskDescription, assignedToUserId, assignedToGroupId, dueDate, startDate, canSubmit, taskDueDateDialogOpen,
      taskStartDateDialogOpen, isSubmitting, afterTaskId, taskSearchText
    } = this.state

    const taskOptions = [{ key: '', text: `-- ${language.translate('application.assignNow')} --`, value: '' }]

    taskList.map((task) => {
      taskOptions.push(
        { key: task.ID, text: task.TaskText, value: task.ID }
      )
    })

    return (
      <Dialog
        open
        onClose={() => this.setState({ showImpromptuTaskModal: false })}
      >
        <DialogTitle
          style={{
            backgroundColor: palette.headerBackgroundColor
          }}
          disableTypography
        >
          <Typography
            variant='h6'
            style={{ color: palette.alternateTextColor }}>
            {language.translate('application.addImpromptuTask')}
          </Typography>
        </DialogTitle>
        <DialogContent style={{ width: '350px' }}>
          <Form
            onValid={() => this.setState({ canSubmit: true })}
            onInvalid={() => this.setState({ canSubmit: false })}>
            <Chip
              label={(startDate)
                ? `${language.translate('application.starts')} ${moment(startDate).calendar(null, {
                  sameDay (now) {
                    return `[${this.fromNow()}]`
                  },
                  nextDay: `[${language.translate('application.tomorrow').lcFirst()}]`,
                  nextWeek: 'dddd',
                  lastDay: `[${language.translate('application.yesterday').lcFirst()}]`,
                  lastWeek (now) {
                    const day = this.format('dddd')
                    return `[${language.translate('application.lastWeekday', [day]).lcFirst()}]`
                  },
                  sameElse (now) {
                    return `[${this.fromNow()}]`
                  }
                })}`
                : language.translate('application.noStartDate')}
              onClick={() => this.setState({ taskStartDateDialogOpen: true })}
              onDelete={(startDate) ? () => this.setState({ startDate: '' }) : null}
              style={{ margin: '15px 0px' }}
              avatar={<Avatar><Today nativeColor={palette.canvasColor} /></Avatar>}
            />
            <Chip
              label={(dueDate)
                ? `${language.translate('application.due')} ${moment(dueDate).calendar(null, {
                  sameDay (now) {
                    return `[${this.fromNow()}]`
                  },
                  nextDay: `[${language.translate('application.tomorrow').lcFirst()}]`,
                  nextWeek: 'dddd',
                  lastDay: `[${language.translate('application.yesterday').lcFirst()}]`,
                  lastWeek (now) {
                    const day = this.format('dddd')
                    return `[${language.translate('application.lastWeekday', [day]).lcFirst()}]`
                  },
                  sameElse (now) {
                    return `[${this.fromNow()}]`
                  }
                })}`
                : language.translate('application.noDueDate')}
              onClick={() => this.setState({ taskDueDateDialogOpen: true })}
              onDelete={(dueDate) ? () => this.setState({ dueDate: '' }) : null}
              avatar={<Avatar><Today nativeColor={palette.canvasColor} /></Avatar>}
            />
            <FormsyText
              name='description'
              required
              fullWidth
              multiLine
              rows={3}
              value={taskDescription}
              floatingLabelText={language.translate('application.taskTitle')}
              onChange={(event) => {
                this.setState({ taskDescription: event.currentTarget.value })
              }}
              validationErrors={language.messages.validationErrors}
            />
            <GroupUserSelect
              name={language.translate('application.assignedTo')}
              dispatch={this.props.dispatch}
              allowAllUsers
              required
              groupValue={assignedToGroupId}
              allUsersLabel={language.translate('application.anyPersonInGroup')}
              userValue={assignedToUserId}
              onGroupChange={(groupId) => {
                this.setState({ assignedToGroupId: groupId })
              }}
              onUserChange={(userId) => {
                this.setState({ assignedToUserId: userId })
              }}
              userListEndpoint={(groupId) => (`usergroup/${groupId}/user/list/fortaskassignments`)}
            />
            <FormsyAutoComplete
              fullWidth
              name='task'
              floatingLabelText={language.translate('application.taskToBeCompletedFirst')}
              dataSource={taskOptions}
              searchText={taskSearchText}
              onNewRequest={(option) => {
                this.setState({ afterTaskId: option.key, taskSearchText: option.text })
              }}
              onUpdateInput={(text) => {
                this.setState({
                  taskSearchText: text,
                  afterTaskId: '',
                  itemClicked: false
                })
              }}
              onBlur={() => {
                const { itemClicked } = this.state

                if (itemClicked) {
                  return
                }

                const { taskSearchText } = this.state

                let found = taskList.filter((task) => (task.TaskText === taskSearchText))[0]

                if (found) {
                  this.setState({
                    taskSearchText: found.TaskText,
                    afterTaskId: found.ID
                  })
                } else {
                  this.setState({
                    taskSearchText: `-- ${language.translate('application.assignNow')} --`,
                    afterTaskId: ''
                  })
                }
              }}
              onFocus={(e) => { e.target.select() }}
              value={afterTaskId}
              filter={AutoComplete.caseInsensitiveFilter}
              openOnFocus
              maxSearchResults={10}
              menuProps={{ onClick: () => this.setState({ itemClicked: true }) }}
            />
          </Form>
          {(taskStartDateDialogOpen)
            ? <Form>
              <DateField
                name='start_date'
                required
                hideInput
                timePicker
                onChange={this.submitStartDate.bind(this)}
                defaultDate={moment().startOf('day')}
                ref='start_date'
                formatDate={date => moment(date).format((user.dateFormat) ? user.dateFormat : 'YYYY-MM-DD')}
                label={language.translate('application.taskStartDate')}
                onDismiss={() => { this.setState({ taskStartDateDialogOpen: false }) }}
              />
            </Form>
            : null}

          {(taskDueDateDialogOpen)
            ? <Form>
              <DateField
                name='due_date'
                required
                hideInput
                timePicker
                onChange={this.submitDueDate.bind(this)}
                defaultDate={moment().endOf('day')}
                ref='due_date'
                formatDate={date => moment(date).format((user.dateFormat) ? user.dateFormat : 'YYYY-MM-DD')}
                label={language.translate('application.taskDueDate')}
                onDismiss={() => { this.setState({ taskDueDateDialogOpen: false }) }}
              />
            </Form>
            : null}
        </DialogContent>
        <DialogActions>
          <Button
            key='submit'
            disabled={(!canSubmit || isSubmitting || !assignedToGroupId || assignedToGroupId === '0')}
            color='primary'
            onClick={() => {
              this.setState({ isSubmitting: true })
              onSubmit(
                {
                  TaskText_Display: taskDescription,
                  AssignedTo_UserGroup_ID: assignedToGroupId,
                  AssignedTo_User_ID: assignedToUserId,
                  DueDate_Local: dueDate,
                  StartDate_Local: startDate
                },
                afterTaskId,
                () => {
                  this.setState({ isSubmitting: false })
                })
            }}
          >
            {(isSubmitting) ? <CircularProgress size={25} /> : language.translate('application.submit')}
          </Button>
          <Button
            key='cancel'
            color='primary'
            onClick={onCancel}
          >
            {language.translate('application.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

ImpromptuTaskDialog.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  dispatch: PropTypes.func,
  taskList: PropTypes.array,
  defaultTaskValue: PropTypes.string
}

ImpromptuTaskDialog.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object,
  user: PropTypes.object
}

export default ImpromptuTaskDialog
