import PropTypes from 'prop-types'
import React from 'react'
import Bowser from 'bowser'

const browser = Bowser.getParser(window.navigator.userAgent)

class BrowserCheck extends React.Component {
  constructor (props) {
    super(props)

    this.state = {}
  }

  render () {
    const { palette } = this.context.muiTheme
    const { language } = this.context

    let isValidBrowser = browser.satisfies({
      chrome: '>=40',
      safari: '>=9',
      'Microsoft Edge': '>=14',
      'Internet Explorer': '>=11'
    }, true)

    return (
      (!isValidBrowser && document.location.host !== 'mobile.processplan.com')
        ? <div style={{
          width: '100%',
          textAlign: 'center'
        }}
        >
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              color: palette.alternateTextColor,
              padding: '5px',
              backgroundColor: palette.errorColor
            }}
          >
            {language.translate('application.incompatibleBrowser')}
          </div>
        </div>
        : null
    )
  }
}

BrowserCheck.propTypes = {}

BrowserCheck.contextTypes = {
  muiTheme: PropTypes.object,
  language: PropTypes.object
}

export default BrowserCheck
