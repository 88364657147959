import PropTypes from 'prop-types'
import React from 'react'
import FormHelper from '~/businessLogic/formHelper'
import {
  Button, Divider, FormControl, IconButton, InputLabel, List, ListItem, ListItemSecondaryAction, ListItemText, Menu,
  MenuItem, Select
} from '@material-ui/core'
import { Add, MoreVert } from '@material-ui/icons'
import DeleteMenuItem from '../Layout/DeleteMenuItem'

class ProcessVisibility extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      selectedGroupID: null,
      selectedTemplateID: null,
      menuAnchor: null
    }
  }

  handleDelete (index) {
    const list = this.props.processVisibilityList

    this.props.onDelete(list[index])
  }

  handleSave () {
    const list = this.props.processVisibilityList.slice(0)

    const item = {
      UserID: this.props.userId
    }

    if (this.state.selectedTemplateID) { item.ProcessTemplateID = this.state.selectedTemplateID } else { item.ProcessGroupID = this.state.selectedGroupID }

    list.push(item)

    this.props.onSaveList(list)

    this.setState({
      selectedGroupID: null,
      selectedTemplateID: null
    })
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const { processVisibilityList, groups, templates, onGroupChange } = this.props
    const { selectedGroupID, selectedTemplateID, menuAnchor } = this.state

    const groupOptions = []
    const templateOptions = []

    if (groups) {
      groups.map((option) => {
        groupOptions.push(<MenuItem value={option.ID} key={option.ID}>{option.GroupName}</MenuItem>)
      })
    }

    if (templates) {
      if (selectedGroupID) {
        templateOptions.push(<MenuItem value={0} key={0}>{language.translate('application.all')}</MenuItem>)
      }

      templates.map((option) => {
        templateOptions.push(
          <MenuItem value={option.ID} key={option.ID}>{option.Title}</MenuItem>)
      })
    }

    return (
      <List style={{ padding: '10px 20px', backgroundColor: palette.accent2Color }}>
        {
          processVisibilityList.map((item, index) => (
            React.Children.toArray([
              <ListItem
                key={item.ID || index}
              >
                <ListItemText
                  primary={FormHelper.decodeHTML((item.ProcessGroupName) ? item.ProcessGroupName : item.ProcessTemplateTitle)}
                  secondary={(item.ProcessGroupName) ? language.translate('application.group') : language.translate('application.template')} />
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={(e) => { this.setState({ menuAnchor: e.currentTarget }) }}><MoreVert /></IconButton>
                  <Menu
                    anchorEl={menuAnchor}
                    open={Boolean(menuAnchor)}
                    onClose={() => { this.setState({ menuAnchor: null }) }}
                    getContentAnchorEl={null}
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  >
                    <DeleteMenuItem
                      onDelete={() => {
                        this.setState({ menuAnchor: null })
                        this.handleDelete(index)
                      }}
                    />
                  </Menu>
                </ListItemSecondaryAction>
              </ListItem>,
              (index < processVisibilityList.length - 1) ? <Divider key={'divider' + index} /> : null
            ])

          ))
        }
        <ListItem
          style={{ paddingTop: '0px', border: `1px solid ${palette.borderColor}` }}
        >
          <ListItemText primary={
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              paddingTop: '10px'
            }}
            >
              <div>{language.translate('application.addNewTemplateGroupRestriction')}</div>
              <FormControl fullWidth style={{ margin: '5px' }}>
                <InputLabel style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {language.translate('application.group')}
                </InputLabel>
                <Select
                  MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
                  value={selectedGroupID || ''}
                  name='group'
                  onChange={(e) => {
                    let groupId = e.target.value
                    this.setState({
                      selectedGroupID: groupId,
                      selectedTemplateID: 0
                    })
                    onGroupChange(groupId)
                  }}
                >
                  {groupOptions}
                </Select>
              </FormControl>
              <FormControl fullWidth style={{ margin: '5px' }}>
                <InputLabel style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {language.translate('application.template')}
                </InputLabel>
                <Select
                  MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
                  value={selectedTemplateID || ''}
                  name='template'
                  onChange={(e) => {
                    this.setState({ selectedTemplateID: e.target.value })
                  }}
                >
                  {templateOptions}
                </Select>
              </FormControl>
              <Button
                onClick={this.handleSave.bind(this)}
                disabled={(!selectedGroupID && !selectedTemplateID)}
                color='primary'
                style={{ marginTop: '10px' }}
                variant='contained'
              >
                <Add />
                {language.translate('application.add')}
              </Button>
            </div>
          } />
        </ListItem>
      </List>
    )
  }
}

ProcessVisibility.propTypes = {
  processVisibilityList: PropTypes.array,
  userId: PropTypes.string.isRequired,
  onSaveList: PropTypes.func,
  onDelete: PropTypes.func,
  groups: PropTypes.array,
  templates: PropTypes.array,
  onGroupChange: PropTypes.func
}

ProcessVisibility.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default ProcessVisibility
