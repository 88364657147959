import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get, post } from '~/actions/base'
import FormHelper from '~/businessLogic/formHelper'
import { Form } from 'formsy-react'
import {
  Button, Card, Dialog, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText,
  Menu, MenuItem, Switch, TextField, Typography
} from '@material-ui/core'
import { Add, MoreVert } from '@material-ui/icons'
import EditAction from './EditAction'
import RightPanelContent from '../../Layout/RightPanelContent'
import { showUndoMessage } from '~/actions/snackbar'
import Zapier from './Zapier'
import DeleteMenuItem from '../../Layout/DeleteMenuItem'
import HelpIcon from '../../Layout/HelpIcon'

class AutomatedActionList extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      isLoaded: false,
      actions: [],
      canSubmit: true,
      editAction: false,
      selectedActionID: null,
      integrationList: null,
      integrationListLoaded: false,
      triggerList: null,
      triggerListLoaded: false,
      templateTaskList: null,
      templateTaskListLoaded: false,
      templateTaskResponseList: null,
      templateFieldList: null,
      templatePublicFormList: null,
      templatePublicFormListLoaded: false,
      conditionTypeList: [],
      conditionTypesLoaded: false,
      showZapierDialog: false,
      userFieldList: [],
      userFieldListLoaded: false,
      filter: '',
      menuAnchor: null,
      menuAnchorIndex: null
    }
  }

  componentDidMount () {
    this.fetchProcessTemplateAutomatedActions()
    this.fetchIntegrationList()
    this.fetchTriggerList()
    this.fetchTemplateTasks()
    this.fetchProcessFields()
    this.fetchPublicForms()
    this.fetchConditionTypes()
    this.fetchUserFieldList()
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (this.state.actions.length !== nextState.actions.length) { return true }

    if (this.state.isLoaded !== nextState.isLoaded) { return true }

    if (this.state.editAction !== nextState.editAction) { return true }

    if (this.state.templateTaskResponseList !== nextState.templateTaskResponseList) { return true }

    if (this.state.externalDataSourceList !== nextState.externalDataSourceList) { return true }

    return true
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.taskId !== this.props.taskId || prevProps.responseId !== this.props.responseId) {
      this.fetchProcessTemplateAutomatedActions()
      this.setState({
        editAction: false,
        selectedActionID: null
      })
    }
  }

  enableButton () {
    this.setState({ canSubmit: true })
  }

  disableButton () {
    this.setState({ canSubmit: false })
  }

  fetchProcessTemplateAutomatedActions () {
    const { templateId, taskId, responseId } = this.props
    let endpoint = ''

    if (taskId) {
      endpoint = `processtemplate/${templateId}/task/${taskId}/automatedaction/list`
    } else if (responseId) {
      if (responseId === 'process_started') {
        endpoint = `processtemplate/${templateId}/automatedaction/list?filter1=ActionTrigger_Trigger(EQUALTO_TEXT)Process Started`
      } else {
        endpoint = `processtemplate/${templateId}/response/${responseId}/automatedaction/list`
      }
    } else {
      endpoint = `processtemplate/${templateId}/automatedaction/list`
    }

    this.props.get(endpoint, {
      onSuccess: (response) => {
        this.setState({
          isLoaded: true,
          actions: response.ProcessTemplateAutomatedActionList
        })
      }
    })
  }

  generateNewAction () {
    const { language } = this.context

    const newItem = {
      Action_Purpose: language.translate('application.newAction'),
      ProcessTemplateID: this.props.templateId,
      Action_Active: false
    }

    const newActionList = this.state.actions.concat(newItem)

    this.setState({
      actions: newActionList,
      editAction: true,
      selectedActionID: null
    })
  }

  removeAction (ID) {
    // Check to see if the action has been created on the API yet and delete accordingly
    const actions = this.state.actions.slice()
    const { language } = this.context

    let actionIndexToRemove = null
    actions.some((action, index) => {
      if (action.ID === ID) {
        actionIndexToRemove = index
        return true
      }
    })

    const body = JSON.stringify({})

    this.props.post(
      `processtemplate/${this.props.templateId}/automatedaction/${actions[actionIndexToRemove].ID}/delete`,
      body,
      {
        onSuccess: (response) => {
          this.fetchProcessTemplateAutomatedActions()

          this.props.showUndoMessage(
            language.translate('application.automatedActionRemoved'),
            () => {
              this.props.post(
                `processtemplate/${this.props.templateId}/automatedaction/${actions[actionIndexToRemove].ID}/delete/undo`,
                body,
                {
                  onSuccess: (response) => {
                    this.fetchProcessTemplateAutomatedActions()
                  }
                }
              )
            }
          )
        }
      }
    )
  }

  removeUnsavedAction () {
    const { actions } = this.state
    let indexToRemove = null

    actions.map((action, index) => {
      if (!action.ID) { indexToRemove = index }
    })

    actions.splice(indexToRemove, 1)
    this.setState({ actions })
  }

  copyAction (id) {
    const body = JSON.stringify({})

    this.props.post(
      `processtemplate/${this.props.templateId}/automatedaction/${id}/copy`,
      body,
      {
        onSuccess: (response) => {
          this.fetchProcessTemplateAutomatedActions()
        }
      }
    )
  }

  saveAction (editedAction, callback) {
    const { actions } = this.state
    let actionIndex = 0

    if (editedAction.ID) {
      // update that action in the state array
      actions.map((action, index) => {
        if (editedAction.ID === action.ID) { actionIndex = index }
      })
    } else {
      // update action on state array that has no ID
      actions.map((action, index) => {
        if (!action.ID) { actionIndex = index }
      })
    }
    actions[actionIndex] = editedAction

    this.setState({ actions })

    if (!editedAction.ActionTrigger_Trigger) {
      if (callback) { callback() }
      return
    }

    // make sure they selected an integration action type
    if (!editedAction.IntegrationActionTypeID && editedAction.ID) {
      this.setState({ actions }, () => {
        if (callback) { callback() }
      })
    } else {
      const body = JSON.stringify({
        ProcessTemplateAutomatedAction: editedAction
      })

      const endpoint = (editedAction.ID) ? `/automatedaction/${editedAction.ID}` : '/automatedaction'

      this.props.post(`processtemplate/${this.props.templateId}${endpoint}`, body, {
        onSuccess: (response) => {
          actions[actionIndex] = response.ProcessTemplateAutomatedAction
          this.setState({
            actions,
            selectedActionID: response.ProcessTemplateAutomatedAction.ID
          }, () => {
            if (callback) { callback() }
          })
        },
        onError: (error, response) => {
          if (response.ProcessTemplateAutomatedAction) {
            actions[actionIndex] = response.ProcessTemplateAutomatedAction

            this.setState({
              actions,
              selectedActionID: response.ProcessTemplateAutomatedAction.ID
            })
          }
        }
      })
    }
  }

  fetchIntegrationList () {
    this.props.get('integration/list', {
      onSuccess: (response) => {
        this.setState({
          integrationList: response.IntegrationList,
          integrationListLoaded: true
        })
      }
    })
  }

  fetchUserFieldList () {
    this.props.get('user/field/list', {
      onSuccess: (response) => {
        this.setState({
          userFieldList: response.UserFieldList,
          userFieldListLoaded: true
        })
      }
    })
  }

  fetchTriggerList () {
    this.props.get('processtemplateautomatedactiontriggers/list', {
      onSuccess: (response) => {
        this.setState({
          triggerList: response.ProcessTemplateAutomatedActionTriggerList,
          triggerListLoaded: true
        })
      }
    })
  }

  fetchTemplateTasks (id, callback) {
    this.props.get(`processtemplate/${id || this.props.templateId}/task/list`, {
      onSuccess: (response) => {
        if (callback) {
          callback(response.ProcessTemplateTaskList)
        } else {
          this.setState({
            templateTaskList: response.ProcessTemplateTaskList,
            templateTaskListLoaded: true
          })
        }
      }
    })
  }

  fetchTaskResponses (templateId, taskId, callback) {
    this.props.get(`processtemplate/${templateId}/task/${taskId}/response/list`, {
      onSuccess: (response) => {
        this.setState({
          templateTaskResponseList: response.ProcessTemplateTaskResponseList
        }, () => {
          if (callback) { callback(response.ProcessTemplateTaskResponseList) }
        })
      }
    })
  }

  fetchProcessFields () {
    this.props.get(`processtemplate/${this.props.templateId}/field/list/withoptions`, {
      onSuccess: (response) => {
        this.setState({
          templateFieldList: response.ProcessTemplateFieldList
        })
      }
    })
  }

  fetchRelatedTemplates (callback) {
    this.props.get(`processtemplate/list/relatedto/template/${this.props.templateId}`, {
      onSuccess: (response) => {
        callback(response.ProcessTemplateList)
      }
    })
  }

  fetchPublicForms () {
    this.props.get(`processtemplate/${this.props.templateId}/publicform/list`, {
      onSuccess: (response) => {
        this.setState({
          templatePublicFormList: response.ProcessTemplatePublicFormList,
          templatePublicFormListLoaded: true
        })
      }
    })
  }

  fetchConditionTypes () {
    this.props.get('integrationconditiontype/list', {
      onSuccess: (response) => {
        this.setState({
          conditionTypeList: response.IntegrationConditionTypeList,
          conditionTypesLoaded: true
        })
      }
    })
  }

  render () {
    const palette = this.context.muiTheme.palette
    const {
      editAction, actions, selectedActionID, integrationList, triggerList, triggerListLoaded, templateTaskList, templateTaskListLoaded,
      templateTaskResponseList, templateFieldList, templatePublicFormList, templatePublicFormListLoaded, conditionTypeList,
      userFieldList, filter, menuAnchor, menuAnchorIndex
    } = this.state
    const { get, post, dispatch, showUndoMessage, screenHeight } = this.props
    const { language } = this.context

    const filteredActions = this.state.actions.filter((action) => {
      return (!filter || action.Action_Purpose.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
    })

    return (
      (editAction)
        ? <EditAction
          onSave={this.saveAction.bind(this)}
          onClose={() => {
            if (!selectedActionID) {
              this.removeUnsavedAction()
            }

            this.setState({
              editAction: false,
              selectedActionID: null
            })
          }}
          action={actions.filter((action) => {
            if (action.ID === selectedActionID) { return true }
            return !selectedActionID && !action.ID
          })[0]}
          integrations={integrationList}
          triggers={triggerList}
          templateTasks={templateTaskList}
          templateFields={templateFieldList}
          userFieldList={userFieldList}
          publicForms={templatePublicFormList}
          onRequestTaskResponses={this.fetchTaskResponses.bind(this)}
          getTasks={this.fetchTemplateTasks.bind(this)}
          responses={templateTaskResponseList}
          conditionTypes={conditionTypeList}
          dispatch={dispatch}
          onUpdate={(updatedAction) => {
            let newActions = actions.map((action) => {
              if (action.ID === selectedActionID) {
                return (updatedAction)
              }

              return (action)
            })

            this.setState({ actions: newActions })
          }}
          get={get}
          post={post}
          getRelatedTemplates={this.fetchRelatedTemplates.bind(this)}
          showUndoMessage={showUndoMessage}
          showZapierDialog={() => this.setState({ showZapierDialog: true })}
          screenHeight={screenHeight}
        />
        : <RightPanelContent
          closePanel={() => this.props.close()}
          title={language.translate('application.automatedActions')}
          leftToolbarAvatar={<HelpIcon helpUrl={'introducing-automated-actions'} />}
        >
          <Form onValid={this.enableButton.bind(this)} onInvalid={this.disableButton.bind(this)}>
            <div style={{ padding: '15px' }}>
              {(actions.length > 10)
                ? <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                  <div
                    style={{
                      width: '90%',
                      padding: '10px',
                      backgroundColor: palette.canvasColor,
                      borderRadius: '10px',
                      border: `1px solid ${palette.borderColor}`
                    }}>
                    <TextField
                      name='actionFilter'
                      style={{ width: '100%' }}
                      placeholder={language.translate('application.filterByActionTitle')}
                      value={filter}
                      onChange={(event) => {
                        this.setState({ filter: event.currentTarget.value })
                      }}
                    />
                  </div>
                </div>
                : null}
              <Card className='card'>
                {(!filteredActions.length)
                  ? <div style={{ padding: '10px' }}>{language.translate('application.noAutomatedActions')}</div>
                  : <div style={{ marginTop: '10px' }}>
                    <List>
                      {filteredActions.map((action, index) => (
                        <ListItem key={action.ID || index} style={{ paddingLeft: '10px' }}>
                          <Switch
                            color='primary'
                            checked={action.Action_Active}
                            onChange={(e, isChecked) => {
                              action.Action_Active = !action.Action_Active
                              this.saveAction(action)
                            }}
                          />
                          <ListItemText
                            primary={FormHelper.decodeHTML(action.Action_Purpose)}
                            secondary={`${action.IntegrationActionType_Description} ${language.translate('application.when').lcFirst()} ${action.ActionTrigger_Trigger}`}
                          />
                          <ListItemSecondaryAction>
                            <IconButton
                              onClick={(e) => {
                                this.setState({
                                  menuAnchor: e.currentTarget,
                                  menuAnchorIndex: index
                                })
                              }}><MoreVert /></IconButton>
                            <Menu
                              anchorEl={menuAnchor}
                              open={Boolean(menuAnchor) && menuAnchorIndex === index}
                              onClose={() => { this.setState({ menuAnchor: null, menuAnchorIndex: null }) }}
                              getContentAnchorEl={null}
                              anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            >
                              <MenuItem onClick={() => {
                                this.setState({
                                  editAction: true,
                                  selectedActionID: action.ID,
                                  menuAnchor: null,
                                  menuAnchorIndex: null
                                }, () => {
                                  if (action.ActionTrigger_Trigger === 'Task Closed' && action.ProcessTemplateTaskID) { this.fetchTaskResponses(this.props.templateId, action.ProcessTemplateTaskID) }
                                })
                              }}
                              >{language.translate('application.edit')}</MenuItem>
                              <MenuItem
                                onClick={() => {
                                  this.setState({ menuAnchor: null, menuAnchorIndex: null })
                                  this.copyAction(action.ID)
                                }}
                              >{language.translate('application.copy')}</MenuItem>
                              <DeleteMenuItem
                                onDelete={() => {
                                  this.setState({ menuAnchor: null, menuAnchorIndex: null })
                                  this.removeAction(action.ID)
                                }}
                              />
                            </Menu>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </List>
                  </div>}
              </Card>
              <Button
                onClick={this.generateNewAction.bind(this)}
                color='primary'
                variant='contained'
                disabled={(!triggerListLoaded || !templateTaskListLoaded || !templatePublicFormListLoaded)}
              >
                <Add />
                {language.translate('application.addAction')}
              </Button>
            </div>
          </Form>
          <Dialog
            style={{ paddingTop: '0px' }}
            open={this.state.showZapierDialog}
            onClose={() => this.setState({ showZapierDialog: false })}
          >
            <DialogTitle
              style={{
                backgroundColor: palette.headerBackgroundColor
              }}
              disableTypography
            >
              <Typography
                variant='h6'
                style={{ color: palette.alternateTextColor }}>
                {language.translate('application.zapier')}
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Zapier
                onOpen={() => this.setState({ showZapierDialog: false })}
                screenWidth={this.props.screenWidth}
              />
            </DialogContent>
          </Dialog>
        </RightPanelContent>
    )
  }
}

AutomatedActionList.propTypes = {
  templateId: PropTypes.string.isRequired,
  responseId: PropTypes.string,
  taskId: PropTypes.string,
  actions: PropTypes.array,
  dispatch: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  showUndoMessage: PropTypes.func.isRequired,
  screenWidth: PropTypes.number,
  screenHeight: PropTypes.number
}

AutomatedActionList.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

const mapStateToProps = state => ({
  screenWidth: state.application.screenWidth,
  screenHeight: state.application.screenHeight
})

const mapDispatchToProps = dispatch => ({
  dispatch,
  post: bindActionCreators(post, dispatch),
  get: bindActionCreators(get, dispatch),
  showUndoMessage: bindActionCreators(showUndoMessage, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(AutomatedActionList)
