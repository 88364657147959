import PropTypes from 'prop-types'
import React from 'react'
import FormHelper from '~/businessLogic/formHelper'
import { FormsyRadioGroup, FormsyRadio } from 'formsy-material-ui'
import FieldWrapper from './FieldWrapper'

class Radio extends React.Component {
  constructor (props, context) {
    super(props, context)
    this.state = {}
  }

  render () {
    const { field, onChange, wrapperProps } = this.props
    const fieldName = FormHelper.decodeHTML(field.FieldName)
    const palette = this.context.muiTheme.palette

    return (
      <FieldWrapper
        label={FormHelper.decodeHTML(field.FieldName)}
        required={field.RequiredField}
        showDivider
        {...wrapperProps}
      >
        {(field.ReadOnlyField && !field.FieldOptionList)
          ? <div style={{ position: 'relative', padding: '5px 0px', fontSize: '15px' }}>
            {field.Value}
          </div>
          : <FormsyRadioGroup
            name={fieldName}
            value={field.ValueID}
            required={field.RequiredField}
            onChange={(event, value) => {
              // the label text doesn't get returned so we need to find it ourselves
              field.Value = field.FieldOptionList.filter((option) => {
                if (option.ID.toString() === value) { return true }
              })[0].OptionDescription

              field.ValueID = value

              if (onChange) { onChange(field) }
            }}
          >
            {(field.FieldOptionList) ? field.FieldOptionList.map(radio => (<FormsyRadio
              key={radio.ID}
              value={radio.ID.toString()}
              label={radio.OptionDescription}
              labelStyle={{ fontWeight: '400', fontSize: '15px' }}
              disabled={(field.ReadOnlyField)}
            />)) : null}
          </FormsyRadioGroup>}
        {(!field.ValueID && field.RequiredField)
          ? <div
            style={{
              color: palette.errorColor,
              fontSize: '12px'
            }}
          >{this.context.language.messages.validationErrors.isDefaultRequiredValue}</div>
          : ''
        }
      </FieldWrapper>
    )
  }
}

Radio.propTypes = {
  field: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  wrapperProps: PropTypes.object
}

Radio.contextTypes = {
  muiTheme: PropTypes.object,
  language: PropTypes.object
}

export default Radio
