import PropTypes from 'prop-types'
import React from 'react'
import { FormsyText, FormsySelect } from 'formsy-material-ui'
import { IconButton, ListItem, ListItemSecondaryAction, ListItemText, Menu, MenuItem } from '@material-ui/core'
import { MenuItem as OldMenuItem } from 'material-ui'
import { MoreVert, Check } from '@material-ui/icons'
import DeleteMenuItem from '../Layout/DeleteMenuItem'
import DeleteIconButton from '../Layout/DeleteIconButton'

const operators = [{
  text: 'contains',
  value: 'CONTAINS_TEXT'
}, {
  text: 'equals',
  value: 'EQUALTO_TEXT'
}]

class CustomFieldInstanceFilter extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      dataPoint: '',
      operator: 'CONTAINS_TEXT',
      value: '',
      editMode: false,
      menuAnchor: null
    }
  }

  componentDidMount () {
    if (this.props.value) {
      this.parseFilter(this.props.value)
    }

    if (!this.props.value) {
      this.setState({ editMode: true })
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.value !== this.props.value && this.props.value) {
      this.parseFilter(this.props.value)
    }
  }

  parseFilter (filterString) {
    let dataPoint = filterString.split('(')[0]
    let operator = filterString.split('(')[1].split(')')[0]
    let value = filterString.split(')')[1]

    this.setState({
      dataPoint,
      operator,
      value
    })
  }

  onChange () {
    const { onChange } = this.props
    const { dataPoint, operator, value } = this.state

    if (!dataPoint || !operator || !value) {
      return
    }

    onChange(`${dataPoint}(${operator})${value}`)
  }

  getProcessFieldsMenu () {
    const { processFields } = this.props
    let menu = []

    processFields.map((field, index) => {
      menu.push(<OldMenuItem value={field.filterProperty} key={index} primaryText={field.FieldName} />)
    })

    return menu
  }

  render () {
    const { value, dataPoint, operator, editMode, menuAnchor } = this.state
    const { language, muiTheme } = this.context
    const { processFields } = this.props

    const processFieldsMenu = this.getProcessFieldsMenu()

    let prettyFilterText = ''

    if (processFields.length && value && dataPoint && operator) {
      prettyFilterText = processFields.filter((field) => (field.filterProperty === dataPoint))[0].FieldName || ''
      let prettyOperator = operators.filter((operatorItem) => (operatorItem.value === operator))[0].text
      prettyFilterText += ` ${language.translate(`application.${prettyOperator}`)}`
      prettyFilterText += ` ${value}`
    }

    return (
      (!editMode)
        ? <ListItem>
          <ListItemText primary={prettyFilterText} />
          <ListItemSecondaryAction>
            <IconButton onClick={(e) => { this.setState({ menuAnchor: e.currentTarget }) }}><MoreVert /></IconButton>
            <Menu
              anchorEl={menuAnchor}
              open={Boolean(menuAnchor)}
              onClose={() => { this.setState({ menuAnchor: null }) }}
              getContentAnchorEl={null}
              anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
              <MenuItem onClick={() => {
                this.setState({ editMode: true, menuAnchor: null })
              }}
              >{language.translate('application.edit')}</MenuItem>
              <DeleteMenuItem
                onDelete={() => {
                  this.setState({ menuAnchor: null })
                  this.props.onRemoveField()
                }}
              />
            </Menu>
          </ListItemSecondaryAction>
        </ListItem>
        : <ListItem
          style={{
            backgroundColor: muiTheme.palette.canvasColor,
            border: `1px solid ${muiTheme.palette.borderColor}`
          }}
        >
          <ListItemText primary={
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              alignItems: 'flex-start'
            }}
            >
              <FormsySelect
                required
                autoWidth
                name='dataPoint'
                value={dataPoint}
                onChange={(e, value) => {
                  this.setState({
                    dataPoint: value
                  }, () => {
                    this.onChange()
                  })
                }}
                floatingLabelText={language.translate('application.field')}
                validationErrors={language.messages.validationErrors}
              >
                {processFieldsMenu}
              </FormsySelect>
              <FormsySelect
                required
                name='operator'
                value={operator}
                onChange={(e, value) => {
                  this.setState({
                    operator: value
                  }, () => {
                    this.onChange()
                  })
                }}
                floatingLabelText={language.translate('application.operator')}
                validationErrors={language.messages.validationErrors}
              >
                {[
                  <OldMenuItem
                    value='CONTAINS_TEXT'
                    key='CONTAINS_TEXT'
                    primaryText={language.translate('application.contains')} />,
                  <OldMenuItem
                    value='EQUALTO_TEXT'
                    key='EQUALTO_TEXT'
                    primaryText={language.translate('application.equals')} />
                ]}
              </FormsySelect>
              <FormsyText
                fullWidth
                style={{ width: '250px' }}
                floatingLabelText={language.translate('application.filter')}
                value={value}
                onBlur={(e) => {
                  this.onChange()
                }}
                onChange={(e) => {
                  this.setState({ value: e.currentTarget.value })
                }}
                name={`filter-value`}
                validationErrors={language.messages.validationErrors}
              />
            </div>
          } />
          <ListItemSecondaryAction style={{ top: '10%' }}>
            <DeleteIconButton onDelete={() => {
              this.setState({ editMode: false }, () => {
                this.props.onRemoveField()
              })
            }} />
            <IconButton
              disabled={(!dataPoint || !operator || !value)}
              onClick={() => {
                this.setState({ editMode: false })
              }}
            >
              <Check nativeColor={muiTheme.palette.primary1Color} />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
    )
  }
}

CustomFieldInstanceFilter.propTypes = {
  value: PropTypes.string,
  processFields: PropTypes.array,
  onChange: PropTypes.func,
  onRemoveField: PropTypes.func
}

CustomFieldInstanceFilter.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default CustomFieldInstanceFilter
