import PropTypes from 'prop-types'
import React from 'react'
import { Button, Card, CardContent, CardHeader, CircularProgress } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  button: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
})

export class CountCard extends React.Component {
  render () {
    const palette = this.context.muiTheme.palette
    const { onClick, accentColor, color, count, title, icon, size, classes } = this.props

    let className = 'task-card'

    if (onClick) {
      className += ' clickable'
    }

    if (size === 'large') {
      return (
        <Card
          className={className}
          style={{ backgroundColor: color, flexBasis: '22%' }}
          onClick={onClick}
        >
          <CardHeader
            title={title}
            titleTypographyProps={{ style: { fontSize: '16px', lineHeight: '22px', color: palette.canvasColor } }}
            style={{ backgroundColor: accentColor }}
          />
          <CardContent style={{ padding: '0px' }}>
            {(count !== null)
              ? <Button
                disableTouchRipple
                disabled={(!onClick)}
                style={{ minWidth: '100%', color: palette.canvasColor, fontSize: '60px', fontWeight: 600 }}
                className={classes.button}
              >
                {count.toString()}
                {icon}
              </Button>
              : <CircularProgress style={{ color: palette.canvasColor }} className='loader' />}
          </CardContent>
        </Card>
      )
    } else if (size === 'medium') {
      return (
        <Card
          className={className}
          style={{ flexBasis: '48%', backgroundColor: color }}
          onClick={onClick}
        >
          {(count !== null)
            ? <CardHeader
              title={`${title}: ${count.toString()}`}
              titleTypographyProps={{ style: { fontSize: '16px', lineHeight: '22px', color: palette.canvasColor } }}
            />
            : <CircularProgress size={24} style={{ color: palette.canvasColor }} className='loader' />}
        </Card>
      )
    }
    return (
      <Card
        className={className}
        style={{ flexBasis: '48%', backgroundColor: color }}
        onClick={onClick}
      >
        {(count !== null)
          ? <CardHeader
            title={
              <div
                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
              >
                <div style={{ marginRight: '5px' }}>{icon}</div>
                <div>{count.toString()}</div>
              </div>}
            titleTypographyProps={{ style: { fontSize: '22px', lineHeight: '25px', color: palette.canvasColor } }}
            style={{ padding: '8px' }}
          />
          : <CircularProgress size={22} style={{ color: palette.canvasColor, marginTop: '14px', marginBottom: '14px' }}
                              className='loader' />}
      </Card>
    )
  }
}

CountCard.propTypes = {
  count: PropTypes.number,
  color: PropTypes.string,
  accentColor: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  icon: PropTypes.object,
  classes: PropTypes.object.isRequired,
  size: PropTypes.string
}

CountCard.contextTypes = {
  muiTheme: PropTypes.object
}

export default withStyles(styles)(CountCard)
