import PropTypes from 'prop-types'
import React from 'react'
import Joyride from 'react-joyride'
import Bowser from 'bowser'

const browser = Bowser.getParser(window.navigator.userAgent)

class GuidedTour extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      unseenSteps: [],
      allSteps: []
    }
  }

  componentDidMount () {
    this.props.onInit(this.joyride)
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.joyride.steps !== this.props.joyride.steps) {
      let unseenSteps = []
      let allSteps = []

      this.props.joyride.steps.map((step) => {
        if (!this.props.hasViewedHelpPage(step.id)) {
          unseenSteps = unseenSteps.concat(step.steps)
        }

        allSteps = allSteps.concat(step.steps)
      })

      this.setState({ unseenSteps, allSteps })
    }
  }

  joyrideCallback (event) {
    if (event.type === 'finished' && this.joyride) {
      this.joyride.reset()

      this.setState({ unseenSteps: [] })

      // check to see if the page has already been marked as visited and add it if not
      const { addViewedHelpPage, hasViewedHelpPage } = this.props
      const { steps } = this.props.joyride
      const pagesToAdd = []

      steps.map((step) => {
        if (!hasViewedHelpPage(step.id)) { pagesToAdd.push(step.id) }
      })

      addViewedHelpPage(pagesToAdd)
    }
  }

  render () {
    const { joyride } = this.props
    const { unseenSteps, allSteps } = this.state
    const { language, muiTheme: { palette } } = this.context

    if (browser.getBrowser().name === 'Internet Explorer') { return null }

    // temporarily stop joyride until we can figure out why is isn't saving
    return null

    return (
      <Joyride
        ref={c => (this.joyride = c)}
        steps={(unseenSteps.length) ? unseenSteps : allSteps}
        run={!!(joyride && unseenSteps.length)}
        continuous={(joyride.type === 'continuous')}
        disableOverlayClicks={!joyride.overlay}
        disableOverlay
        scrollOffset={80}
        styles={{
          options: {
            primaryColor: palette.primary1Color
          }
        }}
        locale={{
          back: language.translate('application.back'),
          close: language.translate('application.close'),
          last: language.translate('application.done'),
          next: language.translate('application.next'),
          skip: language.translate('application.skip')
        }}
        showSkipButton
        callback={e => this.joyrideCallback(e)}
      />
    )
  }
}

GuidedTour.propTypes = {
  joyride: PropTypes.object,
  addViewedHelpPage: PropTypes.func.isRequired,
  hasViewedHelpPage: PropTypes.func.isRequired,
  onInit: PropTypes.func
}

GuidedTour.contextTypes = {
  location: PropTypes.object,
  muiTheme: PropTypes.object,
  language: PropTypes.object
}

export default GuidedTour
