import PropTypes from 'prop-types'
import React from 'react'
import { List, ListItem, ListItemText } from '@material-ui/core'
import GroupUserSelect from '../Forms/GroupUserSelect'
import DateField from '../Forms/DateField'
import moment from 'moment'

const style = {
  listItemStyle: {
    paddingTop: '0px',
    paddingBottom: '0px'
  }
}

class TemporaryBackupUser extends React.Component {
  shouldComponentUpdate (nextProps, nextState) {
    return this.props.user !== nextProps.user
  }

  render () {
    const { language, muiTheme: { palette } } = this.context
    const authenticatedUser = this.context.user
    const { user, onSave, dispatch } = this.props

    return (
      <List style={{ padding: '0px 20px', backgroundColor: palette.accent2Color }}>
        <ListItem style={style.listItemStyle}>
          <ListItemText
            primary={
              <GroupUserSelect
                name={language.translate('application.selectBackupUser')}
                dispatch={dispatch}
                allowAllUsers
                allowAllGroups
                groupValue={user.BackupUserGroupID}
                userValue={user.BackupUserID}
                onGroupChange={(groupId) => {
                  if (user.BackupUserGroupID !== groupId) {
                    user.BackupUserGroupID = groupId
                    onSave(user)
                  }
                }}
                onUserChange={(userId) => {
                  if (user.BackupUserID !== userId) {
                    user.BackupUserID = userId
                    onSave(user)
                  }
                }}
              />
            }
          />
        </ListItem>
        <ListItem style={style.listItemStyle}>
          <ListItemText
            primary={
              <DateField
                name='end_date'
                required
                onChange={(date) => {
                  delete user.BackupEndDate_UTC
                  user.BackupEndDate_Local = `${moment(date, authenticatedUser.dateFormat, true).format('YYYY-MM-DD')}T00:00:00`
                  onSave(user)
                }}
                defaultDate={(user.BackupEndDate_Local) ? moment(user.BackupEndDate_Local).toDate() : null}
                formatDate={date => moment(date).format((authenticatedUser.dateFormat) ? authenticatedUser.dateFormat : 'YYYY-MM-DD')}
                label={language.translate('application.endDateLabel')}
              />
            }
          />
        </ListItem>
      </List>
    )
  }
}

TemporaryBackupUser.propTypes = {
  user: PropTypes.object,
  onSave: PropTypes.func,
  dispatch: PropTypes.func.isRequired
}

TemporaryBackupUser.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object,
  user: PropTypes.object
}

export default TemporaryBackupUser
