import PropTypes from 'prop-types'
import React from 'react'
import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { FormsyText } from 'formsy-material-ui'
import { Form } from 'formsy-react'
import CancelButton from '../Forms/CancelButton'

class ChangePasswordDialog extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      canSubmit: true,
      password: ''
    }
  }

  render () {
    const { open, user, onRequestClose, onSave } = this.props
    const { language, muiTheme } = this.context
    const palette = muiTheme.palette

    return (
      <Dialog
        open={open}
        onClose={onRequestClose}
      >
        <DialogTitle
          style={{
            backgroundColor: palette.headerBackgroundColor
          }}
          disableTypography
        >
          <Typography
            variant='h6'
            style={{ color: palette.alternateTextColor }}>
            {language.translate('application.changePassword')}
          </Typography>
        </DialogTitle>
        <DialogContent style={{ width: '250px' }}>
          <Form
            onValid={() => this.setState({ canSubmit: true })}
            onInvalid={() => this.setState({ canSubmit: false })}
            style={{ display: 'flex', flexDirection: 'column' }}
            autoComplete='off'
          >
            <FormsyText
              fullWidth
              required
              type='password'
              floatingLabelText={language.translate('application.newPassword')}
              onChange={(e) => {
                this.setState({ password: e.currentTarget.value })
              }}
              name='pw'
              validationErrors={language.messages.validationErrors}
            />
            <FormsyText
              fullWidth
              required
              type='password'
              floatingLabelText={language.translate('application.confirmNewPassword')}
              name='repeatPassword'
              validations={'equalsField:pw'}
              validationErrors={language.messages.validationErrors}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingTop: '30px'
              }}
            >
              <Button
                onClick={() => {
                  user.Password = this.state.password
                  onSave(user)
                  onRequestClose()
                }}
                disabled={!this.state.canSubmit}
                color='primary'
                variant='contained'
              >
                {language.translate('application.save')}
              </Button>
              <CancelButton
                onClick={() => onRequestClose()}
                variant='contained'
              />
            </div>
          </Form>
        </DialogContent>
      </Dialog>
    )
  }
}

ChangePasswordDialog.propTypes = {
  open: PropTypes.bool,
  user: PropTypes.object,
  onSave: PropTypes.func,
  onRequestClose: PropTypes.func.isRequired
}

ChangePasswordDialog.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default ChangePasswordDialog
