import PropTypes from 'prop-types'
import React from 'react'
import { FormsyText } from 'formsy-material-ui'
import { Divider, IconButton, Menu, ListItem, ListItemSecondaryAction, ListItemText, MenuItem } from '@material-ui/core'
import { Check, MoreVert } from '@material-ui/icons'
import FormHelper from '~/businessLogic/formHelper'
import { Form } from 'formsy-react'
import DeleteMenuItem from '../Layout/DeleteMenuItem'
import DeleteIconButton from '../Layout/DeleteIconButton'

class TemplateGroupListItem extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      editMode: false,
      canSubmit: true,
      value: null,
      item: null,
      menuAnchor: null
    }
  }

  componentDidMount () {
    if (this.props.item.GroupName === '') { this.setState({ editMode: true }, () => {this.editGroupNameInput.focus()}) }

    this.setState({ item: this.props.item })
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.item !== prevProps.item) { this.setState({ item: this.props.item }) }
  }

  enableButton () {
    this.setState({ canSubmit: true })
  }

  disableButton () {
    this.setState({ canSubmit: false })
  }

  render () {
    const { language, muiTheme } = this.context
    const { onSave } = this.props
    const { item, menuAnchor } = this.state

    if (!item) { return null }

    return (
      (!this.state.editMode)
        ? <ListItem>
          <ListItemText primary={FormHelper.decodeHTML(item.GroupName)} />
          <ListItemSecondaryAction>
            <div>
              <IconButton onClick={(e) => { this.setState({ menuAnchor: e.currentTarget }) }}><MoreVert /></IconButton>
              <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={() => { this.setState({ menuAnchor: null }) }}
                getContentAnchorEl={null}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              >
                <MenuItem onClick={() => this.setState({ editMode: true, menuAnchor: null }, () => { this.editGroupNameInput.focus() })}>
                  {language.translate('application.edit')}
                </MenuItem>
                <DeleteMenuItem
                  onDelete={() => {
                    this.setState({ menuAnchor: null })
                    this.props.deleteGroup(item)
                  }}
                />
              </Menu>
            </div>
          </ListItemSecondaryAction>
        </ListItem>
        : <Form onValid={this.enableButton.bind(this)} onInvalid={this.disableButton.bind(this)}>
          <ListItem style={{ padding: '0px 0px 16px 16px' }}>
            <ListItemText primary={
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                  alignItems: 'center'
                }}
                >
                  <FormsyText
                    floatingLabelText={language.translate('application.groupName')}
                    required
                    name={`group-name-${item.ID}`}
                    ref={(input) => {this.editGroupNameInput = input}}
                    defaultValue={FormHelper.decodeHTML(item.GroupName)}
                    onChange={(e) => {
                      item.GroupName = e.currentTarget.value
                      this.setState({ item })
                    }}
                    validationErrors={language.messages.validationErrors}
                  />
                </div>
                <div>
                  <DeleteIconButton onDelete={() => this.props.deleteGroup(item)} />
                  <IconButton
                    disabled={!this.state.canSubmit}
                    onClick={() => {
                      onSave(this.state.item)
                      this.setState({ editMode: false })
                    }}
                  >
                    <Check />
                  </IconButton>
                </div>
              </div>
            } />
          </ListItem>
          <Divider />
        </Form>
    )
  }
}

TemplateGroupListItem.propTypes = {
  item: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  deleteGroup: PropTypes.func.isRequired
}

TemplateGroupListItem.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object,
  user: PropTypes.object
}

export default TemplateGroupListItem
