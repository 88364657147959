import PropTypes from 'prop-types'
import React from 'react'
import { Button, Divider, List } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import GroupAssignmentListItem from './GroupAssignmentListItem'

class GroupAssignments extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {}
  }

  saveGroup (group, index) {
    const { saveGroups, userGroups } = this.props

    userGroups[index] = group

    saveGroups(userGroups)
  }

  render () {
    const { language, muiTheme: { palette } } = this.context
    const { user, onSave, generateNewUserGroup, userGroups } = this.props

    return (
      <List style={{ padding: '0px 20px', backgroundColor: palette.accent2Color }}>
        {userGroups.map((group, index) => (
          <div key={index}>
            <GroupAssignmentListItem
              group={group}
              user={user}
              onSave={onSave}
              saveGroup={group => this.saveGroup(group, index)}
            />
            <Divider />
          </div>))}
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
          <Button
            onClick={() => generateNewUserGroup()}
            color='primary'
            style={{ margin: '10px' }}
            variant='contained'
          >
            <Add />
            {language.translate('application.createNewGroup')}
          </Button>
        </div>
      </List>
    )
  }
}

GroupAssignments.propTypes = {
  user: PropTypes.object,
  userGroups: PropTypes.array,
  onSave: PropTypes.func,
  saveGroups: PropTypes.func,
  generateNewUserGroup: PropTypes.func
}

GroupAssignments.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default GroupAssignments
