import PropTypes from 'prop-types'
import React from 'react'
import { FormsyText } from 'formsy-material-ui'
import objectAssign from 'object-assign'
import FormHelper from '../../businessLogic/formHelper'
import FieldWrapper from './FieldWrapper'

class Text extends React.Component {
  constructor (props, context) {
    super(props, context)
    this.state = {}
  }

  componentDidMount () {
    const {autoFocus, field} = this.props

    if (autoFocus && !field.ReadOnlyField) {
      this.textField.focus()
    }
  }

  prepValidations () {
    const validations = []

    if (this.props.type === 'tel') validations.push('isMoney')

    return (validations.length > 0) ? validations.join() : null
  }

  render () {
    const { onChange, onBlur, wrapperProps, field, ...rest } = this.props

    return (
      <FieldWrapper
        label={FormHelper.decodeHTML(field.FieldName)}
        required={field.RequiredField}
        {...wrapperProps}
      >
        {(field.ReadOnlyField)
          ? <div style={{ position: 'relative', padding: '5px 0px', fontSize: '15px' }} dangerouslySetInnerHTML={{ __html: FormHelper.encodeHTML(field.Value) }} />
          : <FormsyText
            required={field.RequiredField}
            fullWidth
            name={field.FieldName || 'no-name'}
            value={FormHelper.decodeHTML(field.Value)}
            type={this.props.type}
            onChange={(event) => {
              if (onChange) {
                onChange(objectAssign({}, field, { Value: event.currentTarget.value }))
              }
            }}
            onBlur={(event) => {
              if (onBlur) { onBlur(field) }
            }}
            ref={(input) => { this.textField = input }}
            validations={this.prepValidations()}
            validationErrors={this.context.language.messages.validationErrors}
            {...rest}
          />
        }
      </FieldWrapper>
    )
  }
}

Text.propTypes = {
  field: PropTypes.object.isRequired,
  type: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  wrapperProps: PropTypes.object,
  autoFocus: PropTypes.bool
}

Text.contextTypes = {
  language: PropTypes.object
}

export default Text
