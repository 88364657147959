import PropTypes from 'prop-types'
import React from 'react'
import { IconButton, ListItem, ListItemText, ListItemSecondaryAction, Switch, Tooltip } from '@material-ui/core'
import { FormsyText } from 'formsy-material-ui'
import { Check } from '@material-ui/icons'
import { Form } from 'formsy-react'

class GroupAssignmentListItem extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      editGroup: !(this.props.group.ID),
      canSubmit: true
    }
  }

  shouldItBeToggled (id) {
    const arrayOfFieldsToShow = this.props.user.UserGroupIDList

    return (arrayOfFieldsToShow.indexOf(id) >= 0)
  }

  saveGroups (id, isToggled) {
    const { user } = this.props
    let array = user.UserGroupIDList.filter(Boolean)

    if (isToggled) {
      array = array.concat([id])
    } else {
      const indexOfField = array.indexOf(id)
      if (indexOfField >= 0) array.splice(indexOfField, 1)
    }

    user.UserGroupIDList = array
    this.props.onSave(user)
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const { group, saveGroup } = this.props
    const { editGroup } = this.state
    const isToggled = (this.shouldItBeToggled(group.ID))

    return (
      <ListItem style={{ paddingTop: '14px', paddingBottom: '12px' }}>
        <ListItemText
          primary={(!editGroup)
            ? group.GroupName
            : <Form
              onValid={() => this.setState({ canSubmit: true })}
              onInvalid={() => this.setState({ canSubmit: false })}
            >
              <FormsyText
                style={{ width: '250px' }}
                floatingLabelText={language.translate('application.groupName')}
                required
                name={`${group.GroupName}-group`}
                defaultValue={group.GroupName}
                onChange={(e) => { group.GroupName = e.currentTarget.value }}
                validationErrors={language.messages.validationErrors}
              />
              <Tooltip title={language.translate('application.save')}>
                <IconButton
                  onClick={() => {
                    this.setState({ editGroup: false })
                    saveGroup(group)
                  }}
                >
                  <Check nativeColor={palette.accent3Color} />
                </IconButton>
              </Tooltip>
            </Form>
          }
          secondary={`${language.translate('application.members')}: ${group.MemberCount}`}
        />
        <ListItemSecondaryAction>
          {(!editGroup)
            ? <Switch
              color='primary'
              checked={isToggled}
              onChange={(obj, value) => {
                this.saveGroups(group.ID, !isToggled)
              }}
            />
            : null}
        </ListItemSecondaryAction>
      </ListItem>
    )
  }
}

GroupAssignmentListItem.propTypes = {
  group: PropTypes.object,
  user: PropTypes.object,
  onSave: PropTypes.func,
  saveGroup: PropTypes.func
}

GroupAssignmentListItem.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default GroupAssignmentListItem
