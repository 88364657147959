export const colors = ['#3399cc', '#6076a8', '#7accc8', '#f2c159', '#3acd9b', '#eede75', '#436472']

class ColorHelper {
  /**
   * Returns a random color
   *
   * @param number
   * @returns {*}
   */

  static getRandom (number = Math.floor((Math.random() * 10000) + 1)) {
    const index = number % colors.length

    return colors[index]
  }
}

export default ColorHelper
