import PropTypes from 'prop-types'
import React from 'react'
import FormHelper from '~/businessLogic/formHelper'
import { Form } from 'formsy-react'
import { FormsySelect, FormsyText } from 'formsy-material-ui'
import Icon from '../../Icon/Icon'
import {
  Button, Card, Checkbox, Divider, FormControl, FormControlLabel, IconButton, List, ListItem, ListItemText, MenuItem,
  Select, Step, StepButton, StepContent, Stepper, TextField, Typography
} from '@material-ui/core'
import { MenuItem as OldMenuItem, TimePicker } from 'material-ui'
import { Edit, ArrowBack } from '@material-ui/icons'
import PropertyComponent from './PropertyComponent'
import ActionConditionList from './ActionConditionList'
import './styles.scss'
import RightPanelContent from '../../Layout/RightPanelContent'
import moment from 'moment'

class EditAction extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      stepIndex: 0,
      canSubmit: true,
      isDirty: false,
      canSubmitActionName: false,
      editActionName: false,
      responseMenu: [],
      actionProperties: [],
      actionTypeList: [],
      tokens: {},
      tokensLoaded: false,
      targetDatePivot: null,
      conditionList: [],
      conditionsLoaded: false,
      triggerPivot: null,
      repeatPivot: null,
      relatedTemplates: [],
      relatedTemplatesLoaded: false,
      tasks: [],
      workSchedules: [],
      tasksLoaded: false
    }
  }

  componentDidMount () {
    let { action } = this.props

    if (action.ID) {
      this.fetchActionProperties()
      this.fetchActionConditionList()
    }

    if (action.ActionTrigger_Trigger === 'Linked Process Task Complete') {
      this.props.getRelatedTemplates((relatedTemplates) => {
        this.setState({
          relatedTemplates,
          relatedTemplatesLoaded: true
        })
      })

      if (action.ActionTrigger_ProcessTemplateID) {
        this.props.getTasks(action.ActionTrigger_ProcessTemplateID, (tasks) => {
          this.setState({
            tasks,
            tasksLoaded: true
          })
        })
      }

      if (action.ProcessTemplateTaskID) {
        this.props.onRequestTaskResponses(action.ActionTrigger_ProcessTemplateID, action.ProcessTemplateTaskID)
      }
    }

    if (action.IntegrationActionTypeID) { this.fetchActionTypeProperties(action, action.IntegrationTypeID) }

    this.setState({
      targetDatePivot: (action.ActionTrigger_TriggerMinutes > 0) ? 'after' : 'before'
    })

    this.initPivot('ActionTrigger_TriggerMinutes', 'triggerPivot')
    this.initPivot('ActionTrigger_RepeatMinutes', 'repeatPivot')

    this.fetchFieldTokens()
    this.fetchWorkSchedules()

    if (action.ActionTrigger_Trigger) { this.setState({ stepIndex: 3 }) } else if (action.IntegrationID) { this.setState({ stepIndex: 2 }) }
  }

  shouldComponentUpdate (nextProps, nextState, nextContext) {
    if (nextProps.action.ID !== this.props.action.ID) { return true }

    if (nextProps.responses !== this.props.responses) { return true }

    if (nextState.relatedTemplatesLoaded !== this.state.relatedTemplatesLoaded) { return true }

    return true
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.action.ID !== this.props.action.ID && this.props.action.ID && this.props.action.IntegrationActionTypeID) {
      this.fetchActionProperties()
      this.fetchActionConditionList()
    }

    if (prevProps.responses !== this.props.responses) { this.setState({ responseMenu: this.generateResponseMenuItems() }) }

    if (prevProps.action.ActionTrigger_CompletedDays !== this.props.action.ActionTrigger_CompletedDays) { this.setState({ targetDatePivot: (this.props.action.ActionTrigger_CompletedDays > 0) ? 'after' : 'before' }) }

    if (prevProps.action.ActionTrigger_Trigger !== this.props.action.ActionTrigger_Trigger &&
      this.props.action.ActionTrigger_Trigger === 'Linked Process Task Complete' && !this.state.relatedTemplatesLoaded) {
      this.props.getRelatedTemplates((relatedTemplates) => {
        this.setState({
          relatedTemplates,
          relatedTemplatesLoaded: true
        })
      })
    }
  }

  fetchWorkSchedules () {
    const { get } = this.props
    const endpoint = `workschedule/list`

    get(endpoint, {
      onSuccess: (response) => {
        this.setState({
          workSchedules: response.WorkScheduleList
        })
      }
    })
  }

  workScheduleMenu () {
    const { workSchedules } = this.state
    let { language } = this.context    
    const menu =  [<MenuItem value={false} key={0}>{language.translate('application.selectWorkSchedule') + '...'}</MenuItem>]

    if (workSchedules) {

      workSchedules.map((workSchedule) => {
        menu.push(<MenuItem value={workSchedule.ID} key={workSchedule.ID}>{workSchedule.ScheduleName}</MenuItem>)
      })
    }

    return menu
  }

  enableButton () {
    this.setState({ canSubmit: true })
  }

  disableButton () {
    this.setState({ canSubmit: false })
  }

  handleNext = () => {
    const { stepIndex } = this.state

    if (stepIndex < 3) {
      this.setState({ stepIndex: stepIndex + 1 })
    }
  }

  initPivot (actionMinuteProperty, statePivotProperty) {
    let { action } = this.props
    let pivot = 'minutes'

    if (action[actionMinuteProperty] % 1440 === 0) { pivot = 'days' }

    if (action[actionMinuteProperty] % 60 === 0) { pivot = 'hours' }

    this.setState({ [statePivotProperty]: pivot })
  }

  generateTriggerMenuItems () {
    let triggers = this.props.triggers
    let { language } = this.context

    const menu = [<MenuItem value={false} key={0}>{language.translate('application.selectTrigger') + '...'}</MenuItem>]

    triggers.map(function (trigger, index) {
      menu.push(<MenuItem value={trigger.ID} key={trigger.ID}>{trigger.Description}</MenuItem>)
    })

    return menu
  }

  generateTaskMenuItems (tasks) {
    let { language } = this.context

    const menu = [<MenuItem
      value={0}
      key={0}
      style={{ overflow: 'inherit' }}>
      {language.translate('application.applyToAllTasks')}
    </MenuItem>]

    tasks.map(function (task, index) {
      menu.push(<MenuItem value={task.ID} key={task.ID} style={{ overflow: 'inherit' }}>{task.TaskText}</MenuItem>)
    })

    return menu
  }

  generateResponseMenuItems () {
    let responses = this.props.responses
    let { language } = this.context

    const menu = [<MenuItem value={false} key={0} style={{ overflow: 'inherit' }}>{language.translate('application.applyToAllResponses')}</MenuItem>]

    responses.map(function (response, index) {
      menu.push(<MenuItem value={response.ID} key={response.ID} style={{ overflow: 'inherit' }}>{response.ResponseText}</MenuItem>)
    })

    return menu
  }

  generateRelatedToTemplateMenu () {
    let { relatedTemplates } = this.state
    let { language } = this.context

    const menu = [<MenuItem value={false} key={0}>{language.translate('application.selectTemplate')}</MenuItem>]

    relatedTemplates.map((template) => {
      menu.push(<MenuItem value={template.ID} key={template.ID}>{template.Title}</MenuItem>)
    })

    return menu
  }

  generateFieldMenuItems (fieldType) {
    let fields = this.props.templateFields
    let { language } = this.context

    const menu = [<MenuItem value={false} key={0}>{language.translate('application.selectProcessField')}</MenuItem>]

    fields.map(function (field, index) {
      if (!fieldType || field.FieldType === fieldType) {
        menu.push(<MenuItem value={field.ID} key={field.ID}>{FormHelper.decodeHTML(field.FieldName)}</MenuItem>)
      }
    })

    return menu
  }

  generatePublicFormMenuItems () {
    let forms = this.props.publicForms
    let { language } = this.context

    const menu = [<MenuItem value={false} key={0}>{language.translate('application.selectForm')}</MenuItem>]

    forms.map(function (form, index) {
      menu.push(<MenuItem value={form.ID} key={form.ID}>{FormHelper.decodeHTML(form.PublicDescription)}</MenuItem>)
    })

    return menu
  }

  fetchActionProperties () {
    let { action } = this.props

    if (!action.ID) { return }

    this.props.get(
      'processtemplate/' + action.ProcessTemplateID + '/automatedaction/' + action.ID + '/property/list',
      {
        onSuccess: response => {
          this.setState({
            actionProperties: response.IntegrationPropertyList
          })
        }
      }
    )
  }

  saveActionProperties (properties) {
    let { action, post } = this.props

    let body = JSON.stringify({
      IntegrationPropertyList: properties
    })

    post(
      'processtemplate/' + action.ProcessTemplateID + '/automatedaction/' + action.ID + '/property/list',
      body,
      {
        onSuccess: response => {
          this.setState({
            actionProperties: response.IntegrationPropertyList
          })
        }
      }
    )
  }

  fetchActionTypeProperties (action, typeId) {
    let { onSave } = this.props

    this.props.get('integrationtype/' + typeId + '/actiontype/list', {
      onSuccess: response => {
        if (response.IntegrationActionTypeList.length === 1 || (!action.IntegrationActionTypeID && response.IntegrationActionTypeList.length)) {
          action.IntegrationActionTypeID = response.IntegrationActionTypeList[0].ID
        }

        onSave(action, () => this.fetchActionProperties())

        this.setState({
          actionTypeList: response.IntegrationActionTypeList
        })
      }
    })
  }

  fetchFieldTokens () {
    let { action } = this.props

    this.props.get('processtemplate/' + action.ProcessTemplateID + '/fieldtoken/list', {
      onSuccess: response => {
        const tokens = response.TextValueList

        this.setState({
          tokens: tokens,
          tokensLoaded: true
        })
      }
    })
  }

  getInstanceFieldOptions (templateId, fieldId, callback) {
    const { get } = this.props

    get(`processtemplate/${templateId}/field/${fieldId}/externaloption/list`, {
      onSuccess: response => {
        callback(response.ProcessTemplateFieldOptionList)
      }
    })
  }

  actionTypeMenu () {
    let { actionTypeList } = this.state
    let options = []

    actionTypeList.map(function (option) {
      options.push(<OldMenuItem value={option.ID} key={option.ID} primaryText={option.Description} />)
    })

    return options
  }

  fetchActionConditionList () {
    let { action } = this.props

    this.props.get(
      'processtemplate/' + action.ProcessTemplateID + '/automatedaction/' + action.ID + '/condition/list',
      {
        onSuccess: response => {
          this.setState({
            conditionList: response.ProcessTemplateAutomatedActionConditionList,
            conditionsLoaded: true
          })
        }
      }
    )
  }

  saveActionConditionList (list) {
    let { action } = this.props

    let body = JSON.stringify({
      ProcessTemplateAutomatedActionConditionList: list
    })

    this.props.post(
      'processtemplate/' + action.ProcessTemplateID + '/automatedaction/' + action.ID + '/condition/list',
      body,
      {
        onSuccess: response => {
          this.setState({
            conditionList: response.ProcessTemplateAutomatedActionConditionList
          })
        },
        onError: error => {
          this.fetchActionConditionList()
        }
      }
    )
  }

  copyActionConditionListItem (item) {
    let { action } = this.props

    let body = JSON.stringify({})

    this.props.post(
      'processtemplate/' + action.ProcessTemplateID + '/automatedaction/' + action.ID + '/condition/' + item.ID + '/copy',
      body,
      {
        onSuccess: response => {
          this.fetchActionConditionList()
        }
      }
    )
  }

  deleteActionConditionListItem (item) {
    let { action } = this.props
    let { language } = this.context

    let body = JSON.stringify({})

    this.props.post(
      'processtemplate/' + action.ProcessTemplateID + '/automatedaction/' + action.ID + '/condition/' + item.ID + '/delete',
      body,
      {
        onSuccess: response => {
          this.fetchActionConditionList()

          this.props.showUndoMessage(
            language.translate('application.conditionRemoved'),
            () => {
              this.props.post(
                'processtemplate/' + action.ProcessTemplateID + '/automatedaction/' + action.ID + '/condition/' + item.ID + '/delete/undo',
                body,
                {
                  onSuccess: response => {
                    this.fetchActionConditionList()
                  }
                }
              )
            }
          )
        }
      }
    )
  }

  handleActionSubTypeChange (e, value) {
    let { action, onSave } = this.props

    action.IntegrationActionTypeID = value
    onSave(action, () => this.fetchActionProperties())
  }

  handlePropertyInputChange (property, text, value) {
    let { actionProperties } = this.state
    let propertyIndex = actionProperties.map((p, index) => {
      if (p.ID === property.ID) return index
    })

    property.PropertyValue = value
    property.PropertyText = text

    actionProperties[propertyIndex] = property
    this.saveActionProperties(actionProperties)
  }

  convertToMinutes (time, timeFrame) {
    time = parseInt(time)
    switch (timeFrame) {
      case 'days':
        return time * 1440
      case 'hours':
        return time * 60

      default:
        return time
    }
  }

  setEditTitleFocus () {
    if (!this.titleEditInput) {
      setTimeout(() => {
        this.setEditTitleFocus()
      }, 100)
    } else {
      this.titleEditInput.focus()
    }
  }

  render () {
    if (!this.props.action) { return null }

    let palette = this.context.muiTheme.palette
    let { language } = this.context
    let {
      editActionName, canSubmitActionName, stepIndex, responseMenu, actionTypeList, actionProperties, tokens,
      tokensLoaded, targetDatePivot, conditionList, conditionsLoaded, triggerPivot, repeatPivot, tasksLoaded, tasks
    } = this.state
    let {
      action, onSave, onClose, integrations, onRequestTaskResponses, conditionTypes, templateFields, dispatch,
      templateTasks, showZapierDialog, userFieldList, screenHeight, getTasks, onUpdate
    } = this.props
    let triggerMenu = this.generateTriggerMenuItems()
    let taskMenu = this.generateTaskMenuItems(templateTasks)
    let publicFormsMenu = this.generatePublicFormMenuItems()
    let daysOfTheWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

    action = { ...action }

    return (
      <RightPanelContent
        leftToolbarAvatar={
          <IconButton onClick={() => onClose()}>
            <ArrowBack />
          </IconButton>
        }
        title={
          (editActionName)
            ? <Form
              onValid={() => this.setState({ canSubmitActionName: true })}
              onInvalid={() => this.setState({ canSubmitActionName: false })}>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start'
              }}>
                <FormsyText
                  required
                  name='action_purpose'
                  ref={(input) => { this.titleEditInput = input }}
                  value={FormHelper.decodeHTML(action.Action_Purpose)}
                  onBlur={(event) => {
                    action.Action_Purpose = event.currentTarget.value
                    this.setState({ editActionName: false })
                    onSave(action)
                  }}
                  validationErrors={language.messages.validationErrors}
                  errorStyle={{ display: 'none' }}
                />
              </div>
            </Form>
            : <div style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start'
            }}>
              <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {FormHelper.decodeHTML(action.Action_Purpose)}
              </div>
              <IconButton
                onClick={() => this.setState({ editActionName: true }, () => { this.setEditTitleFocus() })}
              >
                <Edit nativeColor={palette.accent3Color} />
              </IconButton>
            </div>
        }
      >
        <div style={{ padding: '10px' }}>
          <Card className='card'>
            {
              <div className='stepper' style={{ marginTop: '10px' }}>
                <Stepper
                  activeStep={stepIndex}
                  nonLinear
                  orientation='vertical'
                >
                  <Step>
                    <StepButton onClick={() => this.setState({ stepIndex: 0 })}>
                      <strong>{language.translate('application.selectActionType')}</strong>
                    </StepButton>
                    <StepContent>
                      <List>
                        {integrations.map((integration, index) => {
                          return (
                            React.Children.toArray([
                              <ListItem
                                selected={action.IntegrationID === integration.ID}
                                key={integration.ID}
                                button
                                onClick={() => {
                                  let selectedIntegration = integrations.filter((storedIntegration) => (storedIntegration.ID === integration.ID))[0]

                                  // if Zapier was selected, go through Zapier instead
                                  if (selectedIntegration.IntegrationTypeIdentifier === 'ZP') {
                                    showZapierDialog()
                                    onClose()
                                    return
                                  }

                                  action.IntegrationID = integration.ID
                                  action.IntegrationActionTypeID = null

                                  this.setState({
                                    actionTypeList: [],
                                    actionProperties: []
                                  })

                                  this.fetchActionTypeProperties(action, selectedIntegration.IntegrationTypeID)

                                  this.handleNext()
                                }}
                              >
                                <Icon
                                  icon={integration.IntegrationTypeIconName}
                                  size={40} />
                                <ListItemText primary={FormHelper.decodeHTML(integration.Description)} />
                              </ListItem>,
                              (index < integrations.length - 1) ? <Divider key={index} /> : null
                            ])
                          )
                        })}
                      </List>
                    </StepContent>
                  </Step>
                  <Step disabled={(!action.IntegrationID)}>
                    <StepButton onClick={() => this.setState({ stepIndex: 1 })}>
                      <strong>{language.translate('application.selectTrigger')}</strong>
                    </StepButton>
                    <StepContent>
                      <Form>
                        <Select
                          name='trigger'
                          style={{ margin: '5px' }}
                          inputProps={{ style: { overflow: 'hidden', textOverflow: 'ellipsis' } }}
                          onChange={(e) => {
                            action.ActionTrigger_Trigger = e.target.value
                            action.ActionTrigger_ProcessTemplateID = ''
                            action.ProcessTemplateTaskID = ''
                            action.ProcessTemplateTaskResponseID = ''
                            action.ActionTrigger_Count = 0
                            action.ActionTrigger_TriggerMinutes = Math.abs(action.ActionTrigger_TriggerMinutes) // make sure this is positive
                            onSave(action)
                            this.setState({ targetDatePivot: 'after' })
                          }}
                          fullWidth
                          value={action.ActionTrigger_Trigger || false}>
                          {triggerMenu}
                        </Select>
                        {(action.ActionTrigger_Trigger && action.ActionTrigger_Trigger === 'Linked Process Task Complete')
                          ? <Select
                            name='relatedTemplate'
                            style={{ margin: '5px' }}
                            inputProps={{ style: { overflow: 'hidden', textOverflow: 'ellipsis' } }}
                            onChange={(e) => {
                              action.ActionTrigger_ProcessTemplateID = e.target.value
                              if (e.target.value) {
                                getTasks(e.target.value, (tasks) => { this.setState({ tasks, tasksLoaded: true }) })
                              }
                              onSave(action)
                            }}
                            fullWidth
                            value={action.ActionTrigger_ProcessTemplateID || false}>
                            {this.generateRelatedToTemplateMenu()}
                          </Select>
                          : null}
                        {(action.ActionTrigger_Trigger && action.ActionTrigger_Trigger === 'Linked Process Task Complete' && tasksLoaded && action.ActionTrigger_ProcessTemplateID)
                          ? <Select
                            name='relatedTemplateTask'
                            style={{ margin: '5px' }}
                            inputProps={{ style: { overflow: 'hidden', textOverflow: 'ellipsis' } }}
                            onChange={(e) => {
                              action.ProcessTemplateTaskID = e.target.value
                              if (e.target.value) {
                                onRequestTaskResponses(action.ActionTrigger_ProcessTemplateID, e.target.value)
                              }
                              onSave(action)
                            }}
                            fullWidth
                            MenuProps={{ PaperProps: { style: { maxWidth: 400, overflow: 'auto' } } }}
                            value={action.ProcessTemplateTaskID || 0}>
                            {this.generateTaskMenuItems(tasks)}
                          </Select>
                          : null}
                        {(action.ActionTrigger_Trigger && action.ActionTrigger_Trigger.substr(0, 4) === 'Task')
                          ? <Select
                            name='templateTask'
                            style={{ margin: '5px' }}
                            inputProps={{ style: { overflow: 'hidden', textOverflow: 'ellipsis' } }}
                            onChange={(e) => {
                              action.ProcessTemplateTaskID = e.target.value
                              onSave(action);

                              (action.ActionTrigger_Trigger === 'Task Closed' && e.target.value !== 0)
                                ? onRequestTaskResponses(action.ProcessTemplateID, e.target.value)
                                : this.setState({ responseMenu: [] })
                            }}
                            fullWidth
                            MenuProps={{ PaperProps: { style: { maxWidth: 400, overflow: 'auto' } } }}
                            value={action.ProcessTemplateTaskID || 0}>
                            {taskMenu}
                          </Select>
                          : null}
                        {(['Linked Process Task Complete', 'Task Closed'].includes(action.ActionTrigger_Trigger) && action.ProcessTemplateTaskID)
                          ? (responseMenu.length > 0)
                            ? <Select
                              name='response'
                              style={{ margin: '5px' }}
                              onChange={(e) => {
                                action.ProcessTemplateTaskResponseID = e.target.value
                                onSave(action)
                              }}
                              fullWidth
                              MenuProps={{ PaperProps: { style: { maxWidth: 400, overflow: 'auto' } } }}
                              value={action.ProcessTemplateTaskResponseID || false}>
                              {responseMenu}
                            </Select>
                            : null
                          : null}
                        {(action.ActionTrigger_Trigger === 'Linked Process Task Complete')
                          ? <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography>Number to complete before triggering:</Typography>
                            <TextField
                              type='tel'
                              name='count'
                              ref='count'
                              style={{ width: '50px', marginLeft: '5px', marginRight: '5px' }}
                              disabled={(action.ActionTrigger_Count > 9999)}
                              value={action.ActionTrigger_Count || 0}
                              onChange={(e) => {
                                action.ActionTrigger_Count = parseInt(e.currentTarget.value, 10)
                                onUpdate(action)
                              }}
                              onBlur={(e) => {
                                onSave(action)
                              }}
                              fullWidth
                            />
                            <FormControl
                              style={{ marginTop: '16px', marginBottom: '8px' }}
                            >
                              <FormControlLabel
                                key='count_check'
                                label={language.translate('application.all')}
                                control={
                                  <Checkbox
                                    color='primary'
                                    checked={(action.ActionTrigger_Count > 9999)}
                                    onChange={(e, isChecked) => {
                                      if (action.ActionTrigger_Count > 9999) {
                                        action.ActionTrigger_Count = 0
                                      } else {
                                        action.ActionTrigger_Count = 10000
                                      }

                                      onSave(action)
                                    }}
                                  />} />
                            </FormControl>
                          </div> : null}
                        {(action.ActionTrigger_Trigger === 'Process Field Updated' ||
                          action.ActionTrigger_Trigger === 'Field Date Proximity')
                          ? <Select
                            name='template_field'
                            style={{ margin: '5px' }}
                            inputProps={{ style: { overflow: 'hidden', textOverflow: 'ellipsis' } }}
                            onChange={(e) => {
                              action.ActionTrigger_ProcessTemplateFieldNameID = e.target.value
                              onSave(action)
                            }}
                            fullWidth
                            value={action.ActionTrigger_ProcessTemplateFieldNameID || false}>
                            {(action.ActionTrigger_Trigger === 'Field Date Proximity')
                              ? this.generateFieldMenuItems('D')
                              : this.generateFieldMenuItems()}
                          </Select>
                          : null}

                        {(action.ActionTrigger_Trigger === 'Public Form Submitted')
                          ? <Select
                            name='public_form'
                            style={{ margin: '5px' }}
                            inputProps={{ style: { overflow: 'hidden', textOverflow: 'ellipsis' } }}
                            onChange={(e) => {
                              action.ActionTrigger_ProcessTemplatePublicFormID = e.target.value
                              onSave(action)
                            }}
                            fullWidth
                            value={action.ActionTrigger_ProcessTemplatePublicFormID || false}>
                            {publicFormsMenu}
                          </Select>
                          : null}

                        {(action.ActionTrigger_Trigger === 'Task Pending For' ||
                          action.ActionTrigger_Trigger === 'Process Pending For' ||
                          action.ActionTrigger_Trigger === 'Task Completed For' ||
                          action.ActionTrigger_Trigger === 'Target Date Proximity' ||
                          action.ActionTrigger_Trigger === 'Task Due Date Proximity' ||
                          action.ActionTrigger_Trigger === 'Field Date Proximity')
                          ? <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            margin: '0px 5px 5px 6px',
                            fontSize: '16px'
                          }}>
                            <div>
                              {(action.ActionTrigger_Trigger === 'Task Pending For' || action.ActionTrigger_Trigger === 'Process Pending For') ? language.translate('application.pendingFor') : null}
                              {(action.ActionTrigger_Trigger === 'Task Completed For') ? language.translate('application.completedFor') : null}
                              {(action.ActionTrigger_Trigger === 'Target Date Proximity') ? language.translate('application.trigger') : null}
                              {(action.ActionTrigger_Trigger === 'Task Due Date Proximity') ? language.translate('application.trigger') : null}
                              {(action.ActionTrigger_Trigger === 'Field Date Proximity') ? language.translate('application.trigger') : null}
                            </div>
                            <TextField
                              type='tel'
                              name='trigger_minutes'
                              inputRef={(ref) => {this.trigger_minutes = ref}}
                              style={{ width: '30px', marginLeft: '5px' }}
                              defaultValue={Math.abs((triggerPivot === 'days') ? action.ActionTrigger_TriggerMinutes / 1440 : (triggerPivot === 'hours') ? action.ActionTrigger_TriggerMinutes / 60 : action.ActionTrigger_TriggerMinutes || 0)} // ActionTrigger_TriggerMinutes
                              onBlur={(e) => {
                                let triggerMinutes = this.convertToMinutes(e.currentTarget.value, triggerPivot)

                                if (['Task Due Date Proximity', 'Target Date Proximity', 'Field Date Proximity'].includes(action.ActionTrigger_Trigger) && targetDatePivot === 'before') { action.ActionTrigger_TriggerMinutes = 0 - triggerMinutes } else { action.ActionTrigger_TriggerMinutes = triggerMinutes }

                                onSave(action)
                              }}
                              fullWidth
                            />
                            <Select
                              name='trigger_pivot'
                              ref='trigger_pivot'
                              style={{ width: '100px', margin: '5px' }}
                              onChange={(e) => {
                                let value = e.target.value
                                this.setState({ triggerPivot: value })
                                let triggerMinutesField = this.trigger_minutes.value

                                if (['Task Due Date Proximity', 'Target Date Proximity', 'Field Date Proximity'].includes(action.ActionTrigger_Trigger) && targetDatePivot === 'before') { action.ActionTrigger_TriggerMinutes = 0 - this.convertToMinutes(triggerMinutesField, value) } else { action.ActionTrigger_TriggerMinutes = this.convertToMinutes(triggerMinutesField, value) }

                                onSave(action)
                              }}
                              value={triggerPivot}>
                              {[
                                <MenuItem value='minutes' key='minutes'>{language.translate('application.minute')}</MenuItem>,
                                <MenuItem value='hours' key='hours'>{language.translate('application.hour')}</MenuItem>,
                                <MenuItem value='days' key='days'>{language.translate('application.day')}</MenuItem>
                              ]}
                            </Select>
                            {(action.ActionTrigger_Trigger === 'Target Date Proximity' ||
                              action.ActionTrigger_Trigger === 'Task Due Date Proximity' ||
                              action.ActionTrigger_Trigger === 'Field Date Proximity')
                              ? <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Select
                                  name='target_pivot'
                                  ref='target_pivot'
                                  style={{ width: '80px', margin: '5px' }}
                                  onChange={(e) => {
                                    let value = e.target.value
                                    this.setState({ targetDatePivot: value })
                                    let triggerMinutes = this.convertToMinutes(this.trigger_minutes.value, triggerPivot)

                                    if (value === 'before') { action.ActionTrigger_TriggerMinutes = 0 - triggerMinutes } else { action.ActionTrigger_TriggerMinutes = triggerMinutes }

                                    onSave(action)
                                  }}
                                  value={targetDatePivot}>
                                  {[
                                    <MenuItem value='before'
                                              key='before'>{language.translate('application.before').lcFirst()}</MenuItem>,
                                    <MenuItem value='after'
                                              key='after'>{language.translate('application.after').lcFirst()}</MenuItem>
                                  ]}
                                </Select>
                                <div>
                                  date
                                </div>
                              </div>
                              : null}
                          </div>
                          : null}
                        {(action.ActionTrigger_Trigger === 'Task Past Due' ||
                          action.ActionTrigger_Trigger === 'Task Pending For' ||
                          action.ActionTrigger_Trigger === 'Process Pending For' ||
                          action.ActionTrigger_Trigger === 'Task Completed For')
                          ? <div>
                            <div style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              margin: '0px 5px 5px 6px',
                              fontSize: '16px'
                            }}>
                              <div>
                                {language.translate('application.repeatActionEvery')}
                              </div>
                              <FormsyText
                                type='tel'
                                name='repeat_minutes'
                                ref='repeat_minutes'
                                required={(action.ActionTrigger_RepeatTimes > 0)}
                                style={{ width: '30px', marginLeft: '5px' }}
                                inputStyle={{ textAlign: 'center' }}
                                defaultValue={(repeatPivot === 'days') ? action.ActionTrigger_RepeatMinutes / 1440 : (repeatPivot === 'hours') ? action.ActionTrigger_RepeatMinutes / 60 : action.ActionTrigger_RepeatMinutes || 0}
                                onBlur={(e) => {
                                  if (repeatPivot === 'days') { action.ActionTrigger_RepeatMinutes = parseInt(e.currentTarget.value) * 1440 }

                                  if (repeatPivot === 'hours') { action.ActionTrigger_RepeatMinutes = parseInt(e.currentTarget.value) * 60 }

                                  if (repeatPivot === 'minutes') { action.ActionTrigger_RepeatMinutes = parseInt(e.currentTarget.value) }

                                  onSave(action)
                                }}
                                fullWidth
                                validationErrors={language.messages.validationErrors}
                                errorStyle={{ display: 'none' }}
                              />
                              <Select
                                name='repeat_pivot'
                                ref='repeat_pivot'
                                style={{ width: '110px', margin: '5px' }}
                                onChange={(e) => {
                                  let value = e.target.value
                                  this.setState({ repeatPivot: value })

                                  if (value === 'days') { action.ActionTrigger_RepeatMinutes = 1440 * parseInt(this.refs['repeat_minutes'].getValue()) }

                                  if (value === 'hours') { action.ActionTrigger_RepeatMinutes = 60 * parseInt(this.refs['repeat_minutes'].getValue()) }

                                  if (value === 'minutes') { action.ActionTrigger_RepeatMinutes = parseInt(this.refs['repeat_minutes'].getValue()) }

                                  onSave(action)
                                }}
                                value={repeatPivot}>
                                {[
                                  <MenuItem value='minutes'
                                            key='minutes'>{language.translate('application.minute')}</MenuItem>,
                                  <MenuItem value='hours'
                                            key='hours'>{language.translate('application.hour')}</MenuItem>,
                                  <MenuItem value='days' key='days'>{language.translate('application.day')}</MenuItem>
                                ]}
                              </Select>
                            </div>
                            <div style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              margin: '0px 5px 5px 6px',
                              fontSize: '16px'
                            }}>
                              <div>
                                {language.translate('application.repeat')}
                              </div>
                              <FormsyText
                                type='tel'
                                name='repeat_times'
                                ref='repeat_times'
                                required={(action.ActionTrigger_RepeatMinutes > 0)}
                                style={{ width: '50px', marginLeft: '5px', marginRight: '5px' }}
                                defaultValue={action.ActionTrigger_RepeatTimes || 0}
                                inputStyle={{ textAlign: 'center' }}
                                onBlur={(e) => {
                                  action.ActionTrigger_RepeatTimes = parseInt(e.currentTarget.value)

                                  onSave(action)
                                }}
                                fullWidth
                                validationErrors={language.messages.validationErrors}
                                validations={(action.ActionTrigger_RepeatMinutes > 0) ? 'isMoreThan:0' : null}
                                errorStyle={{ display: 'none' }}
                              />
                              {language.translate('application.times').lcFirst()}
                            </div>
                          </div>
                          : null}
                        {(action.ActionTrigger_Trigger === 'Task Pending For' ||
                          action.ActionTrigger_Trigger === 'Process Pending For' ||
                          action.ActionTrigger_Trigger === 'Task Completed For' ||
                          action.ActionTrigger_Trigger === 'Task Past Due' ||
                          action.ActionTrigger_Trigger === 'Target Date Proximity' ||
                          action.ActionTrigger_Trigger === 'Task Due Date Proximity' ||
                          action.ActionTrigger_Trigger === 'Field Date Proximity')
                          ? 
                          <div>
                            <div
                              style={{
                                display: 'flex',
                                fontSize: '14px'
                              }}>
                              <div style={{
                                marginRight: '20px',
                                marginLeft: '6px',
                                fontSize: '16px'
                              }}>{language.translate('application.onlyOn')}</div>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {daysOfTheWeek.map((day) => {
                                  let dayUCFirst = day.charAt(0).toUpperCase() + day.substring(1)

                                  return (
                                    <FormControl
                                      key={day}
                                    >
                                      <FormControlLabel
                                        key='count_check'
                                        label={language.translate('calendar.weekdays.' + day)}
                                        control={<Checkbox
                                          color='primary'
                                          style={{ paddingTop: '2px', paddingBottom: '2px' }}
                                          checked={action['ActionTrigger_On' + dayUCFirst] || false}
                                          onChange={(e, isChecked) => {
                                            action['ActionTrigger_On' + dayUCFirst] = isChecked
                                            onSave(action)
                                          }}
                                          name={day}
                                        />} />
                                    </FormControl>)
                                })}
                              </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <TimePicker
                                name='start_time'
                                textFieldStyle={{ width: '180px' }}
                                pedantic
                                defaultTime={(action.ActionTrigger_StartTime) ? moment(action.ActionTrigger_StartTime, (action.ActionTrigger_StartTime < 100) ? 'mm' : 'Hmm').toDate() : undefined}
                                floatingLabelText={language.translate('application.startingAt')}
                                onChange={(event, value) => {
                                  action.ActionTrigger_StartTime = parseInt(moment(value).format('HHmm'))
                                  onSave(action)
                                }}
                              />
                              <TimePicker
                                name='end_time'
                                textFieldStyle={{ width: '180px' }}
                                pedantic
                                defaultTime={(action.ActionTrigger_EndTime) ? moment(action.ActionTrigger_EndTime, (action.ActionTrigger_EndTime < 100) ? 'mm' : 'Hmm').toDate() : undefined}
                                floatingLabelText={language.translate('application.endingAt')}
                                onChange={(event, value) => {
                                  action.ActionTrigger_EndTime = parseInt(moment(value).format('HHmm'))
                                  onSave(action)
                                }}
                              />
                            </div>
                            <Select
                              name='work_schedule'
                              //ref='work_schedule'
                              value={action.ActionTrigger_OnWorkScheduleID || false}
                              //floatingLabelText={language.translate('application.workSchedule')}
                              onChange={(e) => {
                                action.ActionTrigger_OnWorkScheduleID = e.target.value
                                onSave(action)
                              }}
                              //inputStyle={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                              fullWidth
                              //validationErrors={language.messages.validationErrors}
                            >
                              {this.workScheduleMenu()}
                            </Select>

                          </div>
                          : null}
                      </Form>
                    </StepContent>
                  </Step>
                  <Step>
                    <StepButton onClick={() => this.setState({ stepIndex: 2 })}>
                      <strong>{language.translate('application.selectConditionsOptional')}</strong>
                    </StepButton>
                    <StepContent>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}>
                        <div>{language.translate('application.conditionsToSatisfy')}:</div>
                        <Select
                          name='conditions_satisfied'
                          style={{ width: '60px', margin: '5px' }}
                          onChange={(e) => {
                            action.Action_ConditionsSatisfied = e.target.value
                            onSave(action)
                          }}
                          value={action.Action_ConditionsSatisfied}>
                          {[
                            <MenuItem value='ALL' key='all'>{language.translate('application.all')}</MenuItem>,
                            <MenuItem value='ANY' key='any'>{language.translate('application.any')}</MenuItem>
                          ]}
                        </Select>
                      </div>
                      {(conditionsLoaded)
                        ? <ActionConditionList
                          templateId={action.ProcessTemplateID}
                          actionId={action.ID}
                          actionConditionList={conditionList}
                          dispatch={dispatch}
                          conditionTypes={conditionTypes}
                          processFieldList={templateFields}
                          userFieldList={userFieldList}
                          templateTasks={templateTasks}
                          onRequestTaskResponses={onRequestTaskResponses}
                          onSaveList={this.saveActionConditionList.bind(this)}
                          onCopy={this.copyActionConditionListItem.bind(this)}
                          onDelete={this.deleteActionConditionListItem.bind(this)}
                          getInstanceFieldOptions={this.getInstanceFieldOptions.bind(this)}
                        />
                        : null}
                    </StepContent>
                  </Step>
                  <Step disabled={(!action.IntegrationID)}>
                    <StepButton onClick={() => this.setState({ stepIndex: 3 })}>
                      <strong>{(!action.IntegrationID) ? language.translate('application.selectAnActionType') : integrations.filter((integration) => (integration.ID === action.IntegrationID))[0].Description + ' ' + language.translate('application.action')}</strong>
                    </StepButton>
                    <StepContent>
                      <Form onValid={this.enableButton.bind(this)}
                            onInvalid={this.disableButton.bind(this)}>
                        {/* SHOW HE ZAPIER INFO IF THAT IS THE TYPE THAT WAS CHOSEN */}
                        {(action.IntegrationID === -4)
                          ? <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                          }}>
                            <div style={{ textAlign: 'center' }}>
                              {language.translate('application.zapierActionConfiguration')}
                            </div>
                            <Button
                              href='https://zapier.com/app/dashboard/'
                              target='_blank'
                              style={{ margin: '10px 0px', fontSize: '18px', fontWeight: '600', color: '#ff5c1a' }}
                            >
                              {language.translate('application.goToZapier')}
                              <Icon icon='ZP' size={30} color='#ff5c1a' />
                            </Button>
                          </div>
                          : null}

                        {/* SHOW THE INTEGRATION ACTION TYPE SELECT FIELD IF THE ACTION TYPE HAS MULTIPLE INTEGRATION TYPES */}
                        {(actionTypeList.length > 1)
                          ? <FormsySelect
                            value={action.IntegrationActionTypeID || ''}
                            floatingLabelText={language.translate('application.actionType')}
                            onChange={this.handleActionSubTypeChange.bind(this)}
                            inputStyle={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                            name='action_type'
                            required
                            fullWidth
                            validationErrors={language.messages.validationErrors}>
                            {this.actionTypeMenu()}
                          </FormsySelect>
                          : null}

                        {/* DISPLAY THE FIELDS FOR CONFIGURING THE ACTION PROPERTIES */}
                        {(actionProperties.length && tokensLoaded)
                          ? actionProperties.map(function (property) {
                            let dependencyValue = null

                            if (property.ParentFieldTypeIdentifier) {
                              const dependencyFields = actionProperties.filter(tempField => (tempField.FieldTypeIdentifier === property.ParentFieldTypeIdentifier))
                              dependencyValue = (dependencyFields.length) ? dependencyFields[0].PropertyValue : null
                            }
                            return (
                              <PropertyComponent
                                key={property.ID}
                                actionID={action.ID}
                                property={property}
                                tokens={tokens}
                                onSave={this.handlePropertyInputChange.bind(this)}
                                screenHeight={screenHeight}
                                dependencyValue={dependencyValue}
                              />)
                          }.bind(this))
                          : null}
                      </Form>
                    </StepContent>
                  </Step>
                </Stepper>
              </div>}
          </Card>
        </div>
      </RightPanelContent>
    )
  }
}

EditAction.propTypes = {
  action: PropTypes.object,
  integrations: PropTypes.array,
  triggers: PropTypes.array,
  templateTasks: PropTypes.array,
  responses: PropTypes.array,
  templateFields: PropTypes.array,
  userFieldList: PropTypes.array,
  publicForms: PropTypes.array,
  dataSources: PropTypes.array,
  onRequestTaskResponses: PropTypes.func,
  getRelatedTemplates: PropTypes.func.isRequired,
  getTasks: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  showZapierDialog: PropTypes.func,
  get: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  conditionTypes: PropTypes.array,
  dispatch: PropTypes.func.isRequired,
  showUndoMessage: PropTypes.func.isRequired,
  screenHeight: PropTypes.number
}

EditAction.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default EditAction
