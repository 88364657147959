import PropTypes from 'prop-types'
import React from 'react'
import { IconButton, ListItem, ListItemSecondaryAction, ListItemText, Menu, MenuItem } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import FormHelper from '~/businessLogic/formHelper'

class RecurringScheduleListItem extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      value: null,
      item: null,
      menuAnchor: null
    }
  }

  componentDidMount () {
    this.setState({ item: this.props.item })
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.item !== prevProps.item) { this.setState({ item: this.props.item }) }
  }

  splitOnUppercase (string) {
    let newString = ''

    const stringArray = string.split(/(?=[A-Z])/)

    stringArray.map((word, index) => {
      newString += word

      if (stringArray.length - 1 > index) { newString += ' ' }
    })

    return newString
  }

  render () {
    const { language } = this.context
    const { pushToRecurring, enabled, item, onRemoveField, ...rest } = this.props
    const scheduledItem = this.state.item
    const { menuAnchor } = this.state

    if (!scheduledItem) { return null }

    return (
      <ListItem
        {...rest}
        value={scheduledItem.ID}
      >
        <ListItemText
          primary={scheduledItem.Recurring_RepeatType.splitOnUppercase() || language.translate('application.noRepeatType')}
          secondary={(scheduledItem.HeaderField1_Name)
            ? `${FormHelper.decodeHTML(scheduledItem.HeaderField1_Name)
              }: ${
              FormHelper.decodeHTML(scheduledItem.HeaderField1_Value)}` : null}
        />
        <ListItemSecondaryAction>
          <IconButton
            onClick={(e) => {
              this.setState({
                menuAnchor: e.currentTarget
              })
            }}><MoreVert /></IconButton>
          <Menu
            anchorEl={menuAnchor}
            open={Boolean(menuAnchor)}
            onClose={() => { this.setState({ menuAnchor: null }) }}
            getContentAnchorEl={null}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          >
            {(pushToRecurring)
              ? <MenuItem
                onClick={() => {
                  this.setState({ menuAnchor: null })
                  pushToRecurring(scheduledItem.ID)
                }}
              >{language.translate('application.edit')}</MenuItem>
              : null}
            <MenuItem onClick={() => {
              this.setState({ menuAnchor: null })
              this.props.onRemoveField()
            }}>{language.translate('application.cancel')}</MenuItem>
          </Menu>
        </ListItemSecondaryAction>
      </ListItem>
    )
  }
}

RecurringScheduleListItem.propTypes = {
  item: PropTypes.object.isRequired,
  onRemoveField: PropTypes.func.isRequired,
  pushToRecurring: PropTypes.func,
  enabled: PropTypes.bool
}

RecurringScheduleListItem.contextTypes = {
  language: PropTypes.object
}

export default RecurringScheduleListItem
