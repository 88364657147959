import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link } from 'react-router'
import { List } from '@material-ui/core'

class ReorderableList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      dndConstraint: this.props.dndConstraint || (Math.random() + 1).toString()
    }
  }

  render () {
    const { children, ...props } = this.props
    const { dndConstraint } = this.state
    return (
      <div style={{ width: '100%' }}>
        <List {...props}>
          {React.Children.map(children, (child) => {
            if (['div', Link].includes(child.type)) return child
            return React.cloneElement(child, { listIdentifier: dndConstraint })
          })}
        </List>
      </div>
    )
  }
}

ReorderableList.propTypes = {
  dndConstraint: PropTypes.string,
  children: PropTypes.array
}

export default ReorderableList
