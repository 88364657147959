import PropTypes from 'prop-types'
import React from 'react'
import FormHelper from '../../businessLogic/formHelper'
import ReorderableListItem from '../Layout/ReorderableListItem'
import { IconButton, Menu, MenuItem, TextField } from '@material-ui/core'
import { Check, MoreVert } from '@material-ui/icons'
import DeleteMenuItem from '../Layout/DeleteMenuItem'

class CustomFieldItemOption extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      editMode: false,
      value: '',
      metaData: {},
      menuAnchor: null

    }
  }

  render () {
    const { language, muiTheme } = this.context
    const { option, index, onReorderRequest, droppedItem, onDelete, onChange, style, type, ...rest } = this.props
    const { editMode, value, metaData, menuAnchor } = this.state

    return (
      <ReorderableListItem
        style={style}
        primaryText={
          <div style={{ width: '180px' }}>
            {(editMode)
              ? <div>
                <TextField
                  fullWidth
                  onChange={(event) => {
                    this.setState({ value: event.target.value })
                  }}
                  name={`${option.ID}-edit-option`}
                  value={value}
                />
                {(type === 'GRID')
                  ? <div style={{ paddingTop: '10px' }}>
                    <TextField
                      fullWidth
                      onChange={(event) => {
                        metaData.width = parseInt(event.target.value)
                        this.setState({ metaData })
                      }}
                      style={{ width: '50px', marginRight: '10px' }}
                      label='Width'
                      name={`${option.ID}-width`}
                      type='tel'
                      value={metaData.width}
                    />
                    px
                  </div>
                  : null}
              </div>
              : FormHelper.decodeHTML(option.OptionDescription)}
          </div>}
        index={index}
        rightIconButton={
          (editMode)
            ? <IconButton
              disabled={(!value)}
              onClick={() => {
                option.OptionDescription = value
                option.MetaData = JSON.stringify(metaData)
                onChange(option)
                this.setState({ editMode: false })
              }}
            >
              <Check nativeColor={muiTheme.palette.primary1Color} />
            </IconButton>
            : <div>
              <IconButton onClick={(e) => { this.setState({ menuAnchor: e.currentTarget }) }}><MoreVert /></IconButton>
              <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={() => { this.setState({ menuAnchor: null }) }}
                getContentAnchorEl={null}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              >
                <MenuItem
                  onClick={() => this.setState({
                    editMode: true,
                    value: option.OptionDescription,
                    metaData: JSON.parse(option.MetaData || '{}'),
                    menuAnchor: null
                  })}
                >{language.translate('application.edit')}</MenuItem>
                <DeleteMenuItem
                  onDelete={() => {
                    this.setState({ menuAnchor: null })
                    onDelete(index)
                  }}
                />
              </Menu>
            </div>
        }
        id={option.ID}
        moveItem={data => onReorderRequest(data.dragIndex, data.hoverIndex)}
        droppedItem={droppedItem}
        {...rest}
      />
    )
  }
}

CustomFieldItemOption.propTypes = {
  option: PropTypes.object.isRequired,
  style: PropTypes.object,
  type: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  droppedItem: PropTypes.func,
  index: PropTypes.number,
  onReorderRequest: PropTypes.func,
  onChange: PropTypes.func
}

CustomFieldItemOption.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object,
  user: PropTypes.object
}

export default CustomFieldItemOption
