import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import {
  Button, Dialog, DialogContent, DialogTitle, FormControlLabel, Radio, RadioGroup, TextField, Typography
} from '@material-ui/core'
import { Form } from 'formsy-react'
import CancelButton from '../Forms/CancelButton'
import FormsyTemplateAutoComplete from '../Forms/FormsyTemplateAutoComplete'

class MapSubProcessDialog extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      accountOptionSelected: 'my_account',
      selectedTemplate: ''
    }
  }

  createMigrationPlan () {
    const ProcessTemplateMigrationPlan = {}

    if (this.state.accountOptionSelected === 'my_account') {
      ProcessTemplateMigrationPlan.ToProcessTemplateID = this.state.selectedTemplate
    } else {
      ProcessTemplateMigrationPlan.ToProcessTemplateLaunchCode = this.state.selectedTemplate
    }

    this.props.onSubmit(ProcessTemplateMigrationPlan)
    this.closeDialog()
  }

  closeDialog () {
    this.setState({
      accountOptionSelected: 'my_account',
      selectedTemplate: ''
    })

    this.props.close()
  }

  render () {
    const { accountOptionSelected, selectedTemplate } = this.state
    const { open } = this.props
    const palette = this.context.muiTheme.palette
    const { language } = this.context

    return (
      <Dialog
        open={open}
        onClose={this.closeDialog.bind(this)}
      >
        <DialogTitle
          style={{
            backgroundColor: palette.headerBackgroundColor
          }}
          disableTypography
        >
          <Typography
            variant='h6'
            style={{ color: palette.alternateTextColor }}>
            {language.translate('application.linkedProcesses')}
          </Typography>
        </DialogTitle>
        <DialogContent style={{ width: '300px' }}>
          <div style={{ paddingTop: '20px' }}>
            <div
              style={{
                fontSize: '18px',
                marginBottom: '10px',
                fontWeight: 700
              }}
            >
              {language.translate('application.launchAnotherProcess')}
            </div>
            <RadioGroup
              aria-label='Which Account'
              name='whichAccount'
              value={accountOptionSelected}
              onChange={(e) => {
                this.setState({
                  accountOptionSelected: e.target.value,
                  selectedTemplate: ''
                })
              }}
            >
              <FormControlLabel
                value='my_account'
                control={<Radio color='primary' style={{ paddingTop: '4px', paddingBottom: '4px' }} />}
                label={language.translate('application.inMyAccount')} />
              <FormControlLabel
                value='another_account'
                control={<Radio color='primary' style={{ paddingTop: '4px', paddingBottom: '4px' }} />}
                label={language.translate('application.inAnotherAccount')} />
            </RadioGroup>
            {(accountOptionSelected)
              ? <div>
                {(accountOptionSelected === 'my_account')
                  ? <Form
                    style={{ display: 'flex', flexDirection: 'column' }}
                    autoComplete='off'
                  >
                    <FormsyTemplateAutoComplete
                      required
                      templateId={selectedTemplate}
                      name='template'
                      floatingLabelText={language.translate('application.selectTemplate')}
                      onTemplateChange={(selectedId) => { if (selectedId) { this.setState({ selectedTemplate: selectedId }) } }}
                      sortString='Title ASC'
                    />
                  </Form>
                  : <TextField
                    name='template_code'
                    style={{ marginBottom: '10px' }}
                    label={language.translate('application.processLaunchCode')}
                    onChange={(e) => {
                      this.setState({ selectedTemplate: e.currentTarget.value })
                    }}
                  />}
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}
                >
                  <CancelButton
                    onClick={this.closeDialog.bind(this)}
                    variant='contained'
                  />
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={this.createMigrationPlan.bind(this)}
                    disabled={(!selectedTemplate)}
                  >
                    {language.translate('application.submit')}
                  </Button>
                </div>
              </div>
              : null
            }
          </div>
        </DialogContent>
      </Dialog>
    )
  }
}

MapSubProcessDialog.propTypes = {
  dispatch: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
}

MapSubProcessDialog.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(MapSubProcessDialog)
