import PropTypes from 'prop-types'
import React from 'react'
import { Chip } from '@material-ui/core'
import objectAssign from 'object-assign'
import { darken } from '@material-ui/core/styles/colorManipulator'

class TagCloud extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      max: Math.max(...this.getCounts())
    }
  }

  componentDidUpdate (prevProps) {
    if (JSON.stringify(prevProps.tags) !== JSON.stringify(this.props.tags)) {
      this.setState({
        max: Math.max(...this.getCounts())
      })
    }
  }

  // returns an array of all Count properties
  getCounts () {
    const {tags} = this.props

    return tags.map((tag) => tag.Count)
  }

  render () {
    const {language, muiTheme: {palette}} = this.context
    const {tags, onTagClick, containerStyle, title} = this.props
    const {max} = this.state

    const cardStyle = objectAssign({}, {marginBottom: '20px'}, containerStyle)

    return (
      <div
        style={cardStyle}
      >
        <div style={{fontSize: '18px', fontWeight: 600, color: palette.accent4Color, margin: '10px 5px'}}>
          {title}
        </div>
        <div style={{display: 'flex', flexWrap: 'wrap'}}>
          {(tags.map((tag) => {
            const bgPercentage = (tag.Count / max) * 0.4
            return (<Chip
              key={tag.ID}
              onClick={() => onTagClick(tag)}
              style={{
                margin: '2px',
                backgroundColor: darken(palette.primary3Color, bgPercentage)
              }}
              label={<div style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                maxWidth: '150px',
                color: palette.alternateTextColor
              }}>
                {`${tag.TagName}`}
              </div>}
            />)
          }))}
        </div>
      </div>
    )
  }
}

TagCloud.propTypes = {
  tags: PropTypes.array.isRequired,
  onTagClick: PropTypes.func,
  containerStyle: PropTypes.object,
  title: PropTypes.string.isRequired
}

TagCloud.contextTypes = {
  muiTheme: PropTypes.object,
  location: PropTypes.object,
  language: PropTypes.object,
  user: PropTypes.object
}

export default TagCloud
