import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Button, Card, CardContent, CardHeader, Table, TableBody, TableCell, TableHead, TableRow
} from '@material-ui/core'
import { updateTitle } from '../actions/application'
import DataList from '../components/Layout/DataList'
import { push } from 'react-router-redux'
import moment from 'moment'
import objectAssign from 'object-assign'
import FilterPanel from '../components/AuditTrail/FilterPanel'
import DateHelper from '../businessLogic/dateHelper'

class AuditTrail extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      items: [],
      filterPanelOpen: false
    }
  }

  componentDidMount () {
    this.updateTitle()
  }

  componentDidUpdate (prevProps) {
    this.updateTitle()

    if (this.props.location.query.endpoint !== prevProps.location.query.endpoint) { this.setState({ items: [] }) }
  }

  updateTitle () {
    const { language } = this.context

    this.props.dispatch(updateTitle(language.translate('application.auditTrail')))
  }

  dataLoadCallback (data) {
    this.setState({
      items: data
    })
  }

  itemSelect (item) {
    // When selecting an item on the audit list, determine what info needs
    // to be shown based on that item and direct the user there
    if (item.ProcessInstanceTaskID) {
      this.props.push({
        pathname: this.props.location.pathname,
        query: objectAssign({}, this.props.location.query, {
          ptype: 'task',
          task: item.ProcessInstanceTaskID,
          pid: item.ProcessInstanceTaskID,
          sid: item.ID
        })
      })
      return
    }

    if (item.ProcessInstanceID) {
      this.props.push(`process-visual-progress/${item.ProcessInstanceID}`)
      return
    }

    if (item.ProcessTemplateID) {
      this.props.push(`process-template/${item.ProcessTemplateID}`)
      return
    }

    if (item.UserGroupID) {
      this.props.push({
        pathname: this.props.location.pathname,
        query: objectAssign({}, this.props.location.query, {
          ptype: 'group',
          pid: item.UserGroupID,
          sid: item.UserGroupID
        })
      })
      return
    }

    if (item.UserID) {
      this.props.push({
        pathname: this.props.location.pathname,
        query: objectAssign({}, this.props.location.query, {
          ptype: 'user',
          pid: item.UserID,
          sid: item.UserID
        })
      })
      return
    }

    if (item.IntegrationActionID && item.ProcessTemplateID) {
      this.props.push(`process-template/${item.ProcessTemplateID}`)
    }

    // TODO: add DataSourceID, IntegrationID
  }

  handleFilterChange (filter) {
    const { push, location } = this.props

    push({
      pathname: location.pathname,
      query: objectAssign({},
        location.query,
        {
          start: filter.startDate,
          end: filter.endDate
        })
    })
  }

  parseDateFilter () {
    const { start, end } = this.props.location.query
    const { user } = this.props
    let filter = ''

    if (start) { filter += `filter2=EntryDate_LOCAL(GREATERTHANOREQUALTO_DATE)${DateHelper.formatForAPI(start, `${user.dateFormat} HH:mm`)}` }

    if (start && end) { filter += '&' }

    if (end) { filter += `filter3=EntryDate_LOCAL(LESSTHANOREQUALTO_DATE)${DateHelper.formatForAPI(end, `${user.dateFormat} HH:mm`, false)}` }

    return filter
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const { location, mainContentWidth } = this.props
    const { query } = location
    const { filterPanelOpen } = this.state
    const dateFilter = this.parseDateFilter()

    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            style={{ margin: '10px', backgroundColor: palette.canvasColor }}
            variant='contained'
            onClick={() => this.setState({ filterPanelOpen: true })}
          >
            {language.translate('application.filter', [], true)}
          </Button>
          <div style={{ color: palette.accent4Color }}>
            {(query.start) ? `${language.translate('application.startingOn')} ${query.start}` : null} {(query.end) ? ` | ${language.translate('application.endingOn')} ${query.end}` : null}
          </div>
        </div>
        <DataList
          url={query.endpoint}
          dataCallback={this.dataLoadCallback.bind(this)}
          responseProperty='AuditEntryList'
          filterableColumns={[{
            name: language.translate('application.processTitle'),
            property: 'ProcessTemplateTitle'
          }, {
            name: language.translate('application.processField', [], true),
            property: 'ProcessInstance_HeaderFieldAll_Value'
          }, {
            name: language.translate('application.oldestPendingTaskOwner'),
            property: 'OldestPendingTask_AssignedTo_Name'
          }, {
            name: language.translate('application.taskTitle'),
            property: 'ProcessTemplateTaskText'
          }, {
            name: language.translate('application.userGroup'),
            property: 'UserGroupName'
          }, {
            name: language.translate('application.automatedActionTitle'),
            property: 'ActionDescription'
          }, {
            name: language.translate('application.processTemplateResponseText'),
            property: 'ProcessTemplateTaskResponseText'
          }, {
            name: `${language.translate('application.user')}: ${language.translate('application.fullName')}`,
            property: 'UserFullName'
          }, {
            name: `${language.translate('application.externalDataSource')}: ${language.translate('application.description')}`,
            property: 'ExternalDataSourceDescription'
          }, {
            name: language.translate('application.automatedActionIntegration'),
            property: 'IntegrationActionPurpose'
          }, {
            name: language.translate('application.newValue'),
            property: 'NewValue'
          }, {
            name: language.translate('application.entryByUser'),
            property: 'EntryBy_UserFullName'
          }]}
          noDataText={language.translate('application.nothingToShow')}
          filterText={dateFilter}
          location={location}
        >
          <Card style={{ width: '100%' }}>
            <CardHeader title={query.title} />
            <CardContent style={{ borderTop: `1px solid ${palette.borderColor}` }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: '50%' }}>
                      <h4>{language.translate('application.object')}</h4></TableCell>
                    <TableCell><h4>{language.translate('application.action')}</h4>
                    </TableCell>
                    {(mainContentWidth > 750 &&
                      <TableCell style={{ width: '200px' }}>
                        <h4>
                          {language.translate('application.performedBy')}
                        </h4>
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>

                  {(!this.state.items.length)
                    ? <TableRow>
                      <TableCell>
                        {language.translate('application.noAuditTrail')}
                      </TableCell>
                    </TableRow>
                    : null}

                  {this.state.items.map(item => (
                    <TableRow
                      key={item.ID}
                      className='clickable'
                      onClick={this.itemSelect.bind(this, item)}
                      selected={(item.ID === query.sid)}
                    >
                      <TableCell style={{ whiteSpace: 'normal', width: '50%' }}>
                        {(item.ProcessTemplateTitle)
                          ? <div>
                            <strong>{language.translate('application.processTitle')}: </strong>{item.ProcessTemplateTitle}
                          </div> : null}
                        {(item.ProcessTemplateTaskText)
                          ? <div>
                            <strong>{language.translate('application.task')}: </strong>{item.ProcessTemplateTaskText}
                          </div> : null}
                        {(item.DisplayFieldName)
                          ? <div>
                            <strong>{language.translate('application.field')}: </strong>{item.DisplayFieldName}
                          </div> : null}
                        {(item.ProcessInstance_HeaderField1_Value)
                          ?
                          <div>{`${item.ProcessInstance_HeaderField1_Name}: ${item.ProcessInstance_HeaderField1_Value}`}</div> : null}
                        {(item.ProcessInstance_HeaderField2_Value)
                          ?
                          <div>{`${item.ProcessInstance_HeaderField2_Name}: ${item.ProcessInstance_HeaderField2_Value}`}</div> : null}
                        {(item.ProcessInstance_HeaderField3_Value)
                          ?
                          <div>{`${item.ProcessInstance_HeaderField3_Name}: ${item.ProcessInstance_HeaderField3_Value}`}</div> : null}
                        {(item.UserFullName)
                          ? <div><strong>{language.translate('application.userName')}: </strong>{item.UserFullName}
                          </div> : null}
                        {(item.UserGroupName)
                          ? <div><strong>{language.translate('application.groupName')}: </strong>{item.UserGroupName}
                          </div> : null}
                        {(item.IntegrationActionPurpose) ? <div>
                          <strong>{language.translate('application.actionPurpose')}: </strong>{item.IntegrationActionPurpose}
                        </div> : null}
                        {(item.IntegrationTypeDescription) ? <div>
                          <strong>{language.translate('application.typeDescription')}: </strong>{item.IntegrationTypeDescription}
                        </div> : null}
                        {(item.ExternalDataSourceDescription) ? <div>
                          <strong>{language.translate('application.dataSourceDescription')}: </strong>{item.ExternalDataSourceDescription}
                        </div> : null}
                      </TableCell>
                      <TableCell style={{ whiteSpace: 'normal' }}>
                        <div><strong>{item.ActionDescription}</strong></div>
                        {(item.OldValue || item.NewValue)
                          ? <div><strong>{language.translate('application.changedFrom')}: </strong>{item.OldValue}
                          </div> : null}
                        {(item.NewValue || item.OldValue)
                          ? <div><strong>{language.translate('application.changedTo')}: </strong>{item.NewValue}
                          </div> : null}
                        {(mainContentWidth <= 750 &&
                          <div>
                            <div><strong>{language.translate('application.by')}: </strong>{item.EntryBy_UserFullName}
                            </div>
                            <div>
                              <strong>{language.translate('application.on')}: </strong>{moment(item.EntryDate_Local).format('llll')}
                            </div>
                          </div>
                        )}
                      </TableCell>
                      {(mainContentWidth > 750 &&
                        <TableCell
                          style={{ whiteSpace: 'normal', width: '200px' }}
                        >
                          <div>{item.EntryBy_UserFullName}</div>
                          <div>{moment(item.EntryDate_Local).format('llll')}</div>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </DataList>
        <FilterPanel
          open={filterPanelOpen}
          close={() => this.setState({ filterPanelOpen: false })}
          startDate={query.start}
          endDate={query.end}
          onSubmit={this.handleFilterChange.bind(this)}
        />
      </div>
    )
  }
}

AuditTrail.propTypes = {
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.string,
  push: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  location: PropTypes.object,
  mainContentWidth: PropTypes.number.isRequired
}

AuditTrail.contextTypes = {
  muiTheme: PropTypes.object,
  language: PropTypes.object
}

const mapStateToProps = (state, ownProps) => ({
  title: state.application.title,
  user: state.auth,
  location: ownProps.location,
  mainContentWidth: state.application.mainContentWidth
})

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(AuditTrail)
