import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get } from '../../actions/base'
import { Card, CardContent, CardHeader, CircularProgress, Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import Masonry from 'react-masonry-component'
import BarCard from './BarCard'
import AreaCard from './AreaCard'

const masonryOptions = {
  transitionDuration: '0.0s'
}

class Template extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      templateId: props.templateId,
      averageCompletion: [],
      averageCompletionLoaded: false,
      executionCount: [],
      executionCountLoaded: false,
      taskCompletionTime: [],
      taskCompletionTimeLoaded: false,
      usersInitiating: [],
      usersInitiatingLoaded: false,
      instanceCount: null,
      instanceCountLoaded: false,
      template: null,
      templateLoaded: false,
      averageCompletionTime: null,
      averageCompletionTimeLoaded: false,
      tasksPending: [],
      tasksPendingLoaded: false
    }
  }

  componentDidMount () {
    this.fetchData()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.templateId && this.props.templateId !== prevProps.templateId) {
      this.setState({ templateId: this.props.templateId }, this.fetchData)
    }

    if (this.props.endDate !== prevProps.endDate ||
      this.props.startDate !== prevProps.startDate ||
      this.props.by !== prevProps.by ||
      this.props.time !== prevProps.time) {
      this.fetchData()
    }
  }

  fetchData () {
    const { startDate, endDate, by, time, templateId } = this.props
    const dateFilter = this.props.parseDateFilter(startDate, endDate)

    if (!templateId) {
      return
    }

    this.fetchTemplateData(`/report/averagecompletiontime/in${time}/by${by}${dateFilter}`, 'averageCompletion')
    this.fetchTemplateData(`/report/executioncount/by${by}${dateFilter}`, 'executionCount')
    this.fetchTemplateData(`/report/averageresponsetime/in${time}/bytask${dateFilter}`, 'taskCompletionTime')
    this.fetchTemplateData(`/report/executioncount/byuser${dateFilter}`, 'usersInitiating')
    this.fetchTemplateData(`/instance/list/count${dateFilter}`, 'instanceCount', 'Count')
    this.fetchTemplateData('', 'template', 'ProcessTemplate')
    this.fetchTemplateData(`/report/averagecompletiontime/in${time}${dateFilter}`, 'averageCompletionTime')
    this.fetchTemplateData('/report/taskspending/bytask', 'tasksPending')
  }

  fetchTemplateData (endpoint, stateAttribute, responseAttribute = 'ReportSeriesList') {
    const { templateId } = this.props

    this.setState({ [`${stateAttribute}Loaded`]: false })

    this.props.get(`processtemplate/${templateId}${endpoint}`, {
      onSuccess: (response) => {
        this.setState({
          [stateAttribute]: response[responseAttribute],
          [`${stateAttribute}Loaded`]: true
        })
      }
    })
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const {
      averageCompletion, averageCompletionLoaded, executionCount, executionCountLoaded, taskCompletionTime,
      taskCompletionTimeLoaded, usersInitiating, usersInitiatingLoaded, instanceCount, instanceCountLoaded,
      template, templateLoaded, averageCompletionTime, averageCompletionTimeLoaded, tasksPending,
      tasksPendingLoaded
    } = this.state
    const { mainContentWidth, time, handleUserClick, handleTaskClick } = this.props

    const styles = {
      cardStyles: {
        width: (mainContentWidth < 800) ? '95%' : '45%',
        margin: '10px'
      },
      processInfoTableRow: {
        border: 'none',
        height: '30px'
      },
      processInfoLabel: {
        textAlign: 'right',
        width: '180px',
        height: 'inherit',
        padding: '0px'
      },
      processInfoData: {
        height: 'inherit'
      }
    }

    let pendingTaskCount = 0
    tasksPending.map((task) => { pendingTaskCount += task.SeriesValue })

    return (
      <Masonry options={masonryOptions}>
        <Card style={styles.cardStyles}>
          <CardHeader
            title={language.translate('application.processInformation')}
          />
          <CardContent>
            {(templateLoaded && instanceCountLoaded && averageCompletionTimeLoaded)
              ? <Table>
                <TableBody>
                  <TableRow style={styles.processInfoTableRow}>
                    <TableCell style={styles.processInfoLabel}>
                      {language.translate('application.process')}:
                    </TableCell>
                    <TableCell style={styles.processInfoData}>
                      {template.Title}
                    </TableCell>
                  </TableRow>
                  <TableRow style={styles.processInfoTableRow}>
                    <TableCell style={styles.processInfoLabel}>
                      {language.translate('application.totalExecutions')}:
                    </TableCell>
                    <TableCell style={styles.processInfoData}>
                      {instanceCount}
                    </TableCell>
                  </TableRow>
                  <TableRow style={styles.processInfoTableRow}>
                    <TableCell style={styles.processInfoLabel}>
                      {language.translate('application.averageFinishTime')}:
                    </TableCell>
                    <TableCell style={styles.processInfoData}>
                      {(averageCompletionTime.length) ? `${averageCompletionTime[0].SeriesValue} (${time})` : 'no data'}
                    </TableCell>
                  </TableRow>
                  <TableRow style={styles.processInfoTableRow}>
                    <TableCell style={styles.processInfoLabel}>
                      {language.translate('application.currentPendingTasks')}:
                    </TableCell>
                    <TableCell style={styles.processInfoData}>
                      {pendingTaskCount}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              : <CircularProgress className='loader' />}
          </CardContent>
        </Card>

        <AreaCard
          title={language.translate('application.averageCompletionTimeIn', [time])}
          data={averageCompletion}
          isDataLoaded={averageCompletionLoaded}
          tooltipPrefix={time}
          mainContentWidth={mainContentWidth}
        />

        <BarCard
          title={language.translate('application.currentlyPendingTasksInProcess')}
          subtitle={language.translate('application.currentlyPendingTasksInProcessMessage')}
          data={tasksPending}
          isDataLoaded={tasksPendingLoaded}
          onClick={handleTaskClick}
          tooltipPrefix={language.translate('application.task', [], true)}
          mainContentWidth={mainContentWidth}
        />

        <AreaCard
          title={language.translate('application.processExecutions')}
          data={executionCount}
          isDataLoaded={executionCountLoaded}
          tooltipPrefix={language.translate('application.executions')}
          mainContentWidth={mainContentWidth}
          fillColor={palette.primary2Color}
        />

        <BarCard
          title={language.translate('application.averageTaskCompletionTimeIn', [time])}
          data={taskCompletionTime}
          isDataLoaded={taskCompletionTimeLoaded}
          onClick={handleTaskClick}
          tooltipPrefix={time}
          mainContentWidth={mainContentWidth}
        />

        <BarCard
          title={language.translate('application.usersInitiatingTheProcess')}
          data={usersInitiating}
          isDataLoaded={usersInitiatingLoaded}
          onClick={handleUserClick}
          tooltipPrefix={language.translate('application.executions')}
          mainContentWidth={mainContentWidth}
        />
      </Masonry>
    )
  }
}

Template.propTypes = {
  templateId: PropTypes.string,
  get: PropTypes.func.isRequired,
  mainContentWidth: PropTypes.number,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  by: PropTypes.string,
  time: PropTypes.string,
  parseDateFilter: PropTypes.func.isRequired,
  handleUserClick: PropTypes.func.isRequired,
  handleTaskClick: PropTypes.func.isRequired
}

Template.contextTypes = {
  muiTheme: PropTypes.object,
  language: PropTypes.object,
  user: PropTypes.object
}

const mapStateToProps = state => ({
  mainContentWidth: state.application.mainContentWidth
})

const mapDispatchToProps = dispatch => ({
  get: bindActionCreators(get, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Template)
