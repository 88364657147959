import PropTypes from 'prop-types'
import React from 'react'
import { Avatar } from '@material-ui/core'
import FormHelper from '../../businessLogic/formHelper'
import { get } from '../../actions/base'
import SelectField from './SelectField'

class GroupUserSelectFields extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      groupValue: this.props.groupValue || '',
      userValue: this.initUserValue(),
      groups: [],
      users: [],
      dynamicGroupId: ''
    }
  }

  componentDidMount () {
    if (this.props.withDynamic) { this.fetchGroupsWithDynamic() } else { this.fetchGroups() }

    if (this.state.groupValue && this.state.groupValue !== '0') { this.fetchGroupUsers(this.state.groupValue) }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.groupValue !== this.props.groupValue) {
      this.setState({ groupValue: this.props.groupValue },
        () => this.fetchGroupUsers(this.props.groupValue))
    }

    if (prevProps.userValue !== this.props.userValue) {
      this.setState({ userValue: this.initUserValue() })
    }
  }

  initUserValue () {
    const { userValue, allowAllUsers, groupValue } = this.props

    if (userValue) { return userValue }
    if (allowAllUsers && groupValue) { return '0' }

    return ''
  }

  fetchGroups () {
    this.props.dispatch(get('usergroup/list', {
      onSuccess: (response) => {
        this.setState({
          groups: response.UserGroupList
        })
      }
    }))
  }

  fetchGroupsWithDynamic () {
    this.props.dispatch(get('usergroup/list/withdynamicassigntogroup', {
      onSuccess: (response) => {
        this.setState({
          dynamicGroupId: response.UserGroupList[0].ID,
          groups: response.UserGroupList
        })
      }
    }))
  }

  fetchGroupUsers (groupID) {
    if (!groupID || groupID === '0') {
      return false
    }

    const { afterUsersLoaded } = this.props

    this.props.dispatch(get(`usergroup/${groupID}/user/list`, {
      onSuccess: (response) => {
        this.setState({
          users: response.UserList
        }, () => {
          if (afterUsersLoaded) {
            afterUsersLoaded(response.UserList)
          }
        })
      },

      headers: [],
      isHandshake: null,
      isSecondary: true
    }))
  }

  handleGroupChange (event, groupValue) {
    this.setState({ groupValue, userValue: (!groupValue) ? '' : '0' })

    this.fetchGroupUsers(groupValue)

    if (!groupValue) { groupValue = '0' }

    if (this.props.onGroupChange) { this.props.onGroupChange(groupValue) }
  }

  handleUserChange (event, userValue, index) {
    const { users } = this.state
    this.setState({ userValue })

    if (this.props.onUserChange) { this.props.onUserChange(userValue, users[index - 1]) }
  }

  getPicture (users, userID) {
    if (userID === '0') return 'https://res.cloudinary.com/dubj1ir57/image/upload/v1445263318/group.png'
    if (!userID) return 'https://res.cloudinary.com/dubj1ir57/image/upload/v1445263318/person.png'

    let picture = ''

    users.map((user) => {
      if (user.ID === userID) { picture = user.ProfilePicURL }
    })

    return picture
  }

  getValue () {
    return {
      UserGroupID: this.state.groupValue,
      UserID: this.state.userValue
    }
  }

  render () {
    const {
      label, required, allowAllUsers, groupValue, userValue, dispatch, onGroupChange, onUserChange,
      withDynamic, lockGroup, isSecondary, afterUsersLoaded, groupFieldName, allowAllGroups, userFieldName, handleChange,
      ...rest
    } = this.props
    const { dynamicGroupId } = this.state
    const { language } = this.context
    const userOptions = []

    const groupOptions = []

    if (allowAllGroups) {
      groupOptions.push({
        value: '',
        label: (this.state.groupValue === '') ? '' : language.translate('application.selectAGroup')
      })
    }

    if (allowAllUsers) {
      userOptions.push({
        value: '0',
        label: <div style={{ padding: '5px 0px 10px 5px' }}>All users in this group</div>,
        leftIcon: (this.state.groupValue === dynamicGroupId) ? null
          : <Avatar
            src={'https://res.cloudinary.com/dubj1ir57/image/upload/v1445263318/group.png'}
            style={{ height: '40px', width: '40px' }}
          />
      })
    }

    // Separate out ODS so a divider can be injected
    if (this.state.groups) {
      let userGroups = this.state.groups.filter((group) => (!group.IsOnDemand))
      let odsGroups = this.state.groups.filter((group) => (group.IsOnDemand))
      let odsGroupsGrouped = odsGroups.reduce((acc, group) => {
        const odsCompanyName = group.AccountID
        acc[odsCompanyName] = acc[odsCompanyName] || []
        acc[odsCompanyName].push(group)
        return acc
      }, {})

      userGroups.map((group) => {
        groupOptions.push({
          value: group.ID,
          label: group.GroupName
        })
      })

      if (odsGroups.length) {
        Object.keys(odsGroupsGrouped).sort().map((groupId, index) => {
          let groups = odsGroupsGrouped[groupId]

          groupOptions.push({
            value: 'ODS',
            label: `-- ${language.translate('application.servicesBy')} ${groups[0].AccountName} --`,
            disabled: true,
            style: { color: 'rgba(0,0,0,0.6)' }
          })

          groups.map((group) => {
            groupOptions.push({
              value: group.ID,
              label: group.GroupName
            })
          })
        })
      }
    }

    if (this.state.users) {
      this.state.users.map((user) => {
        userOptions.push({
          value: user.ID,
          label: <div style={{
            padding: '5px 0px 10px 5px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}>{user.FullName}</div>,
          leftIcon: (this.state.groupValue === dynamicGroupId) ? null
            : <Avatar src={user.ProfilePicURL} style={{ height: '40px', width: '40px' }} />
        })
      })
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }} {...rest}>
        <div
          className='field-label'
          style={{
            fontSize: '14px',
            paddingTop: '10px'
          }}
        >{FormHelper.decodeHTML(label)}{(required) ? '*' : ''}</div>
        <div>
          <SelectField
            name={groupFieldName}
            label={language.translate('application.group')}
            value={this.state.groupValue}
            options={groupOptions}
            disabled={lockGroup}
            required={required}
            onChange={(e, { props }) => {
              this.handleGroupChange(e, props.value)

              handleChange(e)
            }}
          />
          <br />
          {this.state.groupValue !== '0'
            ? <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div style={{ flex: 3, width: '100%' }}>
                <SelectField
                  name={userFieldName}
                  label={language.translate('application.user')}
                  value={this.state.userValue}
                  options={userOptions}
                  required={required}
                  onChange={(e, { props }) => {
                    this.handleUserChange(e, props.value)

                    handleChange(e)
                  }}
                />
              </div>
            </div>
            : ''
          }
        </div>
      </div>
    )
  }
}

GroupUserSelectFields.propTypes = {
  required: PropTypes.bool,
  groupValue: PropTypes.string,
  groupFieldName: PropTypes.string,
  userFieldName: PropTypes.string,
  userValue: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  withDynamic: PropTypes.bool,
  onGroupChange: PropTypes.func,
  onUserChange: PropTypes.func,
  handleChange: PropTypes.func,
  lockGroup: PropTypes.bool,
  allowAllUsers: PropTypes.bool,
  allowAllGroups: PropTypes.bool,
  isSecondary: PropTypes.bool,
  afterUsersLoaded: PropTypes.func
}

GroupUserSelectFields.contextTypes = {
  language: PropTypes.object
}

export default GroupUserSelectFields
