import grey from '@material-ui/core/colors/grey'
import blue from '@material-ui/core/colors/blue'
import { fade } from '@material-ui/core/styles/colorManipulator'

const white = '#fff'
const black = '#000'

export default {
  primary: {
    main: 'rgb(42, 158, 217)'
  },
  primary1Color: 'rgb(42, 158, 217)',
  primary2Color: 'rgb(243, 181, 39)',
  primary3Color: grey[300],
  accent1Color: 'rgb(34, 126, 174)',
  accent2Color: grey[100],
  accent3Color: grey[500],
  accent4Color: grey[600],
  accent5Color: 'rgb(91, 180, 186)',
  accent6Color: grey[700],
  accent7Color: grey[400],
  textColor: grey[800],
  alternateTextColor: white,
  canvasColor: white,
  borderColor: grey[300],
  disabledColor: fade(black, 0.3),
  pickerHeaderColor: blue[500],
  errorColor: 'rgb(242, 102, 89)',
  errorAccentColor: 'rgb(194, 82, 71)',
  warningColor: 'rgb(250, 165, 71)',
  warningAccentColor: 'rgb(200, 132, 57)',
  successColor: 'rgb(60, 184, 117)',
  successAccentColor: 'rgb(48, 147, 94)',
  navigationBackgroundColor: 'rgb(56,61,62)',
  navigationFontColor: white,
  headerBackgroundColor: 'rgb(42, 158, 217)',
  headerFontColor: white,
  test: white,
  testBg: grey[100]
}
