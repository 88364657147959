import PropTypes from 'prop-types'
import React from 'react'
import { MenuItem } from 'material-ui'

class DeleteMenuItem extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      confirm: false
    }
  }

  render () {
    const {language, muiTheme: { palette }} = this.context
    const {onDelete, deleteLabel} = this.props
    const {confirm} = this.state

    return (
      (confirm)
        ? <MenuItem style={{ backgroundColor: palette.errorColor, color: palette.alternateTextColor }} onClick={() => { onDelete() }}>
          {language.translate('application.confirm')}
        </MenuItem>
        : <MenuItem style={{ paddingRight: '10px' }} onClick={() => { this.setState({confirm: true}) }}>
          {deleteLabel || language.translate('application.delete')}
        </MenuItem>
    )
  }
}

DeleteMenuItem.propTypes = {
  onDelete: PropTypes.func.isRequired,
  deleteLabel: PropTypes.string
}

DeleteMenuItem.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default DeleteMenuItem
