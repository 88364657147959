import PropTypes from 'prop-types'
import React from 'react'
import { FormsySelect, FormsyText } from 'formsy-material-ui'
import { IconButton, Divider, ListItem, ListItemSecondaryAction, ListItemText, Menu, MenuItem } from '@material-ui/core'
import { MenuItem as OldMenuItem } from 'material-ui'
import { Check, MoreVert } from '@material-ui/icons'
import FormHelper from '~/businessLogic/formHelper'
import { Form } from 'formsy-react'
import FormsyUpload from '../Forms/FormsyUpload'
import { apiServer } from '~/config'
import DeleteMenuItem from '../Layout/DeleteMenuItem'
import DeleteIconButton from '../Layout/DeleteIconButton'

class TemplateTaskResourceListItem extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      editMode: false,
      canSubmit: true,
      item: null,
      resourceType: (this.props.item.ResourceFileName) ? 'file' : 'url',
      menuAnchor: null
    }
  }

  componentDidMount () {
    this.setState({ item: this.props.item })
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.item !== prevProps.item) { this.setState({ item: this.props.item }) }

    if (this.props.item.ResourceDescription === '' && !this.state.editMode) { this.setState({ editMode: true }) }

    if (this.props.item.ID !== prevProps.item.ID) { this.setState({ editMode: false }) }
  }

  enableButton () {
    this.setState({ canSubmit: true })
  }

  disableButton () {
    this.setState({ canSubmit: false })
  }

  parseImageUpload (event) {
    const { item } = this.state
    const files = event.target.files
    const reader = new window.FileReader()
    const me = this

    reader.onload = (function (file) {
      return function (e) {
        item.Base64EncodedFile = e.target.result.split(',')[1]
        item.ResourceFileName = file.name

        me.setState({ item }, () => me.props.onSave(item))
      }
    })(files[0])

    reader.readAsDataURL(files[0])
  }

  render () {
    const { language, muiTheme, user } = this.context
    const { onSave, onRemoveField } = this.props
    const { item, resourceType, menuAnchor } = this.state

    if (!item) { return null }

    return (
      (!this.state.editMode)
        ? <ListItem>
          <ListItemText
            primary={FormHelper.decodeHTML(item.ResourceDescription)}
            secondary={FormHelper.decodeHTML(item.ResourceFileName || item.ResourceURL)} />
          <ListItemSecondaryAction>
            <IconButton onClick={(e) => { this.setState({ menuAnchor: e.currentTarget }) }}><MoreVert /></IconButton>
            <Menu
              anchorEl={menuAnchor}
              open={Boolean(menuAnchor)}
              onClose={() => { this.setState({ menuAnchor: null }) }}
              getContentAnchorEl={null}
              anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
              <MenuItem
                onClick={() => this.setState({ editMode: true, menuAnchor: null })}
              >{language.translate('application.edit')}</MenuItem>
              <DeleteMenuItem
                onDelete={() => {
                  this.setState({ menuAnchor: null })
                  onRemoveField(item)
                }}
              />
            </Menu>
          </ListItemSecondaryAction>
        </ListItem>
        : <Form
          onValid={this.enableButton.bind(this)}
          onInvalid={this.disableButton.bind(this)}
        >
          <ListItem>
            <ListItemText primary={
              <div>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  alignItems: 'center'
                }}
                >
                  <FormsySelect
                    required
                    name='resource_type'
                    value={resourceType}
                    style={{ width: '130px', marginRight: '20px' }}
                    onChange={(e, value) => {
                      this.setState({ resourceType: value })
                    }}
                    floatingLabelText={language.translate('application.resourceType')}
                    validationErrors={language.messages.validationErrors}
                  >
                    {[
                      <OldMenuItem value='url' key='url' primaryText={language.translate('application.url')} />,
                      <OldMenuItem value='file' key='file' primaryText={language.translate('application.file')} />
                    ]}
                  </FormsySelect>
                  <FormsyText
                    floatingLabelText={language.translate('application.description')}
                    required
                    name={`resource_description${item.ID}`}
                    defaultValue={FormHelper.decodeHTML(item.ResourceDescription)}
                    onBlur={(e) => {
                      item.ResourceDescription = e.currentTarget.value
                      this.setState({ item }, () => onSave(item))
                    }}
                    validationErrors={this.context.language.messages.validationErrors}
                  />
                </div>
                <div>
                  {(resourceType === 'url')
                    ? <FormsyText
                      floatingLabelText={language.translate('application.url')}
                      required
                      name={`url${item.ID}`}
                      defaultValue={FormHelper.decodeHTML(item.ResourceURL)}
                      onBlur={(e) => {
                        item.ResourceURL = e.currentTarget.value
                        this.setState({ item }, () => onSave(item))
                      }}
                      validationErrors={this.context.language.messages.validationErrors}
                    />
                    : <div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          padding: '20px 0px'
                        }}
                      >
                        <div>
                          <a
                            target='_blank'
                            href={`${apiServer}/open.aspx?filekey=${item.PrivateFileKey}&token=${user.token}&email=${encodeURIComponent(user.userName)}&`}
                          >
                            {item.ResourceFileName}
                          </a>
                        </div>
                      </div>
                      <FormsyUpload
                        name={'File'}
                        required
                        onChange={this.parseImageUpload.bind(this)}
                        defaultValue={item.ResourceFileName}
                      />
                    </div>
                  }
                </div>
              </div>} />
            <ListItemSecondaryAction style={{ top: '10%' }}>
              <div>
                <DeleteIconButton onDelete={() => onRemoveField(item)} />
                <IconButton
                  disabled={!this.state.canSubmit}
                  onClick={() => {
                    this.setState({ editMode: false })
                  }}
                >
                  <Check />
                </IconButton>
              </div>
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
        </Form>
    )
  }
}

TemplateTaskResourceListItem.propTypes = {
  item: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onRemoveField: PropTypes.func.isRequired
}

TemplateTaskResourceListItem.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object,
  user: PropTypes.object
}

export default TemplateTaskResourceListItem
