import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Card, CardHeader } from '@material-ui/core'
import { Icon } from '../components/Icon'
import { updateTitle } from '../actions/application'
import { RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons'
import { push } from 'react-router-redux'
import { get, post } from '../actions/base'
import DataList from '../components/Layout/DataList'
import objectAssign from 'object-assign'
import AddIcon from '../components/Layout/AddIcon'

class ExternalDataSources extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      externalDataSourceList: [],
      isLoaded: false,
      update: false,
      forceDataRefresh: 0
    }
  }

  componentDidMount () {
    this.updateTitle()
  }

  componentDidUpdate (prevProps, prevState) {
    this.updateTitle()
  }

  updateTitle () {
    const { language } = this.context
    const { params } = this.props

    this.props.dispatch(updateTitle(`${language.translate('application.externalDataSources')} - ${language.translate(`application.${params.type}`)}`))
  }

  dataLoadCallback (data) {
    this.setState({
      externalDataSourceList: data,
      isLoaded: true
    })
  }

  handleSelect (dataSourceId) {
    this.props.push({
      pathname: this.props.location.pathname,
      query: objectAssign({},
        this.props.location.query,
        {
          ptype: 'externalds',
          dsid: dataSourceId
        })
    })
  }

  forceUpdate () {
    this.setState({ update: !this.state.update })
  }

  createNewExternalDataSource () {
    this.props.push({
      pathname: this.props.location.pathname,
      query: objectAssign({},
        this.props.location.query,
        {
          ptype: 'externalds'
        })
    })
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const { mainContentWidth, user, params, location } = this.props
    const { externalDataSourceList, forceDataRefresh } = this.state

    return (
      <div>
        <DataList
          url={`externaldatasource/list/for${params.type}`}
          dataCallback={this.dataLoadCallback.bind(this)}
          fetchAll
          responseProperty='ExternalDataSourceList'
          filterableColumns={[{
            name: language.translate('application.description'),
            property: 'Description'
          }]}
          noDataText={language.translate('application.noExternalDataSources')}
          forceDataRefresh={forceDataRefresh}
          channelName={`Private-${user.accountID}-ExternalDataSource-0`}
          events={['ExternalDataSourceCreated', 'ExternalDataSourceUpdated', 'ExternalDataSourceDeleted']}
          location={location}
        >
          {externalDataSourceList.map((dataSource, index) => (
            <Card
              key={dataSource.ID}
              className='card'
              style={{
                width: (mainContentWidth > 900) ? '30%' : (mainContentWidth > 600) ? '45%' : '90%',
                marginLeft: '10px',
                marginRight: '10px',
                padding: '0px',
                backgroundColor: (location.query.pid === dataSource.ID)
                  ? palette.borderColor
                  : palette.canvasColor
              }}
              onClick={(e) => {
                this.handleSelect(dataSource.ID)
              }}
            >
              <div
                className='clickable'
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}
              >
                <CardHeader
                  title={dataSource.Description}
                  titleTypographyProps={{ style: { fontSize: '16px', fontWeight: '600' } }}
                  subheader={dataSource.DriverName}
                  avatar={<Icon icon={dataSource.DriverName} size={40} />}
                  style={{ padding: '8px' }}
                />
                <div
                  style={{
                    margin: 'auto 10px auto 0px'
                  }}
                >
                  {(dataSource.IsLive)
                    ? <RadioButtonChecked
                      style={{ height: '30px', width: '30px' }}
                      nativeColor={palette.primary1Color}
                    />
                    : <RadioButtonUnchecked
                      style={{ height: '30px', width: '30px' }}
                      nativeColor={palette.accent7Color}
                    />}
                </div>
              </div>
            </Card>
          ))}
        </DataList>
        {(user.canManageProcessTemplates)
          ? <AddIcon
            menuItems={[
              {
                text: language.translate('application.newExternalDataSource'),
                onClick: this.createNewExternalDataSource.bind(this)
              }
            ]}
          />
          : null}
      </div>
    )
  }
}

ExternalDataSources.propTypes = {
  params: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  mainContentWidth: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired,
  location: PropTypes.object
}

ExternalDataSources.contextTypes = {
  muiTheme: PropTypes.object,
  language: PropTypes.object
}

const mapStateToProps = (state, ownProps) => ({
  title: state.application.title,
  mainContentWidth: state.application.mainContentWidth,
  user: state.auth,
  location: ownProps.location
})

const mapDispatchToProps = dispatch => ({
  get: bindActionCreators(get, dispatch),
  post: bindActionCreators(post, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ExternalDataSources)
