import objectAssign from 'object-assign'
import { createReducer } from '../utils'
import {
  DISABLE_FULLSCREEN, ENABLE_FULLSCREEN, LOG_LAST_ACTIVITY, RIGHT_PANEL_CLOSED, RIGHT_PANEL_OPEN,
  SET_MAIN_CONTENT_WIDTH, UPDATE_SCREEN_WIDTH, UPDATE_SCROLLBAR_WIDTH, UPDATE_TITLE_REQUEST, SET_RIGHT_PANEL_WIDTH,
  UPDATE_SCREEN_HEIGHT, SET_API_SERVER, ENABLE_DEBUG, DISABLE_DEBUG, SET_MAIN_APP_REF, SET_RIGHT_PANEL_REF
} from '../constants/ApplicationTypes'
import { apiServer } from '../config'

let urlParams = new URLSearchParams(window.location.search)
let savedApiServer = apiServer

if (window.localStorage.getItem('apiServer')) {
  savedApiServer = window.localStorage.getItem('apiServer')
}

if (urlParams.get('serverregion')) {
  savedApiServer = `https://${urlParams.get('serverregion')}.processplan.com`
}

const initialState = {
  apiServer: savedApiServer,
  title: 'ProcessPlan',
  fullScreen: false,
  screenWidth: document.body.clientWidth,
  screenHeight: document.body.clientHeight,
  scrollBarWidth: 0,
  rightPanelOpen: false,
  rightPanelWidth: rightPanelWidth(),
  rightPanelRef: null,
  mainContentWidth: document.body.clientWidth,
  lastActivity: parseInt(window.localStorage.getItem('lastActivity')) || parseInt(new Date().getTime()),
  debug: false,
  mainAppRef: null
}

function rightPanelWidth () {
  if (window.localStorage.getItem('rightPanelWidth')) { return parseInt(window.localStorage.getItem('rightPanelWidth')) }

  if (document.body.clientWidth > 800) { return 500 }

  return document.body.clientWidth
}

export default createReducer(initialState, {
  [UPDATE_TITLE_REQUEST]: (state, payload) => objectAssign({}, state, {
    title: payload.title
  }),
  [SET_API_SERVER]: (state, payload) => objectAssign({}, state, {
    apiServer: payload.server
  }),
  [ENABLE_FULLSCREEN]: state => objectAssign({}, state, {
    fullScreen: true
  }),
  [DISABLE_FULLSCREEN]: state => objectAssign({}, state, {
    fullScreen: false
  }),
  [ENABLE_DEBUG]: state => objectAssign({}, state, {
    debug: true
  }),
  [DISABLE_DEBUG]: state => objectAssign({}, state, {
    debug: false
  }),
  [UPDATE_SCREEN_WIDTH]: (state, payload) => objectAssign({}, state, {
    screenWidth: payload.width
  }),
  [UPDATE_SCREEN_HEIGHT]: (state, payload) => objectAssign({}, state, {
    screenHeight: payload.height
  }),
  [UPDATE_SCROLLBAR_WIDTH]: (state, payload) => objectAssign({}, state, {
    scrollBarWidth: payload.width
  }),
  [RIGHT_PANEL_OPEN]: state => objectAssign({}, state, {
    rightPanelOpen: true
  }),
  [RIGHT_PANEL_CLOSED]: state => objectAssign({}, state, {
    rightPanelOpen: false
  }),
  [SET_MAIN_CONTENT_WIDTH]: (state, payload) => objectAssign({}, state, {
    mainContentWidth: payload.width
  }),
  [SET_RIGHT_PANEL_WIDTH]: (state, payload) => {
    const difference = state.rightPanelWidth - payload.width
    return objectAssign({}, state, {
      rightPanelWidth: payload.width,
      mainContentWidth: state.mainContentWidth + difference
    })
  },
  [LOG_LAST_ACTIVITY]: (state, payload) => objectAssign({}, state, {
    lastActivity: payload.time
  }),
  [SET_MAIN_APP_REF]: (state, payload) => objectAssign({}, state, {
    mainAppRef: payload.ref
  }),
  [SET_RIGHT_PANEL_REF]: (state, payload) => objectAssign({}, state, {
    rightPanelRef: payload.ref
  })
})
