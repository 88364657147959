import React from 'react'
import PropTypes from 'prop-types'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import MuiSelectField from '@material-ui/core/Select'
import { withStyles } from '@material-ui/core/styles'
import { Field } from 'formik'

const styles = {
  selected: {
    display: 'flex',
    alignItems: 'center'
  }
}

class SelectField extends React.PureComponent {
  render () {
    const {
      required,
      label,
      name,
      options,
      errorBeforeDirty,
      InputLabelProps,
      classes,
      ...other
    } = this.props
    const id = `sel_${name}`

    return (
      <Field
        name={name}
        render={({ field, form: { dirty, errors } }) => {
          const errorText = errors[field.name]
          const hasError = (errorBeforeDirty || dirty) && errorText !== undefined

          return (<FormControl
            fullWidth
            required={required}
            style={{ marginTop: '16px', marginBottom: '8px' }}
            error={hasError}
          >
            <InputLabel {...InputLabelProps} htmlFor={id}>{label}</InputLabel>
            <MuiSelectField
              inputProps={{
                name: name,
                id: 'input_' + id
              }}
              classes={{ select: classes.selected }}
              {...field}
              {...other}
            >
              {
                options.map((item, index) => {
                  let { label, leftIcon, ...other } = item
                  return (<MenuItem
                    key={id + '_' + index}
                    {...other}>
                    {(leftIcon && <ListItemIcon style={{ marginRight: '10px' }}>{leftIcon}</ListItemIcon>)}
                    {label}
                  </MenuItem>)
                })
              }
            </MuiSelectField>
            {errorText && <FormHelperText>{errorText}</FormHelperText>}
          </FormControl>)
        }}
      />
    )
  }
}

SelectField.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
      ]).isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]).isRequired
    })
  ).isRequired,
  errorBeforeDirty: PropTypes.bool,
  InputLabelProps: PropTypes.object,
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(SelectField)
