import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { CircularProgress } from '@material-ui/core'
import { get } from '../actions/base'

class FileRedirect extends React.Component {
  constructor (props, context) {
    super(props, context)

    const query = this.context.location.query

    this.state = {
      filekey: query.filekey
    }
  }

  componentDidMount () {
    this.isUserLoggedIn()
  }

  isUserLoggedIn () {
    const { get, user, apiServer } = this.props
    const { filekey } = this.state

    get('issessionvalid', {
      onSuccess: (response) => {
        window.open(`${apiServer}/open.aspx?filekey=${filekey}&token=${user.token}&email=${encodeURIComponent(user.userName)}&`, '_self')
      }
    })
  }

  render () {
    return <CircularProgress className='loader' />
  }
}

FileRedirect.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  get: PropTypes.func.isRequired,
  apiServer: PropTypes.string.isRequired
}

FileRedirect.contextTypes = {
  location: PropTypes.object
}

const mapStateToProps = state => ({
  user: state.auth,
  apiServer: state.application.apiServer
})

const mapDispatchToProps = dispatch => ({
  get: bindActionCreators(get, dispatch),
  dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(FileRedirect)
