import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import MuiCheckboxField from '@material-ui/core/Checkbox'
import { Field } from 'formik'

class Checkbox extends React.PureComponent {
  render () {
    const {
      required,
      label,
      name,
      errorBeforeDirty,
      InputLabelProps,
      FormControlProps,
      ...other
    } = this.props
    const id = `sel_${name}`

    return (
      <Field
        name={name}
        render={({ field: { value, ...field }, form: { dirty, errors } }) => {
          const errorText = errors[field.name]
          const hasError = (errorBeforeDirty || dirty) && errorText !== undefined

          return (<FormControl
            fullWidth
            required={required}
            style={{ marginTop: '16px', marginBottom: '8px' }}
            error={hasError}
            {...FormControlProps}
          >
            <FormControlLabel
              key={name}
              {...InputLabelProps}
              control={
                <MuiCheckboxField
                  value={name}
                  color='primary'
                  inputProps={{
                    name: name,
                    id: 'input_' + id
                  }}
                  checked={value}
                  {...field}
                  {...other}
                />}
              label={label}
            />
            {errorText && <FormHelperText>{errorText}</FormHelperText>}
          </FormControl>)
        }}
      />
    )
  }
}

Checkbox.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  errorBeforeDirty: PropTypes.bool,
  InputLabelProps: PropTypes.object,
  FormControlProps: PropTypes.object
}

export default Checkbox
