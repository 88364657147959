import PropTypes from 'prop-types'
import React from 'react'
import { Button, List } from '@material-ui/core'
import ActionConditionListItem from './ActionConditionListItem'
import { Add } from '@material-ui/icons'

class ActionConditionList extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      actionConditionList: null,
      editMode: false
    } 
  }

  componentDidMount () {
    this.setState({ actionConditionList: this.props.actionConditionList })
  }

  componentDidUpdate (prevProps) {
    if (this.props.actionConditionList !== prevProps.actionConditionList) { this.setState({ actionConditionList: this.props.actionConditionList }) }
  }

  addNewItem () {
    const newItem = {
      ProcessTemplateID: this.props.templateId,
      ProcessTemplateAutomatedActionID: this.props.actionId,
      ConditionType_Identifier: '',
      new: true
    }

    const newActionConditionList = this.state.actionConditionList.concat(newItem)

    this.setState({ actionConditionList: newActionConditionList })

    // this.props.onSaveList(newActionConditionList);
  }

  handleChange (index, item) {
    const actionConditionList = this.state.actionConditionList
    actionConditionList[index] = item

    this.props.onSaveList(actionConditionList)
  }

  render () {
    const { language } = this.context
    const { actionConditionList, editMode } = this.state
    const {
      onDelete, dispatch, onRequestTaskResponses, onCopy, ...rest
    } = this.props

    return (
      <div>
        {(!actionConditionList || !actionConditionList.length)
          ? <div style={{ margin: '10px 20px' }}>{language.translate('application.noConditionsSet')}</div>
          : <List>
            {actionConditionList.map((item, index) => (
              <ActionConditionListItem
                item={item}
                key={item.ID || 'new'}
                isEditing={(editing) => {
                  this.setState({ editMode: editing })
                }}
                onRequestTaskResponses={(taskId, callback) => { onRequestTaskResponses(this.props.templateId, taskId, callback) }}
                onCopy={() => onCopy(item)}
                onRemoveField={(shouldDelete) => {
                  if (item.ID) {
                    if (shouldDelete) { onDelete(item) }
                  } else {
                    actionConditionList.splice(index, 1)
                    this.setState({ actionConditionList })
                  }
                }}
                onSave={value => this.handleChange(index, value)}
                dispatch={dispatch}
                {...rest}
              />
            ))}
          </List>}
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
          <Button
            disabled={editMode}
            onClick={this.addNewItem.bind(this)}
            color='primary'
            variant='contained'
            style={{ margin: '10px' }}
          >
            <Add />
            {language.translate('application.addCondition')}
          </Button>
        </div>
      </div>
    )
  }
}

ActionConditionList.propTypes = {
  templateId: PropTypes.string.isRequired,
  actionId: PropTypes.string.isRequired,
  actionConditionList: PropTypes.array,
  processFieldList: PropTypes.array,
  userFieldList: PropTypes.array,
  templateTasks: PropTypes.array,
  onRequestTaskResponses: PropTypes.func,
  onSaveList: PropTypes.func,
  onCopy: PropTypes.func,
  onDelete: PropTypes.func,
  conditionTypes: PropTypes.array,
  dispatch: PropTypes.func.isRequired
}

ActionConditionList.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default ActionConditionList
