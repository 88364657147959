import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get, post } from '../../actions/base'
import {
  Button, Card, Dialog, DialogContent, DialogTitle, Divider, FormControl, IconButton, InputLabel, List, ListItem,
  ListItemSecondaryAction, ListItemText, Menu, MenuItem, Select, Switch, TextField, Typography
} from '@material-ui/core'
import { MoreVert, ChevronRight, Add } from '@material-ui/icons'
import FormHelper from '~/businessLogic/formHelper'
import RightPanelContent from '../Layout/RightPanelContent'
import DeleteMenuItem from '../Layout/DeleteMenuItem'
import CancelButton from '../Forms/CancelButton'

class MapSubProcessFields extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      canSubmit: true,
      isLoaded: false,
      fieldList: [],
      mapList: [],
      openAddMappingDialog: false,
      fromField: '',
      toField: '',
      menuAnchor: null,
      menuAnchorIndex: null,
      customText: '',
      migrationPlan: {},
      migrationPlanLoaded: false
    }
  }

  // MIGRATED TO componentDidMount
  // componentWillMount () {
  //   this.fetchAllData()
  // }

  componentDidMount() {
    if (this.state.isLoaded === false) {
      this.fetchAllData()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.mpId && prevProps.mpId !== this.props.mpId) {
      this.fetchAllData()
    }
  }

  fetchAllData() {
    this.fetchMigrationPlanFieldMapList()
    this.fetchMigrationPlan()
  }

  fetchMigrationPlan() {
    const { type, typeId, mpId } = this.props

    this.setState({
      isLoaded: false
    })

    this.props.get(`${type}/${typeId}/${(type === 'processinstance') ? 'template' : ''}migrationplan/${mpId}`, {
      onSuccess: (response) => {
        this.setState({
          migrationPlan: response.ProcessTemplateMigrationPlan,
          migrationPlanLoaded: true
        })
      }
    })
  }

  saveMigrationPlan(migrationPlan) {
    const { type, typeId, mpId } = this.props

    const body = JSON.stringify({
      ProcessTemplateMigrationPlan: migrationPlan
    })

    this.props.post(
      `${type}/${typeId}/${(type === 'processinstance') ? 'template' : ''}migrationplan/${mpId}`,
      body,
      {
        onSuccess: (response) => {
          this.setState({
            migrationPlan: response.ProcessTemplateMigrationPlan
          })
        }
      }
    )
  }

  fetchMigrationPlanFieldMapList() {
    const { type, typeId, mpId } = this.props

    this.setState({
      isLoaded: false
    })

    this.props.get(`${type}/${typeId}/${(type === 'processinstance') ? 'template' : ''}migrationplan/${mpId}/fieldmap/list`, {
      onSuccess: (response) => {
        this.setState({
          isLoaded: true,
          fieldList: response.ProcessTemplateFieldList,
          mapList: response.ProcessTemplateMigrationPlanFieldMapList
        })
      }
    })
  }

  generateTemplateMenu() {
    const { fieldList, isLoaded } = this.state
    const { language } = this.context

    if (!isLoaded) { return false }

    const menu = [<MenuItem
      value={false}
      key={0}
    >{language.translate('application.none')}</MenuItem>]

    fieldList.map((field, index) => {
      menu.push(<MenuItem
        value={field.ID}
        key={field.ID}
      >{FormHelper.decodeHTML(field.FieldName)}</MenuItem>)
    })

    return menu
  }

  generateParentDataMenu() {
    const { language } = this.context
    const menu = []

    this.state.mapList.map((field, index) => {
      if (field.FromFieldName) {
        menu.push(<MenuItem
          value={field.FromFieldName}
          key={field.FromFieldName}
        >{FormHelper.decodeHTML(field.FromFieldName)}</MenuItem>)
      }
    })

    menu.push(<Divider key='div-1' />)
    menu.push(<MenuItem
      value='AccountName'
      key='AccountName'
    >{language.translate('application.accountName')}</MenuItem>)
    menu.push(<MenuItem
      value='TemplateTitle'
      key='TemplateTitle'
    >{language.translate('application.templateTitle')}</MenuItem>)
    menu.push(<MenuItem
      value='ProcessInstanceID'
      key='ProcessInstanceID'
    >{language.translate('application.processInstanceID')}</MenuItem>)
    menu.push(<MenuItem
      value='ResponseBy'
      key='ResponseBy'
    >{language.translate('application.responseBy')}</MenuItem>)
    menu.push(<Divider key='div-2' />)
    menu.push(<MenuItem
      value='CustomText'
      key='CustomText'
    >{language.translate('application.customText')}</MenuItem>)

    return menu
  }

  saveMigrationMap(mapList) {
    const { type, typeId, mpId } = this.props

    const body = JSON.stringify({
      ProcessTemplateMigrationPlanFieldMapList: mapList
    })

    this.props.post(
      `${type}/${typeId}/${(type === 'processinstance') ? 'template' : ''}migrationplan/${mpId}/fieldmap/list`,
      body,
      {
        onSuccess: (response) => {
          this.setState({
            mapList: response.ProcessTemplateMigrationPlanFieldMapList,
            openAddMappingDialog: false,
            fromField: '',
            toField: '',
            customText: ''
          })
        }
      }
    )
  }

  saveNewMapping() {
    const { fromField, toField, customText } = this.state
    const mapList = this.state.mapList.slice()
    let added = false

    mapList.map((field, index) => {
      if (field.FromFieldName === fromField) {
        field.ToProcessTemplateFieldID = toField
        added = true
      }
    })

    if (!added) {
      if (fromField === 'CustomText') {
        mapList.push({
          FromTextValue: customText,
          ToProcessTemplateFieldID: toField
        })
      } else { mapList.push({ FromSystemValue: fromField, ToProcessTemplateFieldID: toField }) }
    }

    this.saveMigrationMap(mapList)
  }

  getFieldTitleByID(id) {
    let title = ''

    this.state.fieldList.map((field) => {
      if (field.ID === id) { title = field.FieldName }
    })

    return title
  }

  addNewItem() {
    this.setState({
      openAddMappingDialog: true
    })
  }

  extractFieldName(field) {
    const { language } = this.context

    if (field.FromFieldName) { return FormHelper.decodeHTML(field.FromFieldName) }

    if (field.FromSystemValue) {
      switch (field.FromSystemValue) {
        case 'AccountName':
          return language.translate('application.accountName')
        case 'TemplateTitle':
          return language.translate('application.templateTitle')
        case 'ProcessInstanceID':
          return language.translate('application.processInstanceID')
        case 'ResponseBy':
          return language.translate('application.responseBy')
        default:
          return field.FromTextValue
      }
    }

    return field.FromTextValue
  }

  render() {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const { isLoaded, mapList, migrationPlan, migrationPlanLoaded, menuAnchor, menuAnchorIndex } = this.state
    const templateMenu = this.generateTemplateMenu()

    return (
      <RightPanelContent
        title={language.translate('application.mapProcessFields')}
        closePanel={() => this.props.close()}
      >
        <Divider style={{ height: '10px', backgroundColor: palette.accent5Color }} />
        {(migrationPlanLoaded)
          ? <div style={{ padding: '15px 15px 0px 15px' }}>
            <Card className='card'>
              <List style={{ padding: '0px' }}>
                <ListItem>
                  <ListItemText
                    primary={language.translate('application.holdParentProcessOpen')}
                  />
                  <ListItemSecondaryAction>
                    <Switch
                      color='primary'
                      checked={migrationPlan.HoldParentProcessOpen}
                      onChange={(obj, value) => {
                        migrationPlan.HoldParentProcessOpen = !migrationPlan.HoldParentProcessOpen
                        this.saveMigrationPlan(migrationPlan)
                      }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Card>
          </div>
          : null}
        {(isLoaded)
          ? <div style={{ padding: '15px' }}>
            <Card className='card'>
              <List style={{ padding: '0px' }}>
                {mapList.map((field, index) => (
                  (field.ToProcessTemplateFieldID)
                    ? React.Children.toArray([
                      <ListItem key={index}>
                        <ListItemText
                          primary={
                            <div style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center'
                            }}
                            >
                              <div>{this.extractFieldName(field)}</div>
                              <ChevronRight style={{ marginTop: '2px' }} />
                              <div>{this.getFieldTitleByID(field.ToProcessTemplateFieldID)}</div>
                            </div>}
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            onClick={(e) => { this.setState({ menuAnchor: e.currentTarget, menuAnchorIndex: index }) }}>
                            <MoreVert />
                          </IconButton>
                          <Menu
                            anchorEl={menuAnchor}
                            open={Boolean(menuAnchor) && menuAnchorIndex === index}
                            onClose={() => { this.setState({ menuAnchor: null, menuAnchorIndex: null }) }}
                            getContentAnchorEl={null}
                            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                          >
                            <DeleteMenuItem
                              onDelete={() => {
                                this.setState({ menuAnchor: null, menuAnchorIndex: null })
                                mapList[index].ToProcessTemplateFieldID = false
                                this.saveMigrationMap(mapList)
                              }}
                            />
                          </Menu>
                        </ListItemSecondaryAction>
                      </ListItem>,
                      (index < mapList.length - 1) ? <Divider key={index + 10000} /> : null
                    ])
                    : null
                ))}
              </List>
            </Card>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
              <Button
                onClick={this.addNewItem.bind(this)}
                color='primary'
                variant='contained'
              >
                <Add />
                {language.translate('application.addMapping')}
              </Button>
            </div>
          </div>
          : null}
        <Dialog
          open={this.state.openAddMappingDialog}
          onClose={() => this.setState({ openAddMappingDialog: false })}
        >
          <DialogTitle
            style={{
              backgroundColor: palette.headerBackgroundColor
            }}
            disableTypography
          >
            <Typography
              variant='h6'
              style={{ color: palette.alternateTextColor }}>
              {language.translate('application.addMapping')}
            </Typography>
          </DialogTitle>
          <DialogContent style={{ width: '300px' }}>
            <FormControl
              fullWidth
              style={{ margin: '10px 0px' }}
            >
              <InputLabel style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {language.translate('application.passDataFrom')}
              </InputLabel>
              <Select
                name='map_from'
                fullWidth
                MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                value={this.state.fromField}
                onChange={(e) => {
                  this.setState({ fromField: e.target.value })
                }}
              >
                {this.generateParentDataMenu()}
              </Select>
            </FormControl>
            {(this.state.fromField === 'CustomText')
              ? <TextField
                name='custom_text'
                fullWidth
                style={{ margin: '10px 0px' }}
                label={language.translate('application.customText')}
                value={this.state.customText}
                onChange={(e) => {
                  this.setState({ customText: e.currentTarget.value })
                }}
              />
              : null}
            <FormControl
              fullWidth
              style={{ margin: '10px 0px' }}
            >
              <InputLabel style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {language.translate('application.toField')}
              </InputLabel>
              <Select
                fullWidth
                MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                value={this.state.toField}
                onChange={(e) => {
                  this.setState({ toField: e.target.value })
                }}
              >
                {templateMenu}
              </Select>
            </FormControl>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <CancelButton
                onClick={() => this.setState({
                  openAddMappingDialog: false,
                  toField: '',
                  fromField: '',
                  customText: ''
                })}
                variant='contained'
              />
              <Button
                onClick={() => {
                  this.saveNewMapping()
                }}
                variant='contained'
                disabled={(!this.state.fromField || !this.state.toField)}
                color='primary'
                style={{ margin: '10px' }}
              >
                {language.translate('application.save')}
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </RightPanelContent>
    )
  }
}

MapSubProcessFields.propTypes = {
  mpId: PropTypes.string,
  type: PropTypes.string,
  typeId: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired
}

MapSubProcessFields.contextTypes = {
  location: PropTypes.object,
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  dispatch,
  post: bindActionCreators(post, dispatch),
  get: bindActionCreators(get, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(MapSubProcessFields)
