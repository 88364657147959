import React from 'react'
import PropTypes from 'prop-types'
import MuiTextField from '@material-ui/core/TextField'
import { Field } from 'formik'

class TextField extends React.Component {
  render () {
    const { label, errorBeforeDirty, name, ...other } = this.props

    return (
      <Field
        name={name}
        render={({ field, form: { dirty, errors, touched } }) => {
          const errorText = errors[field.name]
          const hasError = (errorBeforeDirty || touched) && errorText !== undefined

          return (<MuiTextField
            label={label}
            margin='normal'
            error={hasError}
            helperText={hasError && errorText ? errorText : ''}
            {...field}
            {...other}
          />)
        }}
      />
    )
  }
}

TextField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  errorBeforeDirty: PropTypes.bool
}

export default TextField
