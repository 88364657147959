import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Form } from 'formsy-react'
import { FormsyText } from 'formsy-material-ui'
import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import CancelButton from '../Forms/CancelButton'

class RapidEntryForm extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      canSubmit: true,
      value: null
    }
  }

  enableButton () {
    this.setState({ canSubmit: true })
  }

  disableButton () {
    this.setState({ canSubmit: false })
  }

  render () {
    const { language } = this.context
    const palette = this.context.muiTheme.palette

    return (
      <Dialog
        open={this.props.open}
        onClose={() => this.setState({ showRapidInputWindow: false })}
      >
        <DialogTitle
          style={{
            backgroundColor: palette.headerBackgroundColor
          }}
          disableTypography
        >
          <Typography
            variant='h6'
            style={{ color: palette.alternateTextColor }}>
            {language.translate('application.rapidInput')}
          </Typography>
        </DialogTitle>
        <DialogContent style={{ width: '400px' }}>
          <Form onValid={this.enableButton.bind(this)} onInvalid={this.disableButton.bind(this)}>
            <div style={{ padding: '20px 0px' }}>
              {language.translate('application.rapidInputText')}
            </div>
            <div>
              <FormsyText
                required
                validationErrors={this.context.language.messages.validationErrors}
                multiLine
                onChange={e => this.setState({ value: e.currentTarget.value })}
                name='rapid_entry'
                rows={5}
              />
            </div>
            <div style={{ marginTop: '10px' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                <CancelButton
                  onClick={this.props.close}
                  style={{ margin: '10px' }}
                  variant='contained'
                >
                  {language.translate('application.cancel')}
                </CancelButton>
                <Button
                  onClick={() => this.props.submit(this.state.value)}
                  disabled={!this.state.canSubmit}
                  variant='contained'
                  color='primary'
                  style={{ margin: '10px' }}
                >
                  {language.translate('application.submit')}
                </Button>
              </div>
            </div>
          </Form>
        </DialogContent>
      </Dialog>
    )
  }
}

RapidEntryForm.propTypes = {
  close: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  open: PropTypes.bool
}

RapidEntryForm.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(RapidEntryForm)
