import PropTypes from 'prop-types'
import React from 'react'
import { IconButton, ListItem, ListItemText, ListItemSecondaryAction, Menu, MenuItem } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import { Form } from 'formsy-react'
import { FormsyText } from 'formsy-material-ui'
import DeleteMenuItem from '../DeleteMenuItem'

class FavoriteItem extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      canSubmit: true,
      menuAnchor: null
    }
  }

// figure out why prevprops is never different than this.props
// make field auto focus on create and rename
  componentDidMount () {
    if (this.props.favorite.edit) {
      this.favoriteInput.focus()
    }
  }

  componentDidUpdate (prevProps) {
    if (!prevProps.favorite.edit && this.props.favorite.edit) {
      this.favoriteInput.focus()
    }
  }

  // componentWillUpdate (prevProps) {
  //   if (!prevProps.favorite.edit && this.props.favorite.edit) {
  //     this.favoriteInput.focus()
  //   }
  // }

  render () {
    const { favorite, onFavoriteClick, onChange, enableRename, onRemove, onReorderRequest, index, hideMoveUp, hideMoveDown, ...rest } = this.props
    const { language, muiTheme: { palette } } = this.context
    const { canSubmit, menuAnchor } = this.state

    return (
      (favorite.edit)
        ? <ListItem
          //innerDivStyle={{ paddingTop: '0px' }}
          {...rest}
        >
          <ListItemText
            //primaryTypographyProps={{ style: styles.listItemStyle }}
            primary={
              <Form
                onValid={() => this.setState({ canSubmit: true })}
                onInvalid={() => this.setState({ canSubmit: false })}>
                <FormsyText
                  fullWidth
                  required
                  maxLength={20}
                  name={favorite.displayText}
                  inputStyle={{ color: palette.navigationFontColor }}
                  underlineFocusStyle={{ borderColor: palette.navigationFontColor }}
                  defaultValue={favorite.displayText}
                  ref={(input) => { this.favoriteInput = input }}
                  onFocus={(e) => { e.target.setSelectionRange(e.target.value.length, e.target.value.length) }}
                  onBlur={(e) => { onChange(e.target.value) }}
                  validationErrors={language.messages.validationErrors}
                />
              </Form>
            } />
        </ListItem>
        : <ListItem
          key={favorite.displayText}
          button
          onClick={() => onFavoriteClick(favorite.url)}
          {...rest}
        >
          <ListItemText
            primaryTypographyProps={{ style: { color: palette.navigationFontColor } }}
            primary={favorite.displayText} />
          <ListItemSecondaryAction>
            <IconButton onClick={(e) => { this.setState({ menuAnchor: e.currentTarget }) }}><MoreVert /></IconButton>
            <Menu
              anchorEl={menuAnchor}
              open={Boolean(menuAnchor)}
              onClose={() => { this.setState({ menuAnchor: null }) }}
              getContentAnchorEl={null}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
              <MenuItem onClick={() => {
                this.setState({ menuAnchor: null })
                enableRename()
              }}
              >{language.translate('application.rename')}</MenuItem>
              {(!hideMoveUp)
                ? <MenuItem onClick={() => {
                  this.setState({ menuAnchor: null })
                  onReorderRequest(index, index - 1)
                }}>
                  {language.translate('application.moveUp')}
                </MenuItem>
                : null}
              {(!hideMoveDown)
                ? <MenuItem onClick={() => {
                  this.setState({ menuAnchor: null })
                  onReorderRequest(index, index + 1)
                }}>
                  {language.translate('application.moveDown')}
                </MenuItem>
                : null}
              <DeleteMenuItem
                onDelete={() => {
                  this.setState({ menuAnchor: null })
                  onRemove(favorite)
                }}
              />
            </Menu>
          </ListItemSecondaryAction>
        </ListItem>)
  }
}

FavoriteItem.propTypes = {
  favorite: PropTypes.object,
  onFavoriteClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  enableRename: PropTypes.func.isRequired,
  index: PropTypes.number,
  onReorderRequest: PropTypes.func,
  hideMoveUp: PropTypes.bool,
  hideMoveDown: PropTypes.bool
}

FavoriteItem.contextTypes = {
  muiTheme: PropTypes.object,
  language: PropTypes.object,
  location: PropTypes.object
}

export default FavoriteItem
