import PropTypes from 'prop-types'
import React from 'react'
import { FormsyText } from 'formsy-material-ui'
import {
  Button, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, ListItem, ListItemSecondaryAction,
  ListItemText, Menu, MenuItem, Typography
} from '@material-ui/core'
import FormHelper from '~/businessLogic/formHelper'
import { Form } from 'formsy-react'
import { MoreVert } from '@material-ui/icons'
import DeleteMenuItem from '~/components/Layout/DeleteMenuItem'
import HTMLEditor from '../../components/HTMLEditor/HTMLEditor'

class TextBlockListItem extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      editMode: false,
      canSubmit: true,
      value: null,
      item: null,
      menuAnchor: null
    }
  }

  componentDidMount() {
    if (this.props.item.TemplateTitle === '') { this.setState({ editMode: true }) }
    this.setState({ item: JSON.parse(JSON.stringify(this.props.item)) })
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(this.props.item) !== JSON.stringify(prevProps.item)) { this.setState({ item: JSON.parse(JSON.stringify(this.props.item)) }) }
  }

  enableButton() {
    this.setState({ canSubmit: true })
  }

  disableButton() {
    this.setState({ canSubmit: false })
  }

  convertFieldTokenHTMLToValues(text) {
    const { fieldTokens } = this.props
    const container = document.createElement('div')
    container.innerHTML = text
    const tokensUsed = container.getElementsByClassName('field-token')

    Array.from(tokensUsed).forEach((tempToken) => {
      let tempTokenString = tempToken.innerHTML.replace('&amp;', '&')
      let tokenObject = fieldTokens.filter((token) => (FormHelper.decodeHTML(token.Text) === tempTokenString))[0]
      if (tokenObject) {
        tempToken.classList.remove('invalid-token')
        tempToken.outerHTML = tokenObject.Value
      } else {
        tempToken.classList.add('invalid-token')
      }
    })

    return container.innerHTML
  }

  convertFieldTokenValuesToText(text = '', makeHTML = false) {
    const myRe = /\[(.*?)\]/g
    const { fieldTokens } = this.props

    let tokensUsed = text.match(myRe)

    if (tokensUsed) {
      tokensUsed.map((tokenUsed) => {
        let tokenObject = fieldTokens.filter((token) => (FormHelper.decodeHTML(token.Value) === tokenUsed))[0]
        if (tokenObject) {
          let noBracketsToken = tokenUsed.substr(1, tokenUsed.length - 2)
          if (makeHTML) {
            text = text.replace(`[${noBracketsToken}]`, `<span class="field-token" id="[$${noBracketsToken}]" contenteditable="false">${tokenObject.Text}</span>`)
          } else {
            text = text.replace(noBracketsToken, tokenObject.Text)
          }
        }
      })
    }
    return text
  }

  render() {
    const { language, muiTheme: { palette } } = this.context
    const { onSave, fieldTokens } = this.props
    const { item, menuAnchor } = this.state

    if (!item) { return null }

    return (
      <div>
        <ListItem>
          <ListItemText primary={FormHelper.decodeHTML(item.TextBlockTitle)} />
          <ListItemSecondaryAction>
            <IconButton onClick={(e) => { this.setState({ menuAnchor: e.currentTarget }) }}><MoreVert /></IconButton>
            <Menu
              anchorEl={menuAnchor}
              open={Boolean(menuAnchor)}
              onClose={() => { this.setState({ menuAnchor: null }) }}
              getContentAnchorEl={null}
              anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
              <MenuItem
                onClick={() => this.setState({ editMode: true, menuAnchor: null })}
              >{language.translate('application.edit')}</MenuItem>
              <DeleteMenuItem
                onDelete={() => {
                  this.setState({ menuAnchor: null })
                  this.props.onDelete(item)
                }}
              />
            </Menu>
          </ListItemSecondaryAction>
        </ListItem>
        {this.state.editMode && <Dialog
          PaperProps={{ style: { maxWidth: '800px', width: '90%' } }}
          open
          onClose={() => this.setState({ editMode: false })}
          disableEnforceFocus
        >
          <DialogTitle style={{ backgroundColor: palette.headerBackgroundColor }} disableTypography>
            <Typography
              variant='h6'
              style={{ color: palette.alternateTextColor }}>{language.translate('application.editTextBlock')}</Typography>
          </DialogTitle>
          <DialogContent>
            <Form>
              <FormsyText
                floatingLabelText={language.translate('application.textBlockTitle')}
                required
                name={`textblock-title-${item.ID}`}
                defaultValue={FormHelper.decodeHTML(item.TextBlockTitle)}
                onChange={(e) => {
                  item.TextBlockTitle = e.currentTarget.value
                  this.setState({ item })
                }}
                style={{ marginRight: '15px' }}
                validationErrors={language.messages.validationErrors}
              />
              <HTMLEditor
                key={item.ID}
                name='textblock-html'
                tokens={fieldTokens}
                value={this.convertFieldTokenValuesToText(item.TextBlock, true)}
                toolbar='fontsizeselect fontselect forecolor backcolor | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | table | code'
                labelText={language.translate('application.textBlock')}
                onBlur={(html) => {
                  if (this.convertFieldTokenHTMLToValues(html) !== item.TextBlock) {
                    item.TextBlock = this.convertFieldTokenHTMLToValues(html)
                    this.setState({ item })
                  }
                }}
                required
                validationErrors={language.messages.validationErrors}
              />
            </Form>
          </DialogContent>
          <DialogActions>
            <Button
              key='submit'
              color='primary'
              onClick={() => {
                onSave(item)
                this.setState({ editMode: false })
              }}
            >{language.translate('application.submit')}</Button>,
            <Button
              key='cancel'
              color='primary'
              onClick={() => {
                if (!item.ID) {
                  this.props.onDelete(item)
                }
                this.setState({ editMode: false, item: JSON.parse(JSON.stringify(this.props.item)) })
              }}
            >{language.translate('application.cancel')}</Button>
          </DialogActions>
        </Dialog>}
      </div>
    )
  }
}

TextBlockListItem.propTypes = {
  item: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  fieldTokens: PropTypes.array
}

TextBlockListItem.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object,
  user: PropTypes.object
}

export default TextBlockListItem
