import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'react-router-redux'
import { get } from '../actions/base'
import { updateTitle } from '../actions/application'
import { Button, CircularProgress, Paper } from '@material-ui/core'
import { Print } from '@material-ui/icons'

class CustomHTML extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      html: {},
      htmlLoaded: false
    }
  }

  componentDidMount () {
    const { language } = this.context
    const { location: { query } } = this.props

    if (query.src) {
      this.fetchHtmlData(query.src, query.idx)
    } else if (query.token) {
      this.fetchHtmlData('public/processinstance/customhtml/list', query.idx, query.token)
    }
  }

  fetchHtmlData (src, index = 0, token = null) {
    const { get, updateTitle, user, push } = this.props

    if (!src) { return false }

    const headers = {}

    if (token) {
      headers.token = token
    }

    get(src, {
      onSuccess: (response) => {
        const htmlItem = response.CustomHTMLList[index]
        updateTitle(htmlItem.HTMLTitle)
        this.setState({
          html: htmlItem,
          htmlLoaded: true
        }, () => {
          if (token && htmlItem.AuthenticationRequired) {
            const endpoint = `processinstance/${htmlItem.ProcessInstanceID}/action/${htmlItem.IntegrationActionID}/customhtml/list`
            if (user.isAuthenticated) {
              this.fetchHtmlData(endpoint, index)
            } else {
              push(`/login?next=custom-html&src=${endpoint}`)
            }
          } else if (htmlItem.AutoPrint) {
            this.print(htmlItem.HTMLContent)
          }
        })
      },

      headers: headers
    })
  }

  print (content) {
    const printContainer = document.getElementById('printContainer').contentWindow

    printContainer.document.open()
    printContainer.document.write(content)
    printContainer.document.close()
    printContainer.focus()
    printContainer.print()
    printContainer.close()
  }

  render () {
    const { html, htmlLoaded } = this.state
    const { muiTheme, user, language } = this.context

    if (!htmlLoaded) { return <CircularProgress className='loader' /> }

    return (<div>
      <Button
        onClick={() => this.print(html.HTMLContent)}
        color='primary'
        variant='contained'
        style={{ marginBottom: '10px' }}
      >
        <Print />
        {language.translate('application.print')}
      </Button>
      <Paper elevation={4} style={{ height: '90%', position: 'relative', backgroundColor: '#fff', padding: '10px' }}>
        <div
          dangerouslySetInnerHTML={{ __html: html.HTMLContent }}
        />
      </Paper>
      <iframe
        id='printContainer'
        style={{ height: '0px', width: '0px', position: 'absolute', border: 'none' }}
      />
    </div>)
  }
}

CustomHTML.propTypes = {
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  get: PropTypes.func.isRequired,
  updateTitle: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired
}

CustomHTML.contextTypes = {
  location: PropTypes.object,
  muiTheme: PropTypes.object,
  language: PropTypes.object,
  user: PropTypes.object
}

const mapStateToProps = state => ({
  user: state.auth
})

const mapDispatchToProps = dispatch => ({
  dispatch,
  get: bindActionCreators(get, dispatch),
  updateTitle: bindActionCreators(updateTitle, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomHTML)
