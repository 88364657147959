export default class FormHelper {
  /**
   * Provided with a string, this function returns that string once converting it to valid HTML
   *
   * @param string
   * @returns {*}
   */
  static encodeHTML (string) {
    return (string) ? string.replace(/(?:\r\n|\r|\n)/g, '<br/>') : ''
  }

  /**
   * Provided a string with valid HTML, this function returns the decoded string
   *
   * @param string
   * @returns {*}
   */
  static decodeHTML (string) {
    let newString = (string) ? string.replace(/<br\s*[/]?>/gi, '\n') : ''
    newString = newString.replace(/&#(\d+);/g, (m, n) => String.fromCharCode(n))

    return newString
  }
}
