import { AGENTS_ONLINE, IS_LOADED, SET_API, SET_ELEMENT } from '../constants/LiveChatActionTypes'
import { showSnackbar } from './snackbar'
import * as languages from '../lang'

export function onLoad () {
  return {
    type: IS_LOADED
  }
}

export function setAgentsOnline (online) {
  return {
    type: AGENTS_ONLINE,
    payload: {
      online
    }
  }
}

export function setAPI (api) {
  return {
    type: SET_API,
    payload: {
      api
    }
  }
}

export function setElement (element) {
  return {
    type: SET_ELEMENT,
    payload: {
      element
    }
  }
}

export function destroyElement () {
  return function (dispatch, state) {
    const { element } = state().livechat
    element.parentNode.removeChild(element);

    ['livechat-ping', 'livechat-compact-container', 'livechat-full', 'lc_invite_layer', 'lc_overlay_layer'].map((id) => {
      const element = document.getElementById(id)

      element.parentNode.removeChild(element)
    })

    const scripts = document.getElementsByTagName('script')
    for (let i = 0; i < scripts.length; ++i) {
      console.log(scripts[i].src)
      if (scripts[i].src.substr(0, 29) === 'https://secure.livechatinc.com') {
        scripts[i].parentNode.removeChild(scripts[i])
      }
    }

    dispatch(setElement(null))
  }
}

export function initializeScript (user, postInitCB) {
  return function (dispatch, state) {
    if (state().livechat.isLoaded) { return }

    window.__lc = {}
    window.__lc.license = 5829001
    window.__lc.group = 4
    window.__lc.visitor = {
      name: user.firstName || '',
      email: user.userName || ''
    }
    window.__lc.params = [
      { name: 'Full Name', value: user.fullName },
      { name: 'User ID', value: user.userid },
      { name: 'Title', value: '' },
      { name: 'Company Name', value: user.accountName || '' },
      { name: 'Account ID', value: user.accountID || '' },
      { name: 'Account Status', value: user.accountStatus || '' }
    ]

    const lc = document.createElement('script')
    lc.type = 'text/javascript'
    lc.async = true
    lc.src = `${document.location.protocol === 'https:' ? 'https://' : 'http://'}cdn.livechatinc.com/tracking.js`
    const s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(lc, s)
    dispatch(setElement(lc));

    /*
     * Loop until the LC_API global variable is available from livechat
     * Then setup a callback that triggers when agents are available/unavailable
     */
    (function loop () {
      setTimeout(() => {
        if (window.LC_API) {
//           if (!window.Upscope) {
//             (function (w, u, d) { if (typeof u !== 'function') { var i = function () { i.c(arguments) }; i.q = []; i.c = function (args) { i.q.push(args) }; w.Upscope = i; var l = function () { var s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://js.upscope.io/upscope.js'; var x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x) }; l() } })(window, window.Upscope, document)

//             window.Upscope('init', {
//               apiKey: 'jeiuWcong1KVYP7dtnDhHC8B',
//               requireAuthorizationForSession: true,
//               sendScreenshotOnChatOpen: false,
//  // Replace the list below with a list containing the tags. No white spaces allowed
//  // tags: [“#list”, “#of”, “#tags”, “#here”],
//  // Replace the list below with a list containing identifiers for the user (e.g. user name or email), or null to remove them
//               identities: [user.EmailAddress, user.FullName],
//  // Replace “USER UNIQUE ID” below with the user id, or null to log them out
//               uniqueId: user.ID,
//               trackConsole: true,
//               allowRemoteConsole: true,
//               canvasEncoding: 'png'
//             })

//             // show and hide disconnect button for upscope
//             window.Upscope('on', 'sessionStart', 'sessionContinue', function () {
//               document.getElementById('upscope-stop-screenshare-link').style.display = 'flex'
//             })

//             window.Upscope('on', 'sessionEnd', function () {
//               document.getElementById('upscope-stop-screenshare-link').style.display = 'none'
//               dispatch(showSnackbar(languages[state().auth.language].application.screenSharingTerminated))
//             })
//           }

          dispatch(setAgentsOnline(!!(window.LC_API.agents_are_available())))
          dispatch(setAPI(window.LC_API))
          dispatch(onLoad())

          window.LC_API.on_after_load = () => {
            window.LC_API.disable_sounds()
          }

          window.LC_API.on_chat_state_changed = function (data) {
            switch (data.state) {
              case 'offline':
                dispatch(setAgentsOnline(false))
                break
              case 'online_for_chat':
              case 'online_for_queue':
                dispatch(setAgentsOnline(true))
                break
            }
          }

          if (postInitCB) { postInitCB() }
        } else { loop() }
      }, 1000)
    }())
  }
}
