import { createReducer } from '../utils'
import { AGENTS_ONLINE, IS_LOADED, SET_API, SET_ELEMENT } from '../constants/LiveChatActionTypes'
import objectAssign from 'object-assign'

const initialState = {
  isLoaded: false,
  api: {},
  agentsOnline: false,
  element: null
}

export default createReducer(initialState, {
  [IS_LOADED]: state => objectAssign({}, state, {
    isLoaded: true
  }),

  [AGENTS_ONLINE]: (state, payload) => objectAssign({}, state, {
    agentsOnline: payload.online
  }),

  [SET_API]: (state, payload) => objectAssign({}, state, {
    api: payload.api
  }),

  [SET_ELEMENT]: (state, payload) => objectAssign({}, state, {
    element: payload.element
  })
})
