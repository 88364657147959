import PropTypes from 'prop-types'
import React from 'react'
import { List, ListItem, ListItemText } from '@material-ui/core'
import { MenuItem } from 'material-ui'
import { FormsyText, FormsySelect } from 'formsy-material-ui'
import GroupUserSelect from '../Forms/GroupUserSelect'
import moment from 'moment'

const style = {
  listItemStyle: {
    paddingTop: '0px',
    paddingBottom: '0px'
  }
}

class PersonalInformation extends React.Component {
  shouldComponentUpdate (nextProps, nextState) {
    return this.props.user !== nextProps.user
  }

  render () {
    const { language, muiTheme: { palette } } = this.context
    const { user, onSave, timeZones, dispatch, languages } = this.props

    const timeZoneOptions = []

    timeZones.map((option) => {
      timeZoneOptions.push(<MenuItem value={option.ID} key={option.ID} primaryText={option.Description} />)
    })

    const languageOptions = []

    languages.map((language) => {
      languageOptions.push(<MenuItem value={language.Value} key={language.Value} primaryText={language.Text} />)
    })

    return (
      <List style={{ padding: '0px 20px', backgroundColor: palette.accent2Color }}>
        <ListItem
          style={style.listItemStyle}
        />
        <ListItemText primary={
          <FormsyText
            required
            fullWidth
            name='first_name'
            floatingLabelText={language.translate('application.firstName')}
            value={user.FirstName}
            onBlur={(event) => {
              if (user.FirstName !== event.currentTarget.value) {
                user.FirstName = event.currentTarget.value
                onSave(user)
              }
            }}
            validationErrors={language.messages.validationErrors}
          />
        }
        />
        <ListItem
          style={style.listItemStyle}
        />
        <ListItemText primary={
          <FormsyText
            required
            fullWidth
            name='last_name'
            floatingLabelText={language.translate('application.lastName')}
            value={user.LastName}
            onBlur={(event) => {
              if (user.LastName !== event.currentTarget.value) {
                user.LastName = event.currentTarget.value
                onSave(user)
              }
            }}
            validationErrors={language.messages.validationErrors}
          />
        }
        />
        <ListItem
          style={style.listItemStyle}
        />
        <ListItemText primary={
          <FormsyText
            required
            fullWidth
            name='email'
            floatingLabelText={language.translate('application.emailAddress')}
            value={user.EmailAddress}
            onBlur={(event) => {
              if (user.EmailAddress !== event.currentTarget.value) {
                user.EmailAddress = event.currentTarget.value
                onSave(user)
              }
            }}
            validations={'isEmail:true'}
            validationErrors={language.messages.validationErrors}
          />
        }
        />
        <ListItem
          style={style.listItemStyle}
        />
        <ListItemText primary={
          <FormsyText
            fullWidth
            name='position'
            floatingLabelText={language.translate('application.position')}
            value={user.Position}
            onBlur={(event) => {
              if (user.Position !== event.currentTarget.value) {
                user.Position = event.currentTarget.value
                onSave(user)
              }
            }}
            validationErrors={language.messages.validationErrors}
          />
        }
        />
        <ListItem
          style={style.listItemStyle}
        />
        <ListItemText primary={
          <FormsyText
            fullWidth
            name='department'
            floatingLabelText={language.translate('application.department')}
            value={user.Department}
            onBlur={(event) => {
              if (user.Department !== event.currentTarget.value) {
                user.Department = event.currentTarget.value
                onSave(user)
              }
            }}
            validationErrors={language.messages.validationErrors}
          />
        }
        />
        <ListItem
          style={style.listItemStyle}
        />
        <ListItemText primary={
          <FormsyText
            fullWidth
            name='main_telephone'
            floatingLabelText={language.translate('application.mainTelephone')}
            value={user.MainTelephone}
            onBlur={(event) => {
              if (user.MainTelephone !== event.currentTarget.value) {
                user.MainTelephone = event.currentTarget.value
                onSave(user)
              }
            }}
            validationErrors={language.messages.validationErrors}
          />
        }
        />
        <ListItem
          style={style.listItemStyle}
        />
        <ListItemText primary={
          <FormsyText
            fullWidth
            name='mobile_telephone'
            floatingLabelText={language.translate('application.mobileTelephone')}
            value={user.MobileTelephone}
            onBlur={(event) => {
              if (user.MobileTelephone !== event.currentTarget.value) {
                user.MobileTelephone = event.currentTarget.value
                onSave(user)
              }
            }}
            validationErrors={language.messages.validationErrors}
          />
        }
        />
        <ListItem
          style={style.listItemStyle}
        />
        <ListItemText primary={
          <FormsySelect
            name='language'
            value={user.Language}
            onChange={(e, value) => {
              user.Language = value
              onSave(user)
            }}
            floatingLabelText={language.translate('application.language')}
            validationErrors={language.messages.validationErrors}
            required
          >
            {languageOptions}
          </FormsySelect>
        }
        />
        <ListItem
          style={style.listItemStyle}
        />
        <ListItemText primary={
          <FormsySelect
            name='timezone'
            value={user.TimeZoneID || ''}
            onChange={(e, value) => {
              user.TimeZoneID = value
              setTimeout(() => { onSave(user) }, 200)
            }}
            floatingLabelText={language.translate('application.timeZone')}
            validationErrors={language.messages.validationErrors}
            required
          >
            {timeZoneOptions}
          </FormsySelect>
        }
        />
        <ListItem
          style={style.listItemStyle}
        />
        <ListItemText primary={
          <FormsySelect
            name='date_format'
            value={user.DateFormat || ''}
            onChange={(e, value) => {
              user.DateFormat = value
              onSave(user)
            }}
            floatingLabelText={language.translate('application.dateFormat')}
            validationErrors={language.messages.validationErrors}
          >
            {[
              <MenuItem value='MM/DD/YYYY' key={1} primaryText='12/31/2016' />,
              <MenuItem value='DD/MM/YYYY' key={2} primaryText='31/12/2016' />,
              <MenuItem value='YYYY-MM-DD' key={3} primaryText='2016-12-31' />
            ]}
          </FormsySelect>
        }
        />
        <ListItem
          style={style.listItemStyle}
        />
        <ListItemText primary={
          <GroupUserSelect
            name={language.translate('application.supervisor')}
            dispatch={dispatch}
            allowAllUsers
            allowAllGroups
            isSecondary
            groupValue={user.SupervisorUserGroupID}
            userValue={user.SupervisorUserID}
            onGroupChange={(groupId) => {
              if (user.SupervisorUserGroupID !== groupId) {
                user.SupervisorUserGroupID = groupId
                user.SupervisorUserID = 0
                onSave(user)
              }
            }}
            onUserChange={(userId) => {
              if (user.SupervisorUserID !== userId) {
                user.SupervisorUserID = userId
                onSave(user)
              }
            }}
          />
        }
        />
        <ListItem
          style={style.listItemStyle}
        />
        <ListItemText primary={
          <FormsyText
            fullWidth
            name='company_name'
            floatingLabelText={language.translate('application.companyName')}
            value={user.CompanyName}
            onBlur={(event) => {
              if (user.CompanyName !== event.currentTarget.value) {
                user.CompanyName = event.currentTarget.value
                onSave(user)
              }
            }}
            validationErrors={language.messages.validationErrors}
          />
        }
        />
        <ListItem
          style={style.listItemStyle}
        />
        <ListItemText primary={
          <FormsyText
            fullWidth
            name='company_website'
            floatingLabelText={language.translate('application.companyWebsite')}
            value={user.CompanyWebsite}
            onBlur={(event) => {
              if (user.CompanyWebsite !== event.currentTarget.value) {
                user.CompanyWebsite = event.currentTarget.value
                onSave(user)
              }
            }}
            validationErrors={language.messages.validationErrors}
          />
        }
        />
        <ListItem
          style={style.listItemStyle}
        />
        <ListItemText primary={
          <div style={{ paddingTop: '20px' }}>
            {language.translate('application.lastLoginDate')}: {(user.LastLoginDate_Local) ? moment(user.LastLoginDate_Local).format('ddd, MMM D, YYYY [at] h:mma') : 'never'}
          </div>
        }
        />
      </List>
    )
  }
}

PersonalInformation.propTypes = {
  user: PropTypes.object,
  timeZones: PropTypes.array,
  onSave: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
  languages: PropTypes.array
}

PersonalInformation.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default PersonalInformation
