import PropTypes from 'prop-types'
import React from 'react'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import FormHelper from '../../businessLogic/formHelper'
import FieldWrapper from './FieldWrapper'

// TODO: Update this to formsy checkbox but a bug in MUI is preventing it from working

class CheckBox extends React.Component {
  constructor (props, context) {
    super(props, context)
    this.state = {}
  }

  handleCheckChange (id, description, isChecked) {
    const { field, onChange } = this.props

    let ids = (field.ValueID) ? field.ValueID.split(',').filter(Boolean) : []
    let descriptions = (field.Value) ? field.Value.split(',').filter(Boolean) : []

    descriptions = descriptions.map((item) => (item.trim()))

    if (isChecked) {
      ids = ids.concat([id])
      descriptions = descriptions.concat([description])
    } else {
      const indexOfID = ids.indexOf(id.toString())
      if (indexOfID >= 0) ids.splice(indexOfID, 1)

      const indexOfDescription = descriptions.indexOf(description)
      if (indexOfDescription >= 0) descriptions.splice(indexOfDescription, 1)
    }

    field.ValueID = ids.toString()
    field.Value = descriptions.toString()

    if (onChange) { onChange(field) }
  }

  render () {
    const { field, wrapperProps } = this.props
    const { muiTheme: { palette }, language } = this.context

    const checkedValuesArray = (field.ValueID) ? field.ValueID.split(',') : []

    return (
      <FieldWrapper
        label={FormHelper.decodeHTML(field.FieldName)}
        required={field.RequiredField}
        showDivider
        {...wrapperProps}
      >
        {(field.ReadOnlyField && !field.FieldOptionList)
          ? <div style={{ position: 'relative', padding: '5px 0px', fontSize: '15px' }}>
            {field.Value}
          </div>
          : (field.FieldOptionList) ? <div style={{ display: 'flex', flexDirection: 'column' }}>
            {field.FieldOptionList.map((checkbox, index) => (<FormControlLabel
              key={checkbox.ID}
              control={<Checkbox
                style={{ padding: '4px' }}
                checked={(checkedValuesArray.indexOf(checkbox.ID.toString()) >= 0)}
                color='primary'
                disabled={(field.ReadOnlyField)}
                onChange={(e, isChecked) => {
                  this.handleCheckChange(checkbox.ID, checkbox.OptionDescription, isChecked)
                }}
              />}
              label={checkbox.OptionDescription}
            />))}
          </div> : null}
        {(!checkedValuesArray.length && field.RequiredField)
          ? <div
            style={{
              color: palette.errorColor,
              fontSize: '12px'
            }}
          >{language.messages.validationErrors.isDefaultRequiredValue}</div>
          : ''
        }
      </FieldWrapper>
    )
  }
}

CheckBox.propTypes = {
  field: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  wrapperProps: PropTypes.object
}

CheckBox.contextTypes = {
  muiTheme: PropTypes.object,
  language: PropTypes.object
}

export default CheckBox
