import PropTypes from 'prop-types'
import React from 'react'
import * as Icons from './selection.json'// the file exported from IcoMoon
import objectAssign from 'object-assign'

const unknownIcon = {
  paths: [
    'M965.168 0.016c-0.064 0-0.112-0.016-0.176-0.016h-905.617c-0.064 0-0.112 0.016-0.176 0.016-32.713 0.112-59.2 25.783-59.2 57.503v907.121c0 32.777 26.583 59.376 59.376 59.376h905.617c32.793 0 59.392-26.599 59.392-59.376v-907.121c0-31.72-26.503-57.391-59.216-57.503z',
    'M928.343 191.939h-864.23v736.18c0 17.685 14.34 32.025 32.009 32.025h832.221c17.669 0 32.009-14.34 32.009-32.025v-736.18h-32.009z',
    'M512.232 383.99c-70.723 0-128.034 57.311-128.034 128.034 0 17.669 14.34 32.009 32.009 32.009s32.009-14.34 32.009-32.009c0-35.353 28.664-64.017 64.017-64.017s64.017 28.664 64.017 64.017c0 66.37-96.026 46.028-96.026 160.043h64.017c0-61.008 96.026-53.326 96.026-160.043 0-70.723-57.327-128.034-128.034-128.034zM512.232 704.075c-17.685 0-32.009 14.324-32.009 32.025 0 17.669 14.324 31.992 32.009 31.992 17.669 0 32.009-14.324 32.009-31.992 0-17.701-14.34-32.025-32.009-32.025z',
    'M736.292 63.921c-0.048 0-0.096 0.016-0.16 0.016h-640.010c-17.669 0-32.009 14.34-32.009 32.009s14.34 32.009 32.009 32.009h640.17c17.669 0 32.009-14.34 32.009-32.009 0-17.685-14.34-32.025-32.009-32.025zM832.317 63.921c-17.669 0-32.009 14.34-32.009 32.025 0 17.669 14.34 31.992 32.009 31.992s32.009-14.34 32.009-31.992c0-17.685-14.34-32.025-32.009-32.025zM928.343 63.921c-17.669 0-32.009 14.34-32.009 32.025 0 17.669 14.34 31.992 32.009 31.992s32.009-14.34 32.009-31.992c0-17.685-14.34-32.025-32.009-32.025z'
  ],
  attrs: [
    {
      fill: 'rgb(204, 204, 204)'
    },
    {
      fill: 'rgb(255, 255, 255)'
    },
    {
      fill: 'rgb(155, 124, 171)'
    },
    {
      fill: 'rgb(255, 255, 255)'
    }
  ]
}

class Icon extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      paths: [],
      attributes: [],
      width: 1024
    }
  }

  componentDidMount () {
    this.setIconStates()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.icon !== prevProps.icon) { this.setIconStates() }
  }

  setIconStates () {
    const icon = this.getPath(this.props.icon)

    this.setState({
      paths: icon.paths,
      attributes: icon.attrs,
      width: icon.width || 1024
    })
  }

  getPath (iconName) {
    const icon = Icons.icons.find(icon => icon.properties.name === iconName)

    if (icon) {
      return icon.icon
    }
    return unknownIcon
  }

  render () {
    const { paths, attributes, width } = this.state
    const { color, size = 40, style, ...rest } = this.props
    const newStyle = objectAssign({}, { margin: '0px' }, style)

    return (
      <svg width={`${size}px`} height={`${size}px`} viewBox={`0 0 ${width} 1024`} style={newStyle} {...rest}>
        {paths.map((path, index) => (
          <path
            key={index}
            d={path}
            fill={(color) || attributes[index].fill}
            stroke={(attributes[index].stroke) ? attributes[index].stroke : null}
            strokeWidth={(attributes[index].strokeWidth) ? attributes[index].strokeWidth : null}
            strokeLinejoin={(attributes[index].strokeLinejoin) ? attributes[index].strokeLinejoin : null}
            strokeLinecap={(attributes[index].strokeLinecap) ? attributes[index].strokeLinecap : null}
            strokeMiterlimit={(attributes[index].strokeMiterlimit) ? attributes[index].strokeMiterlimit : null}
          />
        ))}
      </svg>
    )
  }
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.number,
  style: PropTypes.object,
  color: PropTypes.string
}

export default Icon
