import PropTypes from 'prop-types'
import React from 'react'
import { Divider, List, ListItem, ListItemSecondaryAction, ListItemText, Switch, TextField } from '@material-ui/core'

class TemplateTaskAdvancedSettings extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      task: {}
    }
  }

  componentDidMount () {
    this.setState({
      task: this.props.task
    })
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (this.props.task !== nextProps.task) { return true }

    return true
  }

  componentDidUpdate (prevProps, prevState) {
    if (JSON.stringify(prevProps.task) !== JSON.stringify(this.props.task)) { this.setState({ task: this.props.task }) }
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const { onSave, timeFormat } = this.props
    const { task } = this.state

    // fix bug caused by toggles not updating if they were already set to empty when loaded (caused by no task data loaded yet)
    if (!Object.keys(task).length) {
      return null
    }

    return (
      <List key={1} style={{ padding: '0px 20px', backgroundColor: palette.accent2Color }}>
        {(task.MilestoneDescription)
          ? React.Children.toArray([
            <Divider key={1} />,
            <ListItem>
              <ListItemText primary={language.translate('application.milestoneDisplayOrder')} />
              <ListItemSecondaryAction>
                <TextField
                  name='milestone_description'
                  style={{ width: '50px' }}
                  defaultValue={task.MilestoneDisplayOrder}
                  onBlur={(e) => {
                    task.MilestoneDisplayOrder = e.currentTarget.value
                    onSave(task)
                  }}
                />
              </ListItemSecondaryAction>
            </ListItem>])
          : null}
        <Divider />
        <ListItem>
          <ListItemText primary={language.translate('application.allowTaskReassigned')} />
          <ListItemSecondaryAction>
            <Switch
              color='primary'
              checked={task.AllowTaskReassignment}
              onChange={(obj, value) => {
                task.AllowTaskReassignment = !task.AllowTaskReassignment
                onSave(task)
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary={language.translate('application.requireTaskNote')} />
          <ListItemSecondaryAction>
            <Switch
              color='primary'
              checked={task.NoteRequired}
              onChange={(obj, value) => {
                task.NoteRequired = !task.NoteRequired
                onSave(task)
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary={language.translate('application.publicFormTask')} />
          <ListItemSecondaryAction>
            <Switch
              color='primary'
              checked={task.PublicTask}
              onChange={(obj, value) => {
                task.PublicTask = !task.PublicTask
                onSave(task)
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        {(timeFormat === 'M')
          ? <ListItem>
            <ListItemText primary={
              <div>
                {language.translate('application.estimatedMinutes')}
              </div>
            } />
            <ListItemSecondaryAction>
              <TextField
                type='tel'
                name='estimated_minutes'
                style={{ width: '60px' }}
                defaultValue={task.EstimatedMinutes}
                onBlur={(e) => {
                  task.EstimatedMinutes = e.currentTarget.value
                  onSave(task)
                }}
              />
            </ListItemSecondaryAction>
          </ListItem>
          : <ListItem>
            <ListItemText primary={
              <div>
                {language.translate('application.estimatedHours')}
                <i style={{ fontSize: '11px' }}
                >({language.translate('application.example')}: 0.3 =
                  18 {language.translate('application.minute')})</i>
              </div>
            } />
            <ListItemSecondaryAction>
              <TextField
                type='tel'
                name='estimated_hours'
                style={{ width: '60px' }}
                defaultValue={task.EstimatedHours}
                onBlur={(e) => {
                  task.EstimatedHours = e.currentTarget.value
                  onSave(task)
                }}
              />
            </ListItemSecondaryAction>
          </ListItem>}
        <Divider />
        <ListItem>
          <ListItemText primary={language.translate('application.prefillDefaultHours')} />
          <ListItemSecondaryAction>
            <Switch
              color='primary'
              checked={task.DefaultHoursOnTaskInstances}
              onChange={(obj, value) => {
                task.DefaultHoursOnTaskInstances = !task.DefaultHoursOnTaskInstances
                onSave(task)
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary={language.translate('application.sendEmailOnTaskAssignment')} />
          <ListItemSecondaryAction>
            <Switch
              color='primary'
              checked={task.SendTaskAssignmentEmails}
              onChange={(obj, value) => {
                task.SendTaskAssignmentEmails = !task.SendTaskAssignmentEmails
                onSave(task)
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <Divider />
        <ListItem>
          <ListItemText primary={language.translate('application.requirePrecedingTasksComplete')} />
          <ListItemSecondaryAction>
            <Switch
              color='primary'
              checked={task.CompleteAllPrecedingTasksFirst}
              onChange={(obj, value) => {
                task.CompleteAllPrecedingTasksFirst = !task.CompleteAllPrecedingTasksFirst
                onSave(task)
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary={language.translate('application.requirePendingTasksComplete')} />
          <ListItemSecondaryAction>
            <Switch
              color='primary'
              checked={task.CompleteAllPendingTasksFirst}
              onChange={(obj, value) => {
                task.CompleteAllPendingTasksFirst = !task.CompleteAllPendingTasksFirst
                onSave(task)
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary={language.translate('application.requireSubprocessesComplete')} />
          <ListItemSecondaryAction>
            <Switch
              color='primary'
              checked={task.CompleteAllPendingSubprocessesFirst}
              onChange={(obj, value) => {
                task.CompleteAllPendingSubprocessesFirst = !task.CompleteAllPendingSubprocessesFirst
                onSave(task)
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary={language.translate('application.allowMoreThanOneTask')} />
          <ListItemSecondaryAction>
            <Switch
              color='primary'
              checked={task.AllowMultipleInstances}
              onChange={(obj, value) => {
                task.AllowMultipleInstances = !task.AllowMultipleInstances
                onSave(task)
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    )
  }
}

TemplateTaskAdvancedSettings.propTypes = {
  task: PropTypes.object,
  onSave: PropTypes.func,
  timeFormat: PropTypes.string
}

TemplateTaskAdvancedSettings.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default TemplateTaskAdvancedSettings
