import PropTypes from 'prop-types'
import React from 'react'
import { Button, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import TemplateTaskChecklistItem from './TemplateTaskChecklistItem'
import ReorderableList from '../Layout/ReorderableList'

class TemplateTaskChecklist extends React.Component {
  getRequirementOptionMenu () {
    const { checklistRequirementOptions } = this.props
    const menu = []

    checklistRequirementOptions.map((option, index) => {
      menu.push(<MenuItem value={option.Value} key={option.Value}>{option.Text}</MenuItem>)
    })

    return menu
  }

  handleChange (index, value) {
    const { checklist } = this.props
    checklist[index].ItemDescription = value

    this.props.onSaveItem(checklist[index], index)
  }

  handleDelete (item, index) {
    const { onDelete, checklist } = this.props

    if (!item.ID) {
      checklist.splice(index, 1)
      this.setState({ checklist })
    } else { onDelete(item) }
  }

  updateDisplayProperties () {
    const { checklist } = this.props

    checklist.map((item, index) => {
      item.DisplayOrder = index + 1
    })

    this.props.onSaveList(checklist)
  }

  render () {
    const { palette } = this.context.muiTheme
    const { language } = this.context
    const { task, onSaveTask, addNewItem, checklist, reorderChecklist } = this.props

    return (
      <div style={{ margin: '0px 20px' }}>
        {(!checklist || !checklist.length)
          ? <div>{language.translate('application.noChecklistItems')}</div>
          : <div style={{
            width: '80%',
            display: 'flex',
            flexDirection: 'column',
            margin: 'auto',
            backgroundColor: palette.canvasColor,
            border: `1px solid ${palette.borderColor}`,
            borderRadius: '10px'
          }}
          >
            <ReorderableList style={{ padding: '0px' }}>
              {checklist.map((item, index) => (
                <TemplateTaskChecklistItem
                  item={item}
                  key={item.ID || index}
                  index={index}
                  ref='checklist-item'
                  onReorderRequest={reorderChecklist}
                  onRemoveField={() => this.handleDelete(item, index)}
                  droppedItem={this.updateDisplayProperties.bind(this)}
                  onSave={value => this.handleChange(index, value)}
                  style={{
                    backgroundColor: (index % 2 === 0) ? palette.accent2Color : palette.canvasColor,
                    borderBottom: (index === checklist.length - 1) ? 'none' : `1px solid ${palette.borderColor}`
                  }}
                />
              ))}
            </ReorderableList>
          </div>
        }
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'flex-end'
        }}
        >
          <Button
            onClick={addNewItem}
            color='primary'
            variant='contained'
            style={{ margin: '10px' }}
          >
            <Add />
            {language.translate('application.addItem')}
          </Button>
          <FormControl style={{ width: '250px' }}>
            <InputLabel style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {language.translate('application.checklistRequirementOptions')}
            </InputLabel>
            <Select
              name='requirement_option'
              onChange={(e) => {
                task.ChecklistRequirementOption = e.target.value
                onSaveTask(task)
              }}
              value={task.ChecklistRequirementOption}
            >
              {this.getRequirementOptionMenu()}
            </Select>
          </FormControl>
        </div>
      </div>
    )
  }
}

TemplateTaskChecklist.propTypes = {
  checklistRequirementOptions: PropTypes.array.isRequired,
  task: PropTypes.object,
  onSaveTask: PropTypes.func,
  checklist: PropTypes.array,
  onSaveList: PropTypes.func,
  onSaveItem: PropTypes.func,
  onDelete: PropTypes.func,
  addNewItem: PropTypes.func,
  reorderChecklist: PropTypes.func
}

TemplateTaskChecklist.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default TemplateTaskChecklist
