import PropTypes from 'prop-types'
import React from 'react'

class InactiveAccountMessage extends React.Component {
  render () {
    const { user, muiTheme, language } = this.context
    const { palette } = muiTheme

    return (
      (!user.accountActive)
        ? <div style={{ textAlign: 'center', backgroundColor: palette.errorColor, color: '#fff', top: '64px', position: 'relative', zIndex: 1200 }}>
          {language.translate('application.accountInactive')}
          {(document.location.host !== 'mobile.processplan.com')
            ? <a
              href='/plan'
              onClick={(e) => {
                e.preventDefault()
                this.props.onMessageClick()
              }}
              style={{ color: palette.alternateTextColor, margin: '0px 5px' }}
            >
              {language.translate('application.goToPlanPaymentInfo')}
            </a>
            : null}
        </div>
        : null
    )
  }
}

InactiveAccountMessage.propTypes = {
  onMessageClick: PropTypes.func.isRequired
}

InactiveAccountMessage.contextTypes = {
  user: PropTypes.object,
  muiTheme: PropTypes.object,
  language: PropTypes.object
}

export default InactiveAccountMessage
