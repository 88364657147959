import {
  ADD_MENU_ITEM, HIDE_FILTER_LIST, HIDE_SEARCH_FIELD, REMOVE_MENU_ITEM, SET_SELECTED_FILTER,
  SET_SELECTED_MENU_ITEM, SHOW_FILTER_LIST, SHOW_SEARCH_FIELD, TOGGLE_TITLE
} from '../constants/HeaderTypes'

export function showSearchField (filterableColumns) {
  return {
    type: SHOW_SEARCH_FIELD,
    payload: {
      filterableColumns
    }
  }
}

export function hideSearchField () {
  return {
    type: HIDE_SEARCH_FIELD
  }
}

export function showFilterList (filterItems, callback) {
  return {
    type: SHOW_FILTER_LIST,
    payload: {
      items: filterItems,
      callback
    }
  }
}

export function hideFilterList () {
  return {
    type: HIDE_FILTER_LIST
  }
}

export function selectedFilterValue (text) {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_FILTER,
      payload: {
        text
      }
    })
  }
}

export function filterDataOnPage (filter, url = '/') {
  return function (dispatch, state) {
    const currentPage = state().routing.locationBeforeTransitions.pathname

    // make sure they aren't trying to display the same information on the page they are already on
    if (state().header.selectedFilterValue !== filter || currentPage !== url) {
      dispatch(selectedFilterValue(filter))
      dispatch(executeFilterCallback())
    }
  }
}

export function executeFilterCallback () {
  return function (dispatch, state) {
    state().header.onFilterSelect(state().header.selectedFilterValue)
  }
}

/*
 * Menu Items in top right menu
 */
export function addMenuItem (title, callback) {
  return {
    type: ADD_MENU_ITEM,
    payload: {
      title,
      callback
    }
  }
}

export function removeMenuItem (title) {
  return {
    type: REMOVE_MENU_ITEM,
    payload: {
      title
    }
  }
}

export function menuItemClicked (item) {
  return function (dispatch) {
    dispatch(selectedMenuItemValue(item.title))

    item.callback()
  }
}

export function selectedMenuItemValue (text) {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_MENU_ITEM,
      payload: {
        text
      }
    })
  }
}

export function hideTitle () {
  return function (dispatch, state) {
    if (state().header.showTitle === true) { dispatch(toggleTitle()) }
  }
}

export function showTitle () {
  return function (dispatch, state) {
    if (state().header.showTitle === false) { dispatch(toggleTitle()) }
  }
}

export function toggleTitle () {
  return {
    type: TOGGLE_TITLE
  }
}
