import { createReducer } from '../utils'
import {
  LOGOUT_USER, SET_ACCOUNT_DATA, SET_LOGGED_IN, SET_LOGIN_DATA, SET_USER_DATA, UPDATE_HELP_PAGES_VIEWED,
  UPDATE_PROFILE_PIC_URL, UPDATE_USER_SESSION
} from '../constants/AuthenticationTypes'
import objectAssign from 'object-assign'

const initialState = {
  token: window.localStorage.getItem('token'),
  userName: window.localStorage.getItem('username'),
  userid: window.localStorage.getItem('userid'),
  groupIDs: JSON.parse(window.localStorage.getItem('groupIDs')),
  isAuthenticated: !!window.localStorage.getItem('token'),
  isAuthenticating: false,
  accountName: window.localStorage.getItem('accountName'),
  accountID: window.localStorage.getItem('accountID'),
  accountStatus: window.localStorage.getItem('accountStatus'),
  accountActive: (window.localStorage.getItem('accountActive') === 'true'),
  accountImageLight: window.localStorage.getItem('accountImageLight'),
  accountImageDark: window.localStorage.getItem('accountImageDark'),
  partnerImageLight: window.localStorage.getItem('partnerImageLight'),
  partnerImageDark: window.localStorage.getItem('partnerImageDark'),
  partnerWebsiteURL: window.localStorage.getItem('partnerWebsiteURL'),
  accountCreationDateUTC: window.localStorage.getItem('accountCreationDateUTC'),
  templateLibraryAccount: window.localStorage.getItem('templateLibraryAccount'),
  timeTrackingUnit: window.localStorage.getItem('timeTrackingUnit'),
  firstName: window.localStorage.getItem('firstName'),
  lastName: window.localStorage.getItem('lastName'),
  fullName: window.localStorage.getItem('fullName'),
  position: window.localStorage.getItem('position'),
  utcOffset: window.localStorage.getItem('utcOffset'),
  profilePicURL: window.localStorage.getItem('profilePicURL'),
  language: (window.localStorage.getItem('language')) ? window.localStorage.getItem('language') : 'en_US',
  dateFormat: (window.localStorage.getItem('dateFormat')) ? window.localStorage.getItem('dateFormat') : 'YYYY-MM-DD',
  helpPagesViewed: JSON.parse(window.localStorage.getItem('helpPagesViewed')),
  userCustomizations: JSON.parse(window.localStorage.getItem('userCustomizations')) || {},
  accountList: JSON.parse(window.localStorage.getItem('accountList')),
  customizations: JSON.parse(window.localStorage.getItem('customizations')) || {},
  canCancelProcesses: (window.localStorage.getItem('canCancelProcesses') === 'true'),
  canInitiateProcesses: (window.localStorage.getItem('canInitiateProcesses') === 'true'),
  canManageAccountInformation: (window.localStorage.getItem('canManageAccountInformation') === 'true'),
  canChangeTaskAssignments: (window.localStorage.getItem('canChangeTaskAssignments') === 'true'),
  canManageProcessTemplates: (window.localStorage.getItem('canManageProcessTemplates') === 'true'),
  canManageSubscriptionLevel: (window.localStorage.getItem('canManageSubscriptionLevel') === 'true'),
  canManageUsers: (window.localStorage.getItem('canManageUsers') === 'true'),
  canViewAllProcesses: (window.localStorage.getItem('canViewAllProcesses') === 'true'),
  canViewMyProcesses: (window.localStorage.getItem('canViewMyProcesses') === 'true'),
  canViewProcessReports: (window.localStorage.getItem('canViewProcessReports') === 'true'),
  canEditInstanceDiagrams: (window.localStorage.getItem('canEditInstanceDiagrams') === 'true'),
  canRestartInstanceTasks: (window.localStorage.getItem('canRestartInstanceTasks') === 'true'),
  canCreateProcessTemplates: (window.localStorage.getItem('canCreateProcessTemplates') === 'true'),
  canScheduleOrLaunchMultipleProcesses: (window.localStorage.getItem('canScheduleOrLaunchMultipleProcesses') === 'true'),
  isOnDemandUser: (window.localStorage.getItem('isOnDemandUser') === 'true'),
  ssoEnabled: (window.localStorage.getItem('ssoEnabled') === 'true'),
  samlSignOnURL: window.localStorage.getItem('samlSignOnURL'),
  samlLogoutURL: window.localStorage.getItem('samlLogoutURL')
}

export default createReducer(initialState, {
  [SET_LOGGED_IN]: state => objectAssign({}, state, {
    isAuthenticating: false,
    isAuthenticated: true
  }),
  [SET_ACCOUNT_DATA]: (state, account) => {
    let newState = {
      accountName: account.AccountName || '',
      accountID: account.ID || null,
      accountStatus: account.AccountStatus || '',
      accountActive: account.AccountActive,
      accountImageLight: account.HeaderLogoWhiteBG_URL || '',
      accountImageDark: account.HeaderLogoDarkBG_URL || '',
      partnerImageLight: account.PartnerHeaderLogoWhiteBG_URL,
      partnerImageDark: account.PartnerHeaderLogoDarkBG_URL,
      partnerWebsiteURL: account.PartnerHeaderWebsiteURL,
      accountCreationDateUTC: account.CreatedDate_UTC || '',
      templateLibraryAccount: account.TemplateLibraryAccount,
      timeTrackingUnit: account.TimeTrackingUnit,
      customizations: account.Customizations,
      ssoEnabled: account.SSOEnabled,
      samlSignOnURL: account.SAML_SingleSignOnUrl,
      samlLogoutURL: account.SAML_SingleLogoutUrl
    }

    if (account.setDateFormat) {
      newState.dateFormat = account.DefaultDateFormat || 'YYYY-MM-DD'
    }

    return (objectAssign({}, state, newState))
  },
  [SET_USER_DATA]: (state, user) => objectAssign({}, state, {
    userName: user.EmailAddress,
    userid: user.ID,
    groupIDs: user.UserGroupIDList,
    firstName: user.FirstName,
    lastName: user.LastName,
    fullName: user.FullName,
    position: user.Position,
    utcOffset: user.UTCOffset,
    profilePicURL: user.ProfilePicURL,
    language: user.Language.replace('-', '_') || 'en_US',
    dateFormat: user.DateFormat || 'YYYY-MM-DD',
    helpPagesViewed: user.HelpPagesViewed,
    userCustomizations: (user.Customizations) ? JSON.parse(user.Customizations) : {},
    canCancelProcesses: user.AccessControl_CancelProcesses,
    canInitiateProcesses: user.AccessControl_InitiateProcesses,
    canChangeTaskAssignments: user.AccessControl_ChangeTaskAssignments,
    canManageAccountInformation: user.AccessControl_ManageAccountInformation,
    canManageProcessTemplates: user.AccessControl_ManageProcessTemplates,
    canManageSubscriptionLevel: user.AccessControl_ManageSubscriptionLevel,
    canManageUsers: user.AccessControl_ManageUsers,
    canViewAllProcesses: user.AccessControl_ViewAllProcesses,
    canViewMyProcesses: user.AccessControl_ViewMyProcesses,
    canViewProcessReports: user.AccessControl_ViewProcessReports,
    canEditInstanceDiagrams: user.AccessControl_EditInstanceDiagrams,
    canScheduleOrLaunchMultipleProcesses: user.AccessControl_BatchProcessing,
    canRestartInstanceTasks: user.AccessControl_RestartInstanceTasks,
    canCreateProcessTemplates: user.AccessControl_CreateProcessTemplates,
    isOnDemandUser: user.IsOnDemand
  }),
  [SET_LOGIN_DATA]: (state, user) => objectAssign({}, state, {
    token: user.SessionToken,
    accountList: user.Accounts
  }),
  [UPDATE_PROFILE_PIC_URL]: (state, url) => objectAssign({}, state, {
    profilePicURL: url
  }),
  [UPDATE_HELP_PAGES_VIEWED]: (state, pages) => objectAssign({}, state, {
    helpPagesViewed: pages
  }),
  [UPDATE_USER_SESSION]: (state, payload) => objectAssign({}, state, {
    [payload.property]: payload.value
  }),
  [LOGOUT_USER]: state => objectAssign({}, state, {
    isAuthenticated: false,
    isAuthenticating: false,
    token: null,
    userName: null,
    userid: null,
    groupIDs: null,
    accountName: null,
    accountID: null,
    accountStatus: null,
    accountActive: null,
    accountImageLight: null,
    accountImageDark: null,
    partnerImageLight: null,
    partnerImageDark: null,
    partnerWebsiteURL: null,
    accountCreationDateUTC: null,
    templateLibraryAccount: null,
    timeTrackingUnit: null,
    firstName: null,
    lastName: null,
    fullName: null,
    position: null,
    utcOffset: null,
    profilePicURL: null,
    language: 'en_US',
    dateFormat: 'YYYY-MM-DD',
    helpPagesViewed: null,
    userCustomizations: {},
    accountList: null,
    customizations: {},
    canCancelProcesses: null,
    canInitiateProcesses: null,
    canManageAccountInformation: null,
    canChangeTaskAssignments: null,
    canManageProcessTemplates: null,
    canManageSubscriptionLevel: null,
    canManageUsers: null,
    canViewAllProcesses: null,
    canViewMyProcesses: null,
    canViewProcessReports: null,
    canEditInstanceDiagrams: null,
    canRestartInstanceTasks: null,
    canCreateProcessTemplates: null,
    isOnDemandUser: null,
    ssoEnabled: null,
    samlSignOnURL: null,
    samlLogoutURL: null
  })
})
