import PropTypes from 'prop-types'
import React from 'react'
import { Avatar, Card, CardContent, IconButton, MenuItem, Menu } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import './UserCard.scss'

class UserCard extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      menuAnchor: null
    }
  }

  handleUserSelect (userId) {
    this.props.onUserSelect(userId)
  }

  calculateCardWidth () {
    const { mainContentWidth } = this.props

    if (mainContentWidth > 1100) { return '30%' }

    if (mainContentWidth > 750) { return '45%' }

    return '90%'
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { menuAnchor } = this.state
    const { language } = this.context
    const { location, user, onStatisticsClick, onViewWorkListClick } = this.props

    return (
      <Card
        className='card clickable user-card'
        style={{
          width: this.calculateCardWidth(),
          marginLeft: '10px',
          marginRight: '10px',
          marginTop: '20px',
          marginBottom: '20px',
          padding: '0px',
          backgroundColor: (location.query.usid === user.ID)
            ? palette.borderColor
            : palette.canvasColor
        }}
      >

        <IconButton
          style={{
            width: '24px',
            height: '24px',
            padding: '0px',
            float: 'right',
            marginTop: '5px'
          }}
          onClick={(e) => {
            this.setState({
              menuAnchor: e.currentTarget
            })
          }}
        >
          <MoreVert />
        </IconButton>
        <Menu
          className='menu'
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClose={() => { this.setState({ menuAnchor: null }) }}
          getContentAnchorEl={null}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
          <MenuItem
            onClick={() => {
              this.setState({ menuAnchor: null })
              this.handleUserSelect(user.ID)
            }}
          >{language.translate('application.editUser')}</MenuItem>
          <MenuItem
            onClick={() => {
              this.setState({ menuAnchor: null })
              onStatisticsClick()
            }}
          >{language.translate('application.statistics')}</MenuItem>
          <MenuItem
            onClick={() => {
              this.setState({ menuAnchor: null })
              onViewWorkListClick()
            }}
          >{language.translate('application.viewWorkList')}</MenuItem>
        </Menu>
        <CardContent
          onClick={(e) => {
            this.handleUserSelect(user.ID)
          }}
        >
          <div className='card-content'>
            <div style={{ marginRight: '20px' }}>
              <Avatar src={user.ProfilePicURL} style={{ height: '80px', width: '80px' }} />
            </div>
            <div className='content'>
              <div className='title'>{user.FullName}</div>
              <div className='subtitle'>{user.Position}</div>
              <div className='subtitle'>{(user.Department) ? user.Department : null}</div>
              <div className='subtitle'>{user.EmailAddress}</div>
              <div
                className='subtitle'
              >{(user.MainTelephone) ? `${language.translate('application.main')}: ${user.MainTelephone}` : null}</div>
              <div
                className='subtitle'
              >{(user.MobileTelephone) ? `${language.translate('application.cellNumber')}: ${user.MobileTelephone}` : null}</div>
            </div>

          </div>
        </CardContent>
      </Card>
    )
  }
}

UserCard.propTypes = {
  user: PropTypes.object.isRequired,
  onStatisticsClick: PropTypes.func.isRequired,
  onViewWorkListClick: PropTypes.func.isRequired,
  onUserSelect: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  mainContentWidth: PropTypes.number.isRequired
}

UserCard.contextTypes = {
  muiTheme: PropTypes.object,
  language: PropTypes.object
}
export default UserCard
