import PropTypes from 'prop-types'
import React from 'react'
import FormHelper from '../../businessLogic/formHelper'
import FormsyUpload from '../Forms/FormsyUpload'
import { post } from '../../actions/base'
import FieldWrapper from './FieldWrapper'
import DeleteIconButton from '../Layout/DeleteIconButton'

class Attachment extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      clicked: null
    }
  }

  componentDidUpdate (prevProps, prevState) {
    // added to make attachments clickable from instances grid (table view) without loading so much data from api unnecessarily
    // if user click link, but not enough data is loaded, the link gets flagged while more data gets loaded
    // when the data finishes loading, a new window opens the link
    if (this.state.clicked &&
      prevProps.field.ProcessInstanceFieldFileList &&
      this.props.field.ProcessInstanceFieldFileList &&
      !prevProps.field.ProcessInstanceFieldFileList[0].PrivateFileKey &&
      this.props.field.ProcessInstanceFieldFileList[0].PrivateFileKey) {
      let attachments = this.props.field.ProcessInstanceFieldFileList.filter((file) => (this.state.clicked === file.FileName))
      if (attachments.length) {
        let attachment = attachments[0]
        window.open(`${this.props.apiServer}/open.aspx?filekey=${attachment.PrivateFileKey}&token=${this.context.user.token}&email=${encodeURIComponent(this.context.user.userName)}&`, '_blank')
        this.setState({ clicked: null })
      }
    }
  }

  _handleChange (e) {
    const { field, onChange } = this.props

    const { files } = e.target
    const me = this
    for (let i = 0; i < files.length; i++) {
      const reader = new window.FileReader()

      reader.onload = (function (file) {
        return function (e) {
          const ProcessInstanceFieldFileList = (!field.ProcessInstanceFieldFileList) ? [] : field.ProcessInstanceFieldFileList // make this an empty array if this property isn't sent back from the API

          field.ProcessInstanceFieldFileList = ProcessInstanceFieldFileList.concat([{// handle when this doesn't come back from the API (start process panel)
            FileName: file.name,
            Base64EncodedFile: e.target.result.split(',')[1],
            FileType: file.type
          }])

          if (onChange) { onChange(field) }
        }
      })(files[i])

      reader.readAsDataURL(files[i])
    }
  }

  removeFile (id, index) {
    if (!id) { this.removeUnsavedFile(index) } else { this.removeSavedFile(id, index) }
  }

  removeSavedFile (fileId, index) {
    const body = JSON.stringify({})
    const { field, dispatch, onChange } = this.props

    dispatch(post(
      `processinstance/${field.ProcessInstanceID}/field/${field.ID}/file/${fileId}/delete`,
      body,
      {
        onSuccess: (response) => {
          field.ProcessInstanceFieldFileList.splice(index, 1)

          if (onChange) { onChange(field) }
        }
      }
    ))
  }

  removeUnsavedFile (index) {
    const { onChange, field } = this.props

    field.ProcessInstanceFieldFileList.splice(index, 1)

    if (onChange) { onChange(field) }
  }

  isImage (fileName) {
    if (fileName.match(/.(jpg|jpeg|png|gif)$/i)) {
      return true
    }

    return false
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { user } = this.context
    const { field, isPublic, wrapperProps, apiServer } = this.props
    const fileList = field.ProcessInstanceFieldFileList || []

    return (
      <FieldWrapper
        label={FormHelper.decodeHTML(field.FieldName)}
        required={field.RequiredField}
        showDivider
        {...wrapperProps}
      >
        <div className='input-style'>
          {fileList.map((file, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              {(file.ID)
                ? <a
                  target='_blank'
                  href={(file.PublicURL || file.PrivateFileKey) ? (isPublic) ? file.PublicURL : `${apiServer}/open.aspx?filekey=${file.PrivateFileKey}&token=${user.token}&email=${encodeURIComponent(user.userName)}&` : ''}
                  onClick={(e) => {
                    if (!file.PublicURL && !file.PrivateFileKey) {
                      e.preventDefault()
                      this.setState({ clicked: file.ID })
                    }
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {(file.FileName && this.isImage(file.FileName) &&
                      <div style={{ paddingRight: '5px' }}>
                        <img
                          src={(file.PublicURL || file.PrivateFileKey) ? (isPublic) ? file.PublicURL + '&thumbnail=1&' : `${apiServer}/open.aspx?filekey=${file.PrivateFileKey}&token=${user.token}&email=${encodeURIComponent(user.userName)}&thumbnail=1&` : ''}
                          width={'50px'} />
                      </div>
                    )}
                    {file.FileName}
                  </div>
                </a>
                : <div>
                  {file.FileName}
                </div>}
              {(!field.ReadOnlyField)
                ? <DeleteIconButton onDelete={() => this.removeFile(file.ID, index)} />
                : null}
            </div>
          ))}
        </div>
        {(!field.ReadOnlyField)
          ? <FormsyUpload
            style={{ marginBottom: '10px' }}
            name={field.FieldName}
            required={field.RequiredField}
            multiple
            onChange={this._handleChange.bind(this)}
            defaultValue={field.ProcessInstanceFieldFileList}
          />
          : null}
      </FieldWrapper>
    )
  }
}

Attachment.propTypes = {
  field: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  isPublic: PropTypes.bool,
  wrapperProps: PropTypes.object,
  apiServer: PropTypes.string
}

Attachment.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object,
  user: PropTypes.object
}

export default Attachment
