import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateUserSession } from '../actions/authentication'
import {
  Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton,
  Tooltip, Typography
} from '@material-ui/core'
import { MenuItem } from 'material-ui'
import { Help } from '@material-ui/icons'
import { get, post } from '../actions/base'
import { updateTitle } from '../actions/application'
import { push } from 'react-router-redux'
import { Form } from 'formsy-react'
import { FormsySelect, FormsyText } from 'formsy-material-ui'
import moment from 'moment'
import numeral from 'numeral'

class PlanInformation extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      account: [],
      currentPlanID: null,
      canSubmit: true,
      isLoaded: false,
      plans: [],
      plansLoaded: false,
      countries: [],
      countriesLoaded: false,
      showCvvHelp: false,
      processesStartedThisMonth: 0,
      userCount: 0
    }
  }

  componentDidMount () {
    const { language } = this.context

    this.props.dispatch(updateTitle(language.translate('application.planInformation')))

    this.fetchAccountInformation()
    this.fetchThisMonthProcessCount()
    this.fetchCountries()
    this.fetchUserCount()
  }

  fetchAccountInformation () {
    this.props.get('account', {
      onSuccess: (response) => {
        this.setState({
          account: response.Account,
          isLoaded: true,
          currentPlanID: response.Account.PlanID
        }, () => {
          this.fetchPlans()
        })
      }
    })
  }

  fetchUserCount () {
    this.props.get('user/list/count', {
      onSuccess: (response) => {
        this.setState({
          userCount: response.Count
        })
      }
    })
  }

  fetchThisMonthProcessCount () {
    this.props.get('processinstance/list/startedthismonth/count', {
      onSuccess: (response) => {
        this.setState({
          processesStartedThisMonth: response.Count
        })
      }
    })
  }

  fetchPlans () {
    const { account } = this.state

    this.props.get('plan/list', {
      onSuccess: (response) => {
        if (response.PlanList.filter(plan => (plan.ID === account.PlanID)).length === 0) {
          this.fetchPlanById(account.PlanID, response.PlanList)
        } else {
          this.setState({
            plans: response.PlanList,
            plansLoaded: true
          })
        }
      }
    })
  }

  fetchPlanById (planId, planList) {
    let plans = planList

    this.props.get(`plan/${planId}`, {
      onSuccess: (response) => {
        let plan = response.Plan
        plans.push(plan)
        this.setState({ plans, plansLoaded: true })
      }
    })
  }

  fetchCountries () {
    this.props.get('country/list', {
      onSuccess: (response) => {
        this.setState({
          countries: response.TextValueList,
          countriesLoaded: true
        })
      }
    })
  }

  getYearMenu () {
    const yearMenu = []
    const startYear = moment().year()
    const endYear = moment().year() + 15

    for (let year = startYear; year <= endYear; year++) {
      yearMenu.push(<MenuItem value={year} key={year} primaryText={year.toString()} />)
    }

    return yearMenu
  }

  getPlanInfo (planId) {
    const { plans } = this.state
    let planInfo = null

    if (!planId) { return null }

    plans.map((plan) => {
      if (plan.ID === planId) { planInfo = plan }
    })

    return planInfo
  }

  savePaymentInformation () {
    const { canSubmit, account } = this.state
    const { updateUserSession } = this.props

    if (!canSubmit) { return false }

    this.setState({ canSubmit: false })

    const body = JSON.stringify({
      PlanUpdate: account
    })

    this.props.post('account/updateplan', body, {
      onSuccess: (response) => {
        updateUserSession('accountStatus', response.Account.AccountStatus)
        updateUserSession('accountActive', response.Account.AccountActive)
        this.props.push('/')
      }
    })
  }

  // if account plan id isn't in plan/list, call endpoint to pull back that plan info by id and add it to drop down list

  render () {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const {
      account, countries, countriesLoaded, plans, plansLoaded, showCvvHelp, canSubmit, currentPlanID,
      processesStartedThisMonth, userCount
    } = this.state
    const { mainContentWidth } = this.props
    const selectedPlanInfo = this.getPlanInfo(account.PlanID)
    const currentPlanInfo = this.getPlanInfo(currentPlanID)

    const countryList = []

    if (countriesLoaded) {
      countries.map((country, index) => {
        countryList.push(<MenuItem value={country.Value} key={country.Value} primaryText={country.Text} />)
      })
    }

    const planList = []

    if (plansLoaded) {
      plans.map((plan, index) => {
        planList.push(<MenuItem value={plan.ID} key={plan.ID} primaryText={plan.Description} />)
      })
    }

    return (
      <div>
        {(this.state.isLoading || !plansLoaded)
          ? <CircularProgress className='loader' />

          : <Form
            onValid={() => this.setState({ canSubmit: true })}
            onInvalid={() => this.setState({ canSubmit: false })}
          >
            <div style={{
              display: 'flex',
              justifyContent: 'space-around',
              flexDirection: 'row',
              flexWrap: 'wrap',
              alignItems: 'flex-start'
            }}
            >
              <Card
                className='card'
                style={{
                  width: (mainContentWidth > 1100) ? '30%' : (mainContentWidth > 750) ? '45%' : '90%',
                  maxWidth: '450px',
                  marginLeft: '10px',
                  marginRight: '10px'
                }}
              >
                <CardHeader
                  title='Plan'
                  subheader={
                    <div>
                      <div>{language.translate('application.currentUserCount', [userCount])}</div>
                      <div>{(currentPlanInfo) ? `${language.translate('application.currentPlan')}: ${currentPlanInfo.Description}` : null}</div>
                    </div>}
                />
                <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <FormsySelect
                    name='plan'
                    value={account.PlanID}
                    onChange={(e, value) => {
                      account.PlanID = value
                      this.setState({ account })
                    }}
                    floatingLabelText={language.translate('application.selectNewPlan')}
                    validationErrors={language.messages.validationErrors}
                  >
                    {planList}
                  </FormsySelect>
                  {(selectedPlanInfo.Unit === 'PerUser' &&
                    <FormsyText
                      name='userLimit'
                      floatingLabelText={language.translate('application.numberOfUsersNeeded')}
                      value={account.UserLimit}
                      onBlur={(event) => {
                        let number = event.currentTarget.value
                        const isNumeric = (!isNaN(parseFloat(number)) && isFinite(number))

                        if (isNumeric) {
                          account.UserLimit = parseInt(number)
                          this.setState({ account })
                        }
                      }}
                      validations={'isNumeric'}
                      validationErrors={language.messages.validationErrors}
                    />)}
                  {(selectedPlanInfo)
                    ? <div style={{ paddingTop: '10px' }}>
                      <h4 style={{ fontWeight: 600 }}>{language.translate('application.planSummary')}</h4>
                      <ul style={{ paddingLeft: '10px' }}>
                        {(selectedPlanInfo.Unit === 'PerUser')
                          ? <li>{language.translate('application.startUnlimitedPerMonth')}</li>
                          : <li>{language.translate('application.startUpToPerMonth', [selectedPlanInfo.ExecutionLimit])}</li>
                        }
                        <li>
                          {(selectedPlanInfo.TemplateLimit)
                            ? language.translate('application.saveUpToXTemplates', [selectedPlanInfo.TemplateLimit])
                            : language.translate('application.saveUnlimitedTemplates')
                          }
                        </li>
                        {(selectedPlanInfo.Unit !== 'PerUser')
                          ? <li>
                            Include {(selectedPlanInfo.UserLimit) ? `up to ${selectedPlanInfo.UserLimit} ` : 'unlimited '}
                            people
                          </li>
                          : <li>Include {`up to ${account.UserLimit} `} people</li>}
                        <li>{language.translate('application.processesIncludeUnlimitedTasks')}</li>
                        <li>{language.translate('application.processesRunLongAsNecessary')}</li>
                        <li>{language.translate('application.planBillingStructure', [(selectedPlanInfo.Interval === 'Annually') ? numeral(selectedPlanInfo.Fee / 12).format('0,0.00') : selectedPlanInfo.Fee, selectedPlanInfo.Interval]).toLowerCase()}</li>
                      </ul>
                      <div style={{
                        textAlign: 'center'
                      }}
                      >
                        <a
                          href='https://processplan.com/pricing'
                          target='_blank'
                        >{language.translate('application.comparePlans')}</a>
                      </div>
                      <div style={{
                        textAlign: 'center',
                        paddingTop: '10px',
                        fontSize: '30px',
                        fontWeight: 600,
                        color: palette.primary2Color
                      }}
                      >
                        {(selectedPlanInfo.Unit === 'PerUser')
                          ? `${numeral(account.UserLimit * selectedPlanInfo.Fee).format('$0,0')} ${selectedPlanInfo.Interval}`
                          : `${numeral(selectedPlanInfo.Fee).format('$0,0')} ${selectedPlanInfo.Interval}`}
                      </div>
                    </div>
                    : null}
                </CardContent>
              </Card>
              <Card
                className='card'
                style={{
                  width: (mainContentWidth > 1100) ? '30%' : (mainContentWidth > 750) ? '45%' : '90%',
                  maxWidth: '450px'
                }}
              >
                <CardHeader title={language.translate('application.billingAddress')} />
                <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
                  <FormsyText
                    required
                    fullWidth
                    name='address1'
                    floatingLabelText={language.translate('application.address1')}
                    value={account.BillingAddress1}
                    onBlur={(event) => {
                      account.BillingAddress1 = event.currentTarget.value
                      this.setState({ account })
                    }}
                    validationErrors={language.messages.validationErrors}
                  />
                  <FormsyText
                    name='address2'
                    fullWidth
                    floatingLabelText={language.translate('application.address2')}
                    value={account.BillingAddress2}
                    onBlur={(event) => {
                      account.BillingAddress2 = event.currentTarget.value
                      this.setState({ account })
                    }}
                    validationErrors={language.messages.validationErrors}
                  />
                  <FormsyText
                    name='city'
                    fullWidth
                    floatingLabelText={language.translate('application.city')}
                    value={account.BillingCity}
                    onBlur={(event) => {
                      account.BillingCity = event.currentTarget.value
                      this.setState({ account })
                    }}
                    validationErrors={language.messages.validationErrors}
                  />
                  <FormsyText
                    required
                    name='state'
                    fullWidth
                    floatingLabelText={language.translate('application.stateProvinceRegion')}
                    maxLength={3}
                    value={account.BillingState}
                    onBlur={(event) => {
                      account.BillingState = event.currentTarget.value
                      this.setState({ account })
                    }}
                    validationErrors={language.messages.validationErrors}
                  />
                  <FormsyText
                    required
                    name='postal_code'
                    fullWidth
                    floatingLabelText={language.translate('application.postalCode')}
                    value={account.BillingPostalCode}
                    onBlur={(event) => {
                      account.BillingPostalCode = event.currentTarget.value
                      this.setState({ account })
                    }}
                    validationErrors={language.messages.validationErrors}
                  />
                  <FormsySelect
                    required
                    name='country'
                    fullWidth
                    value={account.BillingCountry}
                    onChange={(e, value) => {
                      account.BillingCountry = value
                      this.setState({ account })
                    }}
                    floatingLabelText={language.translate('application.country')}
                    validationErrors={language.messages.validationErrors}
                  >
                    {countryList}
                  </FormsySelect>
                </CardContent>
              </Card>
              <Card
                className='card'
                style={{
                  width: (mainContentWidth > 1100) ? '30%' : (mainContentWidth > 750) ? '45%' : '90%',
                  maxWidth: '450px',
                  marginLeft: '10px',
                  marginRight: '10px'
                }}
              >
                <CardHeader
                  title={language.translate('application.creditCard')}
                  subheader={language.translate('application.creditCardMessage')}
                />
                <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
                  <FormsyText
                    required
                    fullWidth
                    value={account.NameOnCard}
                    name='card_name'
                    floatingLabelText={language.translate('application.nameOnCard')}
                    onBlur={(event) => {
                      account.NameOnCard = event.currentTarget.value
                      this.setState({ account })
                    }}
                    validationErrors={language.messages.validationErrors}
                  />
                  <FormsyText
                    name='card_number'
                    required
                    value={account.CardNumber}
                    fullWidth
                    floatingLabelText={language.translate('application.creditCard#')}
                    onBlur={(event) => {
                      account.CardNumber = event.currentTarget.value
                      this.setState({ account })
                    }}
                    validationErrors={language.messages.validationErrors}
                  />
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <FormsySelect
                      required
                      name='expiry_date_month'
                      value={account.CardExpirationMonth}
                      style={{ width: '120px', marginRight: '20px' }}
                      onChange={(e, value) => {
                        account.CardExpirationMonth = value
                        this.setState({ account })
                      }}
                      floatingLabelText={language.translate('application.expiryMonth')}
                      validationErrors={language.messages.validationErrors}
                    >
                      {[
                        <MenuItem value={1} key={1} primaryText='01' />,
                        <MenuItem value={2} key={2} primaryText='02' />,
                        <MenuItem value={3} key={3} primaryText='03' />,
                        <MenuItem value={4} key={4} primaryText='04' />,
                        <MenuItem value={5} key={5} primaryText='05' />,
                        <MenuItem value={6} key={6} primaryText='06' />,
                        <MenuItem value={7} key={7} primaryText='07' />,
                        <MenuItem value={8} key={8} primaryText='08' />,
                        <MenuItem value={9} key={9} primaryText='09' />,
                        <MenuItem value={10} key={10} primaryText='10' />,
                        <MenuItem value={11} key={11} primaryText='11' />,
                        <MenuItem value={12} key={12} primaryText='12' />
                      ]}
                    </FormsySelect>
                    <FormsySelect
                      required
                      name='expiry_date_year'
                      value={account.CardExpirationYear}
                      style={{ width: '120px' }}
                      onChange={(e, value) => {
                        account.CardExpirationYear = value
                        this.setState({ account })
                      }}
                      floatingLabelText={language.translate('application.expiryYear')}
                      validationErrors={language.messages.validationErrors}
                    >
                      {this.getYearMenu()}
                    </FormsySelect>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                    <FormsyText
                      required
                      name='security_code'
                      value={account.CVV}
                      style={{ width: '120px' }}
                      floatingLabelText={language.translate('application.securityCode')}
                      onBlur={(event) => {
                        account.CVV = event.currentTarget.value
                        this.setState({ account })
                      }}
                      validationErrors={language.messages.validationErrors}
                    />
                    <Tooltip title={language.translate('application.whatsThis')}>
                      <IconButton
                        onClick={() => this.setState({ showCvvHelp: true })}
                      >
                        <Help nativeColor={palette.accent6Color} />
                      </IconButton>
                    </Tooltip>
                  </div>
                </CardContent>
                <CardActions>
                  <Button
                    variant='contained'
                    disabled={(!canSubmit)}
                    color='primary'
                    onClick={() => this.savePaymentInformation()}
                  >
                    {language.translate('application.updateMyPlan')}
                  </Button>
                </CardActions>
              </Card>
            </div>
          </Form>}
        <Dialog
          open={showCvvHelp}
          onClose={() => this.setState({ showCvvHelp: false })}
        >
          <DialogTitle
            style={{
              backgroundColor: palette.headerBackgroundColor
            }}
            disableTypography
          >
            <Typography
              variant='h6'
              style={{ color: palette.alternateTextColor }}>
              {language.translate('application.cvvHelp')}
            </Typography>
          </DialogTitle>
          <DialogContent style={{ width: '400px' }}>
            <div style={{ padding: '20px 0px' }}>
              {language.translate('application.cvvHelpText')}
            </div>
            <div>
              <img src='https://s3.amazonaws.com/ppwebsitefiles/csc.gif' />
            </div>
            <div>
              <img src='https://s3.amazonaws.com/ppwebsitefiles/csc_2.gif' />
            </div>
          </DialogContent>
        </Dialog>
      </div>
    )
  }
}

PlanInformation.propTypes = {
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.string,
  push: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  location: PropTypes.object,
  post: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  mainContentWidth: PropTypes.number.isRequired,
  updateUserSession: PropTypes.func.isRequired
}

PlanInformation.contextTypes = {
  muiTheme: PropTypes.object,
  location: PropTypes.object,
  language: PropTypes.object
}

const mapStateToProps = state => ({
  title: state.application.title,
  user: state.auth,
  mainContentWidth: state.application.mainContentWidth
})

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  post: bindActionCreators(post, dispatch),
  get: bindActionCreators(get, dispatch),
  updateUserSession: bindActionCreators(updateUserSession, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(PlanInformation)
