import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { CircularProgress, Paper } from '@material-ui/core'
import { disableFullScreen, enableFullScreen, updateTitle } from '../actions/application'
import { showSnackbar } from '../actions/snackbar'
import { get } from '../actions/base'
import FormHelper from '../businessLogic/formHelper'
import moment from 'moment'
import { GeneratedIcon, Icon } from '../components/Icon'
import Diagram from '../components/Diagram/Diagram'
import Copyright from '../components/Layout/Copyright'

class PublicProgress extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      processInstance: [],
      isLoading: true,
      diagramModel: {},
      account: {},
      token: context.location.query.token || null
    }
  }

  componentDidMount () {
    this.fetchDiagram()

    this.updateTitle()

    this.props.enableFullScreen()
  }

  componentDidUpdate (prevProps) {
    this.updateTitle()
  }

  componentWillUnmount () {
    this.props.disableFullScreen()
  }

  fetchDiagram () {
    const { token } = this.state
    /*
     * GET PROCESS FLOW MODEL
     */
    const url = `public/processinstance/diagrammodel`
    this.props.dispatch(get(url, {
      onSuccess: (response) => {
        this.setState({
          diagramModel: response.DiagramModel,
          account: response.Account,
          processInstance: response.ProcessInstance,
          isLoading: false
        })
      },
      headers: { token }
    }))
  }

  updateTitle () {
    const { language } = this.context
    const title = language.translate('application.visualProgress')
    this.props.dispatch(updateTitle(title))
  }

  raiseError (message) {
    this.props.dispatch(showSnackbar(message))
  }

  render () {
    const { language, user } = this.context
    const { mainContentWidth } = this.props
    const { diagramModel, account } = this.state
    const palette = this.context.muiTheme.palette
    const process = this.state.processInstance

    return (
      (this.state.isLoading)
        ? <CircularProgress className='loader' />
        : <div
          style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          <div style={{ textAlign: 'center', margin: '20px 0px' }}>
            <img src={account.HeaderLogoWhiteBG_URL} style={{ maxWidth: '300px' }} />
          </div>
          <Paper elevation={4} style={{ height: '100%', width: '90%', position: 'relative', margin: '10px' }}>
            <div style={{
              padding: '10px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-start'
            }}
            >
              <div style={{
                fontSize: '16px',
                color: palette.accent3Color,
                float: 'left',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
              >
                {(process.ProcessTemplateIconName)
                  ? <Icon icon={process.ProcessTemplateIconName} size={40} style={{ marginRight: '10px' }} />
                  : <GeneratedIcon
                    text={process.ProcessTemplateTitle}
                    randomizer={process.ID}
                    style={{ marginRight: '10px' }}
                  />}
                <strong
                  style={{
                    maxWidth: '300px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {process.ProcessTemplateTitle}
                </strong>
              </div>
              {(mainContentWidth > 1200)
                ? <div style={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  flexWrap: 'wrap',
                  justifyContent: 'flex-end'
                }}
                >
                  <Paper
                    elevation={2}
                    style={{
                      padding: '5px',
                      backgroundColor: palette.primary3Color,
                      float: 'right',
                      margin: '5px'
                    }}
                  >
                    {language.translate('application.notStarted')}
                  </Paper>
                  <Paper
                    elevation={2}
                    style={{
                      padding: '5px',
                      backgroundColor: palette.errorColor,
                      float: 'right',
                      margin: '5px',
                      color: palette.canvasColor
                    }}
                  >
                    {language.translate('application.pastDue')}
                  </Paper>
                  <Paper
                    elevation={2}
                    style={{
                      padding: '5px',
                      backgroundColor: palette.successColor,
                      float: 'right',
                      margin: '5px',
                      color: palette.canvasColor
                    }}
                  >
                    {language.translate('application.pendingResponse')}
                  </Paper>
                  <Paper
                    elevation={2}
                    style={{
                      padding: '5px',
                      backgroundColor: palette.primary2Color,
                      float: 'right',
                      margin: '5px',
                      color: palette.canvasColor
                    }}
                  >
                    {language.translate('application.pendingTask')}
                  </Paper>
                  <Paper
                    elevation={2}
                    style={{
                      padding: '5px',
                      backgroundColor: palette.primary1Color,
                      float: 'right',
                      margin: '5px',
                      color: palette.canvasColor
                    }}
                  >
                    {language.translate('application.completed')}
                  </Paper>
                </div>
                : null}
            </div>
            {/* HAVE THIS INFO RIDE JUST BELOW THE TOP LEFT TITLE OF DIAGRAM */}
            <div style={{
              position: 'absolute',
              paddingLeft: '10px',
              fontSize: '11px',
              color: palette.accent4Color
            }}
            >
              <div>
                {FormHelper.decodeHTML(process.HeaderField1_Name)}: {FormHelper.decodeHTML(process.HeaderField1_Value)}
              </div>
              <div>
                {language.translate('application.processTargetDate')}: {(process.TargetDate_Local) ? moment(process.TargetDate_Local).format((user.dateFormat) ? user.dateFormat : 'YYYY-MM-DD') : 'None'}
              </div>
            </div>
            <div style={{ height: '100%', margin: '0px', position: 'absolute', width: '100%' }}>
              <Diagram
                diagramModel={diagramModel}
                diagramType='instance'
                fastRender={process.FastRender}
                disableEditing
                isForImport
                showErrorMessage={this.raiseError.bind(this)}
              />
            </div>
          </Paper>
          <Copyright />
        </div>
    )
  }
}

PublicProgress.propTypes = {
  params: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.string,
  enableFullScreen: PropTypes.func.isRequired,
  disableFullScreen: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  mainContentWidth: PropTypes.number.isRequired
}

PublicProgress.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object,
  location: PropTypes.object,
  user: PropTypes.object
}

const mapStateToProps = state => ({
  title: state.application.title,
  mainContentWidth: state.application.mainContentWidth
})

const mapDispatchToProps = dispatch => ({
  dispatch,
  enableFullScreen: bindActionCreators(enableFullScreen, dispatch),
  disableFullScreen: bindActionCreators(disableFullScreen, dispatch),
  showSnackbar: bindActionCreators(showSnackbar, dispatch),
  get: bindActionCreators(get, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(PublicProgress)
