import PropTypes from 'prop-types'
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { loginUserSuccess } from '~/actions/authentication'
import { updateTitle, setApiServer } from '~/actions/application'
import { connectToPusher } from '~/actions/pusher'
import { styles } from './styles.scss'
import { get, post } from '~/actions/base'
import { push } from 'react-router-redux'
import { Base64 } from 'js-base64'
import { showSnackbar } from '~/actions/snackbar'
import { Button } from '@material-ui/core'
import ForgotPassword from './ForgotPassword'
import BrowserCheck from '~/components/Layout/BrowserCheck'
import { initializeScript } from '~/actions/livechat'
import objectAssign from 'object-assign'
import LoginForm from './LoginForm'
import Subscriber from '~/components/Layout/Notifications/Subscriber'
import TwoFactorConfirmation from './TwoFactorConfirmation'
import WaitingDialog from '~/components/Layout/WaitingDialog'

class LoginView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      componentDidMountRanOnce: false,
      isAuthenticating: false,
      hasMultipleAccounts: false,
      loginData: !!(window.localStorage.getItem('logindata')) ? JSON.parse(window.localStorage.getItem('logindata')) : {}, // leave !! due to mobile app
      accountList: [],
      twoFactorChannel: window.localStorage.getItem('twofactorchannel') || null,
      showTwoFactorWaiting: false
    }
  }

  componentWillMountMigrationToComponentDidMount() {
    const { location } = this.context

    if (location.query.sso) {
      if (Number.isInteger(parseInt(location.query.sso, 10))) {
        window.open(`https://api.processplan.com/saml/login.aspx?aid=${location.query.sso}`, '_self')
      } else {
        window.open(location.query.sso, '_self')
      }
    }
  }

  componentDidMount() {

    if (this.state.componentDidMountRanOnce === false) {
      this.componentWillMountMigrationToComponentDidMount();
      this.setState({ componentDidMountRanOnce: true });
    }

    const { language } = this.context

    this.props.updateTitle(language.translate('application.login'))
    this.props.connectToPusher()
  }

  login(data) {
    const { apiServer, setApiServer, location } = this.props

    this.setState({ isAuthenticating: true })

    const headers = {
      Authorization: `Basic ${Base64.encode(`${data.email}:${data.password}`)}`
    }

    if (data.accountid || location.query.aid) {
      headers.accountid = data.accountid || location.query.aid

      if (data.serverRegion) {
        setApiServer(`https://${data.serverRegion}.processplan.com`)
      }
    }

    if (data.rememberMe) {
      headers.RememberMe = data.rememberMe.toString()
    }

    if (window.localStorage.getItem('twofactortoken')) {
      headers.twofactortoken = window.localStorage.getItem('twofactortoken')
    }

    if (data.twoFactorToken) {
      headers.twofactortoken = data.twoFactorToken
    }

    this.props.get('authenticate', {
      onSuccess: (response) => {
        // if the user is associated with multiple accounts, show the account select form
        if (response.AccountList && response.AccountList.length > 1) {
          this.setState({
            hasMultipleAccounts: true,
            accountList: response.AccountList,
            loginData: data,
            isAuthenticating: false
          })
        } else if (response.AccountList && response.AccountList.length === 1) {
          data.serverRegion = response.AccountList[0].DataServerRegion
          data.accountid = response.AccountList[0].ID
          this.login(data)
        } else if (response.Text) {
          window.localStorage.setItem('twofactorchannel', response.Text)
          window.localStorage.setItem('logindata', JSON.stringify(data))
          this.setState({ twoFactorChannel: response.Text, loginData: data, showTwoFactorWaiting: true })
        } else {
          window.localStorage.removeItem('twofactorchannel')
          window.localStorage.removeItem('logindata')

          // save twofactortoken to localstorage if user want this computer to remember
          if (data.rememberMe && data.twoFactorToken) {
            window.localStorage.setItem('twofactortoken', data.twoFactorToken)
          }

          this.props.loginUserSuccess(response, data.rememberMe, this.state.accountList)
        }
      },

      headers: headers,
      onError: (error, body) => {
        this.setState({ isAuthenticating: false })

        body.ResultList.map((message) => {
          if (message.Code === 401010 && body.Account.SSOEnabled && body.Account.SAML_SingleSignOnUrl) { window.open(body.Text, '_self') }
        })
      }
    })
  }

  handleForgotPassword(email) {
    const { showSnackbar, post } = this.props
    const { language } = this.context

    this.setState({ isAuthenticating: true })

    const headers = {
      username: email
    }

    const body = JSON.stringify({})

    post('public/passwordreset', body, {
      onSuccess: (response) => {
        this.setState({
          isAuthenticating: false
        }, this.cancelPasswordResetForm.bind(this))
        showSnackbar(language.translate('application.checkEmailPasswordReset'))
      },

      headers: headers,

      onError: (error) => {
        this.setState({ isAuthenticating: false })
      }
    })
  }

  cancelPasswordResetForm() {
    const { push } = this.props
    const { location: { query, pathname } } = this.context

    delete query.forgotPassword

    push({
      pathname,
      query
    })
  }

  respondToTwoFactorRequest(approved = false, callback) {
    const { location: { query: { key } } } = this.props
    const body = JSON.stringify({})

    const headers = {
      token: `${key}:${(approved) ? 'APPROVED' : 'REJECTED'}`
    }

    this.props.post(`public/twofactorresponse`, body, {
      onSuccess: (response) => {
        callback()
      },
      headers
    })
  }

  render() {
    const { language, location } = this.context
    const { isLiveChatLoaded, liveChatAPI, initializeLiveChat, push, showSnackbar } = this.props
    const { twoFactorChannel, loginData, showTwoFactorWaiting } = this.state

    return (
      <div
        className='login-box'
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '650px',
          height: '100%'
        }}
      >
        <BrowserCheck />
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <div className='logo'>
            <img
              className='img-responsive'
              style={{ maxWidth: '200px' }}
              src='https://s3.amazonaws.com/ppwebsitefiles/logo_stacked.png'
            />
          </div>
          {(location.query.key)
            ? <TwoFactorConfirmation
              handleConfirm={() => {
                this.respondToTwoFactorRequest(true, () => {
                  this.props.push('/login')
                })
              }}
              handleDeny={() => {
                this.respondToTwoFactorRequest(false, () => {
                  this.props.push('/login')
                })
              }}
            />
            : (location.query.forgotPassword === 'true')
              ? <ForgotPassword
                handleSubmit={this.handleForgotPassword.bind(this)}
                cancel={this.cancelPasswordResetForm.bind(this)}
                isAuthenticating={this.state.isAuthenticating}
              />
              : <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <section id='loginForm'>
                  <LoginForm
                    handleSubmit={this.login.bind(this)}
                    isAuthenticating={this.state.isAuthenticating}
                    hasMultipleAccounts={this.state.hasMultipleAccounts}
                    accountList={this.state.accountList}
                  />
                </section>
                {(document.location.host !== 'mobile.processplan.com')
                  ? <div style={{ paddingTop: '30px' }}>
                    <a href='https://us1.processplan.com/createaccount.aspx'>
                      {language.translate('application.dontHaveAccount')}<br />{language.translate('application.clickHereGetStarted')}
                    </a>
                  </div>
                  : null}
              </div>
          }
          <div style={{ padding: '20px 0px', display: 'flex', flexDirection: 'column' }}>
            <Button
              onClick={() => push({
                pathname: this.context.location.pathname,
                query: objectAssign({},
                  this.context.location.query,
                  {
                    forgotPassword: true
                  })
              })}
              color='primary'
            >
              {language.translate('application.resetPassword')}
            </Button>
            <Button
              onClick={() => {
                if (isLiveChatLoaded) { liveChatAPI.open_chat_window() } else { initializeLiveChat({}, () => this.props.liveChatAPI.open_chat_window()) }
              }}
              color='primary'
            >
              {language.translate('application.needHelp')}
            </Button>
          </div>
        </div>
        {(twoFactorChannel)
          ? <Subscriber
            channelName={twoFactorChannel}
            events={['TwoFactorApproved']}
            callback={(data) => {
              this.login(objectAssign({}, loginData, { twoFactorToken: data.User.TwoFactorAuthToken }))
            }}
          />
          : null}
        {(twoFactorChannel)
          ? <Subscriber
            channelName={twoFactorChannel}
            events={['TwoFactorDenied']}
            callback={(data) => {
              // show deny message
              showSnackbar(language.translate('application.twoFactorAuthenticationDenied'))
              // reset login form
              this.setState({ twoFactorChannel: null, isAuthenticating: false, showTwoFactorWaiting: false })
            }}
          />
          : null}
        {(showTwoFactorWaiting)
          ? <WaitingDialog
            messages={[
              language.translate('application.waitingOnTwoFactorConfirmation'),
              language.translate('application.pleaseCheckYourDevice')
            ]}
            pauseTime={5000}
          />
          : null}
      </div>
    )
  }
}

LoginView.propTypes = {
  isAuthenticating: PropTypes.bool,
  location: PropTypes.object,
  get: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  loginUserSuccess: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  isLiveChatLoaded: PropTypes.bool.isRequired,
  agentsOnline: PropTypes.bool.isRequired,
  liveChatAPI: PropTypes.object.isRequired,
  initializeLiveChat: PropTypes.func.isRequired,
  updateTitle: PropTypes.func.isRequired,
  setApiServer: PropTypes.func.isRequired,
  apiServer: PropTypes.string.isRequired,
  connectToPusher: PropTypes.func.isRequired
}

LoginView.contextTypes = {
  muiTheme: PropTypes.object,
  language: PropTypes.object,
  location: PropTypes.object
}

const mapStateToProps = state => ({
  isAuthenticating: state.auth.isAuthenticating,
  isLiveChatLoaded: state.livechat.isLoaded,
  agentsOnline: state.livechat.agentsOnline,
  liveChatAPI: state.livechat.api,
  apiServer: state.application.apiServer
})

const mapDispatchToProps = dispatch => ({
  loginUserSuccess: bindActionCreators(loginUserSuccess, dispatch),
  get: bindActionCreators(get, dispatch),
  post: bindActionCreators(post, dispatch),
  push: bindActionCreators(push, dispatch),
  showSnackbar: bindActionCreators(showSnackbar, dispatch),
  initializeLiveChat: bindActionCreators(initializeScript, dispatch),
  updateTitle: bindActionCreators(updateTitle, dispatch),
  setApiServer: bindActionCreators(setApiServer, dispatch),
  connectToPusher: bindActionCreators(connectToPusher, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginView)
