import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { goBack, push, replace } from 'react-router-redux'
import {
  Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Menu, MenuItem, Paper,
  TextField, Tooltip, Typography
} from '@material-ui/core'
import { Form } from 'formsy-react'
import { FormsyText } from 'formsy-material-ui'
import {
  Help, Info, Settings, SettingsOutlined, PlayArrow, Save, Storage, CloudDownload,
  Edit, DirectionsRun, MoreVert, Update, GridOn
} from '@material-ui/icons'
import objectAssign from 'object-assign'
import { disableFullScreen, enableFullScreen, updateTitle } from '~/actions/application'
import { showSnackbar, showUndoMessage } from '~/actions/snackbar'
import { addMenuItem, removeMenuItem } from '~/actions/header'
import { get, post } from '~/actions/base'
import { addSteps, removeStep } from '~/actions/joyride'
import FormHelper from '~/businessLogic/formHelper'
import ContactCardDialog from '~/components/User/ContactCardDialog'
import GroupCardDialog from '~/components/User/GroupCardDialog'
import RapidEntryForm from '~/components/ProcessTemplate/RapidEntryForm'
import MapSubProcessDialog from '~/components/ProcessTemplate/MapSubProcessDialog'
import { GeneratedIcon, Icon } from '~/components/Icon'
import TaskAssignmentDialog from '~/components/ProcessTemplate/TaskAssignmentDialog'
import IconSelectorDialog from '~/components/Icon/IconSelectorDialog'
import MessageNotificationIcon from '~/components/Messages/MessageNotificationIcon'
import Subscriber from '~/components/Layout/Notifications/Subscriber'
import WaitingDialog from '~/components/Layout/WaitingDialog'
import Diagram from '~/components/Diagram/Diagram'
import DeleteMenuItem from '~/components/Layout/DeleteMenuItem'
import PrintableTemplateDocumentDialog from '../../components/ProcessTemplate/PrintableTemplateDocumentDialog'

class ProcessTemplate extends React.Component {
  constructor(props) {
    super(props)

    this.handleWindowClose = this.handleWindowClose.bind(this)

    this.state = {
      template: null,
      diagramModel: {},
      isLoading: true,
      isSaving: false,
      contactCardOpen: false,
      contactCardUserID: '',
      groupCardOpen: false,
      groupCardGroupID: '',
      diagramNeedsToBeSaved: false,
      accountLoaded: false,
      showHelp: false,
      showRapidInputWindow: false,
      editTemplateName: false,
      canSubmitTemplateName: true,
      showIconSelector: false,
      showMapSubProcessDialog: false,
      subProcessResponseID: null,
      nodeSelected: {},
      settingsMenuIconRotate: false,
      isForImport: false,
      showWaitingDialog: false,
      taskAssignmentDialogTask: {},
      showImportMessage: false,
      disableImportButton: false,
      menuAnchor: null,
      showDocumentTemplateDialog: false,
      documentTemplates: [],
      documentTemplatesLoaded: false,
      searchText: ''
    }
  }

  componentDidMount() {
    if (!this.props.params.templateid) { this.generateNewTemplate() } else {
      this.fetchTemplateData(this.props.params.templateid)
    }

    this.props.enableFullScreen()

    this.updateTitle()

    window.addEventListener('beforeunload', this.handleWindowClose)
  }

  // MIGRATING componentWillUpdate TO componentDidUpdate
  // componentWillUpdate (nextProps, nextState) {
  //   if (nextProps.params.templateid !== this.props.params.templateid) {
  //     this.setState({ isLoading: true, nodeSelected: {} }, () => {
  //       this.fetchTemplateData(nextProps.params.templateid)
  //     })
  //   }
  // }

  componentDidUpdate(prevProps, prevState) {

    if (this.props.params.templateid !== prevProps.params.templateid) {
      this.setState({ isLoading: true, nodeSelected: {} }, () => {
        this.fetchTemplateData(this.props.params.templateid)
      })
    }

    this.updateTitle()

    if (prevState.isLoading && !this.state.isLoading) { this.initializeGuidedTour() }
  }

  componentWillUnmount() {
    const { disableFullScreen, removeStep } = this.props

    disableFullScreen()
    removeStep('process-template-1')
    removeStep('process-template-2')

    window.removeEventListener('beforeunload', this.handleWindowClose)
  }

  handleWindowClose(event) {
    if (this.state.diagramNeedsToBeSaved) {
      event.returnValue = 'Are you sure you want to exit without saving the diagram first!'
    }
  }

  initializeGuidedTour() {
    const { language } = this.context
    const { addSteps } = this.props

    const steps1 = [{
      title: language.translate('application.iconSelector'),
      content: language.translate('application.iconSelectorInstruction'),
      target: '.icon-selector',
      placement: 'bottom-start',
      disableBeacon: true
    }, {
      title: language.translate('application.designerMenu'),
      content: language.translate('application.designerMenuInstruction'),
      target: '.designer-menu',
      placement: 'bottom',
      disableBeacon: true
    }, {
      title: language.translate('application.designer'),
      content: <div>
        <div>{language.translate('application.designerInstruction1')}</div>
        <div style={{ textAlign: 'center' }}>
          <img width={212} src='../images/selected-node.jpg' />
        </div>
      </div>,
      target: '.goJsCanvas',
      placement: 'top',
      disableBeacon: true
    }, {
      title: 'Designer',
      content: <div>
        <div>
          {language.translate('application.designerInstruction2')}
        </div>
        <div style={{ textAlign: 'center' }}>
          <img width={271} src='../images/right-click-node.jpg' />
        </div>
      </div>,
      target: '.goJsCanvas2',
      placement: 'top',
      disableBeacon: true
    }] // not working if same selector is selected back to back

    addSteps({ id: 'process-template-1', steps: steps1 })
  }

  generateNewTemplate() {
    const { post, replace } = this.props
    const { language } = this.context

    const body = JSON.stringify({
      ProcessTemplate: {
        Title: language.translate('application.newProcess')
      }
    })

    post('processtemplate/withdefaultdiagram', body, {
      onSuccess: (response) => {
        this.setState({
          template: response.ProcessTemplate
        })

        replace(`/process-template/${response.ProcessTemplate.ID}`)
      }
    })
  }

  fetchTemplateData(templateId, isUpdate = false) {
    const { userAccountID, get, location: { query } } = this.props

    let endpoint = `processtemplate/${templateId}/diagrammodel`

    if (query.library) {
      endpoint = `library/` + endpoint
    }

    /*
     *  GET TEMPLATE INFORMATION
     */
    get(endpoint, {
      onSuccess: (response) => {
        const data = response.DiagramModel

        if (isUpdate) {
          this.setState({
            template: response.ProcessTemplate,
            diagramModel: data
          })
        } else {
          this.setState({
            template: response.ProcessTemplate,
            diagramModel: data,
            // isForImport: (userAccountID !== response.ProcessTemplate.AccountID),
            isForImport: !!(query.library),
            isLoading: false
          })
        }
      }
    })
  }

  saveDiagram() {
    this.setState({ isSaving: true })
    const { post } = this.props
    const { diagramModel, template } = this.state
    const body = JSON.stringify({
      DiagramModel: diagramModel
    })

    post(`processtemplate/${template.ID}/diagrammodel`, body, {
      onSuccess: (response) => {
        const data = response.DiagramModel

        this.setState({
          template: response.ProcessTemplate,
          diagramModel: data,
          diagramNeedsToBeSaved: false,
          isSaving: false
        })
      }
    })
  }

  saveTemplateSettings(settings) {
    const { post } = this.props
    const { template } = this.state
    const body = JSON.stringify({
      ProcessTemplate: settings
    })

    post(`processtemplate/${template.ID}`, body, {
      onSuccess: (response) => {
        this.setState({
          template: response.ProcessTemplate
        })
      }
    })
  }

  saveTaskSettings(task) {
    const { post } = this.props
    const { template } = this.state
    const body = JSON.stringify({
      ProcessTemplateTask: task
    })

    post(`processtemplate/${template.ID}/task/${task.ID}`, body, {
      onSuccess: (response) => {
      }
    })
  }

  getChangelog(callback) {
    const { get } = this.props
    const { template } = this.state
    /*
     *  GET CHANGELOG
     */
    get(`processtemplate/${template.ID}/parent/memo/list/newmemos`, {
      onSuccess: (response) => {
        if (callback) {
          callback(response.MemoList)
        }
      }
    })
  }

  importTemplate(fromSameAccount) {
    const { post } = this.props
    const { template } = this.state
    const body = JSON.stringify({})

    this.setState({ disableImportButton: true })

    let endpoint = (fromSameAccount) ? `processtemplate/${template.ID}/copy` : `library/processtemplate/${template.ID}/copy`

    post(endpoint, body, {
      onSuccess: (response) => {
        this.setState({ showImportMessage: true })
      },
      onError: () => {
        this.setState({ disableImportButton: false })
      }
    })
  }

  updateTemplate() {
    const { post, push } = this.props
    const { template } = this.state
    const body = JSON.stringify({})

    this.setState({ showWaitingDialog: true })

    post(`processtemplate/${template.ID}/mergewithparent`, body, {
      onSuccess: (response) => {
        this.setState({ showWaitingDialog: false }, () => {
          push(`/process-template/${response.ProcessTemplate.ID}`)
        })
      },
      onError: () => {
        this.setState({ showWaitingDialog: false })
      }
    })
  }

  deleteTemplate() {
    const { language } = this.context
    const { post, push, showUndoMessage, goBack } = this.props
    const { template } = this.state
    const body = JSON.stringify({})

    post(`processtemplate/${template.ID}/delete`, body, {
      onSuccess: (response) => {
        push('/process-templates/')

        showUndoMessage(
          language.translate('application.templateRemoved'),
          () => {
            post(`processtemplate/${template.ID}/delete/undo`, body, {
              onSuccess: (response) => {
                goBack()
              }
            })
          }
        )
      }
    })
  }

  linkResponseToSubProcess(subProcessId) {
    const { post, push, location, params } = this.props
    const { template, subProcessResponseID } = this.state

    const body = JSON.stringify({})

    post(
      `processtemplate/${template.ID}/version/${template.VersionTimeStamp}/response/${subProcessResponseID}/linktomigrationplan/${subProcessId}`,
      body,
      {
        onSuccess: (response) => {
          this.fetchTemplateData(params.templateid, true)

          push({
            pathname: location.pathname,
            query: objectAssign({}, location.query, {
              ptype: 'tmpmap',
              pid: subProcessId,
              ptsid: template.ID
            })
          })
        }
      }
    )
  }

  createMigrationPlan(ProcessTemplateMigrationPlan) {
    const { template } = this.state
    const body = JSON.stringify({ ProcessTemplateMigrationPlan })

    this.props.dispatch(post(`processtemplate/${template.ID}/migrationplan`, body, {
      onSuccess: (response) => {
        this.linkResponseToSubProcess(response.ProcessTemplateMigrationPlan.ID)
      }
    }))
  }

  updateTitle() {
    const { language } = this.context

    this.props.dispatch(updateTitle(language.translate('application.templateDesigner')))
  }

  raiseError(message) {
    this.props.dispatch(showSnackbar({ message, duration: 8000 }))
  }

  displayContactCard(userID) {
    this.setState({
      contactCardOpen: true,
      contactCardUserID: userID
    })
  }

  displayGroupCard(groupID) {
    this.setState({
      groupCardOpen: true,
      groupCardGroupID: groupID
    })
  }

  openFieldMappingPanel(subProcessId) {
    const { push, location } = this.props
    const { template } = this.state

    push({
      pathname: location.pathname,
      query: {
        ptype: 'tmpmap',
        pid: subProcessId,
        ptsid: template.ID
      }
    })
  }

  openTemplateActions(taskId, responseId) {
    const { push, location } = this.props
    const { template } = this.state

    let query = { ptype: 'ptactions', pid: template.ID }

    if (taskId) { query = objectAssign({}, query, { task: taskId }) }

    if (responseId) { query = objectAssign({}, query, { response: responseId }) }

    push({
      pathname: location.pathname,
      query
    })
  }

  openStartProcess() {
    const { push, location } = this.props
    const { template } = this.state

    push({
      pathname: location.pathname,
      query: {
        ptype: 'plsettings',
        pid: template.ID
      }
    })
  }

  openProcessFields() {
    const { push, location } = this.props
    const { template } = this.state

    push({
      pathname: location.pathname,
      query: { ptype: 'ptfields', pid: template.ID }
    })
  }

  openTemplateSettings() {
    const { push, location } = this.props
    const { template } = this.state

    push({
      pathname: location.pathname,
      query: { ptype: 'ptsettings', pid: template.ID }
    })
  }

  nodeSelected(type, id, bgColor = null, fgColor = null) {
    const { nodeSelected, settingsMenuIconRotate } = this.state

    this.addNodeSelectionJoyride()

    if (!nodeSelected.type && type) {
      this.setState({
        nodeSelected: { type, id, bgColor, fgColor }
      }, () => {
        setTimeout(() => this.setState({ settingsMenuIconRotate: (id === nodeSelected.id) ? settingsMenuIconRotate : !settingsMenuIconRotate }), 50)
      })
    } else if (nodeSelected.type && !type) {
      this.setState({
        settingsMenuIconRotate: (id === nodeSelected.id) ? settingsMenuIconRotate : !settingsMenuIconRotate
      }, () => {
        setTimeout(() => this.setState({ nodeSelected: { type, id, bgColor, fgColor } }), 200)
      })
    } else {
      this.setState({
        nodeSelected: { type, id, bgColor, fgColor },
        settingsMenuIconRotate: (id === nodeSelected.id) ? settingsMenuIconRotate : !settingsMenuIconRotate
      })
    }
  }

  addNodeSelectionJoyride() {
    const { language } = this.context

    const steps = [{
      title: language.translate('application.settingsIcon'),
      content: language.translate('application.settingsIconInstruction'),
      target: '.settings_icon',
      placement: 'bottom',
      styles: {
        beacon: {
          offsetY: -50
        }
      },
      disableBeacon: true
    }]

    this.props.addSteps({ id: 'process-template-2', steps })
  }

  showRapidInputWindow() {
    this.setState({ showRapidInputWindow: true })
  }

  rapidNodesFromText(text) {
    const nodeArray = text.split(/\r?\n/)
    console.log(nodeArray)
    // todo: get with Ryan on how he achieved this
  }

  editTemplateTitle() {
    this.setState({ editTemplateName: true }, () => {
      this.refs.template_title.focus()
    })
  }

  downloadDiagramImage(svg) {
    const { template } = this.state

    const svgString = new window.XMLSerializer().serializeToString(svg)
    const svgBlob = new window.Blob([svgString], { type: 'image/svg+xml;charset=utf-8' })

    const svgUrl = window.URL.createObjectURL(svgBlob)
    const filename = `${template.Title}.svg`
    const downloadLink = document.createElement('a')
    downloadLink.href = svgUrl
    downloadLink.download = filename

    // IE 11
    if (window.navigator.msSaveBlob !== undefined) {
      window.navigator.msSaveBlob(svgBlob, filename)
      return
    }

    document.body.appendChild(downloadLink)
    window.requestAnimationFrame(() => {
      downloadLink.click()
      window.URL.revokeObjectURL(svgUrl)
      document.body.removeChild(downloadLink)
    })
  }

  startProcess() {
    const { push, location } = this.props
    const { template } = this.state

    push({
      pathname: location.pathname,
      query: objectAssign({},
        location.query,
        {
          ptype: 'process_template',
          pid: template.ID
        })
    })
  }

  showTaskSettings(taskId) {
    const { isRightPanelOpen, push, location } = this.props

    if (isRightPanelOpen) { push(location.pathname) }

    setTimeout(() => {
      push({
        pathname: location.pathname,
        query: objectAssign({},
          location.query,
          {
            ptype: 'pttsettings',
            pid: taskId
          })
      })
    }, (isRightPanelOpen) ? 300 : 0)
  }

  showResponseSettings(responseId) {
    const { isRightPanelOpen, push, location } = this.props

    if (isRightPanelOpen) { push(location.pathname) }

    setTimeout(() => {
      push({
        pathname: location.pathname,
        query: objectAssign({},
          location.query,
          {
            ptype: 'ptrsettings',
            pid: responseId
          })
      })
    }, (isRightPanelOpen) ? 300 : 0)
  }

  showTemplateMemos() {
    const { template } = this.state
    const { push, location } = this.props

    push({
      pathname: location.pathname,
      query: objectAssign({},
        location.query,
        {
          ptype: 'memos',
          memoType: 'template',
          pid: template.ID
        })
    })
  }

  getSettingsMenuButton() {
    const { nodeSelected, settingsMenuIconRotate } = this.state
    const { language, muiTheme: { palette } } = this.context
    let label = ''
    let onClick = null
    let color = null

    switch (nodeSelected.type) {
      case 'response':
        label = language.translate('application.responseSettings')
        onClick = () => this.showResponseSettings(nodeSelected.id)
        color = nodeSelected.bgColor || palette.accent4Color
        break
      case 'task':
        label = language.translate('application.taskSettings')
        onClick = () => this.showTaskSettings(nodeSelected.id)
        color = nodeSelected.bgColor || palette.accent1Color
        break
      case 'process':
        label = language.translate('application.fieldMapping')
        onClick = () => this.openFieldMappingPanel(nodeSelected.id)
        color = nodeSelected.bgColor || palette.accent5Color
        break
      default:
        label = language.translate('application.templateSettings')
        onClick = () => this.openTemplateSettings()
        color = palette.accent6Color
        break
    }

    return (
      <Tooltip title={label}>
        <IconButton
          className='settings_icon'
          onClick={onClick}
          style={{ transition: 'all 200ms', transform: (settingsMenuIconRotate) ? 'rotate(180deg)' : 'rotate(0deg)' }}
        >
          <SettingsOutlined nativeColor={color} />
        </IconButton>
      </Tooltip>
    )
  }

  fetchDocumentTemplates() {
    const { get } = this.props

    get(`documenttemplate/list`, {
      onSuccess: (response) => {
        this.setState({
          documentTemplates: response.DocumentTemplateList,
          documentTemplatesLoaded: true
        })
      }
    })
  }

  fetchPrintableDocument(documentTemplateId, callback) {
    const { template } = this.state
    const { get } = this.props
    get(`documenttemplate/${documentTemplateId}/publish/processtemplate/${template.ID}`, {
      onSuccess: (response) => {
        if (callback) {
          callback(response)
        }
      }
    })
  }

  render() {
    const { language, user } = this.context
    const palette = this.context.muiTheme.palette
    const {
      template, showIconSelector, isForImport, diagramNeedsToBeSaved, showWaitingDialog, taskAssignmentDialogTask,
      showImportMessage, disableImportButton, diagramModel, menuAnchor, documentTemplates,
      documentTemplatesLoaded, showDocumentTemplateDialog, searchText, nodeSelected
    } = this.state
    const { push, mainContentWidth, get, isRightPanelOpen } = this.props

    return (
      (this.state.isLoading)
        ? <CircularProgress className='loader' />
        : <Paper elevation={4} style={{ position: 'relative', height: '100%' }}>
          <div style={{ padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{
              fontSize: '16px',
              color: palette.accent3Color,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start'
            }}
            >
              {
                (isForImport)
                  ? <a href={template.AccountWebsiteURL} target='_blank'>
                    <img
                      src={template.AccountHeaderLogoURL}
                      style={{ maxWidth: '80px', marginRight: '10px' }}
                    />
                  </a>
                  :
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '10px' }}>
                    {(template.IconName)
                      ? <IconButton
                        className='icon-selector'
                        style={{ padding: '0px', width: '40px', height: '40px' }}
                        onClick={() => this.setState({ showIconSelector: true })}
                      >
                        <Icon icon={template.IconName} size={40} />
                      </IconButton>
                      : <GeneratedIcon
                        className='clickable icon-selector'
                        text={template.Title}
                        randomizer={template.ID}
                        onClick={() => this.setState({ showIconSelector: true })}
                      />}
                    <div style={{
                      fontSize: '10px',
                      color: palette.accent2Color,
                      float: 'left',
                      position: 'absolute',
                      backgroundColor: palette.disabledColor,
                      top: '40px',
                      whiteSpace: 'nowrap'
                    }}
                    >{language.translate('application.updateIcon')}
                    </div>
                  </div>
              }
              {(this.state.editTemplateName)
                ? <Form
                  onValid={() => this.setState({ canSubmitTemplateName: true })}
                  onInvalid={() => this.setState({ canSubmitTemplateName: false })}
                  autoComplete='off'
                >
                  <FormsyText
                    required
                    name='template_title'
                    ref='template_title'
                    value={FormHelper.decodeHTML(template.Title)}
                    onBlur={(event) => {
                      if (template.Title !== event.currentTarget.value) {
                        template.Title = event.currentTarget.value
                        this.saveTemplateSettings(template)
                      }
                      this.setState({ editTemplateName: false })
                    }}
                    validationErrors={language.messages.validationErrors}
                  />
                </Form>
                : <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  maxWidth: '300px'
                }}
                >
                  <strong style={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                  }}>{this.state.template.Title}</strong>
                  {(!isForImport)
                    ? <Tooltip title={language.translate('application.edit')}>
                      <IconButton
                        onClick={this.editTemplateTitle.bind(this)}
                      //tooltipPosition='bottom-left'
                      >
                        <Edit nativeColor={palette.accent3Color} />
                      </IconButton>
                    </Tooltip>
                    : null}
                </div>
              }
            </div>
            {(mainContentWidth > 1200) &&
              <div>
                <TextField
                  id='search'
                  style={{ marginTop: '0px', minWidth: '250px' }}
                  placeholder={language.translate('application.search')}
                  value={searchText}
                  onChange={(e) => { this.setState({ searchText: e.target.value }) }}
                  margin='normal'
                />
              </div>}
            {(!isForImport)
              ? <div className='designer-menu' style={{ display: 'flex' }}>
                {(mainContentWidth > 930)
                  ? <div>
                    <Tooltip title={language.translate('application.save')}>
                      <IconButton
                        onClick={() => this.saveDiagram()}
                      >
                        <Save nativeColor={palette.accent6Color} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={language.translate('application.processField', [], true)}>
                      <IconButton
                        onClick={() => this.openProcessFields()}
                      >
                        <Storage nativeColor={palette.accent6Color} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={language.translate('application.automatedActions')}>
                      <IconButton
                        onClick={() => this.openTemplateActions()}
                      >
                        <DirectionsRun nativeColor={palette.accent6Color} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={language.translate('application.templateSettings')}>
                      <IconButton
                        onClick={() => this.openTemplateSettings()}
                      >
                        <Settings nativeColor={palette.accent6Color} />
                      </IconButton>
                    </Tooltip>

                    {(nodeSelected.type && this.getSettingsMenuButton())}

                    <Tooltip title={language.translate('application.startProcess')}>
                      <IconButton
                        onClick={() => this.openStartProcess()}
                      >
                        <PlayArrow nativeColor={palette.accent6Color} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={language.translate('application.processTable')}>
                      <IconButton
                        onClick={() => {
                          this.setState({ menuAnchor: null })
                          push(`/process-instances-grid/${template.ID}`)
                        }}
                      >
                        <GridOn nativeColor={palette.accent6Color} />
                      </IconButton>
                    </Tooltip>
                    <MessageNotificationIcon
                      onClick={() => this.showTemplateMemos()}
                      memoType='ProcessTemplate'
                      memoTypeID={template.ID}
                      get={get}
                    />
                    {(template.ParentUpdated)
                      ? <Tooltip title={language.translate('application.updateAvailable')}>
                        <IconButton
                          onClick={() => {
                            const { location } = this.props
                            const { query } = location

                            push({
                              pathname: location.pathname,
                              query: objectAssign({}, query, { update: true })
                            })
                          }}
                        >
                          <Update nativeColor={palette.errorAccentColor} />
                        </IconButton>
                      </Tooltip>
                      : null}
                    <Tooltip title={language.translate('application.help')}>
                      <IconButton
                        onClick={() => this.setState({ showHelp: true })}
                      >
                        <Help nativeColor={palette.accent6Color} />
                      </IconButton>
                    </Tooltip>
                  </div>
                  : null}
                <IconButton style={{ paddingTop: '12px' }}
                  onClick={(e) => { this.setState({ menuAnchor: e.currentTarget }) }}>
                  <MoreVert />
                </IconButton>
                <Menu
                  anchorEl={menuAnchor}
                  open={Boolean(menuAnchor)}
                  onClose={() => { this.setState({ menuAnchor: null }) }}
                  getContentAnchorEl={null}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                >
                  {(mainContentWidth <= 870)
                    ? React.Children.toArray([
                      <MenuItem
                        key='save'
                        onClick={() => {
                          this.setState({ menuAnchor: null })
                          this.saveDiagram()
                        }}
                      >
                        {language.translate('application.save')}
                      </MenuItem>,
                      <MenuItem
                        key='fields'
                        onClick={() => {
                          this.setState({ menuAnchor: null })
                          this.openProcessFields()
                        }}
                      >
                        {language.translate('application.processField', [], true)}
                      </MenuItem>,
                      <MenuItem
                        key='actions'
                        onClick={() => {
                          this.setState({ menuAnchor: null })
                          this.openTemplateActions()
                        }}
                      >
                        {language.translate('application.automatedActions')}
                      </MenuItem>,
                      <MenuItem
                        key='start'
                        onClick={() => {
                          this.setState({ menuAnchor: null })
                          this.openStartProcess()
                        }}
                      >
                        {language.translate('application.startProcess')}
                      </MenuItem>,
                      <MenuItem
                        key='settings'
                        onClick={() => {
                          this.setState({ menuAnchor: null })
                          this.openTemplateSettings()
                        }}
                      >
                        {language.translate('application.templateSettings')}
                      </MenuItem>,
                      <MenuItem
                        key='processTable'
                        onClick={() => {
                          this.setState({ menuAnchor: null })
                          push(`/process-instances-grid/${template.ID}`)
                        }}
                      >
                        {language.translate('application.processTable')}
                      </MenuItem>,
                      (template.ParentUpdated)
                        ? <MenuItem
                          key='update'
                          onClick={() => {
                            this.setState({ menuAnchor: null })
                            this.updateTemplate()
                          }}
                        >
                          {language.translate('application.updateAvailable')}
                        </MenuItem>
                        : null,
                      <MenuItem
                        key='help'
                        onClick={() => this.setState({ showHelp: true, menuAnchor: null })}
                      >
                        {language.translate('application.help')}
                      </MenuItem>
                    ])
                    : null}
                  <MenuItem
                    onClick={() => {
                      this.setState({ menuAnchor: null })
                      push(`/relationships/${template.ID}`)
                    }}
                  >
                    {language.translate('application.relationships')}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      this.setState({ menuAnchor: null })
                      push(`/audit-trail?endpoint=processtemplate/${template.ID}/auditentry/list&title=${template.Title}`)
                    }}
                  >
                    {language.translate('application.auditTrail')}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      this.setState({ menuAnchor: null })

                      const svg = this.diagram.makeSvg({
                        scale: 1
                      })

                      this.downloadDiagramImage(svg)
                    }}
                  >
                    {language.translate('application.downloadDiagramImage')}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      this.setState({ menuAnchor: null })
                      this.importTemplate(true)
                    }}
                  >
                    {language.translate('application.copyTemplate')}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      this.setState({ menuAnchor: null, showDocumentTemplateDialog: true })
                      this.fetchDocumentTemplates()
                    }}
                  >
                    {language.translate('application.printSopDocument')}
                  </MenuItem>
                  <DeleteMenuItem
                    onDelete={() => {
                      this.setState({ menuAnchor: null })
                      this.deleteTemplate()
                    }}
                    deleteLabel={language.translate('application.deleteTemplate')}
                  />
                </Menu>

              </div>
              : <div style={{ display: 'flex' }}>
                {(mainContentWidth > 700)
                  ? <div>
                    <Tooltip title={language.translate('application.importTemplate')}>
                      <IconButton
                        onClick={() => this.importTemplate()}
                        disabled={(disableImportButton)}
                      >
                        <CloudDownload nativeColor={palette.accent6Color} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={language.translate('application.help')}>
                      <IconButton
                        onClick={() => this.setState({ showHelp: true })}
                      >
                        <Help nativeColor={palette.accent6Color} />
                      </IconButton>
                    </Tooltip>
                  </div>
                  : <div>
                    <IconButton style={{ paddingTop: '3px' }}
                      onClick={(e) => { this.setState({ menuAnchor: e.currentTarget }) }}>
                      <MoreVert />
                    </IconButton>
                    <Menu
                      anchorEl={menuAnchor}
                      open={Boolean(menuAnchor)}
                      onClose={() => { this.setState({ menuAnchor: null }) }}
                      getContentAnchorEl={null}
                      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    >
                      <MenuItem
                        onClick={() => {
                          this.setState({ menuAnchor: null })
                          this.importTemplate()
                        }}
                      >
                        {language.translate('application.importTemplate')}
                      </MenuItem>
                      <MenuItem
                        onClick={() => this.setState({ showHelp: true, menuAnchor: null })}
                      >
                        {language.translate('application.help')}
                      </MenuItem>
                    </Menu>
                  </div>}
              </div>
            }
          </div>
          <div style={{ height: 'calc(100% - 50px)', margin: '0px', position: 'absolute', width: '100%' }}>
            <Diagram
              diagramModel={diagramModel}
              diagramNeedsToBeSaved={diagramNeedsToBeSaved}
              diagramRef={(diagram) => { this.diagram = diagram }}
              diagramType='template'
              searchString={searchText}
              fastRender={this.state.template.FastRender}
              onChange={() => { this.setState({ diagramNeedsToBeSaved: true }) }}
              showErrorMessage={this.raiseError.bind(this)}
              canCopyPaste
              onTaskSingleClicked={(nodeData) => {
                if (nodeData.status !== 'new') {
                  if (this.props.isRightPanelOpen) {
                    this.showTaskSettings(nodeData.key)
                  }

                  this.nodeSelected('task', nodeData.key, nodeData.backgroundColor, nodeData.foregroundColor)
                }
              }}
              onResponseSingleClicked={(nodeData) => {
                if (nodeData.status !== 'new') {
                  if (this.props.isRightPanelOpen) {
                    this.showResponseSettings(nodeData.key)
                  }

                  this.nodeSelected('response', nodeData.key, nodeData.backgroundColor, nodeData.foregroundColor)
                }
              }}
              onProcessSingleClicked={(nodeData) => {
                if (this.props.isRightPanelOpen) {
                  this.openFieldMappingPanel(nodeData.key)
                }

                this.nodeSelected('process', nodeData.key, nodeData.backgroundColor, nodeData.foregroundColor)
              }}
              onBackgroundSingleClicked={() => {
                if (this.props.isRightPanelOpen) {
                  this.openTemplateSettings()
                }

                this.nodeSelected(null, null)
              }}
              onStartNodeSingleClicked={() => {
                if (this.props.isRightPanelOpen) {
                  this.openTemplateSettings()
                }

                this.nodeSelected(null, null)
              }}
              onProcessDoubleClicked={(nodeData) => push(`/process-template/${nodeData.templateID}`)}
              displayContactCard={this.displayContactCard.bind(this)}
              displayGroupCard={this.displayGroupCard.bind(this)}
              downloadDiagramImage={this.downloadDiagramImage.bind(this)}
              isForImport={isForImport}
              taskMenuItems={[
                {
                  label: language.translate('application.taskAssignedTo'),
                  action: (nodeObject) => {
                    if (['new', 'copy'].includes(nodeObject.status)) {
                      this.raiseError(language.translate('application.youMustSaveYourChangesFirst'))
                      return false
                    }

                    const task = {
                      ID: nodeObject.key,
                      Default_AssignedTo_UserID: nodeObject.assignedToUserID,
                      Default_AssignedTo_GroupID: nodeObject.assignedToUserGroupID,
                      Default_AssignedTo_TemplateTaskID: nodeObject.assignedToTemplateTaskID,
                      Default_AssignedTo_TemplateFieldID: nodeObject.assignedToTemplateFieldID
                    }

                    this.setState({ taskAssignmentDialogTask: task })
                  }
                }, {
                  label: language.translate('application.taskSettings'),
                  action: (nodeObject) => {
                    if (['new', 'copy'].includes(nodeObject.status)) {
                      this.raiseError(language.translate('application.youMustSaveYourChangesFirst'))
                      return false
                    }

                    this.showTaskSettings(nodeObject.key)
                  }
                }, {
                  label: language.translate('application.automatedActions'),
                  action: (nodeObject) => {
                    if (['new', 'copy'].includes(nodeObject.status)) {
                      this.raiseError(language.translate('application.youMustSaveYourChangesFirst'))
                      return false
                    }

                    this.openTemplateActions(nodeObject.key)
                  }
                }
              ]}
              responseMenuItems={[
                {
                  label: language.translate('application.responseSettings'),
                  action: (nodeObject) => {
                    if (['new', 'copy'].includes(nodeObject.status)) {
                      this.raiseError(language.translate('application.youMustSaveYourChangesFirst'))
                      return false
                    }

                    this.showResponseSettings(nodeObject.key)
                  }
                }, {
                  label: language.translate('application.automatedActions'),
                  action: (nodeObject) => {
                    if (['new', 'copy'].includes(nodeObject.status)) {
                      this.raiseError(language.translate('application.youMustSaveYourChangesFirst'))
                      return false
                    }

                    this.openTemplateActions(null, nodeObject.key)
                  }
                }, {
                  label: language.translate('application.launchAnotherProcess'),
                  action: (nodeData) => {
                    if (this.state.diagramNeedsToBeSaved) {
                      this.raiseError(language.translate('application.youMustSaveYourChangesFirst'))
                      return false
                    }

                    this.setState({
                      showMapSubProcessDialog: true,
                      subProcessResponseID: nodeData.key
                    })
                  }
                }
              ]}
              startNodeMenuItems={[
                {
                  label: language.translate('application.automatedActions'),
                  action: (nodeObject) => { this.openTemplateActions(null, 'process_started') }
                }, {
                  label: language.translate('application.launchAnotherProcess'),
                  action: (nodeData) => {
                    this.setState({
                      showMapSubProcessDialog: true,
                      subProcessResponseID: nodeData.key
                    })
                  }
                }, {
                  label: language.translate('application.processField', [], true),
                  action: this.openProcessFields.bind(this)
                }
              ]}
              subProcessMenuItems={[
                {
                  label: language.translate('application.openProcess'),
                  action: (nodeData) => {
                    if (this.state.diagramNeedsToBeSaved) {
                      this.raiseError(language.translate('application.youMustSaveYourChangesFirst'))
                      return false
                    }

                    if (nodeData.isExternalProcess) {
                      this.raiseError(language.translate('application.youCannotOpenOtherTemplateAccounts'))
                      return false
                    }

                    push(`/process-template/${nodeData.templateID}`)
                  }
                }, {
                  label: language.translate('application.fieldMapping'),
                  action: (nodeData) => { this.openFieldMappingPanel(nodeData.key) }
                }
              ]}
              diagramMenuItems={[
                {
                  label: language.translate('application.saveDiagram'),
                  action: this.saveDiagram.bind(this)
                }, {
                  label: language.translate('application.automatedActions'),
                  action: () => this.openTemplateActions(null, null)
                }, {
                  label: language.translate('application.templateSettings'),
                  action: () => this.openTemplateSettings()
                }
              ]}
            />
          </div>
          {(mainContentWidth > 600)
            ? <div
              style={{
                bottom: '25px',
                position: 'absolute',
                width: '100%',
                height: '25px',
                padding: '0px 3px'
              }}
            >
              <div
                style={{ textAlign: 'center' }}
              >{language.translate('application.progressViewHelpText')}</div>
            </div> : null}
          <ContactCardDialog
            open={this.state.contactCardOpen}
            userID={this.state.contactCardUserID}
            close={() => this.setState({ contactCardOpen: false })}
          />
          <GroupCardDialog
            open={this.state.groupCardOpen}
            groupID={this.state.groupCardGroupID}
            close={() => this.setState({ groupCardOpen: false })}
          />
          <Dialog
            open={this.state.showHelp}
            onClose={() => this.setState({ showHelp: false })}
          >
            <DialogTitle
              style={{
                backgroundColor: palette.headerBackgroundColor
              }}
              disableTypography
            >
              <Typography
                variant='h6'
                style={{ color: palette.alternateTextColor }}>
                {language.translate('application.templateDesignerHelp')}
              </Typography>
            </DialogTitle>
            <DialogContent style={{ width: '400px' }}>
              <div style={{ padding: '20px 0px' }}>
                {language.translate('application.progressViewHelpText')}
              </div>
              <div>
                <Paper
                  elevation={2}
                  style={{
                    padding: '5px',
                    backgroundColor: palette.accent1Color,
                    margin: '5px',
                    textAlign: 'center',
                    color: palette.canvasColor
                  }}
                >
                  {language.translate('application.blueNodesAreTasks')}
                </Paper>
                <Paper
                  elevation={2}
                  style={{
                    padding: '5px',
                    backgroundColor: palette.accent4Color,
                    margin: '5px',
                    textAlign: 'center',
                    color: palette.canvasColor
                  }}
                >
                  {language.translate('application.greyNodesAreResponses')}
                </Paper>
              </div>
            </DialogContent>
          </Dialog>
          <IconSelectorDialog
            onRequestClose={() => this.setState({ showIconSelector: false })}
            selectedIcon={template.IconName}
            open={showIconSelector}
            onSelect={(icon) => {
              template.IconName = icon

              this.saveTemplateSettings(template)

              this.setState({
                showIconSelector: false
              })
            }}
          />
          {(this.state.showMapSubProcessDialog)
            ?
            <MapSubProcessDialog
              open
              close={() => this.setState({ showMapSubProcessDialog: false })}
              onSubmit={this.createMigrationPlan.bind(this)}
            /> : null}
          {taskAssignmentDialogTask.ID
            ? <TaskAssignmentDialog
              open={!!(taskAssignmentDialogTask.ID)}
              close={() => this.setState({ taskAssignmentDialogTask: {} })}
              templateId={template.ID}
              userId={taskAssignmentDialogTask.Default_AssignedTo_UserID}
              groupId={taskAssignmentDialogTask.Default_AssignedTo_GroupID}
              dynamicUserId={taskAssignmentDialogTask.Default_AssignedTo_TemplateFieldID || null}
              dynamicTaskId={taskAssignmentDialogTask.Default_AssignedTo_TemplateTaskID || null}
              onSubmit={(groupId, userId, dynamicId, dynamicProperty) => {
                taskAssignmentDialogTask.Default_AssignedTo_GroupID = groupId
                taskAssignmentDialogTask.Default_AssignedTo_UserID = userId

                if (dynamicId) { taskAssignmentDialogTask[dynamicProperty] = dynamicId }

                this.saveTaskSettings(taskAssignmentDialogTask)
                this.setState({ taskAssignmentDialogTask: {} })
              }}
            />
            : null}
          <RapidEntryForm
            open={this.state.showRapidInputWindow}
            close={() => this.setState({ showRapidInputWindow: false })}
            submit={value => this.rapidNodesFromText(value)}
          />
          <Subscriber
            channelName={`Private-${user.accountID}-ProcessTemplate-${template.ID}`}
            events={['TemplateDiagramUpdated']}
            callback={(data) => {
              if (!diagramNeedsToBeSaved) { this.fetchTemplateData(template.ID, true) } else if (!this.state.isSaving) { this.raiseError(language.translate('application.templateNeedsRefreshing')) }
            }}
          />
          {(showWaitingDialog)
            ? <WaitingDialog
              messages={[
                language.translate('application.buildingNodes'),
                language.translate('application.connectingLines'),
                language.translate('application.automatingActions'),
                language.translate('application.preparingTpsReports')
              ]}
            />
            : null}
          {(showDocumentTemplateDialog && documentTemplatesLoaded &&
            <PrintableTemplateDocumentDialog
              onClose={() => { this.setState({ showDocumentTemplateDialog: false }) }}
              documentTemplates={documentTemplates}
              fetchPrintableDocument={this.fetchPrintableDocument.bind(this)}
            />)}
          <Dialog
            open={showImportMessage}
            disableBackdropClick
            disableEscapeKeyDown
            onClose={() => this.setState({ showImportMessage: false })}
            style={{ zIndex: 9999 }}
          >
            <DialogTitle
              style={{
                backgroundColor: palette.headerBackgroundColor
              }}
              disableTypography
            >
              <Typography
                variant='h6'
                style={{ color: palette.alternateTextColor }}>
                {language.translate('application.copyingTemplate')}
              </Typography>
            </DialogTitle>
            <DialogContent style={{ width: '300px' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  paddingTop: '30px'
                }}
              >
                {language.translate('application.copyTemplateMessage')}
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                color='primary'
                onClick={() => this.setState({ showImportMessage: false })}
                variant='contained'
              >
                {language.translate('application.close')}
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
    )
  }
}

ProcessTemplate.propTypes = {
  params: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.string,
  email: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  enableFullScreen: PropTypes.func.isRequired,
  disableFullScreen: PropTypes.func.isRequired,
  addMenuItem: PropTypes.func.isRequired,
  removeMenuItem: PropTypes.func.isRequired,
  isFullScreen: PropTypes.bool.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  post: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired,
  isRightPanelOpen: PropTypes.bool,
  userAccountID: PropTypes.string,
  addSteps: PropTypes.func.isRequired,
  removeStep: PropTypes.func.isRequired,
  mainContentWidth: PropTypes.number.isRequired,
  showUndoMessage: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired
}

ProcessTemplate.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object,
  location: PropTypes.object,
  user: PropTypes.object
}

const mapStateToProps = (state, ownProps) => ({
  title: state.application.title,
  email: state.auth.userName,
  token: state.auth.token,
  isFullScreen: state.application.fullScreen,
  isRightPanelOpen: state.application.rightPanelOpen,
  userAccountID: state.auth.accountID,
  mainContentWidth: state.application.mainContentWidth,
  location: ownProps.location,
})

const mapDispatchToProps = dispatch => ({
  dispatch,
  enableFullScreen: bindActionCreators(enableFullScreen, dispatch),
  disableFullScreen: bindActionCreators(disableFullScreen, dispatch),
  addMenuItem: bindActionCreators(addMenuItem, dispatch),
  removeMenuItem: bindActionCreators(removeMenuItem, dispatch),
  showSnackbar: bindActionCreators(showSnackbar, dispatch),
  post: bindActionCreators(post, dispatch),
  get: bindActionCreators(get, dispatch),
  push: bindActionCreators(push, dispatch),
  replace: bindActionCreators(replace, dispatch),
  addSteps: bindActionCreators(addSteps, dispatch),
  removeStep: bindActionCreators(removeStep, dispatch),
  showUndoMessage: bindActionCreators(showUndoMessage, dispatch),
  goBack: bindActionCreators(goBack, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ProcessTemplate)
