import PropTypes from 'prop-types'
import React from 'react'
import { Card, CardContent } from '@material-ui/core'

class CustomTooltip extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {}
  }

  render () {
    const { payload, label, active, labelPrefix } = this.props

    if (!active) { return null }

    return (
      <Card style={{ zIndex: 9999 }}>
        <CardContent>
          <div>
            <div>
              {label}
            </div>
            <div>
              {`${labelPrefix}: ${payload[0].value}`}
            </div>
          </div>
        </CardContent>
      </Card>
    )
  }
}

CustomTooltip.propTypes = {
  type: PropTypes.string,
  payload: PropTypes.array,
  label: PropTypes.string,
  active: PropTypes.bool,
  labelPrefix: PropTypes.string
}

CustomTooltip.contextTypes = {
  muiTheme: PropTypes.object,
  location: PropTypes.object,
  language: PropTypes.object
}

export default CustomTooltip
