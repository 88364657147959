import { SET_JOYRIDE_OBJECT, SET_STEPS } from '../constants/JoyrideTypes'

export function removeStep (id) {
  return function (dispatch, state) {
    const steps = state().joyride.steps.slice()

    steps.map((step, index) => {
      if (step.id === id) { steps.splice(index, 1) }
    })

    dispatch(setSteps(steps))
  }
}

export function addSteps (steps) {
  return function (dispatch, state) {
    const prevSteps = state().joyride.steps.slice()
    const newSteps = prevSteps.concat(steps)

    dispatch(setSteps(newSteps))
  }
}

export function setSteps (steps) {
  return {
    type: SET_STEPS,
    payload: {
      steps
    }
  }
}

export function setJoyrideObject (joyride) {
  return {
    type: SET_JOYRIDE_OBJECT,
    payload: {
      joyride
    }
  }
}
