import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get } from '~/actions/base'
import { Form } from 'formsy-react'
import {
  Avatar, Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, Typography
} from '@material-ui/core'
import { MenuItem } from 'material-ui'
import { FormsySelect } from 'formsy-material-ui'
import CancelButton from '../Forms/CancelButton'

class AccessControlEdit extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      canSubmit: true,
      isDirty: false,
      groups: null,
      groupsLoaded: false,
      users: null,
      usersLoaded: false,
      item: null
    }

    //componentWillMount migration start
    this.setState({ item: this.props.accessControlItem })
    //componentWillMount migration end
  }

  // MIGRATED TO componentDidMount and constructor
  // componentWillMount () {
  //   this.fetchGroups()
  //   this.setState({ item: this.props.accessControlItem })
  // }

  componentDidMount() {
    if (this.state.groupsLoaded === false) {
      this.fetchGroups()
    }
  }


  componentDidUpdate(prevProps, prevState) {
    if (this.props.accessControlItem !== prevProps.accessControlItem) { this.setState({ item: this.props.accessControlItem }) }

    if (this.state.item !== prevState.item) { this.fetchGroupUsers() }
  }

  fetchGroups() {
    this.props.dispatch(get('usergroup/list', {
      onSuccess: (response) => {
        this.setState({
          groups: response.UserGroupList,
          groupsLoaded: true
        })
      }
    }))
  }

  fetchGroupUsers() {
    if (!this.state.item || !this.state.item.UserGroupID || this.state.item.UserGroupID === '0') { return }

    this.props.dispatch(get(`usergroup/${this.state.item.UserGroupID}/user/list`, {
      onSuccess: (response) => {
        this.setState({
          users: response.UserList,
          usersLoaded: true
        })
      }
    }))
  }

  enableButton() {
    this.setState({ canSubmit: true })
  }

  disableButton() {
    this.setState({ canSubmit: false })
  }

  render() {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const { item, groups, users } = this.state
    const { isDefaultAccess } = this.props

    if (!item) { return null }

    const groupOptions = [<MenuItem
      value={false}
      key={0}
      primaryText={(item.UserGroupID === '') ? '' : language.translate('application.selectAGroup')}
    />]
    const userOptions = [<MenuItem
      value={false}
      key={0}
      primaryText={(item.UserID === '') ? '' : language.translate('application.all')}
    />]

    if (groups) {
      groups.map((option) => {
        groupOptions.push(<MenuItem value={option.ID} key={option.ID} primaryText={option.GroupName} />)
      })
    }

    if (users) {
      users.map((option) => {
        userOptions.push(
          <MenuItem
            value={option.ID}
            key={option.ID}
            primaryText={`${option.FullName} ${(option.Position) ? `(${option.Position})` : ''}`}
            leftIcon={<Avatar
              src={option.ProfilePicURL}
              style={{ height: '40px', width: '40px' }}
            />}
            innerDivStyle={{ padding: '5px 0px 10px 75px' }}
          />)
      })
    }

    return (
      <Dialog
        open={this.props.isOpen}
        onClose={this.props.onCloseRequest}
      >
        <DialogTitle
          style={{
            backgroundColor: palette.headerBackgroundColor
          }}
          disableTypography
        >
          <Typography
            variant='h6'
            style={{ color: palette.alternateTextColor }}>
            {(isDefaultAccess) ? language.translate('application.defaultAccess') : language.translate('application.accessControl')}
          </Typography>
        </DialogTitle>
        <DialogContent style={{ width: '400px' }}>
          <Form onValid={this.enableButton.bind(this)} onInvalid={this.disableButton.bind(this)}>
            {(!isDefaultAccess)
              ? <FormsySelect
                name='group'
                value={item.UserGroupID || false}
                onChange={(e, value) => {
                  item.UserGroupID = value
                  item.UserGroup_Name = e.target.textContent

                  this.setState({ item, isDirty: true }, this.fetchGroupUsers())
                }}
                floatingLabelText={language.translate('application.group')}
                validationErrors={this.context.language.messages.validationErrors}
                required
              >
                {groupOptions}
              </FormsySelect>
              : null}
            {(this.state.item.UserGroupID && !isDefaultAccess)
              ? <FormsySelect
                name='users'
                value={this.state.item.UserID || false}
                onChange={(e, value) => {
                  item.UserID = value
                  item.User_FullName = e.target.textContent

                  this.setState({ item, isDirty: true })
                }}
                floatingLabelText={language.translate('application.user')}
                validationErrors={this.context.language.messages.validationErrors}
              >
                {userOptions}
              </FormsySelect>
              : null}
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <FormControlLabel
                control={<Checkbox
                  checked={item.Access_ViewTemplate}
                  style={{ paddingTop: '4px', paddingBottom: '4px' }}
                  color='primary'
                  onChange={(e, isChecked) => {
                    item.Access_ViewTemplate = isChecked
                    this.setState({ item, isDirty: true })
                  }}
                />}
                label={language.translate('application.view')}
              />
              <FormControlLabel
                control={<Checkbox
                  checked={item.Access_EditTemplate}
                  style={{ paddingTop: '4px', paddingBottom: '4px' }}
                  color='primary'
                  onChange={(e, isChecked) => {
                    item.Access_EditTemplate = isChecked
                    this.setState({ item, isDirty: true })
                  }}
                />}
                label={language.translate('application.edit')}
              />
              <FormControlLabel
                control={<Checkbox
                  checked={item.Access_StartTemplate}
                  style={{ paddingTop: '4px', paddingBottom: '4px' }}
                  color='primary'
                  onChange={(e, isChecked) => {
                    item.Access_StartTemplate = isChecked
                    this.setState({ item, isDirty: true })
                  }}
                />}
                label={language.translate('application.launch')}
              />
            </div>
            <div style={{ marginTop: '10px' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                <CancelButton
                  onClick={this.props.onCloseRequest}
                  variant='contained'
                  style={{ margin: '10px' }}
                />
                <Button
                  onClick={() => this.props.onSave(this.state.item)}
                  disabled={!this.state.canSubmit || !this.state.isDirty}
                  variant='contained'
                  color='primary'
                  style={{ margin: '10px' }}
                >
                  {language.translate('application.submit')}
                </Button>
              </div>
            </div>
          </Form>
        </DialogContent>
      </Dialog>
    )
  }
}

AccessControlEdit.propTypes = {
  accessControlItem: PropTypes.object,
  onSave: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  onCloseRequest: PropTypes.func.isRequired,
  isDefaultAccess: PropTypes.bool
}

AccessControlEdit.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  dispatch,
  get: bindActionCreators(get, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(AccessControlEdit)
