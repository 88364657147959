import PropTypes from 'prop-types'
import React from 'react'
import FormHelper from '../../businessLogic/formHelper'
import { Button, IconButton } from '@material-ui/core'
import { Clear, GpsNotFixed } from '@material-ui/icons'
import Geolocation from '../Layout/Geolocation'
import FieldWrapper from './FieldWrapper'

class Location extends React.Component {
  constructor (props, context) {
    super(props, context)
    this.state = {
      getLocation: false
    }
  }

  componentDidMount () {
    if (!this.props.field.ValueID && this.props.field.RequiredField) { this.setState({ getLocation: true }) }
  }

  clearLocation () {
    const { field, onChange } = this.props

    field.ValueID = ''
    field.Value = ''

    if (onChange) { onChange(field) }
  }

  render () {
    const { getLocation } = this.state
    const { field, onChange, dispatch, wrapperProps } = this.props
    const { muiTheme: { palette }, language } = this.context

    return (
      <FieldWrapper
        label={FormHelper.decodeHTML(field.FieldName)}
        required={field.RequiredField}
        showDivider
        {...wrapperProps}
      >
        {(field.ReadOnlyField)
          ? <div style={{ position: 'relative', padding: '5px 0px', fontSize: '15px' }}>
            <a href={`https://www.google.com/maps/search/?api=1&query=${field.ValueID}`} target='_blank'>
              {field.ValueID}
            </a>
          </div>
          : <div style={{ position: 'relative', minHeight: '40px', display: 'flex', alignItems: 'center' }}>
            {(field.ValueID)
              ? <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className='input-style'>
                  <a href={`https://www.google.com/maps/search/?api=1&query=${field.ValueID}`} target='_blank'>
                    {field.ValueID}
                  </a>
                </div>
                <IconButton
                  style={{
                    position: 'absolute',
                    right: '0px'
                  }}
                  onClick={this.clearLocation.bind(this)}
                >
                  <Clear />
                </IconButton>
              </div>
              : <Button
                style={{
                  position: 'absolute',
                  right: '4px',
                  padding: '0',
                  width: '24px',
                  minWidth: '48px'
                }}
                color='primary'
                variant='contained'
                onClick={() => {
                  field.ValueID = ''

                  this.setState({ getLocation: true }, () => {
                    if (onChange) { onChange(field) }
                  })
                }}
              >
                <GpsNotFixed />
              </Button>}

            {(getLocation)
              ? <Geolocation
                dispatch={dispatch}
                onLocationChange={({ latitude, longitude }) => {
                  if (latitude && longitude) {
                    field.ValueID = `${latitude.toPrecision(10)}, ${longitude.toPrecision(10)}`

                    this.setState({ getLocation: false }, () => {
                      if (onChange) { onChange(field) }
                    })
                  }
                }}
              />
              : null}
            {(!field.ValueID && field.RequiredField)
              ? <div
                style={{
                  color: palette.errorColor,
                  fontSize: '12px'
                }}
              >{language.messages.validationErrors.isDefaultRequiredValue}</div>
              : ''
            }
          </div>}
      </FieldWrapper>)
  }
}

Location.propTypes = {
  field: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  wrapperProps: PropTypes.object
}

Location.contextTypes = {
  language: PropTypes.object,
  user: PropTypes.object,
  muiTheme: PropTypes.object
}

export default Location
