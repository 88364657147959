import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { showSnackbar } from '../actions/snackbar'
import CustomField from '../components/CustomField'
import {
  Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Collapse, Table, TableBody, TableRow, TableCell
} from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { get, post } from '../actions/base'
import { Form } from 'formsy-react'
import moment from 'moment'
import FormHelper from '../businessLogic/formHelper'
import CopyToClipboard from 'react-copy-to-clipboard'
import RightPanelContent from '../components/Layout/RightPanelContent'
import ProcessFieldHelper from '../businessLogic/processFieldHelper'

class ProcessInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      componentDidMountRanOnce: false,
      process: [],
      processFields: [],
      taskList: [],
      processLoaded: false,
      processFieldsLoaded: false,
      taskListLoaded: false,
      processFieldsExpanded: false,
      isSubmitting: false
    }
  }

  // MIGRATED TO componentDidMount
  // componentWillMount() {
  //     this.fetchAll()
  // }

  componentDidMount() {
    if (this.state.componentDidMountRanOnce === false) {
      this.fetchAll()
      this.setState({ componentDidMountRanOnce: true })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.processId !== this.props.processId) { this.fetchAll() }
  }

  fetchAll() {
    this.fetchProcess()
    this.fetchProcessFields()
    this.fetchTaskList()
  }

  fetchProcess() {
    const { processId } = this.props

    this.props.get(`processinstance/${processId}`, {
      onSuccess: (response) => {
        this.setState({
          process: response.ProcessInstance,
          processLoaded: true
        })
      }
    })
  }

  fetchProcessFields() {
    const { processId } = this.props

    this.props.get(`processinstance/${processId}/field/list`, {
      onSuccess: (response) => {
        this.setState({
          processFields: response.ProcessInstanceFieldList,
          processFieldsLoaded: true
        })
      }
    })
  }

  saveProcessFields() {
    const { processId } = this.props

    this.setState({ isSubmitting: true })

    const fields = this.state.processFields.filter(field => (!field.ReadOnlyField))

    const body = JSON.stringify({
      ProcessInstanceFieldList: this.getDirtyFields(fields)
    })

    this.props.dispatch(post(`processinstance/${processId}/field/list`, body, {
      onSuccess: (response) => {
        this.setState({
          isSubmitting: false,
          processFields: response.ProcessInstanceFieldList
        })
      },

      onError: (error) => {
        this.setState({ isSubmitting: false })
      }
    }))
  }

  getDirtyFields(fields) {
    const dirtyFields = fields.filter(field => (field.isDirty || !field.ID))

    dirtyFields.map(field => delete field.isDirty)

    return dirtyFields
  }

  fetchTaskList() {
    const { processId } = this.props

    this.props.get(`processinstance/${processId}/task/list`, {
      onSuccess: (response) => {
        this.setState({
          taskList: response.ProcessInstanceTaskList,
          taskListLoaded: true
        })
      }
    })
  }

  readableProcessFields() {
    let fields = ''

    this.state.processFields.map((field) => {
      fields += `${FormHelper.decodeHTML(field.FieldName)}: ${FormHelper.decodeHTML(field.Value.replace(/<br\s*[/]?>/gi, ' '))}\n`
    })

    return fields
  }

  readableTaskListFields() {
    const { language } = this.context
    let fields = ''

    this.state.taskList.map((task) => {
      fields += `${language.translate('application.task')}: ${FormHelper.decodeHTML(task.TaskText_Display)}\n`
      fields += `${language.translate('application.response')}: ${FormHelper.decodeHTML(task.SelectedResponse_Text)}\n`
      fields += `${language.translate('application.notes')}: ${FormHelper.decodeHTML(task.Notes)}\n`
      fields += `${language.translate('application.responseBy')}: ${FormHelper.decodeHTML(task.Completed_FullName)} on ${moment(task.Completed_Date_Local).format('llll')}\n\n`
    })

    return fields
  }

  getProcessLog(process) {
    const { language } = this.context

    return (
      `ProcessPlan.com ${language.translate('application.textLogTitle')}\n${
      language.translate('application.processTitle')}: ${process.ProcessTemplateTitle}\n${
      language.translate('application.instanceID')}: ${process.ID}\n${
      language.translate('application.initiatedBy')}: ${process.CreatedByUser_FullName} on ${moment(process.CreatedDate_Local).format('llll')}\n${
      language.translate('application.completedDate')}: ${moment(process.CompletedDate_Local).format('llll')}\n\n${
      language.translate('application.fieldsTitle')}\n${

      this.readableProcessFields()}\n${

      this.readableTaskListFields()}`
    )
  }

  render() {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const { process, processFields, processFieldsLoaded, processFieldsExpanded, processLoaded, taskListLoaded } = this.state
    const { showSnackbar, rightPanelWidth } = this.props

    return (
      <RightPanelContent
        title={language.translate('application.processInformation')}
        closePanel={() => this.props.onRequestClose()}
      >
        <div style={{ padding: '15px', display: 'flex', flexDirection: (rightPanelWidth > 800) ? 'row' : 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'column', flex: 1, marginRight: (rightPanelWidth > 800) ? '10px' : '0px' }}>
            <Card className='card'>
              <CardHeader
                title={language.translate('application.completionTitle')}
                subheader={language.translate('application.completionSubTitle')}
              />
              <CardContent>
                {processLoaded === true
                  ? <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>{language.translate('application.instanceID')}:</TableCell>
                        <TableCell>{process.ID}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{language.translate('application.processTitle')}:</TableCell>
                        <TableCell>{process.ProcessTemplateTitle}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{language.translate('application.initiatedBy')}:</TableCell>
                        <TableCell>{process.CreatedByUser_FullName}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{language.translate('application.initiatedDate')}:</TableCell>
                        <TableCell>{moment(process.CreatedDate_Local).format('llll')}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{language.translate('application.daysToComplete')}:</TableCell>
                        <TableCell>{process.ProcessInstanceAge}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{language.translate('application.averageDaysToCompletion')}:</TableCell>
                        <TableCell>{process.AverageDaysToCompletion}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{language.translate('application.completedDate')}:</TableCell>
                        <TableCell>{(process.CompletedDate_Local) ? moment(process.CompletedDate_Local).format('llll') : null}</TableCell>
                      </TableRow>
                      {(process.CanceledDate_Local)
                        ? <TableRow>
                          <TableCell>{language.translate('application.canceledDate')}:</TableCell>
                          <TableCell>{(process.CanceledDate_Local) ? moment(process.CanceledDate_Local).format('llll') : null}</TableCell>
                        </TableRow>
                        : null}
                      {(process.CanceledByUser_FullName)
                        ? <TableRow>
                          <TableCell>{language.translate('application.canceledBy')}:</TableCell>
                          <TableCell>{process.CanceledByUser_FullName}</TableCell>
                        </TableRow>
                        : null}
                    </TableBody>
                  </Table>
                  : <CircularProgress className='loader' />}
              </CardContent>
            </Card>

            <Card
              className='card'
            >
              <CardHeader
                title={language.translate('application.fieldsTitle')}
                subheader={language.translate('application.fieldsSubTitle')}
              />
              <CardContent>
                {processFieldsLoaded === true
                  ? <Form>
                    {processFields.slice(0, process.NumberOfHeaderFieldsToDisplay).map((field, index) => {
                      let filter = null

                      if (field.DependantOnFieldType) {
                        filter = ProcessFieldHelper.createFilterFromDependantFieldValue(field.DependantOnFieldType, processFields)
                      }

                      if (ProcessFieldHelper.isInstanceLookupField(field.FieldType) && field.MetaData) {
                        filter = ProcessFieldHelper.createFilterFromParsedMetaData(field.MetaData, processFields)
                      }

                      return <CustomField
                        key={field.ProcessTemplateFieldID}
                        field={field}
                        fieldList={processFields}
                        ref={field.ProcessTemplateFieldID}
                        dispatch={this.props.dispatch}
                        filter={filter}
                        onUpdate={(field) => {
                          processFields[index] = field
                          processFields[index].isDirty = true

                          this.setState({ processFields })
                        }}
                      />
                    })}

                    <Collapse in={processFieldsExpanded}>
                      {processFields.slice(process.NumberOfHeaderFieldsToDisplay, processFields.length).map((field, index) => {
                        let filter = null

                        if (field.DependantOnFieldType) {
                          filter = ProcessFieldHelper.createFilterFromDependantFieldValue(field.DependantOnFieldType, processFields)
                        }

                        if (ProcessFieldHelper.isInstanceLookupField(field.FieldType) && field.MetaData) {
                          filter = ProcessFieldHelper.createFilterFromParsedMetaData(field.MetaData, processFields)
                        }

                        return <CustomField
                          key={field.ProcessTemplateFieldID}
                          field={field}
                          fieldList={processFields}
                          ref={field.ProcessTemplateFieldID}
                          dispatch={this.props.dispatch}
                          filter={filter}
                          onUpdate={(field) => {
                            processFields[index + process.NumberOfHeaderFieldsToDisplay] = field
                            processFields[index + process.NumberOfHeaderFieldsToDisplay].isDirty = true

                            this.setState({ processFields })
                          }}
                        />
                      })}
                    </Collapse>
                  </Form>
                  : <CircularProgress className='loader' />}
              </CardContent>
              {processFields.length > process.NumberOfHeaderFieldsToDisplay
                ? <CardActions>
                  <Button
                    color='primary'
                    onClick={this.saveProcessFields.bind(this)}
                    variant='contained'
                    disabled={this.state.isSubmitting}
                  >
                    {language.translate('application.saveFields')}
                  </Button>
                  <Button
                    onClick={() => this.setState({ processFieldsExpanded: !processFieldsExpanded })}
                  >
                    {(processFieldsExpanded)
                      ? language.translate('application.less')
                      : language.translate('application.more')}
                    {(processFieldsExpanded) ? <ExpandLess /> : <ExpandMore />}
                  </Button>
                </CardActions>
                : ''
              }
            </Card>
          </div>

          <Card className='card' style={{ flex: 1 }}>
            <CardHeader
              title={language.translate('application.textLogTitle')}
              subheader={language.translate('application.textLogSubtitle')}
            />
            {processLoaded && processFieldsLoaded && taskListLoaded
              ? <CopyToClipboard
                text={this.getProcessLog(process)}
                onCopy={() => showSnackbar(language.translate('application.processInfoCopied'))}
              >
                <div
                  style={{
                    border: `1px solid ${palette.borderColor}`,
                    color: 'rgba(0, 0, 0, 0.870588)',
                    padding: '15px',
                    fontSize: '14px',
                    margin: '10px',
                    overflow: 'hidden',
                    cursor: 'copy'
                  }}
                  dangerouslySetInnerHTML={{ __html: FormHelper.encodeHTML(this.getProcessLog(process)) }}
                />
              </CopyToClipboard>
              : <CircularProgress className='loader' />}
          </Card>
        </div>
      </RightPanelContent>
    )
  }
}

ProcessInfo.propTypes = {
  processId: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  title: PropTypes.string,
  rightPanelWidth: PropTypes.number,
  showSnackbar: PropTypes.func.isRequired
}

ProcessInfo.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

const mapStateToProps = state => ({
  user: state.auth,
  title: state.application.title,
  rightPanelWidth: state.application.rightPanelWidth
})

const mapDispatchToProps = dispatch => ({
  get: bindActionCreators(get, dispatch),
  post: bindActionCreators(post, dispatch),
  showSnackbar: bindActionCreators(showSnackbar, dispatch),
  dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(ProcessInfo)
