import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'react-router-redux'
import { Avatar, Card, Divider, List, ListItem, ListItemText, Tooltip } from '@material-ui/core'
import FormHelper from '~/businessLogic/formHelper'
import moment from 'moment'
import objectAssign from 'object-assign'
import { GeneratedIcon, Icon } from '../Icon'

class TaskList extends React.Component {
  getPriorityColor (task) {
    const completed = !!(task.Completed_UserID)
    const palette = this.context.muiTheme.palette

    if (completed) { return palette.primary3Color }
    if (task.PastDue) { return palette.errorColor }
    if (task.DueSoon) { return palette.warningColor }

    return palette.successColor
  }

  onTaskSelection (taskId) {
    this.props.push({
      pathname: this.context.location.pathname,
      query: objectAssign({}, this.context.location.query, { ptype: 'task', task: taskId, pid: taskId })
    })
  }

  getProcessHeaderFields (task) {
    const items = []

    for (let i = 1; i <= task.NumberOfHeaderFieldsToDisplay; i++) {
      if (task[`ProcessInstance_HeaderField${i}_Name`]) {
        items.push(
          <div key={i} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
            {
              `${FormHelper.decodeHTML(task[`ProcessInstance_HeaderField${i}_Name`])}: ${
                FormHelper.decodeHTML(task[`ProcessInstance_HeaderField${i}_Value`])}`
            }
          </div>)
      }
    }

    return items
  }

  getTemplateAvatar (task, size) {
    return (
      <Tooltip title={task.ProcessTemplateTitle}>
        {(task.ProcessTemplateIconName)
          ? <Icon style={{ minWidth: `${size}px` }} icon={task.ProcessTemplateIconName} size={size} />
          : <GeneratedIcon
            style={{ width: size, height: size, fontSize: (size < 40) ? 'inherit' : null }}
            text={task.ProcessTemplateTitle}
            randomizer={task.ProcessTemplateID}
          />}
      </Tooltip>)
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { user } = this.context
    const { containerStyle, showAssignedTo } = this.props

    return (
      <Card
        style={objectAssign({}, { padding: '0px', marginBottom: '10px', width: '100%' }, containerStyle)}
      >
        <List style={{ paddingTop: '0px', paddingBottom: '0px' }}>
          {
            this.props.tasks.map((task, index) => {
              const processFieldArray = this.getProcessHeaderFields(task)
              return (
                React.Children.toArray([
                  <ListItem
                    key={task.ID}
                    button
                    onClick={() => this.onTaskSelection(task.ID)}
                    selected={this.props.location.query.task === task.ID}
                    style={{ borderLeft: `7px solid ${this.getPriorityColor(task)}` }}
                  >
                    {
                      (showAssignedTo)
                        ? <Tooltip title={task.AssignedTo_DisplayName}>
                          <Avatar
                            src={task.AssignedTo_ProfilePicURL}
                            style={{ height: 40, width: 40 }}
                          />
                        </Tooltip>
                        : this.getTemplateAvatar(task, 40)
                    }
                    <ListItemText
                      primary={
                        <div>
                          <div style={{ fontSize: '12px', color: palette.accent3Color, display: 'flex', flexDirection: 'row' }}>
                            {(task.DueDate_Local &&
                              <div style={{ paddingRight: '5px' }}>
                                {moment(task.DueDate_Local).format((user.dateFormat) ? user.dateFormat + ' hh:mm a' : 'YYYY-MM-DD hh:mm a') + ' | '}
                              </div>
                            )}
                            <div>
                              {task.ProcessTemplateTitle}
                            </div>
                          </div>
                          <div style={{ display: 'block' }}>
                            {(showAssignedTo && <div style={{
                              paddingRight: '5px',
                              paddingTop: '5px',
                              display: 'flex',
                              float: 'left'
                            }}>{this.getTemplateAvatar(task, 20)}</div>)}
                            {FormHelper.decodeHTML(task.TaskText_Display)}
                          </div>
                        </div>}
                      secondary={
                        (processFieldArray.length)
                          ? <div>
                            {
                              processFieldArray.map(item => item)
                            }
                          </div>
                          : null
                      }
                      secondaryTypographyProps={{ component: 'div' }}
                    />
                  </ListItem>,
                  (index < this.props.tasks.length - 1) ? <Divider key={task.ID + 'div'} /> : null
                ])
              )
            })}
        </List>
      </Card>
    )
  }
}

TaskList.propTypes = {
  tasks: PropTypes.array.isRequired,
  push: PropTypes.func.isRequired,
  location: PropTypes.object,
  showAssignedTo: PropTypes.bool,
  containerStyle: PropTypes.object
}

TaskList.contextTypes = {
  muiTheme: PropTypes.object,
  location: PropTypes.object,
  user: PropTypes.object
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(TaskList)
