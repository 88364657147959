import PropTypes from 'prop-types'
import React from 'react'
import { MenuItem } from 'material-ui'
import FormHelper from '../../businessLogic/formHelper'
import { FormsySelect } from 'formsy-material-ui'
import FieldWrapper from './FieldWrapper'

class Select extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  handleChange (event, value, index) {
    const { field, onChange } = this.props

    field.Value = (field.FieldOptionList[index - 1]) ? field.FieldOptionList[index - 1].OptionDescription : ''
    field.ValueID = value.toString()

    if (onChange) { onChange(field) }
  }

  render () {
    const { language } = this.context
    const { field, wrapperProps } = this.props
    // setting the default menu item to display based on state until formsy-material-ui fixes it from their end
    const options = [<MenuItem
      value=''
      key={0}
      primaryText={(field.Value === '') ? '' : language.translate('application.pleaseSelect')}
    />]

    if (field.FieldOptionList) {
      field.FieldOptionList.map((option) => {
        options.push(<MenuItem value={option.ID} key={option.ID} primaryText={option.OptionDescription} />)
      })
    }

    return (
      <FieldWrapper
        label={FormHelper.decodeHTML(field.FieldName)}
        required={field.RequiredField}
        {...wrapperProps}
      >
        {(field.ReadOnlyField)
          ? <div style={{ position: 'relative', padding: '5px 0px', fontSize: '15px' }}>
            {field.Value}
          </div>
          : <FormsySelect
            value={(field.ValueID) ? field.ValueID : ''}
            onChange={this.handleChange.bind(this)}
            fullWidth
            name={field.FieldName}
            required={field.RequiredField}
            validationErrors={this.context.language.messages.validationErrors}
            style={{ width: '100%', maxWidth: '256px' }}
          >
            {options}
          </FormsySelect>}
      </FieldWrapper>
    )
  }
}

Select.propTypes = {
  field: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  wrapperProps: PropTypes.object
}

Select.contextTypes = {
  language: PropTypes.object
}

export default Select
