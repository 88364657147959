import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get, post } from '../../actions/base'
import { showUndoMessage } from '../../actions/snackbar'
import { Button, Card, Divider, List } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { push } from 'react-router-redux'
import TemplateGroupListItem from './TemplateGroupListItem'
import RightPanelContent from '../Layout/RightPanelContent'

class TemplateGroups extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      groups: null,
      isLoaded: false
    }
  }

  // MIGRATED TO componentDidMount
  // componentWillMount () {
  //   this.fetchAllData()
  // }

  componentDidMount() {
    if (this.state.isLoaded === false) {
      this.fetchAllData()
    }
  }

  fetchAllData() {
    this.fetchGroups()
  }

  fetchGroups() {
    this.props.get('processtemplategroup/list', {
      onSuccess: (response) => {
        this.setState({
          isLoaded: true,
          groups: response.ProcessTemplateGroupList
        })
      }
    })
  }

  addNewItem() {
    const newItem = {
      GroupName: ''
    }

    const newGroups = this.state.groups.concat(newItem)

    this.setState({ groups: newGroups })
  }

  deleteGroup(index, item) {
    const { language } = this.context
    const body = JSON.stringify({})

    if (!item.ID) {
      const { groups } = this.state
      groups.splice(index, 1)

      this.setState({ groups })
    } else {
      this.props.post(`processtemplategroup/${item.ID}/delete`, body, {
        onSuccess: (response) => {
          this.fetchGroups()

          this.props.showUndoMessage(
            language.translate('application.groupRemoved'),
            () => {
              this.props.post(`processtemplategroup/${item.ID}/delete/undo`, body, {
                onSuccess: (response) => {
                  this.fetchGroups()
                }
              })
            }
          )
        }
      })
    }
  }

  saveGroup(index, group) {
    const { groups } = this.state
    const endpoint = (!group.ID) ? 'processtemplategroup' : `processtemplategroup/${group.ID}`

    const body = JSON.stringify({
      ProcessTemplateGroup: group
    })

    this.props.post(endpoint, body, {
      onSuccess: (response) => {
        groups[index] = response.ProcessTemplateGroup

        this.setState({ groups })
      }
    })
  }

  render() {
    const { language } = this.context
    const { isLoaded, groups } = this.state

    return (
      <RightPanelContent
        closePanel={() => this.props.close()}
        title={language.translate('application.templateGroups')}
      >
        {(isLoaded)
          ? <div style={{ padding: '15px' }}>
            <Card className='card'>
              <List style={{ padding: '0px' }}>
                {groups.map((group, index) => (
                  React.Children.toArray([
                    <TemplateGroupListItem
                      key={group.ID || index}
                      item={group}
                      deleteGroup={group => this.deleteGroup(index, group)}
                      onSave={group => this.saveGroup(index, group)}
                    />,
                    (index < groups.length - 1) ? <Divider key={index + 1000} /> : null
                  ])
                ))}
              </List>
            </Card>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
              <Button
                onClick={this.addNewItem.bind(this)}
                color='primary'
                variant='contained'
              >
                <Add />
                {language.translate('application.createNewGroup')}
              </Button>
            </div>
          </div>
          : null}
      </RightPanelContent>
    )
  }
}

TemplateGroups.propTypes = {
  dispatch: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  showUndoMessage: PropTypes.func.isRequired
}

TemplateGroups.contextTypes = {
  location: PropTypes.object,
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  dispatch,
  post: bindActionCreators(post, dispatch),
  get: bindActionCreators(get, dispatch),
  push: bindActionCreators(push, dispatch),
  showUndoMessage: bindActionCreators(showUndoMessage, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(TemplateGroups)
