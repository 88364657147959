import PropTypes from 'prop-types'
import React from 'react'
import { Button } from '@material-ui/core'

class TwoFactorConfirmation extends React.Component {
  render () {
    const palette = this.context.muiTheme.palette
    const {language} = this.context
    const {handleConfirm, handleDeny} = this.props

    return (
      <div>
        <h4>{language.translate('application.twoFactorConfirmationMessage')}</h4>
        <div style={{display: 'flex', justifyContent: 'space-around', width: '100%', marginTop: '20px'}}>
          <Button
            variant='contained'
            color='primary'
            onClick={handleConfirm}
          >
            {language.translate('application.yes')}
          </Button>
          <Button
            variant='contained'
            style={{ backgroundColor: palette.errorColor, color: palette.alternateTextColor }}
            onClick={handleDeny}
          >
            {language.translate('application.no')}
          </Button>
        </div>
      </div>
    )
  }
}

TwoFactorConfirmation.propTypes = {
  handleConfirm: PropTypes.func.isRequired,
  handleDeny: PropTypes.func.isRequired
}

TwoFactorConfirmation.contextTypes = {
  muiTheme: PropTypes.object,
  language: PropTypes.object
}

export default TwoFactorConfirmation
