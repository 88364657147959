import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateTitle } from '../actions/application'
import { push } from 'react-router-redux'
import Masonry from 'react-masonry-component'
import { Base64 } from 'js-base64'
import { Divider, Typography } from '@material-ui/core'
import { get } from '../actions/base'
import DataList from '../components/Layout/DataList'
import objectAssign from 'object-assign'
import AddIcon from '../components/Layout/AddIcon'
import UserGroupCard from '../components/User/UserGroupCard'
import HelpIcon from '../components/Layout/HelpIcon'

const masonryOptions = {
  transitionDuration: '0.0s'
}

class UserGroups extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      groups: [],
      update: false
    }
  }

  componentDidMount () {
    this.updateTitle()
  }

  componentDidUpdate () {
    this.updateTitle()
  }

  updateTitle () {
    const { language } = this.context
    this.props.dispatch(updateTitle(language.translate('application.userGroup', [], true)))
  }

  dataLoadCallback (data) {
    this.setState({
      groups: data
    })
  }

  handleGroupSelect (groupId) {
    this.props.push({
      pathname: this.props.location.pathname,
      query: objectAssign({},
        this.props.location.query,
        {
          ptype: 'group',
          pid: groupId,
          gsid: groupId
        })
    })
  }

  viewGroupWorkList (groupId, groupName) {
    let query = {}

    query.search = Base64.encodeURI(JSON.stringify({
      displayOnly: true,
      value: groupId,
      displayText: 'Groups',
      displayValue: groupName,
      operator: 'group-filter'
    }))

    this.props.push({
      pathname: '/',
      query
    })
  }

  createNewGroup () {
    let query = objectAssign({},
      this.props.location.query,
      {
        ptype: 'group'
      })

    delete query.pid
    delete query.gsid

    this.props.push({
      pathname: this.props.location.pathname,
      query
    })
  }

  forceUpdate () {
    this.setState({ update: !this.state.update })
  }

  openOnDemandSkillForm () {
    window.open(`https://dev.processplan.com/public-form?token=JnBmaWQ9MFpFR2hwSEVpN0RTbXkmcGZ0PWNOQzN4RnY5cGV2Q0FrUkRpZXR3SzJ6QW44Qw`, '_blank')
  }

  render () {
    const { user, language, muiTheme: { palette } } = this.context
    const { mainContentWidth, get, location } = this.props
    const { groups } = this.state

    let userGroups = groups.filter((filterGroup) => (!filterGroup.IsOnDemand))
    let odsGroups = groups.filter((filterGroup) => (filterGroup.IsOnDemand))
    let odsGroupsGrouped = odsGroups.reduce((acc, group) => {
      const odsCompanyName = group.AccountID
      acc[odsCompanyName] = acc[odsCompanyName] || []
      acc[odsCompanyName].push(group)
      return acc
    }, {})

    return (
      <div>
        <DataList
          url='usergroup/list/withuserdata'
          pageSize={25}
          dataCallback={this.dataLoadCallback.bind(this)}
          responseProperty='UserGroupList'
          filterableColumns={[{
            name: language.translate('application.groupName'),
            property: 'GroupName'
          }, {
            name: language.translate('application.groupDescription'),
            property: 'GroupDescription'
          }]}
          noDataText={language.translate('application.noGroups')}
          channelName={`Private-${user.accountID}-UserGroup-0`}
          events={['UserGroupCreated', 'UserGroupUpdated', 'UserGroupDeleted']}
          location={location}
          disableMasonry
        >
          <Masonry options={masonryOptions}>
            {userGroups.map((group, index) => (
              <UserGroupCard
                key={group.ID}
                group={group}
                width={(mainContentWidth > 1100) ? '30%' : (mainContentWidth > 750) ? '45%' : '90%'}
                forceUpdate={this.forceUpdate.bind(this)}
                get={get}
                onGroupSelect={this.handleGroupSelect.bind(this)}
                onViewWorkListClick={this.viewGroupWorkList.bind(this)}
                showMenu={(group.AccountID === user.accountID)}
              />))}
          </Masonry>

          {(odsGroups.length)
            ? Object.keys(odsGroupsGrouped).sort().map((groupId, index) => {
              let groups = odsGroupsGrouped[groupId]

              return (
                <div key={`${groupId}-header`}>
                  <div style={{
                    width: '100%',
                    padding: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <Divider style={{ width: '80%' }} />
                    <Typography variant='h6' style={{
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                      {language.translate('application.servicesProvidedBy')} {groups[0].AccountName}
                      <HelpIcon helpUrl={'on-demand-services'}
                                size='small'
                                helpText={language.translate('application.learnMore')} />
                    </Typography>
                    <Typography
                      component='div'
                      variant='body2'
                      style={{
                        maxWidth: '80%',
                        textAlign: 'center'
                      }}>
                      {groups[0].AccountDescription}
                      <span
                        style={{ color: palette.accent3Color, paddingLeft: '5px' }}>
                        {language.translate('application.address')}: {groups[0].AccountPhysicalAddress1} {groups[0].AccountPhysicalCity}, {groups[0].AccountPhysicalState} {groups[0].AccountPhysicalCountry} {groups[0].AccountPhysicalPostalCode}{(groups[0].AccountWebsiteURL &&
                        <span> - {language.translate('application.website')}: <a target='_blank'
                                                                                 href={(groups[0].AccountWebsiteURL.substring(0, 4) !== 'http') ? `http://${groups[0].AccountWebsiteURL}` : groups[0].AccountWebsiteURL}>{groups[0].AccountWebsiteURL}</a></span>)}
                        </span>
                    </Typography>
                  </div>

                  <Masonry options={masonryOptions}>
                    {groups.map((group, index) => (
                      <UserGroupCard
                        key={group.ID}
                        group={group}
                        width={(mainContentWidth > 1100) ? '30%' : (mainContentWidth > 750) ? '45%' : '90%'}
                        forceUpdate={this.forceUpdate.bind(this)}
                        get={get}
                        onGroupSelect={this.handleGroupSelect.bind(this)}
                        onViewWorkListClick={this.viewGroupWorkList.bind(this)}
                      />))}
                  </Masonry>
                </div>)
            }) : null}
        </DataList>
        <AddIcon
          menuItems={[{ text: language.translate('application.newGroup'), onClick: this.createNewGroup.bind(this) }]}
        />
      </div>
    )
  }
}

UserGroups.propTypes = {
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.string,
  get: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  mainContentWidth: PropTypes.number.isRequired,
  location: PropTypes.object
}

UserGroups.contextTypes = {
  muiTheme: PropTypes.object,
  language: PropTypes.object,
  user: PropTypes.object
}

const mapStateToProps = (state, ownProps) => ({
  title: state.application.title,
  mainContentWidth: state.application.mainContentWidth,
  location: ownProps.location
})

const mapDispatchToProps = dispatch => ({
  get: bindActionCreators(get, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(UserGroups)
