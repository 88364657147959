import { combineReducers } from 'redux'
import { routerReducer as routing } from 'react-router-redux'
import auth from './auth'
import navigation from './navigation'
import application from './application'
import snackbar from './snackbar'
import header from './header'
import livechat from './livechat'
import joyride from './joyride'
import pusher from './pusher'
import notification from './notification'

const appReducer = combineReducers({
  auth,
  navigation,
  application,
  snackbar,
  header,
  routing,
  livechat,
  joyride,
  pusher,
  notification
})

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_USER') {
    // state = undefined;
    const { routing, snackbar, pusher, application } = state
    state = { routing, snackbar, pusher, application }

    // manually reset these on application state
    application.rightPanelOpen = false
    application.fullScreen = false
    application.mainAppRef = null
  }

  return appReducer(state, action)
}

export default rootReducer
