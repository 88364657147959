import PropTypes from 'prop-types'
import React from 'react'
import FormHelper from '../../businessLogic/formHelper'
import { Button, Checkbox, FormControlLabel } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { get } from '../../actions/base'
import FieldWrapper from './FieldWrapper'

class ExternalSourceChecklist extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      dataList: [],
      isLoaded: false
    }
  }

  componentDidMount () {
    if (!this.props.field.ReadOnlyField) {
      this.fetchData()
    }
  }

  componentDidUpdate (prevProps) {
    const { ReadOnlyField } = this.props.field

    if (prevProps.field.ReadOnlyField && !ReadOnlyField) {
      this.fetchData()
    }

    if (prevProps.filter !== this.props.filter && !ReadOnlyField) {
      this.fetchData()
    }
  }

  handleCheckChange (id, description, isChecked) {
    const { field, onChange } = this.props

    let ids = (field.ValueID) ? field.ValueID.split(',').filter(Boolean) : []
    let descriptions = (field.Value) ? field.Value.split(',').filter(Boolean) : []

    descriptions = descriptions.map((item) => (item.trim()))

    if (isChecked) {
      ids = ids.concat([id])
      descriptions = descriptions.concat([description])
    } else {
      const indexOfID = ids.indexOf(id.toString())
      if (indexOfID >= 0) ids.splice(indexOfID, 1)

      const indexOfDescription = descriptions.indexOf(description)
      if (indexOfDescription >= 0) descriptions.splice(indexOfDescription, 1)
    }

    field.ValueID = ids.toString()
    field.Value = descriptions.toString()

    if (onChange) { onChange(field) }
  }

  fetchData () {
    const { field, publicFormToken, filter, externalSourceEndpoint, afterDataLoad } = this.props
    const FieldID = field.ProcessTemplateFieldID || field.ID // grab the correct ID field depending on if it's a template or instance
    const ProcessID = field.ProcessTemplateID
    this.setState({ isLoaded: false })

    let endpoint = ''

    if (externalSourceEndpoint) {
      endpoint = externalSourceEndpoint
    } else {
      endpoint = (publicFormToken) ? `public/publicform/field/${FieldID}/option/list` : `processtemplate/${ProcessID}/field/${FieldID}/externaloption/list`
    }
    const headers = (publicFormToken) ? { token: publicFormToken } : {}

    if (filter) {
      if (filter.charAt(0) === '&') {
        endpoint += `?${filter.substr(1, filter.length - 1)}`
      }
    }

    this.props.dispatch(get(endpoint,
      {
        onSuccess: (response) => {
          let processTemplateFieldOptionList = response.ProcessTemplateFieldOptionList || []
          this.setState({
            dataList: processTemplateFieldOptionList,
            isLoaded: true
          }, () => {
            if (afterDataLoad) {
              afterDataLoad()
            }
          })
        },
        headers
      }
    ))
  }

  render () {
    const { field, wrapperProps, actionButton } = this.props
    const { dataList } = this.state

    const checkedValuesArray = (field.ValueID) ? field.ValueID.split(',') : []

    return (
      <FieldWrapper
        label={FormHelper.decodeHTML(field.FieldName)}
        required={field.RequiredField}
        showDivider
        {...wrapperProps}
      >
        {(field.ReadOnlyField)
          ? <div style={{ position: 'relative', padding: '5px 0px', fontSize: '15px' }}>
            {field.Value}
          </div>
          : <div style={{ display: 'flex', flexDirection: 'column' }}>
            {(dataList) ? dataList.map((checkbox, index) => (<FormControlLabel
              key={checkbox.ID}
              control={<Checkbox
                style={{ padding: '2px' }}
                checked={(checkedValuesArray.indexOf(checkbox.ID.toString()) >= 0)}
                color='primary'
                disabled={(field.ReadOnlyField)}
                onChange={(e, isChecked) => {
                  this.handleCheckChange(checkbox.ID, checkbox.OptionDescription, isChecked)
                }}
              />}
              label={checkbox.OptionDescription}
            />)) : null}
            {(actionButton && field.CanAddProcessInstanceLookup)
              ? <Button
                onClick={actionButton}
                color='primary'
                variant='contained'
                style={{
                  right: '4px',
                  width: '24px',
                  minWidth: '48px',
                  margin: '10px'
                }}
              >
                <Add />
              </Button>
              : null}
          </div>
        }
      </FieldWrapper>
    )
  }
}

ExternalSourceChecklist.propTypes = {
  field: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  actionButton: PropTypes.func,
  afterDataLoad: PropTypes.func,
  publicFormToken: PropTypes.string,
  filter: PropTypes.string,
  externalSourceEndpoint: PropTypes.string,
  wrapperProps: PropTypes.object
}

ExternalSourceChecklist.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default ExternalSourceChecklist
