export const UPDATE_TITLE_REQUEST = 'UPDATE_TITLE_REQUEST'
export const ENABLE_FULLSCREEN = 'ENABLE_FULLSCREEN'
export const DISABLE_FULLSCREEN = 'DISABLE_FULLSCREEN'
export const UPDATE_SCREEN_WIDTH = 'UPDATE_SCREEN_WIDTH'
export const UPDATE_SCROLLBAR_WIDTH = 'UPDATE_SCROLLBAR_WIDTH'
export const RIGHT_PANEL_OPEN = 'RIGHT_PANEL_OPEN'
export const RIGHT_PANEL_CLOSED = 'RIGHT_PANEL_CLOSED'
export const SET_MAIN_CONTENT_WIDTH = 'SET_MAIN_CONTENT_WIDTH'
export const LOG_LAST_ACTIVITY = 'LOG_LAST_ACTIVITY'
export const SET_RIGHT_PANEL_WIDTH = 'SET_RIGHT_PANEL_WIDTH'
export const UPDATE_SCREEN_HEIGHT = 'UPDATE_SCREEN_HEIGHT'
export const SET_API_SERVER = 'SET_API_SERVER'
export const ENABLE_DEBUG = 'ENABLE_DEBUG'
export const DISABLE_DEBUG = 'DISABLE_DEBUG'
export const SET_MAIN_APP_REF = 'SET_MAIN_APP_REF'
export const SET_RIGHT_PANEL_REF = 'SET_RIGHT_PANEL_REF'
