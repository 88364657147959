import React from 'react'
import { IndexRedirect, Route } from 'react-router'
import App from './containers/App'
import {
  AccountInformation, APIEndpoints, AuditTrail, CustomHTML, ExternalDataSources, FileRedirect, Handshake,
  Invoices, LoginView, MilestoneReport, MyTasksView, NotFound, PlanInformation, ProcessesInProgress, ProcessHistory,
  ProcessTemplate, ProcessTemplatesView, ProcessVisualProgress, PublicForm, Reports, ResetPassword, Response,
  ScheduledProcesses, StartProcessView, Statistics, UserGroups, Users, ProcessInstancesGrid, Relationships,
  PublicTask, ProcessLibrary, PublicProgress, DashboardGroups, ProcessPeople
} from './views'
import { requireAuthentication } from './components/Authentication/RequireAuth'
import { requireNoAuthentication } from './components/Authentication/RequireNoAuth'

export default function routes (store) {
  return (
    <Route component={App}>
      <IndexRedirect to='/(process/:processid/task/:taskid)' />
      <Route path='/(process/:processid/task/:taskid)' component={requireAuthentication(MyTasksView)} />
      <Route path='login' component={requireNoAuthentication(LoginView)} />
      <Route path='reset-password' component={requireNoAuthentication(ResetPassword)} />
      <Route path='mytasks(/:filter)' component={requireAuthentication(MyTasksView)} />
      <Route path='process-templates' component={requireAuthentication(ProcessTemplatesView)} />
      <Route path='process-library' component={requireAuthentication(ProcessLibrary)} />
      <Route path='start-process/:processid' component={requireAuthentication(StartProcessView)} />
      <Route path='processes-in-progress' component={requireAuthentication(ProcessesInProgress)} />
      <Route path='reports' component={requireAuthentication(Reports)} />
      <Route path='process-visual-progress/:processid' component={requireAuthentication(ProcessVisualProgress)} />
      <Route path='process-template(/:templateid)' component={requireAuthentication(ProcessTemplate)} />
      <Route path='relationships(/:templateid)(/:instanceid)' component={requireAuthentication(Relationships)} />
      <Route path='process-history(/:processid)' component={requireAuthentication(ProcessHistory)} />
      <Route path='process-instances-grid(/:templateId)' component={requireAuthentication(ProcessInstancesGrid)} />
      <Route path='users' component={requireAuthentication(Users)} />
      <Route path='user-groups' component={requireAuthentication(UserGroups)} />
      <Route path='dashboard-groups' component={requireAuthentication(DashboardGroups)} />
      <Route path='account' component={requireAuthentication(AccountInformation)} />
      <Route path='plan' component={requireAuthentication(PlanInformation)} />
      <Route path='file-redirect' component={requireAuthentication(FileRedirect)} />
      <Route path='api-endpoints' component={requireAuthentication(APIEndpoints)} />
      <Route path='milestone-report' component={MilestoneReport} />
      <Route path='custom-html' component={CustomHTML} />
      <Route path='audit-trail' component={requireAuthentication(AuditTrail)} />
      <Route path='invoices' component={requireAuthentication(Invoices)} />
      <Route path='statistics' component={requireAuthentication(Statistics)} />
      <Route path='scheduled-processes' component={requireAuthentication(ScheduledProcesses)} />
      <Route path='external-datasources/:type' component={requireAuthentication(ExternalDataSources)} />
      <Route path='response' component={Response} />
      <Route path='public-form' component={PublicForm} />
      <Route path='progress-report' component={PublicProgress} />
      <Route path='public-task/:taskId' component={PublicTask} />
      <Route path='process-people' component={requireAuthentication(ProcessPeople)} />
      <Route path='handshake' component={Handshake} />
      <Route path='*' component={NotFound} />
    </Route>
  )
}
