import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get } from '../../actions/base'
import { push } from 'react-router-redux'
import DataList from '../Layout/DataList'
import RightPanelContent from '../Layout/RightPanelContent'
import NotificationItem from './NotificationItem'
import objectAssign from 'object-assign'
import { GeneratedIcon, Icon } from '../Icon'

class NotificationPanel extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      isLoaded: false,
      notificationList: [],
      forceDataRefresh: 0
    }
  }

  dataLoadCallback (data) {
    this.setState({
      notificationList: data
    })
  }

  render () {
    const { language } = this.context
    const { notificationList, forceDataRefresh } = this.state
    const { user, push, location, rightPanelRef } = this.props

    return (
      <RightPanelContent
        title={language.translate('application.notifications')}
        closePanel={() => this.props.close()}
      >
        <div style={{ padding: '15px' }}>
          <DataList
            url={`user/${user.userid}/notification/list`}
            dataCallback={this.dataLoadCallback.bind(this)}
            responseProperty='NotificationList'
            noDataText={language.translate('application.noNotifications')}
            disableMasonry
            channelName={`Private-${user.accountID}-User-${user.userID}`}
            events={['NotificationCreated', 'NotificationViewed', 'NotificationRead', 'NotificationArchived']}
            containerElement={rightPanelRef}
            disableSearch
            forceDataRefresh={forceDataRefresh}
            location={location}
            hideFilterChips
          >
            {notificationList.map((notification, index) => {
              switch (notification.NotificationType) {
                case 'NewTaskAssignment':
                  return (
                    <NotificationItem
                      key={notification.ID}
                      leftAvatar={(notification.ToProcessTemplateIconName)
                        ? <Icon icon={notification.ToProcessTemplateIconName} size={40} />
                        : <GeneratedIcon
                          text={notification.ToProcessTemplateTitle}
                          randomizer={notification.ToProcessInstanceID}
                        />}
                      subject={
                        <div>
                          {language.translate('application.newTaskAssignmentInProcess')}
                          <strong> {notification.ToProcessTemplateTitle}</strong>
                        </div>
                      }
                      onClick={() => {
                        push({
                          pathname: location.pathname,
                          query: objectAssign(
                            {},
                            location.query,
                            {
                              ptype: 'task',
                              task: notification.ToProcessInstanceTaskID,
                              pid: notification.ToProcessInstanceTaskID
                            })
                        })
                      }}
                      date={notification.CreatedDate_Local}
                      hasViewed={!!(notification.CurrentUser_ViewedDate_UTC)}
                    />
                  )

                case 'NewFeature':
                  return (
                    <NotificationItem
                      key={notification.ID}
                      leftAvatar={<Icon icon='PP' size={40} />}
                      subject={notification.Subject || language.translate('application.newFeature')}
                      body={notification.Body}
                      onClick={() => {
                        if (notification.TargetURL) { window.open(notification.TargetURL, '_blank') }
                      }}
                      date={notification.CreatedDate_Local}
                      hasViewed={!!(notification.CurrentUser_ViewedDate_UTC)}
                    />
                  )

                case 'ProcessTemplateImported':
                  return (
                    <NotificationItem
                      key={notification.ID}
                      leftAvatar={<Icon icon='commerce-12' size={40} />}
                      subject={notification.Subject}
                      body={notification.Body}
                      onClick={() => {
                        const pushTo = new window.URL(notification.TargetURL)
                        push(pushTo.pathname)
                      }}
                      date={notification.CreatedDate_Local}
                      hasViewed={!!(notification.CurrentUser_ViewedDate_UTC)}
                    />
                  )

                default:
                  return (
                    <NotificationItem
                      key={notification.ID}
                      leftAvatar={<Icon icon='PP' size={40} />}
                      subject={notification.Subject}
                      body={notification.Body}
                      onClick={() => {
                        if (notification.TargetURL) {
                          const pushTo = new window.URL(notification.TargetURL)
                          push(pushTo.pathname)
                        }
                      }}
                      date={notification.CreatedDate_Local}
                      hasViewed={!!(notification.CurrentUser_ViewedDate_UTC)}
                    />
                  )
              }
            })}
          </DataList>
        </div>
      </RightPanelContent>
    )
  }
}

NotificationPanel.propTypes = {
  dispatch: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  user: PropTypes.object,
  push: PropTypes.func.isRequired,
  location: PropTypes.object,
  rightPanelRef: PropTypes.object
}

NotificationPanel.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

const mapStateToProps = state => ({
  user: state.auth,
  rightPanelRef: state.application.rightPanelRef
})

const mapDispatchToProps = dispatch => ({
  dispatch,
  get: bindActionCreators(get, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationPanel)
