import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Add } from '@material-ui/icons'
import grey from '@material-ui/core/colors/grey'
import { IconButton, Menu, MenuItem } from '@material-ui/core'

class AddIcon extends React.Component {
  constructor (props) {
    super(props)

    this.state = ({
      menuOpen: false,
      mouseAway: true,
      menuAnchor: null
    })
  }

  getMarginBottom () {
    return (this.props.snackbar.isOpen) ? '32px' : '0px'
  }

  // possible future use to allow menu to open when hovered, currently experiencing side effects
  mouseEnter () {
    this.setState({ menuOpen: true, mouseAway: false })
  }

  mouseLeave () {
    this.setState({ mouseAway: true })

    setTimeout(() => {
      if (this.state.mouseAway) { this.setState({ menuOpen: false }) }
    }, 400)
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { menuItems, isRightPanelOpen, rightPanelWidth } = this.props
    const { menuAnchor } = this.state

    return (
      <div>
        <IconButton
          className='bottom-right-action'
          style={{
            height: '60px',
            width: '60px',
            textAlign: 'center',
            backgroundColor: palette.errorColor,
            cursor: 'pointer',
            marginBottom: this.getMarginBottom(),
            marginRight: (isRightPanelOpen) ? `${rightPanelWidth}px` : '0px',
            borderRadius: '30px',
            boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
            position: 'fixed',
            zIndex: '1199'
          }}
          onClick={(e) => { this.setState({ menuAnchor: e.currentTarget }) }}>
          <Add nativeColor={grey[50]} style={{
            height: '34px',
            width: '34px'
          }} />
        </IconButton>
        <Menu
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClose={() => { this.setState({ menuAnchor: null }) }}
          getContentAnchorEl={null}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {menuItems.map((item, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                this.setState({ menuAnchor: null })
                item.onClick()
              }}
            >
              {item.text}
            </MenuItem>
          ))}
        </Menu>
      </div>
    )
  }
}

AddIcon.propTypes = {
  snackbar: PropTypes.object.isRequired,
  menuItems: PropTypes.array,
  isRightPanelOpen: PropTypes.bool.isRequired,
  rightPanelWidth: PropTypes.number
}

AddIcon.contextTypes = {
  muiTheme: PropTypes.object,
  language: PropTypes.object
}

const mapStateToProps = state => ({
  snackbar: state.snackbar,
  isRightPanelOpen: state.application.rightPanelOpen,
  rightPanelWidth: state.application.rightPanelWidth
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(AddIcon)
