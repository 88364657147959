import PropTypes from 'prop-types'
import React from 'react'
import { SketchPicker } from 'react-color'
import { Popover } from '@material-ui/core'

class ColorPicker extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      color: this.parseColor(props.color),
      showPicker: false,
      anchor: {}
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.color !== this.props.color) { this.setState({ color: this.parseColor(this.props.color) }) }
  }

  parseColor (color) {
    if (!color) { return null }

    if (color.substr(0, 3).toLowerCase() === 'rgb') { return this.parseRGBColorToObject(color) }

    if (color.substr(0, 1) === '#') { return this.parseHexColorToObject(color) }
  }

  parseHexColorToObject (color) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
    color = color.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)

    const rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color)
    
    if (!rgb) {
      return null
    }

    return ({ r: parseInt(rgb[1], 16), g: parseInt(rgb[2], 16), b: parseInt(rgb[3], 16), a: '1' })
  }

  parseRGBColorToObject (color) {
    const rgb = color.replace(/[^\d,.]/g, '').split(',')

    return ({ r: rgb[0], g: rgb[1], b: rgb[2], a: (rgb[3]) ? rgb[3] : '1' })
  }

  setColor () {
    const { onChange, requireHex } = this.props
    const { color } = this.state

    if (color) {
      onChange((requireHex) ? this.rgbaToHex(color.r, color.g, color.b, color.a) : `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`)
    } else {
      onChange('')
    }
  }

  componentToHex (c) {
    const hex = c.toString(16)
    return hex.length === 1 ? '0' + hex : hex
  }

  rgbaToHex (r, g, b, a) {
    return `#${this.componentToHex(r)}${this.componentToHex(g)}${this.componentToHex(b)}`
  }

  render () {
    const { label, requireHex } = this.props
    const { color, showPicker, anchor } = this.state
    const { muiTheme } = this.context

    const styles = {
      color: {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
        border: `1px solid ${muiTheme.palette.borderColor}`,
        background: (color) ? `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})` : `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 10 10'><path d='M-1 0 L9 10 L11 10 L1 0' fill='red' /></svg>")`
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer'
      }
    }

    return (
      <div style={{ margin: '10px 0px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className='field-label' style={{ display: (!label) ? 'none' : 'inherit' }}>{label}</div>
          <div
            style={styles.swatch}
            onClick={event => this.setState({ showPicker: true, anchor: event.currentTarget })}
          >
            <div
              style={styles.color}
            />
          </div>
        </div>

        <Popover
          open={showPicker}
          anchorEl={anchor}
          anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
          transformOrigin={{ horizontal: 'right', vertical: 'center' }}
          onClose={() => this.setState({ showPicker: false }, () => this.setColor())}
        >
          <SketchPicker
            color={color || 'transparent'}
            onChange={color => this.setState({ color: color.rgb })}
            disableAlpha={!!(requireHex)}
          />
        </Popover>
      </div>
    )
  }
}

ColorPicker.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
  onChange: PropTypes.func,
  requireHex: PropTypes.bool
}

ColorPicker.contextTypes = {
  muiTheme: PropTypes.object
}

export default ColorPicker
