import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateTitle } from '../actions/application'
import DataList from '../components/Layout/DataList'
import { push } from 'react-router-redux'
import { post, get } from '../actions/base'
import { showSnackbar } from '../actions/snackbar'
import objectAssign from 'object-assign'
import ProcessInfoCard from '../components/Process/ProcessInfoCard'
import ImpromptuTaskDialog from '../components/Process/ImpromptuTaskDialog'

class ProcessesInProgress extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      processes: [],
      impromptuTaskProcessInstanceId: null,
      taskList: [],
      taskListLoaded: false
    }
  }

  componentDidMount () {
    this.updateTitle()
  }

  componentDidUpdate () {
    this.updateTitle()
  }

  updateTitle () {
    const { language } = this.context

    this.props.dispatch(updateTitle(language.translate('application.processesInProgress')))
  }

  dataLoadCallback (data) {
    this.setState({
      processes: data
    })
  }

  createImpromptuTask (task, afterTemplateTaskId, errorCallback) {
    const { post, showSnackbar, push, location } = this.props
    const { language } = this.context

    task.ProcessInstanceID = this.state.impromptuTaskProcessInstanceId

    const body = JSON.stringify({
      ProcessInstanceTask: task,
      POSTOptions: {
        ProcessTemplateTaskID: afterTemplateTaskId
      }
    })

    post(`processinstance/${task.ProcessInstanceID}/task`, body, {
      onSuccess: (response) => {
        this.setState({ impromptuTaskProcessInstanceId: null })
        showSnackbar({
          message: language.translate('application.impromptuTaskCreated'),
          action: language.translate('application.openThisTask'),
          actionEvent: () => {
            push({
              pathname: location.pathname,
              query: objectAssign(
                {},
                location.query,
                {
                  ptype: 'task',
                  task: response.ProcessInstanceTask.ID,
                  pid: response.ProcessInstanceTask.ID
                })
            })
          }
        })
      },
      onError: errorCallback
    })
  }

  getTemplateTasks (templateId) {
    this.props.get(`processtemplate/${templateId}/task/list`, {
      onSuccess: (response) => {
        this.setState({
          taskList: response.ProcessTemplateTaskList,
          taskListLoaded: true
        })
      }
    })
  }

  getPendingTask (instanceId, callback) {
    const { user, get } = this.props

    get(`processinstance/${instanceId}/task/pending/assignedtouser/${user.userid}/ifpossible`, {
      onSuccess: (response) => {
        if (callback) {
          callback(response)
        }
      }
    })
  }

  render () {
    const { language } = this.context
    const { mainContentWidth, location } = this.props
    const { impromptuTaskProcessInstanceId, taskList, taskListLoaded } = this.state

    return (
      <DataList
        url={`user/${this.props.user.userid}/processinstance/list/pending`}
        dataCallback={this.dataLoadCallback.bind(this)}
        responseProperty='ProcessInstanceList'
        filterableColumns={[{
          name: language.translate('application.processTitle'),
          property: 'ProcessTemplateTitle'
        }, {
          name: language.translate('application.processField', [], true),
          property: 'HeaderFieldAll_Value'
        }]}
        noDataText={language.translate('application.noProcesses')}
        location={location}
      >
        {this.state.processes.map(process => (
          <div
            style={{ width: (mainContentWidth < 900) ? '100%' : '50%' }}
            key={process.ID + Math.random()}
          >
            <ProcessInfoCard
              key={process.ID + Math.random()}
              process={process}
              onClick={processId => this.props.push(`/process-visual-progress/${processId}`)}
              onViewProgressClick={processId => this.props.push(`/process-visual-progress/${processId}`)}
              onAddImpromptuTaskClick={() => {
                this.getTemplateTasks(process.ProcessTemplateID)
                this.setState({ impromptuTaskProcessInstanceId: process.ID })
              }}
              onPendingTaskClick={(process) => {
                this.getPendingTask(process.ID, (response) => {
                  let taskId = response.ProcessInstanceTask.ID

                  const query = objectAssign({}, location.query, {
                    ptype: 'task',
                    task: taskId,
                    pid: taskId
                  })

                  this.props.push({
                    pathname: location.pathname,
                    query
                  })
                })
              }}
            />
          </div>
        ))}
        {(impromptuTaskProcessInstanceId && taskListLoaded)
          ? <ImpromptuTaskDialog
            onCancel={() => this.setState({ impromptuTaskProcessInstanceId: null })}
            onSubmit={this.createImpromptuTask.bind(this)}
            dispatch={this.props.dispatch}
            taskList={taskList}
          />
          : null}
      </DataList>
    )
  }
}

ProcessesInProgress.propTypes = {
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.string,
  user: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  mainContentWidth: PropTypes.number,
  location: PropTypes.object
}

ProcessesInProgress.contextTypes = {
  muiTheme: PropTypes.object,
  language: PropTypes.object
}

const mapStateToProps = (state, ownProps) => ({
  title: state.application.title,
  user: state.auth,
  mainContentWidth: state.application.mainContentWidth,
  location: ownProps.location
})

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  showSnackbar: bindActionCreators(showSnackbar, dispatch),
  post: bindActionCreators(post, dispatch),
  get: bindActionCreators(get, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ProcessesInProgress)
