import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateTitle, enableFullScreen, disableFullScreen } from '../actions/application'
import DataList from '../components/Layout/DataList'
import { push } from 'react-router-redux'
import { Base64 } from 'js-base64'
import { ProcessInfoCard } from '../components/Process'
import TaskList from '../components/Tasks/TaskList'
import { get, post } from '../actions/base'
import { showSnackbar } from '../actions/snackbar'
import objectAssign from 'object-assign'
import ProcessInfoTable from '../components/Process/ProcessInfoTable'
import { Button } from '@material-ui/core'
import { Check, HourglassEmpty, SelectAll, ViewHeadline, ViewModule, ViewWeek, Warning } from '@material-ui/icons'
import TagCloud from '../components/Tags/TagCloud'
import { ingestSearchFromUrl } from '../components/Layout/Search/Selectors'
import ImpromptuTaskDialog from '../components/Process/ImpromptuTaskDialog'
import RedGreenReportCard from './DashboardGroups/RedGreenReportCard'
import CountCard from '../components/Tasks/CountCard'

class Reports extends React.Component {
  constructor (props) {

    super(props);

    var refreshIntervalId = 0;

    this.state = {
      items: [],
      dataLoaded: false,
      tagList: [],
      templateList: [],
      impromptuTaskProcessInstanceId: null,
      forceDataRefresh: 0,
      taskList: [],
      taskListLoaded: false,
      fullApiResponse: {}
    }
  }

  componentDidMount () {
    this.updateTitle()

    if (this.props.location.query.tag_endpoint) {
      this.fetchTagList(this.props.location.query.tag_endpoint, 'TagList', 'tagList')
    }

    if (this.props.location.query.processtemplate_endpoint) {
      this.fetchTagList(this.props.location.query.processtemplate_endpoint, 'ProcessTemplateList', 'templateList')
    }

    if (this.props.location.query.type === 'red-green') {
      this.props.enableFullScreen()
    }
  }

  componentDidUpdate (prevProps) {
    this.updateTitle()

    if (this.props.location.query.endpoint !== prevProps.location.query.endpoint) {
      this.setState({ items: [] })
    }

    if (this.props.location.query.tag_endpoint !== prevProps.location.query.tag_endpoint) {
      this.setState({ tagList: [] })

      if (this.props.location.query.tag_endpoint) {
        this.fetchTagList(this.props.location.query.tag_endpoint, 'TagList', 'tagList')
      }
    }

    if (this.props.location.query.processtemplate_endpoint !== prevProps.location.query.processtemplate_endpoint) {
      this.setState({ templateList: [] })

      if (this.props.location.query.processtemplate_endpoint) {
        this.fetchTagList(this.props.location.query.processtemplate_endpoint, 'ProcessTemplateList', 'templateList')
      }
    }

    if (JSON.stringify(this.props.searchItems) !== JSON.stringify(prevProps.searchItems)) {
      if (this.props.location.query.tag_endpoint) {
        this.fetchTagList(this.props.location.query.tag_endpoint, 'TagList', 'tagList')
      }

      if (this.props.location.query.processtemplate_endpoint) {
        this.fetchTagList(this.props.location.query.processtemplate_endpoint, 'ProcessTemplateList', 'templateList')
      }
    }

    if (this.props.location.query.type !== 'red-green' && prevProps.location.query.type === 'red-green') {
      this.props.disableFullScreen()
    }

    if (this.props.location.query.type === 'red-green' && prevProps.location.query.type !== 'red-green') {
      this.props.enableFullScreen()
    }

    //IF A TIMEOUT HAS BEEN SET THEN CLEAR IT SO WE CAN RESET IT AFTER EVERY UI UPDATE
    if (this.refreshIntervalId) {
      clearTimeout(this.refreshIntervalId);
    }
    
    //REFRESH DATA EVERY 10 MINUTES IF NO OTHER UPDATES HAVE BEEN TRIGGERED  
    this.refreshIntervalId = setTimeout(function() {this.setState({ forceDataRefresh: this.state.forceDataRefresh + 1 })}.bind(this), 600000);
   
  }

  componentWillUnmount () {
    this.props.disableFullScreen();
    clearTimeout(this.refreshIntervalId);
  }

  fetchTagList (endpoint, responseProperty, stateProperty) {
    const { get, location: { query }, searchItems } = this.props

    let url = endpoint

    if (searchItems.length) {
      url += '?'
    }

    searchItems.map((filter, index) => {
      if (index > 0) { url += '&' }

      const filterIndex = index + 1
      const operator = filter.operator || '(CONTAINS_TEXT)'

      url += `filter${filterIndex}=${filter.property}${operator}${filter.value}`
    })

    get(url, {
      onSuccess: (response) => {
        this.setState({
          [stateProperty]: response[responseProperty]
        })
      }
    })
  }

  updateTitle () {
    const { language } = this.context
    const { updateTitle, location: { query } } = this.props

    let title = language.translate('application.reports')

    if (query.title) {
      title = query.title
    }

    updateTitle(title)
  }

  dataLoadCallback (data, apiResponse) {
    this.setState({
      items: data,
      dataLoaded: true,
      fullApiResponse: apiResponse
    })
  }

  filterableColumns () {
    const { type, endpoint } = this.props.location.query
    const { language } = this.context

    switch (type) {
      case 'task':
        return [{
          name: language.translate('application.processTitle'),
          property: 'ProcessTemplateTitle'
        }, {
          name: language.translate('application.taskTitle'),
          property: 'TaskText_Display'
        }, {
          name: language.translate('application.processField', [], true),
          property: 'ProcessInstance_HeaderFieldAll_Value'
        }, {
          name: `${language.translate('application.assignedTo')} - ${language.translate('application.user', [], false)}`,
          property: 'AssignedTo_User_FullName'
        }, {
          name: `${language.translate('application.assignedTo')} - ${language.translate('application.group')}`,
          property: 'AssignedTo_UserGroup_Name'
        }, {
          name: language.translate('application.processGroup'),
          property: 'ProcessTemplateGroupName'
        }, {
          name: language.translate('application.notes'),
          property: 'Notes'
        }]
      case 'process':
      case 'red-green':
        if (endpoint === 'processinstance/list/pending') {
          return [{
            name: language.translate('application.processField', [], true),
            property: 'HeaderFieldAll_Value'
          }, {
            name: language.translate('application.processTitle'),
            property: 'ProcessTemplateTitle'
          }, {
            name: language.translate('application.oldestPendingTaskOwner'),
            property: 'OldestPendingTask_AssignedTo_Name'
          }]
        } else {
          return [{
            name: language.translate('application.processField', [], true),
            property: 'HeaderFieldAll_Value'
          }, {
            name: language.translate('application.processTitle'),
            property: 'ProcessTemplateTitle'
          }]
        }
      default:
        return []
    }
  }

  handleTagClicked (value, property, displayText) {

    const { push, location } = this.props
    let { location: { query: { search = [] } } } = this.props

    const newSearchItem = Base64.encodeURI(JSON.stringify({
      property,
      value,
      displayText
    }))

    if (!Array.isArray(search)) {
      search = [search]
    }

    // dedupe search
    if (search.includes(newSearchItem)) {
      return
    }

    search.push(newSearchItem)

    push({
      pathname: location.pathname,
      query: objectAssign({}, location.query, { search })
    })
  }

  getTagNamesUsed (property) {
    const { searchItems } = this.props

    return searchItems.map((item) => { if (item.property === property) { return item.value } })
  }

  createImpromptuTask (task, afterTemplateTaskId, errorCallback) {
    const { post, showSnackbar, push, location } = this.props
    const { language } = this.context

    task.ProcessInstanceID = this.state.impromptuTaskProcessInstanceId

    const body = JSON.stringify({
      ProcessInstanceTask: task,
      POSTOptions: {
        ProcessTemplateTaskID: afterTemplateTaskId
      }
    })

    post(`processinstance/${task.ProcessInstanceID}/task`, body, {
      onSuccess: (response) => {
        this.setState({ impromptuTaskProcessInstanceId: null })
        showSnackbar({
          message: language.translate('application.impromptuTaskCreated'),
          action: language.translate('application.openThisTask'),
          actionEvent: () => {
            push({
              pathname: location.pathname,
              query: objectAssign(
                {},
                location.query,
                {
                  ptype: 'task',
                  task: response.ProcessInstanceTask.ID,
                  pid: response.ProcessInstanceTask.ID
                })
            })
          }
        })
      },
      onError: errorCallback
    })
  }

  cancelProcess (instanceId) {
    const { post } = this.props

    const body = JSON.stringify({})

    post(`processinstance/${instanceId}/cancel`, body, {
      onSuccess: (response) => {
        this.setState({ forceDataRefresh: this.state.forceDataRefresh + 1 })
      }
    })
  }

  getTemplateTasks (templateId) {
    this.props.get(`processtemplate/${templateId}/task/list`, {
      onSuccess: (response) => {
        this.setState({
          taskList: response.ProcessTemplateTaskList,
          taskListLoaded: true
        })
      }
    })
  }

  getPendingTask (instanceId, callback) {
    const { user, get } = this.props

    get(`processinstance/${instanceId}/task/pending/assignedtouser/${user.userid}/ifpossible`, {
      onSuccess: (response) => {
        if (callback) {
          callback(response)
        }
      }
    })
  }

  getCountCardSize () {
    const { mainContentWidth } = this.props

    if (mainContentWidth > 944) { return 'large' }
    if (mainContentWidth > 420) { return 'medium' }

    return 'small'
  }

  render () {
    const { language, muiTheme: { palette } } = this.context
    const { mainContentWidth, user, push, location, location: { query } } = this.props
    const view = query.view
    const { items, tagList, impromptuTaskProcessInstanceId, forceDataRefresh, templateList, taskList, taskListLoaded, fullApiResponse, dataLoaded } = this.state
    const tagNamesUsed = this.getTagNamesUsed('TagList')
    const tagsToDisplay = tagList.filter((tag) => !(tagNamesUsed.includes(tag.TagName)))
    const templatesUsed = this.getTagNamesUsed('ProcessTemplateTitle')
    const templatesToDisplay = templateList.filter((template) => !(templatesUsed.includes(template.Title)))

    let responseProperty = 'ProcessInstanceList'

    if (query.type === 'task') {
      responseProperty = 'ProcessInstanceTaskList'
    }

    let countCardSize = this.getCountCardSize()

    return (
      <div>
        {(query.type === 'process')
          ? <div style={{ marginBottom: '5px' }}>
            <Button
              style={{ minWidth: '48px', margin: '5px 0px 5px 5px', backgroundColor: palette.canvasColor }}
              disabled={(!query.view)}
              variant='contained'
              onClick={() => {
                delete query.view

                push({
                  pathname: location.pathname,
                  query
                })
              }}
            >
              <ViewModule />
            </Button>
            <Button
              style={{ minWidth: '48px', margin: '5px 5px 5px 0px', backgroundColor: palette.canvasColor }}
              disabled={!!(query.view)}
              variant='contained'
              onClick={() => {
                const query = objectAssign({}, location.query, {
                  view: 'table'
                })

                push({
                  pathname: location.pathname,
                  query
                })
              }}
            >
              <ViewHeadline />
            </Button>
          </div>
          : null}
        {(query.type === 'red-green' && <Button
          style={{ minWidth: '48px', margin: '5px 5px 5px 20px', backgroundColor: palette.canvasColor }}
          variant='contained'
          onClick={() => {
            let endpoint = location.query.endpoint
            let groupId = endpoint.split('/')[1]
            const query = objectAssign({}, location.query, {
              ptype: 'dashboard-group',
              pid: groupId,
              gsid: groupId
            })

            push({
              pathname: location.pathname,
              query
            })
          }}
        >
          {language.translate('application.edit')}
        </Button>)}
        {(query.type === 'red-green' && dataLoaded &&
          <div style={{ padding: '0 25px', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <CountCard
              count={fullApiResponse.DashboardGroup.InstancePastDueCount}
              color={palette.errorColor}
              accentColor={palette.errorAccentColor}
              title={language.translate('application.pastDue')}
              size={countCardSize}
              icon={<Warning
                nativeColor={palette.canvasColor}
                style={{
                  width: (countCardSize === 'small') ? '25px' : '60px',
                  height: (countCardSize === 'small') ? '25px' : '58px',
                  marginTop: '5px'
                }}
              />}
            />
            <CountCard
              count={fullApiResponse.DashboardGroup.InstanceWarningCount}
              color={palette.warningColor}
              accentColor={palette.warningAccentColor}
              title={language.translate('application.warning')}
              size={countCardSize}
              icon={<ViewWeek
                nativeColor={palette.canvasColor}
                style={{
                  width: (countCardSize === 'small') ? '25px' : '60px',
                  height: (countCardSize === 'small') ? '25px' : '58px',
                  marginTop: '5px'
                }}
              />}
            />
            <CountCard
              count={fullApiResponse.DashboardGroup.InstanceCompletedCount}
              color={palette.successColor}
              accentColor={palette.successAccentColor}
              title={language.translate('application.completed')}
              size={countCardSize}
              icon={<Check
                nativeColor={palette.canvasColor}
                style={{
                  width: (countCardSize === 'small') ? '25px' : '60px',
                  height: (countCardSize === 'small') ? '25px' : '58px',
                  marginTop: '5px'
                }}
              />}
            />
            <CountCard
              count={fullApiResponse.DashboardGroup.InstanceTotalCount}
              color={palette.primary1Color}
              accentColor={palette.accent1Color}
              title={language.translate('application.total')}
              size={countCardSize}
              icon={<SelectAll
                nativeColor={palette.canvasColor}
                style={{
                  width: (countCardSize === 'small') ? '25px' : '60px',
                  height: (countCardSize === 'small') ? '25px' : '58px',
                  marginTop: '5px'
                }}
              />}
            />
          </div>)}
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          {(query.subtitle)
            ? <div style={{ paddingLeft: '10px', fontSize: '18px', fontWeight: 600 }}>
              {query.subtitle}
            </div>
            : null}
          {(query.subtitleSecondary)
            ? <div style={{ paddingLeft: '10px', fontSize: '16px', color: palette.accent4Color }}>
              {(query.subtitleSecondaryLink)
                ? <a
                  style={{ cursor: 'pointer' }}
                  onClick={() => { push(query.subtitleSecondaryLink) }}>{query.subtitleSecondary}</a>
                : query.subtitleSecondary}
            </div>
            : null}
        </div>
        <div style={{ display: 'flex' }}>
          <DataList
            url={query.endpoint}
            dataCallback={this.dataLoadCallback.bind(this)}
            responseProperty={responseProperty}
            filterableColumns={this.filterableColumns()}
            noDataText={language.translate('application.nothingToShow')}
            location={location}
            containerStyle={{ width: '100%' }}
            forceDataRefresh={forceDataRefresh}
            disableMasonry={(query.type === 'red-green')}
            pageSize={(query.type === 'red-green') ? 100 : null}
            channelName={(query.type === 'red-green') ? `Private-${user.accountID}-${query.channel}` : null}
            events={(query.type === 'red-green') ? ['DashboardGroupCreated', 'DashboardGroupUpdated', 'DashboardGroupDeleted', 'InstanceUpdated'] : null}
          >
            {(query.type === 'process')
              ? (view === 'table')
                ? <ProcessInfoTable
                  processes={items}
                  onProcessClick={process => push(`/process-visual-progress/${process.ID}`)}
                  showLastProgress={(mainContentWidth > 900)}
                  showWaitingOn={(mainContentWidth > 600)}
                  viewProgressClick={(process) => push(`/process-visual-progress/${process.ID}`)}
                  cancelProcessClick={(process) => this.cancelProcess(process.ID)}
                  viewRelationshipsClick={(process) => push(`/relationships/${process.ProcessTemplateID}/${process.ID}`)}
                  oldestPendingTaskClick={(process) => {
                    const query = objectAssign({}, location.query, {
                      ptype: 'task',
                      task: process.OldestPendingTask_ID,
                      pid: process.OldestPendingTask_ID
                    })

                    push({
                      pathname: location.pathname,
                      query
                    })
                  }}
                  addImpromptuTaskClick={(process) => {
                    this.getTemplateTasks(process.ProcessTemplateID)
                    this.setState({ impromptuTaskProcessInstanceId: process.ID })
                  }}
                />
                : items.map(item => (
                  <div
                    style={{ width: (mainContentWidth < 900) ? '100%' : '50%' }}
                    key={item.ID + Math.random()}
                  >
                    <ProcessInfoCard
                      process={item}
                      onClick={processId => this.props.push(`/process-visual-progress/${processId}`)}
                      onViewProgressClick={processId => this.props.push(`/process-visual-progress/${processId}`)}
                      onAddImpromptuTaskClick={() => {
                        this.getTemplateTasks(item.ProcessTemplateID)
                        this.setState({ impromptuTaskProcessInstanceId: item.ID })
                      }}
                      onPendingTaskClick={(process) => {
                        this.getPendingTask(process.ID, (response) => {
                          let taskId = response.ProcessInstanceTask.ID

                          const query = objectAssign({}, location.query, {
                            ptype: 'task',
                            task: taskId,
                            pid: taskId
                          })

                          this.props.push({
                            pathname: location.pathname,
                            query
                          })
                        })
                      }}
                    />
                  </div>))
              : null}

            {(query.type === 'task')
              ? <TaskList tasks={this.state.items} location={location} showAssignedTo />
              : null}
            {(query.type === 'red-green' && dataLoaded &&
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly' }}>
                {items.map((item, index) => (
                  <div
                    style={{ width: '300px' }}
                    key={item.ID + Math.random()}
                  >
                    <RedGreenReportCard
                      process={item}
                      onClick={processId => this.props.push(`/process-visual-progress/${processId}`)}
                    />
                  </div>
                ))}
              </div>
            )}
          </DataList>
          <div style={{ maxWidth: '350px', display: 'flex', flexDirection: 'column' }}>
            <TagCloud
              tags={tagsToDisplay}
              title={language.translate('application.tags')}
              onTagClick={(tag) => {
                let tagProperty = (query.type === 'red-green') ? 'OldestPendingTask_TagList' : 'TagList'
                this.handleTagClicked(tag.TagName, tagProperty, 'Tags')
              }}
              containerStyle={{
                width: '300px',
                marginLeft: '20px',
                display: (tagsToDisplay.length && mainContentWidth > 1100) ? 'initial' : 'none'
              }}
            />
            <TagCloud
              tags={templatesToDisplay.map((template) => {
                template.TagName = template.Title
                return template
              })}
              onTagClick={(template) => { this.handleTagClicked(template.Title, 'ProcessTemplateTitle', 'Template') }}
              containerStyle={{
                width: '300px',
                marginLeft: '20px',
                display: (templatesToDisplay.length && mainContentWidth > 1100) ? 'initial' : 'none'
              }}
              title={language.translate('application.process', [], true)}
            />
          </div>
        </div>
        {(impromptuTaskProcessInstanceId && taskListLoaded)
          ? <ImpromptuTaskDialog
            onCancel={() => this.setState({ impromptuTaskProcessInstanceId: null })}
            onSubmit={this.createImpromptuTask.bind(this)}
            dispatch={this.props.dispatch}
            taskList={taskList}
          />
          : null}
      </div>
    )

  }
}

Reports.propTypes = {
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.string,
  push: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  mainContentWidth: PropTypes.number.isRequired,
  location: PropTypes.object,
  get: PropTypes.func,
  post: PropTypes.func,
  updateTitle: PropTypes.func,
  enableFullScreen: PropTypes.func,
  disableFullScreen: PropTypes.func,
  showSnackbar: PropTypes.func,
  searchItems: PropTypes.array
}

Reports.contextTypes = {
  muiTheme: PropTypes.object,
  language: PropTypes.object
}

const mapStateToProps = (state, ownProps) => ({
  title: state.application.title,
  mainContentWidth: state.application.mainContentWidth,
  user: state.auth,
  location: ownProps.location,
  searchItems: ingestSearchFromUrl(ownProps.location.query.search)
})

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  get: bindActionCreators(get, dispatch),
  post: bindActionCreators(post, dispatch),
  updateTitle: bindActionCreators(updateTitle, dispatch),
  enableFullScreen: bindActionCreators(enableFullScreen, dispatch),
  disableFullScreen: bindActionCreators(disableFullScreen, dispatch),
  showSnackbar: bindActionCreators(showSnackbar, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Reports)
