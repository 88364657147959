import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Button, Card, CardContent, CardHeader, Checkbox, CircularProgress, Dialog, DialogActions, DialogTitle, Divider,
  FormControlLabel, Switch, TextField, Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { MenuItem } from 'material-ui'
import { Delete, AddAPhoto } from '@material-ui/icons'
import { push } from 'react-router-redux'
import { Form } from 'formsy-react'
import { FormsySelect, FormsyText } from 'formsy-material-ui'
import CopyToClipboard from 'react-copy-to-clipboard'
import Masonry from 'react-masonry-component'
import { updateUserSession } from '../actions/authentication'
import { get, post } from '../actions/base'
import { updateTitle } from '../actions/application'
import FormHelper from '../businessLogic/formHelper'
import FormsyUpload from '../components/Forms/FormsyUpload'
import ColorPicker from '../components/Forms/ColorPicker'
import { showSnackbar } from '../actions/snackbar'
import HelpIcon from '../components/Layout/HelpIcon'
import DeleteIconButton from '../components/Layout/DeleteIconButton'

const masonryOptions = {
  transitionDuration: '0.0s'
}

const styles = theme => ({
  label: {
    fontSize: '16px',
    color: theme.palette.accent3Color
  }
})

class AccountInformation extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      account: [],
      canSubmit: true,
      isLoaded: false,
      ipWhitelistFieldValue: '',
      isValidIP: true,
      countries: [],
      countriesLoaded: false,
      language: [],
      languagesLoaded: false,
      showCancelAccountConfirmation: false,
      processesStartedThisMonth: 0
    }
  }

  componentDidMount () {
    const { language } = this.context

    this.props.dispatch(updateTitle(language.translate('application.accountInformation')))

    this.fetchAccountInformation()
    this.fetchThisMonthProcessCount()
    this.fetchCountries()
    this.fetchLanguages()
  }

  fetchAccountInformation () {
    this.props.get('account', {
      onSuccess: (response) => {
        this.setState({
          account: response.Account,
          isLoaded: true
        })
      }
    })
  }

  fetchThisMonthProcessCount () {
    this.props.get('processinstance/list/startedthismonth/count', {
      onSuccess: (response) => {
        this.setState({
          processesStartedThisMonth: response.Count
        })
      }
    })
  }

  fetchCountries () {
    this.props.get('country/list', {
      onSuccess: (response) => {
        this.setState({
          countries: response.TextValueList,
          countriesLoaded: true
        })
      }
    })
  }

  fetchLanguages () {
    this.props.get('language/list', {
      onSuccess: (response) => {
        this.setState({
          languagesLoaded: true,
          languages: response.TextValueList
        })
      }
    })
  }

  parseImageUpload (event, urlAttribute, filenameAttribute, endpoint) {
    const files = event.target.files
    let uploadedFile = {}
    const reader = new window.FileReader()
    const me = this

    reader.onload = (function (file) {
      return function (e) {
        uploadedFile = {
          FileName: file.name,
          Base64EncodedFile: e.target.result.split(',')[1],
          FileType: file.type
        }
        me.saveAccountLogo(uploadedFile, urlAttribute, filenameAttribute, endpoint)
      }
    })(files[0])

    reader.readAsDataURL(files[0])
  }

  saveAccountLogo (image, urlAttribute, filenameAttribute, endpoint) {
    const { updateUserSession } = this.props
    const body = JSON.stringify({
      AccountLogo: image
    })

    this.props.post(`account/logo/${endpoint}`, body, {
      onSuccess: (response) => {
        const { account } = this.state

        account[urlAttribute] = response.Account[urlAttribute]
        account[filenameAttribute] = response.Account[filenameAttribute]

        // update the logo stored in the users session and global state
        this.setState({ account }, () => {
          updateUserSession('accountImageLight', response.Account.HeaderLogoWhiteBG_URL)
          updateUserSession('accountImageDark', response.Account.HeaderLogoDarkBG_URL)
        })
      }
    })
  }

  deleteAccountLogo (isWhiteBG) {
    const { updateUserSession } = this.props
    const endpoint = (isWhiteBG) ? 'account/logo/whitebg/delete' : 'account/logo/darkbg/delete'

    const body = JSON.stringify({})

    this.props.post(endpoint, body, {
      onSuccess: (response) => {
        this.fetchAccountInformation()
        updateUserSession('accountImageLight', response.Account.HeaderLogoWhiteBG_URL)
        updateUserSession('accountImageDark', response.Account.HeaderLogoDarkBG_URL)
      }
    })
  }

  saveAccount () {
    const { canSubmit, account } = this.state

    if (!canSubmit) { return false }

    if (account.SMTP_Password && account.SMTP_Password.includes('•')) {
      delete account.SMTP_Password
    }

    const body = JSON.stringify({
      Account: account
    })

    this.props.post('account', body, {
      onSuccess: (response) => {
        this.setState({
          account: response.Account
        })
      }
    })
  }

  deleteAccount () {
    const { account } = this.state

    const body = JSON.stringify({
      Account: account
    })

    this.props.post('account/cancel', body, {
      onSuccess: (response) => {
        window.open('https://docs.google.com/forms/d/e/1FAIpQLScA5YH-1PoTnyGvbkNYvmo-v4w402671i1VoMxYyZu-wrJeBQ/viewform', '_self')
      }
    })
  }

  changePalette (attribute, color) {
    const { updateUserSession } = this.props
    const { account } = this.state
    const customizations = (account.Customizations) ? JSON.parse(account.Customizations) : {}

    customizations.theme = customizations.theme || {}
    customizations.theme.palette = customizations.theme.palette || {}

    customizations.theme.palette[attribute] = color

    account.Customizations = JSON.stringify(customizations)
    updateUserSession('customizations', customizations, true)
    // account.Customizations = '';

    this.setState({ account }, () => this.saveAccount())
  }

  resetPalette () {
    const { updateUserSession } = this.props
    const { account } = this.state
    const customizations = (account.Customizations) ? JSON.parse(account.Customizations) : {}

    customizations.theme = customizations.theme || {}
    customizations.theme.palette = {}

    account.Customizations = JSON.stringify(customizations)
    updateUserSession('customizations', customizations, true)

    this.setState({ account }, () => this.saveAccount())
  }

  testSMTP () {
    const body = JSON.stringify({})

    this.props.post('account/smtptest', body, {
      onSuccess: (response) => {
      }
    })
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { location, language, user } = this.context
    const {
      account, ipWhitelistFieldValue, isValidIP, countries, countriesLoaded, showCancelAccountConfirmation, processesStartedThisMonth, languages,
      languagesLoaded
    } = this.state
    const { mainContentWidth, push, showSnackbar, classes, apiServer } = this.props

    let server = apiServer.replace('api', '')

    const countryList = []

    if (countriesLoaded) {
      countries.map((country, index) => {
        countryList.push(<MenuItem value={country.Value} key={country.Value} primaryText={country.Text} />)
      })
    }

    const languageOptions = []

    if (languagesLoaded) {
      languages.map((language) => {
        languageOptions.push(<MenuItem
          value={language.Value}
          key={language.Value}
          primaryText={language.Text}
        />)
      })
    }

    let ipWhitelistArray = (account.IPWhitelist) ? account.IPWhitelist.split(',') : []

    return (
      <div>
        {(!this.state.isLoaded)
          ? <CircularProgress className='loader' />

          : <Form
            onValid={() => this.setState({ canSubmit: true, isValidIP: true })}
            onInvalid={() => this.setState({ canSubmit: false, isValidIP: false })}
          >
            <Masonry options={masonryOptions}>
              <Card
                className='card'
                style={{
                  width: (mainContentWidth > 1100) ? '40%' : '90%',
                  margin: '10px'
                }}
              >
                <CardHeader
                  title={language.translate('application.accountInformation')}
                  subheader={language.translate('application.processesStartedThisMonth', [processesStartedThisMonth])}
                />
                <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
                  <FormsyText
                    required
                    fullWidth
                    name='account_name'
                    floatingLabelText={language.translate('application.accountName')}
                    value={FormHelper.decodeHTML(account.AccountName)}
                    onBlur={(event) => {
                      account.AccountName = event.currentTarget.value
                      this.setState({ account }, this.saveAccount.bind(this))
                    }}
                    validationErrors={language.messages.validationErrors}
                  />
                  <FormsyText
                    name='website_url'
                    fullWidth
                    floatingLabelText={language.translate('application.websiteUrl')}
                    value={account.WebsiteURL}
                    onBlur={(event) => {
                      account.WebsiteURL = event.currentTarget.value
                      this.setState({ account }, this.saveAccount.bind(this))
                    }}
                    validationErrors={language.messages.validationErrors}
                  />
                  <FormsyText
                    name='vat'
                    fullWidth
                    floatingLabelText='VAT #'
                    value={account.VAT}
                    onBlur={(event) => {
                      account.VAT = event.currentTarget.value
                      this.setState({ account }, this.saveAccount.bind(this))
                    }}
                    validationErrors={language.messages.validationErrors}
                  />
                  <FormsySelect
                    name='date_format'
                    value={account.DefaultDateFormat || 'YYYY-MM-DD'}
                    onChange={(e, value) => {
                      account.DefaultDateFormat = value
                      this.setState({ account }, this.saveAccount.bind(this))
                    }}
                    floatingLabelText={language.translate('application.defaultDateFormat')}
                    validationErrors={language.messages.validationErrors}
                  >
                    {[
                      <MenuItem value='MM/DD/YYYY' key={1} primaryText='12/31/2016' />,
                      <MenuItem value='DD/MM/YYYY' key={2} primaryText='31/12/2016' />,
                      <MenuItem value='YYYY-MM-DD' key={3} primaryText='2016-12-31' />
                    ]}
                  </FormsySelect>
                  <FormsySelect
                    name='language'
                    value={account.DefaultLanguage}
                    onChange={(e, value) => {
                      account.DefaultLanguage = value
                      this.setState({ account }, this.saveAccount.bind(this))
                    }}
                    floatingLabelText={language.translate('application.defaultLanguage')}
                    validationErrors={language.messages.validationErrors}
                  >
                    {languageOptions}
                  </FormsySelect>
                  <FormControlLabel
                    control={<Switch
                      checked={account.OnDemandServicesEnabled}
                      color='primary'
                      onChange={(obj, value) => {
                        account.OnDemandServicesEnabled = !account.OnDemandServicesEnabled
                        this.setState({ account }, this.saveAccount.bind(this))
                      }}
                    />}
                    style={{ justifyContent: 'space-between', marginLeft: '0px' }}
                    label={<div>{language.translate('application.enableOnDemandServices')}<HelpIcon
                      helpUrl={'on-demand-services'} helpText={language.translate('application.learnMore')} /></div>}
                    labelPlacement='start'
                    classes={{ label: classes.label }}
                  />
                  <FormsyText
                    multiLine
                    rows={10}
                    fullWidth
                    name='description'
                    floatingLabelText={language.translate('application.companyDescription')}
                    value={account.AccountDescription}
                    onBlur={(event) => {
                      account.AccountDescription = event.currentTarget.value
                      this.setState({ account }, this.saveAccount.bind(this))
                    }}
                    validationErrors={language.messages.validationErrors}
                  />
                </CardContent>
              </Card>
              <Card
                className='card'
                style={{
                  width: (mainContentWidth > 1100) ? '40%' : '90%',
                  margin: '10px'
                }}
              >
                <CardHeader
                  title={language.translate('application.partnerReferralInfo')}
                  subheader={language.translate('application.partnerReferralSubTitle')}
                />
                <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
                  <TextField
                    disabled
                    id='referral_link'
                    label={language.translate('application.referralLink')}
                    value={`${server}/createaccount.aspx?REFERREDBY=${account.PartnerCode}`}
                    margin='normal'
                    variant='outlined'
                  />
                  <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <CopyToClipboard
                      text={`${server}/createaccount.aspx?REFERREDBY=${account.PartnerCode}`}
                      onCopy={() => showSnackbar(language.translate('application.referralLinkCopied'))}
                    >
                      <Button
                        color='primary'
                        variant='contained'
                      >
                        {language.translate('application.copyReferralLink')}
                      </Button>
                    </CopyToClipboard>
                  </div>
                  <Divider style={{ margin: '10px 0px' }} />
                  <Typography
                    variant='body1'
                    style={{ color: palette.accent3Color }}>
                    {language.translate('application.addReferralLinkToYourWebsite')}
                  </Typography>
                  <div style={{
                    backgroundColor: palette.accent2Color,
                    width: '100%',
                    textAlign: 'center',
                    padding: '10px'
                  }}
                  >
                    <a
                      href={`${server}/createaccount.aspx?REFERREDBY=${account.PartnerCode}`}
                      target='_blank'><img src='https://info.processplan.com/hubfs/getprocessplan.png' /></a>
                  </div>
                  <TextField
                    disabled
                    id='referral_link'
                    label={language.translate('application.websiteCode')}
                    value={`<a href="${server}/createaccount.aspx?REFERREDBY=${account.PartnerCode}" target="_blank"><img src="https://info.processplan.com/hubfs/getprocessplan.png" /></a>`}
                    margin='normal'
                    variant='outlined'
                  />
                  <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <CopyToClipboard
                      text={`<a href="${server}/createaccount.aspx?REFERREDBY=${account.PartnerCode}" target="_blank"><img src="https://info.processplan.com/hubfs/getprocessplan.png" /></a>`}
                      onCopy={() => showSnackbar(language.translate('application.websiteCodeCopied'))}
                    >
                      <Button
                        color='primary'
                        variant='contained'
                      >
                        {language.translate('application.copyWebsiteCode')}
                      </Button>
                    </CopyToClipboard>
                  </div>
                </CardContent>
              </Card>
              <Card
                className='card'
                style={{
                  width: (mainContentWidth > 1100) ? '40%' : '90%',
                  margin: '10px'
                }}
              >
                <CardHeader title={language.translate('application.physicalAddress')} />
                <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
                  <FormsyText
                    fullWidth
                    name='address1'
                    floatingLabelText={language.translate('application.address1')}
                    value={account.PhysicalAddress1}
                    onBlur={(event) => {
                      account.PhysicalAddress1 = event.currentTarget.value
                      this.setState({ account }, this.saveAccount.bind(this))
                    }}
                    validationErrors={language.messages.validationErrors}
                  />
                  <FormsyText
                    name='address2'
                    fullWidth
                    floatingLabelText={language.translate('application.address2')}
                    value={account.PhysicalAddress2}
                    onBlur={(event) => {
                      account.PhysicalAddress2 = event.currentTarget.value
                      this.setState({ account }, this.saveAccount.bind(this))
                    }}
                    validationErrors={language.messages.validationErrors}
                  />
                  <FormsyText
                    name='city'
                    fullWidth
                    floatingLabelText={language.translate('application.city')}
                    value={account.PhysicalCity}
                    onBlur={(event) => {
                      account.PhysicalCity = event.currentTarget.value
                      this.setState({ account }, this.saveAccount.bind(this))
                    }}
                    validationErrors={language.messages.validationErrors}
                  />
                  <FormsyText
                    name='state'
                    fullWidth
                    floatingLabelText={language.translate('application.stateProvinceRegion')}
                    value={account.PhysicalState}
                    maxLength={3}
                    onBlur={(event) => {
                      account.PhysicalState = event.currentTarget.value
                      this.setState({ account }, this.saveAccount.bind(this))
                    }}
                    validationErrors={language.messages.validationErrors}
                  />
                  <FormsyText
                    name='postal_code'
                    fullWidth
                    floatingLabelText={language.translate('application.postalCode')}
                    value={account.PhysicalPostalCode}
                    onBlur={(event) => {
                      account.PhysicalPostalCode = event.currentTarget.value
                      this.setState({ account }, this.saveAccount.bind(this))
                    }}
                    validationErrors={language.messages.validationErrors}
                  />
                  <FormsySelect
                    name='country'
                    fullWidth
                    value={account.PhysicalCountry}
                    onChange={(e, value) => {
                      account.PhysicalCountry = value
                      this.setState({ account }, this.saveAccount.bind(this))
                    }}
                    floatingLabelText={language.translate('application.country')}
                    validationErrors={language.messages.validationErrors}
                  >
                    {countryList}
                  </FormsySelect>
                </CardContent>
              </Card>
              <Card
                className='card'
                style={{
                  width: (mainContentWidth > 1100) ? '40%' : '90%',
                  margin: '10px'
                }}
              >
                <CardHeader
                  title={language.translate('application.customizations')}
                  subheader={language.translate('application.customizationsMessage')}
                />
                <CardContent style={{ textAlign: 'center' }}>
                  <div style={{
                    border: `1px solid ${palette.borderColor}`,
                    padding: '0px 10px 10px 10px'
                  }}
                  >
                    <ColorPicker
                      label={language.translate('application.headerBarColor')}
                      color={user.theme.palette.headerBackgroundColor}
                      onChange={color => this.changePalette('headerBackgroundColor', color)}
                    />
                    <Divider />
                    <ColorPicker
                      label={language.translate('application.headerFontColor')}
                      color={user.theme.palette.headerFontColor}
                      onChange={color => this.changePalette('headerFontColor', color)}
                    />
                    <Button
                      color='primary'
                      variant='contained'
                      onClick={() => this.resetPalette()}
                    >
                      {language.translate('application.resetThemeToDefault')}
                    </Button>
                  </div>
                  {/*
                   * UPLOAD COMPANY LOGO FOR LIGHT BACKGROUND
                   */}
                  <div style={{
                    color: 'rgba(0, 0, 0, 0.298039)',
                    fontWeight: 700,
                    fontSize: '12px',
                    marginTop: '20px',
                    textAlign: 'left'
                  }}
                  >
                    {language.translate('application.companyLogoLight')}
                  </div>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                  >
                    <div style={{
                      backgroundColor: palette.accent2Color,
                      width: '100%',
                      textAlign: 'center',
                      padding: '10px'
                    }}
                    >
                      {(account.HeaderLogoWhiteBG_URL)
                        ? <img
                          src={account.HeaderLogoWhiteBG_URL}
                          style={{ maxWidth: '230px', width: '100%' }}
                        />
                        : <AddAPhoto
                          nativeColor={palette.accent7Color}
                          style={{ height: '80px', width: '80px' }}
                        />}
                    </div>
                    {(account.HeaderLogoWhiteBG_FileName)
                      ? <Button
                        onClick={() => this.deleteAccountLogo(true)}
                        style={{
                          marginLeft: '10px',
                          minWidth: 'inherit',
                          width: '50px',
                          backgroundColor: palette.errorColor
                        }}
                        variant='contained'
                      >
                        <Delete nativeColor={palette.canvasColor} />
                      </Button>
                      : null}
                    <FormsyUpload
                      name='account_logo_light'
                      style={{ marginLeft: '10px' }}
                      onChange={e => this.parseImageUpload(e, 'HeaderLogoWhiteBG_URL', 'HeaderLogoWhiteBG_FileName', 'whitebg')}
                      multiple={false}
                      defaultValue={account.HeaderLogoWhiteBG_FileName}
                    />
                  </div>

                  {/*
                   * UPLOAD COMPANY LOGO FOR DARK BACKGROUND
                   */}
                  <div style={{
                    color: 'rgba(0, 0, 0, 0.298039)',
                    fontWeight: 700,
                    fontSize: '12px',
                    marginTop: '10px',
                    textAlign: 'left'
                  }}
                  >
                    {language.translate('application.companyLogoDark')}
                  </div>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                  >
                    <div style={{
                      backgroundColor: 'rgb(56,61,62)',
                      width: '100%',
                      textAlign: 'center',
                      padding: '10px'
                    }}
                    >
                      {(account.HeaderLogoDarkBG_URL)
                        ? <img
                          src={account.HeaderLogoDarkBG_URL}
                          style={{ maxWidth: '230px', width: '100%' }}
                        />
                        : <AddAPhoto
                          nativeColor={palette.accent7Color}
                          style={{ height: '80px', width: '80px' }}
                        />}
                    </div>
                    {(account.HeaderLogoDarkBG_FileName)
                      ? <Button
                        onClick={() => this.deleteAccountLogo(false)}
                        style={{
                          marginLeft: '10px',
                          minWidth: 'inherit',
                          width: '50px',
                          backgroundColor: palette.errorColor
                        }}
                        variant='contained'
                      >
                        <Delete nativeColor={palette.canvasColor} />
                      </Button>
                      : null}
                    <FormsyUpload
                      name='account_logo_dark'
                      style={{ marginLeft: '10px' }}
                      onChange={e => this.parseImageUpload(e, 'HeaderLogoDarkBG_URL', 'HeaderLogoDarkBG_FileName', 'darkbg')}
                      multiple={false}
                      defaultValue={account.HeaderLogoDarkBG_FileName}
                    />
                  </div>
                </CardContent>
              </Card>
              <Card
                className='card'
                style={{
                  width: (mainContentWidth > 1100) ? '40%' : '90%',
                  margin: '10px'
                }}
              >
                <CardHeader
                  title={language.translate('application.authentication')}
                  subheader={language.translate('application.authenticationSubtitle')}
                />
                <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
                  <FormControlLabel
                    control={<Switch
                      checked={account.SSOEnabled}
                      color='primary'
                      onChange={(obj, value) => {
                        account.SSOEnabled = !account.SSOEnabled
                        this.setState({ account }, this.saveAccount.bind(this))
                      }}
                    />}
                    style={{ justifyContent: 'space-between' }}
                    label={language.translate('application.enableSSO')}
                    labelPlacement='start'
                    classes={{ label: classes.label }}
                  />
                  <Button
                    color='primary'
                    variant='contained'
                    style={{ width: '230px', marginTop: '20px' }}
                    onClick={() => {
                      push({
                        pathname: location.pathname,
                        query: { ptype: 'saml' }
                      })
                    }}
                  >
                    {language.translate('application.configureSSO')}
                  </Button>
                </CardContent>
              </Card>
              <Card
                className='card'
                style={{
                  width: (mainContentWidth > 1100) ? '40%' : '90%',
                  margin: '10px'
                }}
              >
                <CardHeader
                  title={language.translate('application.smtpSettings')}
                  subhader={language.translate('application.smtpSettingsDescription')} />
                <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingTop: '0px' }}>
                  <FormsyText
                    fullWidth
                    name='smtp-hostname'
                    floatingLabelText={language.translate('application.hostName')}
                    value={account.SMTP_Hostname}
                    onBlur={(event) => {
                      account.SMTP_Hostname = event.currentTarget.value
                      this.setState({ account }, this.saveAccount.bind(this))
                    }}
                    validationErrors={language.messages.validationErrors}
                  />
                  <FormsyText
                    fullWidth
                    name='smtp-username'
                    floatingLabelText={language.translate('application.userName')}
                    value={account.SMTP_Username}
                    onBlur={(event) => {
                      account.SMTP_Username = event.currentTarget.value
                      this.setState({ account }, this.saveAccount.bind(this))
                    }}
                    validationErrors={language.messages.validationErrors}
                  />
                  <FormsyText
                    name='smtp-password'
                    fullWidth
                    type='password'
                    autoComplete='new-password'
                    floatingLabelText={language.translate('application.password')}
                    value={account.SMTP_Password}
                    onBlur={(event) => {
                      account.SMTP_Password = event.currentTarget.value
                      this.setState({ account }, this.saveAccount.bind(this))
                    }}
                    validationErrors={language.messages.validationErrors}
                  />
                  <FormsyText
                    name='smtp-port-number'
                    fullWidth
                    floatingLabelText={language.translate('application.portNumber')}
                    value={account.SMTP_PortNumber}
                    onBlur={(event) => {
                      account.SMTP_PortNumber = event.currentTarget.value
                      this.setState({ account }, this.saveAccount.bind(this))
                    }}
                    validationErrors={language.messages.validationErrors}
                  />
                  <FormControlLabel
                    control={<Checkbox
                      checked={account.SMTP_EnableSSL}
                      color='primary'
                      onChange={() => {
                        account.SMTP_EnableSSL = !account.SMTP_EnableSSL
                        this.setState({ account }, this.saveAccount.bind(this))
                      }}
                    />}
                    label={language.translate('application.enableSSL')}
                  />
                  <Button
                    onClick={this.testSMTP.bind(this)}
                    color='primary'
                    variant='contained'
                    style={{ marginTop: '5px' }}
                  >
                    {language.translate('application.testSettings')}
                  </Button>
                </CardContent>
              </Card>
              <Card
                className='card'
                style={{
                  width: (mainContentWidth > 1100) ? '40%' : '90%',
                  margin: '10px'
                }}
              >                
                <CardHeader
                  title={language.translate('application.ipWhitelist')}
                  subheader={language.translate('application.limitAccessToOnlySpecificIpAddresses')}
                />
                <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingTop: '0px' }}>
                  {ipWhitelistArray.map(ip => (
                    <Typography key={ip} id={ip} style={{ paddingLeft: '10px' }}>{ip}
                      <DeleteIconButton onDelete={() => {
                        let ips = ipWhitelistArray.filter(tempIp => ip !== tempIp)
                        account.IPWhitelist = ips.toString()
                        this.setState({ account }, this.saveAccount.bind(this))
                      }} />
                    </Typography>))
                  }
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <FormsyText
                      key='addIpToWhitelist'
                      name='addIpToWhitelist'
                      floatingLabelText={language.translate('application.enterIPAddress')}
                      value={ipWhitelistFieldValue}
                      validations={'isIPAddress'}
                      validationErrors={this.context.language.messages.validationErrors}
                      onChange={(e) => {
                        this.setState({ ipWhitelistFieldValue: e.target.value })
                      }}
                      style={{ width: '200px' }}
                    />
                    <Button
                      color='primary'
                      variant='contained'
                      onClick={() => {
                        if (account.IPWhitelist) {
                          account.IPWhitelist += ','
                        }
                        account.IPWhitelist += ipWhitelistFieldValue
                        this.setState({ account, ipWhitelistFieldValue: '' }, this.saveAccount.bind(this))
                      }}
                      disabled={!isValidIP || !ipWhitelistFieldValue}
                    >
                      {language.translate('application.add')}
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </Masonry>
            <Card
              className='card'
              style={{
                width: (mainContentWidth > 1100) ? '40%' : '90%',
                margin: '40px auto'
              }}
            >
              <CardHeader
                title={language.translate('application.cancelAccount')}
                subheader={language.translate('application.cancelAccountMessage')}
              />
              <CardContent style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  style={{ backgroundColor: palette.errorColor, color: palette.alternateTextColor }}
                  onClick={() => this.setState({ showCancelAccountConfirmation: true })}
                  variant='contained'
                >
                  {language.translate('application.deleteThisAccount')}
                </Button>
              </CardContent>
            </Card>
          </Form>}
        <Dialog
          open={showCancelAccountConfirmation}
          onClose={() => this.setState({ showCancelAccountConfirmation: false })}
        >
          <DialogTitle
            style={{
              backgroundColor: palette.headerBackgroundColor
            }}
            disableTypography
          >
            <Typography
              variant='h6'
              style={{ color: palette.alternateTextColor }}>
              {language.translate('application.deleteThisAccountConfirmation')}
            </Typography>
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() => {
                this.deleteAccount()
              }}
              style={{ backgroundColor: palette.errorColor, color: palette.alternateTextColor }}
              variant='contained'
            >
              {language.translate('application.yesDeleteAccount')}
            </Button>
            <Button
              onClick={() => this.setState({ showCancelAccountConfirmation: false })}
              variant='contained'
            >
              {language.translate('application.cancel')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

AccountInformation.propTypes = {
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.string,
  apiServer: PropTypes.string,
  push: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  location: PropTypes.object,
  post: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  mainContentWidth: PropTypes.number.isRequired,
  updateUserSession: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
}

AccountInformation.contextTypes = {
  muiTheme: PropTypes.object,
  location: PropTypes.object,
  user: PropTypes.object,
  language: PropTypes.object
}

const mapStateToProps = state => ({
  title: state.application.title,
  apiServer: state.application.apiServer,
  user: state.auth,
  mainContentWidth: state.application.mainContentWidth
})

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  post: bindActionCreators(post, dispatch),
  get: bindActionCreators(get, dispatch),
  updateUserSession: bindActionCreators(updateUserSession, dispatch),
  showSnackbar: bindActionCreators(showSnackbar, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AccountInformation))
