import PropTypes from 'prop-types'
import React from 'react'
import { CircularProgress, Card, CardContent, CardHeader } from '@material-ui/core'
import {
  XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, AreaChart, Area
} from 'recharts'
import CustomTooltip from './CustomTooltip'
import NoDataToShow from '../Layout/NoDataToShow'

class AreaCard extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {}
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const { title, subtitle, data, isDataLoaded, tooltipPrefix, mainContentWidth, fillColor } = this.props

    const labelUCFirst = tooltipPrefix.charAt(0).toUpperCase() + tooltipPrefix.substring(1)

    const styles = {
      cardStyles: {
        width: (mainContentWidth < 800) ? '95%' : '45%',
        margin: '10px'
      }
    }

    return (
      <Card style={styles.cardStyles}>
        <CardHeader
          title={title}
          subheader={subtitle}
        />
        <CardContent>
          {(isDataLoaded)
            ? (data.length)
              ? <ResponsiveContainer width='100%' height={400}>
                <AreaChart
                  data={data}
                  margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                >
                  <XAxis dataKey='SeriesLabel' />
                  <YAxis />
                  <CartesianGrid strokeDasharray='3 3' />
                  <Tooltip
                    content={<CustomTooltip labelPrefix={labelUCFirst || language.translate('application.value')} />}
                  />
                  <Area
                    type='monotone'
                    dataKey='SeriesValue'
                    stroke={palette.accent3Color}
                    fill={fillColor || palette.primary1Color}
                  />
                </AreaChart>
              </ResponsiveContainer>
              : <NoDataToShow noDataText={language.translate('application.noDataAdjustFilter')} />
            : <CircularProgress className='loader' />}
        </CardContent>
      </Card>
    )
  }
}

AreaCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  data: PropTypes.array,
  isDataLoaded: PropTypes.bool,
  tooltipPrefix: PropTypes.string,
  mainContentWidth: PropTypes.number,
  fillColor: PropTypes.string
}

AreaCard.defaultProps = {
  tooltipPrefix: ''
}

AreaCard.contextTypes = {
  muiTheme: PropTypes.object,
  location: PropTypes.object,
  language: PropTypes.object
}

export default AreaCard
