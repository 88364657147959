import PropTypes from 'prop-types'
import React from 'react'
import { filterDataOnPage, menuItemClicked } from '../../actions/header'
import { connect } from 'react-redux'
import { ClickAwayListener, IconButton, ListItemIcon, Menu, MenuItem } from '@material-ui/core'
import { ArrowDropDownCircle, Check } from '@material-ui/icons'
import SearchField from './Search/SearchField'

class ContentMenu extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      menuAnchor: null
    }
  }

  render () {
    const { menuAnchor } = this.state
    const { header, location, selectedFilterValue } = this.props
    const { muiTheme } = this.context

    return (
      <div style={{ display: 'inherit' }}>
        <SearchField location={location} />
        {(header.menuItems.length || header.showFilter)
          ? <div>
            <ClickAwayListener
              onClickAway={() => {
                if (menuAnchor) {
                  this.setState({ open: null })
                }
              }}>
              <IconButton onClick={(e) => { this.setState({ menuAnchor: e.currentTarget }) }}>
                <ArrowDropDownCircle nativeColor={muiTheme.palette.headerFontColor} />
              </IconButton>
            </ClickAwayListener>
            <Menu
              anchorEl={menuAnchor}
              open={Boolean(menuAnchor)}
              onClose={() => { this.setState({ menuAnchor: null }) }}
              getContentAnchorEl={null}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
              {header.showFilter
                ? <div style={{ outline: 'none' }}>
                  {header.filterItems.map(item => (<MenuItem
                    key={item}
                    onClick={() => {
                      this.props.dispatch(filterDataOnPage(item))
                      this.setState({ menuAnchor: null })
                    }}
                    style={{ paddingLeft: (selectedFilterValue === item) ? '10px' : '50px' }}
                  >
                    {selectedFilterValue === item &&
                      <ListItemIcon>
                        <Check />
                      </ListItemIcon>}
                    {item}
                  </MenuItem>))}
                </div>
                : ''}

              {(header.menuItems.length)
                ? <div>
                  {header.menuItems.map(item => (<MenuItem
                    key={item.title}
                    onClick={() => {
                      this.setState({ menuAnchor: null })
                      this.props.dispatch(menuItemClicked(item))
                    }}
                  >{item.title}</MenuItem>))}
                </div>
                : ''
              }
            </Menu>
          </div>
          : null}
      </div>
    )
  }
}

ContentMenu.propTypes = {
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object,
  header: PropTypes.object,
  selectedFilterValue: PropTypes.string
}

ContentMenu.contextTypes = {
  muiTheme: PropTypes.object
}

const mapStateToProps = state => ({
  header: state.header,
  selectedFilterValue: state.header.selectedFilterValue
})

const mapDispatchToProps = dispatch => ({
  dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(ContentMenu)
