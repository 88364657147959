import PropTypes from 'prop-types'
import React from 'react'
import FormHelper from '~/businessLogic/formHelper'
import objectAssign from 'object-assign'
import { Button, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Menu } from '@material-ui/core'
import { Add, MoreVert } from '@material-ui/icons'
import DefaultVisibilityEdit from './DefaultVisibilityEdit'
import DeleteMenuItem from '../Layout/DeleteMenuItem'
import HelpIcon from '../Layout/HelpIcon'

class DefaultVisibilityList extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      isLoaded: false,
      defaultVisibilityDialogOpen: false,
      editingItem: null,
      editingItemIndex: null,
      menuAnchor: null,
      menuIndex: null
    }
  }

  // componentWillMount () {
  // }

  // componentDidUpdate (prevProps, prevState) {
  // }

  handleDelete(index) {
    const list = this.props.defaultVisibilityList

    this.props.onDelete(list[index])
  }

  handleSave(item) {
    // TODO: editing an item updates the props directly instead of waiting for the api response to update them
    const acl = this.props.defaultVisibilityList.slice(0)

    if (item.ID) {
      acl[this.state.editingItemIndex] = objectAssign({}, item)
    } else {
      acl.push(item)
    }

    this.props.onSaveList(acl)

    this.setState({
      defaultVisibilityDialogOpen: false,
      editingItem: null,
      editingItemIndex: null
    })
  }

  addNew() {
    const dvl = this.props.defaultVisibilityList

    const newItem = {}

    this.setState({
      defaultVisibilityDialogOpen: true,
      editingItem: newItem,
      editingItemIndex: dvl.length
    })
  }

  render() {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const { defaultVisibilityList, noResultsText } = this.props
    const { menuAnchor, menuIndex } = this.state

    return (
      <List style={{ padding: '0px 20px', backgroundColor: palette.accent2Color }}>
        {(!defaultVisibilityList.length && noResultsText)
          ? <div style={{ textAlign: 'left', padding: '20px', fontSize: '18px' }}>{noResultsText}</div>
          : null}
        {
          defaultVisibilityList.map((item, index) => (
            <ListItem
              button
              onClick={() => this.setState({
                defaultVisibilityDialogOpen: true,
                editingItem: item,
                editingItemIndex: index
              })}
              key={index}
            >
              <ListItemText
                primary={FormHelper.decodeHTML((item.User_FullName) ? item.User_FullName : item.UserGroup_Name)}
                secondary={(item.User_FullName) ? item.User_Position : null} />
              <ListItemSecondaryAction>
                <IconButton onClick={(e) => { this.setState({ menuAnchor: e.currentTarget, menuIndex: index }) }}>
                  <MoreVert />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))
        }
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Button
            onClick={this.addNew.bind(this)}
            color='primary'
            variant='contained'
            style={{ margin: '10px' }}
          >
            <Add />
            {language.translate('application.add')}
          </Button>
          <HelpIcon helpUrl={'editing-a-process-template$DefaultProcessInstanceVisibility'} />
        </div>
        <Menu
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClose={() => { this.setState({ menuAnchor: null, menuIndex: null }) }}
          getContentAnchorEl={null}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
          <DeleteMenuItem
            onDelete={() => {
              this.setState({ menuAnchor: null, menuIndex: null })
              this.handleDelete(menuIndex)
            }}
          />
        </Menu>
        <DefaultVisibilityEdit
          item={this.state.editingItem}
          onSave={this.handleSave.bind(this)}
          isOpen={this.state.defaultVisibilityDialogOpen}
          onCloseRequest={() => this.setState({
            defaultVisibilityDialogOpen: false,
            editingItem: null,
            editingItemIndex: null
          })}
        />
      </List>
    )
  }
}

DefaultVisibilityList.propTypes = {
  defaultVisibilityList: PropTypes.array,
  onSaveList: PropTypes.func,
  onDelete: PropTypes.func,
  noResultsText: PropTypes.string
}

DefaultVisibilityList.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default DefaultVisibilityList
