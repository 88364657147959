import PropTypes from 'prop-types'
import React from 'react'
import { Button, Chip } from '@material-ui/core'
import AutoComplete from '../Forms/AutoComplete'

class TemplateTaskTags extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      tagSearchText: ''
    }
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const { tagSearchText } = this.state
    const { addTag, removeTag, taskTagList, tagList, manageTags } = this.props

    return (
      <div
        className='taskTags'
        key='task-tags'
        style={{
          border: `1px solid ${palette.borderColor}`,
          borderRadius: '10px',
          padding: '10px',
          margin: '10px'
        }}>
        <div className='field-label' style={{
          fontSize: '16px',
          paddingBottom: '5px'
        }}>
          {language.translate('application.taskTags')}:
        </div>
        <div style={{
          display: 'flex',
          flexWrap: 'wrap'
        }}>
          {taskTagList.map((tag) => {
            return (<Chip
              key={tag.ID}
              onDelete={() => removeTag(tag.ID)}
              style={{ margin: '2px' }}
              label={tag.TagName}
            />)
          })}
        </div>
        <AutoComplete
          onChange={(selectedItem) => {
            addTag(selectedItem.value)
          }}
          clearOnSelect
          menuHeight={300}
          items={tagList.map((tag) => {
            return { label: tag.TagName, value: tag.ID }
          })}
          hintText={language.translate('application.addTag')}
          inputProps={{ style: { marginBottom: '5px', marginTop: '5px' } }}
        />
        <Button
          color='primary'
          onClick={manageTags}
          variant='contained'
        >
          {language.translate('application.manageTags')}
        </Button>
      </div>
    )
  }
}

TemplateTaskTags.propTypes = {
  taskTagList: PropTypes.array,
  tagList: PropTypes.array,
  addTag: PropTypes.func,
  manageTags: PropTypes.func,
  removeTag: PropTypes.func
}

TemplateTaskTags.defaultProps = {
  taskTagList: [],
  tagList: []
}

TemplateTaskTags.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default TemplateTaskTags
