import PropTypes from 'prop-types'
import React from 'react'
import { Card, CardHeader } from '@material-ui/core'

export class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)

    this.clearError = this.clearError.bind(this)

    this.state = {
      error: null,
      errorInfo: null,
      pageChangeListener: null
    }
  }

  componentDidMount () {
    this.setState({ pageChangeListener: this.context.router.listen(this.clearError) })
  }

  componentWillUnmount () {
    // calling the function removes the listener
    if (this.state.pageChangeListener) { this.state.pageChangeListener() }
  }

  componentDidCatch (error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    if (window.Rollbar) {
      window.Rollbar.error(error)
    }

    this.setState({
      error: error,
      errorInfo: errorInfo
    })
  }

  clearError () {
    this.setState({
      errorInfo: null
    })
  }

  render () {
    if (this.state.errorInfo) {
      return (
        <Card style={{ margin: '10px' }}>
          <CardHeader
            title='Uh oh!'
            subheader='Something went wrong! Rest assured, our developers have been notified and we are checking
            into the issue.'
          />
        </Card>
      )
    }
    return this.props.children
  }
}

ErrorBoundary.propTypes = {}

ErrorBoundary.contextTypes = {
  router: PropTypes.object,
  muiTheme: PropTypes.object
}

export default ErrorBoundary
