import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { Form } from 'formsy-react'
import { FormsyText } from 'formsy-material-ui'
import { updateTitle } from '../actions/application'
import { addSteps, removeStep } from '../actions/joyride'
import { push } from 'react-router-redux'
import { get, post } from '../actions/base'
import DataList from '../components/Layout/DataList'
import objectAssign from 'object-assign'
import TemplatesByGroup from '../components/ProcessTemplate/TemplatesByGroup'
import AddIcon from '../components/Layout/AddIcon'
import WaitingDialog from '../components/Layout/WaitingDialog'
import { showSnackbar } from '../actions/snackbar'
import DateHelper from '../businessLogic/dateHelper'
import moment from 'moment'

class ProcessTemplatesView extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      processTemplates: {},
      templatesLoaded: false,
      mostRecentTemplates: null,
      mostRecentLoaded: false,
      update: false,
      forceDataRefresh: 0,
      showImportShareCodeDialog: false,
      canSubmitShareCode: true,
      shareCode: null,
      showWaitingDialog: false,
      showImportMessage: false
    }
  }

  componentDidMount () {
    this.updateTitle()

    this.fetchRecentlyLaunchedTemplates()
  }

  componentDidUpdate (prevProps, prevState) {
    this.updateTitle()

    if (prevState.forceDataRefresh !== this.state.forceDataRefresh) { this.fetchRecentlyLaunchedTemplates() }

    if (!prevState.templatesLoaded && this.state.templatesLoaded) {
      this.initializeGuidedTour()
    }
  }

  componentWillUnmount () {
    this.props.removeStep('process-templates-1')
    this.props.removeStep('process-templates-2')
  }

  updateTitle () {
    const { language } = this.context

    this.props.dispatch(updateTitle(language.translate('application.processDirectory')))
  }

  initializeGuidedTour () {
    const { language } = this.context

    const steps1 = [{
      title: language.translate('application.processTemplate'),
      content: language.translate('application.processTemplateInstruction'),
      target: '.card .clickable',
      placement: 'bottom',
      offset: 0,
      disableBeacon: true
    }, {
      title: language.translate('application.templateMenu'),
      content: language.translate('application.templateMenuInstruction'),
      target: '.templateCardContextMenu',
      placement: 'bottom',
      offset: 0,
      disableBeacon: true
    }]

    this.props.addSteps({ id: 'process-templates-1', steps: steps1 })
  }

  dataLoadCallback (data) {
    const grouped = data.reduce((acc, template) => {
      const templateGroupName = (template.ProcessGroupName) ? template.ProcessGroupName : 'Not Grouped'
      acc[templateGroupName] = acc[templateGroupName] || []
      acc[templateGroupName].push(template)
      return acc
    }, {})

    this.setState({
      processTemplates: grouped,
      templatesLoaded: true
    })
  }

  fetchRecentlyLaunchedTemplates () {
    this.props.get(`user/${this.props.user.userid}/processtemplate/list/recentlylaunched`, {
      onSuccess: (response) => {
        this.setState({
          mostRecentTemplates: response.ProcessTemplateList,
          mostRecentLoaded: true
        })
      }
    })
  }

  handleProcessSelect (templateId) {
    this.props.push({
      pathname: this.props.location.pathname,
      query: objectAssign({},
        this.props.location.query,
        {
          ptype: 'process_template',
          pid: templateId,
          ptsid: templateId
        })
    })
  }

  handleScheduleProcess (templateId) {
    this.props.push({
      pathname: this.props.location.pathname,
      query: objectAssign({},
        this.props.location.query,
        {
          ptype: 'process_template',
          pid: templateId,
          ptsid: templateId,
          recurring: true
        })
    })
  }

  handleStartMultiple (templateId) {
    this.props.push({
      pathname: this.props.location.pathname,
      query: objectAssign({},
        this.props.location.query,
        {
          ptype: 'process_template_import',
          pid: templateId,
          ptsid: templateId
        })
    })
  }

  handleStatisticsClick (templateId) {
    this.props.push({
      pathname: '/statistics',
      query: {
        type: 'template',
        templateId,
        by: 'month',
        time: 'days'
      }
    })
  }

  handleInstancesClick (templateId) {
    this.props.push({
      pathname: `/process-instances-grid/${templateId}`
    })
  }

  forceUpdate () {
    this.setState({ update: !this.state.update })
  }

  sopClick (callback) {
    this.props.get(`documenttemplate/list`, {
      onSuccess: (response) => {
        if (callback) {
          callback(response)
        }
      }
    })
  }

  fetchPrintableDocument (templateId, documentTemplateId, callback) {
    const { get } = this.props

    get(`documenttemplate/${documentTemplateId}/publish/processtemplate/${templateId}`, {
      onSuccess: (response) => {
        if (callback) {
          callback(response)
        }
      }
    })
  }

  importFromShareCode () {
    const { shareCode } = this.state

    this.setState({ canSubmitShareCode: false })

    const body = JSON.stringify({
      POSTOptions: {
        ShareCode: shareCode
      }
    })

    this.props.dispatch(post('processtemplate/copyusingsharecode', body, {
      onSuccess: (response) => {
        this.setState({
          showWaitingDialog: false,
          showImportMessage: true,
          canSubmitShareCode: true,
          showImportShareCodeDialog: false
        })
      },

      onError: (error) => {
        this.setState({ showWaitingDialog: false, canSubmitShareCode: true })
      }
    }))
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const { mainContentWidth, push, user, location } = this.props
    const { processTemplates, mostRecentTemplates, mostRecentLoaded, forceDataRefresh, showWaitingDialog, showImportMessage } = this.state

    const newDate = moment()
    const offset = newDate.utcOffset()
    let currentUTC = DateHelper.convertDateTimeToUTC(newDate, offset)
    let accountCreatedUTC = moment(user.accountCreationDateUTC)
    let minutesSinceAccountCreation = currentUTC.diff(accountCreatedUTC, 'minutes')

    return (
      <div>
        {(minutesSinceAccountCreation < 30) &&
        <Typography variant='subtitle1' style={{ color: palette.accent6Color, paddingLeft: '20px' }}>
          {language.translate('application.loadingAccountTemplatesMessage')}
        </Typography>}
        {(mostRecentLoaded && !location.query.search)
          ? <TemplatesByGroup
            groupName={language.translate('application.mostRecent')}
            templates={mostRecentTemplates}
            onGroupToggle={toggle => this.forceUpdate()}
            onClick={this.handleProcessSelect.bind(this)}
            mainContentWidth={mainContentWidth}
            editTemplateClick={templateId => push(`/process-template/${templateId}`)}
            sopClick={this.sopClick.bind(this)}
            fetchPrintableDocument={this.fetchPrintableDocument.bind(this)}
            onSchedule={this.handleScheduleProcess.bind(this)}
            onStartViaImport={this.handleStartMultiple.bind(this)}
            onStatisticsClick={this.handleStatisticsClick.bind(this)}
            onInstancesClick={this.handleInstancesClick.bind(this)}
          />
          : null}
        <DataList
          url='processtemplate/list'
          dataCallback={this.dataLoadCallback.bind(this)}
          fetchAll
          responseProperty='ProcessTemplateList'
          filterableColumns={[{
            name: language.translate('application.title'),
            property: 'Title'
          }, {
            name: language.translate('application.processGroup'),
            property: 'ProcessGroupName'
          }, {
            name: language.translate('application.description'),
            property: 'Description'
          }]}
          noDataText={language.translate('application.noProcessTemplates')}
          forceDataRefresh={forceDataRefresh}
          location={location}
        >
          {Object.keys(processTemplates).sort().map((group, index) => (
            <TemplatesByGroup
              key={index}
              groupName={group}
              templates={processTemplates[group]}
              onGroupToggle={toggle => this.forceUpdate()}
              onClick={this.handleProcessSelect.bind(this)}
              mainContentWidth={mainContentWidth}
              editTemplateClick={templateId => push(`/process-template/${templateId}`)}
              sopClick={this.sopClick.bind(this)}
              fetchPrintableDocument={this.fetchPrintableDocument.bind(this)}
              onSchedule={this.handleScheduleProcess.bind(this)}
              onStartViaImport={this.handleStartMultiple.bind(this)}
              onStatisticsClick={this.handleStatisticsClick.bind(this)}
              onInstancesClick={this.handleInstancesClick.bind(this)}
            />
          ))}
        </DataList>
        {(user.canCreateProcessTemplates)
          ? <AddIcon
            menuItems={[
              {
                text: language.translate('application.newTemplate'),
                onClick: () => push('/process-template')
              },
              {
                text: language.translate('application.importFromShareCode'),
                onClick: () => this.setState({ showImportShareCodeDialog: true }, () => {
                  setTimeout(() => this.shareCodeInput.focus(), 200)
                })
              }
            ]}
          />
          : null}
        <Dialog
          open={this.state.showImportShareCodeDialog}
          onClose={() => this.setState({ showImportShareCodeDialog: false })}
        >
          <DialogTitle
            style={{
              backgroundColor: palette.headerBackgroundColor
            }}
            disableTypography
          >
            <Typography
              variant='h6'
              style={{ color: palette.alternateTextColor }}>
              {language.translate('application.importFromShareCode')}
            </Typography>
          </DialogTitle>
          <DialogContent style={{ width: '350px' }}>
            <Form
              onValid={() => this.setState({ canSubmitShareCode: true })}
              onInvalid={() => this.setState({ canSubmitShareCode: false })}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <FormsyText
                required
                floatingLabelText={language.translate('application.shareCode')}
                ref={(input) => { this.shareCodeInput = input }}
                name='share_code'
                fullWidth
                onChange={e => this.setState({ shareCode: e.currentTarget.value })}
                validationErrors={language.messages.validationErrors}
              />
              <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}>
                <Button
                  color='primary'
                  variant='contained'
                  style={{ display: 'block' }}
                  disabled={!this.state.canSubmitShareCode}
                  onClick={() => {
                    this.importFromShareCode()
                  }}
                >
                  {language.translate('application.submit')}
                </Button>
                <Button
                  variant='contained'
                  style={{ display: 'block' }}
                  onClick={() => this.setState({ showImportShareCodeDialog: false })}
                >
                  {language.translate('application.cancel')}
                </Button>
              </div>
            </Form>
          </DialogContent>
        </Dialog>
        {(showWaitingDialog)
          ? <WaitingDialog
            messages={[
              language.translate('application.buildingNodes'),
              language.translate('application.connectingLines'),
              language.translate('application.automatingActions'),
              language.translate('application.preparingTpsReports')
            ]}
          />
          : null}
        <Dialog
          open={showImportMessage}
          disableBackdropClick
          disableEscapeKeyDown
          onClose={() => this.setState({ showImportMessage: false })}
          style={{ zIndex: 9999 }}
        >
          <DialogTitle
            style={{
              backgroundColor: palette.headerBackgroundColor
            }}
            disableTypography
          >
            <Typography
              variant='h6'
              style={{ color: palette.alternateTextColor }}>
              {language.translate('application.importingTemplate')}
            </Typography>
          </DialogTitle>
          <DialogContent style={{ width: '300px' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                paddingTop: '30px'
              }}
            >
              {language.translate('application.importingTemplatesMessage')}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              color='primary'
              variant='contained'
              onClick={() => this.setState({ showImportMessage: false })}
            >
              {language.translate('application.close')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

ProcessTemplatesView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.string,
  get: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  mainContentWidth: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired,
  addSteps: PropTypes.func.isRequired,
  removeStep: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  location: PropTypes.object
}

ProcessTemplatesView.contextTypes = {
  muiTheme: PropTypes.object,
  language: PropTypes.object
}

const mapStateToProps = (state, ownProps) => ({
  title: state.application.title,
  mainContentWidth: state.application.mainContentWidth,
  user: state.auth,
  location: ownProps.location
})

const mapDispatchToProps = dispatch => ({
  get: bindActionCreators(get, dispatch),
  post: bindActionCreators(post, dispatch),
  push: bindActionCreators(push, dispatch),
  addSteps: bindActionCreators(addSteps, dispatch),
  removeStep: bindActionCreators(removeStep, dispatch),
  showSnackbar: bindActionCreators(showSnackbar, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ProcessTemplatesView)
