import PropTypes from 'prop-types'
import React from 'react'
import { FormsyText } from 'formsy-material-ui'
import {
  Button, IconButton, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, FormControl, InputLabel, List, ListItem,
  ListItemSecondaryAction, ListItemText, Menu, MenuItem, Select, Typography
} from '@material-ui/core'
import CopyToClipboard from 'react-copy-to-clipboard'
import FormHelper from '~/businessLogic/formHelper'
import { Form } from 'formsy-react'
import { Add, MoreVert, Check, ChevronRight } from '@material-ui/icons'
import DeleteMenuItem from '../Layout/DeleteMenuItem'
import DeleteIconButton from '../Layout/DeleteIconButton'
import CancelButton from '../Forms/CancelButton'

class WebHookListItem extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      editMode: false,
      canSubmit: true,
      value: null,
      item: null,
      templateFieldList: [],
      mapList: [],
      mapListLoaded: false,
      openAddMappingDialog: false,
      toField: null,
      fromField: '',
      menuAnchor: null,
      mapItemMenuAnchor: null,
      mapItemMenuId: null
    }
  }

  componentDidMount () {
    if (this.props.item.PurposeDescription === '') { this.setState({ editMode: true }) }

    this.setState({ item: this.props.item })
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.item !== prevProps.item) { this.setState({ item: this.props.item }) }

    if (!prevState.editMode && this.state.editMode && this.props.item.ID) { this.fetchFieldMappingData(this.props.item.ID) }

    if (!prevProps.item.ID && this.props.item.ID && this.state.editMode) { this.fetchFieldMappingData(this.props.item.ID) }
  }

  enableButton () {
    this.setState({ canSubmit: true })
  }

  disableButton () {
    this.setState({ canSubmit: false })
  }

  fetchFieldMappingData (id) {
    this.props.fetchWebHookMappings(id, (data) => {
      this.setState({
        mapList: data.ProcessTemplateWebhookFieldMapList,
        templateFieldList: data.ProcessTemplateFieldList,
        mapListLoaded: true
      })
    })
  }

  saveNewMapping () {
    const { fromField, toField } = this.state
    const { item, saveWebHookMap } = this.props
    const mapList = this.state.mapList.slice()
    let added = false

    mapList.map((field, index) => {
      if (field.ToProcessTemplateFieldID === toField) {
        field.FromIncomingFieldName = fromField
        added = true
      }
    })

    if (!added) {
      mapList.push({ FromIncomingFieldName: fromField, ToProcessTemplateFieldID: toField })
    }

    saveWebHookMap(mapList, item.ID, (response) => {
      this.setState({
        mapList: response.ProcessTemplateWebhookFieldMapList,
        openAddMappingDialog: false,
        fromField: '',
        toField: null
      })
    })
  }

  getFieldTitleByID (id) {
    let title = ''

    this.state.templateFieldList.map((field) => {
      if (field.ID === id) { title = field.FieldName }
    })

    return title
  }

  generateTemplateMenu () {
    const { templateFieldList, mapListLoaded } = this.state
    const { language } = this.context

    if (!mapListLoaded) { return false }

    const menu = [<MenuItem
      value={false}
      key={0}>
      {language.translate('application.none')}
    </MenuItem>]

    templateFieldList.map((field, index) => {
      menu.push(<MenuItem
        value={field.ID}
        key={field.ID}>
        {FormHelper.decodeHTML(field.FieldName)}
      </MenuItem>)
    })

    return menu
  }

  render () {
    const { language, muiTheme: { palette } } = this.context
    const { onSave, displayMessage, deleteWebHookMapItem } = this.props
    const { item, mapList, mapListLoaded, menuAnchor, mapItemMenuAnchor, mapItemMenuId } = this.state

    if (!item) { return null }

    const templateMenu = this.generateTemplateMenu()

    return (
      (!this.state.editMode)
        ? <CopyToClipboard
          text={item.WebhookURL}
          onCopy={() => displayMessage(language.translate('application.webhookCopiedToClipboard'))}
        >
          <ListItem button>
            <ListItemText
              primary={FormHelper.decodeHTML(item.PurposeDescription)}
              secondaryTypographyProps={{ style: { overflow: 'hidden', textOverflow: 'ellipsis' } }}
              secondary={item.WebhookURL} />
            <ListItemSecondaryAction>
              <IconButton onClick={(e) => { this.setState({ menuAnchor: e.currentTarget }) }}>
                <MoreVert />
              </IconButton>
              <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={() => { this.setState({ menuAnchor: null }) }}
                getContentAnchorEl={null}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              >
                <MenuItem
                  onClick={() => {
                    this.setState({ editMode: true, menuAnchor: null })
                  }}
                >{language.translate('application.edit')}</MenuItem>
                <DeleteMenuItem
                  onDelete={() => {
                    this.setState({ menuAnchor: null })
                    this.props.onRemoveField()
                  }}
                />
              </Menu>
            </ListItemSecondaryAction>
          </ListItem>
        </CopyToClipboard>
        : <Form onValid={this.enableButton.bind(this)} onInvalid={this.disableButton.bind(this)}>
          <ListItem>
            <ListItemText primary={
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                alignItems: 'flex-start'
              }}
              >
                <FormsyText
                  floatingLabelText={language.translate('application.description')}
                  style={{ margin: '10px' }}
                  required
                  name={`itemDescription-${item.ID}`}
                  defaultValue={FormHelper.decodeHTML(item.PurposeDescription)}
                  onChange={(e) => {
                    item.PurposeDescription = e.currentTarget.value
                    this.setState({ item })
                  }}
                  validationErrors={language.messages.validationErrors}
                />
                <div style={{
                  border: `1px solid ${palette.borderColor}`,
                  margin: '10px',
                  padding: '10px',
                  width: '100%'
                }}
                >
                  {(mapListLoaded)
                    ? <div>
                      {(!mapList.length)
                        ? <div style={{ margin: '10px' }}>{language.translate('application.noWebhookMappings')}</div>
                        : <List style={{ padding: '0px' }}>
                          {mapList.map((field, index) => (
                            (field.ToProcessTemplateFieldID)
                              ? React.Children.toArray([
                                <ListItem
                                  key={index}>
                                  <ListItemText
                                    primary={
                                      <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                      }}
                                      >
                                        <div>{FormHelper.decodeHTML(field.FromIncomingFieldName)}</div>
                                        <ChevronRight
                                          style={{ marginTop: '2px' }}
                                        />
                                        <div>{this.getFieldTitleByID(field.ToProcessTemplateFieldID)}</div>
                                      </div>} />
                                  <ListItemSecondaryAction>
                                    <IconButton
                                      onClick={(e) => {
                                        this.setState({ mapItemMenuAnchor: e.currentTarget, mapItemMenuId: field.ID })
                                      }}>
                                      <MoreVert />
                                    </IconButton>
                                    <Menu
                                      anchorEl={mapItemMenuAnchor}
                                      open={Boolean(mapItemMenuAnchor) && mapItemMenuId === field.ID}
                                      onClose={() => { this.setState({ mapItemMenuAnchor: null, mapItemMenuId: null }) }}
                                      getContentAnchorEl={null}
                                      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    >
                                      <DeleteMenuItem
                                        onDelete={() => {
                                          this.setState({ mapItemMenuAnchor: null, mapItemMenuId: null })
                                          deleteWebHookMapItem(item.ID, field.ID, () => {
                                            this.fetchFieldMappingData(item.ID)
                                          }, () => {
                                            this.fetchFieldMappingData(item.ID)
                                          })
                                        }}
                                      />
                                    </Menu>
                                  </ListItemSecondaryAction>
                                </ListItem>,
                                (index < mapList.length - 1)
                                  ? <Divider key={index + 10000} /> : null
                              ])
                              : null
                          ))}
                        </List>
                      }
                      <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start'
                      }}
                      >
                        <Button
                          onClick={() => this.setState({ openAddMappingDialog: true })}
                          color='primary'
                          variant='contained'
                        >
                          <Add />
                          {language.translate('application.addMapping')}
                        </Button>
                      </div>
                    </div>
                    : <CircularProgress className='loader' />
                  }
                </div>
              </div>} />
            <ListItemSecondaryAction style={{ top: '10%' }}>
              <div>
                <DeleteIconButton onDelete={this.props.onRemoveField} />
                <IconButton
                  disabled={!this.state.canSubmit}
                  onClick={() => {
                    onSave(this.state.item)
                    // forcing a delay to prevent triggering copy on touch devices
                    setTimeout(() => {
                      this.setState({ editMode: false })
                    }, 300)
                  }}
                >
                  <Check />
                </IconButton>
              </div>
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
          <Dialog
            open={this.state.openAddMappingDialog}
            onClose={() => this.setState({ openAddMappingDialog: false })}
          >
            <DialogTitle
              style={{
                backgroundColor: palette.headerBackgroundColor
              }}
              disableTypography
            >
              <Typography
                variant='h6'
                style={{ color: palette.alternateTextColor }}>
                {language.translate('application.addMapping')}
              </Typography>
            </DialogTitle>
            <DialogContent style={{ width: '300px' }}>
              <FormsyText
                name='from_value'
                fullWidth
                floatingLabelText={language.translate('application.incomingFieldName')}
                value={this.state.fromField}
                onChange={(e) => {
                  this.setState({ fromField: e.currentTarget.value })
                }}
              />
              <FormControl fullWidth>
                <InputLabel style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {language.translate('application.toField')}
                </InputLabel>
                <Select
                  name='map_to'
                  fullWidth
                  MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
                  value={this.state.toField || ''}
                  onChange={(e) => {
                    this.setState({ toField: e.target.value })
                  }}
                >
                  {templateMenu}
                </Select>
              </FormControl>
              <div
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
              >
                <CancelButton
                  onClick={() => this.setState({
                    openAddMappingDialog: false,
                    toField: null,
                    fromField: ''
                  })}
                  variant='contained'
                  style={{ margin: '10px' }}
                />
                <Button
                  onClick={() => {
                    this.saveNewMapping()
                  }}
                  disabled={(!this.state.fromField || !this.state.toField)}
                  variant='contained'
                  color='primary'
                  style={{ margin: '10px' }}
                >
                  {language.translate('application.save')}
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        </Form>
    )
  }
}

WebHookListItem.propTypes = {
  item: PropTypes.object.isRequired,
  fetchWebHookMappings: PropTypes.func.isRequired,
  saveWebHookMap: PropTypes.func.isRequired,
  deleteWebHookMapItem: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onRemoveField: PropTypes.func.isRequired,
  displayMessage: PropTypes.func
}

WebHookListItem.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object,
  user: PropTypes.object
}

export default WebHookListItem
