import PropTypes from 'prop-types'
import React from 'react'
import { FormsyTime } from 'formsy-material-ui'
import FormHelper from '../../businessLogic/formHelper'
import DateHelper from '../../businessLogic/dateHelper'
import { IconButton } from '@material-ui/core'
import { Clear } from '@material-ui/icons'
import moment from 'moment'
import FieldWrapper from './FieldWrapper'

class Time extends React.Component {
  constructor (props, context) {
    super(props, context)
    this.state = {}
  }

  clearTime () {
    const { field, onChange } = this.props

    field.ValueID = ''
    field.Value = ''

    if (onChange) { onChange(field) }
  }

  render () {
    const { field, onChange, wrapperProps } = this.props
    const { user } = this.context

    const timeValue = (field.ValueID) ? moment(field.ValueID).toDate() : undefined

    return (
      <FieldWrapper
        label={FormHelper.decodeHTML(field.FieldName)}
        required={field.RequiredField}
        {...wrapperProps}
      >
        {(field.ReadOnlyField)
          ? <div style={{ position: 'relative', padding: '5px 0px', fontSize: '15px' }}>
            {field.Value}
          </div>
          : <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <FormsyTime
              fullWidth
              name={field.FieldName}
              style={{ flex: 5 }}
              ref={field.FieldName}
              pedantic
              required={field.RequiredField}
              value={timeValue}
              validationErrors={this.context.language.messages.validationErrors}
              onChange={(event, value) => {
                field.ValueID = DateHelper.formatForAPI(value, user.dateFormat, false)

                if (onChange) { onChange(field) }
              }}
            />
            {(field.ValueID)
              ? <div style={{ flex: 1 }}>
                <IconButton
                  onClick={this.clearTime.bind(this)}
                >
                  <Clear />
                </IconButton>
              </div>
              : null}
          </div>}
      </FieldWrapper>)
  }
}

Time.propTypes = {
  field: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  wrapperProps: PropTypes.object
}

Time.contextTypes = {
  language: PropTypes.object,
  user: PropTypes.object
}

export default Time
