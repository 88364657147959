import PropTypes from 'prop-types'
import React from 'react'
import { Badge, IconButton, Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { ChatBubble, ChatBubbleOutline } from '@material-ui/icons'
import Subscriber from '../Layout/Notifications/Subscriber'

const styles = theme => ({
  primary: {
    backgroundColor: theme.palette.accent1Color
  },
  badge_hidden: {
    display: 'none'
  },
  badge_nothidden: {
  }
})

class MessageNotificationIcon extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      isLoading: false,
      count: 0
    }
  }

  componentDidMount () {
    this.fetchMemoCount()
  }

  fetchMemoCount () {
    const { memoTypeID, memoType } = this.props
    const endpoint = `${memoType}/${memoTypeID}/memo/list/unviewed/count`

    this.props.get(endpoint, {
      onSuccess: (response) => {
        this.setState({
          count: response.Count
        })
      }
    })
  }

  render () {
    const { iconColor, onClick, memoType, memoTypeID, classes } = this.props
    const { muiTheme, user, language } = this.context
    const { isLoading, count } = this.state

    return (
      <div style={{ display: 'inline-block' }}>
        <Tooltip title={language.translate('application.messages')}>
          <IconButton
            onClick={() => {
              if (onClick) { onClick() }
            }}
            disabled={isLoading}
          >
            <Badge
              badgeContent={count}
              classes={{
                colorPrimary: classes.primary,
                badge: (count === 0) ? classes.badge_hidden : classes.badge_nothidden
              }}
              color='primary'
            >
              {(!count)
                ? <ChatBubbleOutline nativeColor={(iconColor) || muiTheme.palette.accent6Color} />
                : <ChatBubble nativeColor={(iconColor) || muiTheme.palette.accent6Color} />
              }
            </Badge>
          </IconButton>
        </Tooltip>
        <Subscriber
          channelName={`Private-${user.accountID}-${memoType}-${memoTypeID}`}
          events={['MemoCreated', 'MemoViewed', 'MemoRead', 'MemoArchived']}
          callback={() => {
            setTimeout(() => this.fetchMemoCount(), 1000)
          }}
        />
        {(memoType !== 'user')
          ? <Subscriber
            channelName={`Private-${user.accountID}-User-${user.userid}`}
            events={['MemoCreated', 'MemoViewed', 'MemoRead', 'MemoArchived']}
            callback={() => {
              setTimeout(() => this.fetchMemoCount(), 1000)
            }}
          />
          : null}
      </div>
    )
  }
}

MessageNotificationIcon.propTypes = {
  memoType: PropTypes.string,
  memoTypeID: PropTypes.string,
  onClick: PropTypes.func,
  iconColor: PropTypes.string,
  badgeColor: PropTypes.string,
  get: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
}

MessageNotificationIcon.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object,
  user: PropTypes.object
}

export default withStyles(styles)(MessageNotificationIcon)
