import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Card, CardHeader } from '@material-ui/core'

class Template extends Component {
  render () {
    const { title, message, action } = this.props

    return (
      <Card>
        <CardHeader
          style={{ marginRight: '20px' }}
          title={title}
          subheader={message}
          onClick={action}
        />
      </Card>
    )
  }
}

Template.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  action: PropTypes.func
}

export default Template
