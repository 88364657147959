import * as Colors from 'material-ui/styles/colors'
import { darken } from 'material-ui/utils/colorManipulator'
import Spacing from 'material-ui/styles/spacing'
import zIndex from 'material-ui/styles/zIndex'
import palette from './palette'

export default {
  spacing: Spacing,
  zIndex,
  fontFamily: 'Source Sans Pro, Roboto, sans-serif',
  palette,
  tableRow: {
    hoverColor: 'rgb(111,181,209)',
    stripeColor: Colors.grey200
  },
  listItem: {
    rightIconColor: 'rgb(226, 226, 226)'
  },
  raisedButton: {
    disabledColor: darken('rgb(226, 226, 226)', 0)
  },
  snackbar: {
    actionColor: palette.accent5Color
  }
}
