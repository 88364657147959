import PropTypes from 'prop-types'
import React from 'react'
import numeral from 'numeral'
import { Avatar, Card, CardHeader, CardContent, IconButton, Menu, MenuItem, Typography } from '@material-ui/core'
import { ExpandLess, ExpandMore, MoreVert } from '@material-ui/icons'
import { GeneratedIcon, Icon } from '../Icon'

class UserGroupCard extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      showAll: false,
      menuAnchor: null
    }
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const { width, group, onGroupSelect, forceUpdate, onViewWorkListClick } = this.props
    const { showAll, menuAnchor } = this.state

    return (
      <Card
        key={group.ID}
        className='card clickable'
        style={{
          width,
          marginLeft: '10px',
          marginRight: '10px',
          marginTop: '20px',
          marginBottom: '20px',
          padding: '0px',
          backgroundColor: (this.context.location.query.gsid === group.ID)
            ? palette.borderColor
            : palette.canvasColor
        }}
      >
        <CardHeader
          title={group.GroupName}
          subheader={<div>
            <div>{group.GroupDescription.substr(0, 200)}{group.GroupDescription.length > 200 && '...'}</div>
            <div style={{ color: palette.textColor }}>
              {(group.BaseFeeInCents) ? `${numeral(group.BaseFeeInCents / 100).format('$0,0.00')} ${language.translate('application.baseFee').lcFirst()} + `
                : null}
              {(group.RatePerMinuteInCents) ? `${numeral(group.RatePerMinuteInCents / 100).format('$0,0.00')} ${language.translate('application.perMinute').lcFirst()}`
                : null}
            </div>
          </div>}
          avatar={(group.IconName)
            ? <Icon icon={group.IconName} size={40} />
            : <GeneratedIcon
              text={group.GroupName}
              randomizer={group.ID}
            />}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            onGroupSelect(group.ID)
          }}
          style={{ paddingTop: '8px', paddingLeft: '8px', width: '100%' }}
          titleTypographyProps={{
            style: {
              fontSize: '18px',
              lineHeight: '22px',
              fontWeight: '600',
              width: '100%'
            }
          }}
          action={
            <IconButton
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                this.setState({
                  menuAnchor: e.currentTarget
                })
              }}
            >
              <MoreVert />
            </IconButton>
          }
        />
        <Menu
          className='menu'
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClose={() => { this.setState({ menuAnchor: null }) }}
          getContentAnchorEl={null}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
          <MenuItem
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              this.setState({ menuAnchor: null })
              onGroupSelect(group.ID)
            }}
          >{language.translate('application.editGroup')}</MenuItem>
          <MenuItem
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              this.setState({ menuAnchor: null })
              onViewWorkListClick(group.ID, group.GroupName)
            }}
          >{language.translate('application.viewWorkList')}</MenuItem>
        </Menu>
        <CardContent
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            onGroupSelect(group.ID)
          }}
          style={{
            paddingLeft: '15px',
            paddingRight: '15px',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap'
          }}
        >
          {(!group.UserList.length)
            ? (group.IsOnDemand) ? null : language.translate('application.noMembers')
            : group.UserList.map((user, index) => (
              (index < 7 || showAll)
                ? <div
                  key={index}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '65px'
                  }}
                >
                  <Avatar
                    src={user.ProfilePicURL}
                  />
                  <Typography>{user.FirstName}</Typography>
                </div>
                : null
            ))}
          {(group.UserList.length > 7)
            ? <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '65px'
              }}
            >
              <Avatar>
                {(!showAll)
                  ? <ExpandMore
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      this.setState({ showAll: true }, () => forceUpdate())
                    }}
                  />
                  : <ExpandLess
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      this.setState({ showAll: false }, () => forceUpdate())
                    }}
                  />}
              </Avatar>
              <Typography>
                {(!showAll) ? language.translate('application.showAll') : language.translate('application.less')}
              </Typography>
            </div>
            : null}
        </CardContent>
      </Card>
    )
  }
}

UserGroupCard.propTypes = {
  group: PropTypes.object.isRequired,
  get: PropTypes.func,
  width: PropTypes.string.isRequired,
  forceUpdate: PropTypes.func,
  onViewWorkListClick: PropTypes.func,
  onGroupSelect: PropTypes.func
}

UserGroupCard.contextTypes = {
  muiTheme: PropTypes.object,
  location: PropTypes.object,
  language: PropTypes.object
}

export default UserGroupCard
