import PropTypes from 'prop-types'
import React from 'react'
import Icon from '../../Icon/Icon'
import { Button, CircularProgress } from '@material-ui/core'

class Zapier extends React.Component {
  componentDidMount () {
    const script = document.createElement('script')
    const script2 = document.createElement('script')

    script.src = 'https://zapier.com/zapbook/embed/widget.js?services=processplan&container=true&limit=10&html_id=zapier'
    script.async = true

    script2.text = this.zapierOpenDialog

    this.refs.zapier.appendChild(script)
    this.refs.zapier.appendChild(script2)
  }

  zapierOpenDialog (e) {
    e.preventDefault()
    e.stopPropagation()
    const button = e.target
    window.open(button.getAttribute('data-popup-href'), 'zapierModalWindow', 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=980,height=700')
    return false
  }

  render () {
    const { language } = this.context
    const { screenWidth } = this.props

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '10px'
        }}
      >
        <h3 style={{ textAlign: 'center' }}>Automate your business. Connect apps to ProcessPlan</h3>
        <div style={{
          margin: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'center'
        }}
        >
          {(screenWidth > 700)
            ? <div style={{ width: '45%' }}>
              <img width='100%' src='https://s3.amazonaws.com/ppwebsitefiles/zapierheader.png' />
            </div>
            : null}
          <div style={{
            width: (screenWidth > 700) ? '45%' : '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
          >
            <div>{language.translate('application.zapierMessage')}</div>
            <Button
              href='https://zapier.com/apps/processplan/integrations/'
              target='_blank'
              style={{ marginTop: '10px', fontSize: '18px', fontWeight: '600', color: '#ff5c1a' }}
            >
              {language.translate('application.goToZapier')}
              <Icon style={{ paddingLeft: '5px' }} icon='ZP' size={30} color='#ff5c1a' />
            </Button>
          </div>
        </div>
        <div
          id='zapier'
          ref='zapier'
          style={{ display: (screenWidth > 700) ? 'inherit' : 'none' }}
        >
          <CircularProgress className='loader' />
        </div>
      </div>
    )
  }
}

Zapier.propTypes = {
  onOpen: PropTypes.func,
  screenWidth: PropTypes.number
}

Zapier.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default Zapier
