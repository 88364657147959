import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get, post } from '~/actions/base'
import { Card, Divider, List, ListItem, ListItemSecondaryAction, ListItemText, Switch, TextField } from '@material-ui/core'
import RightPanelContent from '../Layout/RightPanelContent'
import ColorPicker from '../Forms/ColorPicker'

class TemplateResponseSettings extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      isLoaded: false,
      template: null,
      templateResponse: null
    }
  }

  // MIGRATED TO componentDidMount
  // componentWillMount () {
  //   this.fetchProcessTemplateResponseSettings()
  // }

  componentDidMount() {
    if (this.state.isLoaded === false) {
      this.fetchProcessTemplateResponseSettings()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.templateResponseId !== this.props.templateResponseId) {
      this.fetchProcessTemplateResponseSettings()
    }
  }

  fetchProcessTemplateResponseSettings() {
    const { templateResponseId } = this.props

    this.props.get(`processtemplatetaskresponse/${templateResponseId}`, {
      onSuccess: (response) => {
        this.setState({
          isLoaded: true,
          templateResponse: response.ProcessTemplateTaskResponse,
          template: response.ProcessTemplate
        })
      }
    })
  }

  saveProcessTemplateResponseSettings(settings) {
    const body = JSON.stringify({
      ProcessTemplateTaskResponse: settings
    })

    this.props.post(
      `processtemplate/${this.state.template.ID}/response/${this.state.templateResponse.ID}`,
      body,
      {
        onSuccess: (response) => {
          this.setState({
            templateResponse: response.ProcessTemplateTaskResponse
          })
        }
      }
    )
  }

  render() {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const { templateResponse, isLoaded } = this.state

    return (
      <RightPanelContent
        title={(isLoaded) ? templateResponse.ResponseText : null}
        closePanel={() => this.props.close()}
      >
        <Divider style={{ height: '10px', backgroundColor: palette.accent4Color }} />
        <div style={{ padding: '15px' }}>
          {(isLoaded)
            ? <Card className='card'>
              <List>
                <ListItem>
                  <ListItemText primary={language.translate('application.responseShouldCancelProcess')} />
                  <ListItemSecondaryAction>
                    <Switch
                      color='primary'
                      checked={(templateResponse.CancelProcessInstance)}
                      onChange={(obj, value) => {
                        templateResponse.CancelProcessInstance = !templateResponse.CancelProcessInstance
                        this.saveProcessTemplateResponseSettings(templateResponse)
                      }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary={language.translate('application.hideResponseIfPastDue')} />
                  <ListItemSecondaryAction>
                    <Switch
                      color='primary'
                      checked={(templateResponse.HideIfPastDue)}
                      onChange={(obj, value) => {
                        templateResponse.HideIfPastDue = !templateResponse.HideIfPastDue
                        this.saveProcessTemplateResponseSettings(templateResponse)
                      }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary={language.translate('application.processInBackground')} />
                  <ListItemSecondaryAction>
                    <Switch
                      color='primary'
                      checked={(templateResponse.ProcessInBackground)}
                      onChange={(obj, value) => {
                        templateResponse.ProcessInBackground = !templateResponse.ProcessInBackground
                        this.saveProcessTemplateResponseSettings(templateResponse)
                      }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary={language.translate('application.exitSubprocess')} />
                  <ListItemSecondaryAction>
                    <Switch
                      color='primary'
                      checked={(templateResponse.ExitSubprocess)}
                      onChange={(obj, value) => {
                        templateResponse.ExitSubprocess = !templateResponse.ExitSubprocess
                        this.saveProcessTemplateResponseSettings(templateResponse)
                      }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary={language.translate('application.maxResponseSelectionTimes')} />
                  <ListItemSecondaryAction style={{ marginRight: '10px' }}>
                    <TextField
                      type='tel'
                      name='response_max'
                      style={{ width: '40px' }}
                      defaultValue={templateResponse.MaxSelections}
                      onBlur={(e) => {
                        templateResponse.MaxSelections = parseInt(e.currentTarget.value)
                        this.saveProcessTemplateResponseSettings(templateResponse)
                      }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary={language.translate('application.responseButtonDisplayColor')} />
                  <ListItemSecondaryAction style={{ marginRight: '10px' }}>
                    <ColorPicker
                      color={templateResponse.DisplayColor}
                      requireHex
                      onChange={color => {
                        templateResponse.DisplayColor = color
                        this.saveProcessTemplateResponseSettings(templateResponse)
                      }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Card>
            : null}
        </div>
      </RightPanelContent>
    )
  }
}

TemplateResponseSettings.propTypes = {
  templateResponseId: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired
}

TemplateResponseSettings.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  dispatch,
  post: bindActionCreators(post, dispatch),
  get: bindActionCreators(get, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(TemplateResponseSettings)
