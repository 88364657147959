import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get, post } from '../../actions/base'
import { showUndoMessage } from '../../actions/snackbar'
import {
  Button, Card, CardContent, CardHeader, Checkbox, Collapse, Divider, FormControl, FormControlLabel, IconButton,
  InputLabel, MenuItem, List, Select, TextField, Typography
} from '@material-ui/core'
import { Icon, GeneratedIcon } from '~/components/Icon'
import IconSelectorDialog from '~/components/Icon/IconSelectorDialog'
import { Add, Check, Edit, ExpandMore } from '@material-ui/icons'
import { push, goBack } from 'react-router-redux'
import { Form } from 'formsy-react'
import { FormsyText } from 'formsy-material-ui'
import FormHelper from '../../businessLogic/formHelper'
import objectAssign from 'object-assign'
import RightPanelContent from '~/components/Layout/RightPanelContent'
import DashboardGroupTemplateListItem from './DashboardGroupTemplateListItem'

class DashboardGroupSettings extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      canSubmit: true,
      group: null,
      isLoaded: false,
      templates: [],
      templatesLoaded: false,
      showIconSelector: false,
      editGroupName: false,
      canSubmitGroupName: true,
      groupSettingsExpanded: false,
      processCreatedTimeContext: 'days',
      processCompletedTimeContext: 'days',
      dueDateTimeContext: 'days'
    }
  }

  // MIGRATED TO componentDidMount
  // componentWillMount () {
  //   if (!this.props.groupId) { this.createGroup() } else { this.fetchAllData() }
  // }

  componentDidMount() {
    if (this.state.isLoaded === false) {
      if (!this.props.groupId) { this.createGroup() } else { this.fetchAllData() }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.groupId && prevProps.groupId !== this.props.groupId) {
      this.fetchAllData()
    }

    if (!this.props.groupId && prevProps.groupId) {
      this.createGroup()
    }
  }

  fetchAllData() {
    this.fetchGroup()
    this.fetchGroupTemplates()
  }

  fetchGroup() {
    const { groupId } = this.props
    this.setState({ isLoaded: false })

    this.props.dispatch(get(`dashboardgroup/${groupId}`, {
      onSuccess: (response) => {
        this.setState({
          group: response.DashboardGroup,
          isLoaded: true
        }, () => {
          this.setDateContext(response.DashboardGroup.InstanceCreatedDisplayMinutes, 'processCreatedTimeContext')
          this.setDateContext(response.DashboardGroup.InstanceCompletedDisplayMinutes, 'processCompletedTimeContext')
        })
      }
    }))
  }

  createGroup() {
    const { language } = this.context

    const body = JSON.stringify({
      DashboardGroup: { GroupName: language.translate('application.newDashboard') }
    })

    this.props.post('dashboardgroup', body, {
      onSuccess: (response) => {
        this.props.push({
          pathname: this.context.location.pathname,
          query: objectAssign({},
            this.context.location.query,
            {
              ptype: 'dashboard-group',
              pid: response.DashboardGroup.ID,
              gsid: response.DashboardGroup.ID
            })
        })
      }
    })
  }

  saveDashboardGroup(group) {
    const body = JSON.stringify({
      DashboardGroup: group
    })

    this.props.post(`dashboardgroup/${this.state.group.ID}`, body, {
      onSuccess: (response) => {
        this.setState({
          group: response.DashboardGroup
        })
      }
    })
  }

  fetchGroupTemplates() {
    const { groupId } = this.props

    if (!groupId || groupId === '0') {
      return false
    }

    this.props.dispatch(get(`dashboardgroup/${groupId}/template/list`, {
      onSuccess: (response) => {
        this.setState({
          templates: response.ProcessTemplateList,
          templatesLoaded: true
        })
      }
    }))
  }

  addTemplateToGroup(templateId) {
    const body = JSON.stringify({})

    this.props.post(`dashboardgroup/${this.props.groupId}/addtemplate/${templateId}`, body, {
      onSuccess: (response) => {
        this.fetchGroupTemplates()
      }
    })
  }

  deleteGroup() {
    const { language } = this.context

    const body = JSON.stringify({})

    this.props.post(`dashboardgroup/${this.props.groupId}/delete`, body, {
      onSuccess: (response) => {
        this.props.close()

        this.props.showUndoMessage(
          language.translate('application.dashboardGroupRemoved'),
          () => {
            this.props.post(`dashboardgroup/${this.props.groupId}/delete/undo`, body, {
              onSuccess: (response) => {
                this.props.goBack()
              }
            })
          }
        )
      }
    })
  }

  removeTemplateFromGroup(templateId, templateIndex) {
    const body = JSON.stringify({})

    if (templateId) {
      this.props.post(`dashboardgroup/${this.props.groupId}/removetemplate/${templateId}`, body, {
        onSuccess: (response) => {
          this.fetchGroupTemplates()
        }
      })
    } else {
      let newTemplates = JSON.parse(JSON.stringify(this.state.templates))
      newTemplates.splice(templateIndex, 1)

      this.setState({ templates: newTemplates })
    }
  }

  generateNewTemplate() {
    const { templates } = this.state

    templates.push({})

    this.setState({ templates })
  }

  openTemplate(templateId) {
    const { query } = this.context.location
    delete query.gsid

    this.props.push({
      pathname: `process-template/${templateId}`,
      query
    })
  }

  setDateContext(adjustment, stateProperty) {
    let context = 'minutes'

    if (!(adjustment % 1440)) {
      context = 'days'
    } else if (!(adjustment % 60)) {
      context = 'hours'
    }

    this.setState({ [stateProperty]: context })
  }

  convertToMinutes(value, context) {
    if (context === 'minutes') {
      return parseInt(value, 10)
    }

    if (context === 'hours') {
      return value * 60
    }

    if (context === 'days') {
      return value * 1440
    }
  }

  convertFromMinutes(value, context) {
    if (context === 'minutes') {
      return value
    }

    if (context === 'hours') {
      return value / 60
    }

    if (context === 'days') {
      return value / 1440
    }
  }

  render() {
    const palette = this.context.muiTheme.palette
    const { language, user } = this.context
    const { isLoaded, templates, templatesLoaded, group, showIconSelector, editGroupName, canSubmitGroupName, groupSettingsExpanded, processCreatedTimeContext, processCompletedTimeContext, dueDateTimeContext } = this.state
    let createdTimeAdjustment, completedTimeAdjustment, dueDateTimeAdjustment

    if (isLoaded) {
      createdTimeAdjustment = this.convertFromMinutes(group.InstanceCreatedDisplayMinutes, processCreatedTimeContext)
      completedTimeAdjustment = this.convertFromMinutes(group.InstanceCompletedDisplayMinutes, processCompletedTimeContext)
      dueDateTimeAdjustment = this.convertFromMinutes(group.DueDateProximity, dueDateTimeContext)
    }

    return (
      <RightPanelContent
        closePanel={() => this.props.close()}
        title={
          (isLoaded)
            ? (editGroupName)
              ? <Form
                onValid={() => this.setState({ canSubmitGroupName: true })}
                onInvalid={() => this.setState({ canSubmitGroupName: false })}
              >
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  padding: '0px 20px'
                }}
                >
                  <FormsyText
                    required
                    name='group_name'
                    value={FormHelper.decodeHTML(group.GroupName)}
                    onChange={(event) => {
                      group.GroupName = event.currentTarget.value
                    }}
                    validationErrors={language.messages.validationErrors}
                    errorStyle={{ display: 'none' }}
                  />
                  <IconButton
                    onClick={() => {
                      this.setState({ editGroupName: false })
                      this.saveDashboardGroup(group)
                    }}
                    disabled={!canSubmitGroupName}
                  >
                    <Check nativeColor={palette.accent3Color} />
                  </IconButton>
                </div>
              </Form>
              : <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start'
              }}
              >
                <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {FormHelper.decodeHTML(group.GroupName)}
                </div>
                <IconButton onClick={() => this.setState({ editGroupName: true })}>
                  <Edit nativeColor={palette.accent3Color} fontSize='small' />
                </IconButton>
              </div>
            : null
        }
        titleStyle={{ paddingRight: '0px', paddingLeft: '15px' }}
        titleContainerStyle={{ justifyContent: 'center' }}
        leftToolbarAvatar={
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '5px' }}>
            {(isLoaded)
              ? (group.IconName)
                ? <IconButton
                  style={{ padding: '0px', width: '40px', height: '40px' }}
                  onClick={() => this.setState({ showIconSelector: true })}
                >
                  <Icon icon={group.IconName} size={40} />
                </IconButton>
                : <GeneratedIcon
                  className='clickable'
                  text={group.GroupName}
                  randomizer={group.ID}
                  onClick={() => this.setState({ showIconSelector: true })}
                />
              : null}
            <div style={{
              fontSize: '10px',
              color: palette.accent2Color,
              float: 'left',
              position: 'absolute',
              backgroundColor: palette.disabledColor,
              top: '35px',
              whiteSpace: 'nowrap'
            }}
            >{language.translate('application.updateIcon')}
            </div>
          </div>
        }
        toolbarMenuItems={[
          (isLoaded)
            ? {
              onClick: () => this.deleteGroup(),
              label: language.translate('application.deleteDashboard'),
              isDelete: true
            } : null
        ].filter(Boolean)}
      >
        {(isLoaded && templatesLoaded)
          ? <div style={{ padding: '15px' }}>
            {(group.GroupName !== language.translate('application.newDashboard') &&
              <Card className='card'>
                <CardHeader
                  title={language.translate('application.dashboardSettings')}
                  titleTypographyProps={{ style: { fontSize: '20px' } }}
                  action={
                    <IconButton
                      style={{
                        transform: (groupSettingsExpanded) ? 'rotate(180deg)' : 'rotate(0deg)',
                        marginLeft: 'auto'
                      }}
                      onClick={() => this.setState({ groupSettingsExpanded: !groupSettingsExpanded })}
                      aria-expanded={groupSettingsExpanded}
                      aria-label='Show more'
                    >
                      <ExpandMore />
                    </IconButton>
                  }
                />
                <Collapse in={groupSettingsExpanded} timeout='auto' unmountOnExit>
                  <CardContent style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                    <div style={{ padding: '10px', display: 'flex', flexDirection: 'column' }}>
                      <TextField
                        name='description'
                        label={language.translate('application.groupDescription')}
                        multiline
                        rowsMax='3'
                        fullWidth
                        style={{ margin: '10px 0' }}
                        defaultValue={group.GroupDescription}
                        onBlur={(e) => {
                          group.GroupDescription = e.currentTarget.value
                          this.setState({ group }, () => {
                            this.saveDashboardGroup(group)
                          })
                        }}
                      />
                      <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        fontSize: '16px',
                        margin: '10px 0'
                      }}>
                        <div style={{ paddingRight: '5px', width: '50%' }}>
                          {language.translate('application.startedProcessesOnReportFor')}
                          <Typography
                            variant='caption'
                            style={{ paddingLeft: '5px', color: palette.accent4Color }}
                          >
                            (0 = forever)
                          </Typography>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <TextField
                            type='tel'
                            name='created_adjustment'
                            ref='created_adjustment'
                            style={{ width: '40px', marginRight: '10px' }}
                            value={createdTimeAdjustment}
                            onChange={(e) => {
                              let adjustment = this.convertToMinutes(e.currentTarget.value, processCreatedTimeContext)

                              group.InstanceCreatedDisplayMinutes = adjustment

                              this.setState({ group })
                            }}
                            onBlur={(e) => {
                              let adjustment = this.convertToMinutes(e.currentTarget.value, processCreatedTimeContext)

                              group.InstanceCreatedDisplayMinutes = adjustment

                              this.setState({ group }, () => {
                                this.saveDashboardGroup(group)
                              })
                            }}
                          />
                          <Select
                            style={{ marginRight: '0px 5px' }}
                            autoWidth
                            name='created_time_context'
                            value={processCreatedTimeContext}
                            onChange={(e) => {
                              let value = e.target.value
                              const prevDateContext = processCreatedTimeContext

                              this.setState({ processCreatedTimeContext: value }, () => {
                                const prevContextValue = this.convertFromMinutes(group.InstanceCreatedDisplayMinutes, prevDateContext)

                                group.InstanceCreatedDisplayMinutes = this.convertToMinutes(prevContextValue, value)

                                this.setState({ group }, () => {
                                  this.saveDashboardGroup(group)
                                })
                              })
                            }}
                          >
                            {[
                              <MenuItem value='days' key={1}>{language.translate('application.day')}</MenuItem>,
                              <MenuItem value='hours' key={2}>{language.translate('application.hour')}</MenuItem>,
                              <MenuItem value='minutes' key={3}>{language.translate('application.minute')}</MenuItem>
                            ]}
                          </Select>
                        </div>
                      </div>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        fontSize: '16px',
                        margin: '10px 0'
                      }}>
                        <div style={{ paddingRight: '5px', width: '50%' }}>
                          {language.translate('application.completedProcessesOnReportFor')}
                          <Typography
                            variant='caption'
                            style={{ paddingLeft: '5px', color: palette.accent4Color }}
                          >
                            (0 = forever)
                          </Typography>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <TextField
                            type='tel'
                            name='completed_adjustment'
                            ref='completed_adjustment'
                            style={{ width: '40px', marginRight: '10px' }}
                            value={completedTimeAdjustment}
                            onChange={(e) => {
                              let adjustment = this.convertToMinutes(e.currentTarget.value, processCompletedTimeContext) || 0

                              group.InstanceCompletedDisplayMinutes = adjustment

                              this.setState({ group })
                            }}
                            onBlur={(e) => {
                              let adjustment = this.convertToMinutes(e.currentTarget.value, processCompletedTimeContext) || 0

                              group.InstanceCompletedDisplayMinutes = adjustment

                              this.setState({ group }, () => {
                                this.saveDashboardGroup(group)
                              })
                            }}
                          />
                          <Select
                            style={{ marginRight: '0px 5px' }}
                            autoWidth
                            name='completed_time_context'
                            value={processCompletedTimeContext}
                            onChange={(e) => {
                              let value = e.target.value
                              const prevDateContext = processCompletedTimeContext

                              this.setState({ processCompletedTimeContext: value }, () => {
                                const prevContextValue = this.convertFromMinutes(group.InstanceCompletedDisplayMinutes, prevDateContext) || 0

                                group.InstanceCompletedDisplayMinutes = this.convertToMinutes(prevContextValue, value)

                                this.setState({ group }, () => {
                                  this.saveDashboardGroup(group)
                                })
                              })
                            }}
                          >
                            {[
                              <MenuItem value='days' key={1}>{language.translate('application.day')}</MenuItem>,
                              <MenuItem value='hours' key={2}>{language.translate('application.hour')}</MenuItem>,
                              <MenuItem value='minutes' key={3}>{language.translate('application.minute')}</MenuItem>
                            ]}
                          </Select>
                        </div>
                      </div>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        fontSize: '16px',
                        margin: '10px 0'
                      }}>
                        <div style={{ paddingRight: '5px', width: '50%' }}>
                          Show warning if due date is within:
                          {/*language.translate('application.startedProcessesOnReportFor')*/}
                          <Typography
                            variant='caption'
                            style={{ paddingLeft: '5px', color: palette.accent4Color }}
                          >
                            (0 = no warnings)
                          </Typography>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <TextField
                            type='tel'
                            name='dueDateProximity'
                            ref='dueDateProximity'
                            style={{ width: '40px', marginRight: '10px' }}
                            value={dueDateTimeAdjustment || 0}
                            onChange={(e) => {
                              let adjustment = this.convertToMinutes(e.currentTarget.value, dueDateTimeContext)

                              group.DueDateProximity = adjustment

                              this.setState({ group })
                            }}
                            onBlur={(e) => {
                              let adjustment = this.convertToMinutes(e.currentTarget.value, dueDateTimeContext)

                              group.DueDateProximity = adjustment

                              this.setState({ group }, () => {
                                this.saveDashboardGroup(group)
                              })
                            }}
                          />
                          <Select
                            style={{ marginRight: '0px 5px' }}
                            autoWidth
                            name='dueDateContext'
                            value={dueDateTimeContext}
                            onChange={(e) => {
                              let value = e.target.value
                              const prevDateContext = dueDateTimeContext

                              this.setState({ dueDateTimeContext: value }, () => {
                                const prevContextValue = this.convertFromMinutes(group.DueDateProximity, prevDateContext)

                                group.DueDateProximity = this.convertToMinutes(prevContextValue, value)

                                this.setState({ group }, () => {
                                  this.saveDashboardGroup(group)
                                })
                              })
                            }}
                          >
                            {[
                              <MenuItem value='days' key={1}>{language.translate('application.day')}</MenuItem>,
                              <MenuItem value='hours' key={2}>{language.translate('application.hour')}</MenuItem>,
                              <MenuItem value='minutes' key={3}>{language.translate('application.minute')}</MenuItem>
                            ]}
                          </Select>
                        </div>
                      </div>
                      <FormControl style={{ marginRight: '0px 10px', width: '200px' }}>
                        <InputLabel style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {language.translate('application.sortBy')}
                        </InputLabel>
                        <Select
                          name='instance_sort_by'
                          value={group.InstanceSortBy}
                          onChange={(e) => {
                            group.InstanceSortBy = e.target.value

                            this.setState({ group }, () => {
                              this.saveDashboardGroup(group)
                            })
                          }}
                        >
                          {[
                            <MenuItem value='CreatedDate_UTC'
                              key={1}>{language.translate('application.instanceAge')}</MenuItem>,
                            <MenuItem value='ProcessTemplateTitle'
                              key={2}>{language.translate('application.templateTitle')}</MenuItem>,
                            <MenuItem value='Tasks_ProgressPercentage'
                              key={3}>{language.translate('application.percentCompleted')}</MenuItem>,
                            <MenuItem value='LastCompletedTask_CompletedDate_UTC'
                              key={3}>{language.translate('application.lastProgress')}</MenuItem>,
                            <MenuItem value='Tasks_PastDueCount'
                              key={3}>{language.translate('application.pastDue')}</MenuItem>
                          ]}
                        </Select>
                      </FormControl>
                      <FormControlLabel
                        style={{ padding: '10px 0' }}
                        control={<Checkbox
                          checked={group.InstanceSortByAsc}
                          color='primary'
                          onChange={() => {
                            group.InstanceSortByAsc = !group.InstanceSortByAsc

                            this.setState({ group }, () => {
                              this.saveDashboardGroup(group)
                            })
                          }}
                        />}
                        label={language.translate('application.sortAscending')}
                      />
                    </div>
                  </CardContent>
                </Collapse>
              </Card>
            )}
            {(templates && templates.length > 0 &&
              <Card className='card'>
                <CardContent>
                  <List style={{ padding: '0px' }}>
                    {templates.map((template, index) => (
                      React.Children.toArray([
                        <DashboardGroupTemplateListItem
                          key={index}
                          template={template}
                          removeTemplateFromGroup={(templateId) => { this.removeTemplateFromGroup(templateId, index) }}
                          addTemplateToGroup={this.addTemplateToGroup.bind(this)}
                          onTemplateSelect={this.openTemplate.bind(this)}
                        />,
                        (index < templates.length - 1) ? <Divider key={template.ID} /> : null
                      ])
                    ))}
                  </List>
                </CardContent>
              </Card>)}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
              <Button
                onClick={this.generateNewTemplate.bind(this)}
                color='primary'
                variant='contained'
              >
                <Add />
                {language.translate('application.addTemplateToDashboard')}
              </Button>
            </div>
            <IconSelectorDialog
              onRequestClose={() => this.setState({ showIconSelector: false })}
              selectedIcon={group.IconName}
              open={showIconSelector}
              onSelect={(icon) => {
                group.IconName = icon

                this.saveDashboardGroup(group)

                this.setState({
                  showIconSelector: false
                })
              }}
            />
          </div>
          : null}
      </RightPanelContent>
    )
  }
}

DashboardGroupSettings.propTypes = {
  groupId: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  showUndoMessage: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired
}

DashboardGroupSettings.contextTypes = {
  location: PropTypes.object,
  language: PropTypes.object,
  muiTheme: PropTypes.object,
  user: PropTypes.object
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  dispatch,
  post: bindActionCreators(post, dispatch),
  get: bindActionCreators(get, dispatch),
  push: bindActionCreators(push, dispatch),
  showUndoMessage: bindActionCreators(showUndoMessage, dispatch),
  goBack: bindActionCreators(goBack, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardGroupSettings)
