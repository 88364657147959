import PropTypes from 'prop-types'
import React from 'react'
import { Button, Checkbox, FormControlLabel, List, ListItem, ListItemText } from '@material-ui/core'
import { FormsyText } from 'formsy-material-ui'

const style = {
  listItemStyle: {
    paddingTop: '0px',
    paddingBottom: '0px'
  }
}

class SmtpSettings extends React.Component {
  shouldComponentUpdate (nextProps, nextState) {
    return this.props.user !== nextProps.user
  }

  render () {
    const { language, muiTheme: { palette } } = this.context
    const { user, onSave, testSettings } = this.props

    return (
      <List style={{ padding: '0px 20px', backgroundColor: palette.accent2Color }}>
        <ListItem
          style={style.listItemStyle}
        >
          <ListItemText primary={
            <FormsyText
              fullWidth
              name='smtp-hostname'
              floatingLabelText={language.translate('application.hostName')}
              value={user.SMTP_Hostname}
              onBlur={(event) => {
                user.SMTP_Hostname = event.currentTarget.value
                onSave(user)
              }}
              validationErrors={language.messages.validationErrors}
            />
          }
          />
        </ListItem>
        <ListItem
          style={style.listItemStyle}
        >
          <ListItemText primary={
            <FormsyText
              fullWidth
              name='smtp-username'
              floatingLabelText={language.translate('application.userName')}
              value={user.SMTP_Username}
              onBlur={(event) => {
                user.SMTP_Username = event.currentTarget.value
                onSave(user)
              }}
              validationErrors={language.messages.validationErrors}
            />
          }
          />
        </ListItem>
        <ListItem
          style={style.listItemStyle}
        >
          <ListItemText primary={
            <FormsyText
              name='smtp-password'
              autoComplete='new-password'
              fullWidth
              type='password'
              floatingLabelText={language.translate('application.password')}
              value={user.SMTP_Password}
              onBlur={(event) => {
                user.SMTP_Password = event.currentTarget.value
                onSave(user)
              }}
              validationErrors={language.messages.validationErrors}
            />
          }
          />
        </ListItem>
        <ListItem
          style={style.listItemStyle}
        >
          <ListItemText primary={
            <FormsyText
              name='smtp-port-number'
              fullWidth
              floatingLabelText={language.translate('application.portNumber')}
              value={user.SMTP_PortNumber}
              onBlur={(event) => {
                user.SMTP_PortNumber = event.currentTarget.value
                onSave(user)
              }}
              validationErrors={language.messages.validationErrors}
            />
          }
          />
        </ListItem>
        <ListItem
          style={style.listItemStyle}
        >
          <ListItemText primary={
            <FormControlLabel
              control={<Checkbox
                checked={user.SMTP_EnableSSL}
                color='primary'
                onChange={() => {
                  user.SMTP_EnableSSL = !user.SMTP_EnableSSL
                  onSave(user)
                }}
              />}
              label={language.translate('application.enableSSL')}
            />
          }
          />
        </ListItem>
        <ListItem
          style={style.listItemStyle}
        >
          <ListItemText primary={
            <Button
              onClick={testSettings}
              color='primary'
              variant='contained'
              style={{ margin: '5px 0' }}
            >
              {language.translate('application.testSettings')}
            </Button>
          }
          />
        </ListItem>
      </List>
    )
  }
}

SmtpSettings.propTypes = {
  user: PropTypes.object,
  onSave: PropTypes.func,
  testSettings: PropTypes.func
}

SmtpSettings.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default SmtpSettings
