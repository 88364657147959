import { darken } from '@material-ui/core/styles/colorManipulator'
import palette from './palette'

export default {
  palette,
  typography: {
    fontFamily: 'Source Sans Pro, Roboto, sans-serif',
    useNextVariants: true
  },
  overrides: {
    MuiSnackbarContent: {
      action: {
        color: palette.accent5Color
      }
    },
    MuiButton: {
      root: {
        disabled: {
          color: darken('rgb(226, 226, 226)', 0)
        }
      }
    },
    MuiTableRow: {
      root: {
        '&$hover:hover': {
          backgroundColor: 'rgb(111,181,209)'
        },
        '&$selected': {
          backgroundColor: 'rgb(111,181,209)'
        }
      }
    },
    MuiDrawer: {
      paper: {
        '&::-webkit-scrollbar-track': {
          borderRadius: '6px'
        },
        '&::-webkit-scrollbar': {
          width: '10px',
          backgroundColor: 'transparent'
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '6px',
          backgroundColor: '#e2e2e2'
        }
      }
    }
  },
  props: {
    MuiPopover: {
      elevation: 2
    }
  }
}
