import PropTypes from 'prop-types'
import React from 'react'
import { Button, List } from '@material-ui/core'
import ScheduledDatabaseQueryListItem from './ScheduledDatabaseQueryListItem'
import { Add } from '@material-ui/icons'

class ScheduledDatabaseQueryList extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      scheduledQueryList: null
    }
  }

  componentDidMount () {
    this.setState({ scheduledQueryList: this.props.scheduledQueryList })
  }

  componentDidUpdate (prevProps) {
    if (this.props.scheduledQueryList !== prevProps.scheduledQueryList) { this.setState({ scheduledQueryList: this.props.scheduledQueryList }) }
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const { scheduledQueryList } = this.state
    const { onDelete, pushToDataSource } = this.props

    return (
      <div style={{ padding: '10px 20px', backgroundColor: palette.accent2Color }}>
        {(!scheduledQueryList || !scheduledQueryList.length)
          ? <div style={{ margin: '10px 20px' }}>{language.translate('application.noSchedules')}</div>
          : <div style={{ padding: '0px 20px', backgroundColor: palette.accent2Color }}>
            <List>
              {scheduledQueryList.map((item, index) => (
                <ScheduledDatabaseQueryListItem
                  item={item}
                  key={item.ID}
                  onRemoveField={() => onDelete(item)}
                  pushToDataSource={pushToDataSource}
                />
              ))}
            </List>
          </div>}
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
          <Button
            onClick={() => pushToDataSource()}
            color='primary'
            style={{ margin: '10px' }}
            variant='contained'
          >
            <Add />
            {language.translate('application.addItem')}
          </Button>
        </div>
      </div>
    )
  }
}

ScheduledDatabaseQueryList.propTypes = {
  scheduledQueryList: PropTypes.array,
  onSaveList: PropTypes.func,
  onDelete: PropTypes.func,
  pushToDataSource: PropTypes.func
}

ScheduledDatabaseQueryList.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default ScheduledDatabaseQueryList
