import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get } from '../../actions/base'
import FormsyAutoComplete from './FormsyAutoComplete'

class ExternalSource extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      ValueID: parseInt(this.props.value, 10) || '',
      ValueText: this.props.valueText,
      dataList: [],
      isLoaded: true
    }
  }

  componentDidMount () {
    this.fetchData('')
  }

  componentDidUpdate (prevProps) {
    const { value, valueText } = this.props

    if (prevProps.dependencyValue !== this.props.dependencyValue) {
      this.fetchData(valueText || '', !!(valueText === value))
    }
  }

  handleChange (option) {
    this.setState({
      ValueID: option.key,
      ValueText: option.text
    })

    if (this.props.onChange) { this.props.onChange(option.key, option.text) }
  }

  fetchData (text) {
    const { searchUrl, responseProperty, dependencyValue, dependencyId, searchOverride } = this.props
    this.setState({ isLoaded: false })

    let searchFilter = (searchOverride) ? searchOverride(text) : `text=${text}`

    let endpoint = `${searchUrl}?${searchFilter}`

    if (dependencyId && dependencyValue) {
      endpoint += `&${dependencyId}=${dependencyValue}`
    }

    this.props.get(endpoint, {
      onSuccess: (response) => {
        this.setState({
          dataList: response[responseProperty],
          isLoaded: true
        })
      }
    })
  }

  // TODO: add floating label and continue to test this

  render () {
    const { dispatch, get, onChange, value, valueText, searchUrl, responseProperty, valuePropertyName, textPropertyName, itemTemplate, dependencyValue, dependencyId, searchOverride, ...rest } = this.props
    const { dataList, isLoaded, ValueText, ValueID } = this.state
    const { language } = this.context
    const options = []

    if (isLoaded) {
      dataList.map((option) => {
        options.push(
          {
            key: (valuePropertyName) ? option[valuePropertyName] : option.Value,
            text: (textPropertyName) ? option[textPropertyName] : option.Text,
            value: (itemTemplate) ? itemTemplate(option) : (valuePropertyName) ? option[valuePropertyName] : option.Text
          }
        )
      })
    }

    return (
      <FormsyAutoComplete
        {...rest}
        dataSource={options}
        onNewRequest={this.handleChange.bind(this)}
        onUpdateInput={(text) => {
          setTimeout(() => {
            var lastCharacterOfSearch = text.substr(text.length - 1)
            var searchText = this.state.ValueText

            if (lastCharacterOfSearch === searchText.substr(searchText.length - 1) && text.length === searchText.length) {
              this.fetchData(text)
            }
          }, 500)

          this.setState({
            ValueID: text,
            ValueText: text
          })
        }}
        searchText={ValueText}
        value={ValueID}
        validationErrors={language.messages.validationErrors}
      />
    )
  }
}

ExternalSource.propTypes = {
  dispatch: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  valueText: PropTypes.string,
  searchUrl: PropTypes.string,
  responseProperty: PropTypes.string,
  required: PropTypes.bool,
  valuePropertyName: PropTypes.string,
  textPropertyName: PropTypes.string,
  itemTemplate: PropTypes.func,
  searchOverride: PropTypes.func,
  dependencyValue: PropTypes.string,
  dependencyId: PropTypes.string
}

ExternalSource.contextTypes = {
  language: PropTypes.object
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  dispatch,
  get: bindActionCreators(get, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ExternalSource)
