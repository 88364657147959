import PropTypes from 'prop-types'
import React from 'react'
import FormHelper from '../../businessLogic/formHelper'
import { IconButton, Tooltip } from '@material-ui/core'
import { FormsyText } from 'formsy-material-ui'
import { InsertLink } from '@material-ui/icons'
import objectAssign from 'object-assign'
import FieldWrapper from './FieldWrapper'

class Link extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      edit: ((!this.props.field.ProcessInstanceID || !this.props.field.Value) && this.props.editable) // if there is no instance ID, then default to showing the input field
    }
  }

  getHref () {
    const { field, url, type, isPublic, apiServer } = this.props
    const { user } = this.context

    switch (type) {
      case 'email':
        return `mailto:${url}`
      case 'phone':
        return `tel:${url}`
      case 'file':
        if (isPublic) { return field.PublicURL }
        return `${apiServer}/open.aspx?filekey=${field.PrivateFileKey}&token=${user.token}&email=${encodeURIComponent(user.userName)}&`

      default:
        return (url && url.substring(0, 4) !== 'http') ? `http://${url}` : url
    }
  }

  prepValidations () {
    const validations = []

    if (this.props.type === 'email') validations.push('isEmailMultiple:true')
    if (this.props.type === 'url') validations.push('isUrl:true')

    return (validations.length > 0) ? validations.join() : null
  }

  isImage (fileName) {
    if (fileName.match(/.(jpg|jpeg|png|gif)$/i)) {
      return true
    }

    return false
  }

  render () {
    const { palette } = this.context.muiTheme

    const href = this.getHref()
    const { edit } = this.state
    const { field, onChange, onBlur, type, wrapperProps, url, editable, apiServer, ...rest } = this.props

    return (
      <FieldWrapper
        label={FormHelper.decodeHTML(field.FieldName)}
        required={field.RequiredField}
        showDivider={(!edit)}
        {...wrapperProps}
      >
        <div className='input-style'>
          {(edit && !field.ReadOnlyField)
            ? <FormsyText
              required={field.RequiredField}
              fullWidth
              name={field.FieldName}
              value={FormHelper.decodeHTML(field.Value)}
              onChange={(event) => {
                if (onChange) {
                  onChange(objectAssign({}, field, { Value: event.currentTarget.value }))
                }
              }}
              onBlur={() => {
                if (field.Value) {
                  const parser = document.createElement('a')
                  parser.href = field.Value

                  if (type === 'url' && ['http:', 'https:'].indexOf(parser.protocol) >= 0 && !field.Value.substr(0, 6).includes('http')) { field.Value = `http://${field.Value}` }
                }

                if (onBlur) { onBlur(field) }
              }}
              validations={this.prepValidations()}
              validationErrors={this.context.language.messages.validationErrors}
              {...rest}
            />
            : <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flex: 5, paddingTop: '15px', width: '80%' }}>
                <a target='_blank' style={{ wordWrap: 'break-word' }} href={href}>{this.props.url}
                  {(this.props.url && this.isImage(this.props.url) &&
                    <div>
                      <img src={href + '&thumbnail=1&'} width={'50%'} />
                    </div>
                  )}</a>
              </div>
              {(!field.ReadOnlyField && this.props.editable)
                ? <div style={{ flex: 1 }}>
                  <Tooltip title='Edit'>
                    <IconButton
                      onClick={() => this.setState({ edit: true })}
                    >
                      <InsertLink nativeColor={palette.accent3Color} />
                    </IconButton>
                  </Tooltip>
                </div>
                : null}
            </div>
          }
        </div>
      </FieldWrapper>
    )
  }
}

Link.propTypes = {
  field: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  url: PropTypes.string,
  type: PropTypes.string,
  editable: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  isPublic: PropTypes.bool,
  wrapperProps: PropTypes.object,
  apiServer: PropTypes.string
}

Link.contextTypes = {
  muiTheme: PropTypes.object,
  language: PropTypes.object,
  user: PropTypes.object
}

export default Link
