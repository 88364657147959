import PropTypes from 'prop-types'
import React from 'react'
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { Print } from '@material-ui/icons'
import moment from 'moment'
import numeral from 'numeral'

class Invoice extends React.Component {
  print () {
    // window.print();
    const content = document.getElementById('invoice_contents')
    const invoiceContainer = document.getElementById('invoiceContainer').contentWindow

    invoiceContainer.document.open()
    invoiceContainer.document.write(content.innerHTML)
    invoiceContainer.document.close()
    invoiceContainer.focus()
    invoiceContainer.print()
    invoiceContainer.close()
  }

  generateInvoice () {
    const { lineItems, account, invoice } = this.props
    const { user, muiTheme, language } = this.context
    const { palette } = muiTheme

    let subtotal = 0
    lineItems.map((lineItem) => {
      subtotal += lineItem.LineItem_Amount
    })

    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
          <div>
            <img
              src='https://s3.amazonaws.com/ppwebsitefiles/pp_black_250w.png'
              style={{ maxWidth: '200px' }}
            />
            <div style={{ marginTop: '10px' }}>
              <div>ProcessPlan, LLC</div>
              <div>3256 North Valdosta Rd.</div>
              <div>Valdosta, GA 31605</div>
              <div>855-336-2399</div>
            </div>
          </div>
          <div>
            <div style={{
              textAlign: 'center',
              fontSize: '20px',
              fontWeight: 600
            }}
            >{language.translate('application.invoice').toUpperCase()}</div>
            <div>{language.translate('application.invoiceNumber')}: {invoice.Invoice_Number}</div>
            <div>{language.translate('application.issueDate')}: {moment(invoice.Invoice_Date).format(user.dateFormat)}</div>
            <div style={{ display: 'flex' }}>{language.translate('application.paymentTerms')}: <strong style={{
              color: palette.errorColor,
              paddingLeft: '5px'
            }}
            > {(invoice.Invoice_Paid) ? language.translate('application.paid') : language.translate('application.dueImmediately')}</strong>
            </div>
            <div>{language.translate('application.accountNumber')}: {account.ID}</div>
            {(account.VAT) ? <div>VAT: {account.VAT}</div> : null}
          </div>
        </div>
        <div style={{ marginBottom: '20px', paddingTop: '20px' }}>
          <div>
            <div><strong>{language.translate('application.billTo')}:</strong></div>
            <div style={{ paddingLeft: '20px' }}>
              <div>{account.AccountName}</div>
              <div>{account.BillingAddress1}</div>
              <div>{account.BillingCity}, {account.BillingState} {account.BillingPostalCode}</div>
              <div>{account.BillingCountry}</div>
            </div>
          </div>
        </div>
        <Table style={{ border: `1px solid ${palette.borderColor}`, marginBottom: '20px' }}>
          <TableHead>
            <TableRow>
              <TableCell style={{
                fontSize: '16px',
                fontWeight: 600
              }}
              >{language.translate('application.description')}</TableCell>
              <TableCell style={{
                textAlign: 'center',
                fontSize: '16px',
                fontWeight: 600
              }}
              >{language.translate('application.quantity')}</TableCell>
              <TableCell style={{
                textAlign: 'right',
                fontSize: '16px',
                fontWeight: 600
              }}
              >{language.translate('application.amount')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lineItems.map((lineItem, index) => (
              <TableRow key={index}>
                <TableCell>{lineItem.LineItem_Description}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{lineItem.LineItem_Quantity}</TableCell>
                <TableCell
                  style={{ textAlign: 'right' }}
                >{numeral(lineItem.LineItem_Amount / 100).format('$0,0.00')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0px 10px' }}>
          <div>{language.translate('application.subtotal')}:</div>
          <div>{numeral(subtotal / 100).format('$0,0.00')}</div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0px 10px' }}>
          <div>{language.translate('application.tax')} (0%):</div>
          <div>$0.00</div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0px 10px' }}>
          <div>{language.translate('application.amountDueInUSD')}:</div>
          <div>
            <strong style={{ borderBottom: '3px solid #666666' }}>
              {numeral(invoice.Invoice_Amount / 100).format('$0,0.00')}
            </strong>
          </div>
        </div>
        <div style={{ marginTop: '50px', textAlign: 'center' }}>
          <div>{language.translate('application.thankYouForUsingPPlan')}</div>
          <div>www.processplan.com</div>
          <div>855-336-2399</div>
          <div>help@processplan.com</div>
        </div>
      </div>
    )
  }

  render () {
    const { palette } = this.context.muiTheme
    const { language } = this.context

    return (
      <div>
        <Button
          onClick={() => this.print()}
          color='primary'
          variant='contained'
          style={{ marginBottom: '10px' }}
        >
          <Print />
          {language.translate('application.print')}
        </Button>
        <iframe
          id='invoiceContainer'
          style={{ height: '0px', width: '0px', position: 'absolute', border: 'none' }}
        />
        <div id='invoice_contents' style={{ border: `1px solid ${palette.borderColor}`, padding: '10px' }}>
          {this.generateInvoice()}
        </div>
      </div>
    )
  }
}

Invoice.propTypes = {
  invoice: PropTypes.object,
  lineItems: PropTypes.array,
  account: PropTypes.object
}

Invoice.contextTypes = {
  user: PropTypes.object,
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default Invoice
