import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'react-router-redux'
import { Button } from '@material-ui/core'
import objectAssign from 'object-assign'
import { get } from '../actions/base'
import { updateTitle } from '../actions/application'
import Template from '../components/Statistics/Template'
import Task from '../components/Statistics/Task'
import User from '../components/Statistics/User'
import UserBacklog from '../components/Statistics/UserBacklog'
import FilterPanel from '../components/Statistics/FilterPanel'
import DateHelper from '../businessLogic/dateHelper'

class Statistics extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      filterPanelOpen: false,
      by: props.location.query.by,
      time: props.location.query.time,
      templateLoaded: false,
      taskList: [],
      tasksLoaded: false,
      userList: [],
      usersLoaded: false,
      userGroupList: [],
      userGroupsLoaded: false
    }
  }

  componentDidMount () {
    this.initialize()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.location.query.time !== prevProps.location.query.time) {
      this.setState({ time: this.props.location.query.time })
    }

    if (this.props.location.query.by !== prevProps.location.query.by) {
      this.setState({ by: this.props.location.query.by })
    }

    if (this.props.location.query.type !== prevProps.location.query.type) {
      this.initialize()
    }

    if (this.props.location.query.type === 'template' && !this.props.location.query.templateId && prevProps.location.query.templateId) { this.setState({ filterPanelOpen: true }) }

    if (this.props.location.query.type === 'task' && !this.props.location.query.templateId && !this.props.location.query.taskId && prevProps.location.query.taskId && prevProps.location.query.templateId) { this.setState({ filterPanelOpen: true }) }

    if (this.props.location.query.type === 'user' && !this.props.location.query.userId && prevProps.location.query.userId) { this.setState({ filterPanelOpen: true }) }
  }

  initialize () {
    const { type, templateId, userId, taskId, userGroupId } = this.props.location.query
    const { language } = this.context
    let title = ''
    let { filterPanelOpen } = this.state

    switch (type) {
      case 'template':
        title = language.translate('application.process')
        if (!templateId) { filterPanelOpen = true }
        break
      case 'task':
        title = language.translate('application.task')
        if (templateId) { this.fetchTasks() }
        if (!templateId || !taskId) { filterPanelOpen = true }
        break
      case 'user':
        title = language.translate('application.user')
        this.fetchUsers()
        if (!userId) { filterPanelOpen = true }
        break
      case 'user_backlog':
        title = language.translate('application.userBacklog')
        this.fetchUserGroups()
        if (!userGroupId) { filterPanelOpen = true }
        break
    }

    this.props.dispatch(updateTitle(`${title} ${language.translate('application.statistics').lcFirst()}`))

    this.setState({ filterPanelOpen })
  }

  fetchUsers (filter) {
    const { get } = this.props

    let endpoint = 'user/list'

    if (filter) {
      endpoint += `?filter1=FullName(CONTAINS_TEXT)${filter}`
    }

    get(endpoint, {
      onSuccess: (response) => {
        this.setState({
          userList: response.UserList,
          usersLoaded: true
        })
      }
    })
  }

  fetchUserGroups (filter) {
    const { get } = this.props

    let endpoint = 'usergroup/list'

    if (filter) {
      endpoint += `?filter1=GroupName(CONTAINS_TEXT)${filter}`
    }

    get(endpoint, {
      onSuccess: (response) => {
        this.setState({
          userGroupList: response.UserGroupList,
          userGroupsLoaded: true
        })
      }
    })
  }

  fetchTasks (templateId = this.props.location.query.templateId) {
    const { get } = this.props

    get(`processtemplate/${templateId}/task/list`, {
      onSuccess: (response) => {
        this.setState({
          taskList: response.ProcessTemplateTaskList,
          tasksLoaded: true
        })
      }
    })
  }

  handleFilterChange (filter) {
    const { push, location } = this.props
    const { type } = location.query

    const newQuery = {
      start: filter.startDate,
      end: filter.endDate,
      by: filter.by,
      time: filter.time
    }

    switch (type) {
      case 'template':
        newQuery.templateId = filter.templateID
        break
      case 'task':
        newQuery.templateId = filter.templateID
        newQuery.taskId = filter.taskID
        break
      case 'user':
        newQuery.userId = filter.userID
        break
      case 'user_backlog':
        newQuery.userGroupId = filter.userGroupID
        break
    }

    push({
      pathname: location.pathname,
      query: objectAssign({},
        location.query,
        newQuery)
    })
  }

  handleUserClick (user) {
    const { push, location } = this.props
    const { time, by } = this.state

    push({
      pathname: location.pathname,
      query: objectAssign({},
        location.query,
        {
          type: 'user',
          userId: user.UserID,
          by: by || 'month',
          time: time || 'days'
        })
    })
  }

  handleTaskClick (task) {
    const { push, location } = this.props
    const { by, time } = this.state

    push({
      pathname: location.pathname,
      query: objectAssign({},
        location.query,
        {
          taskId: task.ProcessTemplateTaskID,
          type: 'task',
          templateId: task.ProcessTemplateID,
          by,
          time
        })
    })
  }

  handleProcessClick (process) {
    const { push, location } = this.props
    const { by, time } = this.state

    push({
      pathname: location.pathname,
      query: objectAssign({},
        location.query,
        {
          type: 'template',
          templateId: process.ProcessTemplateID,
          by,
          time
        })
    })
  }

  parseDateFilter (startDate, endDate) {
    let dateFilter = '?'
    const { user } = this.context

    if (startDate) { dateFilter += `startdate=${DateHelper.formatForAPI(startDate, user.dateFormat)}` }

    if (startDate && endDate) { dateFilter += '&' }

    if (endDate) { dateFilter += `enddate=${DateHelper.formatForAPI(`${endDate} 23:59:00`, `${user.dateFormat} H:mm:ss`, false)}` }

    return dateFilter
  }

  render () {
    const { filterPanelOpen, by, time, taskList, userList, userGroupList } = this.state
    const { query } = this.props.location
    const { language } = this.context
    const palette = this.context.muiTheme.palette

    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            variant='contained'
            style={{ margin: '10px', backgroundColor: palette.canvasColor }}
            onClick={() => this.setState({ filterPanelOpen: true })}
          >
            {language.translate('application.filter', [], true)}
          </Button>
          {(query.type !== 'user_backlog' &&
          <div style={{ color: palette.accent4Color }}>
            {language.translate('application.groupBy')} {by}
            | {language.translate('application.dataIn')} {time} {(query.start) ? ` | Starting on ${query.start}` : null} {(query.end) ? ` | ${language.translate('application.endingOn')} ${query.end}` : null}
          </div>)}
        </div>
        {(query.type === 'template')
          ? <Template
            templateId={query.templateId}
            startDate={query.start}
            endDate={query.end}
            by={by}
            time={time}
            parseDateFilter={this.parseDateFilter.bind(this)}
            handleUserClick={this.handleUserClick.bind(this)}
            handleTaskClick={this.handleTaskClick.bind(this)}
          />
          : null}

        {(query.type === 'task')
          ? <Task
            templateId={query.templateId}
            taskId={query.taskId}
            startDate={query.start}
            endDate={query.end}
            by={by}
            time={time}
            parseDateFilter={this.parseDateFilter.bind(this)}
            handleUserClick={this.handleUserClick.bind(this)}
          />
          : null}

        {(query.type === 'user' && by && time)
          ? <User
            userId={query.userId}
            startDate={query.start}
            endDate={query.end}
            by={by}
            time={time}
            parseDateFilter={this.parseDateFilter.bind(this)}
            handleProcessClick={this.handleProcessClick.bind(this)}
            handleTaskClick={this.handleTaskClick.bind(this)}
          />
          : null}

        {(query.type === 'user_backlog')
          ? <UserBacklog
            userGroupId={query.userGroupId}
            startDate={query.start}
            endDate={query.end}
            by={by}
            time={time}
            parseDateFilter={this.parseDateFilter.bind(this)}
            handleUserClick={this.handleUserClick.bind(this)}
          />
          : null}

        {(filterPanelOpen)
          ? <FilterPanel
            open={filterPanelOpen}
            close={() => this.setState({ filterPanelOpen: false })}
            startDate={query.start}
            endDate={query.end}
            by={by}
            time={time}
            type={query.type}
            userList={userList}
            userGroupList={userGroupList}
            taskList={taskList}
            userID={query.userId}
            onUserSearch={this.fetchUsers.bind(this)}
            onUserGroupSearch={this.fetchUserGroups.bind(this)}
            taskID={query.taskId}
            templateID={query.templateId}
            userGroupID={query.userGroupId}
            onTemplateSelect={(templateId) => {
              if (query.type === 'task') { this.fetchTasks(templateId) }
            }}
            onSubmit={this.handleFilterChange.bind(this)}
          />
          : null}
      </div>
    )
  }
}

Statistics.propTypes = {
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.string,
  push: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  location: PropTypes.object,
  mainContentWidth: PropTypes.number
}

Statistics.contextTypes = {
  muiTheme: PropTypes.object,
  language: PropTypes.object,
  user: PropTypes.object
}

const mapStateToProps = state => ({
  title: state.application.title,
  mainContentWidth: state.application.mainContentWidth
})

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  get: bindActionCreators(get, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Statistics)
