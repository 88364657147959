import PropTypes from 'prop-types'
import React from 'react'
import FormHelper from '~/businessLogic/formHelper'
import objectAssign from 'object-assign'
import {
  Button, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Menu, MenuItem
} from '@material-ui/core'
import { Add, MoreVert } from '@material-ui/icons'
import AccessControlEdit from './AccessControlEdit'
import DeleteMenuItem from '../Layout/DeleteMenuItem'
import HelpIcon from '../Layout/HelpIcon'

class AccessControlList extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      isLoaded: false,
      accessControlDialogOpen: false,
      editingItem: null,
      editingItemIndex: null,
      menuAnchor: null,
      menuAnchorIndex: null
    }
  }

  handleDelete (index) {
    const list = this.props.accessControlList

    this.props.onDelete(list[index])
  }

  getReadablePermissions (item) {
    const { language } = this.context
    const permissions = []

    if (item.Access_ViewTemplate) { permissions.push(language.translate('application.view')) }

    if (item.Access_EditTemplate) { permissions.push(language.translate('application.edit')) }

    if (item.Access_StartTemplate) { permissions.push(language.translate('application.launch')) }

    return permissions.join(', ')
  }

  handleSave (item) {
    // TODO: editing an item updates the props directly instead of waiting for the api response to update them
    const acl = this.props.accessControlList.slice(0)
    const { editingItemIndex } = this.state
    const { template, onSaveList, onSaveTemplate } = this.props

    if (editingItemIndex === -1) {
      template.DefaultAccess_View = item.Access_ViewTemplate
      template.DefaultAccess_Edit = item.Access_EditTemplate
      template.DefaultAccess_Launch = item.Access_StartTemplate

      onSaveTemplate(template)
    } else {
      if (item.ID) {
        acl[editingItemIndex] = objectAssign({}, item)
      } else {
        acl.push(item)
      }

      onSaveList(acl)
    }

    this.setState({
      accessControlDialogOpen: false,
      editingItem: null,
      editingItemIndex: null
    })
  }

  addNew () {
    const acl = this.props.accessControlList

    const newItem = {
      Access_EditTemplate: true,
      Access_StartTemplate: true,
      Access_ViewTemplate: true,
      ProcessTemplateID: this.props.templateId
    }

    this.setState({
      accessControlDialogOpen: true,
      editingItem: newItem,
      editingItemIndex: acl.length
    })
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const { accessControlList, template } = this.props
    const { menuAnchor, menuAnchorIndex } = this.state

    const defaultAccess = {
      Access_ViewTemplate: template.DefaultAccess_View,
      Access_EditTemplate: template.DefaultAccess_Edit,
      Access_StartTemplate: template.DefaultAccess_Launch
    }

    return (
      <List style={{ padding: '0px 20px', backgroundColor: palette.accent2Color }}>
        <ListItem>
          <ListItemText
            primary={language.translate('application.defaultAccess')}
            secondary={this.getReadablePermissions(defaultAccess)} />
          <ListItemSecondaryAction>
            <IconButton
              onClick={(e) => {
                this.setState({
                  menuAnchor: e.currentTarget,
                  menuAnchorIndex: -1
                })
              }}><MoreVert /></IconButton>
            <Menu
              anchorEl={menuAnchor}
              open={Boolean(menuAnchor) && menuAnchorIndex === -1}
              onClose={() => { this.setState({ menuAnchor: null, menuAnchorIndex: null }) }}
              getContentAnchorEl={null}
              anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
              <MenuItem
                onClick={() => this.setState({
                  accessControlDialogOpen: true,
                  editingItem: defaultAccess,
                  editingItemIndex: -1,
                  menuAnchor: null,
                  menuAnchorIndex: null
                })}
              >
                {language.translate('application.edit')}
              </MenuItem>
            </Menu>
          </ListItemSecondaryAction>
        </ListItem>
        {
          accessControlList.map((item, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={FormHelper.decodeHTML((item.User_FullName) ? item.User_FullName : item.UserGroup_Name)}
                secondary={this.getReadablePermissions(item)} />
              <ListItemSecondaryAction>
                <IconButton
                  onClick={(e) => {
                    this.setState({
                      menuAnchor: e.currentTarget,
                      menuAnchorIndex: index
                    })
                  }}><MoreVert /></IconButton>
                <Menu
                  anchorEl={menuAnchor}
                  open={Boolean(menuAnchor) && menuAnchorIndex === index}
                  onClose={() => { this.setState({ menuAnchor: null, menuAnchorIndex: null }) }}
                  getContentAnchorEl={null}
                  anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                >
                  <MenuItem
                    onClick={() => this.setState({
                      accessControlDialogOpen: true,
                      editingItem: item,
                      editingItemIndex: index,
                      menuAnchor: null,
                      menuAnchorIndex: null
                    })}
                  >
                    {language.translate('application.edit')}
                  </MenuItem>
                  <DeleteMenuItem
                    onDelete={() => {
                      this.setState({ menuAnchor: null, menuAnchorIndex: null })
                      this.handleDelete(index)
                    }}
                  />
                </Menu>
              </ListItemSecondaryAction>
            </ListItem>
          ))
        }
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Button
            onClick={this.addNew.bind(this)}
            color='primary'
            variant='contained'
            style={{ margin: '10px' }}
          >
            <Add />
            {language.translate('application.add')}
          </Button>
          <HelpIcon helpUrl={'editing-a-process-template$AccessControl'} />
        </div>
        <AccessControlEdit
          accessControlItem={this.state.editingItem}
          isDefaultAccess={(this.state.editingItemIndex === -1)}
          onSave={this.handleSave.bind(this)}
          isOpen={this.state.accessControlDialogOpen}
          onCloseRequest={() => this.setState({
            accessControlDialogOpen: false,
            editingItem: null,
            editingItemIndex: null
          })}
        />
      </List>
    )
  }
}

AccessControlList.propTypes = {
  accessControlList: PropTypes.array,
  templateId: PropTypes.string.isRequired,
  template: PropTypes.object.isRequired,
  onSaveTemplate: PropTypes.func,
  onSaveList: PropTypes.func,
  onDelete: PropTypes.func
}

AccessControlList.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default AccessControlList
