import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  CircularProgress, Card, CardHeader, Table, TableBody, TableCell, TableHead, TableRow, IconButton, Menu, MenuItem
} from '@material-ui/core'
import { get } from '../actions/base'
import { push } from 'react-router-redux'
import objectAssign from 'object-assign'
import { updateTitle } from '../actions/application'
import { MoreVert } from '@material-ui/icons'
import moment from 'moment'
import FormHelper from '~/businessLogic/formHelper'
import DataList from '../components/Layout/DataList'

class ProcessHistory extends Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      history: [],
      process: {},
      processLoaded: false,
      menuAnchorElement: null
    }
  }

  componentDidMount () {
    const { updateTitle } = this.props
    const { language } = this.context

    updateTitle(language.translate('application.processHistory'))

    this.fetchProcess()
  }

  fetchProcess () {
    const processid = this.props.params.processid
    const { get } = this.props

    get(`processinstance/${processid}`, {
      onSuccess: (response) => {
        this.setState({
          process: response.ProcessInstance,
          processLoaded: true
        })
      }
    })
  }

  getProcessHeaderFields () {
    const { process } = this.state
    const { language } = this.context

    const items = [
      <div
        key={0}
        style={{ fontWeight: 700 }}
      >
        {`${language.translate('application.processStartDate')}: ${moment(process.CreatedDate_Local).format('ddd, MMM D, YYYY')}`}
      </div>
    ]

    for (let i = 1; i <= process.NumberOfHeaderFieldsToDisplay; i++) {
      if (process[`HeaderField${i}_Name`]) {
        items.push(
          <div key={i} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
            {
              `${FormHelper.decodeHTML(process[`HeaderField${i}_Name`])}: ${
                FormHelper.decodeHTML(process[`HeaderField${i}_Value`])}`
            }
          </div>)
      }
    }

    return (
      <div>
        {
          items.map(item => item)
        }
      </div>
    )
  }

  render () {
    const { history, process, processLoaded, menuAnchorElement } = this.state
    const { mainContentWidth, push, params: { processid } } = this.props
    const { location, language } = this.context

    const headerFields = this.getProcessHeaderFields()

    return (
      (!processLoaded)
        ? <CircularProgress className='loader' />
        : <DataList
          url={`processinstance/${processid}/task/list`}
          dataCallback={(data) => {
            this.setState({
              history: data
            })
          }}
          disableSearch
          responseProperty='ProcessInstanceTaskList'
          noDataText={language.translate('application.noHistory')}
          location={location}
        >
          <Card className='card' style={{ width: '100%' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-start'
              }}
            >
              <CardHeader
                title={process.ProcessTemplateTitle}
                subheader={headerFields}
                style={{ padding: '8px', width: '90%' }}
              />
              <IconButton
                aria-label='More'
                aria-owns={(menuAnchorElement) ? 'menu' : null}
                aria-haspopup='true'
                onClick={(e) => { this.setState({ menuAnchorElement: e.currentTarget }) }}
              >
                <MoreVert />
              </IconButton>
              <Menu
                anchorEl={menuAnchorElement}
                open={Boolean(menuAnchorElement)}
                onClose={() => { this.setState({ menuAnchorElement: null }) }}
                getContentAnchorEl={null}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              >
                <MenuItem
                  onClick={() => { push(`/process-visual-progress/${process.ID}`) }}
                >{language.translate('application.viewProgress')}</MenuItem>
                <MenuItem
                  onClick={() => { push(`/process-template/${process.ProcessTemplateID}`) }}
                >{language.translate('application.processTemplate')}</MenuItem>
              </Menu>
            </div>
            <Table>
              {(mainContentWidth > 750 &&
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{ width: '25%' }}
                    >{language.translate('application.task')}</TableCell>
                    <TableCell
                      style={{ width: '25%' }}
                    >{language.translate('application.response')}</TableCell>
                    <TableCell
                      style={{ width: '25%' }}
                    >{language.translate('application.notes')}</TableCell>
                    <TableCell style={{ width: '150px' }}>
                      {language.translate('application.responseBy')}
                    </TableCell>
                  </TableRow>
                </TableHead>
              )}
              <TableBody>
                {
                  history.map((task) => (
                    <TableRow
                      hover
                      key={task.ID}
                      className='clickable'
                      onClick={() => {
                        push({
                          pathname: location.pathname,
                          query: objectAssign({}, location.query, { ptype: 'task', task: task.ID, pid: task.ID })
                        })
                      }}
                      selected={(task.ID === location.query.pid)}
                    >
                      {(mainContentWidth > 750 &&
                        <TableCell style={{ width: '25%' }}>
                          {task.TaskText_Display}
                        </TableCell>
                      )}
                      {(mainContentWidth > 750 &&
                        <TableCell style={{ width: '25%' }}>
                          {task.SelectedResponse_Text}
                        </TableCell>
                      )}
                      {(mainContentWidth > 750 &&
                        <TableCell style={{ width: '25%' }}>
                          {task.Notes}
                        </TableCell>
                      )}
                      {(mainContentWidth > 750 &&
                        <TableCell style={{ width: '150px' }}>
                          {(task.Completed_Date_Local)
                            ? <div>
                              {task.Completed_FullName}
                              <br /> {moment(task.Completed_Date_Local).format('ddd, MMM D, YYYY')}
                            </div>
                            : null}
                        </TableCell>
                      )}

                      {(mainContentWidth <= 750 &&
                        <TableCell
                          style={{ paddingTop: '15px', width: '100%' }}
                        >
                          <ul style={{ listStyle: 'none' }}>
                            <li>
                              <strong>{language.translate('application.task')}: </strong>{task.TaskText_Display}
                            </li>
                            <li>
                              <strong>{language.translate('application.response')}: </strong>{task.SelectedResponse_Text}
                            </li>
                            <li><strong>{language.translate('application.notes')}: </strong>{task.Notes}</li>
                            <li>
                              <strong>{language.translate('application.responseBy')}: </strong>{task.Completed_FullName}
                              - {moment(task.Completed_Date_Local).format('ddd, MMM D, YYYY')}
                            </li>
                          </ul>
                        </TableCell>
                      )}
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </Card>
        </DataList>
    )
  }
}

ProcessHistory.propTypes = {
  params: PropTypes.object.isRequired,
  get: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  updateTitle: PropTypes.func.isRequired,
  mainContentWidth: PropTypes.number.isRequired
}

ProcessHistory.contextTypes = {
  location: PropTypes.object,
  language: PropTypes.object
}

const mapStateToProps = state => ({
  mainContentWidth: state.application.mainContentWidth
})

const mapDispatchToProps = dispatch => ({
  get: bindActionCreators(get, dispatch),
  push: bindActionCreators(push, dispatch),
  updateTitle: bindActionCreators(updateTitle, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ProcessHistory)
