import PropTypes from 'prop-types'
import React from 'react'
import { Avatar, IconButton, Tooltip } from '@material-ui/core'
import { RemoveCircle } from '@material-ui/icons'

class DeleteIconButton extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      confirm: false
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (!prevState.confirm && this.state.confirm) {
      setTimeout(() => {
        if (this.state.confirm) {
          this.setState({ confirm: false })
        }
      }, 3000)
    }
  }

  render () {
    const { language, muiTheme: { palette } } = this.context
    const { onDelete, onClick, ...rest } = this.props
    const { confirm } = this.state

    return (
      <Tooltip title={(confirm) ? language.translate('application.confirm') : language.translate('application.delete')}>
        <IconButton
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            if (confirm) {
              this.setState({ confirm: false }, onDelete)
            } else {
              this.setState({ confirm: true })
            }
          }}
          {...rest}
        >
          {(confirm)
            ? <Avatar style={{ backgroundColor: palette.errorColor, width: '20px', height: '20px', fontSize: '18px' }}>
              ?
            </Avatar>
            : <RemoveCircle nativeColor={palette.errorColor} />
          }
        </IconButton>
      </Tooltip>
    )
  }
}

DeleteIconButton.propTypes = {
  onDelete: PropTypes.func.isRequired
}

DeleteIconButton.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default DeleteIconButton
