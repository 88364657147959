import PropTypes from 'prop-types'
import React from 'react'
import keycode from 'keycode'
import { HOC } from 'formsy-react'
import AutoComplete from 'material-ui/AutoComplete'
import objectAssign from 'object-assign'
import { setMuiComponentAndMaybeFocus } from 'formsy-react/src/utils'

class FormsyAutoComplete extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: this.props.defaultValue || this.props.value || '',
      openDirection: 'down',
      showNoFilter: false
    }

    //componentWillMount migration start
    this.props.setValue(this.props.defaultValue || this.props.value || '')
    //componentWillMount migration end
  }

  // componentWillMount () {
  //   this.props.setValue(this.props.defaultValue || this.props.value || '')
  // }

  handleBlur(event) {
    this.props.setValue(event.currentTarget.value)
    if (this.props.onBlur) this.props.onBlur(event)
  }

  handleChange(event) {
    this.setState({
      value: event.value
    })

    if (this.props.onNewRequest) this.props.onNewRequest(event)
  }

  handleKeyDown(event) {
    this.setState({ showNoFilter: false })
    if (keycode(event) === 'enter') this.props.setValue(event.currentTarget.value)
    if (this.props.onKeyDown) this.props.onKeyDown(event, event.currentTarget.value)
  }

  // setMuiComponentAndMaybeFocus: setMuiComponentAndMaybeFocus,

  ignoreFilter(searchText, key) {
    if (this.state.showNoFilter) {
      return true
    } else {
      return AutoComplete.caseInsensitiveFilter(searchText, key)
    }
  }

  render() {
    const { openDirection } = this.state

    const {
      defaultValue, // eslint-disable-line no-unused-vars
      onFocus,
      menuStyle,
      menuProps,
      value, // eslint-disable-line no-unused-vars
      validationError, // eslint-disable-line no-unused-vars
      validationErrors, // eslint-disable-line no-unused-vars
      isRequired,
      setValue, // eslint-disable-line no-unused-vars
      setValidations, // eslint-disable-line no-unused-vars
      resetValue, // eslint-disable-line no-unused-vars
      getValue, // eslint-disable-line no-unused-vars
      hasValue, // eslint-disable-line no-unused-vars
      getErrorMessage, // eslint-disable-line no-unused-vars
      getErrorMessages, // eslint-disable-line no-unused-vars
      isFormDisabled, // eslint-disable-line no-unused-vars
      isValid, // eslint-disable-line no-unused-vars
      isPristine, // eslint-disable-line no-unused-vars
      isFormSubmitted, // eslint-disable-line no-unused-vars
      showRequired, // eslint-disable-line no-unused-vars
      showError, // eslint-disable-line no-unused-vars
      isValidValue, // eslint-disable-line no-unused-vars
      onBlur, // eslint-disable-line no-unused-vars
      filter,
      ignoreFilterOnFocus,
      ...rest
    } = this.props

    let newMenuProps = {}

    if (menuProps && menuProps.maxHeight) { newMenuProps = menuProps } else { newMenuProps = objectAssign({}, menuProps, { maxHeight: 300 }) }

    return (
      <AutoComplete
        {...rest}
        menuStyle={menuStyle}
        menuProps={newMenuProps}
        errorText={getErrorMessage()}
        filter={(ignoreFilterOnFocus) ? this.ignoreFilter.bind(this) : AutoComplete.caseInsensitiveFilter}
        openOnFocus
        onBlur={this.handleBlur.bind(this)}
        onNewRequest={this.handleChange.bind(this)}
        onFocus={(e) => {
          if (onFocus) {
            onFocus(e)
          }

          if (ignoreFilterOnFocus) {
            this.setState({ showNoFilter: true })
          }

          const pixelsFromTop = e.currentTarget.getBoundingClientRect().bottom
          const pixelWindowHeight = document.body.clientHeight

          if (pixelWindowHeight > newMenuProps.maxHeight * 2) {
            this.setState({ openDirection: (pixelWindowHeight - pixelsFromTop < newMenuProps.maxHeight) ? 'up' : 'down' })
          }
        }}
        onKeyDown={this.handleKeyDown.bind(this)}
        ref={setMuiComponentAndMaybeFocus}
        value={this.state.value}
        targetOrigin={{ vertical: (openDirection === 'down') ? 'top' : 'bottom', horizontal: 'left' }}
        anchorOrigin={{ vertical: (openDirection === 'down') ? 'bottom' : 'top', horizontal: 'left' }}
      />
    )
  }
}

FormsyAutoComplete.propTypes = {
  defaultValue: PropTypes.any,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onNewRequest: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  value: PropTypes.any,
  menuStyle: PropTypes.object,
  menuProps: PropTypes.object,
  validationError: PropTypes.string,
  validationErrors: PropTypes.object,
  setValue: PropTypes.func,
  isRequired: PropTypes.func,
  setValidations: PropTypes.func,
  resetValue: PropTypes.func,
  getValue: PropTypes.func,
  hasValue: PropTypes.func,
  getErrorMessage: PropTypes.func,
  getErrorMessages: PropTypes.func,
  isFormDisabled: PropTypes.func,
  isValid: PropTypes.func,
  isPristine: PropTypes.func,
  isFormSubmitted: PropTypes.func,
  showRequired: PropTypes.func,
  showError: PropTypes.func,
  isValidValue: PropTypes.func,
  filter: PropTypes.func,
  ignoreFilterOnFocus: PropTypes.bool
}

export default HOC(FormsyAutoComplete)
