import PropTypes from 'prop-types'
import React from 'react'
import FormHelper from '../../businessLogic/formHelper'
import { post } from '../../actions/base'
import FormsySignature from '../Forms/FormsySignature'
import FieldWrapper from './FieldWrapper'

class Signature extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      showSignatureDialog: false
    }
  }

  _handleChange (signature) {
    const { field, onChange } = this.props

    if (!signature) {
      this.resetSignature()
      return
    }

    field.ProcessInstanceFieldFileList = [signature]

    if (onChange) { onChange(field) }
  }

  removeSavedFile (fileId, index) {
    const body = JSON.stringify({})
    const { field, dispatch, onChange } = this.props

    dispatch(post(
      `processinstance/${field.ProcessInstanceID}/field/${field.ID}/file/${fileId}/delete`,
      body,
      {
        onSuccess: (response) => {
          field.ProcessInstanceFieldFileList.splice(index, 1)

          if (onChange) { onChange(field) }
        }
      }
    ))
  }

  removeUnsavedFile (index) {
    const { onChange, field } = this.props

    field.ProcessInstanceFieldFileList.splice(index, 1)

    if (onChange) { onChange(field) }
  }

  resetSignature () {
    const { field } = this.props

    if (!field.ProcessInstanceFieldFileList || !field.ProcessInstanceFieldFileList.length) { return }

    if (field.ProcessInstanceFieldFileList[0].ID) { this.removeSavedFile(field.ProcessInstanceFieldFileList[0].ID, 0) } else { this.removeUnsavedFile(0) }
  }

  render () {
    const { field, wrapperProps, onChange, ...rest } = this.props
    const fileList = field.ProcessInstanceFieldFileList || []

    return (
      <FieldWrapper
        label={FormHelper.decodeHTML(field.FieldName)}
        required={field.RequiredField}
        showDivider
        {...wrapperProps}
      >
        <FormsySignature
          name={field.FieldName}
          value={fileList[0]}
          readOnly={field.ReadOnlyField}
          onChange={this._handleChange.bind(this)}
          required={field.RequiredField}
          {...rest}
        />
      </FieldWrapper>
    )
  }
}

Signature.propTypes = {
  field: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  isPublic: PropTypes.bool,
  wrapperProps: PropTypes.object
}

Signature.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object,
  user: PropTypes.object
}

export default Signature
