import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import update from 'react-addons-update'
import { bindActionCreators } from 'redux'
import { get, post } from '../../actions/base'
import { Button, Card, TextField } from '@material-ui/core'
import CustomFieldItem from '../CustomFields/CustomFieldItem'
import { Add } from '@material-ui/icons'
import ReorderableList from '../Layout/ReorderableList'
import RightPanelContent from '../Layout/RightPanelContent'
import { showUndoMessage } from '../../actions/snackbar'

class EditProcessFields extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      isLoaded: false,
      fields: [],
      userGroups: [],
      userGroupsLoaded: false,
      processFieldTypes: [],
      processFieldTypesLoaded: false,
      editingItem: null,
      filter: '',
      tokens: [],
      tokensLoaded: false
    }
  }

  componentDidMount () {
    this.fetchProcessTemplateFields(this.props.templateId)
    this.fetchUserGroups()
    this.fetchProcessFieldTypes()
    this.fetchFieldTokens()
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (this.state.fields.length !== nextState.fields.length) { return true }

    if (this.state.isLoaded !== nextState.isLoaded) { return true }

    return true
  }

  componentDidUpdate (prevProps) {
    if (this.props.templateId !== prevProps.templateId) { this.fetchProcessTemplateFields(this.props.templateId) }
  }

  fetchProcessTemplateFields (templateId, callback) {
    this.props.get(`processtemplate/${templateId}/field/list/withoptions`, {
      onSuccess: (response) => {
        if (callback) {
          callback(response)
        } else {
          this.setState({
            isLoaded: true,
            fields: response.ProcessTemplateFieldList
          })
        }
      }
    })
  }

  fetchProcessFieldTypes () {
    this.props.get('processtemplate/field/type/list', {
      onSuccess: (response) => {
        this.setState({
          processFieldTypesLoaded: true,
          processFieldTypes: response.ProcessTemplateFieldTypeList
        })
      }
    })
  }

  fetchUserGroups () {
    this.props.get('usergroup/list', {
      onSuccess: (response) => {
        this.setState({
          userGroupsLoaded: true,
          userGroups: response.UserGroupList
        })
      }
    })
  }

  fetchFieldVisibilityList (templateId, fieldId, callback) {
    this.props.get(`processtemplate/${templateId}/field/${fieldId}/visibility/list`, {
      onSuccess: (response) => {
        if (callback) { callback(response.ProcessTemplateFieldVisibilityList) }
      }
    })
  }

  saveVisibilityList (list, fieldId, callback) {
    const body = JSON.stringify({
      ProcessTemplateFieldVisibilityList: list
    })

    this.props.post(
      `processtemplate/${this.props.templateId}/field/${fieldId}/visibility/list`,
      body,
      {
        onSuccess: (response) => {
          if (callback) { callback(response.ProcessTemplateFieldVisibilityList) }
        }
      }
    )
  }

  deleteVisibilityListItem (item, fieldId, callback) {
    const { language } = this.context

    const body = JSON.stringify({})

    this.props.post(
      `processtemplate/${this.props.templateId}/field/${fieldId}/visibility/${item.ID}/delete`,
      body,
      {
        onSuccess: (response) => {
          if (callback) {
            callback()
          }

          this.props.showUndoMessage(
            language.translate('application.visibilityRemoved'),
            () => {
              this.props.post(
                `processtemplate/${this.props.templateId}/field/${fieldId}/visibility/${item.ID}/delete/undo`,
                body,
                {
                  onSuccess: (response) => {
                    if (callback) {
                      callback()
                    }
                  }
                }
              )
            }
          )
        }
      }
    )
  }

  addNewField () {
    const { fields } = this.state
    const { language } = this.context

    const newItem = {
      FieldName: language.translate('application.newField'),
      FieldType: '1',
      ID: null,
      ProcessTemplateID: this.props.templateId,
      RequiredField: false,
      SecureField: false,
      UniqueField: false,
      SortNum: (fields.length < 1) ? 1 : fields[fields.length - 1].SortNum + 1
    }

    this.setState({
      fields: this.state.fields.concat(newItem),
      editingItem: fields.length
    }, () => this.saveField(null))
  }

  addNewFieldOption (fieldId, name) {
    const { fields } = this.state
    let fieldIndex = null

    fields.some((field, index) => {
      if (field.ID === fieldId) {
        fieldIndex = index
        return true
      }
    })

    const field = fields[fieldIndex]
    const options = field.FieldOptionList.slice()
    const displayOrder = (options.length) ? options[options.length - 1].DisplayOrder + 1 : 1

    options.push({
      OptionDescription: name,
      ProcessTemplateFieldID: field.ID,
      ProcessTemplateID: field.ProcessTemplateID,
      DisplayOrder: displayOrder
    })

    this.handleChange(field.ID, 'FieldOptionList', options)
  }

  handleChange (fieldId, property, value, save = true) {
    const { fields } = this.state

    let fieldIndex = null

    fields.some((field, index) => {
      if (field.ID === fieldId) {
        fieldIndex = index
        return true
      }
    })

    fields[fieldIndex][property] = value

    this.setState({ fields }, () => {
      if (save && fields[fieldIndex].FieldName) { this.saveField(fields[fieldIndex].ID) }
    })
  }

  removeField (ID) {
    const fields = this.state.fields.slice()
    const { language } = this.context
    const body = JSON.stringify({})
    let fieldIndexToRemove = null
    fields.some((field, index) => {
      if (field.ID === ID) {
        fieldIndexToRemove = index
        return true
      }
    })

    const fieldToRemove = fields.splice(fieldIndexToRemove, 1)[0]

    if (fieldToRemove.ID) {
      this.props.post(
        `processtemplate/${this.props.templateId}/field/${fieldToRemove.ID}/delete`,
        body,
        {
          onSuccess: (response) => {
            this.setState({ fields }, () => {
              this.props.showUndoMessage(
                language.translate('application.processFieldRemoved'),
                () => {
                  this.props.post(
                    `processtemplate/${this.props.templateId}/field/${fieldToRemove.ID}/delete/undo`,
                    body,
                    {
                      onSuccess: (response) => {
                        this.fetchProcessTemplateFields(this.props.templateId)
                      }
                    }
                  )
                }
              )
            })
          }
        }
      )
    } else {
      this.setState({ fields })
    }
  }

  reorderFields (fromIndex, toIndex) {
    const fields = this.state.fields
    const dragField = fields[fromIndex]

    this.setState(update(this.state, {
      fields: {
        $splice: [
          [fromIndex, 1],
          [toIndex, 0, dragField]
        ]
      }
    }))
  }

  reorderOptions (fieldId, optionFromIndex, optionToIndex) {
    const fields = this.state.fields.slice()
    let fieldIndex = null
    fields.some((field, index) => {
      if (field.ID === fieldId) {
        fieldIndex = index
        return true
      }
    })
    const options = fields[fieldIndex].FieldOptionList
    const dragField = options[optionFromIndex]

    options.splice(optionFromIndex, 1)
    options.splice(optionToIndex, 0, dragField)

    fields[fieldIndex].FieldOptionList = options

    this.setState({ fields })
  }

  deleteFieldOption (fieldId, optionIndex) {
    const fields = this.state.fields.slice()
    const { language } = this.context
    const body = JSON.stringify({})

    let fieldIndex = null
    fields.some((field, index) => {
      if (field.ID === fieldId) {
        fieldIndex = index
        return true
      }
    })

    const optionToRemove = fields[fieldIndex].FieldOptionList[optionIndex]

    if (optionToRemove.ID) {
      this.props.post(
        `processtemplate/${this.props.templateId}/field/${fields[fieldIndex].ID}/option/${optionToRemove.ID}/delete`,
        body,
        {
          onSuccess: (response) => {
            this.fetchProcessTemplateFields(this.props.templateId)

            this.props.showUndoMessage(
              language.translate('application.optionRemoved'),
              () => {
                this.props.post(
                  `processtemplate/${this.props.templateId}/field/${fields[fieldIndex].ID}/option/${optionToRemove.ID}/delete/undo`,
                  body,
                  {
                    onSuccess: (response) => {
                      this.fetchProcessTemplateFields(this.props.templateId)
                    }
                  }
                )
              }
            )
          }
        }
      )
    } else {
      const options = fields[fieldIndex].FieldOptionList
      options.splice(optionIndex, 1)
      fields[fieldIndex].FieldOptionList = options

      this.setState({ fields })
    }
  }

  updateDisplayProperties () {
    const { fields } = this.state

    fields.map((item, index) => {
      item.SortNum = index + 1
    })

    this.saveFields()
  }

  saveFields () {
    const body = JSON.stringify({
      ProcessTemplateFieldList: this.state.fields
    })

    this.props.post(`processtemplate/${this.props.templateId}/field/list/withoptions`, body, {
      onSuccess: (response) => {
        this.setState({
          isLoaded: true,
          fields: response.ProcessTemplateFieldList
        })
      },

      onError: (error) => {
        this.fetchProcessTemplateFields(this.props.templateId)
      }
    })
  }

  saveField (ID) {
    const { fields } = this.state
    let fieldIndexToSave = null

    if (ID) {
      fields.some((field, index) => {
        if (field.ID === ID) {
          fieldIndexToSave = index
          return true
        }
      })
    } else {
      fieldIndexToSave = fields.length - 1
    }

    const field = fields[fieldIndexToSave]

    const body = JSON.stringify({
      ProcessTemplateField: field
    })

    const endpoint = (field.ID) ? `/${field.ID}/withoptions` : ''

    this.props.post(`processtemplate/${this.props.templateId}/field${endpoint}`, body, {
      onSuccess: (response) => {
        fields[fieldIndexToSave] = response.ProcessTemplateField

        this.setState({
          isLoaded: true,
          fields
        })
      },

      onError: (error) => {
        this.fetchProcessTemplateFields(this.props.templateId)
      }
    })
  }

  fetchFieldTokens () {
    const { templateId, get } = this.props

    get(`processtemplate/${templateId}/fieldtoken/list`, {
      onSuccess: (response) => {
        const tokens = response.TextValueList

        this.setState({
          tokens,
          tokensLoaded: true
        })
      }
    })
  }

  render () {
    const { language, muiTheme: { palette } } = this.context
    const { userGroups, fields, processFieldTypes, editingItem, filter, tokens, tokensLoaded } = this.state

    const filteredFields = fields.filter((field) => {
      return (!filter || field.FieldName.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
    })

    return (
      <RightPanelContent
        title={language.translate('application.processField', [], true)}
        closePanel={() => this.props.close()}
      >
        <div style={{ padding: '15px' }}>
          {(fields.length > 10)
            ? <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
              <div
                style={{
                  width: '90%',
                  padding: '10px 10px',
                  backgroundColor: palette.canvasColor,
                  borderRadius: '10px',
                  border: `1px solid ${palette.borderColor}`
                }}>
                <TextField
                  name='fieldFilter'
                  style={{ width: '100%' }}
                  placeholder={language.translate('application.filterByFieldName')}
                  value={filter}
                  onChange={(event) => {
                    this.setState({ filter: event.currentTarget.value })
                  }}
                />
              </div>
            </div>
            : null}
          <Card className='card'>
            {(!filteredFields.length && tokensLoaded)
              ? <div style={{ margin: '10px' }}>{language.translate('application.noProcessFields')}</div>
              : <ReorderableList style={{ padding: '0px' }}>
                {filteredFields.map((field, index) => (
                  <CustomFieldItem
                    field={field}
                    key={field.ID}
                    index={index}
                    ref='process-field'
                    editMode={(editingItem === index)}
                    onItemClick={() => this.setState({ editingItem: index })}
                    onEndEdit={() => this.setState({ editingItem: null })}
                    onAddFieldOption={name => this.addNewFieldOption(field.ID, name)}
                    customFieldTypes={processFieldTypes}
                    onReorderOptionsRequest={this.reorderOptions.bind(this)}
                    onReorderRequest={this.reorderFields.bind(this)}
                    getFieldVisibility={this.fetchFieldVisibilityList.bind(this)}
                    saveVisibilityList={this.saveVisibilityList.bind(this)}
                    deleteVisibilityListItem={this.deleteVisibilityListItem.bind(this)}
                    disableDrag={(fields.length !== filteredFields.length)}
                    onRemoveField={() => this.removeField(field.ID)}
                    onRemoveFieldOption={this.deleteFieldOption.bind(this)}
                    onChange={(property, value, save) => this.handleChange(field.ID, property, value, save)}
                    onSave={() => this.saveField(field.ID)}
                    droppedItem={this.updateDisplayProperties.bind(this)}
                    style={{
                      borderBottom: `1px solid ${palette.borderColor}`
                    }}
                    showVisibilityEdit
                    userGroups={userGroups}
                    tokens={tokens}
                    getFieldTokens={this.fetchFieldTokens.bind(this)}
                    getTemplateFields={this.fetchProcessTemplateFields.bind(this)}
                  />
                ))}
              </ReorderableList>}
          </Card>
          <Button
            onClick={this.addNewField.bind(this)}
            color='primary'
            variant='contained'
          >
            <Add />
            {language.translate('application.addField')}
          </Button>
        </div>
      </RightPanelContent>
    )
  }
}

EditProcessFields.propTypes = {
  templateId: PropTypes.string.isRequired,
  fields: PropTypes.array,
  dispatch: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  showUndoMessage: PropTypes.func.isRequired
}

EditProcessFields.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  dispatch,
  post: bindActionCreators(post, dispatch),
  get: bindActionCreators(get, dispatch),
  showUndoMessage: bindActionCreators(showUndoMessage, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(EditProcessFields)
