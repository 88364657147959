import React from 'react'
import PropTypes from 'prop-types'
import Downshift from 'downshift'
import { List, AutoSizer } from 'react-virtualized'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Popper from '@material-ui/core/Popper'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'

function renderInput (inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput
        },
        ...InputProps
      }}
      {...other}
    />
  )
}

function renderSuggestion ({ key, suggestion, index, itemProps, highlightedIndex, selectedItem }) {
  const isHighlighted = highlightedIndex === index

  return (
    <MenuItem
      key={key}
      {...itemProps}
      selected={isHighlighted}
      component='div'
    >
      {suggestion.label}
    </MenuItem>
  )
}

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired
}

function getSuggestions (suggestions, value) {
  const inputValue = value.trim().toLowerCase()
  const inputLength = inputValue.length

  return inputLength === 0
    ? suggestions
    : suggestions.filter(suggestion => (suggestion.label.toLowerCase().includes(inputValue)))
}

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  container: {
    flexGrow: 1,
    position: 'relative'
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`
  },
  inputRoot: {
    flexWrap: 'wrap'
  },
  divider: {
    height: theme.spacing.unit * 2
  }
})

let popperNode

class AutoComplete extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      inputValue: '',
      menuItems: props.items
    }
  }

  render () {
    const {
      classes, items, onChange, hintText, label, inputRef, autoFocus, menuHeight, inputOverride, searchText, inputProps,
      openOnFocus, clearOnSelect, ...rest
    } = this.props
    const { inputValue, menuItems } = this.state

    return (
      <div className={classes.root}>
        <Downshift
          id='downshift-popper'
          inputValue={searchText || inputValue}
          itemCount={menuItems.length}
          itemToString={item => (item ? item.label : '')}
          onChange={(selectedItem) => {
            onChange(selectedItem)

            if (clearOnSelect) {
              this.setState({ inputValue: '', menuItems: getSuggestions(items, '') })
            }
          }}
          onInputValueChange={(inputValue) => {
            if (inputOverride) {
              inputOverride(inputValue)
              this.setState({ menuItems: getSuggestions(items, '') })
            } else {
              this.setState({ inputValue, menuItems: getSuggestions(items, inputValue) })
            }
          }}
          {...rest}
        >
          {(
            {
              getInputProps,
              getItemProps,
              getMenuProps,
              highlightedIndex,
              isOpen,
              selectedItem,
              openMenu
            }) =>
            (
              <div className={classes.container}>
                {renderInput({
                  fullWidth: true,
                  classes,
                  autoFocus: (autoFocus),
                  InputProps: getInputProps({
                    placeholder: hintText || null,
                    label: label || null,
                    onFocus: () => { openMenu() },
                    ...inputProps
                  }),
                  ref: node => {
                    popperNode = node

                    if (inputRef && node) {
                      inputRef(node)
                    }
                  }
                })}
                <div {...getMenuProps({ onMouseDown: (e) => (e.preventDefault()) })}>
                  <Popper open={isOpen && openOnFocus} anchorEl={popperNode} modifiers={{ flip: { enabled: false } }}
                          style={{ zIndex: 3000 }}>
                    <Paper square style={{ width: popperNode ? popperNode.clientWidth : null }}>
                      <AutoSizer disableHeight>
                        {({ width }) => (
                          <List
                            width={width}
                            scrollToIndex={highlightedIndex || 0}
                            height={Math.min(menuItems.length * 52, menuHeight || 200)}
                            rowCount={menuItems.length}
                            rowHeight={48}
                            containerStyle={{ overflow: 'initial', maxWidth: '100%' }}
                            rowRenderer={({ key, index, style }) => {
                              let suggestion = menuItems[index]
                              return (renderSuggestion({
                                key,
                                suggestion,
                                index,
                                itemProps: getItemProps({
                                  item: menuItems[index],
                                  index,
                                  style: {
                                    ...style,
                                    fontWeight:
                                      selectedItem === menuItems[index] ? 500 : 400,
                                    width: 'fit-content',
                                    minWidth: '100%'
                                  }
                                }),
                                highlightedIndex,
                                selectedItem,
                                style
                              }))
                            }}
                          />
                        )}
                      </AutoSizer>
                    </Paper>
                  </Popper>
                </div>
              </div>
            )}
        </Downshift>
      </div>
    )
  }
}

AutoComplete.propTypes = {
  items: PropTypes.array,
  value: PropTypes.string,
  label: PropTypes.string,
  searchText: PropTypes.string,
  hintText: PropTypes.string,
  onChange: PropTypes.func,
  inputOverride: PropTypes.func,
  inputRef: PropTypes.func,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  inputProps: PropTypes.object,
  autoFocus: PropTypes.bool,
  clearOnSelect: PropTypes.bool,
  openOnFocus: PropTypes.bool,
  menuHeight: PropTypes.number
}

AutoComplete.defaultProps = {
  openOnFocus: true
}

export default withStyles(styles, { withTheme: true })(AutoComplete)
