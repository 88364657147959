import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import update from 'react-addons-update'
import { bindActionCreators } from 'redux'
import { Base64 } from 'js-base64'
import {
  Drawer, withStyles, IconButton, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Typography
} from '@material-ui/core'
import {
  AccountBalance, WorkOutline, CheckCircle, Code, Dashboard, Description, GroupWork, Help,
  HelpOutline, Home, Input, List as ListIcon, Payment, Receipt, Settings, Warning,
  Repeat, Favorite, Add, GridOn, Label, Store, People,
  LiveHelp, Storage, InsertChart, ShowChart, Timer, TimerOff,
  DirectionsRun, SelectAll, Sync, Group, Person, LocalLibrary, TextFormat, Schedule
} from '@material-ui/icons'
import { push } from 'react-router-redux'
import { toggleNavigation } from '~/actions/navigation'
import { updateUserSession } from '~/actions/authentication'
import objectAssign from 'object-assign'
import FavoriteList from './Navigation/FavoriteList'
import { post } from '~/actions/base'
import { showSnackbar } from '~/actions/snackbar'
import NestedListItem from './NestedListItem'

const styles = theme => ({
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    root: {
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgb(76, 81, 82)'
      }
    }
  },
  nested: {
    paddingLeft: theme.spacing.unit * 2
  }
})

class Navigation extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      favoritesOpen: true
    }
  }

  navigateTo (route) {
    if (typeof route === 'string' && route.startsWith('http')) { window.open(route, '_blank') } else { this.props.dispatch(push(route)) }

    if (document.body.clientWidth <= 768 && this.props.isOpen) { this.props.dispatch(toggleNavigation()) }
  }

  addPageToUserFavorites (e) {
    const { location: { pathname, search }, userCustomizations, updateUserSession, pageTitle, showSnackbar } = this.props
    const { language } = this.context
    const { favoritesOpen } = this.state
    const url = pathname + search

    const customizations = objectAssign({}, userCustomizations)
    customizations.favorites = customizations.favorites || []

    const newFavorite = {
      displayText: pageTitle,
      url: url,
      edit: true
    }

    if (customizations.favorites.filter((favorite) => (favorite.url === newFavorite.url)).length) {
      showSnackbar(language.translate('application.duplicateFavoritesNotAllowed'))
      return
    }

    // if favorites list is collapsed, make sure it is open
    if (!favoritesOpen) {
      this.setState({ favoritesOpen: true })
    }

    customizations.favorites.push(newFavorite)

    updateUserSession('userCustomizations', customizations, true)

    this.saveUserSettings(customizations)
  }

  removeFavorite (favorite) {
    const { userCustomizations, userCustomizations: { favorites = [] }, updateUserSession } = this.props
    let customizations = objectAssign({}, userCustomizations)

    const newFavorites = favorites.filter(favoriteItem => (JSON.stringify(favorite) !== JSON.stringify(favoriteItem)))

    customizations.favorites = newFavorites

    updateUserSession('userCustomizations', customizations, true)

    this.saveUserSettings(customizations)
  }

  updateFavorite (value, index) {
    const { userCustomizations, updateUserSession } = this.props
    let customizations = objectAssign({}, userCustomizations)

    customizations.favorites[index].displayText = value
    delete customizations.favorites[index].edit

    updateUserSession('userCustomizations', customizations, true)

    this.saveUserSettings(customizations)
  }

  enableFavoriteRename (index) {
    const { userCustomizations, updateUserSession } = this.props
    let customizations = objectAssign({}, userCustomizations)
    // let customizations = userCustomizations

    customizations.favorites[index].edit = true

    updateUserSession('userCustomizations', customizations, true)
  }

  reorderFavorites (fromIndex, toIndex) {
    const { userCustomizations, updateUserSession } = this.props
    const { favorites } = userCustomizations
    const dragField = favorites[fromIndex]

    let newUserCustomizations = update(this.props.userCustomizations, {
      favorites: {
        $splice: [
          [fromIndex, 1],
          [toIndex, 0, dragField]
        ]
      }
    })

    updateUserSession('userCustomizations', newUserCustomizations, true)
    this.saveUserSettings(newUserCustomizations)
  }

  saveUserSettings (customizations) {
    const { user } = this.props

    const body = JSON.stringify({
      User: { Customizations: JSON.stringify(customizations) }
    })

    this.props.post(`user/${user.userid}`, body)
  }

  getListItem ({ onClick, icon, iconStyle, text, nestedList, hidden }) {
    if (hidden) {
      return
    }

    const { muiTheme: { palette } } = this.context
    const { classes } = this.props
    const styles = {
      listItemStyle: {
        color: palette.navigationFontColor
      },
      leftIconStyle: {
        color: palette.navigationFontColor
      }
    }

    return (
      nestedList
        ? <NestedListItem
          style={{ paddingRight: '12px' }}
          button
          key={text}
          onClick={() => {
            if (onClick) {
              onClick()
            }
          }}
          nestedList={
            <List disablePadding className={classes.nested}>
              {nestedList.map(listItem => (this.getListItem(listItem)))}
            </List>
          }
        >
          <ListItemIcon style={{ color: styles.leftIconStyle.color, ...iconStyle }}>{icon}</ListItemIcon>
          <ListItemText
            style={{ paddingLeft: '0px' }}
            primaryTypographyProps={{ style: styles.listItemStyle }}
            primary={text} />
        </NestedListItem>
        : <ListItem
          style={{ paddingRight: '10px' }}
          button
          key={text}
          onClick={() => {
            if (onClick) {
              onClick()
            }
          }}
        >
          <ListItemIcon style={{ color: styles.leftIconStyle.color, ...iconStyle }}>{icon}</ListItemIcon>
          <ListItemText
            style={{ paddingLeft: '0px', paddingRight: '0px' }}
            primaryTypographyProps={{ style: styles.listItemStyle }}
            primary={text} />
        </ListItem>)
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const { joyride, user, userCustomizations, userCustomizations: { favorites = [] }, classes } = this.props
    const { favoritesOpen } = this.state

    const styles = {
      listItemStyle: {
        color: palette.navigationFontColor
      },
      leftIconStyle: {
        color: palette.navigationFontColor
      }
    }

    const navMenuItems = [
      {
        onClick: () => {
          let filter = Base64.encodeURI(JSON.stringify({
            displayText: language.translate('application.noFutureTasks'),
            text: 'current',
            operator: 'task-filter',
            displayOperator: ' ',
            displayOnly: true
          }))

          this.navigateTo({ pathname: '/', query: { search: filter } })
        },
        icon: <Home />,
        text: language.translate('application.myTasks')
      }, {
        onClick: () => {
          this.navigateTo('/process-templates')
        },
        icon: <Repeat />,
        iconStyle: { transform: 'rotate(90deg)' },
        text: language.translate('application.startAProcess'),
        hidden: !(user.canInitiateProcesses || user.canManageProcessTemplates)
      }, {
        icon: <InsertChart />,
        text: language.translate('application.reports'),
        hidden: !(user.canViewProcessReports),
        nestedList: [{
          icon: <Dashboard />,
          text: language.translate('application.dashboard', [], true),
          onClick: () => {
            this.navigateTo('/dashboard-groups')
          }
        }, {
          icon: <Description />,
          text: language.translate('application.processReports'),
          nestedList: [{
            onClick: () => {
              this.navigateTo('/reports?type=process&endpoint=processinstance/list/pending')
            },
            icon: <Sync />,
            text: language.translate('application.inProgress')
          }, {
            onClick: () => {
              this.navigateTo('/reports?type=process&endpoint=processinstance/list')
            },
            icon: <SelectAll />,
            text: language.translate('application.all')
          }, {
            onClick: () => {
              this.navigateTo('/process-instances-grid')
            },
            icon: <GridOn />,
            text: language.translate('application.tableView')
          }, {
            onClick: () => {
              this.navigateTo('/statistics?by=month&time=days&type=template')
            },
            icon: <ShowChart />,
            text: language.translate('application.statistics')
          }, {
            icon: <Timer />,
            text: language.translate('application.scheduled'),
            nestedList: [{
              onClick: () => {
                this.navigateTo('/scheduled-processes?type=active')
              },
              icon: <Timer />,
              text: language.translate('application.active')
            }, {
              onClick: () => {
                this.navigateTo('/scheduled-processes?type=expired')
              },
              icon: <TimerOff />,
              text: language.translate('application.expired')
            }]
          }]
        }, {
          icon: <Description />,
          text: language.translate('application.taskReports'),
          nestedList: [{
            onClick: () => {
              this.navigateTo('/reports?type=task&endpoint=processinstancetask/list/pending&tag_endpoint=processinstancetask/list/pending/tag/list&processtemplate_endpoint=processinstancetask/list/pending/processtemplate/list')
            },
            icon: <Sync />,
            text: language.translate('application.inProgress')
          }, {
            onClick: () => {
              this.navigateTo('/reports?type=task&endpoint=processinstancetask/list/pastdue&tag_endpoint=processinstancetask/list/pastdue/tag/list&processtemplate_endpoint=processinstancetask/list/pastdue/processtemplate/list')
            },
            icon: <Warning />,
            text: language.translate('application.pastDue')
          }, {
            onClick: () => {
              this.navigateTo('/reports?type=task&endpoint=processinstancetask/list/completed&tag_endpoint=processinstancetask/list/completed/tag/list')
            },
            icon: <CheckCircle />,
            text: language.translate('application.completed')
          }, {
            onClick: () => {
              this.navigateTo('/statistics?by=month&time=days&type=task')
            },
            icon: <ShowChart />,
            text: language.translate('application.statistics')
          }]
        }, {
          icon: <Description />,
          text: language.translate('application.userReports'),
          nestedList: [{
            onClick: () => {
              this.navigateTo('/statistics?type=user_backlog')
            },
            icon: <Warning />,
            text: language.translate('application.backLog')
          }, {
            onClick: () => {
              this.navigateTo('/statistics?by=month&time=days&type=user')
            },
            icon: <ShowChart />,
            text: language.translate('application.statistics')
          }, {
            onClick: () => {
              let query = (this.props.location.pathname === '/') ? objectAssign({}, this.props.location.query, { showFilter: 'true' }) : { showFilter: 'true' }

              this.props.push({
                pathname: '/',
                query
              })
            },
            icon: <WorkOutline />,
            text: language.translate('application.viewWorkList')
          }]
        }]
      }, {
        icon: <Group />,
        text: language.translate('application.administration'),
        hidden: !(user.canManageProcessTemplates || user.canManageAccountInformation || user.canManageUsers || user.canViewProcessReports || user.canManageSubscriptionLevel),
        nestedList: [{
          onClick: () => {
            this.props.push({
              pathname: this.props.location.pathname,
              query: objectAssign({}, this.props.location.query, { ptype: 'ptgroups' })
            })
          },
          icon: <GroupWork />,
          text: language.translate('application.templateGroups'),
          hidden: !(user.canManageProcessTemplates)
        }, {
          onClick: () => {
            this.navigateTo('/account')
          },
          icon: <AccountBalance />,
          text: language.translate('application.accountInformation'),
          hidden: !(user.canManageAccountInformation)
        }, {
          onClick: () => {
            this.navigateTo('/users')
          },
          icon: <Person />,
          text: language.translate('application.user', [], true),
          hidden: !(user.canManageUsers)
        }, {
          onClick: () => {
            this.navigateTo('/user-groups')
          },
          icon: <Group />,
          text: language.translate('application.userGroup', [], true),
          hidden: !(user.canManageUsers)
        }, {
          onClick: () => {
            this.props.push({
              pathname: this.props.location.pathname,
              query: objectAssign({}, this.props.location.query, { ptype: 'cufields' })
            })
          },
          icon: <Input />,
          text: language.translate('application.customUserFields'),
          hidden: !(user.canManageAccountInformation)
        }, {
          onClick: () => {
            this.navigateTo('/audit-trail?endpoint=auditentry/list&title=Audit trail')
          },
          icon: <ListIcon />,
          text: language.translate('application.auditTrail'),
          hidden: !(user.canViewProcessReports)
        }, {
          icon: <Storage />,
          text: language.translate('application.dataSources'),
          hidden: !(user.canManageAccountInformation),
          nestedList: [{
            onClick: () => {
              this.navigateTo('/external-datasources/automatedActions')
            },
            icon: <DirectionsRun />,
            text: language.translate('application.automatedActions')
          }, {
            onClick: () => {
              this.navigateTo('/external-datasources/dropDownLists')
            },
            icon: <Input />,
            text: language.translate('application.dropDownLists')
          }, {
            onClick: () => {
              this.navigateTo('/external-datasources/launchingProcesses')
            },
            icon: <Repeat />,
            iconStyle: { transform: 'rotate(90deg)' },
            text: language.translate('application.launchingProcesses')
          }]
        }, {
          onClick: () => {
            this.props.push({
              pathname: this.props.location.pathname,
              query: objectAssign({}, this.props.location.query, { ptype: 'integrations' })
            })
          },
          icon: <Settings />,
          text: language.translate('application.integration', [], true),
          hidden: !(user.canManageAccountInformation)
        }, {
          onClick: () => {
            this.props.push({
              pathname: this.props.location.pathname,
              query: objectAssign({}, this.props.location.query, { ptype: 'offdocs' })
            })
          },
          icon: <Description />,
          text: language.translate('application.sopTemplate', [], true)
        }, {
          onClick: () => {
            this.props.push({
              pathname: this.props.location.pathname,
              query: objectAssign({}, this.props.location.query, { ptype: 'textblocks' })
            })
          },
          icon: <TextFormat />,
          text: language.translate('application.textBlock', [], true)
        }, {
          onClick: () => {
            this.props.push({
              pathname: this.props.location.pathname,
              query: objectAssign({}, this.props.location.query, { ptype: 'workschedules' })
            })
          },
          icon: <Schedule />,
          text: language.translate('application.workSchedule', [], true)
        }, {
          onClick: () => {
            this.props.push({
              pathname: this.props.location.pathname,
              query: objectAssign({}, this.props.location.query, { ptype: 'atags' })
            })
          },
          icon: <Label />,
          text: language.translate('application.accountTags'),
          hidden: !(user.canManageAccountInformation)
        }, {
          onClick: () => {
            this.navigateTo('/plan')
          },
          icon: <Payment />,
          text: language.translate('application.planInformation'),
          hidden: !(document.location.host !== 'mobile.processplan.com' && user.canManageSubscriptionLevel)
        }, {
          onClick: () => {
            this.navigateTo('/invoices')
          },
          icon: <Receipt />,
          text: language.translate('application.invoice', [], true),
          hidden: !(user.canManageAccountInformation)
        }]
      }, {
        onClick: () => {
          this.navigateTo('/process-library')
        },
        icon: <LocalLibrary />,
        text: language.translate('application.processLibrary'),
        hidden: !(user.canCreateProcessTemplates)
      }, {
        onClick: () => {
          this.navigateTo('/marketplace')
        },
        icon: <Store />,
        text: language.translate('application.marketplace'),
        hidden: true
      }, {
        icon: <Help />,
        text: language.translate('application.help'),
        nestedList: [{
          onClick: () => {
            window.open('http://help.processplan.com/home', '_blank')
          },
          icon: <Help />,
          text: language.translate('application.onlineDocumentation')
        }, {
          icon: <LiveHelp />,
          text: language.translate('application.loading'),
          hidden: (this.props.isLiveChatLoaded)
        }, {
          onClick: () => {
            this.props.liveChatAPI.open_chat_window()
          },
          icon: <LiveHelp />,
          text: (this.props.agentsOnline) ? language.translate('application.liveSupport') : language.translate('application.supportTicket'),
          hidden: (!this.props.isLiveChatLoaded)
        }, {
          onClick: () => {
            joyride.joyride.start(true)
          },
          icon: <HelpOutline />,
          text: language.translate('application.tourThisPage'),
          hidden: (!joyride.steps.length)
        }, {
          onClick: () => {
            this.navigateTo('/api-endpoints')
          },
          icon: <Code />,
          text: language.translate('application.apiEndpoints')
        }]
      }
    ]

    return (
      <Drawer
        className='left-nav'
        open={this.props.isOpen}
        variant='persistent'
        PaperProps={{
          style: {
            maxWidth: '260px',
            backgroundColor: palette.navigationBackgroundColor,
            paddingBottom: '20px',
            '&::WebkitScrollbarThumb': {
              backgroundColor: 'rgb(76, 81, 82)'
            }
          }
        }}
      >
        <div className={classes.toolbar} />
        <List style={{ paddingTop: '0px' }}>
          <NestedListItem
            button
            style={objectAssign({}, styles.listItemStyle, {
              backgroundColor: 'rgb(115, 115, 115)',
              boxShadow: '#383636 0px 0px 4px 0px'
            })}
            open={favoritesOpen}
            hideRightIcon
            onClick={() => {this.setState({ favoritesOpen: !favoritesOpen })}}
            nestedList={
              <List
                disablePadding
                className={classes.nested}
                style={{
                  backgroundColor: palette.accent6Color,
                  padding: '0px',
                  boxShadow: 'rgb(56, 54, 54) 0px 0px 4px 0px inset'
                }}>
                <FavoriteList
                  key='favorites'
                  favorites={favorites}
                  onFavoriteClick={this.navigateTo.bind(this)}
                  onChange={this.updateFavorite.bind(this)}
                  onRemove={this.removeFavorite.bind(this)}
                  enableRename={this.enableFavoriteRename.bind(this)}
                  onReorderRequest={this.reorderFavorites.bind(this)}
                />
              </List>
            }
          >
            <ListItemIcon style={{ color: styles.leftIconStyle.color }}><Favorite /></ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ style: styles.listItemStyle }}
              primary={language.translate('application.favorites')} />
            <ListItemSecondaryAction>
              <IconButton
                onClick={this.addPageToUserFavorites.bind(this)}
              >
                <Add />
              </IconButton>
            </ListItemSecondaryAction>
          </NestedListItem>
          {navMenuItems.map(menuItem => (this.getListItem(menuItem)))}
        </List>
        {(this.props.user.accountImageDark)
          ? <div style={{ textAlign: 'center', paddingTop: '30px' }}>
            <img src={this.props.user.accountImageDark} style={{ maxWidth: '230px' }} />
          </div>
          : null}
        {(this.props.user.partnerImageDark)
          ? <div style={{ textAlign: 'center', paddingTop: '30px' }}>
            <Typography style={{ color: palette.navigationFontColor }}>
              {language.translate('application.yourPartner')}
            </Typography>
            {(this.props.user.partnerWebsiteURL)
              ? <a
                href={(this.props.user.partnerWebsiteURL.substring(0, 4) !== 'http') ? `http://${this.props.user.partnerWebsiteURL}` : this.props.user.partnerWebsiteURL}
                target='_blank'>
                <img src={this.props.user.partnerImageDark} style={{ maxWidth: '230px' }} />
              </a>
              : <img src={this.props.user.partnerImageDark} style={{ maxWidth: '230px' }} />}
          </div>
          : null}
      </Drawer>
    )
  }
}

Navigation.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  user: PropTypes.object,
  userCustomizations: PropTypes.object,
  isLiveChatLoaded: PropTypes.bool.isRequired,
  agentsOnline: PropTypes.bool.isRequired,
  liveChatAPI: PropTypes.object.isRequired,
  joyride: PropTypes.object,
  push: PropTypes.func.isRequired,
  updateUserSession: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  location: PropTypes.object,
  pageTitle: PropTypes.string,
  classes: PropTypes.object.isRequired,
}

Navigation.contextTypes = {
  muiTheme: PropTypes.object,
  language: PropTypes.object,
  location: PropTypes.object
}

const mapStateToProps = state => ({
  isOpen: state.navigation.open,
  user: state.auth,
  userCustomizations: state.auth.userCustomizations,
  isLiveChatLoaded: state.livechat.isLoaded,
  agentsOnline: state.livechat.agentsOnline,
  liveChatAPI: state.livechat.api,
  joyride: state.joyride,
  pageTitle: state.application.title
})

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  updateUserSession: bindActionCreators(updateUserSession, dispatch),
  post: bindActionCreators(post, dispatch),
  showSnackbar: bindActionCreators(showSnackbar, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Navigation))
