import { createReducer } from '../utils'
import {
  ADD_PENDING_MESSAGE, HIDE_SNACKBAR_REQUEST, REMOVE_FIRST_PENDING_MESSAGE, SHOW_SNACKBAR_REQUEST
} from '../constants/SnackbarActionTypes'
import objectAssign from 'object-assign'

const initialState = {
  isOpen: false,
  message: {},
  pending: []
}

export default createReducer(initialState, {
  [SHOW_SNACKBAR_REQUEST]: (state, payload) => objectAssign({}, state, {
    isOpen: true,
    message: payload.message
  }),

  [HIDE_SNACKBAR_REQUEST]: state => objectAssign({}, state, {
    isOpen: false
  }),

  [ADD_PENDING_MESSAGE]: (state, payload) => objectAssign({}, state, {
    pending: state.pending.concat(payload.message)
  }),

  [REMOVE_FIRST_PENDING_MESSAGE]: state => objectAssign({}, state, {
    pending: state.pending.slice(1)
  })
})
