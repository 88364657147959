import { createReducer } from '../utils'
import { SET_PUSHER_INSTANCE } from '../constants/PusherTypes'
import objectAssign from 'object-assign'

const initialState = {
  pusher: null
}

export default createReducer(initialState, {
  [SET_PUSHER_INSTANCE]: (state, payload) => objectAssign({}, state, {
    pusher: payload.pusher
  })
})
