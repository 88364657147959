import PropTypes from 'prop-types'
import React from 'react'
import { Avatar, Card, CardContent, CardHeader } from '@material-ui/core'
import { Sync, CheckCircle } from '@material-ui/icons'
import FormHelper from '../../businessLogic/formHelper'
import ReactMarkdown from 'react-markdown'

class MilestoneCard extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      progressCompleted: 0
    }
  }

  componentDidMount () {
    this.progress(5)
  }

  progress (completed) {
    const { process } = this.props

    const numberCompleted = process.Milestones.filter(milestone => (milestone.Completed_Date_Local)).length
    const maxPercentage = numberCompleted * 100

    if (completed > maxPercentage) {
      this.setState({ progressCompleted: maxPercentage })
    } else {
      this.setState({ progressCompleted: completed })
      setTimeout(() => this.progress(completed + 10), 50)
    }
  }

  render () {
    const { progressCompleted } = this.state
    const { muiTheme, language } = this.context
    const { process, cardStyle } = this.props

    let instanceMilestones = process.Milestones
    const numberCompleted = instanceMilestones.filter(milestone => (milestone.Completed_Date_Local)).length

    return (
      <Card
        className='card'
        style={cardStyle}>
        <CardHeader
          title={process.ProcessTemplateTitle}
          titleTypographyProps={{ style: { fontSize: '20px', color: muiTheme.palette.alternateTextColor } }}
          subheader={
            (process.HeaderField1_Name)
              ? `${FormHelper.decodeHTML(process.HeaderField1_Name)}: ${FormHelper.decodeHTML(process.HeaderField1_Value)}`
              : ''
          }
          subheaderTypographyProps={{ style: { color: muiTheme.palette.alternateTextColor } }}
          style={{
            borderBottom: `1px solid ${muiTheme.palette.borderColor}`,
            backgroundColor: muiTheme.palette.accent1Color
          }}
        />
        <CardContent>
          {(process.MilestoneInstructions)
            ? <ReactMarkdown source={process.MilestoneInstructions} escapeHtml linkTarget='_blank' />
            : null}
          {(!instanceMilestones.length)
            ? <div>{language.translate('application.noMilestonesSet')}</div>
            : <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              marginTop: '20px'
            }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {instanceMilestones.map((milestone, index) => (
                  <div
                    key={milestone.ProcessTemplateTaskID}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      height: (instanceMilestones[index + 1]) ? '70px' : 'inherit'
                    }}
                  >
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}
                    >
                      {(milestone.Completed_Date_Local)
                        ? <CheckCircle
                          style={{ height: '35px', width: '55px' }}
                          nativeColor={muiTheme.palette.successColor}
                        />
                        : (instanceMilestones[index - 1] && instanceMilestones[index - 1].Completed_Date_Local)
                          ? <div style={{ margin: '3px 9px' }}>
                            <Avatar
                              style={{
                                height: '35px',
                                width: '35px',
                                backgroundColor: (progressCompleted >= numberCompleted * 100) ? muiTheme.palette.primary1Color : muiTheme.palette.canvasColor,
                                transition: 'background-color 0.5s ease'
                              }}
                            >
                              <Sync
                                nativeColor={(progressCompleted >= numberCompleted * 100) ? muiTheme.palette.canvasColor : muiTheme.palette.primary1Color}
                                style={{
                                  transform: `rotate(-${progressCompleted}deg)`
                                }}
                              />
                            </Avatar>
                          </div>
                          : <div style={{ margin: '3px 9px' }}>
                            <Avatar
                              style={{ height: '35px', width: '35px', fontSize: '20px' }}
                            >
                              {index + 1}
                            </Avatar>
                          </div>}
                      {(index < instanceMilestones.length - 1)
                        ? <div
                          style={{
                            position: 'relative',
                            height: '100%',
                            display: 'block',
                            width: '4px',
                            backgroundColor: 'rgb(224,224,224)',
                            borderRadius: '2px',
                            margin: '0px',
                            overflow: 'hidden',
                            transition: 'inherit'
                          }}
                        >
                          <div
                            style={{
                              width: '100%',
                              backgroundColor: muiTheme.palette.primary1Color,
                              height: (progressCompleted - index * 100 < 0) ? '0%' : `${progressCompleted - index * 100}%`
                            }}
                          />
                        </div>
                        : null}
                    </div>
                    <div
                      style={{
                        textAlign: 'center',
                        marginTop: '6px'
                      }}
                    >{milestone.MilestoneDescription}</div>
                  </div>
                ))}
              </div>
            </div>
          }
        </CardContent>
      </Card>)
  }
}

MilestoneCard.propTypes = {
  process: PropTypes.object.isRequired,
  cardStyle: PropTypes.object
}

MilestoneCard.contextTypes = {
  location: PropTypes.object,
  muiTheme: PropTypes.object,
  language: PropTypes.object
}

export default MilestoneCard
