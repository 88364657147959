import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { MenuItem } from 'material-ui'
import GroupUserSelect from '../Forms/GroupUserSelect'
import { get } from '~/actions/base'
import { Form } from 'formsy-react'
import { FormsySelect } from 'formsy-material-ui'
import FormHelper from '~/businessLogic/formHelper'

class TaskAssignmentDialog extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      userId: this.props.userId,
      groupId: this.props.groupId,
      dynamicProperty: null,
      fieldList: [],
      taskList: [],
      dynamicUserId: this.props.dynamicUserId,
      dynamicTaskId: this.props.dynamicTaskId,
      showDynamicUserSelect: false,
      showDynamicTaskSelect: false
    }
  }

  componentDidMount () {
    this.fetchProcessFields()
    this.fetchProcessTasks()
  }

  fetchProcessFields () {
    const { templateId } = this.props

    this.props.dispatch(get(`processtemplate/${templateId}/field/list`, {
      onSuccess: (response) => {
        const userFieldList = []

        response.ProcessTemplateFieldList.map((field) => {
          if (['G', 'P', 'UL'].includes(field.FieldType)) { userFieldList.push(field) }
        })

        this.setState({
          fieldList: userFieldList
        })
      }
    }))
  }

  fetchProcessTasks () {
    const { templateId } = this.props

    this.props.dispatch(get(`processtemplate/${templateId}/task/list`, {
      onSuccess: (response) => {
        this.setState({
          taskList: response.ProcessTemplateTaskList
        })
      }
    }))
  }

  displayDynamicFieldIfNecessary (user) {
    if (!user) {
      return
    }

    let showDynamicUserSelect = false
    let showDynamicTaskSelect = false
    let dynamicProperty = null

    if (['01-ProcessFields', '10-FieldValueSupervisor'].includes(user.LastName)) {
      showDynamicUserSelect = true
      showDynamicTaskSelect = false
      dynamicProperty = 'Default_AssignedTo_TemplateFieldID'
    } else if (['05-Completer', '07-SpecificTaskAssignment'].includes(user.LastName)) {
      showDynamicTaskSelect = true
      showDynamicUserSelect = false
      dynamicProperty = 'Default_AssignedTo_TemplateTaskID'
    } else {
      showDynamicUserSelect = false
      showDynamicTaskSelect = false
      dynamicProperty = null
    }

    this.setState({ showDynamicUserSelect, showDynamicTaskSelect, dynamicProperty })

    if (showDynamicTaskSelect || showDynamicUserSelect) {
      return true
    }

    return false
  }

  getDynamicIdValue () {
    const { showDynamicTaskSelect, showDynamicUserSelect, dynamicUserId, dynamicTaskId } = this.state

    if (showDynamicTaskSelect) {
      return dynamicTaskId
    }

    if (showDynamicUserSelect) {
      return dynamicUserId
    }

    return null
  }

  render () {
    const { groupId, userId, fieldList, taskList, dynamicUserId, dynamicTaskId, dynamicProperty, showDynamicTaskSelect, showDynamicUserSelect } = this.state
    const { open, close, onSubmit } = this.props
    const palette = this.context.muiTheme.palette
    const { language } = this.context

    const userOptions = [<MenuItem
      value=''
      key={'0'}
      primaryText={(this.state.value === '') ? '' : language.translate('application.pleaseSelect')}
    />]

    if (fieldList.length) {
      fieldList.map((option) => {
        userOptions.push(<MenuItem
          value={option.ID}
          key={option.ID}
          primaryText={FormHelper.decodeHTML(option.FieldName)}
        />)
      })
    }

    const taskOptions = [<MenuItem
      value=''
      key={'0'}
      primaryText={(this.state.value === '') ? '' : language.translate('application.pleaseSelect')}
    />]

    if (taskList.length) {
      taskList.map((option) => {
        taskOptions.push(<MenuItem
          value={option.ID}
          key={option.ID}
          primaryText={FormHelper.decodeHTML(option.TaskText)}
        />)
      })
    }

    return (
      <Dialog
        open={open}
        onClose={() => close()}
      >
        <DialogTitle
          style={{
            backgroundColor: palette.headerBackgroundColor
          }}
          disableTypography
        >
          <Typography
            variant='h6'
            style={{ color: palette.alternateTextColor }}>
            {language.translate('application.taskAssignedTo')}
          </Typography>
        </DialogTitle>
        <DialogContent style={{ width: '370px' }}>
          <Form>
            <GroupUserSelect
              name='Assigned To'
              dispatch={this.props.dispatch}
              allowAllUsers
              ref='assigned_to'
              withDynamic
              groupValue={groupId}
              userValue={userId}
              allUsersLabel={language.translate('application.anyPersonInGroup')}
              onGroupChange={(groupId) => this.setState({
                groupId,
                userId: '0',
                dynamicUserId: null,
                dynadynamicTaskIdmicId: null,
                dynamicProperty: '',
                showDynamicUserSelect: false,
                showDynamicTaskSelect: false
              })}
              onUserChange={(userId, user) => {
                this.displayDynamicFieldIfNecessary(user)

                this.setState({ userId, dynamicUserId: null, dynamicTaskId: null, dynamicProperty: '' })
              }}
              afterUsersLoaded={(users) => {
                if (userId) {
                  users.map((user) => {
                    if (user.ID === userId) {
                      this.displayDynamicFieldIfNecessary(user)
                    }
                  })
                }
              }}
              userListEndpoint={(groupId) => (`usergroup/${groupId}/user/list/fortaskassignments`)}
            />
            {(userId)
              ? (showDynamicUserSelect)
                ? <FormsySelect
                  value={dynamicUserId || ''}
                  onChange={(e, value) => this.setState({
                    dynamicUserId: value,
                    dynamicProperty: 'Default_AssignedTo_TemplateFieldID'
                  })}
                  autoWidth
                  name={'dynamic_user'}
                  inputStyle={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                  required
                  validationErrors={this.context.language.messages.validationErrors}
                >
                  {userOptions}
                </FormsySelect>
                : (showDynamicTaskSelect)
                  ? <FormsySelect
                    value={dynamicTaskId || ''}
                    autoWidth
                    onChange={(e, value) => this.setState({
                      dynamicTaskId: value,
                      dynamicProperty: 'Default_AssignedTo_TemplateTaskID'
                    })}
                    name={'dynamic_task'}
                    inputStyle={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                    required
                    validationErrors={this.context.language.messages.validationErrors}
                  >
                    {taskOptions}
                  </FormsySelect>
                  : null
              : null}
          </Form>
        </DialogContent>
        <DialogActions>
          <Button
            key='submit'
            color='primary'
            onClick={() => {
              onSubmit(groupId, userId, this.getDynamicIdValue(), dynamicProperty)
            }}
          >
            {language.translate('application.submit')}
          </Button>
          <Button
            key='cancel'
            color='primary'
            onClick={() => close()}
          >
            {language.translate('application.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

TaskAssignmentDialog.propTypes = {
  dispatch: PropTypes.func.isRequired,
  groupId: PropTypes.string,
  userId: PropTypes.string,
  dynamicId: PropTypes.string,
  dynamicTaskId: PropTypes.string,
  dynamicUserId: PropTypes.string,
  templateId: PropTypes.string,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
}

TaskAssignmentDialog.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(TaskAssignmentDialog)
