import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get } from '../../actions/base'
import {
  Checkbox, Divider, FormControl, FormControlLabel, InputLabel, List, ListItem, ListItemSecondaryAction, ListItemText,
  MenuItem, Select, Switch, TextField, Typography
} from '@material-ui/core'
import moment from 'moment'

class TemplateTaskStartDueDateSettings extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      startDatePivot: null,
      startDateContext: 'days',
      dueDatePivot: null,
      dueDateContext: 'days',
      task: null,
      workSchedules: []
    }
  }

  componentDidMount() {
    this.setState({
      dueDatePivot: (this.props.task.DefaultDueDateAdjustment >= 0) ? 'after' : 'before',
      startDatePivot: (this.props.task.DefaultStartDateAdjustment >= 0) ? 'after' : 'before',
      task: this.props.task
    }, () => {
      this.setDateContext(this.props.task.DefaultStartDateAdjustment, 'startDateContext')
      this.setDateContext(this.props.task.DefaultDueDateAdjustment, 'dueDateContext')
    });
    console.log('fetching work schedules in componentDidMount');
    this.fetchWorkSchedules();
    console.log('finished fetching work schedules in componentDidMount');
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.task !== nextProps.task) { return true }

    if (this.props.task.DefaultDatePivotPoint !== nextProps.task.DefaultDatePivotPoint) { return true }

    if (this.props.task.DefaultDueDateAdjustment !== nextProps.task.DefaultDueDateAdjustment) { return true }

    if (this.props.task.DefaultStartDateAdjustment !== nextProps.task.DefaultStartDateAdjustment) { return true }

    return true
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.task.DefaultDueDateAdjustment !== this.props.task.DefaultDueDateAdjustment) { this.setState({ dueDatePivot: (this.props.task.DefaultDueDateAdjustment > 0) ? 'after' : 'before' }) }

    if (prevProps.task.DefaultStartDateAdjustment !== this.props.task.DefaultStartDateAdjustment) { this.setState({ startDatePivot: (this.props.task.DefaultStartDateAdjustment > 0) ? 'after' : 'before' }) }

    if (JSON.stringify(prevProps.task) !== JSON.stringify(this.props.task)) { this.setState({ task: this.props.task }) }
  }

  setDateContext(adjustment, stateProperty) {
    let context = 'minutes'

    if (!(adjustment % 1440)) {
      context = 'days'
    } else if (!(adjustment % 60)) {
      context = 'hours'
    }

    this.setState({ [stateProperty]: context })

  }

  fetchWorkSchedules() {
    const { get } = this.props
    const endpoint = `workschedule/list`;

    console.log('calling api endpoint');

    get(endpoint, {
      onSuccess: (response) => {
        console.log('set work schedule state');
        this.setState({
          workSchedules: response.WorkScheduleList
        });
      }
    })
  }

  workScheduleMenu() {
    const { workSchedules } = this.state
    let { language } = this.context
    const menu = [<MenuItem value={false} key={0}>{language.translate('application.selectWorkSchedule') + '...'}</MenuItem>]
    console.log('loading menu');

    if (workSchedules) {
      workSchedules.map((workSchedule) => {
        menu.push(<MenuItem value={workSchedule.ID} key={workSchedule.ID}>{workSchedule.ScheduleName}</MenuItem>)
      })
    }

    return menu
  }

  getPivotPointText() {
    const { language } = this.context

    switch (this.props.task.DefaultDatePivotPoint) {
      case 'S':
        return language.translate('application.processStartDate').toLowerCase()
      case 'P':
        return language.translate('application.processTargetDate').toLowerCase()
      case 'T':
        return language.translate('application.taskAssignmentDate').toLowerCase()
    }
  }

  convertToMinutes(value, context) {
    if (context === 'minutes') {
      return value
    }

    if (context === 'hours') {
      return value * 60
    }

    if (context === 'days') {
      return value * 1440
    }
  }

  convertFromMinutes(value, context) {
    if (context === 'minutes') {
      return value
    }

    if (context === 'hours') {
      return value / 60
    }

    if (context === 'days') {
      return value / 1440
    }
  }

  getNumberMenu(min, max, defaultMenuItem) {
    let count = min
    let menu = [<MenuItem value={defaultMenuItem.value} key={defaultMenuItem.value}>{defaultMenuItem.label}</MenuItem>]

    while (count < max + 1) {
      let value = count.toString()

      if (parseInt(value) < 10) {
        value = `0${value}`
      }

      menu.push(<MenuItem value={value} key={value}>{value}</MenuItem>)

      count++
    }

    return menu
  }

  render() {
    const { language, muiTheme: { palette } } = this.context
    const { onSave } = this.props
    const { dueDatePivot, startDatePivot, task, startDateContext, dueDateContext, workSchedules } = this.state

    if (!task) {
      return null
    }

    const startDateAdjustment = this.convertFromMinutes(task.DefaultStartDateAdjustment, startDateContext)
    const dueDateAdjustment = this.convertFromMinutes(task.DefaultDueDateAdjustment, dueDateContext)
    let daysOfTheWeek = language.messages.calendar.weekdays
    let monthsOfYear = language.messages.calendar.months

    let monthsOfYearMenu = [<MenuItem value={'mm'} key={'mm'}>{language.translate('application.every')}</MenuItem>]

    Object.keys(monthsOfYear).map((month, index) => {
      let monthValue = (index + 1 < 10) ? `0${index + 1}` : `${index + 1}`

      monthsOfYearMenu.push(<MenuItem value={monthValue} key={monthValue}>{monthsOfYear[month]}</MenuItem>)
    })

    let yearMenu = this.getNumberMenu(moment().year(), moment().year() + 5, {
      label: language.translate('application.every'),
      value: 'yyyy'
    })
    let dayOfMonthMenu = this.getNumberMenu(1, 31, { label: language.translate('application.every'), value: 'dd' })
    let hourMenu = this.getNumberMenu(0, 23, { label: language.translate('application.every'), value: 'hh' })
    let minuteMenu = this.getNumberMenu(0, 59, { label: language.translate('application.every'), value: 'nn' })

    return (
      <List style={{ padding: '0px 20px', backgroundColor: palette.accent2Color }}>
        <div style={{ margin: '5px 5px 5px 16px', paddingTop: '10px' }}>
          <div className='field-label' style={{ fontSize: '14px' }}>
            {language.translate('application.startDueDatesRelativeTo')}
          </div>
          <Select
            style={{ marginLeft: '5px' }}
            name='pivot_date'
            onChange={(e) => {
              task.DefaultDatePivotPoint = e.target.value
              onSave(task)
            }}
            value={task.DefaultDatePivotPoint}
          >
            {[
              <MenuItem value='S' key='S'>{language.translate('application.processStartDate')}</MenuItem>,
              <MenuItem value='P' key='P'>{language.translate('application.processTargetDate')}</MenuItem>,
              <MenuItem value='T' key='T'>{language.translate('application.taskAssignmentDate')}</MenuItem>,
              <MenuItem value='A' key='A'>{language.translate('application.absoluteDate')}</MenuItem>
            ]}
          </Select>
        </div>
        <Divider />
        <div style={{ margin: '5px 5px 5px 16px' }}>
          <div className='field-label' style={{ fontSize: '14px', paddingBottom: '10px' }}>
            {language.translate('application.setStartDateTo')}
          </div>
          {(task.DefaultDatePivotPoint === 'A')
            ? <div>
              <FormControl>
                <InputLabel style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {language.translate('application.year')}
                </InputLabel>
                <Select
                  style={{
                    marginLeft: '5px',
                    width: 'auto',
                    minWidth: '100px',
                    marginRight: '5px',
                    marginBottom: '15px'
                  }}
                  name='year'
                  autoWidth
                  onChange={(e) => {
                    let newValue = `${e.target.value}${task.DefaultStartDateAbsolute.substring(4)}`
                    task.DefaultStartDateAbsolute = newValue
                    onSave(task)
                  }}
                  value={task.DefaultStartDateAbsolute.substring(0, 4)}
                >
                  {yearMenu}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {language.translate('application.month')}
                </InputLabel>
                <Select
                  style={{
                    marginLeft: '5px',
                    width: 'auto',
                    minWidth: '100px',
                    marginRight: '5px',
                    marginBottom: '15px'
                  }}
                  name='month'
                  autoWidth
                  onChange={(e) => {
                    let newValue = `${task.DefaultStartDateAbsolute.substring(0, 5)}${e.target.value}${task.DefaultStartDateAbsolute.substring(7)}`
                    task.DefaultStartDateAbsolute = newValue
                    onSave(task)
                  }}
                  value={task.DefaultStartDateAbsolute.substring(5, 7)}
                >
                  {monthsOfYearMenu}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {language.translate('application.day')}
                </InputLabel>
                <Select
                  style={{
                    marginLeft: '5px',
                    width: 'auto',
                    minWidth: '100px',
                    marginRight: '5px',
                    marginBottom: '15px'
                  }}
                  name='day'
                  autoWidth
                  onChange={(e) => {
                    let newValue = `${task.DefaultStartDateAbsolute.substring(0, 8)}${e.target.value}${task.DefaultStartDateAbsolute.substring(10)}`
                    task.DefaultStartDateAbsolute = newValue
                    onSave(task)
                  }}
                  value={task.DefaultStartDateAbsolute.substring(8, 10)}
                >
                  {dayOfMonthMenu}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {language.translate('application.hour')}
                </InputLabel>
                <Select
                  style={{
                    marginLeft: '5px',
                    width: 'auto',
                    minWidth: '100px',
                    marginRight: '5px',
                    marginBottom: '15px'
                  }}
                  name='hour'
                  autoWidth
                  onChange={(e) => {
                    let newValue = `${task.DefaultStartDateAbsolute.substring(0, 11)}${e.target.value}${task.DefaultStartDateAbsolute.substring(13)}`
                    task.DefaultStartDateAbsolute = newValue
                    onSave(task)
                  }}
                  value={task.DefaultStartDateAbsolute.substring(11, 13)}
                >
                  {hourMenu}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {language.translate('application.minute')}
                </InputLabel>
                <Select
                  style={{
                    marginLeft: '5px',
                    width: 'auto',
                    minWidth: '100px',
                    marginRight: '5px',
                    marginBottom: '15px'
                  }}
                  name='minute'
                  autoWidth
                  style={{ paddingLeft: '5px' }}
                  onChange={(e) => {
                    let newValue = `${task.DefaultStartDateAbsolute.substring(0, 14)}${e.target.value}`
                    task.DefaultStartDateAbsolute = newValue
                    onSave(task)
                  }}
                  value={task.DefaultStartDateAbsolute.substring(14, 16)}
                >
                  {minuteMenu}
                </Select>
              </FormControl>
            </div>
            : <div>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                fontSize: '16px',
                marginLeft: '5px'
              }}>
                <TextField
                  type='tel'
                  name='start_adjustment'
                  ref='start_adjustment'
                  style={{ width: '40px', marginRight: '10px' }}
                  value={(startDateAdjustment > 0) ? startDateAdjustment : 0 - startDateAdjustment}
                  onChange={(e) => {
                    let adjustment = this.convertToMinutes(e.currentTarget.value, startDateContext)

                    if (startDatePivot === 'before') {
                      task.DefaultStartDateAdjustment = 0 - adjustment
                    } else {
                      task.DefaultStartDateAdjustment = adjustment
                    }

                    this.setState({ task })
                  }}
                  onBlur={(e) => {
                    let adjustment = this.convertToMinutes(e.currentTarget.value, startDateContext)

                    if (startDatePivot === 'before') {
                      task.DefaultStartDateAdjustment = 0 - adjustment
                    } else {
                      task.DefaultStartDateAdjustment = adjustment
                    }

                    onSave(task)
                  }}
                />
                <Select
                  style={{ marginRight: '0px 5px' }}
                  autoWidth
                  name='start_date_context'
                  value={startDateContext}
                  onChange={(e) => {
                    let value = e.target.value
                    const prevDateContext = startDateContext

                    this.setState({ startDateContext: value }, () => {
                      const prevContextValue = this.convertFromMinutes(task.DefaultStartDateAdjustment, prevDateContext)

                      task.DefaultStartDateAdjustment = this.convertToMinutes(prevContextValue, value)

                      this.setState({ task })
                      onSave(task)
                    })
                  }}
                >
                  {[
                    <MenuItem value='days' key={1}>{language.translate('application.day')}</MenuItem>,
                    <MenuItem value='hours' key={2}>{language.translate('application.hour')}</MenuItem>,
                    <MenuItem value='minutes' key={3}>{language.translate('application.minute')}</MenuItem>
                  ]}
                </Select>
              </div>
              <Select
                name='start_pivot'
                ref='start_pivot'
                autoWidth
                style={{ marginLeft: '5px' }}
                onChange={(e) => {
                  this.setState({ startDatePivot: e.target.value })
                  task.DefaultStartDateAdjustment = 0 - task.DefaultStartDateAdjustment

                  onSave(task)
                }}
                value={startDatePivot}
              >
                {[
                  <MenuItem
                    value='before'
                    key='before'>
                    {`${language.translate('application.before').lcFirst()} ${this.getPivotPointText()}`}
                  </MenuItem>,
                  <MenuItem
                    value='after'
                    key='after'>
                    {`${language.translate('application.after').lcFirst()} ${this.getPivotPointText()}`}
                  </MenuItem>
                ]}
              </Select>
            </div>}
        </div>
        <Divider />
        <div style={{ margin: '5px 5px 5px 16px' }}>
          <div className='field-label' style={{ fontSize: '14px', paddingBottom: '10px' }}>
            {language.translate('application.setDueDateTo')}
          </div>
          {(task.DefaultDatePivotPoint === 'A')
            ? <div>
              <FormControl>
                <InputLabel style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {language.translate('application.year')}
                </InputLabel>
                <Select
                  style={{
                    marginLeft: '5px',
                    width: 'auto',
                    minWidth: '100px',
                    marginRight: '5px',
                    marginBottom: '15px'
                  }}
                  name='year'
                  autoWidth
                  onChange={(e) => {
                    let newValue = `${e.target.value}${task.DefaultDueDateAbsolute.substring(4)}`
                    task.DefaultDueDateAbsolute = newValue
                    onSave(task)
                  }}
                  value={task.DefaultDueDateAbsolute.substring(0, 4)}
                >
                  {yearMenu}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {language.translate('application.month')}
                </InputLabel>
                <Select
                  style={{
                    marginLeft: '5px',
                    width: 'auto',
                    minWidth: '100px',
                    marginRight: '5px',
                    marginBottom: '15px'
                  }}
                  name='month'
                  autoWidth
                  onChange={(e) => {
                    let newValue = `${task.DefaultDueDateAbsolute.substring(0, 5)}${e.target.value}${task.DefaultDueDateAbsolute.substring(7)}`
                    task.DefaultDueDateAbsolute = newValue
                    onSave(task)
                  }}
                  value={task.DefaultDueDateAbsolute.substring(5, 7)}
                >
                  {monthsOfYearMenu}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {language.translate('application.day')}
                </InputLabel>
                <Select
                  style={{
                    marginLeft: '5px',
                    width: 'auto',
                    minWidth: '100px',
                    marginRight: '5px',
                    marginBottom: '15px'
                  }}
                  name='day'
                  autoWidth
                  onChange={(e) => {
                    let newValue = `${task.DefaultDueDateAbsolute.substring(0, 8)}${e.target.value}${task.DefaultDueDateAbsolute.substring(10)}`
                    task.DefaultDueDateAbsolute = newValue
                    onSave(task)
                  }}
                  value={task.DefaultDueDateAbsolute.substring(8, 10)}
                >
                  {dayOfMonthMenu}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {language.translate('application.hour')}
                </InputLabel>
                <Select
                  style={{
                    marginLeft: '5px',
                    width: 'auto',
                    minWidth: '100px',
                    marginRight: '5px',
                    marginBottom: '15px'
                  }}
                  name='hour'
                  autoWidth
                  onChange={(e) => {
                    let newValue = `${task.DefaultDueDateAbsolute.substring(0, 11)}${e.target.value}${task.DefaultDueDateAbsolute.substring(13)}`
                    task.DefaultDueDateAbsolute = newValue
                    onSave(task)
                  }}
                  value={task.DefaultDueDateAbsolute.substring(11, 13)}
                >
                  {hourMenu}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {language.translate('application.minute')}
                </InputLabel>
                <Select
                  style={{
                    marginLeft: '5px',
                    width: 'auto',
                    minWidth: '100px',
                    marginRight: '5px',
                    marginBottom: '15px'
                  }}
                  name='minute'
                  autoWidth
                  onChange={(e) => {
                    let newValue = `${task.DefaultDueDateAbsolute.substring(0, 14)}${e.target.value}`
                    task.DefaultDueDateAbsolute = newValue
                    onSave(task)
                  }}
                  value={task.DefaultDueDateAbsolute.substring(14, 16)}
                >
                  {minuteMenu}
                </Select>
              </FormControl>
            </div>
            : <div>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                fontSize: '16px',
                marginLeft: '5px'
              }}>
                <TextField
                  type='tel'
                  name='due_adjustment'
                  ref='due_adjustment'
                  style={{ width: '40px', marginRight: '10px' }}
                  value={(task.DefaultDueDateAdjustment > 0) ? dueDateAdjustment : 0 - dueDateAdjustment}
                  onChange={(e) => {
                    let adjustment = this.convertToMinutes(e.currentTarget.value, dueDateContext)

                    if (startDatePivot === 'before') {
                      task.DefaultDueDateAdjustment = 0 - adjustment
                    } else {
                      task.DefaultDueDateAdjustment = adjustment
                    }

                    this.setState({ task })
                  }}
                  onBlur={(e) => {
                    let adjustment = this.convertToMinutes(e.currentTarget.value, dueDateContext)

                    if (startDatePivot === 'before') {
                      task.DefaultDueDateAdjustment = 0 - adjustment
                    } else {
                      task.DefaultDueDateAdjustment = adjustment
                    }

                    onSave(task)
                  }}
                />
                <Select
                  style={{ marginRight: '0px 5px' }}
                  autoWidth
                  name='due_date_context'
                  value={dueDateContext}
                  onChange={(e) => {
                    let value = e.target.value
                    const prevDateContext = dueDateContext

                    this.setState({ dueDateContext: value }, () => {
                      const prevContextValue = this.convertFromMinutes(task.DefaultDueDateAdjustment, prevDateContext)

                      task.DefaultDueDateAdjustment = this.convertToMinutes(prevContextValue, value)

                      this.setState({ task })
                      onSave(task)
                    })
                  }}
                >
                  {[
                    <MenuItem value='days' key={1}>{language.translate('application.day')}</MenuItem>,
                    <MenuItem value='hours' key={2}>{language.translate('application.hour')}</MenuItem>,
                    <MenuItem value='minutes' key={3}>{language.translate('application.minute')}</MenuItem>
                  ]}
                </Select>
              </div>
              <Select
                name='due_pivot'
                ref='due_pivot'
                autoWidth
                style={{ marginLeft: '5px' }}
                onChange={(e, index, value) => {
                  this.setState({ dueDatePivot: e.target.value })

                  task.DefaultDueDateAdjustment = 0 - task.DefaultDueDateAdjustment

                  onSave(task)
                }}
                value={dueDatePivot}
              >
                {[
                  <MenuItem
                    value='before'
                    key='before'>
                    {`${language.translate('application.before').lcFirst()} ${this.getPivotPointText()}`}
                  </MenuItem>,
                  <MenuItem
                    value='after'
                    key='after'>
                    {`${language.translate('application.after').lcFirst()} ${this.getPivotPointText()}`}
                  </MenuItem>
                ]}
              </Select>
            </div>}
        </div>
        <Divider />


        <div
          className='field-label'
          style={{
            fontSize: '14px',
            paddingTop: '10px'
          }}
        >
          <div>{task.DefaultDatePivotPoint === 'A' ? language.translate('application.dateAboveMustFallOn') : language.translate('application.daysToCount')}</div>
          <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '30px' }}>
            {Object.keys(daysOfTheWeek).map((day, index) => (<FormControlLabel
              key={day}
              control={
                <Checkbox
                  checked={task[`DefaultDate${day.ucFirst()}`]}
                  color='primary'
                  onChange={(e, isChecked) => {
                    task[`DefaultDate${day.ucFirst()}`] = isChecked
                    onSave(task)
                  }}
                  style={{ padding: '3px 12px' }}
                />}
              label={daysOfTheWeek[day]}
            />))}
          </div>
          {task.DefaultDatePivotPoint === 'A' &&
            <Typography
              variant='caption'
              style={{ padding: '0px 20px', color: palette.accent4Color }}
            >
              *If the start/due date does not fall on one of the above selected days, the system will set it to the next
              selected day
          </Typography>}
        </div>


        <Divider />
        <ListItem>
          <ListItemText
            primary={language.translate('application.allowStartDueDatesEdited')}
          />
          <ListItemSecondaryAction>
            <Switch
              color='primary'
              checked={(task.DatesEditable)}
              onChange={(obj, value) => {
                task.DatesEditable = !task.DatesEditable
                onSave(task)
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>

        <Divider />
        <div className='field-label' style={{ fontSize: '14px', paddingTop: '10px' }}>
          {language.translate('application.workSchedule')}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '20px' }}>
          <Select
            // style={{ marginLeft: '5px' }}
            name='work_schedule'
            ref='work_schedule'
            onChange={(e) => {
              task.WorkScheduleID = e.target.value
              onSave(task)
            }}
            value={(task.WorkScheduleID) ? task.WorkScheduleID : false}
          >
            {this.workScheduleMenu()}
          </Select>
        </div>
      </List>
    )
  }
}

TemplateTaskStartDueDateSettings.propTypes = {
  dispatch: PropTypes.func.isRequired,
  task: PropTypes.object,
  onSave: PropTypes.func,
  get: PropTypes.func.isRequired
}

TemplateTaskStartDueDateSettings.contextTypes = {
  language: PropTypes.object,
  user: PropTypes.object,
  muiTheme: PropTypes.object
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  dispatch,
  get: bindActionCreators(get, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(TemplateTaskStartDueDateSettings)

//export default TemplateTaskStartDueDateSettings
