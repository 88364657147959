import PropTypes from 'prop-types'
import React from 'react'
import { Button, List } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import TemplateTaskResourceListItem from './TemplateTaskResourceListItem'

class TemplateTaskResourceList extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      resources: null
    }
  }

  componentDidMount () {
    this.setState({ resources: this.props.resources })
  }

  componentDidUpdate (prevProps) {
    if (this.props.resources !== prevProps.resources) { this.setState({ resources: this.props.resources }) }
  }

  addNewItem () {
    const { resources } = this.state

    const newItem = {
      ResourceDescription: '',
      ProcessTemplateID: this.props.templateId,
      ProcessTemplateTaskID: this.props.taskId
    }

    this.props.onSaveResource(resources.length, newItem)
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const { resources } = this.state
    const { onDelete, onSaveResource } = this.props

    return (
      <div style={{ margin: '0px 20px' }}>
        {(!resources || !resources.length)
          ? <div>{language.translate('application.noResourceItems')}</div>
          : <div style={{ backgroundColor: palette.accent2Color }}>
            <List>
              {resources.map((item, index) => (
                <TemplateTaskResourceListItem
                  key={item.ID || index}
                  item={item}
                  onSave={resource => onSaveResource(index, resource)}
                  onRemoveField={onDelete}
                />
              ))}
            </List>
          </div>}
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
          <Button
            onClick={this.addNewItem.bind(this)}
            color='primary'
            variant='contained'
            style={{ margin: '10px' }}
          >
            <Add />
            {language.translate('application.addItem')}
          </Button>
        </div>
      </div>
    )
  }
}

TemplateTaskResourceList.propTypes = {
  templateId: PropTypes.string.isRequired,
  taskId: PropTypes.string.isRequired,
  resources: PropTypes.array,
  onSaveList: PropTypes.func,
  onSaveResource: PropTypes.func,
  onDelete: PropTypes.func
}

TemplateTaskResourceList.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default TemplateTaskResourceList
