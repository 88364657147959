import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Dialog, DialogContent, DialogTitle, Typography
} from '@material-ui/core'
import { push } from 'react-router-redux'
import objectAssign from 'object-assign'
import { Base64 } from 'js-base64'
import { Form } from 'formsy-react'
import Papa from 'papaparse'
import { updateTitle } from '~/actions/application'
import { post } from '~/actions/base'
import DataList from '~/components/Layout/DataList'
import AddIcon from '~/components/Layout/AddIcon'
import FormsyUpload from '~/components/Forms/FormsyUpload'
import WaitingDialog from '~/components/Layout/WaitingDialog'
import UserCard from './UserCard'

class Users extends React.Component {
  constructor (props) {
    super(props)

    this.parseUserImportFile = this.parseUserImportFile.bind(this)
    this.dataLoadCallback = this.dataLoadCallback.bind(this)
    this.createNewUser = this.createNewUser.bind(this)

    this.state = {
      users: [],
      update: false,
      showImportUsersDialog: false,
      importFileError: '',
      showWaitingDialog: false
    }
  }

  componentDidMount () {
    this.updateTitle()
  }

  componentDidUpdate () {
    this.updateTitle()
  }

  updateTitle () {
    const { language } = this.context
    this.props.dispatch(updateTitle(language.translate('application.user', [], true)))
  }

  dataLoadCallback (data) {
    this.setState({
      users: data
    })
  }

  handleUserSelect (userId) {
    this.props.push({
      pathname: this.props.location.pathname,
      query: objectAssign({},
        this.props.location.query,
        {
          ptype: 'user',
          pid: userId,
          usid: userId
        })
    })
  }

  createNewUser () {
    let query = JSON.parse(JSON.stringify(this.props.location.query))

    if (query.ptype === 'user') {
      delete query.ptype
      delete query.pid
      delete query.usid

      this.props.push({
        pathname: this.props.location.pathname,
        query
      })

      setTimeout(() => {
        this.createNewUser()
      }, 100)
    } else {
      this.props.push({
        pathname: this.props.location.pathname,
        query: objectAssign({},
          query,
          {
            ptype: 'user'
          })
      })
    }
  }

  forceUpdate () {
    this.setState({ update: !this.state.update })
  }

  parseUserImportFile (event) {
    const files = event.target.files

    Papa.parse(files[0], {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        const users = results.data

        if (results.errors.length) {
          this.setState({ importFileError: results.errors[0].message })
        } else {
          this.setState({ importFileError: '', showImportUsersDialog: false })

          // parse the user group list into an array
          users.map((user, index) => {
            if (user.UserGroupNameList) {
              users[index].UserGroupNameList = user.UserGroupNameList.split(',').filter(Boolean)
            } else {
              delete users[index].UserGroupNameList
            }

            // trim header properties in case of rogue spaces
            Object.keys(user).map((property) => {
              let newProperty = property.trim()

              if (newProperty !== property) {
                user[newProperty] = user[property]
                delete user[property]
              }
            })

            return users
          })

          this.saveUsers(results.data)
        }
      }
    })
  }

  saveUsers (users) {
    this.setState({ showWaitingDialog: true })
    const body = JSON.stringify({
      UserList: users
    })

    this.props.post('user/list', body, {
      onSuccess: (response) => {
        this.setState({
          showImportUsersDialog: false,
          showWaitingDialog: false
        })
      },

      onError: (error) => {
        this.setState({ showWaitingDialog: false })
      }
    })
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { users, showImportUsersDialog, importFileError, showWaitingDialog } = this.state
    const { language } = this.context
    const { location, mainContentWidth, push } = this.props

    return (
      <div>
        <DataList
          url='user/list'
          dataCallback={this.dataLoadCallback}
          fetchAll
          responseProperty='UserList'
          filterableColumns={[{
            name: language.translate('application.lastName'),
            property: 'LastName'
          }, {
            name: language.translate('application.firstName'),
            property: 'FirstName'
          }, {
            name: language.translate('application.position'),
            property: 'Position'
          }, {
            name: language.translate('application.emailAddress'),
            property: 'EmailAddress'
          }]}
          noDataText={language.translate('application.noUsers')}
          channelName={`Private-${this.context.user.accountID}-User-0`}
          events={['UserCreated', 'UserUpdated', 'UserDeleted']}
          location={location}
        >

          {users.map((user, index) => (
            <UserCard
              key={user.ID}
              user={user}
              onStatisticsClick={() => {
                this.props.push({
                  pathname: '/statistics',
                  query: {
                    type: 'user',
                    userId: user.ID,
                    by: 'month',
                    time: 'days'
                  }
                })
              }}
              onViewWorkListClick={() => {
                let query = {}

                query.search = Base64.encodeURI(JSON.stringify({
                  displayOnly: true,
                  value: user.ID,
                  displayText: 'Users',
                  displayValue: user.FullName,
                  operator: 'user-filter'
                }))

                push({
                  pathname: `/`,
                  query
                })
              }}
              onUserSelect={this.handleUserSelect.bind(this)}
              mainContentWidth={mainContentWidth}
              location={location}
            />))}
        </DataList>
        <AddIcon
          menuItems={[
            {
              text: language.translate('application.newUser'),
              onClick: this.createNewUser
            }, {
              text: language.translate('application.importUsers'),
              onClick: () => this.setState({ showImportUsersDialog: true })
            }]}
        />
        <Dialog
          open={showImportUsersDialog}
          onClose={() => this.setState({ showImportUsersDialog: false })}
        >
          <DialogTitle
            style={{
              backgroundColor: palette.headerBackgroundColor
            }}
            disableTypography
          >
            <Typography
              variant='h6'
              style={{ color: palette.alternateTextColor }}>
              {language.translate('application.importUsers')}
            </Typography>
          </DialogTitle>
          <DialogContent style={{ width: '370px' }}>
            <div>
              <div style={{ margin: '10px 0px' }}>
                {language.translate('application.importUsersHelpText')}
                <a
                  style={{ paddingLeft: '5px' }}
                  href='http://help.processplan.com/mass-user-import'
                  target='_blank'
                  rel='noopener noreferrer'
                >{language.translate('application.importUsersDocs')}</a>
              </div>
              {(importFileError)
                ? <div style={{ color: palette.errorColor }}>
                  {importFileError}
                </div>
                : null}
              <Form style={{ textAlign: 'center' }}>
                <FormsyUpload
                  name='Upload file'
                  multiple={false}
                  buttonLabel={language.translate('application.importUsers')}
                  accept='.csv'
                  onChange={this.parseUserImportFile}
                  defaultValue={null}
                />
              </Form>
            </div>
          </DialogContent>
        </Dialog>
        {(showWaitingDialog)
          ? <WaitingDialog
            messages={[
              language.translate('application.warmingUpProcessors'),
              language.translate('application.importingUsers'),
              language.translate('application.digitizingPersonalities'),
              language.translate('application.lastTimeMonkeyDidntSurvive'),
              language.translate('application.itsStillFasterThanAddingThemManually'),
              language.translate('application.goAheadHoldYourBreath'),
              language.translate('application.generatingWelcomeEmails')
            ]}
            pauseTime={5000}
          />
          : null}
      </div>
    )
  }
}

Users.propTypes = {
  dispatch: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  location: PropTypes.object,
  mainContentWidth: PropTypes.number.isRequired
}

Users.contextTypes = {
  muiTheme: PropTypes.object,
  language: PropTypes.object,
  user: PropTypes.object
}

const mapStateToProps = (state, ownProps) => ({
  title: state.application.title,
  mainContentWidth: state.application.mainContentWidth,
  location: ownProps.location
})

const mapDispatchToProps = dispatch => ({
  post: bindActionCreators(post, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Users)
