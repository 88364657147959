import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { AutoComplete } from 'material-ui'
import { get } from '../../actions/base'
import FormsyAutoComplete from './FormsyAutoComplete'

class FormsyTemplateAutoComplete extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      processTemplates: [],
      processTemplatesLoaded: false,
      searchText: '',
      templateOptions: [],
      processTemplateGroups: [],
      processTemplateGroupsLoaded: false,
      templateGroupOptions: [],
      groupSearchText: ''
    }
  }

  componentDidMount () {
    this.getProcessTemplates()
    this.getProcessTemplateGroups()
  }

  getProcessTemplates () {
    const { get, sortString, templateId } = this.props
    const { searchText } = this.state
    let hasQueryString = false

    let endpoint = 'processtemplate/list'

    if (sortString) {
      endpoint += `?orderby=${sortString}`
      hasQueryString = true
    }

    if (searchText) {
      endpoint += (hasQueryString) ? '&' : '?'

      endpoint += `filter1=Title(CONTAINS_TEXT)${searchText}`
    }

    get(endpoint, {
      onSuccess: (response) => {
        const templateOptions = []

        response.ProcessTemplateList.map((template) => {
          templateOptions.push(
            { key: template.ID, text: template.Title, value: template.Title }
          )
        })

        this.setState({
          processTemplates: response.ProcessTemplateList,
          templateOptions: templateOptions,
          processTemplatesLoaded: true,
          searchText: (templateId) ? response.ProcessTemplateList.filter((template) => (template.ID === templateId))[0].Title : ''
        })
      }
    })
  }

  getProcessTemplateGroups () {
    const { get } = this.props
    const { language } = this.context

    let endpoint = 'processtemplategroup/list'

    get(endpoint, {
      onSuccess: (response) => {
        const templateGroupOptions = [{
          key: null,
          text: language.translate('application.noFilter'),
          value: language.translate('application.noFilter')
        }]

        response.ProcessTemplateGroupList.map((group) => {
          templateGroupOptions.push(
            { key: group.ID, text: group.GroupName, value: group.GroupName }
          )
        })

        this.setState({
          processTemplateGroups: response.ProcessTemplateGroupList,
          processTemplateGroupsLoaded: true,
          templateGroupOptions
        })
      }
    })
  }

  handleChange (selectedOption) {
    const { onTemplateChange } = this.props

    if (onTemplateChange) { onTemplateChange(selectedOption.key) }
  }

  onBlur (e) {
    const { searchText } = this.state
    const { onTemplateChange, templateId } = this.props

    if (searchText === '' && templateId !== '') {
      if (onTemplateChange) { onTemplateChange('') }
    }
  }

  filterTemplatesByGroup (selectedGroupOption) {
    const { processTemplates } = this.state
    const templateOptions = []

    processTemplates.filter((template) => {
      if (template.ProcessGroupName === selectedGroupOption.value || !selectedGroupOption.key) {
        templateOptions.push(
          { key: template.ID, text: template.Title, value: template.Title }
        )
      }
    })

    this.setState({ templateOptions })
  }

  onGroupBlur (e) {
    // Try to get this working, but right now it is over-riding 'onNewRequest' execution when a group is clicked
    const { groupSearchText, templateGroupOptions } = this.state

    let filteredGroups = templateGroupOptions.filter((group) => (group.value.toLowerCase().includes(groupSearchText.toLowerCase())))

    if (filteredGroups.length) {
      this.setState({ groupSearchText: filteredGroups[0].value }, () => {
        this.filterTemplatesByGroup(filteredGroups[0])
      })
    }
  }

  render () {
    const {
      name, required, templateId, sortString, dispatch, onTemplateChange, get, ...rest
    } = this.props
    const { searchText, templateGroupOptions, groupSearchText, templateOptions } = this.state
    const { language } = this.context

    return (
      <div>
        <FormsyAutoComplete
          fullWidth
          name='group'
          dataSource={templateGroupOptions}
          onNewRequest={this.filterTemplatesByGroup.bind(this)}
          onUpdateInput={(text) => {
            this.setState({ groupSearchText: text })
          }}
          searchText={groupSearchText}
          //onBlur={this.onGroupBlur.bind(this)}
          onFocus={(e) => {
            e.target.select()
          }}
          ignoreFilterOnFocus
          floatingLabelText={language.translate('application.filterTemplatesByGroup')}
          maxSearchResults={1000}
        />
        <FormsyAutoComplete
          fullWidth
          name={name}
          dataSource={templateOptions}
          onNewRequest={this.handleChange.bind(this)}
          onUpdateInput={(text) => {
            this.setState({ searchText: text })
          }}
          searchText={searchText}
          onBlur={this.onBlur.bind(this)}
          value={templateId || null}
          ignoreFilterOnFocus
          openOnFocus
          onFocus={(e) => {
            e.target.select()
          }}
          maxSearchResults={10}
          required={required}
          validationErrors={language.messages.validationErrors}
          {...rest}
          // menuProps={{onClick: () => this.setState({itemClicked: true})}}
        />
      </div>
    )
  }
}

FormsyTemplateAutoComplete.propTypes = {
  required: PropTypes.bool,
  templateId: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onTemplateChange: PropTypes.func,
  sortString: PropTypes.string,
  get: PropTypes.func
}

FormsyTemplateAutoComplete.contextTypes = {
  language: PropTypes.object
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  dispatch,
  get: bindActionCreators(get, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(FormsyTemplateAutoComplete)
