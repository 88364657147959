import PropTypes from 'prop-types'
import React from 'react'
import { Avatar, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core'
import { MenuItem } from 'material-ui'
import { Form } from 'formsy-react'
import ExternalSource from '../Forms/ExternalSource'
import DeleteIconButton from '../Layout/DeleteIconButton'

class UserListItem extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      canSubmit: true
    }
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const { member, removeUserFromGroup, addUserToGroup, onMemberSelect, disableEdit } = this.props
    const { canSubmit } = this.state

    return (
      (member.FullName)
        ? <ListItem button={(!disableEdit)} onClick={() => {
          if (!disableEdit) {
            onMemberSelect(member.ID)
          }
        }}>
          <Avatar src={member.ProfilePicURL} />
          <ListItemText
            primary={member.FullName}
            secondary={member.Position} />
          {(!disableEdit &&
            <ListItemSecondaryAction>
              <DeleteIconButton onDelete={() => removeUserFromGroup(member.ID)} />
            </ListItemSecondaryAction>)}
        </ListItem>
        : <ListItem style={{ paddingLeft: '20px' }}>
          <ListItemText
            primary={
              <Form
                onValid={() => this.setState({ canSubmit: true })}
                onInvalid={() => this.setState({ canSubmit: false })}
              >
                <ExternalSource
                  fullWidth
                  name='member_select'
                  floatingLabelText={language.translate('application.selectMember')}
                  required
                  onChange={(value, text) => {
                    if (canSubmit && value) { addUserToGroup(value) }
                  }}
                  searchOverride={(filter) => (`filter1=FullName(CONTAINS_TEXT)${filter}`)}
                  itemTemplate={user => (
                    <MenuItem
                      style={{ paddingBottom: '10px' }}
                      primaryText={
                        <div style={{ paddingTop: '15px' }}>
                          <div style={{ lineHeight: 'initial' }}>{user.FullName}</div>
                          <div style={{
                            lineHeight: 'initial',
                            fontSize: '12px',
                            color: palette.accent4Color
                          }}
                          >{user.Position}</div>
                        </div>}
                      leftIcon={<Avatar
                        src={user.ProfilePicURL}
                        style={{ height: '40px', width: '40px' }}
                      />}
                    />
                  )}
                  responseProperty={'UserList'}
                  searchUrl={'user/list'}
                  menuProps={{ maxHeight: 200 }}
                  valuePropertyName='ID'
                  textPropertyName='FullName'
                />
              </Form>
            } />
          <ListItemSecondaryAction style={{ top: '20%' }}>
            <DeleteIconButton onDelete={() => removeUserFromGroup()} />
          </ListItemSecondaryAction>
        </ListItem>
    )
  }
}

UserListItem.propTypes = {
  member: PropTypes.object.isRequired,
  removeUserFromGroup: PropTypes.func.isRequired,
  addUserToGroup: PropTypes.func.isRequired,
  onMemberSelect: PropTypes.func,
  disableEdit: PropTypes.bool
}

UserListItem.contextTypes = {
  location: PropTypes.object,
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default UserListItem
