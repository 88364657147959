import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'react-router-redux'
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Paper, Tooltip, Typography } from '@material-ui/core'
import AutoComplete from '../components/Forms/AutoComplete'
import { Storage } from '@material-ui/icons'
import { Base64 } from 'js-base64'
import objectAssign from 'object-assign'
import { updateTitle } from '../actions/application'
import { get } from '../actions/base'
import { GeneratedIcon, Icon } from '../components/Icon'
import Diagram from '../components/Diagram/Diagram'
import FormHelper from '../businessLogic/formHelper'
import * as Icons from '../components/Icon/selection.json'

class Relationships extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      mainProcess: {},
      templates: [],
      diagramModel: {},
      isLoading: true,
      isSaving: false,
      account: {},
      accountLoaded: false,
      showInstanceSelect: false,
      instanceList: [],
      instancesLoaded: false,
      filterSearchText: ''
    }
  }

  componentDidMount () {
    this.fetchMainProcess()

    this.updateTitle()
  }

  componentDidUpdate (prevProps, prevState) {
    this.updateTitle()

    if (!prevState.showInstanceSelect && this.state.showInstanceSelect && !this.state.instancesLoaded) {
      this.fetchInstances()
    }

    if (prevProps.params.instanceid !== this.props.params.instanceid || prevProps.params.templateid !== this.props.params.templateid) {
      this.fetchMainProcess()
    }

    if (prevProps.params.templateid !== this.props.params.templateid) {
      this.setState({ instancesLoaded: false, instanceList: [], isLoading: true })
    }
  }

  fetchMainProcess () {
    const { get, params } = this.props

    let endpoint = (params.instanceid) ? `processinstance/${params.instanceid}` : `processtemplate/${params.templateid}`
    endpoint += '/field/list'

    get(endpoint, {
      onSuccess: (response) => {
        let mainProcess = (params.instanceid) ? response.ProcessInstance : response.ProcessTemplate

        if (params.instanceid) {
          mainProcess.IconName = mainProcess.ProcessTemplateIconName
          mainProcess.Title = mainProcess.ProcessTemplateTitle
          mainProcess.ProcessInstanceID = mainProcess.ID
          mainProcess.ID = mainProcess.ProcessTemplateID
          mainProcess.ProcessInstanceFieldList = response.ProcessInstanceFieldList
        } else {
          mainProcess.ProcessTemplateFieldList = response.ProcessTemplateFieldList
        }

        mainProcess.NodeType = 'main'

        if (params.instanceid) {
          mainProcess.TotalInstanceCount = 1
          mainProcess.PendingInstanceCount = (mainProcess.Status === 'in-progress') ? 1 : 0
          this.setMainProcessStateData(mainProcess)
        } else {
          // get pending instances count
          this.fetchTemplatePendingCount(mainProcess.ID, (count) => {
            mainProcess.PendingInstanceCount = count

            // get total instance count
            this.fetchTemplateInstanceCount(mainProcess.ID, (count) => {
              mainProcess.TotalInstanceCount = count

              this.setMainProcessStateData(mainProcess)
            })
          })
        }
      }
    })
  }

  setMainProcessStateData (mainProcess) {
    let templates = []

    mainProcess.ID = 'main-' + mainProcess.ID

    // now that we have all the info we can create the node
    const data = {
      nodeDataArray: [this.templateToNode(mainProcess)],
      linkDataArray: []
    }

    templates.push(mainProcess)

    this.setState({
      mainProcess: mainProcess,
      diagramModel: data,
      templates
    }, () => {
      this.fetchChildren(() => {
        let fieldList = mainProcess.ProcessTemplateFieldList || mainProcess.ProcessInstanceFieldList
        let lookupFields = fieldList.filter((field) => (field.ProcessInstanceLookupTemplateID && field.FieldType !== 'INSTLINK'))
        let lookupFieldIds = lookupFields.map((field) => (field.ProcessInstanceLookupTemplateID))
        let dedupLookupFieldIds = Array.from(new Set(lookupFieldIds))

        let loadedTemplateIds = this.state.templates.map((template) => (template.ID))
        dedupLookupFieldIds.map((id) => {
          if (loadedTemplateIds.includes(id)) {
            let { diagramModel } = this.state
            let relation = { from: id, to: mainProcess.ID }
            diagramModel.linkDataArray.push(relation)
            this.setState({ diagramModel })
            return
          }

          let field = lookupFields.filter((lookupField) => (lookupField.ProcessInstanceLookupTemplateID === id))[0]

          if (field.ValueID && field.ValueID.includes(',')) {
            let ids = field.ValueID.split(',')

            ids.map((valueId, index) => {
              this.fetchParent(id, valueId, mainProcess.ID, `-${index}`)
            })
          } else {
            this.fetchParent(id, field.ValueID, mainProcess.ID)
          }
        })
      })
    })
  }

  fetchParent (id, instanceId, connectToId, appendToParentId = '') {
    const { get, params } = this.props
    const { templates, diagramModel } = this.state
    let endpoint = (instanceId) ? `processinstance/${instanceId}` : `processtemplate/${id}`
    endpoint += '/field/list'

    get(endpoint, {
      onSuccess: (response) => {
        let process = (instanceId) ? response.ProcessInstance : response.ProcessTemplate

        if (instanceId) {
          process.IconName = process.ProcessTemplateIconName
          process.Title = process.ProcessTemplateTitle
          process.ProcessInstanceID = process.ID
          process.ID = process.ProcessTemplateID
          process.ProcessInstanceFieldList = response.ProcessInstanceFieldList
        } else {
          process.ProcessTemplateFieldList = response.ProcessTemplateFieldList
        }

        process.NodeType = 'parent'

        this.fetchTemplatePendingCount(process.ID, (count) => {
          process.PendingInstanceCount = (instanceId) ? (process.Status === 'in-progress') ? 1 : 0 : count

          this.fetchTemplateInstanceCount(process.ID, (count) => {
            process.TotalInstanceCount = (instanceId) ? 1 : (params.instanceid) ? 0 : count

            process.ID = 'parent-' + process.ID + appendToParentId
            templates.push(process)

            diagramModel.nodeDataArray.push(this.templateToNode(process))
            let relation = { from: process.ID, to: connectToId }
            diagramModel.linkDataArray.push(relation)

            this.setState({
              templates,
              diagramModel
            }, () => {
              if (this.diagram) {
                setTimeout(() => { this.diagram.zoomToFit() }, 20)
              }
            })
          })
        })
      }
    })
  }

  fetchInstances (filter) {
    const { get, params } = this.props

    let endpoint = `processtemplate/${params.templateid}/instance/list`

    if (filter) {
      endpoint += `?filter1=ProcessInstanceFieldList[1].value(CONTAINS_TEXT)${filter}`
    }

    get(endpoint, {
      onSuccess: (response) => {
        this.setState({
          instanceList: response.ProcessInstanceList,
          instancesLoaded: true
        })
      }
    })
  }

  fetchTemplateInstanceCount (templateId, callback) {
    const { get } = this.props

    get(`processtemplate/${templateId}/instance/list/count`, {
      onSuccess: (response) => {
        callback(response.Count)
      }
    })
  }

  fetchTemplatePendingCount (templateId, callback) {
    const { get } = this.props

    get(`processtemplate/${templateId}/instance/list/pending/count`, {
      onSuccess: (response) => {
        callback(response.Count)
      }
    })
  }

  fetchChildren (callback) {
    const { get, params } = this.props
    let { diagramModel, mainProcess } = this.state

    let endpoint = `processtemplate/list/relatedto/template/${params.templateid}`

    if (params.instanceid) {
      endpoint += `/instance/${params.instanceid}`
    }

    get(endpoint, {
      onSuccess: (response) => {
        const { templates } = this.state
        let loadedTemplateIds = templates.map((template) => (template.ID))

        response.ProcessTemplateList.map((child) => {
          if (!loadedTemplateIds.includes(child.ID)) {
            child.InstanceFields = (response.ProcessTemplateFieldList) ? response.ProcessTemplateFieldList.filter((field) => (field.ProcessTemplateID === child.ID)) : null
            child.NodeType = 'child'
            child.ID = 'child-' + child.ID
            diagramModel.nodeDataArray.push(this.templateToNode(child))
            diagramModel.linkDataArray.push({ from: mainProcess.ID, to: child.ID })
            templates.push(child)
          }
        })

        this.setState({
          templates: templates,
          diagramModel: diagramModel,
          isLoading: false
        }, () => {
          if (this.diagram) {
            this.diagram.zoomToFit()
          }
          callback()
        })
      }
    })
  }

  templateToNode (template) {
    let subtext = ''
    subtext += (template.HeaderField1_Value) ? `${template.HeaderField1_Value}\n\n` : ''
    subtext += `Pending: ${template.PendingInstanceCount || 0}   Total: ${template.TotalInstanceCount || 0}`

    const icon = (template.IconName) ? Icons.icons.find(icon => icon.properties.name === template.IconName).icon : null

    let svgIcon = null

    if (icon) {
      svgIcon = icon.paths.map((path, index) => {
        return {
          path: `${path}`,
          fill: icon.attrs[index].fill,
          stroke: (icon.attrs[index].stroke) ? icon.attrs[index].stroke : null,
          strokeWidth: (icon.attrs[index].strokeWidth) ? icon.attrs[index].strokeWidth : null,
          strokeLinejoin: (icon.attrs[index].strokeLinejoin) ? icon.attrs[index].strokeLinejoin : null,
          strokeLinecap: (icon.attrs[index].strokeLinecap) ? icon.attrs[index].strokeLinecap : null,
          strokeMiterlimit: (icon.attrs[index].strokeMiterlimit) ? icon.attrs[index].strokeMiterlimit : null
        }
      })
    }

    return ({
      backgroundColor: (!template.TotalInstanceCount) ? '#8a8a8a' : (!template.PendingInstanceCount) ? '#227eae' : '#F3B527',
      category: 'TaskNodeTemplate',
      foregroundColor: '#ffffff',
      isEditable: false,
      isExternalProcess: false,
      key: template.ID,
      id: this.parseId(template.ID),
      instanceId: template.ProcessInstanceID,
      status: 'unchanged',
      fieldList: template.ProcessInstanceFieldList || template.ProcessTemplateFieldList,
      instanceFields: template.InstanceFields,
      text: template.Title,
      type: template.NodeType || '',
      pendingInstanceCount: template.PendingInstanceCount,
      totalInstanceCount: template.TotalInstanceCount,
      subText: subtext,
      svgIcon: svgIcon
    })
  }

  updateTitle () {
    const { language } = this.context

    this.props.dispatch(updateTitle(language.translate('application.relationships')))
  }

  downloadDiagramImage (svg) {
    const { mainProcess } = this.state

    const svgString = new window.XMLSerializer().serializeToString(svg)
    const svgBlob = new window.Blob([svgString], { type: 'image/svg+xml;charset=utf-8' })

    const svgUrl = window.URL.createObjectURL(svgBlob)
    const filename = `${mainProcess.Title}.svg`
    const downloadLink = document.createElement('a')
    downloadLink.href = svgUrl
    downloadLink.download = filename

    // IE 11
    if (window.navigator.msSaveBlob !== undefined) {
      window.navigator.msSaveBlob(svgBlob, filename)
      return
    }

    document.body.appendChild(downloadLink)
    window.requestAnimationFrame(() => {
      downloadLink.click()
      window.URL.revokeObjectURL(svgUrl)
      document.body.removeChild(downloadLink)
    })
  }

  parseId (id) {
    return id.split('-')[1]
  }

  startProcess (nodeData) {
    const { push } = this.props
    const templateId = nodeData.id

    let newQuery = objectAssign({},
      this.props.location.query,
      {
        ptype: 'process_template',
        pid: templateId,
        ptsid: templateId
      })

    // clear out any fld query string values that auto populate start process panel
    Object.keys(newQuery).map((query) => {
      if (query.substr(0, 3) === 'fld') {
        delete newQuery[query]
      }
    })

    if (nodeData.key.split('-')[0] !== 'child' || !this.props.params.instanceid) {
      push({
        pathname: this.props.location.pathname,
        query: newQuery
      })
    } else {
      this.props.get(`processtemplate/${templateId}/field/list/visibleatstart`, {
        onSuccess: (response) => {
          // find the field that will hold this relationship in the start panel that will open
          let lookupFieldIndex = response.ProcessTemplateFieldList.findIndex((field) => {
            return (field.ProcessInstanceLookupTemplateID === this.props.params.templateid)
          })

          if (lookupFieldIndex) {
            newQuery[`fld${lookupFieldIndex + 1}`] = this.state.mainProcess.HeaderField1_Value
          }

          let targetField = response.ProcessTemplateFieldList[lookupFieldIndex]
          let instanceFilters = this.parseInstanceFiltersFromMetaData(targetField.MetaData, response.ProcessTemplateFieldList)
          instanceFilters.map((filter) => { newQuery[filter.name] = filter.value })

          push({
            pathname: this.props.location.pathname,
            query: newQuery
          })
        }
      })
    }
  }

  parseInstanceFiltersFromMetaData (metaData, targetFieldList) {
    let { mainProcess } = this.state
    let filters = []

    let urlParams = new URLSearchParams(metaData)

    for (let key of urlParams.keys()) {
      let param = urlParams.get(key)

      if (param.includes('ProcessInstanceFieldList')) {
        let destinationFieldID = param.match(/(\[fld_(.+))/g)[0].split('_')[1]
        let templateFieldID = param.match(/(List\[(.+)\].)/g)[0].split('[')[1].split(']')[0]
        let destinationIndex = targetFieldList.findIndex((field) => (field.ID === destinationFieldID)) + 1
        let destinationValue = mainProcess.ProcessInstanceFieldList.find((field) => (field.ProcessTemplateFieldID === templateFieldID)).Value

        filters.push({ name: `fld${destinationIndex}`, value: `${destinationValue}` })
      }
    }

    return filters
  }

  openProcessFields () {
    const { push, location, params: { instanceid } } = this.props

    push({
      pathname: location.pathname,
      query: objectAssign({},
        location.query,
        {
          ptype: 'pifields',
          pid: instanceid
        })
    })
  }

  goToTable (nodeData) {
    const { push } = this.props
    let query = {}

    if (this.props.params.instanceid && nodeData.type === 'main') {
      query.search = Base64.encodeURI(JSON.stringify({
        property: 'ID',
        value: this.props.params.instanceid,
        displayValue: this.state.mainProcess.HeaderField1_Value,
        displayText: this.state.mainProcess.HeaderField1_Name,
        operator: '(EQUALTO_ID)'
      }))
    }

    if (this.props.params.instanceid && nodeData.type === 'parent') {
      let childField = this.state.mainProcess.ProcessInstanceFieldList.find((field) => (field.ProcessInstanceLookupTemplateID === nodeData.id))
      let Value = childField.Value
      let ValueID = childField.ValueID

      if (ValueID.includes(',')) {
        let indexToUse = parseInt(nodeData.key.split('-')[2], 10)

        Value = Value.split(',')[indexToUse]
        ValueID = ValueID.split(',')[indexToUse]
      }

      if (childField && childField.ID) {
        query.search = Base64.encodeURI(JSON.stringify({
          property: 'ID',
          value: ValueID,
          displayValue: Value,
          displayText: 'Process Field',
          operator: '(EQUALTO_ID)'
        }))
      }
    }

    if (this.props.params.instanceid && nodeData.type === 'child') {
      let template = this.state.templates.filter((template) => (template.NodeType === 'child' && this.parseId(template.ID) === nodeData.id))[0]
      let instanceField = template.InstanceFields.filter((field) => (this.props.params.templateid === field.ProcessInstanceLookupTemplateID))[0]
      query.search = Base64.encodeURI(JSON.stringify({
        property: `ProcessInstanceFieldList[${instanceField.ID}].valueid`,
        value: this.props.params.instanceid,
        displayValue: this.state.mainProcess.HeaderField1_Value,
        displayText: instanceField.FieldName,
        operator: '(EQUALTO_TEXT)'
      }))
    }

    push({
      pathname: `/process-instances-grid/${nodeData.id}`,
      query
    })
  }

  render () {
    const { language, user } = this.context
    const palette = this.context.muiTheme.palette
    const { diagramModel, mainProcess, showInstanceSelect, instancesLoaded, instanceList, filterSearchText } = this.state
    const { push, params } = this.props

    return (
      (this.state.isLoading)
        ? <CircularProgress className='loader' />
        : <Paper elevation={4} style={{ height: '100%', position: 'relative' }}>
          <div style={{ padding: '10px', display: 'flex', justifyContent: 'space-between' }}>
            <div style={{
              fontSize: '16px',
              color: palette.accent3Color,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start'
            }}
            >
              {(mainProcess.IconName)
                ? <Icon icon={mainProcess.IconName} size={40} />
                : <GeneratedIcon
                  style={{ marginRight: '10px' }}
                  className='clickable icon-selector'
                  text={mainProcess.Title}
                  randomizer={mainProcess.ID}
                  onClick={() => this.setState({ showIconSelector: true })}
                />}
              <div style={{ marginLeft: '10px' }}>
                {mainProcess.Title}
                {(params.instanceid)
                  ? `: ${mainProcess.HeaderField1_Value}`
                  : null}
              </div>
            </div>
            {(params.instanceid)
              ? <Tooltip title={language.translate('application.processField', [], true)}>
                <IconButton
                  onClick={() => this.openProcessFields()}
                >
                  <Storage nativeColor={palette.accent6Color} />
                </IconButton>
              </Tooltip> : null}
          </div>
          {(this.props.params.instanceid)
            ? <div style={{
              position: 'absolute',
              paddingLeft: '10px',
              fontSize: '11px',
              color: palette.accent4Color
            }}
            >
              <div>
                {FormHelper.decodeHTML(mainProcess.HeaderField1_Name)}: {FormHelper.decodeHTML(mainProcess.HeaderField1_Value)}
              </div>
              <div>
                {FormHelper.decodeHTML(mainProcess.HeaderField2_Name)}: {FormHelper.decodeHTML(mainProcess.HeaderField2_Value)}
              </div>
              <div>
                {FormHelper.decodeHTML(mainProcess.HeaderField3_Name)}: {FormHelper.decodeHTML(mainProcess.HeaderField3_Value)}
              </div>
            </div>
            : null}
          <div style={{
            height: (this.props.params.instanceid) ? 'calc(100% - 10px)' : '100%',
            margin: '0px',
            position: 'absolute',
            width: '100%'
          }}>
            <Diagram
              diagramModel={JSON.parse(JSON.stringify(diagramModel))}
              diagramType='template'
              disableEditing
              disableDragTree
              isVertical
              diagramRef={(diagram) => { this.diagram = diagram }}
              disableReformatValidation
              onTaskDoubleClicked={(nodeData) => {
                this.goToTable(nodeData)
              }}
              downloadDiagramImage={this.downloadDiagramImage.bind(this)}
              diagramMenuItems={[
                {
                  label: language.translate('application.filterByProcess'),
                  action: (nodeData) => {
                    this.setState({ showInstanceSelect: true })
                  }
                }, {
                  label: language.translate('application.removeFilter'),
                  action: (nodeData) => {
                    push(`/relationships/${this.props.params.templateid}`)
                  },
                  shouldDisplay: (nodeData) => {
                    return !!(this.props.params.instanceid)
                  }
                }
              ]}
              taskMenuItems={[
                {
                  label: language.translate('application.processTable'),
                  action: (nodeData) => {
                    this.goToTable(nodeData)
                  }
                }, {
                  label: language.translate('application.centerOnThisProcess'),
                  action: (nodeData) => {
                    let endpoint = `/relationships/${nodeData.id}`

                    if (nodeData.instanceId) {
                      endpoint += `/${nodeData.instanceId}`
                    }

                    push(endpoint)
                  },
                  shouldDisplay: (nodeData) => {
                    return (nodeData.key !== this.state.mainProcess.ID)
                  }
                }, {
                  label: language.translate('application.filterByProcess'),
                  action: (nodeData) => {
                    this.setState({ showInstanceSelect: true })
                  },
                  shouldDisplay: (nodeData) => {
                    return (nodeData.key === this.state.mainProcess.ID)
                  }
                }, {
                  label: language.translate('application.removeFilter'),
                  action: (nodeData) => {
                    push(`/relationships/${this.props.params.templateid}`)
                  },
                  shouldDisplay: (nodeData) => {
                    return !!(nodeData.key === this.state.mainProcess.ID && this.props.params.instanceid)
                  }
                }, {
                  label: language.translate('application.startProcess'),
                  action: (nodeData) => {
                    this.startProcess(nodeData)
                  }
                }, {
                  label: language.translate('application.viewTemplate'),
                  action: (nodeData) => {
                    push(`/process-template/${nodeData.id}`)
                  }
                }
              ]}
            />
          </div>
          {(instancesLoaded)
            ? <Dialog
              open={showInstanceSelect}
              onClose={() => { this.setState({ showInstanceSelect: false, filterSearchText: '' }) }}
            >
              <DialogTitle
                style={{
                  backgroundColor: palette.headerBackgroundColor
                }}
                disableTypography
              >
                <Typography
                  variant='h6'
                  style={{ color: palette.alternateTextColor }}>
                  {language.translate('application.selectProcess')}
                </Typography>
              </DialogTitle>
              <DialogContent style={{ width: '400px', paddingTop: '20px' }}>
                <AutoComplete
                  onChange={(selectedItem) => {
                    if (selectedItem.value) {
                      push(`/relationships/${this.props.params.templateid}/${selectedItem.value}`)
                    }

                    this.setState({ filterSearchText: '', showInstanceSelect: false, instancesLoaded: false })
                  }}
                  menuHeight={300}
                  items={instanceList.map((instance) => {
                    if (instance.HeaderField1_Value) {
                      return { label: instance.HeaderField1_Value, value: instance.ID }
                    } else {
                      return null
                    }
                  }).filter(Boolean)}
                  autoFocus
                  hintText={language.translate('application.search')}
                />
                <div style={{ textAlign: 'right' }}>
                  <Button
                    onClick={() => {
                      this.setState({
                        showInstanceSelect: false,
                        filterSearchText: '',
                        instancesLoaded: false
                      })
                    }}
                    variant='contained'
                    color='primary'
                    style={{ margin: '10px' }}
                  >
                    {language.translate('application.cancel')}
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
            : null}
        </Paper>
    )
  }
}

Relationships.propTypes = {
  params: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  get: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired
}

Relationships.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object,
  location: PropTypes.object,
  user: PropTypes.object
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  dispatch,
  get: bindActionCreators(get, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Relationships)
