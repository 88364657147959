import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get, post } from '../actions/base'
import { push } from 'react-router-redux'
import {
  Card, CircularProgress, Button, Divider, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction,
  ListItemText, Menu, MenuItem
} from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import RightPanelContent from '../components/Layout/RightPanelContent'
import { showUndoMessage } from '../actions/snackbar'
import Icon from '../components/Icon/Icon'
import IntegrationConfigurationForm from '../components/IntegrationConfigurationForm'
import DeleteMenuItem from '../components/Layout/DeleteMenuItem'

class IntegrationConfiguration extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      isLoaded: false,
      integrations: [],
      integrationTypes: [],
      showConfigurationForm: false,
      editingItemIndex: null,
      menuAnchor: null,
      menuIndex: null
    }
  }

  componentDidMount () {
    this.fetchIntegrations()
    this.fetchIntegrationTypes()
  }

  fetchIntegrations () {
    this.props.get('integration/list/configured', {
      onSuccess: (response) => {
        this.setState({
          integrations: response.IntegrationList,
          isLoaded: true
        })
      }
    })
  }

  fetchIntegrationTypes () {
    this.props.get('integrationtype/list/configurable', {
      onSuccess: (response) => {
        this.setState({
          integrationTypes: response.IntegrationTypeList
        })
      }
    })
  }

  addNewIntegration () {
    const { integrations } = this.state
    integrations.push({})

    this.setState({
      showConfigurationForm: true,
      editingItemIndex: integrations.length - 1,
      integrations
    })
  }

  saveIntegration () {
    const { integrations, editingItemIndex } = this.state

    const body = JSON.stringify({
      Integration: integrations[editingItemIndex]
    })

    const endpoint = (integrations[editingItemIndex].ID) ? `/${integrations[editingItemIndex].ID}` : ''

    this.props.post(`integration${endpoint}`, body, {
      onSuccess: (response) => {
        integrations[editingItemIndex] = response.Integration
        this.setState({
          integrations,
          showConfigurationForm: false,
          editingItemIndex: null
        })
      }
    })
  }

  deleteIntegration (id) {
    const body = JSON.stringify({})
    const { language } = this.context

    this.props.post(`integration/${id}/delete`, body, {
      onSuccess: (response) => {
        this.fetchIntegrations()

        this.props.showUndoMessage(
          language.translate('application.integrationRemoved'),
          () => {
            this.props.post(`integration/${id}/delete/undo`, body, {
              onSuccess: (response) => {
                this.fetchIntegrations()
              }
            })
          }
        )
      }
    })
  }

  updateField (fieldName, value) {
    const { editingItemIndex, integrations } = this.state

    integrations[editingItemIndex][fieldName] = value

    this.setState({ integrations })
  }

  render () {
    const { language } = this.context
    const { integrations, isLoaded, editingItemIndex, integrationTypes, showConfigurationForm, menuAnchor, menuIndex } = this.state
    const { close } = this.props

    return (
      <RightPanelContent
        title={language.translate('application.integration', [], true)}
        closePanel={() => close()}
      >
        {(showConfigurationForm)
          ? <Card
            className='card'
            style={{
              margin: '10px',
              padding: '10px'
            }}
          >
            <IntegrationConfigurationForm
              integration={integrations[editingItemIndex]}
              updateField={this.updateField.bind(this)}
              integrationTypes={integrationTypes}
              onSave={this.saveIntegration.bind(this)}
              onCancel={() => this.setState({ showConfigurationForm: false, editingItemIndex: null })}
            />
          </Card>
          : <div>
            <Card
              className='card'
              style={{
                margin: '10px'
              }}
            >
              {(!isLoaded)
                ? <CircularProgress className='loader' />
                : <List disablePadding>
                  {integrations.map((integration, index) => (
                    (integration.ID)
                      ? React.Children.toArray([
                        <ListItem
                          key={index}
                        >
                          <ListItemIcon>
                            <Icon
                              icon={integration.IntegrationTypeIconName}
                              size={40}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={integration.Description} />
                          <ListItemSecondaryAction>
                            <IconButton
                              style={{
                                padding: '3px'
                              }}
                              onClick={(e) => {
                                this.setState({ menuAnchor: e.currentTarget, menuIndex: index })
                              }}
                            >
                              <MoreVert />
                            </IconButton>
                            <Menu
                              className='menu'
                              anchorEl={menuAnchor}
                              open={Boolean(menuAnchor) && menuIndex === index}
                              onClose={() => { this.setState({ menuAnchor: null, menuIndex: null }) }}
                              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                              getContentAnchorEl={null}
                            >
                              <MenuItem onClick={() => {
                                this.setState({
                                  editingItemIndex: index,
                                  showConfigurationForm: true,
                                  menuAnchor: null,
                                  menuIndex: null
                                })
                              }}
                              >{language.translate('application.edit')}</MenuItem>
                              <DeleteMenuItem
                                onDelete={() => {
                                  this.deleteIntegration(integration.ID)
                                  this.setState({ menuAnchor: null, menuIndex: null })
                                }}
                              />
                            </Menu>
                          </ListItemSecondaryAction>
                        </ListItem>,
                        (index < integrations.length - 1) ? <Divider key={index + 10000} /> : null
                      ])
                      : null
                  ))}
                </List>
              }
            </Card>
            <Button
              color='primary'
              style={{ margin: '10px' }}
              variant='contained'
              onClick={this.addNewIntegration.bind(this)}
            >
              {language.translate('application.add')}
            </Button>
          </div>
        }
      </RightPanelContent>
    )
  }
}

IntegrationConfiguration.propTypes = {
  dispatch: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  user: PropTypes.object,
  push: PropTypes.func.isRequired,
  showUndoMessage: PropTypes.func.isRequired
}

IntegrationConfiguration.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object,
  location: PropTypes.object
}

const mapStateToProps = state => ({
  user: state.auth
})

const mapDispatchToProps = dispatch => ({
  dispatch,
  get: bindActionCreators(get, dispatch),
  post: bindActionCreators(post, dispatch),
  push: bindActionCreators(push, dispatch),
  showUndoMessage: bindActionCreators(showUndoMessage, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationConfiguration)
