import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get, post } from '~/actions/base'
import { push } from 'react-router-redux'
import {
  Card, CircularProgress, Button, Divider, List
} from '@material-ui/core'
import { Add } from '@material-ui/icons'
import RightPanelContent from '~/components/Layout/RightPanelContent'
import { showUndoMessage } from '~/actions/snackbar'
import TextBlockListItem from './TextBlockListItem'

class TextBlockTemplates extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      textBlocks: [],
      textBlocksLoaded: false,
      fieldTokens: {},
      fieldTokensLoaded: false
    }
  }

  componentDidMount () {
    this.fetchTextBlocks()
    this.fetchTextBlockTokens()
  }

  fetchTextBlocks () {
    this.props.get('textblock/list', {
      onSuccess: (response) => {
        this.setState({
          textBlocks: response.TextBlockList,
          textBlocksLoaded: true
        })
      }
    })
  }

  fetchTextBlockTokens () {
    this.props.get('textblock/fieldtoken/list', {
      onSuccess: (response) => {
        this.setState({
          fieldTokens: response.TextValueList,
          fieldTokensLoaded: true
        })
      }
    })
  }

  addNewTextBlock () {
    const { textBlocks } = this.state
    const newItem = {
      TemplateTitle: ''
    }

    const newTextBlocks = textBlocks.concat(newItem)

    this.setState({ textBlocks: newTextBlocks })
  }

  saveTextBlock (index, template) {
    const { textBlocks } = this.state
    const endpoint = (!template.ID) ? 'textblock' : `textblock/${template.ID}`

    const body = JSON.stringify({
      TextBlock: template
    })

    this.props.post(endpoint, body, {
      onSuccess: (response) => {
        textBlocks[index] = response.TextBlock

        this.setState({ textBlocks })
      }
    })
  }

  deleteTextBlock (index, item) {
    const { language } = this.context
    const body = JSON.stringify({})

    if (!item.ID) {
      const { textBlocks } = this.state
      textBlocks.splice(index, 1)

      this.setState({ textBlocks })
    } else {
      this.props.post(`textblock/${item.ID}/delete`, body, {
        onSuccess: (response) => {
          this.fetchTextBlocks()

          this.props.showUndoMessage(
            language.translate('application.textBlockRemoved'),
            () => {
              this.props.post(`textblock/${item.ID}/delete/undo`, body, {
                onSuccess: (response) => {
                  this.fetchTextBlocks()
                }
              })
            }
          )
        }
      })
    }
  }

  updateField (fieldName, value) {
    const { editingItemIndex, textBlocks } = this.state

    textBlocks[editingItemIndex][fieldName] = value

    this.setState({ textBlocks })
  }

  render () {
    const { language } = this.context
    const { textBlocks, textBlocksLoaded, fieldTokens, fieldTokensLoaded } = this.state
    const { close } = this.props

    return (
      <RightPanelContent
        title={language.translate('application.textBlock', [], true)}
        closePanel={() => close()}
      >
        {(textBlocksLoaded)
          ? <div style={{ padding: '15px' }}>
            <Card className='card'>
              <List style={{ padding: '0px' }}>
                {textBlocks.map((template, index) => (
                  React.Children.toArray([
                    <TextBlockListItem
                      key={template.ID || index}
                      item={template}
                      onDelete={template => this.deleteTextBlock(index, template)}
                      onSave={template => this.saveTextBlock(index, template)}
                      fieldTokens={fieldTokens}
                    />,
                    (index < textBlocks.length - 1) ? <Divider key={`${textBlocks.ID}-div`} /> : null
                  ])
                ))}
              </List>
            </Card>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
              <Button
                onClick={this.addNewTextBlock.bind(this)}
                color='primary'
                variant='contained'
              >
                <Add />
                {language.translate('application.add')}
              </Button>
            </div>
          </div>
          : null}
      </RightPanelContent>
    )
  }
}

TextBlockTemplates.propTypes = {
  dispatch: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  user: PropTypes.object,
  push: PropTypes.func.isRequired,
  showUndoMessage: PropTypes.func.isRequired
}

TextBlockTemplates.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object,
  location: PropTypes.object
}

const mapStateToProps = state => ({
  user: state.auth
})

const mapDispatchToProps = dispatch => ({
  dispatch,
  get: bindActionCreators(get, dispatch),
  post: bindActionCreators(post, dispatch),
  push: bindActionCreators(push, dispatch),
  showUndoMessage: bindActionCreators(showUndoMessage, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(TextBlockTemplates)
