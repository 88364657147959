import PropTypes from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { Base64 } from 'js-base64'
import { bindActionCreators } from 'redux'
import { get, post } from '~/actions/base'
import { Avatar, Card, Collapse, Divider, List, ListItem, ListItemText } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { showSnackbar, showUndoMessage } from '~/actions/snackbar'
import { updateProfilePicURL } from '~/actions/authentication'
import PersonalInformation from './PersonalInformation'
import UserSecurity from './UserSecurity'
import GroupAssignments from './GroupAssignments'
import ChangePasswordDialog from './ChangePasswordDialog'
import Preferences from './Preferences'
import ProcessVisibility from './ProcessVisibility'
import TemporaryBackupUser from './TemporaryBackupUser'
import ApplicationPasswords from './ApplicationPasswords'
import { Form } from 'formsy-react'
import objectAssign from 'object-assign'
import { goBack, push } from 'react-router-redux'
import ExternalSource from '../Forms/ExternalSource'
import RightPanelContent from '../Layout/RightPanelContent'
import CustomFieldValueList from './CustomFieldValueList'
import SmtpSettings from './SmtpSettings'

class UserSettings extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      canSubmit: true,
      isLoaded: false,
      userGroups: null,
      userGroupsLoaded: false,
      user: {},
      languagesLoaded: false,
      languages: [],
      timeZones: null,
      timeZonesLoaded: false,
      showChangePasswordDialog: false,
      processVisibilityList: null,
      processVisibilityListLoaded: false,
      groups: null,
      groupsLoaded: false,
      templates: null,
      templatesLoaded: false,
      applicationPasswords: null,
      applicationPasswordsLoaded: false,
      connectWiseIntegrationID: 0,
      connectWiseTypeID: 0,
      customFields: [],
      customFieldsLoaded: false,
      personalInformationExpanded: !props.userId,
      customUserFieldsExpanded: false,
      userSecurityExpanded: false,
      groupAssignmentsExpanded: false,
      preferencesExpanded: false,
      processVisibilityExpanded: false,
      connectwiseCredentialsExpanded: false,
      temporaryBackupUserExpanded: false,
      smtpSettingsExpanded: false,
      applicationPasswordsExpanded: false
    }
  }

  // MIGRATED TO componentDidMount
  // componentWillMount () {
  //   this.fetchAllData()
  // }

  componentDidMount() {
    if (this.state.isLoaded === false) {
      this.fetchAllData()
    }
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.props.userId && prevProps.userId !== this.props.userId) {
      this.fetchAllData()
    }

    if (!prevState.timeZonesLoaded && this.state.timeZonesLoaded && !this.props.userId) {
      this.generateNewUser()
    }
  }

  generateNewUser() {
    const { timeZones } = this.state

    const { timeZone } = Intl.DateTimeFormat().resolvedOptions()
    const filteredTimeZones = timeZones.filter(tz => (tz.Description === timeZone))

    const user = {
      EmailAddress: '',
      FirstName: '',
      LastName: '',
      TimeZoneID: (filteredTimeZones.length) ? filteredTimeZones[0].ID : '',
      Language: 'en-US'
    }

    this.setState({ user })
  }

  fetchAllData() {
    this.fetchUserSettings()
    this.fetchCustomFields()
    this.fetchUserGroups()
    this.fetchTimeZones()
    this.fetchProcessVisibility()
    this.fetchGroups()
    this.fetchTemplates()
    this.fetchApplicationPasswords()
    this.fetchIntegrations()
    this.fetchLanguages()
  }

  fetchUserSettings() {
    const { userId } = this.props

    if (!userId) {
      return false
    }

    this.setState({
      isLoaded: false
    })

    this.props.get(`user/${userId}`, {
      onSuccess: (response) => {
        this.setState({
          isLoaded: true,
          user: response.User
        })
      }
    })
  }

  fetchCustomFields() {
    const { userId } = this.props

    if (!userId) {
      return false
    }

    this.props.get(`user/${userId}/fieldvalue/list`, {
      onSuccess: (response) => {
        this.setState({
          customFieldsLoaded: true,
          customFields: response.UserFieldValueList
        })
      }
    })
  }

  fetchUserGroups() {
    this.props.get('usergroup/list', {
      onSuccess: (response) => {
        this.setState({
          userGroupsLoaded: true,
          userGroups: response.UserGroupList.filter((group) => (!group.IsOnDemand))
        })
      }
    })
  }

  saveUserGroups(groups) {
    const body = JSON.stringify({
      UserGroupList: groups
    })

    this.props.post('usergroup/list', body, {
      onSuccess: (response) => {
        this.setState({
          userGroups: response.UserGroupList,
          userGroupsLoaded: true
        })
      }
    })
  }

  generateNewUserGroup() {
    const { language } = this.context

    const { userGroups } = this.state

    userGroups.push({
      GroupName: language.translate('application.newGroup'),
      MemberCount: 0
    })

    this.setState({ userGroups })
  }

  fetchLanguages() {
    this.props.get('language/list', {
      onSuccess: (response) => {
        this.setState({
          languagesLoaded: true,
          languages: response.TextValueList
        })
      }
    })
  }

  fetchTimeZones() {
    this.props.get('timezone/list', {
      onSuccess: (response) => {
        this.setState({
          timeZonesLoaded: true,
          timeZones: response.TimeZoneList
        })
      }
    })
  }

  fetchIntegrations() {
    this.props.get('integration/list', {
      onSuccess: (response) => {
        // see if one is ConnectWise and set the ID
        response.IntegrationList.map((integration) => {
          if (integration.IntegrationTypeIdentifier === 'CW') {
            this.getCwId()
            this.setState({
              connectWiseIntegrationID: integration.ID
            })
          }
        })
      }
    })
  }

  getCwId() {
    this.props.get('processtemplate/field/type/list', {
      onSuccess: (response) => {
        // grab the CW type ID
        response.ProcessTemplateFieldTypeList.map((type) => {
          if (type.Identifier === 'CWML') {
            this.setState({
              connectWiseTypeID: type.ID
            })
          }
        })
      }
    })
  }

  fetchGroups() {
    this.props.dispatch(get('processtemplategroup/list', {
      onSuccess: (response) => {
        this.setState({
          groups: response.ProcessTemplateGroupList,
          groupsLoaded: true
        })
      }
    }))
  }

  fetchTemplates() {
    this.props.dispatch(get('processtemplate/list', {
      onSuccess: (response) => {
        this.setState({
          templates: response.ProcessTemplateList,
          templatesLoaded: true
        })
      }
    }))
  }

  fetchTemplatesByGroup(groupId) {
    this.props.dispatch(get(`processtemplategroup/${groupId}/processtemplate/list`, {
      onSuccess: (response) => {
        this.setState({
          templates: response.ProcessTemplateList,
          templatesLoaded: true
        })
      }
    }))
  }

  fetchProcessVisibility() {
    const { userId } = this.props

    if (!userId) {
      return false
    }

    this.props.get(`user/${userId}/processtemplatevisibility/list`, {
      onSuccess: (response) => {
        this.setState({
          processVisibilityList: response.UserProcessTemplateVisibilityList,
          processVisibilityListLoaded: true
        })
      }
    })
  }

  fetchApplicationPasswords() {
    const { userId } = this.props

    if (!userId) {
      return false
    }

    this.props.get(`user/${userId}/applicationpassword/list`, {
      onSuccess: (response) => {
        this.setState({
          applicationPasswords: response.ApplicationPasswordList,
          applicationPasswordsLoaded: true
        })
      }
    })
  }

  saveApplicationPasswordList(list) {
    const { userId } = this.props

    const body = JSON.stringify({
      ApplicationPasswordList: list
    })

    this.props.post(`user/${userId}/applicationpassword/list`, body, {
      onSuccess: (response) => {
        this.setState({
          applicationPasswords: response.ApplicationPasswordList
        })
      }
    })
  }

  deleteApplicationPasswordItem(item) {
    const { language } = this.context

    const body = JSON.stringify({})

    this.props.post(`user/${this.props.userId}/applicationpassword/${item.ID}/delete`, body, {
      onSuccess: (response) => {
        this.fetchApplicationPasswords()

        this.props.showUndoMessage(
          language.translate('application.applicationPasswordRemoved'),
          () => {
            this.props.post(`user/${this.props.userId}/applicationpassword/${item.ID}/delete/undo`, body, {
              onSuccess: (response) => {
                this.fetchApplicationPasswords()
              }
            })
          }
        )
      }
    })
  }

  saveProcessVisibilityList(list) {
    const { userId } = this.props

    const body = JSON.stringify({
      UserProcessTemplateVisibilityList: list
    })

    this.props.post(`user/${userId}/processtemplatevisibility/list`, body, {
      onSuccess: (response) => {
        this.setState({
          processVisibilityList: response.UserProcessTemplateVisibilityList
        })
      }
    })
  }

  deleteProcessVisibilityItem(item) {
    const { language } = this.context

    const body = JSON.stringify({})

    this.props.post(
      `user/${this.props.userId}/processtemplatevisibility/${item.ID}/delete`,
      body,
      {
        onSuccess: (response) => {
          this.fetchProcessVisibility()

          this.props.showUndoMessage(
            language.translate('application.visibilityRemoved'),
            () => {
              this.props.post(
                `user/${this.props.userId}/processtemplatevisibility/${item.ID}/delete/undo`,
                body,
                {
                  onSuccess: (response) => {
                    this.fetchProcessVisibility()
                  }
                }
              )
            }
          )
        }
      }
    )
  }

  saveUserSettings(settings) {
    if (!this.state.canSubmit) { return false }

    if (!settings.ID) {
      this.createNewUser(settings)
      return false
    }

    if (settings.SMTP_Password && settings.SMTP_Password.includes('•')) {
      delete settings.SMTP_Password
    }

    const body = JSON.stringify({
      User: settings
    })

    this.props.post(`user/${this.props.userId}`, body, {
      onSuccess: (response) => {
        this.setState({
          user: response.User
        })
      }
    })
  }

  createNewUser(settings) {
    const body = JSON.stringify({
      User: settings
    })

    this.props.post('user', body, {
      onSuccess: (response) => {
        this.props.push({
          pathname: this.context.location.pathname,
          query: objectAssign({},
            this.context.location.query,
            {
              ptype: 'user',
              pid: response.User.ID,
              usid: response.User.ID
            })
        })
      }
    })
  }

  deleteUser() {
    const { language } = this.context
    const body = JSON.stringify({})

    this.props.post(`user/${this.props.userId}/delete`, body, {
      onSuccess: (response) => {
        this.props.close()

        this.props.showUndoMessage(
          language.translate('application.userRemoved'),
          () => {
            this.props.post(`user/${this.props.userId}/delete/undo`, body, {
              onSuccess: (response) => {
                this.props.goBack()
              }
            })
          }
        )
      }
    })
  }

  parseImageUpload(event) {
    const files = event.target.files

    if (files.length < 1) { return false }

    let uploadedFile = {}
    const reader = new window.FileReader()
    const me = this

    reader.onload = (function (file) {
      return function (e) {
        uploadedFile = {
          FileName: file.name,
          Base64EncodedFile: e.target.result.split(',')[1],
          FileType: file.type
        }
        me.saveProfileImage(uploadedFile)
      }
    })(files[0])

    reader.readAsDataURL(files[0])
  }

  saveProfileImage(image) {
    const body = JSON.stringify({
      UserProfilePicture: image
    })

    this.props.post(`user/${this.props.userId}/profilepicture`, body, {
      onSuccess: (response) => {
        const { user } = this.state
        user.ProfilePicURL = response.UserProfilePicture.URL
        if (this.props.loggedInUserID === user.ID) { this.props.updateProfilePicURL(response.UserProfilePicture.URL) }

        this.setState({ user })
      }
    })
  }

  saveCustomUserField(index, field) {
    const { customFields } = this.state

    const body = JSON.stringify({
      UserFieldValue: field
    })

    const endpoint = (!field.ID) ? '' : `/${field.ID}`

    this.props.post(`user/${this.props.userId}/fieldvalue${endpoint}`, body, {
      onSuccess: (response) => {
        customFields[index] = response.UserFieldValue

        this.setState({
          customFields
        })
      }
    })
  }

  testSMTP() {
    const body = JSON.stringify({})

    this.props.post('account/smtptest', body, {
      onSuccess: (response) => {
      }
    })
  }

  render() {
    const palette = this.context.muiTheme.palette
    const { language, location } = this.context
    const {
      isLoaded, user, userGroups, userGroupsLoaded, timeZonesLoaded, timeZones, showChangePasswordDialog,
      processVisibilityList, processVisibilityListLoaded, groups, groupsLoaded, templates, templatesLoaded,
      applicationPasswords, applicationPasswordsLoaded, connectWiseIntegrationID, languages, languagesLoaded,
      customFields, customFieldsLoaded, connectWiseTypeID, personalInformationExpanded, customUserFieldsExpanded,
      userSecurityExpanded, groupAssignmentsExpanded, preferencesExpanded, processVisibilityExpanded,
      connectwiseCredentialsExpanded, temporaryBackupUserExpanded, smtpSettingsExpanded, applicationPasswordsExpanded
    } = this.state
    const { dispatch, userId, push, loggedInUserID, showSnackbar } = this.props

    return (
      <RightPanelContent
        leftToolbarAvatar={
          (isLoaded)
            ? <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '5px' }}>
              <Avatar
                src={user.ProfilePicURL}
                className='clickable'
                style={{ height: '50px', width: '50px' }}
                onClick={() => ReactDOM.findDOMNode(this.refs.user_image_upload).click()}
              />
              <div style={{
                fontSize: '10px',
                color: palette.accent2Color,
                float: 'left',
                position: 'absolute',
                backgroundColor: palette.disabledColor,
                top: '40px',
                whiteSpace: 'nowrap'
              }}
              >{language.translate('application.updateImage')}
              </div>
            </div>
            : null
        }
        closePanel={() => this.props.close()}
        title={(isLoaded) ? user.FullName : (!userId) ? language.translate('application.newUser') : null}
        toolbarMenuItems={[
          (user && loggedInUserID === user.ID)
            ? {
              onClick: () => {
                this.setState({ showChangePasswordDialog: true })
              },
              label: language.translate('application.changePassword')
            }
            : null,
          (userId)
            ? {
              onClick: () => {
                push(`/audit-trail?endpoint=auditentry/list/entryby/user/${userId}&title=${user.FullName}`)
              },
              label: language.translate('application.activityAuditTrail')
            }
            : null,
          (userId)
            ? {
              onClick: () => {
                push(`/audit-trail?endpoint=user/${userId}/auditentry/list&title=${user.FullName}`)
              },
              label: language.translate('application.profileAuditTrail')
            }
            : null,
          (userId)
            ? {
              onClick: () => {
                let query = {}

                query.search = Base64.encodeURI(JSON.stringify({
                  displayOnly: true,
                  value: user.ID,
                  displayText: 'Users',
                  displayValue: user.FullName,
                  operator: 'user-filter'
                }))

                push({
                  pathname: `/`,
                  query
                })
              },
              label: language.translate('application.viewWorkList')
            }
            : null,
          (userId)
            ? {
              onClick: () => this.deleteUser(),
              label: language.translate('application.deleteUser'),
              isDelete: true
            }
            : null
        ].filter(Boolean)}
      >
        <div style={{ padding: '15px' }}>
          {((isLoaded && userGroupsLoaded) || !userId)
            ? <Card className='card'>
              <List style={{ padding: '0px' }}>
                <Form
                  onValid={() => this.setState({ canSubmit: true })}
                  onInvalid={() => this.setState({ canSubmit: false })}
                  autoComplete='off'
                >
                  <ListItem
                    button
                    onClick={() => { this.setState({ personalInformationExpanded: !personalInformationExpanded }) }}
                  >
                    <ListItemText primary={language.translate('application.personalInformation')} />
                    {personalInformationExpanded ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={personalInformationExpanded} timeout='auto' unmountOnExit>
                    {(timeZonesLoaded && languagesLoaded)
                      ? <PersonalInformation
                        key='personal'
                        user={user}
                        onSave={this.saveUserSettings.bind(this)}
                        timeZones={timeZones}
                        languages={languages}
                        dispatch={dispatch}
                      />
                      : []}
                  </Collapse>
                </Form>
                {(!userId)
                  ? null
                  : <div>
                    <Divider />
                    <ListItem
                      button
                      onClick={() => { this.setState({ customUserFieldsExpanded: !customUserFieldsExpanded }) }}
                    >
                      <ListItemText primary={language.translate('application.customUserFields')} />
                      {customUserFieldsExpanded ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={customUserFieldsExpanded} timeout='auto' unmountOnExit>
                      {(customFieldsLoaded)
                        ? <CustomFieldValueList
                          key='custom_fields'
                          dispatch={dispatch}
                          fields={customFields}
                          manageCustomFields={() => {
                            push({
                              pathname: location.pathname,
                              query: { ptype: 'cufields' }
                            })
                          }}
                          onChange={this.saveCustomUserField.bind(this)}
                        />
                        : []}
                    </Collapse>
                    <Divider />
                    <ListItem
                      button
                      onClick={() => { this.setState({ userSecurityExpanded: !userSecurityExpanded }) }}
                    >
                      <ListItemText
                        primary={language.translate('application.userSecurity')}
                        secondary={language.translate('application.userSecurityMessage')} />
                      {userSecurityExpanded ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={userSecurityExpanded} timeout='auto' unmountOnExit>
                      <UserSecurity
                        key='security'
                        user={user}
                        onSave={this.saveUserSettings.bind(this)}
                      />
                    </Collapse>
                    <Divider />
                    <ListItem
                      button
                      onClick={() => { this.setState({ groupAssignmentsExpanded: !groupAssignmentsExpanded }) }}
                    >
                      <ListItemText
                        primary={language.translate('application.groupAssignments')}
                        secondary={language.translate('application.groupAssignmentsMessage')} />
                      {groupAssignmentsExpanded ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={groupAssignmentsExpanded} timeout='auto' unmountOnExit>
                      <GroupAssignments
                        key='groups'
                        user={user}
                        userGroups={userGroups}
                        onSave={this.saveUserSettings.bind(this)}
                        saveGroups={this.saveUserGroups.bind(this)}
                        generateNewUserGroup={this.generateNewUserGroup.bind(this)}
                      />
                    </Collapse>
                    <Divider />
                    <ListItem
                      button
                      onClick={() => { this.setState({ preferencesExpanded: !preferencesExpanded }) }}
                    >
                      <ListItemText
                        primary={language.translate('application.preferences')}
                        secondary={language.translate('application.preferencesUserMessage')} />
                      {preferencesExpanded ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={preferencesExpanded} timeout='auto' unmountOnExit>
                      <Preferences
                        key='preferences'
                        user={user}
                        onSave={this.saveUserSettings.bind(this)}
                      />
                    </Collapse>
                    <Divider />
                    <ListItem
                      button
                      onClick={() => { this.setState({ processVisibilityExpanded: !processVisibilityExpanded }) }}
                    >
                      <ListItemText
                        primary={language.translate('application.processVisibility')}
                        secondary={language.translate('application.processVisibilityMessage')} />
                      {processVisibilityExpanded ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={processVisibilityExpanded} timeout='auto' unmountOnExit>
                      {(groupsLoaded && templatesLoaded && processVisibilityListLoaded)
                        ? [<ProcessVisibility
                          key='visibility'
                          processVisibilityList={processVisibilityList}
                          userId={user.ID}
                          onDelete={this.deleteProcessVisibilityItem.bind(this)}
                          onSaveList={this.saveProcessVisibilityList.bind(this)}
                          groups={groups}
                          templates={templates}
                          onGroupChange={this.fetchTemplatesByGroup.bind(this)}
                        />
                        ] : []}
                    </Collapse>
                    <Divider
                      style={{ display: (connectWiseIntegrationID && connectWiseTypeID) ? 'inherit' : 'none' }} />
                    <ListItem
                      button
                      onClick={() => { this.setState({ connectwiseCredentialsExpanded: !connectwiseCredentialsExpanded }) }}
                      style={{ display: (connectWiseIntegrationID) ? 'flex' : 'none' }}
                    >
                      <ListItemText
                        primary={language.translate('application.connectWise')}
                        secondary={language.translate('application.connectWiseCredentialsMessage')} />
                      {connectwiseCredentialsExpanded ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={connectwiseCredentialsExpanded} timeout='auto' unmountOnExit>
                      <List style={{ padding: '0px 20px', backgroundColor: palette.accent2Color }}>
                        <ListItem
                          key='connect_wise'
                          style={{
                            paddingTop: '0px',
                            paddingBottom: '0px'
                          }}
                        >
                          <ListItemText
                            primary={
                              <Form autoComplete='off'>
                                <ExternalSource
                                  fullWidth
                                  name='ConnectWise User Account'
                                  floatingLabelText={language.translate('application.connectWiseUserAccount')}
                                  onChange={(value, text) => {
                                    user.CW_Integration_UserID = value.toString()
                                    user.CW_Integration_Username = text
                                    this.saveUserSettings(user)
                                  }}
                                  value={user.CW_Integration_UserID}
                                  valueText={user.CW_Integration_Username}
                                  responseProperty={'TextValueList'}
                                  searchUrl={`integration/${connectWiseIntegrationID}/field/type/${connectWiseTypeID}/externaloption/list`}
                                  menuProps={{ maxHeight: 200 }}
                                />
                              </Form>
                            } />
                        </ListItem>
                      </List>
                    </Collapse>
                    <Divider />
                    <Form key='backup_user' autoComplete='off'>
                      <ListItem
                        button
                        onClick={() => { this.setState({ temporaryBackupUserExpanded: !temporaryBackupUserExpanded }) }}
                      >
                        <ListItemText
                          primary={language.translate('application.temporaryBackupUser')}
                          secondary={language.translate('application.temporaryBackupUserMessage', [user.FullName])} />
                        {temporaryBackupUserExpanded ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={temporaryBackupUserExpanded} timeout='auto' unmountOnExit>
                        <TemporaryBackupUser
                          key='backup_user'
                          user={user}
                          onSave={this.saveUserSettings.bind(this)}
                          dispatch={dispatch}
                        />
                      </Collapse>
                      <Divider />
                      <ListItem
                        button
                        onClick={() => { this.setState({ smtpSettingsExpanded: !smtpSettingsExpanded }) }}
                      >
                        <ListItemText
                          primary={language.translate('application.smtpSettings')}
                          secondary={language.translate('application.smtpSettingsDescription')} />
                        {smtpSettingsExpanded ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={smtpSettingsExpanded} timeout='auto' unmountOnExit>
                        <SmtpSettings
                          key='smtp'
                          user={user}
                          onSave={this.saveUserSettings.bind(this)}
                          testSettings={this.testSMTP.bind(this)}
                        />
                      </Collapse>
                    </Form>
                    <Divider />
                    <ListItem
                      button
                      onClick={() => { this.setState({ applicationPasswordsExpanded: !applicationPasswordsExpanded }) }}
                    >
                      <ListItemText
                        primary={language.translate('application.applicationPasswords')}
                        secondary={language.translate('application.applicationPasswordsMessage')} />
                      {applicationPasswordsExpanded ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={applicationPasswordsExpanded} timeout='auto' unmountOnExit>
                      {(applicationPasswordsLoaded)
                        ? [<ApplicationPasswords
                          key='application_passwords'
                          applicationPasswords={applicationPasswords}
                          userId={user.ID}
                          showSnackbar={showSnackbar}
                          onDelete={this.deleteApplicationPasswordItem.bind(this)}
                          onSaveList={this.saveApplicationPasswordList.bind(this)}
                        />
                        ] : []}
                    </Collapse>
                  </div>}
              </List>
            </Card>
            : null}
          <ChangePasswordDialog
            onRequestClose={() => this.setState({ showChangePasswordDialog: false })}
            open={showChangePasswordDialog}
            user={user}
            onSave={this.saveUserSettings.bind(this)}
          />
          <input
            ref={'user_image_upload'}
            type='file'
            accept='image/*'
            style={{ display: 'none' }}
            onChange={this.parseImageUpload.bind(this)}
          />
        </div>
      </RightPanelContent>
    )
  }
}

UserSettings.propTypes = {
  userId: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func,
  updateProfilePicURL: PropTypes.func,
  loggedInUserID: PropTypes.string,
  push: PropTypes.func.isRequired,
  showUndoMessage: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired
}

UserSettings.contextTypes = {
  location: PropTypes.object,
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

const mapStateToProps = state => ({
  loggedInUserID: state.auth.userid
})

const mapDispatchToProps = dispatch => ({
  dispatch,
  post: bindActionCreators(post, dispatch),
  get: bindActionCreators(get, dispatch),
  showSnackbar: bindActionCreators(showSnackbar, dispatch),
  updateProfilePicURL: bindActionCreators(updateProfilePicURL, dispatch),
  push: bindActionCreators(push, dispatch),
  showUndoMessage: bindActionCreators(showUndoMessage, dispatch),
  goBack: bindActionCreators(goBack, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings)
