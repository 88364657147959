import PropTypes from 'prop-types'
import React from 'react'
import FormHelper from '../../businessLogic/formHelper'
import FieldWrapper from './FieldWrapper'

class Default extends React.Component {
  render () {
    const { field, wrapperProps } = this.props

    return (
      <FieldWrapper
        label={FormHelper.decodeHTML(field.FieldName)}
        required={field.RequiredField}
        showDivider
        {...wrapperProps}
      >
        <div style={{ whiteSpace: 'normal' }} className='input-style'>
          {FormHelper.decodeHTML(field.Value)}
        </div>
      </FieldWrapper>
    )
  }
}

Default.propTypes = {
  field: PropTypes.object.isRequired,
  wrapperProps: PropTypes.object
}

export default Default
