import PropTypes from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'
import { HOC } from 'formsy-react'
import { Button } from '@material-ui/core'
import { CloudUpload } from '@material-ui/icons'

class FormsyUpload extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: this.props.defaultValue || []
    }

    //componentWillMount migration start
    if (this.props.defaultValue && this.props.defaultValue.length === 0) {
      this.props.setValue('')
    } else {
      this.props.setValue(this.props.defaultValue)
    }
    //componentWillMount migration end

  }

  // componentWillMount() {
  //   if (this.props.defaultValue && this.props.defaultValue.length === 0) {
  //     this.props.setValue('')
  //   } else {
  //     this.props.setValue(this.props.defaultValue)
  //   }
  // }

  handleChange(event) {
    const files = event.target.files
    const me = this
    for (let i = 0; i < files.length; i++) {
      const reader = new window.FileReader()

      reader.onload = (function (file) {
        return function (e) {
          me.setState({
            value: me.state.value.concat({
              FileName: file.name,
              Base64EncodedFile: e.target.result.split(',')[1],
              FileType: file.type
            })
          })
        }
      })(files[i])

      reader.readAsDataURL(files[i])
    }

    this.props.setValue(event.currentTarget.files)

    if (this.props.onChange) this.props.onChange(event)
  }

  _openFileDialog() {
    const fileUploadDom = ReactDOM.findDOMNode(this.refs[this.props.name])
    fileUploadDom.click()
  }

  render() {
    const {
      defaultValue, // eslint-disable-line no-unused-vars
      value, // eslint-disable-line no-unused-vars
      validationError, // eslint-disable-line no-unused-vars
      validationErrors, // eslint-disable-line no-unused-vars
      style,
      isRequired,
      buttonLabel,
      setValue, // eslint-disable-line no-unused-vars
      setValidations, // eslint-disable-line no-unused-vars
      resetValue, // eslint-disable-line no-unused-vars
      getValue, // eslint-disable-line no-unused-vars
      hasValue, // eslint-disable-line no-unused-vars
      getErrorMessage, // eslint-disable-line no-unused-vars
      getErrorMessages, // eslint-disable-line no-unused-vars
      isFormDisabled, // eslint-disable-line no-unused-vars
      isValid, // eslint-disable-line no-unused-vars
      isPristine, // eslint-disable-line no-unused-vars
      isFormSubmitted, // eslint-disable-line no-unused-vars
      showRequired, // eslint-disable-line no-unused-vars
      showError, // eslint-disable-line no-unused-vars
      isValidValue, // eslint-disable-line no-unused-vars
      ...rest
    } = this.props
    const { language, muiTheme } = this.context

    return (
      <div>
        <Button
          color='primary'
          style={style}
          onClick={this._openFileDialog.bind(this)}
          variant='contained'
        >
          <CloudUpload nativeColor={muiTheme.palette.canvasColor} />
          {buttonLabel}
        </Button>

        {(isRequired() && this.state.value && this.state.value.length === 0)
          ? <div
            style={{
              position: 'relative',
              bottom: '-4px',
              fontSize: '12px',
              lineHeight: '12px',
              color: 'rgb(244, 67, 54)',
              transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
              marginBottom: '5px'
            }}
          >
            {language.messages.validationErrors.isDefaultRequiredValue}
          </div>
          : ''}

        <input
          {...rest}
          ref={this.props.name}
          value={[]}
          type='file'
          style={{ display: 'none' }}
          onChange={this.handleChange.bind(this)}
        />
      </div>
    )
  }
}

FormsyUpload.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  name: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.array,
  style: PropTypes.object,
  validationError: PropTypes.string,
  validationErrors: PropTypes.object,
  setValue: PropTypes.func,
  isRequired: PropTypes.func,
  setValidations: PropTypes.func,
  resetValue: PropTypes.func,
  getValue: PropTypes.func,
  hasValue: PropTypes.func,
  getErrorMessage: PropTypes.func,
  getErrorMessages: PropTypes.func,
  isFormDisabled: PropTypes.func,
  isValid: PropTypes.func,
  isPristine: PropTypes.func,
  isFormSubmitted: PropTypes.func,
  showRequired: PropTypes.func,
  showError: PropTypes.func,
  isValidValue: PropTypes.func
}

FormsyUpload.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default HOC(FormsyUpload)
