import { TOGGLE_NAVIGATION_REQUEST } from '../constants/NavigationTypes'

export function toggleNavigation () {
  return {
    type: TOGGLE_NAVIGATION_REQUEST
  }
}

export function hideNavigation () {
  return function (dispatch, state) {
    if (state().navigation.open === true) { dispatch(toggleNavigation()) }
  }
}

export function showNavigation () {
  return function (dispatch, state) {
    if (state().navigation.open === false) { dispatch(toggleNavigation()) }
  }
}
