import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MenuItem } from 'material-ui'
import { Button } from '@material-ui/core'
import { Form } from 'formsy-react'
import { FormsyText, FormsySelect } from 'formsy-material-ui'
import CancelButton from './Forms/CancelButton'

class IntegrationConfigurationForm extends Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      canSubmit: true
    }
  }

  integrationMenu () {
    const { integrationTypes } = this.props
    const menu = []

    integrationTypes.map((integration) => {
      menu.push(<MenuItem value={integration.ID} key={integration.ID} primaryText={integration.Name} />)
    })

    return menu
  }

  render () {
    const { language, muiTheme } = this.context
    const { integration, updateField, onSave, onCancel, integrationTypes } = this.props
    const { canSubmit } = this.state

    const selectedIntegrationType = integrationTypes.filter(type => (type.ID === integration.IntegrationTypeID))[0]

    return (
      <Form
        onValid={() => this.setState({ canSubmit: true })}
        onInvalid={() => this.setState({ canSubmit: false })}
        style={{ display: 'flex', flexDirection: 'column' }}
        autoComplete='off'
      >
        <FormsySelect
          value={integration.IntegrationTypeID || ''}
          floatingLabelText={language.translate('application.integration')}
          onChange={(e, value) => updateField('IntegrationTypeID', value)}
          inputStyle={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
          name='integration_type'
          required
          fullWidth
          validationErrors={language.messages.validationErrors}
        >
          {this.integrationMenu()}
        </FormsySelect>
        {(integration.IntegrationTypeID)
          ? <div>
            <FormsyText
              fullWidth
              required
              name='description'
              floatingLabelText={language.translate('application.description')}
              onBlur={(event) => {
                updateField('Description', event.currentTarget.value)
              }}
              defaultValue={integration.Description}
              validationErrors={language.messages.validationErrors}
            />
            {(selectedIntegrationType.APIVersionAlias)
              ? <FormsyText
                fullWidth
                required
                name='api-version'
                floatingLabelText={selectedIntegrationType.APIVersionAlias}
                onBlur={(event) => {
                  updateField('APIVersion', event.currentTarget.value)
                }}
                defaultValue={integration.APIVersion}
                validationErrors={language.messages.validationErrors}
              />
              : null}
            {(selectedIntegrationType.AncillaryData1Alias)
              ? <FormsyText
                fullWidth
                required
                name='ancillary-data1'
                floatingLabelText={selectedIntegrationType.AncillaryData1Alias}
                onBlur={(event) => {
                  updateField('AncillaryData1', event.currentTarget.value)
                }}
                defaultValue={integration.AncillaryData1}
                validationErrors={language.messages.validationErrors}
              />
              : null}
            {(selectedIntegrationType.AncillaryData2Alias)
              ? <FormsyText
                fullWidth
                required
                name='ancillary-data2'
                floatingLabelText={selectedIntegrationType.AncillaryData2Alias}
                onBlur={(event) => {
                  updateField('AncillaryData2', event.currentTarget.value)
                }}
                defaultValue={integration.AncillaryData2}
                validationErrors={language.messages.validationErrors}
              />
              : null}
            {(selectedIntegrationType.UsernameAlias)
              ? <FormsyText
                fullWidth
                required
                name='username'
                floatingLabelText={selectedIntegrationType.UsernameAlias}
                onBlur={(event) => {
                  updateField('Username', event.currentTarget.value)
                }}
                defaultValue={integration.Username}
                validationErrors={language.messages.validationErrors}
              />
              : null}
            {(selectedIntegrationType.PasswordAlias)
              ? <FormsyText
                fullWidth
                required
                name='password'
                type='password'
                floatingLabelText={selectedIntegrationType.PasswordAlias}
                onBlur={(event) => {
                  updateField('Password', event.currentTarget.value)
                }}
                defaultValue={integration.Password}
                validationErrors={language.messages.validationErrors}
              />
              : null}
            {(selectedIntegrationType.DomainNameAlias)
              ? <FormsyText
                fullWidth
                required
                name='domain-name'
                floatingLabelText={selectedIntegrationType.DomainNameAlias}
                onBlur={(event) => {
                  updateField('DomainName', event.currentTarget.value)
                }}
                defaultValue={integration.DomainName}
                validationErrors={language.messages.validationErrors}
              />
              : null}
            {(selectedIntegrationType.ProtocolAlias)
              ? <FormsyText
                fullWidth
                required
                name='protocol'
                floatingLabelText={selectedIntegrationType.ProtocolAlias}
                onBlur={(event) => {
                  updateField('Protocol', event.currentTarget.value)
                }}
                defaultValue={integration.Protocol}
                validationErrors={language.messages.validationErrors}
              />
              : null}
          </div>
          : null}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            paddingTop: '30px'
          }}
        >
          <CancelButton
            onClick={() => onCancel()}
            variant='contained'
          />
          <Button
            onClick={() => onSave()}
            variant='contained'
            disabled={!canSubmit}
            color='primary'
          >
            {language.translate('application.save')}
          </Button>
        </div>
      </Form>
    )
  }
}

IntegrationConfigurationForm.propTypes = {
  integration: PropTypes.object,
  integrationTypes: PropTypes.array.isRequired,
  updateField: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}

IntegrationConfigurationForm.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default IntegrationConfigurationForm
