import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Button, Card, CardHeader, CardContent, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, FormControl,
  IconButton, InputLabel, List, ListItem, ListItemSecondaryAction, ListItemText, Menu, MenuItem, Select, Step, Stepper,
  StepButton, StepContent, Typography
} from '@material-ui/core'
import { Add, ChevronRight, MoreVert } from '@material-ui/icons'
import Papa from 'papaparse'
import { Form } from 'formsy-react'
import { get, post } from '../../actions/base'
import RightPanelContent from '../Layout/RightPanelContent'
import FormsyUpload from '../Forms/FormsyUpload'
import DeleteMenuItem from '../Layout/DeleteMenuItem'
import CancelButton from '../Forms/CancelButton'

class Panel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stepIndex: 0,
      canSubmit: true,
      fieldList: [],
      template: {},
      isLoaded: false,
      file: null,
      importFileError: '',
      processesToStart: [],
      headerColumns: [],
      mapList: [],
      openAddMappingDialog: false,
      fromField: '',
      toField: {},
      menuAnchor: null,
      menuAnchorIndex: null,
      showWaitingDialog: false
    }
  }

  // MIGRATED TO componentDidMount
  // componentWillMount() {
  //   this.fetchProcessFields()
  // }

  componentDidMount() {
    if (this.state.isLoaded === false) {
      this.fetchProcessFields()
    }
  }

  fetchProcessFields() {
    const { templateId } = this.props

    this.props.dispatch(get(`processtemplate/${templateId}/field/list`, {
      onSuccess: (response) => {
        this.setState({
          fieldList: response.ProcessTemplateFieldList,
          template: response.ProcessTemplate,
          isLoaded: true
        })
      }
    }))
  }

  startProcesses = () => {
    const { mapList, fieldList, processesToStart } = this.state
    this.setState({ showWaitingDialog: true })

    const processFieldList = JSON.parse(JSON.stringify(fieldList))

    const processToStartIndex = processesToStart.findIndex(({ started }) => (!started))

    if (processToStartIndex < 0) {
      this.setState({ showWaitingDialog: false })
      this.props.close()
      return
    }

    let processFields = this.mergeValuesIntoProcessFieldList(processFieldList, processesToStart[processToStartIndex].process, mapList)

    this.startProcess(processFields, () => {
      processesToStart[processToStartIndex].started = true
      this.setState({ processesToStart })
      this.startProcesses()
    })
  }

  mergeValuesIntoProcessFieldList(processFields, fieldValues, mapList) {
    processFields.map((field) => {
      mapList.map((mapItem) => {
        if (field.ID === mapItem.toId) {
          const fieldName = Object.keys(fieldValues).filter(value => (value === mapItem.fromName))[0]
          field.Value = fieldValues[fieldName]
        }
      })
    })

    return processFields
  }

  startProcess(fieldList, successCallback) {
    const { process } = this.state
    const { templateId, post } = this.props

    // make sure they can't submit while it's submitting
    this.disableButton()

    // gather form data
    const processFieldValues = JSON.parse(JSON.stringify(fieldList))

    // convert the field ID's to ProcessTemplateFieldID and delete
    // the ID's since this is creating new instances
    processFieldValues.map((field) => {
      if (!field.ProcessTemplateFieldID) {
        field.ProcessTemplateFieldID = field.ID
        field.ProcessTemplateFieldID = field.ID
        delete field.ID
        delete field.ID
      }
    })

    const body = JSON.stringify({
      ProcessInstanceFieldList: processFieldValues,
      ProcessInstance: process
    })

    post(`processtemplate/${templateId}/start`, body, {
      onSuccess: (response) => {
        if (successCallback) { successCallback() }
      },

      onError: (error) => {
        this.enableButton()
      }
    })
  }

  enableButton = () => {
    this.setState({
      canSubmit: true,
      showWaitingDialog: false
    })
  }

  disableButton = () => {
    this.setState({
      canSubmit: false
    })
  }

  parseProcessImportFile = (event) => {
    const files = event.target.files

    Papa.parse(files[0], {
      delimiter: ',',
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        if (results.errors.length) {
          this.setState({ importFileError: results.errors[0].message })
        } else {
          this.setState({ importFileError: '' })

          this.setState({
            processesToStart: results.data.map(process => ({ started: false, process })),
            headerColumns: results.meta.fields,
            stepIndex: 1
          })
        }
      }
    })
  }

  fileHeaderMenu = () => {
    const { headerColumns, isLoaded } = this.state
    const { language } = this.context

    if (!isLoaded) { return false }

    const menu = []

    headerColumns.map((field, index) => {
      menu.push(<MenuItem
        value={field}
        key={index}>
        {field}
      </MenuItem>)
    })

    return menu
  }

  processFieldsMenu = () => {
    let { fieldList } = this.state

    const menu = []

    fieldList.map((field, index) => {
      menu.push(<MenuItem
        value={field.ID}
        key={field.ID}>
        {field.FieldName}
      </MenuItem>)
    })

    return menu
  }

  saveNewMapping = () => {
    const { mapList, toField, fromField } = this.state

    mapList.push({ fromName: fromField, toName: toField.FieldName, toId: toField.ID })

    this.setState({
      openAddMappingDialog: false,
      toField: {},
      fromField: '',
      mapList
    })
  }

  render() {
    const { language, muiTheme: { palette } } = this.context
    const {
      isLoaded, processesToStart, stepIndex, mapList, openAddMappingDialog,
      fromField, toField, fieldList, showWaitingDialog, template, menuAnchor, menuAnchorIndex
    } = this.state

    const processesStarted = processesToStart.filter(({ started }) => (started)).length || 0

    return (
      <RightPanelContent
        closePanel={() => this.props.close()}
        title={template.Title}
      >
        {(!isLoaded)
          ? <CircularProgress className='loader' />
          : <Form
            style={{ margin: '10px 20px' }}
            onValid={this.enableButton}
            onInvalid={this.disableButton}
            autoComplete='off'
          >
            <Card>
              <CardHeader
                title={language.translate('application.startMultiple')}
                subheader={<div>
                  <div>
                    {language.translate('application.startMultipleInstructions')}
                  </div>
                  <div>
                    <a href='http://help.processplan.com/starting-multiple-instances' target='_blank'>
                      {language.translate('application.onlineDocumentation')}
                    </a>
                  </div>
                </div>
                }
              />
              <CardContent>
                <div className='stepper' style={{ marginTop: '10px' }}>
                  <Stepper
                    activeStep={stepIndex}
                    nonLinear
                    orientation='vertical'
                  >
                    <Step>
                      <StepButton onClick={() => this.setState({ stepIndex: 0 })}>
                        <strong>{language.translate('application.uploadFile')}</strong>
                      </StepButton>
                      <StepContent>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <FormsyUpload
                            name='Upload file'
                            required
                            multiple={false}
                            buttonLabel={language.translate('application.uploadFile')}
                            accept='.csv'
                            onChange={this.parseProcessImportFile}
                            defaultValue={processesToStart}
                          />
                        </div>
                      </StepContent>
                    </Step>
                    <Step disabled={(!processesToStart)}>
                      <StepButton onClick={() => this.setState({ stepIndex: 1 })}>
                        <strong>{language.translate('application.fieldMapping')}</strong>
                      </StepButton>
                      <StepContent>
                        <List style={{ padding: '0px' }}>
                          {mapList.map((field, index) => (
                            React.Children.toArray([
                              <ListItem key={field.toId}>
                                <ListItemText
                                  primary={
                                    <div style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center'
                                    }}
                                    >
                                      <div>{field.fromName}</div>
                                      <ChevronRight style={{ marginTop: '2px' }} />
                                      <div>{field.toName}</div>
                                    </div>} />
                                <ListItemSecondaryAction>
                                  <div>
                                    <IconButton
                                      style={{ top: '4px' }}
                                      onClick={(e) => {
                                        this.setState({
                                          menuAnchor: e.currentTarget,
                                          menuAnchorIndex: index
                                        })
                                      }}><MoreVert /></IconButton>
                                    <Menu
                                      anchorEl={menuAnchor}
                                      open={Boolean(menuAnchor) && menuAnchorIndex === index}
                                      onClose={() => { this.setState({ menuAnchor: null, menuAnchorIndex: null }) }}
                                      getContentAnchorEl={null}
                                      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    >
                                      <DeleteMenuItem
                                        onDelete={() => {
                                          this.setState({ menuAnchor: null, menuAnchorIndex: null })
                                          mapList.splice(index, 1)
                                          this.setState({ mapList })
                                        }}
                                      />
                                    </Menu>
                                  </div>
                                </ListItemSecondaryAction>
                              </ListItem>,
                              (index < mapList.length - 1) ? <Divider key={field.toId + 10000} /> : null
                            ])
                          ))}
                        </List>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                          <Button
                            onClick={() => { this.setState({ openAddMappingDialog: true }) }}
                            color='primary'
                            variant='contained'
                          >
                            <Add />
                            {language.translate('application.addMapping')}
                          </Button>
                        </div>
                      </StepContent>
                    </Step>
                  </Stepper>
                </div>
              </CardContent>
            </Card>
            <Button
              color='primary'
              variant='contained'
              disabled={(!processesToStart.length)}
              style={{ display: 'block', margin: '20px 0px' }}
              fullWidth
              onClick={this.startProcesses}
            >
              {language.translate('application.submit')}
            </Button>
          </Form>
        }
        <Dialog
          open={openAddMappingDialog}
          onClose={() => this.setState({ openAddMappingDialog: false })}
        >
          <DialogTitle
            style={{
              backgroundColor: palette.headerBackgroundColor
            }}
            disableTypography
          >
            <Typography
              variant='h6'
              style={{ color: palette.alternateTextColor }}>
              {language.translate('application.addMapping')}
            </Typography>
          </DialogTitle>
          <DialogContent style={{ width: '300px' }}>
            <FormControl fullWidth>
              <InputLabel style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {language.translate('application.passDataFrom')}
              </InputLabel>
              <Select
                name='map_from'
                MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
                value={fromField || ''}
                onChange={(e) => {
                  this.setState({ fromField: e.target.value })
                }}
              >
                {this.fileHeaderMenu()}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {language.translate('application.toField')}
              </InputLabel>
              <Select
                name='map_to'
                MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
                value={toField.ID || ''}
                onChange={(e, i) => {
                  this.setState({ toField: fieldList.filter((item) => (e.target.value === item.ID))[0] })
                }}
              >
                {this.processFieldsMenu()}
              </Select>
            </FormControl>
            <div
              style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
            >
              <CancelButton
                onClick={() => this.setState({
                  openAddMappingDialog: false,
                  toField: {},
                  fromField: null
                })}
                variant='contained'
                style={{ margin: '10px' }}
              />
              <Button
                onClick={() => {
                  this.saveNewMapping()
                }}
                disabled={(!this.state.fromField || !this.state.toField)}
                color='primary'
                variant='contained'
                style={{ margin: '10px' }}
              >
                {language.translate('application.save')}
              </Button>
            </div>
          </DialogContent>
        </Dialog>
        <Dialog
          open={showWaitingDialog}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <DialogTitle
            style={{
              backgroundColor: palette.headerBackgroundColor
            }}
            disableTypography
          >
            <Typography
              variant='h6'
              style={{ color: palette.alternateTextColor }}>
              {language.translate('application.taskAssignedTo')}
            </Typography>
          </DialogTitle>
          <DialogContent style={{ width: '350px' }}>
            <div style={{ height: '120px', overflow: 'hidden' }}>
              <CircularProgress className='loader' />
              <div style={{ textAlign: 'center', marginTop: '20px' }}>
                Starting process {processesStarted + 1} of {processesToStart.length}
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </RightPanelContent>
    )
  }
}

Panel.propTypes = {
  templateId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired
}

Panel.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  dispatch,
  post: bindActionCreators(post, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Panel)
