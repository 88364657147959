import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get } from '../../actions/base'
import { updateTitle } from '../../actions/application'
import Masonry from 'react-masonry-component'
import { CircularProgress } from '@material-ui/core'
import MilestoneCard from './MilestoneCard'

const masonryOptions = {
  transitionDuration: '0.0s',
  fitWidth: true
}

class MilestoneReport extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      processInstances: [],
      milestonesLoaded: false,
      account: null
    }
  }

  componentDidMount () {
    const {language} = this.context

    this.fetchMilestoneData()
    this.props.updateTitle(language.translate('application.milestoneReport'))
  }

  fetchMilestoneData () {
    const {instancetoken, token} = this.context.location.query
    const {get} = this.props

    const headers = {
      token: instancetoken || token
    }

    get(`public/processinstance/milestone/list?cb=${Math.random()}`, {
      onSuccess: (response) => {
        let processInstances = response.ProcessInstanceList

        processInstances.map((instance) => {
          const milestones = response.ProcessInstanceTaskList.filter((milestone) => (milestone.ProcessInstanceID === instance.ID))

          instance.Milestones = milestones
        })

        this.setState({
          processInstances,
          account: response.Account,
          milestonesLoaded: true
        })
      },

      headers: headers
    })
  }

  render () {
    const {milestonesLoaded, account, processInstances} = this.state
    const {user} = this.context
    const {screenWidth} = this.props

    if (!milestonesLoaded) { return <CircularProgress className='loader' /> }

    let maxMilestoneLength = 0

    processInstances.map((instance) => {
      if (instance.Milestones.length > maxMilestoneLength) {
        maxMilestoneLength = instance.Milestones.length
      }
    })

    return (
      <div
        style={{width: '100%', margin: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center'}}
      >
        <div style={{textAlign: 'center', margin: '30px 0px'}}>
          <img src={account.HeaderLogoWhiteBG_URL} style={{maxWidth: '300px'}} />
        </div>
        <Masonry style={{width: '100%', margin: '0 auto'}} options={masonryOptions}>
          {processInstances.map((process, index) => {
            if (index > 0 && !process.Milestones.length) return null

            return (
              <MilestoneCard
                key={process.ID}
                style={{width: '100%'}}
                cardStyle={{minWidth: (screenWidth > 450) ? '400px' : '300px', width: (screenWidth > 900) ? `47%` : '95%', margin: '10px'}}
                process={process}
              />
            )
          })}
        </Masonry>
        {(!user.isAuthenticated)
          ? <div style={{marginTop: '40px', fontSize: '10px'}}>
            <a href='https://processplan.com' target='_blank' style={{color: '#606060'}}>This process
              milestone report was published via ProcessPlan.com.</a>
            <div>Copyright © 2014-{new Date().getFullYear()} ProcessPlan, LLC. All rights reserved.</div>
          </div>
          : null}
      </div>
    )
  }
}

MilestoneReport.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  get: PropTypes.func.isRequired,
  screenWidth: PropTypes.number,
  updateTitle: PropTypes.func.isRequired
}

MilestoneReport.contextTypes = {
  location: PropTypes.object,
  muiTheme: PropTypes.object,
  language: PropTypes.object,
  user: PropTypes.object
}

const mapStateToProps = state => ({
  user: state.auth,
  screenWidth: state.application.screenWidth
})

const mapDispatchToProps = dispatch => ({
  dispatch,
  get: bindActionCreators(get, dispatch),
  updateTitle: bindActionCreators(updateTitle, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(MilestoneReport)
