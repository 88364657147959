import PropTypes from 'prop-types'
import React from 'react'
import { IconButton, ListItem, ListItemSecondaryAction, ListItemText, Menu, MenuItem } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import DeleteMenuItem from '../Layout/DeleteMenuItem'

class RecurringScheduleListItem extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      value: null,
      item: null,
      menuAnchor: null
    }
  }

  componentDidMount () {
    this.setState({ item: this.props.item })
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.item !== prevProps.item) { this.setState({ item: this.props.item }) }
  }

  render () {
    const { language } = this.context
    const { pushToDataSource, enabled, item, onRemoveField, ...rest } = this.props
    const scheduledItem = this.state.item
    const { menuAnchor } = this.state

    if (!scheduledItem) { return null }

    return (
      <ListItem
        {...rest}
        value={scheduledItem.ID}
      >
        <ListItemText
          primary={scheduledItem.Description}
          secondary={scheduledItem.DriverName}
        />
        <ListItemSecondaryAction>
          <IconButton onClick={(e) => { this.setState({ menuAnchor: e.currentTarget }) }}><MoreVert /></IconButton>
          <Menu
            anchorEl={menuAnchor}
            open={Boolean(menuAnchor)}
            onClose={() => { this.setState({ menuAnchor: null }) }}
            getContentAnchorEl={null}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          >
            {(pushToDataSource)
              ? <MenuItem
                onClick={() => pushToDataSource(scheduledItem.ID)}
              >{language.translate('application.edit')}</MenuItem>
              : null}
            <DeleteMenuItem
              onDelete={this.props.onRemoveField}
            />
          </Menu>
        </ListItemSecondaryAction>
      </ListItem>
    )
  }
}

RecurringScheduleListItem.propTypes = {
  item: PropTypes.object.isRequired,
  onRemoveField: PropTypes.func.isRequired,
  pushToDataSource: PropTypes.func,
  enabled: PropTypes.bool
}

RecurringScheduleListItem.contextTypes = {
  language: PropTypes.object
}

export default RecurringScheduleListItem
