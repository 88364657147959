import PropTypes from 'prop-types'
import React from 'react'
import { FormsyText } from 'formsy-material-ui'
import { Divider, IconButton, ListItem, ListItemSecondaryAction, ListItemText, Menu, MenuItem } from '@material-ui/core'
import { Check, MoreVert } from '@material-ui/icons'
import FormHelper from '~/businessLogic/formHelper'
import { Form } from 'formsy-react'
import ReorderableListItem from '../Layout/ReorderableListItem'
import DeleteMenuItem from '../Layout/DeleteMenuItem'
import DeleteIconButton from '../Layout/DeleteIconButton'

class TemplateTaskChecklistItem extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      editMode: false,
      canSubmit: true,
      value: null,
      menuAnchor: null
    }
  }

  componentDidMount () {
    if (this.props.item.ItemDescription === '') { this.setState({ editMode: true }) }
  }

  enableButton () {
    this.setState({ canSubmit: true })
  }

  disableButton () {
    this.setState({ canSubmit: false })
  }

  handleChecklistItemChange (event) {
    this.setState({ value: event.currentTarget.value })
  }

  handleSave () {
    this.props.onSave(this.state.value)

    this.setState({ editMode: false, value: null })
  }

  render () {
    const { language, muiTheme } = this.context
    const { item, index, onReorderRequest, onSave, droppedItem, onRemoveField, ...rest } = this.props
    const { menuAnchor } = this.state

    return (
      <div>
        {(!this.state.editMode)
          ? <ReorderableListItem
            primaryText={FormHelper.decodeHTML(item.ItemDescription)}
            rightIconButton={
              <IconButton onClick={(e) => { this.setState({ menuAnchor: e.currentTarget }) }}><MoreVert /></IconButton>
            }
            index={index}
            id={item.ID}
            moveItem={data => onReorderRequest(data.dragIndex, data.hoverIndex)}
            droppedItem={droppedItem}
            {...rest}
          />
          : <Form onValid={this.enableButton.bind(this)} onInvalid={this.disableButton.bind(this)}>
            <ListItem>
              <ListItemText primary={
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  alignItems: 'center'
                }}
                >
                  <FormsyText
                    floatingLabelText={language.translate('application.description')}
                    required
                    name={`item-${item.ID}`}
                    updateImmediately
                    defaultValue={FormHelper.decodeHTML(item.ItemDescription)}
                    onChange={this.handleChecklistItemChange.bind(this)}
                    validationErrors={language.messages.validationErrors}
                  />
                </div>} />
              <ListItemSecondaryAction style={{ top: '20%' }}>
                <div>
                  <DeleteIconButton onDelete={onRemoveField} />
                  <IconButton
                    disabled={!this.state.canSubmit}
                    onClick={this.handleSave.bind(this)}
                  >
                    <Check />
                  </IconButton>
                </div>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
          </Form>}
        <Menu
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClose={() => { this.setState({ menuAnchor: null }) }}
          getContentAnchorEl={null}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
          <MenuItem
            onClick={() => this.setState({ editMode: true, value: item.ItemDescription, menuAnchor: null })}
          >{language.translate('application.edit')}</MenuItem>
          <DeleteMenuItem
            onDelete={() => {
              this.setState({ menuAnchor: null })
              onRemoveField()
            }}
          />
        </Menu>
      </div>
    )
  }
}

TemplateTaskChecklistItem.propTypes = {
  item: PropTypes.object.isRequired,
  style: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onRemoveField: PropTypes.func.isRequired,
  droppedItem: PropTypes.func,
  index: PropTypes.number,
  onReorderRequest: PropTypes.func
}

TemplateTaskChecklistItem.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default TemplateTaskChecklistItem
