import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { updateTitle } from '../actions/application'
import { push } from 'react-router-redux'
import { get, post } from '../actions/base'
import DataList from '../components/Layout/DataList'
import TemplatesByGroup from '../components/ProcessTemplate/TemplatesByGroup'

class ProcessLibrary extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      processTemplates: {},
      templatesLoaded: false,
      update: false,
      forceDataRefresh: 0,
      showImportMessage: false
    }
  }

  componentDidMount () {
    this.updateTitle()
  }

  componentDidUpdate (prevProps, prevState) {
    this.updateTitle()
  }

  updateTitle () {
    const { language } = this.context

    this.props.dispatch(updateTitle(language.translate('application.processLibrary')))
  }

  dataLoadCallback (data) {
    const grouped = data.reduce((acc, template) => {
      const templateGroupName = (template.ProcessGroupName) ? template.ProcessGroupName : 'Not Grouped'
      acc[templateGroupName] = acc[templateGroupName] || []
      acc[templateGroupName].push(template)
      return acc
    }, {})

    this.setState({
      processTemplates: grouped,
      templatesLoaded: true
    })
  }

  handleProcessSelect (templateId) {
    this.props.push({
      pathname: `/process-template/${templateId}`,
      query: {
        library: true
      }
    })
  }

  forceUpdate () {
    this.setState({ update: !this.state.update })
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const { mainContentWidth, location } = this.props
    const { processTemplates, forceDataRefresh, showImportMessage } = this.state

    return (
      <div>
        <DataList
          url='library/processtemplate/list'
          dataCallback={this.dataLoadCallback.bind(this)}
          fetchAll
          responseProperty='ProcessTemplateList'
          filterableColumns={[{
            name: language.translate('application.title'),
            property: 'Title'
          }, {
            name: language.translate('application.description'),
            property: 'Description'
          }]}
          noDataText={language.translate('application.noProcessTemplates')}
          forceDataRefresh={forceDataRefresh}
          location={location}
        >
          {Object.keys(processTemplates).sort().map((group, index) => (
            <TemplatesByGroup
              key={index}
              groupName={group}
              templates={processTemplates[group]}
              onClick={this.handleProcessSelect.bind(this)}
              onGroupToggle={toggle => this.forceUpdate()}
              mainContentWidth={mainContentWidth}
              hideMenu
            />
          ))}
        </DataList>
        <Dialog
          open={showImportMessage}
          disableBackdropClick
          disableEscapeKeyDown
          onClose={() => this.setState({ showImportMessage: false })}
          style={{ zIndex: 9999 }}
        >
          <DialogTitle
            style={{
              backgroundColor: palette.headerBackgroundColor
            }}
            disableTypography
          >
            <Typography
              variant='h6'
              style={{ color: palette.alternateTextColor }}>
              {language.translate('application.importingTemplate')}
            </Typography>
          </DialogTitle>
          <DialogContent style={{ width: '300px' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                paddingTop: '30px'
              }}
            >
              {language.translate('application.importingTemplatesMessage')}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              color='primary'
              onClick={() => this.setState({ showImportMessage: false })}
              variant='contained'
            >
              {language.translate('application.close')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

ProcessLibrary.propTypes = {
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.string,
  get: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  mainContentWidth: PropTypes.number.isRequired,
  location: PropTypes.object
}

ProcessLibrary.contextTypes = {
  muiTheme: PropTypes.object,
  language: PropTypes.object
}

const mapStateToProps = (state, ownProps) => ({
  title: state.application.title,
  mainContentWidth: state.application.mainContentWidth,
  location: ownProps.location
})

const mapDispatchToProps = dispatch => ({
  get: bindActionCreators(get, dispatch),
  post: bindActionCreators(post, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ProcessLibrary)
