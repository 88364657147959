import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import CustomField from '~/components/CustomField'
import {
  Avatar, Button, Card, CardHeader, CardContent, Chip, CircularProgress, Collapse, Dialog, DialogActions,
  DialogContent, DialogTitle, IconButton, LinearProgress, Typography
} from '@material-ui/core'
import { ExpandMore, Today } from '@material-ui/icons'
import DateHelper from '~/businessLogic/dateHelper'
import moment from 'moment'
import objectAssign from 'object-assign'
import { push } from 'react-router-redux'
import { Form } from 'formsy-react'
import numeral from 'numeral'
import { Base64 } from 'js-base64'
import { get, post } from '~/actions/base'
import { showSnackbar } from '~/actions/snackbar'
import ClosedInfoCard from '~/components/Tasks/ClosedInfoCard'
import DateField from '~/components/Forms/DateField'
import GroupUserSelect from '~/components/Forms/GroupUserSelect'
import RightPanelContent from '~/components/Layout/RightPanelContent'
import MessageNotificationIcon from '~/components/Messages/MessageNotificationIcon'
import ImpromptuTaskDialog from '~/components/Process/ImpromptuTaskDialog'
import ProcessFieldHelper from '~/businessLogic/processFieldHelper'
import ResponseCard from '~/components/Tasks/ResponseCard'

class TaskView extends React.Component {
  constructor(props) {
    super(props)

    this.tick = this.tick.bind(this)

    this.state = {
      task: null,
      isTaskLoaded: false,
      process: null,
      checklist: null,
      resources: null,
      responses: null,
      fields: null,
      canSubmitProcessFields: true,
      canSubmitTaskInfo: true,
      isSubmitting: false,
      taskStartDateDialogOpen: false,
      taskDueDateDialogOpen: false,
      taskAssignedToDialogOpen: false,
      showImpromptuTaskModal: false,
      tasksWithNotes: [],
      tasksWithNotesLoaded: false,
      expandNotes: false,
      timer: 0,
      timerInterval: null,
      taskList: [],
      taskListLoaded: false,
      showAccountInfoDialog: false,
      accountInfo: {},
      accountInfoLoaded: false
    }
  }

  // MIGRATED TO componentDidMount
  // componentWillMount () {
  //   this.fetchTask()
  // }

  componentDidMount() {
    if (this.state.isTaskLoaded === false) {
      this.fetchTask()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.taskId !== this.props.taskId) {
      this.fetchTask()
      this.setState({
        accountInfo: {},
        accountInfoLoaded: false,
        showAccountInfoDialog: false
      })
    }
  }

  componentWillUnmount() {
    if (this.state.timerInterval) {
      clearInterval(this.state.timerInterval)
    }
  }

  fetchTask() {
    const { taskId, onRequestClose, location, user } = this.props

    if (!taskId) {
      onRequestClose()
      return false
    }

    this.setState({ isTaskLoaded: false })

    this.props.get(`processinstancetask/${taskId}`, {
      onSuccess: (response) => {
        const fields = response.ProcessInstanceFieldList.map((field, index) => {
          index += 1
          if (location.query[`fld${index}`] || location.query[`fld${field.ID}`]) {
            let queryStringValue = location.query[`fld${index}`] || location.query[`fld${field.ID}`]
            if (field.FieldOptionList) {
              field.ValueID = ProcessFieldHelper.getIdsFromOptionListArray(queryStringValue, field)
            } else {
              field.Value = queryStringValue
              field.ValueID = queryStringValue
            }
            field.isDirty = true
          }

          return field
        })

        this.setState({
          task: response.ProcessInstanceTask,
          isTaskLoaded: true,
          isSubmitting: false,
          process: response.ProcessInstance,
          checklist: response.ProcessInstanceTaskChecklistItemList,
          resources: response.ProcessTemplateTaskResourceList,
          responses: response.ProcessInstanceTaskResponseList,
          fields,
          canSubmitProcessFields: true
        }, () => {
          if (this.state.timerInterval) {
            clearInterval(this.state.timerInterval)
          }

          if ((response.ProcessInstanceTask.IsOnDemand && user.isOnDemandUser) || (response.ProcessInstanceTask.TimeEntryRequired && user.timeTrackingUnit === 'M')) {
            let timerInterval = setInterval(this.tick, 1000)
            this.setState({ timerInterval, timer: response.ProcessInstanceTask.MinutesSpent * 60 || 0 })
          }
        })

        this.fetchTasksWithNotes(response.ProcessInstance.ID)
      }
    })
  }

  tick() {
    let newTime = this.state.timer + 1
    this.setState({ timer: newTime })
  }

  fetchTasksWithNotes(instanceId) {
    this.props.get(`processinstance/${instanceId}/task/list/containingnotes`, {
      onSuccess: (response) => {
        this.setState({
          tasksWithNotes: response.ProcessInstanceTaskList,
          tasksWithNotesLoaded: true
        })
      }
    })
  }

  submitResponse(responseID, callback) {
    const { task, fields } = this.state
    const { user } = this.props
    this.setState({
      isSubmitting: true
    })

    const processFields = []

    fields.map((field) => {
      if (field.ProcessTemplateFieldID && !field.ReadOnlyField) {
        processFields.push(field)
      }
    })

    const taskPayload = {
      Notes: task.Notes,
      HoursSpent: task.HoursSpent,
      MinutesSpent: (task.IsOnDemand || (task.TimeEntryRequired && user.timeTrackingUnit === 'M')) ? Math.ceil(this.state.timer / 60) : task.MinutesSpent || 0,
      ID: task.ID,
      ProcessInstanceID: task.ProcessInstanceID
    }

    if (task.ExpenseInUSDollars) {
      taskPayload.ExpenseInUSDollars = task.ExpenseInUSDollars
    }

    // Update the task on the API
    if (responseID) { this.submitTaskResponse(taskPayload, processFields, responseID) } else { this.updateTask(task.ID, taskPayload, processFields, callback) }
  }

  updateTask(taskID, taskData, processData, callback) {
    const { location } = this.props

    const queryString = location.query

    const body = JSON.stringify({
      ProcessInstanceTask: taskData,
      ProcessInstanceFieldList: this.getDirtyFields(processData),
      ProcessInstanceTaskChecklistItemList: this.getCheckListValues(),
      POSTOptions: {
        ValidateRequiredProcessFields: false
      }
    })

    this.props.dispatch(post(`processinstance/${taskData.ProcessInstanceID}/task/${taskID}`, body, {
      onSuccess: () => {
        this.setState({ isSubmitting: false })

        Object.keys(queryString).map((query) => {
          if (query.substr(0, 3) === 'fld') {
            delete queryString[query]
          }
        })

        push({
          pathname: location.pathname,
          query: queryString
        })

        if (callback) {
          callback()
        } else {
          this.props.onRequestClose()
        }
      },

      onError: () => {
        this.setState({
          isSubmitting: false
        })
      }
    }))
  }

  getDirtyFields(fields) {
    const dirtyFields = fields.filter(field => (field.isDirty || !field.ID))

    dirtyFields.map(field => delete field.isDirty)

    return dirtyFields
  }

  cancelTask() {
    const { task } = this.state

    const body = JSON.stringify({})

    this.props.dispatch(post(`processinstance/${task.ProcessInstanceID}/task/${task.ID}/cancel`, body, {
      onSuccess: () => {
        this.setState({ isSubmitting: false })
        this.props.onRequestClose()
      },
      onError: () => {
        this.setState({
          isSubmitting: false
        })
      }
    }))
  }

  getCheckListValues() {
    const selected = []

    if (!this.state.checklist) { return selected }

    this.state.checklist.map((option) => {
      selected.push({
        ProcessTemplateTaskChecklistID: option.ProcessTemplateTaskChecklistID,
        Checked: (option.Checked),
        ID: option.ID || null
      })
    })

    return selected
  }

  submitTaskResponse(taskData, processData, ResponseID) {
    const { task, responses } = this.state
    const ProcessInstanceID = task.ProcessInstanceID
    const TaskID = task.ID
    const responseAttribute = (responses[0].ProcessTemplateTaskResponseID) ? 'ProcessTemplateTaskResponseID' : 'ResponseText'
    const body = JSON.stringify({
      ProcessInstanceTask: taskData,
      ProcessInstanceFieldList: this.getDirtyFields(processData),
      ProcessInstanceTaskChecklistItemList: this.getCheckListValues(),
      POSTOptions: {
        [responseAttribute]: ResponseID,
        SendNewTaskEmailNotification: false
      }
    })

    this.props.dispatch(post(`processinstance/${ProcessInstanceID}/task/${TaskID}/respond`, body, {
      onSuccess: (response) => {
        // If there are more child tasks for this user, then load the next task
        // so the user can just walk through them.  If there is no next task for the user, but the process is
        // complete, take them to a process completion receipt screen.  If none of the above, return them to
        // the dashboard
        // let usersPendingTasks = response.ProcessInstanceTask.filter((task) => (task.AssignedTo_CurrentUser === true));

        if (response.CustomHTMLList && response.CustomHTMLList.length) {
          this.handleCustomHTML(response.CustomHTMLList)
        }

        if (response.ProcessInstance && response.ProcessInstance.CompletedDate_Local !== '') {
          // take them to a process receipt screen
          const newQuery = objectAssign({}, this.props.location.query, { ptype: 'process', pid: ProcessInstanceID })
          this.props.push({
            pathname: this.props.location.pathname,
            query: newQuery
          })
        } else if (response.ProcessInstanceTask) {
          this.props.onResponseSubmit(response.ProcessInstanceTask)
        } else {
          this.props.onRequestClose() // fallback - this should never get triggered
        }
      },

      onError: () => {
        this.setState({
          isSubmitting: false
        })
      }
    }))
  }

  handleCustomHTML(list) {
    let hasPrinted = false

    list.map((htmlItem, index) => {
      if (htmlItem.AutoDisplay) {
        // open in new tab
        window.open(`/custom-html?src=processinstance/${htmlItem.ProcessInstanceID}/action/${htmlItem.IntegrationActionID}/customhtml/list&idx=${index}`, '_blank')
      }

      if (htmlItem.AutoPrint && !hasPrinted && !htmlItem.AutoDisplay) {
        const printContainer = document.getElementById('printContainer').contentWindow

        printContainer.document.open()
        printContainer.document.write(htmlItem.HTMLContent)
        printContainer.document.close()
        printContainer.focus()
        printContainer.print()
        printContainer.close()
        hasPrinted = true
      }
    })
  }

  isTaskCompleted() {
    const { task } = this.state

    return !!((task.Completed_Date_Local || task.Canceled_Date_Local))
  }

  submitStartDate(date) {
    const { user } = this.context

    date = (date) ? DateHelper.formatForAPI(date, `${user.dateFormat}THH:mm:ss`, false) : ''

    this.updateTaskAPI({ StartDate_Local: date }, (response) => {
      let { task } = this.state

      task.StartDate_Local = response.ProcessInstanceTask.StartDate_Local

      this.setState({
        taskStartDateDialogOpen: false,
        task
      })
    })
  }

  submitDueDate(date) {
    const { user } = this.context

    date = (date) ? DateHelper.formatForAPI(date, `${user.dateFormat}THH:mm:ss`, false) : ''

    this.updateTaskAPI({ DueDate_Local: date }, (response) => {
      let { task } = this.state

      task.DueDate_Local = response.ProcessInstanceTask.DueDate_Local

      this.setState({
        taskDueDateDialogOpen: false,
        task
      })
    })
  }

  submitAssignedTo(groupId, userId) {
    this.updateTaskAPI({ AssignedTo_UserGroup_ID: groupId, AssignedTo_User_ID: userId }, (response) => {
      let { task } = this.state

      task.AssignedTo_Date_Local = response.ProcessInstanceTask.AssignedTo_Date_Local
      task.AssignedTo_Date_UTC = response.ProcessInstanceTask.AssignedTo_Date_UTC
      task.AssignedTo_DisplayName = response.ProcessInstanceTask.AssignedTo_DisplayName
      task.AssignedTo_ProfilePicURL = response.ProcessInstanceTask.AssignedTo_ProfilePicURL
      task.AssignedTo_UserGroup_ID = response.ProcessInstanceTask.AssignedTo_UserGroup_ID
      task.AssignedTo_UserGroup_Name = response.ProcessInstanceTask.AssignedTo_UserGroup_Name
      task.AssignedTo_User_EmailAddress = response.ProcessInstanceTask.AssignedTo_User_EmailAddress
      task.AssignedTo_User_FirstName = response.ProcessInstanceTask.AssignedTo_User_FirstName
      task.AssignedTo_User_FullName = response.ProcessInstanceTask.AssignedTo_User_FullName
      task.AssignedTo_User_ID = response.ProcessInstanceTask.AssignedTo_User_ID

      this.setState({
        taskAssignedToDialogOpen: false,
        task
      })
    })
  }

  updateTaskAPI(payload, onSuccess) {
    const { task } = this.state

    const body = JSON.stringify({
      ProcessInstanceTask: payload,
      POSTOptions: {
        ValidateRequiredProcessFields: false
      }
    })

    this.props.dispatch(post(`processinstance/${task.ProcessInstanceID}/task/${task.ID}`, body, {
      onSuccess: (response) => {
        onSuccess(response)
      }
    }))
  }

  userCanCancelTask() {
    const { user } = this.props
    const { process } = this.state

    return (user.canCancelProcesses || process.CreatedByUser_ID === user.userid)
  }

  showInstanceMemos() {
    const { process, task } = this.state
    const { user } = this.context

    let newQuery = objectAssign({},
      this.props.location.query,
      {
        ptype: 'memos',
        memoType: 'instance',
        pid: process.ID
      })

    if (user.userid !== task.AssignedTo_User_ID) {
      newQuery.usid = task.AssignedTo_User_ID
      newQuery.gsid = task.AssignedTo_UserGroup_ID
    }

    this.props.push({
      pathname: this.props.location.pathname,
      query: newQuery
    })
  }

  createImpromptuTask(task, afterTemplateTaskId, errorCallback) {
    const { post, showSnackbar, push, location } = this.props
    const { language } = this.context

    task.ProcessInstanceID = this.state.task.ProcessInstanceID

    const body = JSON.stringify({
      ProcessInstanceTask: task,
      POSTOptions: {
        ProcessTemplateTaskID: afterTemplateTaskId
      }
    })

    post(`processinstance/${task.ProcessInstanceID}/task`, body, {
      onSuccess: (response) => {
        this.setState({ showImpromptuTaskModal: false })
        showSnackbar({
          message: language.translate('application.impromptuTaskCreated'),
          action: language.translate('application.openThisTask'),
          actionEvent: () => {
            push({
              pathname: location.pathname,
              query: objectAssign(
                {},
                location.query,
                {
                  ptype: 'task',
                  task: response.ProcessInstanceTask.ID,
                  pid: response.ProcessInstanceTask.ID
                })
            })
          }
        })
      },
      onError: errorCallback
    })
  }

  getTemplateTasks() {
    const { task } = this.state

    this.props.get(`processtemplate/${task.ProcessTemplateID}/task/list`, {
      onSuccess: (response) => {
        this.setState({
          taskList: response.ProcessTemplateTaskList,
          taskListLoaded: true
        })
      }
    })
  }

  fetchAccountInfo() {
    this.props.get(`account`, {
      onSuccess: (response) => {
        this.setState({
          accountInfo: response.Account,
          accountInfoLoaded: true,
          showAccountInfoDialog: true
        })
      }
    })
  }

  render() {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const { user, get, push, location, rightPanelWidth } = this.props
    const {
      task, process, checklist, resources, responses, fields, isTaskLoaded, isSubmitting, showImpromptuTaskModal,
      tasksWithNotes, tasksWithNotesLoaded, expandNotes, timer, taskList, taskListLoaded, showAccountInfoDialog,
      accountInfo, accountInfoLoaded
    } = this.state

    let startDatePrefix = (task && task.StartDate_Local) ? (moment().isAfter(task.StartDate_Local)) ? language.translate('application.started') : language.translate('application.starts') : null

    return (
      (isTaskLoaded)
        ? <RightPanelContent
          toolbarStyle={{ height: '80px' }}
          leftToolbarAvatar={
            <Avatar
              className={(task.AllowReassign && user.canChangeTaskAssignments && !task.TaskLocked) ? 'clickable' : ''}
              src={task.AssignedTo_ProfilePicURL}
              style={{ marginLeft: '10px', height: '60px', width: '60px', marginTop: '4px' }}
              onClick={() => ((task.AllowReassign && user.canChangeTaskAssignments && !task.TaskLocked) ? this.setState({ taskAssignedToDialogOpen: true }) : false)}
            />
          }
          titleStyle={{ overflow: 'inherit' }}
          title={(timer) ? numeral(timer).format('00:00:00') : null}
          closePanel={() => this.props.onRequestClose()}
          rightToolbarItem={
            <div>
              {(user.canViewMyProcesses || user.canViewAllProcesses)
                ? <MessageNotificationIcon
                  onClick={() => this.showInstanceMemos()}
                  memoType='ProcessInstance'
                  memoTypeID={process.ID}
                  get={get}
                /> : null}
            </div>
          }
          toolbarMenuItems={[
            (user.canViewMyProcesses || user.canViewAllProcesses)
              ? {
                onClick: () => this.props.push(`/process-visual-progress/${task.ProcessInstanceID}`),
                label: language.translate('application.viewProgress')
              }
              : null,
            (user.canViewMyProcesses || user.canViewAllProcesses)
              ? {
                onClick: () => { this.props.push(`/process-history/${task.ProcessInstanceID}`) },
                label: language.translate('application.history')
              }
              : null,
            (user.canViewMyProcesses || user.canViewAllProcesses)
              ? {
                onClick: () => {
                  this.props.push({
                    pathname: location.pathname,
                    query: objectAssign({}, location.query, { ptype: 'process', pid: process.ID })
                  })
                },
                label: language.translate('application.processInfo')
              }
              : null,
            (!this.isTaskCompleted() && this.userCanCancelTask())
              ? {
                onClick: () => this.cancelTask(),
                label: language.translate('application.cancelThisTask'),
                isDelete: true
              }
              : null,
            {
              onClick: () => {
                this.getTemplateTasks()
                this.setState({ showImpromptuTaskModal: true })
              },
              label: language.translate('application.addImpromptuTask')
            },
            (user.isOnDemandUser && task.IsOnDemand)
              ? {
                onClick: () => {
                  this.fetchAccountInfo()
                },
                label: language.translate('application.accountInformation')
              }
              : null
          ].filter(Boolean)}
        >
          {(isSubmitting) ? <LinearProgress /> : null}
          <div style={{ padding: '10px' }}>
            {(!this.isTaskCompleted())
              ? <div style={{ display: 'flex', justifyContent: 'space-around', paddingBottom: '10px' }}>
                <Chip
                  label={(task.StartDate_Local)
                    ? `${startDatePrefix} ${moment(task.StartDate_Local).calendar(null, {
                      sameDay(now) {
                        return `[${this.fromNow()}]`
                      },
                      nextDay: `[${language.translate('application.tomorrow').lcFirst()}]`,
                      nextWeek: 'dddd',
                      lastDay: `[${language.translate('application.yesterday').lcFirst()}]`,
                      lastWeek(now) {
                        const day = this.format('dddd')
                        return `[${language.translate('application.lastWeekday', [day]).lcFirst()}]`
                      },
                      sameElse(now) {
                        return `[${this.fromNow()}]`
                      }
                    })}`
                    : language.translate('application.noStartDate')}
                  avatar={<Avatar><Today nativeColor={palette.canvasColor} /></Avatar>}
                  onClick={(!task.EditableDates || task.TaskLocked) ? null : () => this.setState({ taskStartDateDialogOpen: true })}
                  disabled={(!task.EditableDates || task.TaskLocked)}
                  style={{ cursor: (task.EditableDates && !task.TaskLocked) ? 'pointer' : 'default' }}
                  onDelete={(task.StartDate_Local && task.EditableDates && !task.TaskLocked) ? () => this.submitStartDate('') : null}
                />
                <Chip
                  label={(task.DueDate_Local)
                    ? `${language.translate('application.due')} ${moment(task.DueDate_Local).calendar(null, {
                      sameDay(now) {
                        return `[${this.fromNow()}]`
                      },
                      nextDay: `[${language.translate('application.tomorrow').lcFirst()}]`,
                      nextWeek: 'dddd',
                      lastDay: `[${language.translate('application.yesterday').lcFirst()}]`,
                      lastWeek(now) {
                        const day = this.format('dddd')
                        return `[${language.translate('application.lastWeekday', [day]).lcFirst()}]`
                      },
                      sameElse(now) {
                        return `[${this.fromNow()}]`
                      }
                    })}`
                    : language.translate('application.noDueDate')}
                  avatar={<Avatar><Today nativeColor={palette.canvasColor} /></Avatar>}
                  onClick={(!task.EditableDates || task.TaskLocked) ? null : () => this.setState({ taskDueDateDialogOpen: true })}
                  style={{ cursor: (task.EditableDates && !task.TaskLocked) ? 'pointer' : 'default' }}
                  disabled={(!task.EditableDates || task.TaskLocked)}
                  onDelete={(task.DueDate_Local && task.EditableDates && !task.TaskLocked) ? () => this.submitDueDate('') : null}
                />
              </div>
              : null}

            <div style={{ display: (rightPanelWidth > 800) ? 'flex' : 'inherit', flexDirection: 'row' }}>
              <div style={{ display: 'flex', flexDirection: 'column', flex: 1, marginRight: (rightPanelWidth > 800) ? '10px' : '0px' }}>
                {(!this.isTaskCompleted())
                  ? <Form
                    onValid={() => this.setState({ canSubmitTaskInfo: true })}
                    onInvalid={() => this.setState({ canSubmitTaskInfo: false })}
                  >
                    <ResponseCard
                      task={task}
                      checklist={checklist}
                      resources={resources}
                      responses={responses}
                      onResponseSubmit={this.submitResponse.bind(this)}
                      canRespond={(this.state.canSubmitProcessFields && this.state.canSubmitTaskInfo)}
                      canSave={(!this.state.isSubmitting)}
                      canSubmitTaskInfo={this.state.canSubmitTaskInfo}
                      onChecklistChange={(checklist) => { this.setState({ checklist }) }}
                      onTaskChange={(task) => { this.setState({ task }) }}
                      onTimeSpentChange={(minutes) => {
                        if ((task.IsOnDemand && user.isOnDemandUser) || (task.TimeEntryRequired && user.timeTrackingUnit === 'M')) {
                          this.setState({ timer: minutes * 60 })
                        }
                      }}
                      initiallyExpanded={task.Notes !== '' || task.TaskNoteRequired}
                      user={user}
                      disabled={(task.TaskLocked)}
                    />
                  </Form>
                  : null}

                {(this.isTaskCompleted())
                  ? <ClosedInfoCard task={task} checklist={checklist} resources={resources} />
                  : ''}

                {(tasksWithNotesLoaded && tasksWithNotes.length > 0 && (
                  <Card style={{ marginTop: '20px', marginBottom: '20px' }}>
                    <CardHeader
                      title={language.translate('application.previousTaskNotes')}
                      subheader={language.translate('application.taskNotesSubtitle')}
                      action={
                        <IconButton
                          style={{ transform: (expandNotes) ? 'rotate(180deg)' : 'rotate(0deg)', marginLeft: 'auto' }}
                          onClick={() => this.setState({ expandNotes: !expandNotes })}
                          aria-expanded={expandNotes}
                          aria-label='Show more'
                        >
                          <ExpandMore />
                        </IconButton>
                      }
                    />
                    <Collapse in={expandNotes}>
                      <CardContent>
                        {tasksWithNotes.map((task, index) => {
                          return (
                            <div
                              key={task.ID}
                              style={{
                                padding: '5px',
                                borderTop: index && `1px solid ${palette.borderColor}`
                              }}>
                              <div className='field-label'>
                                {task.TaskText_Display}
                                {task.Completed_Date_Local && ` - ${language.translate('application.completed')}: ${moment(task.Completed_Date_Local).fromNow()} ${language.translate('application.by')} ${task.Completed_FullName}`}
                              </div>
                              <div style={{ paddingLeft: '5px' }}>
                                {task.Notes}
                              </div>
                            </div>)
                        })}
                      </CardContent>
                    </Collapse>
                  </Card>))}
              </div>

              {/*
           * PROCESS FIELDS
           */}

              <Form
                onValid={() => this.setState({ canSubmitProcessFields: true })}
                onInvalid={() => this.setState({ canSubmitProcessFields: false })}
                autoComplete='off'
                style={{ flex: 1 }}
              >
                <Card>
                  <CardHeader
                    title={language.translate('application.fieldsTitle')}
                    subheader={language.translate('application.fieldsSubTitle')}
                  />
                  <CardContent>
                    {fields.map((field, index) => {
                      let filter = null

                      if (field.DependantOnFieldType) {
                        filter = ProcessFieldHelper.createFilterFromDependantFieldValue(field.DependantOnFieldType, fields)
                      }

                      if (ProcessFieldHelper.isInstanceLookupField(field.FieldType) && field.MetaData) {
                        filter = ProcessFieldHelper.createFilterFromParsedMetaData(field.MetaData, fields)
                      }

                      return <CustomField
                        key={field.ProcessTemplateFieldID}
                        field={field}
                        ref={field.ProcessTemplateFieldID}
                        dispatch={this.props.dispatch}
                        filter={filter}
                        fieldList={fields}
                        onUpdate={(field) => {
                          fields[index] = field
                          fields[index].isDirty = true

                          this.setState({ fields })
                        }}
                        actionButton={(field.FieldType === 'INSTANCE' || field.FieldType === 'INSTCHLS') ? () => {
                          this.submitResponse(null, () => {
                            let newReturnTo = {
                              ...this.props.location.query,
                              newFieldIndex: index + 1
                            }

                            let query = {
                              ptype: 'process_template',
                              pid: field.ProcessInstanceLookupTemplateID,
                              returnTo: Base64.encodeURI(JSON.stringify(newReturnTo))
                            }

                            // parse the correct ID's out of the MetaData and push them into the query string
                            let filterArray = ProcessFieldHelper.parseFiltersFromString(field.MetaData)
                            filterArray.map((filter) => {
                              if (filter.includes('ProcessInstanceFieldList')) {
                                let { instanceFieldId, templateFieldId } = ProcessFieldHelper.parseFieldDependencyFilter(filter)

                                let foundField = fields.filter((fieldToSearch) => (fieldToSearch.ID === instanceFieldId || fieldToSearch.ProcessTemplateFieldID === instanceFieldId))[0]

                                if (foundField.Value) {
                                  query[`fld${templateFieldId}`] = foundField.Value
                                }
                              }
                            })

                            push({
                              pathname: location.pathname,
                              query
                            })
                          })
                        } : null}
                      />
                    })}
                  </CardContent>
                </Card>
              </Form>

            </div>
            {(this.state.taskStartDateDialogOpen)
              ? <Form>
                <DateField
                  name='start_date'
                  required
                  timePicker
                  hideInput
                  onChange={this.submitStartDate.bind(this)}
                  defaultDate={(task.StartDate_Local) ? moment(task.StartDate_Local).toDate() : moment().startOf('day')}
                  ref='start_date'
                  formatDate={date => moment(date).format((user.dateFormat) ? user.dateFormat : 'YYYY-MM-DD')}
                  label={language.translate('application.taskStartDate')}
                  onDismiss={() => { this.setState({ taskStartDateDialogOpen: false }) }}
                />
              </Form>
              : null}

            {(this.state.taskDueDateDialogOpen)
              ? <Form>
                <DateField
                  name='due_date'
                  required
                  timePicker
                  hideInput
                  onChange={this.submitDueDate.bind(this)}
                  defaultDate={(task.DueDate_Local) ? moment(task.DueDate_Local).toDate() : moment().endOf('day')}
                  ref='due_date'
                  formatDate={date => moment(date).format((user.dateFormat) ? user.dateFormat : 'YYYY-MM-DD')}
                  label={language.translate('application.taskDueDate')}
                  onDismiss={() => { this.setState({ taskDueDateDialogOpen: false }) }}
                />
              </Form>
              : null}

            <Dialog
              open={this.state.taskAssignedToDialogOpen}
              onClose={() => this.setState({ taskAssignedToDialogOpen: false })}
            >
              <DialogTitle
                style={{
                  backgroundColor: palette.headerBackgroundColor
                }}
                disableTypography
              >
                <Typography
                  variant='h6'
                  style={{ color: palette.alternateTextColor }}>
                  {language.translate('application.taskAssignedTo')}
                </Typography>
              </DialogTitle>
              <DialogContent style={{ width: '350px' }}>
                <Form>
                  <GroupUserSelect
                    name={language.translate('application.assignedTo')}
                    dispatch={this.props.dispatch}
                    ref='assigned_to'
                    allowAllUsers
                    allowAllGroups
                    allUsersLabel={language.translate('application.anyPersonInGroup')}
                    groupValue={task.AssignedTo_UserGroup_ID}
                    userValue={task.AssignedTo_User_ID}
                    userListEndpoint={(groupId) => (`usergroup/${groupId}/user/list/fortaskassignments`)}
                  />
                </Form>
              </DialogContent>
              <DialogActions>
                <Button
                  key='submit'
                  color='primary'
                  onClick={() => this.submitAssignedTo(this.refs.assigned_to.getValue().UserGroupID, this.refs.assigned_to.getValue().UserID)}
                >{language.translate('application.submit')}</Button>,
                <Button
                  key='cancel'
                  color='primary'
                  onClick={() => this.setState({ taskAssignedToDialogOpen: false })}
                >{language.translate('application.cancel')}</Button>
              </DialogActions>
            </Dialog>
            {(showImpromptuTaskModal && taskListLoaded)
              ? <ImpromptuTaskDialog
                onCancel={() => this.setState({ showImpromptuTaskModal: false })}
                onSubmit={this.createImpromptuTask.bind(this)}
                dispatch={this.props.dispatch}
                taskList={taskList}
                defaultTaskValue={task.ProcessTemplateTaskID}
              />
              : null}
            {(showAccountInfoDialog && accountInfoLoaded &&
              <Dialog
                open
                onClose={() => this.setState({ showAccountInfoDialog: false })}
              >
                <DialogTitle
                  style={{
                    backgroundColor: palette.headerBackgroundColor
                  }}
                  disableTypography
                >
                  <Typography
                    variant='h6'
                    style={{ color: palette.alternateTextColor }}>
                    {language.translate('application.accountInformation')}
                  </Typography>
                </DialogTitle>
                <DialogContent style={{ width: '400px' }}>
                  <div style={{ paddingTop: '10px' }}>
                    <div style={{ textAlign: 'center' }}><img src={accountInfo.HeaderLogoWhiteBG_URL} /></div>
                    <Typography variant='body2'>{accountInfo.AccountDescription}</Typography>
                    <div style={{ paddingTop: '10px', fontWeight: 600 }}>
                      <div>{accountInfo.AccountName}</div>
                      <div>{accountInfo.PhysicalAddress1}</div>
                      {(accountInfo.PhysicalAddress2 && <div>{accountInfo.PhysicalAddress2}</div>)}
                      <div>{accountInfo.PhysicalCity}, {accountInfo.PhysicalState} {accountInfo.PhysicalCountry} {accountInfo.PhysicalPostalCode}</div>
                      <div><a target='_blank' href={accountInfo.WebsiteURL}>{accountInfo.WebsiteURL}</a></div>
                    </div>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    key='close'
                    color='primary'
                    onClick={() => this.setState({ showAccountInfoDialog: false })}
                  >{language.translate('application.close')}</Button>
                </DialogActions>
              </Dialog>
            )}
          </div>
          <iframe
            id='printContainer'
            style={{ height: '0px', width: '0px', position: 'absolute', border: 'none' }}
          />
        </RightPanelContent>
        : <CircularProgress className='loader' />
    )
  }
}

TaskView.propTypes = {
  taskId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  onResponseSubmit: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  get: PropTypes.func,
  post: PropTypes.func,
  showSnackbar: PropTypes.func,
  rightPanelWidth: PropTypes.number,
  location: PropTypes.object,
  user: PropTypes.object.isRequired
}

TaskView.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object,
  user: PropTypes.object
}

const mapStateToProps = state => ({
  user: state.auth,
  rightPanelWidth: state.application.rightPanelWidth
})

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  get: bindActionCreators(get, dispatch),
  post: bindActionCreators(post, dispatch),
  showSnackbar: bindActionCreators(showSnackbar, dispatch),
  dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(TaskView)
