import PropTypes from 'prop-types'
import React from 'react'
import { FormsyDate } from 'formsy-material-ui'
import FormHelper from '../../businessLogic/formHelper'
import DateHelper from '../../businessLogic/dateHelper'
import { IconButton } from '@material-ui/core'
import moment from 'moment'
import { Clear } from '@material-ui/icons'
import FieldWrapper from './FieldWrapper'

class Date extends React.Component {
  constructor (props, context) {
    super(props, context)
    this.state = {}
  }

  formatDate (date) {
    const { user } = this.context

    return moment(date).format((user.dateFormat) ? user.dateFormat : 'YYYY-MM-DD')
  }

  clearDate () {
    const { field, onChange } = this.props

    field.ValueID = ''
    field.Value = ''

    if (onChange) { onChange(field) }
  }

  render () {
    const { field, onChange, wrapperProps } = this.props
    const { user } = this.context

    const dateValue = (field.ValueID) ? moment(field.ValueID).toDate() : undefined

    return (
      <FieldWrapper
        label={FormHelper.decodeHTML(field.FieldName)}
        required={field.RequiredField}
        {...wrapperProps}
      >
        {(field.ReadOnlyField)
          ? <div style={{ position: 'relative', padding: '5px 0px', fontSize: '15px' }}>
            {field.Value}
          </div>
          : <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <FormsyDate
              fullWidth
              style={{ flex: 5 }}
              name={field.FieldName}
              ref={field.FieldName}
              value={dateValue}
              firstDayOfWeek={0}
              required={field.RequiredField}
              validationErrors={this.context.language.messages.validationErrors}
              formatDate={this.formatDate.bind(this)}
              onChange={(event, value) => {
                field.ValueID = DateHelper.formatForAPI(value, user.dateFormat)
                field.Value = moment(value).format(user.dateFormat)
                if (onChange) { onChange(field) }
              }}
            />
            {(field.Value)
              ? <div style={{ flex: 1 }}>
                <IconButton
                  onClick={this.clearDate.bind(this)}
                >
                  <Clear />
                </IconButton></div>
              : null}
          </div>}
      </FieldWrapper>)
  }
}

Date.propTypes = {
  field: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  wrapperProps: PropTypes.object
}

Date.contextTypes = {
  language: PropTypes.object,
  user: PropTypes.object
}

export default Date
