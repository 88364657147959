import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import { Base64 } from 'js-base64'
import {
  Avatar, Badge, Button, Dialog, DialogContent, DialogTitle, Divider, List, ListItem, ListItemText, Popover, TextField,
  Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { ChatBubble, ChatBubbleOutline, Notifications, NotificationsNone, Person } from '@material-ui/icons'
import { logoutAndRedirect, setAccountData, setLoginData, setUserData } from '../../actions/authentication'
import { setApiServer } from '../../actions/application'
import { connectToPusher, disconnectPusher } from '../../actions/pusher'
import { get, post } from '../../actions/base'
import objectAssign from 'object-assign'
import { push } from 'react-router-redux'
import NotificationIcon from '../Notifications/NotificationIcon'
import CancelButton from '../Forms/CancelButton'

const styles = theme => ({
  badge_nothidden: {
    top: 30,
    right: 35
  },
  badge_hidden: {
    display: 'none'
  },
  primary: {
    backgroundColor: theme.palette.headerFontColor,
    color: theme.palette.headerBackgroundColor
  }
})

class AccountMenu extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      showUserAccountMenu: false,
      accountElement: null,
      showAccountPasswordDialog: false,
      password: '',
      switchToAccount: null,
      isAuthenticating: false
    }
  }

  logoutAPI () {
    const { post, logout } = this.props

    const body = JSON.stringify({})

    post('logout', body, {
      onSuccess: (response) => {
        logout()
      }
    })
  }

  switchAccount () {
    const { user, push, connectToPusher, disconnectPusher, setApiServer } = this.props
    const { password, switchToAccount } = this.state

    this.setState({ isAuthenticating: true })

    const headers = {
      Authorization: `Basic ${Base64.encode(`${user.userName}:${password}`)}`,
      accountid: switchToAccount.ID
    }

    if (switchToAccount.DataServerRegion) {
      setApiServer(`https://${switchToAccount.DataServerRegion}.processplan.com`)
    }

    this.props.get('authenticate', {
      onSuccess: (response) => {
        response.User.Accounts = user.accountList
        this.props.setUserData(response.User)
        this.props.setAccountData(response.Account)
        this.props.setLoginData(response.User)
        this.setState({
          password: '',
          isAuthenticating: false,
          showAccountPasswordDialog: false,
          switchToAccount: null
        }, () => {
          push('/login')
          disconnectPusher()
          connectToPusher()
        })
      },

      headers: headers,

      onError: (error) => {
        this.setState({ isAuthenticating: false })
      }
    })
  }

  returnToHomeAccount () {
    const { user, post } = this.props

    const body = JSON.stringify({})

    post(`user/${user.userid}/returntohomeaccount`, body, {
      onSuccess: (response) => {
      }
    })
  }

  pushToRecurring (instanceId, templateId) {
    const { push, currentPage } = this.props
    const { location } = this.context

    const queryAttributes = {
      ptype: 'process_template',
      pid: templateId,
      recurring: true
    }

    if (instanceId) { queryAttributes.piid = instanceId }

    this.setState({ showUserAccountMenu: false })

    push({
      pathname: currentPage,
      query: objectAssign({},
        location.query,
        queryAttributes
      )
    })
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const { showAccountPasswordDialog, isAuthenticating } = this.state
    const {
      user, push, currentPage, unviewedNotificationCount, showNotifications, showIcons, showUserMessages,
      unviewedMessageCount, classes
    } = this.props

    return (
      <div>
        <Badge
          style={{ padding: '0px' }}
          badgeContent={unviewedNotificationCount + unviewedMessageCount}
          classes={{
            badge: (showIcons && unviewedNotificationCount + unviewedMessageCount) ? classes.badge_nothidden : classes.badge_hidden,
            colorPrimary: classes.primary
          }}
          color='primary'
        >
          <Avatar
            onClick={event => this.setState({
              showUserAccountMenu: true,
              accountElement: event.currentTarget
            })}
            src={this.props.user.profilePicURL}
            className='clickable'
            style={{
              backgroundColor: 'transparent',
              marginLeft: '30px',
              marginTop: '0px',
              height: '50px',
              width: '50px'
            }}
          />
        </Badge>
        <Popover
          open={this.state.showUserAccountMenu}
          anchorEl={this.state.accountElement}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          onClose={() => this.setState({ showUserAccountMenu: false })}
        >
          {(showIcons)
            ? <div>
              <div style={{
                width: '60%',
                margin: 'auto',
                display: 'flex',
                justifyContent: 'space-around',
                paddingTop: '5px'
              }}
              >
                <NotificationIcon
                  onClick={() => {
                    this.setState({ showUserAccountMenu: false })
                    showNotifications()
                  }}
                  count={unviewedNotificationCount}
                  icon={
                    (!unviewedNotificationCount)
                      ? <NotificationsNone nativeColor={palette.textColor} />
                      : <Notifications nativeColor={palette.textColor} />
                  }
                  tooltip={language.translate('application.notifications')}
                />
                <NotificationIcon
                  onClick={() => {
                    this.setState({ showUserAccountMenu: false })
                    showUserMessages()
                  }}
                  count={unviewedMessageCount}
                  icon={
                    (!unviewedMessageCount)
                      ? <ChatBubbleOutline
                        nativeColor={palette.textColor}
                      />
                      : <ChatBubble nativeColor={palette.textColor} />
                  }
                  tooltip={language.translate('application.messages')}
                />
              </div>
              <Divider />
            </div>
            : null}

          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            margin: '10px'
          }}
          >
            <Avatar
              src={user.profilePicURL}
              style={{ backgroundColor: 'transparent', margin: '0px 10px', height: '70px', width: '70px' }}
            />
            <div style={{ margin: '0px 10px' }}>
              <div>{user.fullName}</div>
              <div>{user.userName}</div>
              <div>{user.accountName}</div>
              <Button
                style={{ margin: '15px 0px' }}
                color='primary'
                variant='contained'
                onClick={() => {
                  push({
                    pathname: currentPage,
                    query: objectAssign({},
                      this.context.location.query,
                      {
                        ptype: 'user',
                        pid: user.userid,
                        usid: user.userid
                      })
                  })
                  this.setState({ showUserAccountMenu: false })
                }}
              >
                {language.translate('application.myAccount')}
              </Button>
            </div>
          </div>
          {(user.accountList && user.accountList.length)
            ? <div>
              <Divider />
              <div style={{ margin: '10px 0px 0px 15px', fontSize: '12px', color: palette.accent3Color }}>
                {language.translate('application.switchAccount')}:
              </div>
              <List
                className='scrollbar'
                style={{ maxHeight: '200px', overflow: 'scroll', overflowX: 'hidden' }}
              >
                {(user.accountList.map((account) => {
                  if (user.accountID !== account.ID) {
                    return (<ListItem
                      button
                      key={account.ID}
                      onClick={() => {
                        this.setState({
                          switchToAccount: account,
                          showAccountPasswordDialog: true,
                          showUserAccountMenu: false
                        })
                      }}
                    >
                      <ListItemText primary={account.AccountName} />
                    </ListItem>)
                  }
                }))}
              </List>
            </div>
            : null}
          <Divider />
          <div style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'space-between' }}>
            <Button
              variant='contained'
              onClick={() => {
                this.logoutAPI()
              }}
              style={{ margin: '10px' }}
            >
              {language.translate('application.signOut')}
            </Button>
            {(user.isOnDemandUser)
              ? <Button
                variant='contained'
                onClick={() => {
                  this.returnToHomeAccount()
                }}
                style={{ margin: '10px' }}
              >
                {language.translate('application.homeAccount')}
              </Button>
              : null}
          </div>
        </Popover>
        <Dialog
          open={showAccountPasswordDialog}
          disableBackdropClick
          disableEscapeKeyDown
          onClose={() => this.setState({ showAccountPasswordDialog: false })}
        >
          <DialogTitle
            style={{
              backgroundColor: palette.headerBackgroundColor
            }}
            disableTypography
          >
            <Typography
              variant='h6'
              style={{ color: palette.alternateTextColor }}>
              {language.translate('application.enterAccountPassword')}
            </Typography>
          </DialogTitle>
          <DialogContent style={{ width: '300px', paddingTop: '10px' }}>
            <TextField
              required
              label={language.translate('application.password')}
              autoFocus
              type='password'
              onChange={e => this.setState({ password: e.currentTarget.value })}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  this.switchAccount()
                }
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                paddingTop: '30px'
              }}
            >
              <Button
                color='primary'
                variant='contained'
                onClick={this.switchAccount.bind(this)}
                disabled={isAuthenticating}
              >
                <Person />
                {language.translate('application.signIn')}
              </Button>
              <CancelButton
                onClick={() => {
                  this.setState({
                    password: '',
                    showAccountPasswordDialog: false,
                    switchToAccount: null
                  })
                }}
                variant='contained'
              />
            </div>
          </DialogContent>
        </Dialog>
      </div>
    )
  }
}

AccountMenu.propTypes = {
  dispatch: PropTypes.func,
  user: PropTypes.object,
  logout: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  currentPage: PropTypes.string,
  setUserData: PropTypes.func,
  setAccountData: PropTypes.func,
  setLoginData: PropTypes.func,
  connectToPusher: PropTypes.func,
  disconnectPusher: PropTypes.func,
  showNotifications: PropTypes.func,
  showUserMessages: PropTypes.func,
  unviewedNotificationCount: PropTypes.number,
  unviewedMessageCount: PropTypes.number,
  setApiServer: PropTypes.func.isRequired,
  showIcons: PropTypes.bool,
  classes: PropTypes.object.isRequired
}

AccountMenu.contextTypes = {
  muiTheme: PropTypes.object,
  language: PropTypes.object,
  location: PropTypes.object
}

const mapStateToProps = state => ({
  user: state.auth
})

const mapDispatchToProps = dispatch => ({
  logout: bindActionCreators(logoutAndRedirect, dispatch),
  push: bindActionCreators(push, dispatch),
  post: bindActionCreators(post, dispatch),
  get: bindActionCreators(get, dispatch),
  setUserData: bindActionCreators(setUserData, dispatch),
  setAccountData: bindActionCreators(setAccountData, dispatch),
  setLoginData: bindActionCreators(setLoginData, dispatch),
  connectToPusher: bindActionCreators(connectToPusher, dispatch),
  setApiServer: bindActionCreators(setApiServer, dispatch),
  disconnectPusher: bindActionCreators(disconnectPusher, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AccountMenu))
