import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, Typography } from '@material-ui/core'
import { get } from '../../actions/base'
import { logoutAndRedirect } from '../../actions/authentication'
import Subscriber from './Notifications/Subscriber'

class IdleSessionMonitor extends React.Component {
  constructor (props) {
    super(props)

    this.secondsRemaining = ''
    this.countdownInterval = null
    this.tick = this.tick.bind(this)

    this.state = {
      showDialog: false,
      disableButton: false,
      minutes: '05',
      seconds: '00'
    }
  }

  renewSession () {
    this.setState({ disableButton: true })
    const { get } = this.props
    clearInterval(this.countdownInterval)

    get('currentsession', {
      onSuccess: (response) => {
        this.setState({
          showDialog: false,
          minutes: '05',
          seconds: '00',
          disableButton: false
        })
      }
    })
  }

  tick () {
    const { logoutAndRedirect, location } = this.props

    let min = Math.floor(this.secondsRemaining / 60)
    let sec = this.secondsRemaining - (min * 60)
    this.setState({
      minutes: min,
      seconds: sec
    })
    if (sec < 10) {
      this.setState({
        seconds: '0' + this.state.seconds
      })
    }
    if (min < 10) {
      this.setState({
        minutes: '0' + min
      })
    }
    if (min === 0 && sec === 0) {
      clearInterval(this.countdownInterval)
      console.log('logging out due to inactivity')
      logoutAndRedirect(location.pathname, location.query)
    }
    this.secondsRemaining--
  }

  startCountDown () {
    this.countdownInterval = setInterval(this.tick, 1000)
    let time = this.state.minutes
    this.secondsRemaining = time * 60
  }

  render () {
    const { showDialog, minutes, seconds, disableButton } = this.state
    const { muiTheme: { palette }, language, user } = this.context

    return (
      <div>
        <Subscriber
          channelName={'Private-' + user.accountID + '-User-' + user.userid}
          events={['SessionTimeoutImminent']}
          callback={(data) => {
            if (data.User.SessionToken === user.token && user.isAuthenticated) {
              this.startCountDown()
              this.setState({ showDialog: true })
            }
          }} />
        {showDialog && <Dialog
          PaperProps={{ style: { width: '350px' } }}
          open={showDialog}
          onClose={() => this.setState({ showDialog: false })}
        >
          <DialogTitle style={{ backgroundColor: palette.headerBackgroundColor }} disableTypography>
            <Typography
              variant='h6'
              style={{ color: palette.alternateTextColor }}>
              {language.translate('application.sessionTimeout')} - {minutes}:{seconds}
            </Typography>
          </DialogTitle>
          <DialogContent style={{ paddingTop: '15px', paddingBottom: '0px' }}>
            <Typography variant='subtitle1'>{language.translate('application.idleSessionText')}</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              color='primary'
              variant='contained'
              disabled={disableButton}
              onClick={() => this.renewSession()}
            >
              {language.translate('application.yes')}
            </Button>
          </DialogActions>
        </Dialog>}
      </div>
    )
  }
}

IdleSessionMonitor.propTypes = {
  lastActivity: PropTypes.number.isRequired,
  logoutAndRedirect: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  location: PropTypes.object
}

IdleSessionMonitor.contextTypes = {
  user: PropTypes.object,
  muiTheme: PropTypes.object,
  language: PropTypes.object
}

const mapStateToProps = (state) => ({
  lastActivity: state.application.lastActivity
})

const mapDispatchToProps = dispatch => ({
  logoutAndRedirect: bindActionCreators(logoutAndRedirect, dispatch),
  get: bindActionCreators(get, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(IdleSessionMonitor)
