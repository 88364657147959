import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Cancel } from '@material-ui/icons'

class StopUpscope extends Component {
  render () {
    const { language, muiTheme: { palette } } = this.context

    return (
      <div
        onClick={() => window.Upscope('stopSession')}
        style={{
          display: 'none',
          zIndex: 9999,
          bottom: '20px',
          left: '20px',
          position: 'absolute',
          cursor: 'pointer',
          color: palette.alternateTextColor,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '110px',
          height: '110px',
          backgroundColor: palette.errorColor,
          padding: '15px 10px',
          borderRadius: '55px',
          fontSize: '15px',
          boxShadow: 'rgba(200,200,200,0.8) 0 0 10px'
        }}
        id='upscope-stop-screenshare-link'>
        <Cancel style={{ height: '40px', width: '40px' }} nativeColor={palette.alternateTextColor} />
        <div style={{ textAlign: 'center' }}>STOP SHARE</div>
      </div>
    )
  }
}

StopUpscope.propTypes = {}

StopUpscope.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default StopUpscope
