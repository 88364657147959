import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Chip } from '@material-ui/core'
import { fade } from '@material-ui/core/styles/colorManipulator'

class SearchFilters extends Component {
  render () {
    const { filters, handleDeleteSearchFilter } = this.props
    const { palette } = this.context.muiTheme

    return (
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginBottom: '10px' }}>
        {filters.map((filter, index) => {
          let value = filter.displayValue || filter.value || null

          if (value) {
            if (value.length > 16) {
              value = `${value.substr(0, 15)}...`
            }
            value = `'${value}'`
          }

          return (
            <Chip
              key={index}
              onDelete={() => {
                handleDeleteSearchFilter(filter, index)
              }}
              style={{
                margin: '4px',
                border: `1px solid ${palette.borderColor}`,
                backgroundColor: fade(palette.headerBackgroundColor, 0.25)
              }}
              label={`${value || ''} ${filter.displayOperator || 'in'} ${filter.displayText || ''}`}
            />
          )
        })}
      </div>
    )
  }
}

SearchFilters.propTypes = {
  filters: PropTypes.array,
  handleDeleteSearchFilter: PropTypes.func
}

SearchFilters.contextTypes = {
  muiTheme: PropTypes.object
}

export default SearchFilters
