import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { Base64 } from 'js-base64'
import objectAssign from 'object-assign'
import { hideSearchField, showSearchField } from '../../actions/header'
import Masonry from 'react-masonry-component'
import InfiniteScroll from './InfiniteScroll'
import NoDataToShow from './NoDataToShow'
import SearchFilters from './Search/SearchFilters'
import { ingestSearchFromUrl } from './Search/Selectors'

const masonryOptions = {
  transitionDuration: '0.0s'
}

class DataList extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      list: [],
      isLoading: true
    }
  }

  componentDidMount () {
    if (!this.props.disableSearch) { this.props.showSearchField(this.props.filterableColumns) }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.searchItems &&
      this.props.searchItems &&
      prevProps.searchItems.length !== this.props.searchItems.length &&
      this.props.localSearch) { prevProps.dataCallback(this.localFilter()) }

    if (!this.props.disableSearch &&
      prevProps.filterableColumns &&
      this.props.filterableColumns &&
      JSON.stringify(prevProps.filterableColumns) !== JSON.stringify(this.props.filterableColumns)) { this.props.showSearchField(this.props.filterableColumns) }

    if (!prevProps.disableSearch && this.props.disableSearch) {
      this.props.hideSearchField()
    }
  }

  componentWillUnmount () {
    if (!this.props.disableSearch) { this.props.hideSearchField() }
  }

  storeData (response, scroll, callback) {
    const { dataCallback, searchItems } = this.props
    const list = (scroll) ? this.state.list.concat(response[this.props.responseProperty]) : response[this.props.responseProperty]

    this.setState({
      list,
      isLoading: false
    }, () => {
      if (searchItems.length && this.props.localSearch) { dataCallback(this.localFilter()) } else { dataCallback(this.state.list, response) }

      if (callback) { callback() }
    })
  }

  localFilter () {
    const { disableSearch, searchItems } = this.props
    let { list } = this.state

    if (!searchItems.length || disableSearch) { return list }

    searchItems.map((item) => {
      list = list.filter(listItem => (listItem[item.property].trim().toLowerCase().indexOf(item.value.trim().toLowerCase()) >= 0))
    })

    return list
  }

  apiEndpoint () {
    let { url, filterText, localSearch, disableSearch, searchItems } = this.props

    if (searchItems.length && !localSearch && !disableSearch) {
      url += (url.includes('?')) ? '&' : '?'

      searchItems.map((filter, index) => {
        if (index > 0) { url += '&' }

        const filterIndex = index + 1
        const operator = filter.operator || '(CONTAINS_TEXT)'

        url += `filter${filterIndex}=${filter.property}${operator}${filter.value}`
      })
    }

    if (filterText) {
      url = (searchItems.length) ? `${url}&` : `${url}?`
      url += filterText
    }

    return url
  }

  removeSearchFilter (filter) {
    const { location } = this.props
    let query = objectAssign({}, location.query)

    if (!Array.isArray(query.search)) {
      query.search = [query.search]
    }

    const newSearch = query.search.filter((searchItem) => (Base64.encodeURI(JSON.stringify(filter)) !== searchItem))

    delete query.search

    const newQuery = objectAssign({}, { search: newSearch }, query)

    this.props.push({
      pathname: location.pathname,
      query: newQuery
    })
  }

  render () {
    const { disableMasonry, channelName, events, containerElement, hideFilterChips, ...rest } = this.props
    let { location: { query: { search = [] } } } = this.props

    if (!Array.isArray(search)) {
      search = [search]
    }

    const searchObjects = search.map((searchItem) => (JSON.parse(Base64.decode(searchItem))))

    return (
      <InfiniteScroll
        sourceUrl={this.apiEndpoint()}
        dataReceived={this.storeData.bind(this)}
        pageSize={this.props.pageSize || 35}
        fetchAll={this.props.fetchAll || this.props.localSearch}
        responseProperty={this.props.responseProperty}
        forceDataRefresh={this.props.forceDataRefresh}
        channelName={channelName}
        events={events}
        containerElement={containerElement}
        {...rest}
      >
        {(!hideFilterChips)
          ? <SearchFilters
            filters={searchObjects}
            handleDeleteSearchFilter={this.removeSearchFilter.bind(this)}
          />
          : null}
        {
          (!this.state.isLoading && this.state.list.length < 1)
            ? <NoDataToShow noDataText={this.props.noDataText} />
            : (disableMasonry)
            ? this.props.children
            : <Masonry options={masonryOptions}>
              {
                this.props.children
              }
            </Masonry>
        }
      </InfiniteScroll>
    )
  }
}

DataList.propTypes = {
  filterText: PropTypes.string,
  dataCallback: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  filterableColumns: PropTypes.array,
  children: PropTypes.node.isRequired,
  pageSize: PropTypes.number,
  fetchAll: PropTypes.bool,
  hideFilterChips: PropTypes.bool,
  responseProperty: PropTypes.string,
  noDataText: PropTypes.string,
  forceDataRefresh: PropTypes.number,
  disableMasonry: PropTypes.bool,
  disableSearch: PropTypes.bool,
  localSearch: PropTypes.bool,
  channelName: PropTypes.string,
  events: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  containerElement: PropTypes.object,
  hideSearchField: PropTypes.func.isRequired,
  showSearchField: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  location: PropTypes.object,
  searchItems: PropTypes.array
}

DataList.contextTypes = {}

const mapStateToProps = (state, ownProps) => ({
  navigationOpen: state.navigation.open, // this is needed to fix a bug with re-rendering the layout
  searchItems: ingestSearchFromUrl(ownProps.location.query.search)
})

const mapDispatchToProps = dispatch => ({
  hideSearchField: bindActionCreators(hideSearchField, dispatch),
  showSearchField: bindActionCreators(showSearchField, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(DataList)
