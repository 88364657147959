import PropTypes from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Card, CardHeader, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Menu, MenuItem, Table, TableCell,
  Tooltip, Typography
} from '@material-ui/core'
import { AutoComplete } from 'material-ui'
import { Base64 } from 'js-base64'
import { CallSplit, Share, MoreVert, Done, ArrowDownward, ImportExport } from '@material-ui/icons'
import Papa from 'papaparse'
import FileSaver from 'file-saver'
import { push, replace } from 'react-router-redux'
import objectAssign from 'object-assign'
import { Form } from 'formsy-react'
import { showSnackbar } from '../actions/snackbar'
import { disableFullScreen, enableFullScreen, updateTitle } from '../actions/application'
import { get, post } from '../actions/base'
import FormsyAutoComplete from '../components/Forms/FormsyAutoComplete'
import DataList from '../components/Layout/DataList'
import AddIcon from '../components/Layout/AddIcon'
import CustomFieldAdapter from '../components/Process/InstanceGrid/CustomFieldAdapter'
import Subscriber from '../components/Layout/Notifications/Subscriber'
import { GeneratedIcon, Icon } from '../components/Icon'
import ProcessFieldHelper from '../businessLogic/processFieldHelper'
import WaitingDialog from '../components/Layout/WaitingDialog'
import DeleteMenuItem from '../components/Layout/DeleteMenuItem'
import { ingestSearchFromUrl } from '../components/Layout/Search/Selectors'
import ImpromptuTaskDialog from '../components/Process/ImpromptuTaskDialog'
import { MultiGrid, CellMeasurer, CellMeasurerCache, AutoSizer } from 'react-virtualized'
import FieldCell from './ProcessTable/FieldCell'

const cache = new CellMeasurerCache({
  defaultHeight: 50,
  minHeight: 50,
  fixedWidth: true
})

class ProcessInstancesGrid extends React.Component {
  constructor(props) {
    super(props)

    this.keyPressEvent = this.keyPressEvent.bind(this)

    this.state = {
      componentDidMountRanOnce: false,
      processInstances: [],
      instancesLoaded: false,
      template: {},
      templateFields: [],
      templateFieldsLoaded: false,
      sortDirection: 'asc',
      sortIndex: null,
      selectedColumn: null,
      selectedRow: null,
      showSelectTemplateDialog: (!props.params.templateId),
      templateSearchText: '',
      templates: [],
      templatesLoaded: false,
      showExportWaitingDialog: false,
      instancesForExport: [],
      forceDataRefresh: 0,
      impromptuTaskProcessInstanceId: null,
      menuAnchor: null,
      menuId: null,
      mainGridRef: null,
      scrollableGridRef: null,
      taskList: [],
      taskListLoaded: false
    }
  }

  componentWillMountMigrationToComponentDidMount() {
    // parse search to see if cancel date stuff is present, if not add it
    if (!this.props.location.query.search) {
      this.addNotCancelledFilter()
    } else {
      let { location: { query: { search = [] } } } = this.props

      if (!Array.isArray(search)) {
        search = [search]
      }

      const searchObjects = search.map((searchItem) => (JSON.parse(Base64.decode(searchItem))))

      let cancelObject = searchObjects.filter((item) => (item.property === 'CanceledDate_UTC'))

      if (!cancelObject.length) {
        this.addNotCancelledFilter()
      }
    }

    if (this.props.params.templateId) {
      this.fetchTemplateFields()
    } else {
      this.fetchTemplates()
    }

    this.updateTitle()

    this.disableGetDefaultPropsWarning()
    window.addEventListener('keydown', this.keyPressEvent)
  }

  componentDidMount() {

    if (this.state.componentDidMountRanOnce === false) {
      this.componentWillMountMigrationToComponentDidMount()
      this.setState({ componentDidMountRanOnce: true })
    }

    this.props.enableFullScreen()
  }

  addNotCancelledFilter() {
    let { location } = this.props

    const newSearchItem = Base64.encodeURI(JSON.stringify({
      property: 'CanceledDate_UTC',
      value: '',
      displayText: 'Canceled',
      displayOperator: 'Not',
      operator: '(ISEMPTY)'
    }))

    let newUrl = location.pathname

    if (location.search) {
      newUrl += `${location.search}&search=${newSearchItem}`
    } else {
      newUrl += `?search=${newSearchItem}`
    }

    this.props.replace(newUrl)
  }

  componentDidUpdate(prevProps, prevState) {
    this.updateTitle()

    if (prevProps.params.templateId !== this.props.params.templateId && this.props.params.templateId) {
      this.fetchTemplateFields()
      this.setState({ selectedRow: null, selectedColumn: null, templateFieldsLoaded: false, instancesLoaded: false })
    }

    if (prevProps.params.templateId && !this.props.params.templateId) {
      if (!this.state.templatesLoaded) {
        this.fetchTemplates()
      }

      this.setState({ showSelectTemplateDialog: true })
    }

    if (!prevState.mainGridRef && this.state.mainGridRef) {
      this.setState({ scrollableGridRef: ReactDOM.findDOMNode(this).getElementsByClassName('bottomRightGrid')[0] })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.keyPressEvent)
    this.props.disableFullScreen()
  }

  keyPressEvent(e) {
    const { selectedRow, selectedColumn, templateFields, processInstances } = this.state

    if (selectedRow !== null && selectedColumn !== null && e.key === 'Tab') {
      e.preventDefault()

      let newColumnIndex = selectedColumn
      let newRowIndex = selectedRow

      if (e.shiftKey) {
        if (selectedColumn === 0 && selectedRow - 1 >= 0) {
          newRowIndex--
          newColumnIndex = templateFields.length - 1
        } else if (selectedColumn === 0 && selectedRow === 0) {
          newColumnIndex = null
          newRowIndex = null
        } else {
          newColumnIndex--
        }
      } else {
        if (selectedColumn + 1 === templateFields.length && selectedRow + 1 < processInstances.length) {
          newRowIndex++
          newColumnIndex = 0
        } else if (selectedColumn + 1 === templateFields.length && selectedRow + 1 === processInstances.length) {
          newColumnIndex = null
          newRowIndex = null
        } else {
          newColumnIndex++
        }
      }

      this.setState({ selectedColumn: newColumnIndex, selectedRow: newRowIndex })
    }
  }

  disableGetDefaultPropsWarning = () => {
    const warning = 'Warning: getDefaultProps is only used on classic React.createClass definitions. Use a static property named `defaultProps` instead.'
    const consoleError = console.error.bind(console)

    console.error = (...args) => {
      if (args[0] === warning) return
      return consoleError(...args)
    }
  }

  updateTitle() {
    const { language } = this.context
    const { updateTitle } = this.props

    updateTitle(language.translate('application.processTable'))
  }

  sortTable(columnIndex) {
    let { sortDirection, sortIndex } = this.state

    if (sortDirection === 'asc' && sortIndex === columnIndex) {
      sortDirection = 'desc'
    } else {
      sortDirection = 'asc'
    }

    this.setState({ sortDirection, sortIndex: columnIndex })
  }

  fetchInstancesForExport(endpoint, limit = 500, iteration = 0) {
    const { get, location } = this.props

    let searchItems = ingestSearchFromUrl(location.query.search)

    let newEndpoint = `${endpoint}${(endpoint.includes('?')) ? '&' : '?'}`

    newEndpoint += `limit=${limit}&offset=${limit * iteration}`

    if (searchItems.length) {
      searchItems.map((filter, index) => {
        newEndpoint += '&'

        const filterIndex = index + 1
        const operator = filter.operator || '(CONTAINS_TEXT)'

        newEndpoint += `filter${filterIndex}=${filter.property}${operator}${filter.value}`
      })
    }

    get(newEndpoint, {
      onSuccess: (response) => {
        let instances = [...response.ProcessInstanceList, ...this.state.instancesForExport]

        this.setState({
          instancesForExport: instances
        }, () => {
          if (response.ProcessInstanceList.length === limit) {
            // recursively iterate this method until all results have been obtained
            this.fetchInstancesForExport(endpoint, limit, iteration + 1)
          } else {
            // once all results are obtained, parse the data into a csv file for download
            let headers = []
            let data = []

            this.state.templateFields.map((header) => { headers.push(header.FieldName) })

            this.state.instancesForExport.map((instance) => {
              let instanceValues = []

              instance.ProcessInstanceFieldList.map((field) => {
                instanceValues.push(field.Value)
              })

              data.push(instanceValues)
            })

            let csv = Papa.unparse({ fields: headers, data })
            let file = new window.Blob([csv], { type: 'text/csv' })

            this.setState({ showExportWaitingDialog: false })

            FileSaver.saveAs(file, `${this.state.template.Title}.csv`)
          }
        })
      }
    })
  }

  fetchTemplateFields() {
    const { params, get } = this.props

    get(`processtemplate/${params.templateId}/field/list/withoptions`, {
      onSuccess: (response) => {
        this.setState({
          templateFields: response.ProcessTemplateFieldList,
          template: response.ProcessTemplate,
          templateFieldsLoaded: true
        })
      }
    })
  }

  fetchTemplates() {
    const { get } = this.props

    get(`processtemplate/list`, {
      onSuccess: (response) => {
        this.setState({
          templates: response.ProcessTemplateList,
          templatesLoaded: true
        })
      }
    })
  }

  fetchFieldData(field, instanceId, callback) {
    const { get } = this.props
    const { template } = this.state
    let endpoint = (field.ID) ? `processinstance/${instanceId}/field/${field.ID}` : `processtemplate/${template.ID}/field/${field.ProcessTemplateFieldID}`

    get(endpoint, {
      onSuccess: (response) => {
        if (callback) {
          callback((field.ID) ? response.ProcessInstanceField : response.ProcessTemplateField)
        }
      }
    })
  }

  saveFieldData(field, instanceIndex, fieldIndex, newField = false) {
    const { selectedColumn, selectedRow, processInstances } = this.state
    const body = JSON.stringify({
      ProcessInstanceField: field
    })

    let endpoint = `processinstance/${processInstances[instanceIndex].ID}/field`

    if (field.ID && !newField) {
      endpoint += `/${field.ID}`
    }

    this.props.dispatch(post(endpoint, body, {
      onSuccess: (response) => {
        if (selectedColumn === fieldIndex && selectedRow === instanceIndex) {
          this.updateFieldInMaster(instanceIndex, fieldIndex, response.ProcessInstanceField)
        }
      }
    }))
  }

  dataLoadCallback(data) {
    // this is to address an issue with the API's pusher event, when even is triggered and update happens, the
    // API may not include all data because of a script that only runs every 15 seconds to prepare it
    // here we are checking to see if the data is missing and getting it another way if so
    if (this.state.templateFields.length) {
      data.map((instance, index) => {
        if (!instance.ProcessInstanceFieldList.length) {
          const { get } = this.props

          get(`processinstance/${instance.ID}/field/list`, {
            onSuccess: (response) => {
              instance.ProcessInstanceFieldList = response.ProcessInstanceFieldList

              this.setState({
                processInstances: data
              })
            }
          })
        }
      })
    }

    this.setState({
      processInstances: data,
      instancesLoaded: true
    })
  }

  prepareFilters() {
    const { templateFields } = this.state
    const { language } = this.context

    let filters = [{
      name: language.translate('application.allFields', [], true),
      property: 'HeaderFieldAll_Value'
    }]

    templateFields.map((field, fieldIndex) => {
      filters.push({
        name: field.FieldName,
        key: field.FieldName,
        property: `ProcessInstanceFieldList[${fieldIndex + 1}].value`
      })
    })

    return filters
  }

  updateFieldInMaster(instanceIndex, fieldIndex = null, field) {
    const processInstances = JSON.parse(JSON.stringify(this.state.processInstances))

    if (fieldIndex === null) {
      fieldIndex = this.findFieldIndex(instanceIndex, field)
    }

    processInstances[instanceIndex].ProcessInstanceFieldList[fieldIndex] = field

    this.setState({ processInstances })
  }

  findFieldIndex(instanceIndex, fieldToFind) {
    const { processInstances } = this.state
    let tempIndex = null

    processInstances[instanceIndex].ProcessInstanceFieldList.map((field, fieldIndex) => {
      if (field.ID === fieldToFind.ID) {
        tempIndex = fieldIndex
      }
    })

    return tempIndex
  }

  getTemplateMenu() {
    const { templates } = this.state
    const menu = []

    templates.map((template) => {
      menu.push(
        { key: template.ID, text: template.Title, value: template.Title }
      )
    })

    return menu
  }

  handleSwitchTemplates() {
    const { templatesLoaded } = this.state

    if (!templatesLoaded) {
      this.fetchTemplates()
    }

    this.setState({ showSelectTemplateDialog: true })
  }

  handleStartMultiple() {
    const { params: { templateId } } = this.props

    this.props.push({
      pathname: this.props.location.pathname,
      query: objectAssign({},
        this.props.location.query,
        {
          ptype: 'process_template_import',
          pid: templateId,
          ptsid: templateId
        })
    })
  }

  cancelProcess(instanceId) {
    const { post } = this.props

    const body = JSON.stringify({})

    post(`processinstance/${instanceId}/cancel`, body, {
      onSuccess: (response) => {
        this.setState({ forceDataRefresh: this.state.forceDataRefresh + 1 })
      }
    })
  }

  createImpromptuTask(task, afterTemplateTaskId, errorCallback) {
    const { post, showSnackbar, push, location } = this.props
    const { language } = this.context

    task.ProcessInstanceID = this.state.impromptuTaskProcessInstanceId

    const body = JSON.stringify({
      ProcessInstanceTask: task,
      POSTOptions: {
        ProcessTemplateTaskID: afterTemplateTaskId
      }
    })

    post(`processinstance/${task.ProcessInstanceID}/task`, body, {
      onSuccess: (response) => {
        this.setState({ impromptuTaskProcessInstanceId: null })
        showSnackbar({
          message: language.translate('application.impromptuTaskCreated'),
          action: language.translate('application.openThisTask'),
          actionEvent: () => {
            push({
              pathname: location.pathname,
              query: objectAssign(
                {},
                location.query,
                {
                  ptype: 'task',
                  task: response.ProcessInstanceTask.ID,
                  pid: response.ProcessInstanceTask.ID
                })
            })
          }
        })
      },
      onError: errorCallback
    })
  }

  getMenuCell(instance, instanceIndex, style) {
    const { language, muiTheme: { palette } } = this.context
    const { menuAnchor, menuId } = this.state
    const { location, push, user } = this.props

    return (<TableCell
      component='div'
      style={{
        ...style,
        textAlign: 'center',
        padding: '0px',
        verticalAlign: 'top',
        border: `1px solid ${palette.borderColor}`
      }}>
      <IconButton
        aria-label='More'
        aria-owns={(menuAnchor) ? 'menu' : null}
        aria-haspopup='true'
        onClick={(e) => {
          this.setState({
            menuAnchor: e.currentTarget,
            menuId: instance.ID
          })
        }}
      >
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor) && menuId === instance.ID}
        onClose={() => { this.setState({ menuAnchor: null }) }}
        getContentAnchorEl={null}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <MenuItem
          onClick={() => {
            this.setState({ menuAnchor: null })
            push(`/process-visual-progress/${instance.ID}`)
          }}
        >{language.translate('application.viewProgress')}</MenuItem>
        <MenuItem
          onClick={() => {
            this.setState({ menuAnchor: null })
            push(`/process-history/${instance.ID}`)
          }}
        >{language.translate('application.processHistory')}</MenuItem>
        <MenuItem
          onClick={() => {
            this.setState({ menuAnchor: null })
            push(`/relationships/${instance.ProcessTemplateID}/${instance.ID}`)
          }}
        >{language.translate('application.relationships')}</MenuItem>
        <MenuItem
          onClick={() => {
            this.setState({ menuAnchor: null, selectedRow: null, selectedColumn: null })
            const query = objectAssign({}, location.query, {
              ptype: 'task',
              task: instance.OldestPendingTask_ID,
              pid: instance.OldestPendingTask_ID
            })

            this.props.push({
              pathname: location.pathname,
              query
            })
          }}
        >{language.translate('application.oldestPending')}</MenuItem>
        <MenuItem
          onClick={() => {
            this.getTemplateTasks(instance.ProcessTemplateID)
            this.setState({
              impromptuTaskProcessInstanceId: instance.ID,
              menuAnchor: null,
              selectedRow: null,
              selectedColumn: null
            })
          }}
        >{language.translate('application.addImpromptuTask')}</MenuItem>
        {(!instance.CanceledDate_Local)
          ? <DeleteMenuItem
            onDelete={() => {
              this.setState({ menuAnchor: null, selectedRow: null, selectedColumn: null })
              this.cancelProcess(instance.ID)
            }}
            deleteLabel={language.translate('application.cancelThisProcess')}
          />
          : null}
      </Menu>
      <Subscriber
        channelName={'Private-' + user.accountID + '-ProcessInstance-' + instance.ID}
        events={['InstanceFieldUpdated']}
        callback={(data) => {
          this.updateFieldInMaster(instanceIndex, null, data.ProcessInstanceField)
        }} />
    </TableCell>)
  }

  getProgressCell(instance, style) {
    const { muiTheme: { palette } } = this.context
    const { push } = this.props

    return (<TableCell
      component='div'
      style={{
        ...style,
        textAlign: 'center',
        padding: '5px',
        verticalAlign: 'top',
        border: `1px solid ${palette.borderColor}`
      }}>
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => {
          push(`/process-visual-progress/${instance.ID}`)
        }
        }>
        <div>{`${instance.Tasks_ProgressPercentage}%`}</div>
        <div
          style={{
            position: 'inherit',
            width: '100%',
            display: 'block',
            height: '10px',
            backgroundColor: 'rgb(224,224,224)',
            borderRadius: '2px',
            margin: 'auto',
            overflow: 'hidden',
            transition: 'inherit'
          }}
        >
          <div
            style={{
              height: '100%',
              backgroundColor: palette.primary1Color,
              width: `${instance.Tasks_ProgressPercentage}%`
            }}
          />
        </div>
      </div>
    </TableCell>)
  }

  getTemplateTasks(templateId) {
    this.props.get(`processtemplate/${templateId}/task/list`, {
      onSuccess: (response) => {
        this.setState({
          taskList: response.ProcessTemplateTaskList,
          taskListLoaded: true
        })
      }
    })
  }

  render() {
    const { language, muiTheme: { palette } } = this.context
    const { push, user, params, dispatch, screenHeight, apiServer, location } = this.props
    const {
      processInstances, instancesLoaded, templateFields, templateFieldsLoaded, template, impromptuTaskProcessInstanceId,
      sortDirection, sortIndex, selectedRow, selectedColumn, scrollableGridRef, mainGridRef,
      showSelectTemplateDialog, templateSearchText, showExportWaitingDialog, forceDataRefresh, taskList, taskListLoaded
    } = this.state

    let endpoint = `processtemplate/${params.templateId}/instance/list`

    if (sortIndex !== null) {
      endpoint += `?orderby=ProcessInstanceFieldList[${sortIndex + 1}].value ${sortDirection}`
    } else {
      endpoint += `?orderby=Tasks_ProgressPercentage ASC,HeaderField1_Value`
    }

    const templateOptions = this.getTemplateMenu()

    const styles = {
      headerCell: {
        border: `1px solid ${palette.borderColor}`,
        fontSize: '16px',
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
        color: palette.accent3Color
      }
    }

    return (
      <div>
        {(params.templateId)
          ? <Card
            style={{ padding: '0 10px 10px' }}
          >
            {(!templateFieldsLoaded)
              ? <CircularProgress className='loader' />
              : <div>
                <CardHeader
                  title={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {(template.IconName)
                        ? <Icon icon={template.IconName} size={40} />
                        : <GeneratedIcon
                          text={template.Title}
                          randomizer={template.ID}
                        />}
                      <div style={{ marginLeft: '10px' }}>{template.Title}</div>
                      <Tooltip title={language.translate('application.switchTemplates')}>
                        <IconButton
                          onClick={() => this.handleSwitchTemplates()}
                        >
                          <ImportExport style={{ transform: 'rotate(90deg)' }} />
                        </IconButton>
                      </Tooltip>
                    </div>}
                  style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                  action={
                    <div style={{ display: 'flex' }}>
                      <Tooltip title={language.translate('application.export')}>
                        <IconButton
                          onClick={() => {
                            this.setState({
                              showExportWaitingDialog: true,
                              instancesForExport: []
                            }, () => { this.fetchInstancesForExport(endpoint) })
                          }}
                        >
                          <ImportExport nativeColor={palette.accent6Color} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={language.translate('application.relationships')}>
                        <IconButton
                          onClick={() => push(`/relationships/${template.ID}`)}
                        >
                          <Share nativeColor={palette.accent6Color} style={{ transform: 'rotate(90deg)' }} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={language.translate('application.viewTemplate')}>
                        <IconButton
                          onClick={() => push(`/process-template/${template.ID}`)}
                        >
                          <CallSplit nativeColor={palette.accent6Color} style={{ transform: 'rotate(90deg)' }} />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }
                />
                <div
                  style={{ height: `${screenHeight - 200}px` }}>
                  <DataList
                    url={endpoint}
                    dataCallback={this.dataLoadCallback.bind(this)}
                    responseProperty='ProcessInstanceList'
                    filterableColumns={this.prepareFilters()}
                    noDataText={language.translate('application.noProcesses')}
                    disableMasonry
                    location={location}
                    containerElement={scrollableGridRef}
                    forceDataRefresh={forceDataRefresh}
                    channelName={`Private-${this.props.user.accountID}-ProcessTemplate-${params.templateId}`}
                    events={['InstanceCreated']}
                    containerStyle={{ height: '100%' }}
                  >
                    {(instancesLoaded && templateFieldsLoaded)
                      ? <Form style={{ height: '100%' }}>

                        <AutoSizer>
                          {({ width, height }) => (
                            <Table
                              style={{ height: height - 45, width }}
                              component='div'
                            >
                              <MultiGrid
                                columnCount={templateFields.length + 2}
                                fixedColumnCount={0}
                                estimatedColumnSize={250}
                                fixedRowCount={1}
                                columnWidth={({ index }) => {
                                  if (index === 0) {
                                    return 60
                                  }

                                  if (index === 1) {
                                    return 100
                                  }

                                  return 250
                                }}
                                deferredMeasurementCache={cache}
                                ref={ref => {
                                  if (!mainGridRef && ref) {
                                    this.setState({ mainGridRef: ref })
                                  }
                                }}
                                height={height - 45}
                                overscanColumnCount={4}
                                overscanRowCount={4}
                                scrollToColumn={this.state.selectedColumn && this.state.selectedColumn + 2}
                                scrollToRow={this.state.selectedRow && this.state.selectedRow + 1}
                                styleBottomRightGrid={{ outline: 'none' }}
                                classNameBottomRightGrid='bottomRightGrid'
                                cellRenderer={({ columnIndex, key, parent, rowIndex, style }) => {
                                  let instance = processInstances[rowIndex - 1]

                                  let fieldIndex = columnIndex - 2

                                  return (<CellMeasurer
                                    cache={cache}
                                    columnIndex={columnIndex}
                                    key={key}
                                    parent={parent}
                                    rowIndex={rowIndex}
                                  >
                                    {({ measure }) => {
                                      let instanceIndex = rowIndex - 1

                                      if (columnIndex === 0) {
                                        if (rowIndex === 0) {
                                          return (<TableCell
                                            component='div'
                                            style={{
                                              ...style,
                                              textAlign: 'center',
                                              paddingRight: '20px',
                                              ...styles.headerCell
                                            }}
                                          />)
                                        } else {
                                          return (this.getMenuCell(instance, instanceIndex, style))
                                        }
                                      }

                                      if (columnIndex === 1) {
                                        if (rowIndex === 0) {
                                          return (<TableCell
                                            component='div'
                                            style={{
                                              ...style,
                                              textAlign: 'center',
                                              paddingLeft: '35px',
                                              ...styles.headerCell
                                            }}
                                          >
                                            <Tooltip
                                              title={language.translate('application.percentCompleted')}
                                            >
                                              <Done nativeColor='rgb(158, 158, 158)' />
                                            </Tooltip>
                                          </TableCell>)
                                        } else {
                                          return (this.getProgressCell(instance, style))
                                        }
                                      }

                                      if (rowIndex === 0) {
                                        let header = templateFields[fieldIndex]

                                        return (<TableCell
                                          component='div'
                                          style={{
                                            ...style,
                                            cursor: 'pointer',
                                            ...styles.headerCell
                                          }}
                                          onMouseUp={() => (this.sortTable(fieldIndex))}
                                        >
                                          <div
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              flexDirection: 'row',
                                              width: '200px'
                                            }}>
                                            <div style={{ width: '90%' }}>
                                              <div
                                                style={{
                                                  whiteSpace: 'nowrap',
                                                  textOverflow: 'ellipsis',
                                                  overflow: 'hidden',
                                                  display: 'block'
                                                }}
                                              >
                                                {header.FieldName}
                                              </div>
                                              <Typography
                                                variant='caption'
                                                style={{ display: (header.ReadOnlyField) ? 'inherit' : 'none' }}
                                              >
                                                {` (${language.translate('application.readOnly')})`}
                                              </Typography>
                                            </div>
                                            {(fieldIndex === sortIndex)
                                              ? <IconButton>
                                                <ArrowDownward
                                                  nativeColor={palette.accent3Color}
                                                  style={(sortDirection === 'desc') ? { transform: 'rotate(180deg)' } : {}} />
                                              </IconButton>
                                              : null}
                                          </div>
                                        </TableCell>)
                                      }

                                      if (!instance.ProcessInstanceFieldList[fieldIndex] || instance.ProcessInstanceFieldList[fieldIndex].ProcessTemplateFieldID !== templateFields[fieldIndex].ID) {
                                        return null
                                      }

                                      let field = instance.ProcessInstanceFieldList[fieldIndex]

                                      return (
                                        <FieldCell
                                          field={field}
                                          style={style}
                                          processInstanceFieldList={instance.ProcessInstanceFieldList}
                                          onSizeChange={() => { measure() }}
                                          dispatch={dispatch}
                                          fetchFieldData={(field, callback) => { this.fetchFieldData(field, instance.ID, callback) }}
                                          onUpdate={(field) => {
                                            const newField = (!field.ProcessInstanceID)

                                            this.saveFieldData(field, instanceIndex, fieldIndex, newField)
                                            this.updateFieldInMaster(instanceIndex, fieldIndex, field)
                                          }}
                                          templateId={template.ID}
                                          apiServer={apiServer}
                                          isSelected={(selectedColumn === fieldIndex && selectedRow === instanceIndex)}
                                          onClick={() => {
                                            if (selectedColumn === fieldIndex && selectedRow === instanceIndex) {
                                              return
                                            }

                                            this.setState({
                                              selectedColumn: fieldIndex,
                                              selectedRow: instanceIndex
                                            })
                                          }}
                                        />
                                      )
                                    }}
                                  </CellMeasurer>)
                                }}
                                rowCount={processInstances.length + 1}
                                rowHeight={cache.rowHeight}
                                width={width}
                              />
                            </Table>
                          )}
                        </AutoSizer>
                      </Form>
                      : null
                    }
                  </DataList>
                </div>
                <AddIcon
                  menuItems={[
                    {
                      text: language.translate('application.startProcess'),
                      onClick: () => {
                        let { location: { query: { search = [] }, query } } = this.props

                        if (!Array.isArray(search)) {
                          search = [search]
                        }

                        let nextQuery = {
                          ptype: 'process_template',
                          pid: template.ID,
                          ptsid: template.ID
                        }

                        // if filtering by instance lookup, apply that value to the field in start process view
                        search.map((searchItem) => {
                          let searchObject = JSON.parse(Base64.decode(searchItem))

                          if (searchObject.property && searchObject.property.startsWith('ProcessInstanceFieldList')) {
                            let templateFieldId = searchObject.property.split('[')[1].split(']')[0]

                            templateFields.map((templateField) => {
                              if (templateField.ID === templateFieldId && ProcessFieldHelper.isInstanceLookupField(templateField.FieldType)) {
                                nextQuery[`fld${templateField.ID}`] = searchObject.displayValue
                              }
                            })
                          }
                        })

                        if (['pifields', 'task'].includes(query.ptype)) {
                          nextQuery.returnTo = Base64.encodeURI(JSON.stringify(query))
                        }

                        push({
                          pathname: location.pathname,
                          query: objectAssign({},
                            location.query,
                            nextQuery)
                        })
                      }
                    }, {
                      text: language.translate('application.importFromCsvFile'),
                      onClick: this.handleStartMultiple.bind(this)
                    }
                  ]}
                />
              </div>}
          </Card>
          : null}
        {(impromptuTaskProcessInstanceId && taskListLoaded)
          ? <ImpromptuTaskDialog
            onCancel={() => this.setState({ impromptuTaskProcessInstanceId: null })}
            onSubmit={this.createImpromptuTask.bind(this)}
            dispatch={this.props.dispatch}
            taskList={taskList}
          />
          : null}
        <Dialog
          PaperProps={{ style: { width: '350px' } }}
          open={showSelectTemplateDialog}
          onClose={() => this.setState({ showSelectTemplateDialog: false })}
        >
          <DialogTitle style={{ backgroundColor: palette.headerBackgroundColor }} disableTypography>
            <Typography
              variant='h6'
              style={{ color: palette.alternateTextColor }}>{language.translate('application.selectTemplate')}</Typography>
          </DialogTitle>
          <DialogContent>
            <Form>
              <FormsyAutoComplete
                fullWidth
                name='template'
                floatingLabelText={language.translate('application.selectTemplate')}
                hintText={language.translate('application.search')}
                dataSource={templateOptions}
                searchText={templateSearchText}
                onNewRequest={(option) => {
                  if (option.key) {
                    this.setState({ templateSearchText: '', showSelectTemplateDialog: false })
                    push(`/process-instances-grid/${option.key}`)
                  } else {
                    this.setState({ templateSearchText: '' })
                  }
                }}
                onUpdateInput={(text) => {
                  this.setState({ templateSearchText: text })
                }}
                value={params.templateId}
                filter={AutoComplete.caseInsensitiveFilter}
                openOnFocus
                maxSearchResults={10}
                required
                validationErrors={language.messages.validationErrors}
                popoverProps={{ style: { width: 'auto', minWidth: '300' } }}
              />
            </Form>
          </DialogContent>
        </Dialog>
        {(showExportWaitingDialog)
          ? <WaitingDialog
            messages={[
              language.translate('application.warmingUpProcessors'),
              language.translate('application.collectingData'),
              language.translate('application.preparingTpsReports'),
              language.translate('application.lastTimeMonkeyDidntSurvive'),
              language.translate('application.itsStillFasterThanAddingThemManually'),
              language.translate('application.goAheadHoldYourBreath')
            ]}
            pauseTime={5000}
          />
          : null}
      </div>
    )
  }
}

ProcessInstancesGrid.propTypes = {
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.string,
  get: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired,
  updateTitle: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  enableFullScreen: PropTypes.func.isRequired,
  disableFullScreen: PropTypes.func.isRequired,
  mainContentWidth: PropTypes.number.isRequired,
  screenHeight: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  apiServer: PropTypes.string,
  location: PropTypes.object
}

ProcessInstancesGrid.contextTypes = {
  muiTheme: PropTypes.object,
  language: PropTypes.object
}

const mapStateToProps = (state, ownProps) => ({
  title: state.application.title,
  mainContentWidth: state.application.mainContentWidth,
  screenHeight: state.application.screenHeight,
  user: state.auth,
  apiServer: state.application.apiServer,
  location: ownProps.location
})

const mapDispatchToProps = dispatch => ({
  get: bindActionCreators(get, dispatch),
  post: bindActionCreators(post, dispatch),
  push: bindActionCreators(push, dispatch),
  replace: bindActionCreators(replace, dispatch),
  showSnackbar: bindActionCreators(showSnackbar, dispatch),
  updateTitle: bindActionCreators(updateTitle, dispatch),
  enableFullScreen: bindActionCreators(enableFullScreen, dispatch),
  disableFullScreen: bindActionCreators(disableFullScreen, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ProcessInstancesGrid)
