import PropTypes from 'prop-types'
import React from 'react'
import { Paper } from '@material-ui/core'
import moment from 'moment'
import Autolinker from 'autolinker'
import { darken, fade } from '@material-ui/core/styles/colorManipulator'

class NotificationItem extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      hovered: false
    }
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { leftAvatar, date, subject, body, onClick, hasViewed } = this.props
    const { hovered } = this.state
    const paperStyle = {}

    if (!hasViewed) {
      paperStyle.boxShadow = 'none'
      paperStyle.backgroundColor = fade(palette.primary1Color, 0.05)
      paperStyle.borderBottom = `1px solid ${palette.borderColor}`
    }

    if (hovered) { paperStyle.backgroundColor = (!hasViewed) ? fade(palette.primary1Color, 0.1) : darken(palette.canvasColor, 0.05) }

    return (
      <Paper
        onClick={onClick}
        style={paperStyle}
        className='clickable'
        onMouseEnter={() => this.setState({ hovered: true })}
        onMouseLeave={() => this.setState({ hovered: false })}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {(leftAvatar)
            ? <div style={{ marginLeft: '10px' }}>{leftAvatar}</div>
            : null}
          <div style={{ marginLeft: '10px', padding: '10px' }}>
            <div
              style={{ color: palette.accent3Color, fontSize: '13px' }}
            >
              {moment(date).fromNow()}
            </div>
            <div
              style={{ color: palette.textColor, fontSize: '14px' }}
            >
              {subject}
            </div>
            <div>
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  color: palette.accent6Color
                }}
                dangerouslySetInnerHTML={{ __html: Autolinker.link(body) }}
              />
            </div>
          </div>
        </div>
      </Paper>
    )
  }
}

NotificationItem.propTypes = {
  leftAvatar: PropTypes.object,
  subject: PropTypes.node,
  onClick: PropTypes.func,
  date: PropTypes.string,
  body: PropTypes.node,
  hasViewed: PropTypes.bool
}

NotificationItem.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object,
  location: PropTypes.object
}

export default NotificationItem
