import { Base64 } from 'js-base64'

export function ingestSearchFromUrl (search = [], withDisplayOnly = false) {
  let searchItems = []

  if (!Array.isArray(search)) {
    search = [search]
  }

  if (search && search.length) {
    search.map(searchItem => {
      const searchItemObject = JSON.parse(Base64.decode(searchItem))

      if (!searchItemObject.displayOnly || withDisplayOnly) {
        searchItems.push(searchItemObject)
      }
    })
  }
  return searchItems
}
