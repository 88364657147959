import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Copyright extends Component {
  render () {
    const { language } = this.context

    return (
      <div style={{ marginTop: '40px', fontSize: '10px' }}>
        <div>{language.translate('application.copyright')} © 2014-{new Date().getFullYear()} ProcessPlan,
          LLC. {language.translate('application.allRightsReserved')}.
          <a
            href='https://processplan.com/privacy-policy'
            target='_blank'
            style={{ color: '#606060', margin: '0 5px' }}
          >
            {language.translate('application.privacyPolicy')}
          </a>
          <a href='https://processplan.com/terms-of-service' target='_blank' style={{ color: '#606060' }}>
            {language.translate('application.termsOfService')}
          </a></div>
      </div>
    )
  }
}

Copyright.propTypes = {}

Copyright.contextTypes = {
  language: PropTypes.object
}

export default Copyright
