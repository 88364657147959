import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'react-router-redux'
import { setNotifications, addNotification } from '../../actions/notifications'
import { logoutAndRedirect, setAccountData, setUserData } from '../../actions/authentication'
import { get } from '../../actions/base'
import objectAssign from 'object-assign'
import NotificationSystem from 'react-notification-system'
import Template from './Notifications/Template'
import Subscriber from './Notifications/Subscriber'
import {
  Notifications as NotificationsIcon, NotificationsNone, ChatBubbleOutline, ChatBubble
} from '@material-ui/icons'
import NotificationIcon from '../Notifications/NotificationIcon'

class Notifications extends React.Component {
  constructor (props, context) {
    super(props, context)

    this._notificationSystem = null
  }

  getApplicationUpdateElement () {
    let { language } = this.context

    return (
      <Template
        title={language.translate('application.newUpdate')}
        message={language.translate('application.newUpdateMessage')}
        action={() => window.location.reload(true)}
      />
    )
  }

  getTaskAssignmentElement (taskInstance) {
    let { push, location } = this.props
    let { language } = this.context

    const query = objectAssign({}, location.query, {
      ptype: 'task', pid: taskInstance.ID, task: taskInstance.ID
    })

    return (
      <Template
        title={language.translate('application.newTaskMessage')}
        message={taskInstance.ProcessTemplateTitle + ': ' + taskInstance.TaskText_Display}
        action={() => push({
          pathname: window.location.pathname,
          query
        })}
      />
    )
  }

  getMemoElement (memo) {
    let { push } = this.props
    let { user } = this.context
    let path = window.location.pathname
    let query = { ptype: 'memos' }
    let { language } = this.context

    if (memo.ToProcessInstanceID) {
      path = '/process-visual-progress/' + memo.ToProcessInstanceID
      query.memoType = 'instance'
      query.pid = memo.ToProcessInstanceID
    } else if (memo.ToProcessTemplateID) {
      path = '/process-template/' + memo.ToProcessTemplateID
      query.memoType = 'template'
      query.pid = memo.ToProcessTemplateID
    } else {
      query.memoType = 'user'
      query.pid = user.userid
    }

    return (
      <Template
        title={language.translate('application.newMessage')}
        message={memo.Subject + ': ' + memo.Body}
        action={() => push({
          pathname: path,
          query
        })}
      />
    )
  }

  emitNotification (component) {
    if (this._notificationSystem) {
      this._notificationSystem.addNotification({
        position: 'bl',
        autoDismiss: 10,
        level: 'info',
        children: component
      })
    }
  }

  userProfileUpdatedCallback (payload) {
    const { get, setUserData } = this.props

    get(`user/${payload.User.ID}`, {
      onSuccess: (response) => {
        setUserData(response.User)
      }
    })
  }

  accountUpdatedCallback (payload) {
    const { get, setAccountData } = this.props

    get(`account`, {
      onSuccess: (response) => {
        setAccountData(response.Account)
      }
    })
  }

  applicationUpdateCallback = () => {
    this.emitNotification(this.getApplicationUpdateElement())
  }

  memoCallback = (payload) => {
    this.emitNotification(this.getMemoElement(payload.Memo))
  }

  logoutUser (data) {
    if (data.User.SessionToken === this.context.user.token) {
      this.props.logoutAndRedirect()
    }
  }

  render () {
    let {
      unviewedNotificationCount, showNotifications, showUserMessages,
      showIcons, unviewedMessageCount, push
    } = this.props
    let { muiTheme, user, language } = this.context

    return (
      <div>
        {(showIcons)
          ? <div>
            <NotificationIcon
              onClick={showNotifications}
              count={unviewedNotificationCount}
              icon={
                (!unviewedNotificationCount)
                  ? <NotificationsNone nativeColor={muiTheme.palette.alternateTextColor} />
                  : <NotificationsIcon nativeColor={muiTheme.palette.alternateTextColor} />
              }
              tooltip={language.translate('application.notifications')}
            />
            <NotificationIcon
              onClick={showUserMessages}
              count={unviewedMessageCount}
              icon={
                (!unviewedMessageCount)
                  ? <ChatBubbleOutline nativeColor={muiTheme.palette.alternateTextColor} />
                  : <ChatBubble nativeColor={muiTheme.palette.alternateTextColor} />
              }
              tooltip={language.translate('application.messages')}
            />
          </div>
          : null
        }
        <NotificationSystem
          ref={n => { this._notificationSystem = n }}
          style={{
            NotificationItem: {
              DefaultStyle: {
                padding: '0px',
                height: 'inherit'
              }
            }
          }}
        />
        <Subscriber
          channelName={'Private-' + user.accountID + '-User-' + user.userid}
          events={['InstanceTaskAssigned']}
          callback={(payload) => { this.emitNotification(this.getTaskAssignmentElement(payload.ProcessInstanceTask)) }} />
        <Subscriber
          channelName={'Application'}
          events={['application_update']}
          callback={this.applicationUpdateCallback} />
        <Subscriber
          channelName={'Private-' + user.accountID + '-Account-' + user.accountID}
          events={['AccountUpdated']}
          callback={(data) => {
            this.accountUpdatedCallback(data)
          }} />
        <Subscriber
          channelName={'Private-' + user.accountID + '-User-' + user.userid}
          events={['UserUpdated']}
          callback={(data) => {
            this.userProfileUpdatedCallback(data)
          }} />
        <Subscriber
          channelName={'Private-' + user.accountID + '-User-' + user.userid}
          events={['MemoCreated']}
          callback={this.memoCallback} />
        <Subscriber
          channelName={'Private-' + user.accountID + '-User-' + user.userid}
          events={['SessionTimeout']}
          callback={(data) => this.logoutUser(data)} />
        <Subscriber
          channelName={'Private-' + user.accountID + '-User-' + user.userid}
          events={['NotificationCreated']}
          callback={(data) => {
            const { Notification } = data

            if (Notification.NotificationType === 'ProcessTemplateImported') {
              this.emitNotification(<Template
                title={Notification.Subject}
                message={Notification.Body}
                action={() => {
                  const pushTo = new window.URL(Notification.TargetURL)
                  push(pushTo.pathname)
                }}
              />)
            }
          }} />
      </div>
    )
  }
}

Notifications.propTypes = {
  pusher: PropTypes.object,
  setNotifications: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
  notifications: PropTypes.array,
  unviewedNotificationCount: PropTypes.number,
  unviewedMessageCount: PropTypes.number,
  showNotifications: PropTypes.func,
  showUserMessages: PropTypes.func,
  push: PropTypes.func.isRequired,
  setAccountData: PropTypes.func,
  setUserData: PropTypes.func,
  logoutAndRedirect: PropTypes.func,
  get: PropTypes.func,
  location: PropTypes.object,
  showIcons: PropTypes.bool
}

Notifications.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object,
  user: PropTypes.object,
  location: PropTypes.object,
  router: PropTypes.object
}

const mapStateToProps = (state) => ({
  pusher: state.pusher.pusher,
  notifications: state.notification.notifications
})

const mapDispatchToProps = (dispatch) => ({
  setNotifications: bindActionCreators(setNotifications, dispatch),
  addNotification: bindActionCreators(addNotification, dispatch),
  push: bindActionCreators(push, dispatch),
  setAccountData: bindActionCreators(setAccountData, dispatch),
  setUserData: bindActionCreators(setUserData, dispatch),
  get: bindActionCreators(get, dispatch),
  logoutAndRedirect: bindActionCreators(logoutAndRedirect, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)
