import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, IconButton, Menu, MenuItem, TableRow, TableCell } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import moment from 'moment'
import FormHelper from '~/businessLogic/formHelper'
import { Icon, GeneratedIcon } from '../Icon'
import DeleteMenuItem from '../Layout/DeleteMenuItem'

class ProcessInfoTableRow extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      menuAnchor: null
    }
  }

  getPriorityColor (dueDate) {
    const difference = (dueDate) ? moment(dueDate).diff(moment(), 'days') : null
    const palette = this.context.muiTheme.palette

    switch (true) {
      case (difference === null):
        return palette.primary3Color
      case (difference > 2):
        return palette.successColor
      case (difference >= 0 && difference <= 2):
        return palette.warningColor
      case (difference < 0):
        return palette.errorColor
      default:
        return palette.primary3Color
    }
  }

  getProcessHeaderFields () {
    const { process } = this.props
    const items = []

    for (let i = 1; i <= process.NumberOfHeaderFieldsToDisplay; i++) {
      if (process[`HeaderField${i}_Name`]) {
        items.push(
          <div key={i} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
            {
              `${FormHelper.decodeHTML(process[`HeaderField${i}_Name`])}: ${
                FormHelper.decodeHTML(process[`HeaderField${i}_Value`])}`
            }
          </div>)
      }
    }

    return (
      <div style={{ display: 'grid' }}>
        {
          items.map(item => item)
        }
      </div>
    )
  }

  render () {
    const {
      process, onProcessClick, showLastProgress, showWaitingOn, viewProgressClick,
      cancelProcessClick, viewRelationshipsClick, oldestPendingTaskClick, addImpromptuTaskClick, ...rest
    } = this.props
    const { menuAnchor } = this.state
    const { muiTheme: { palette }, language } = this.context
    const headerFields = this.getProcessHeaderFields()

    return (
      <TableRow
        component='div'
        hover
        style={{ margin: '10px' }}
        className='clickable'
        onClick={onProcessClick}
        {...rest}
      >
        <TableCell
          component='div'
          style={{
            width: '50px',
            textAlign: 'center',
            padding: '5px 0px 5px 5px',
            verticalAlign: 'top',
            borderRight: `1px solid ${palette.borderColor}`,
            borderLeft: `7px solid ${this.getPriorityColor(process.TargetDate_Local)}`
          }}>
          <div onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}>
            <IconButton
              className='menu'
              style={{ marginRight: '10px' }}
              onClick={(e) => { this.setState({ menuAnchor: e.currentTarget }) }}
            >
              <MoreVert />
            </IconButton>
            <Menu
              anchorEl={menuAnchor}
              open={Boolean(menuAnchor)}
              onClose={() => { this.setState({ menuAnchor: null }) }}
              getContentAnchorEl={null}
              anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
              transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            >
              <MenuItem
                onClick={() => {
                  this.setState({ menuAnchor: null })
                  viewProgressClick()
                }}
              >{language.translate('application.viewProgress')}</MenuItem>
              <MenuItem
                onClick={() => {
                  this.setState({ menuAnchor: null })
                  viewRelationshipsClick()
                }}
              >{language.translate('application.relationships')}</MenuItem>
              <MenuItem
                onClick={() => {
                  this.setState({ menuAnchor: null })
                  oldestPendingTaskClick()
                }}
              >{language.translate('application.oldestPending')}</MenuItem>
              <MenuItem
                onClick={() => {
                  this.setState({ menuAnchor: null })
                  addImpromptuTaskClick()
                }}
              >{language.translate('application.addImpromptuTask')}</MenuItem>
              {(!process.CanceledDate_Local)
                ? <DeleteMenuItem
                  onDelete={() => {
                    this.setState({ menuAnchor: null })
                    cancelProcessClick()
                  }}
                  deleteLabel={language.translate('application.cancelThisProcess')}
                />
                : null}
            </Menu>
          </div>
        </TableCell>
        <TableCell style={{ paddingLeft: '15px', width: '40%', paddingRight: '20px' }} component='div'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: '10px' }}>
              {(process.ProcessTemplateIconName)
                ? <Icon icon={process.ProcessTemplateIconName} size={50} />
                : <GeneratedIcon
                  style={{ height: '50px', width: '50px' }}
                  text={process.ProcessTemplateTitle}
                  randomizer={process.ProcessTemplateID}
                />}
            </div>
            <div>
              <div style={{ fontWeight: 700 }}>
                {process.ProcessTemplateTitle}
              </div>
              <div>{headerFields}</div>
            </div>
          </div>
        </TableCell>
        {(showWaitingOn)
          ? <TableCell
            component='div'
            style={{ textAlign: 'center', width: '30%', paddingRight: '20px' }}
          >{(process.OldestPendingTask_AssignedTo_Name)
            ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Avatar
                src={process.OldestPendingTask_AssignedTo_ProfilePicURL}
                style={{ margin: '5px', height: '50px', width: '50px' }}
              />
              <div style={{ padding: '0 5px', textAlign: 'left' }}>
                {process.OldestPendingTask_AssignedTo_Name}<br />
                {moment(process.OldestPendingTask_CreatedDate_Local).format('ddd, MMM Do, YYYY')}<br />
                {moment(process.OldestPendingTask_CreatedDate_Local).fromNow(true)}
              </div>
            </div>
            : ''}</TableCell>
          : null}
        {(showLastProgress)
          ? <TableCell style={{ textAlign: 'center', width: '30%', paddingRight: '20px' }} onClick={onProcessClick} component='div'>
            {process.LastCompletedTask_CompletedUser_FullName}<br />
            {(process.LastCompletedTask_CompletedDate_Local) ? moment(process.LastCompletedTask_CompletedDate_Local).format('ddd, MMM Do, YYYY') : ''}<br />
            {(process.LastCompletedTask_CompletedDate_Local) ? moment(process.LastCompletedTask_CompletedDate_Local).fromNow() : ''}
          </TableCell>
          : null}
        <TableCell style={{ textAlign: 'center', paddingTop: '5px', paddingBottom: '5px' }} component='div'>
          <div>{`${process.Tasks_ProgressPercentage}%`}</div>
          <div
            style={{
              position: 'inherit',
              width: '60px',
              display: 'block',
              height: '10px',
              backgroundColor: 'rgb(224,224,224)',
              borderRadius: '2px',
              margin: 'auto',
              overflow: 'hidden',
              transition: 'inherit'
            }}
          >
            <div
              style={{
                height: '100%',
                backgroundColor: palette.primary1Color,
                width: `${process.Tasks_ProgressPercentage}%`
              }}
            />
          </div>
        </TableCell>
      </TableRow>
    )
  }
}

ProcessInfoTableRow.propTypes = {
  process: PropTypes.object.isRequired,
  onProcessClick: PropTypes.func,
  viewProgressClick: PropTypes.func,
  cancelProcessClick: PropTypes.func,
  viewRelationshipsClick: PropTypes.func,
  oldestPendingTaskClick: PropTypes.func,
  addImpromptuTaskClick: PropTypes.func,
  showLastProgress: PropTypes.bool,
  showWaitingOn: PropTypes.bool
}

ProcessInfoTableRow.contextTypes = {
  muiTheme: PropTypes.object,
  language: PropTypes.object
}

export default ProcessInfoTableRow
