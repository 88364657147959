import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get } from '../../actions/base'
import {
  Card, CardContent, CardHeader, CircularProgress, Table, TableBody, TableCell, TableRow
} from '@material-ui/core'
import Masonry from 'react-masonry-component'
import BarCard from './BarCard'

const masonryOptions = {
  transitionDuration: '0.0s'
}

class UserBacklog extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      userGroupId: props.userGroupId,
      userGroup: {},
      userGroupLoaded: false,
      estimatedMinutes: [],
      estimatedMinutesLoaded: false,
      historicalMinutes: [],
      historicalMinutesLoaded: false
    }
  }

  componentDidMount () {
    this.fetchData()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.userGroupId && this.props.userGroupId !== prevProps.userGroupId) {
      this.setState({ userGroupId: this.props.userGroupId }, this.fetchData)
    }

    if (this.props.endDate !== prevProps.endDate ||
      this.props.startDate !== prevProps.startDate ||
      this.props.by !== prevProps.by ||
      this.props.time !== prevProps.time) {
      this.fetchData()
    }
  }

  fetchData () {
    const { by, time, startDate, endDate, userGroupId } = this.props
    const dateFilter = this.props.parseDateFilter(startDate, endDate)

    if (!userGroupId) {
      return
    }

    this.fetchUserData('', 'userGroup', 'UserGroup')
    this.fetchUserData(`/report/tasks/pending/estimatedminutes/byuser`, 'estimatedMinutes')
    this.fetchUserData(`/report/tasks/pending/historicalminutes/byuser`, 'historicalMinutes')
  }

  fetchUserData (endpoint, stateAttribute, responseAttribute = 'ReportSeriesList') {
    const { userGroupId } = this.props

    this.setState({ [`${stateAttribute}Loaded`]: false })

    this.props.get(`usergroup/${userGroupId}${endpoint}`, {
      onSuccess: (response) => {
        this.setState({
          [stateAttribute]: response[responseAttribute],
          [`${stateAttribute}Loaded`]: true
        })
      }
    })
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const {
      userGroup, userGroupLoaded, estimatedMinutes, estimatedMinutesLoaded, historicalMinutes,
      historicalMinutesLoaded
    } = this.state
    const { mainContentWidth, handleUserClick, time } = this.props

    const styles = {
      cardStyles: {
        width: (mainContentWidth < 800) ? '95%' : '45%',
        margin: '10px'
      },
      processInfoTableRow: {
        border: 'none',
        height: '20px'
      },
      processInfoLabel: {
        textAlign: 'right',
        width: '180px',
        height: 'inherit',
        padding: '0px'
      },
      processInfoData: {
        height: 'inherit'
      }
    }

    return (
      <div>
        <Card style={styles.cardStyles}>
          {(userGroupLoaded)
            ? <CardHeader
              title={`${language.translate('application.userGroup')}: ${userGroup.GroupName}`}
            />
            : <CircularProgress className='loader' />}
        </Card>
        <Masonry options={masonryOptions}>
          <BarCard
            title={language.translate('application.basedOnEstimatedMinutes')}
            subtitle={language.translate('application.basedOnEstimatedMinutesSubtitle')}
            data={estimatedMinutes}
            isDataLoaded={estimatedMinutesLoaded}
            onClick={handleUserClick}
            tooltipPrefix={language.translate('application.minute')}
            mainContentWidth={mainContentWidth}
          />

          <BarCard
            title={language.translate('application.basedOnHistoricalMinutes')}
            subtitle={language.translate('application.basedOnHistoricalMinutesSubtitle')}
            data={historicalMinutes}
            isDataLoaded={historicalMinutesLoaded}
            onClick={handleUserClick}
            tooltipPrefix={language.translate('application.minute')}
            mainContentWidth={mainContentWidth}
          />
        </Masonry>
      </div>
    )
  }
}

UserBacklog.propTypes = {
  userId: PropTypes.string,
  get: PropTypes.func.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  by: PropTypes.string,
  time: PropTypes.string,
  parseDateFilter: PropTypes.func.isRequired,
  mainContentWidth: PropTypes.number,
  handleUserClick: PropTypes.func.isRequired
}

UserBacklog.contextTypes = {
  muiTheme: PropTypes.object,
  language: PropTypes.object
}

const mapStateToProps = state => ({
  mainContentWidth: state.application.mainContentWidth
})

const mapDispatchToProps = dispatch => ({
  get: bindActionCreators(get, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(UserBacklog)
