import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import { updateTitle } from '../actions/application'
import { post } from '../actions/base'
import DataList from '../components/Layout/DataList'
import { Card, Divider, List, ListItem, ListItemText } from '@material-ui/core'
import { push } from 'react-router-redux'
import objectAssign from 'object-assign'
import FormHelper from '../businessLogic/formHelper'
import { GeneratedIcon, Icon } from '../components/Icon'

class ScheduledProcesses extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      items: [],
      componentDidMountRanOnce: false
    }
  }

  componentWillMountMigrationToComponentDidMount() {
    const { push, location } = this.props

    if (!location.query.type) {
      push({
        pathname: location.pathname,
        query: { type: 'active' }
      })
    }
  }

  componentDidMount() {
    if (this.state.componentDidMountRanOnce === false) {
      this.componentWillMountMigrationToComponentDidMount()
      this.setState({ componentDidMountRanOnce: true })
    }
    this.updateTitle()
  }

  componentDidUpdate(prevProps) {
    const { push } = this.props

    this.updateTitle()

    if (this.props.location.query.endpoint !== prevProps.location.query.endpoint) { this.setState({ items: [] }) }

    if (!this.props.location.query.type) {
      push({
        pathname: this.props.location.pathname,
        query: { type: 'active' }
      })
    }
  }

  updateTitle() {
    const { language } = this.context
    const { location } = this.props

    if (location.query.type) { this.props.dispatch(updateTitle(`${language.translate('application.scheduledProcesses')} - ${location.query.type.ucFirst()}`)) }
  }

  dataLoadCallback(data) {
    this.setState({
      items: data
    })
  }

  filterColumns() {
    const { language } = this.context

    return [{
      name: language.translate('application.processTitle'),
      property: 'ProcessTemplateTitle'
    }, {
      name: language.translate('application.processField', [], true),
      property: 'HeaderFieldAll_Value'
    }, {
      name: language.translate('application.oldestPendingTaskOwner'),
      property: 'OldestPendingTask_AssignedTo_Name'
    }]
  }

  pushToRecurring(instanceId, templateId) {
    const { push, location } = this.props

    const queryAttributes = {
      ptype: 'process_template',
      pid: templateId,
      recurring: true
    }

    if (instanceId) { queryAttributes.piid = instanceId }

    push({
      pathname: location.pathname,
      query: objectAssign({},
        location.query,
        queryAttributes
      )
    })
  }

  render() {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const { items } = this.state
    const { user, location, location: { query } } = this.props

    if (!query.type) { return null }

    return (
      <DataList
        url={`processinstance/list/scheduled/${query.type}`}
        dataCallback={this.dataLoadCallback.bind(this)}
        responseProperty='ProcessInstanceList'
        filterableColumns={this.filterColumns()}
        noDataText={language.translate('application.noScheduledProcesses')}
        channelName={`Private-${user.accountID}-ScheduledInstance-0`}
        events={['InstanceCanceled', 'InstanceCreated', 'InstanceUpdated']}
        location={location}
      >
        <Card
          style={{ padding: '0px', marginBottom: '10px', width: '100%' }}
        >
          <List style={{ paddingTop: '0px', paddingBottom: '0px' }}>
            {items.map((item, index) => (
              React.Children.toArray([
                <ListItem
                  button
                  selected={location.query.piid === item.ID}
                  onClick={() => {
                    const templateId = items.filter(item => item.ID === item.ID)[0].ProcessTemplateID

                    this.pushToRecurring(item.ID, templateId)
                  }}
                  key={item.ID}
                >
                  {
                    (item.ProcessTemplateIconName)
                      ? <Icon icon={item.ProcessTemplateIconName} size={40} />
                      : <GeneratedIcon
                        text={item.ProcessTemplateTitle}
                        randomizer={item.ProcessTemplateID}
                        style={{ marginTop: '5px' }}
                      />
                  }
                  <div>
                    <div style={{ fontSize: '12px', color: palette.accent3Color, display: 'flex', paddingLeft: '16px' }}>
                      <div>
                        {item.Recurring_RepeatType.splitOnUppercase() || language.translate('application.noRepeatType')}
                      </div>
                      {(item.Recurring_NextDate_Local)
                        ? <div style={{ marginLeft: '3px' }}>
                          {`- ${language.translate('application.nextOccurrence')}: ${moment(item.Recurring_NextDate_Local).format(user.dateFormat)
                            } ${language.translate('application.at').lcFirst()} ${moment(item.Recurring_NextDate_Local).format('hh:mm a')}`}
                        </div>
                        : language.translate('application.noMoreOccurrences')}
                    </div>
                    <ListItemText
                      primary={item.ProcessTemplateTitle}
                      secondary={(item.HeaderField1_Name)
                        ? `${FormHelper.decodeHTML(item.HeaderField1_Name)
                        }: ${
                        FormHelper.decodeHTML(item.HeaderField1_Value)}` : null}
                    />
                  </div>
                </ListItem>,
                (index < items.length - 1)
                  ? <Divider key={index} /> : null
              ])
            ))}
          </List>
        </Card>
      </DataList>
    )
  }
}

ScheduledProcesses.propTypes = {
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.string,
  push: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  mainContentWidth: PropTypes.number.isRequired,
  location: PropTypes.object,
  post: PropTypes.func
}

ScheduledProcesses.contextTypes = {
  muiTheme: PropTypes.object,
  language: PropTypes.object
}

const mapStateToProps = (state, ownProps) => ({
  title: state.application.title,
  mainContentWidth: state.application.mainContentWidth,
  user: state.auth,
  location: ownProps.location
})

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  post: bindActionCreators(post, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledProcesses)
