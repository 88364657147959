import PropTypes from 'prop-types'
import React from 'react'
import update from 'react-addons-update'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import objectAssign from 'object-assign'
import { get, post } from '~/actions/base'
import { push } from 'react-router-redux'
import {
  Avatar, Button, Card, Collapse, Divider, List, ListItem, ListItemSecondaryAction, ListItemText, Switch
} from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { showUndoMessage } from '~/actions/snackbar'
import TemplateTaskChecklist from './TemplateTaskChecklist'
import TemplateTaskAdvancedSettings from './TemplateTaskAdvancedSettings'
import TemplateTaskStartDueDateSettings from './TemplateTaskStartDueDateSettings'
import ProcessFieldsSelectList from './ProcessFieldSelectList'
import TemplateTaskResourceList from './TemplateTaskResourceList'
import TaskAssignmentDialog from './TaskAssignmentDialog'
import TemplateTaskTags from './TemplateTaskTags'
import FormHelper from '~/businessLogic/formHelper'
import RightPanelContent from '../Layout/RightPanelContent'
import TemplateTaskSettings from './TemplateTaskSettings'

class TemplateTaskSettingsPanel extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      isLoaded: false,
      template: null,
      templateTask: {},
      checklistLoaded: false,
      checklist: null,
      templateFields: null,
      templateFieldsLoaded: false,
      taskAssignedToDialogOpen: false,
      checklistRequirementOptionsLoaded: false,
      checklistRequirementOptions: null,
      tokens: [],
      tokensLoaded: false,
      tagListLoaded: false,
      tagList: [],
      settingsExpanded: false,
      advancedSettingsExpanded: false,
      startDueDateSettingsExpanded: false,
      checklistExpanded: false,
      resourcesExpanded: false,
      tagsExpanded: false,
      viewingTaskFieldsExpanded: false,
      completingTaskFieldsExpanded: false,
      readOnlyOverrideFieldsExpanded: false
    }
  }

  // MIGRATED TO componentDidMount
  // componentWillMount () {
  //   this.fetchAllData()
  // }

  componentDidMount() {
    if (this.state.isLoaded === false) {
      this.fetchAllData()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.templateTaskId !== this.props.templateTaskId) {
      this.fetchAllData()
    }
  }

  fetchAllData() {
    this.fetchProcessTemplateTaskSettings()
    this.fetchChecklistRequirementOptions()
    this.fetchAccountTags()
  }

  fetchProcessTemplateTaskSettings() {
    const { templateTaskId } = this.props

    this.props.get(`processtemplatetask/${templateTaskId}`, {
      onSuccess: (response) => {
        this.setState({
          isLoaded: true,
          templateTask: response.ProcessTemplateTask,
          template: response.ProcessTemplate
        })

        this.fetchChecklist()
        this.fetchTemplateFields()
        this.fetchTemplateTaskResources()
        this.fetchFieldTokens()
      }
    })
  }

  fetchTemplateFields() {
    const { templateTask } = this.state
    this.props.get(`processtemplate/${this.state.template.ID}/field/list`, {
      onSuccess: (response) => {
        let templateFields = []

        if (!templateTask.ProcessTemplateFieldID_DisplayOrder.length) {
          templateFields = response.ProcessTemplateFieldList
        } else {
          let tempArray = response.ProcessTemplateFieldList

          templateTask.ProcessTemplateFieldID_DisplayOrder.map((fieldId) => {
            let foundFieldIndex = tempArray.findIndex((field, index) => (field.ID === fieldId))

            if (foundFieldIndex > -1) {
              templateFields.push(tempArray[foundFieldIndex])
              tempArray.splice(foundFieldIndex, 1)
            }
          })

          templateFields = templateFields.concat(tempArray)
        }
        this.setState({
          templateFieldsLoaded: true,
          templateFields: templateFields.filter(Boolean)
        })
      }
    })
  }

  fetchFieldTokens() {
    this.props.get(`processtemplate/${this.state.template.ID}/fieldtoken/list`, {
      onSuccess: (response) => {
        const tokens = response.TextValueList

        this.setState({
          tokens,
          tokensLoaded: true
        })
      }
    })
  }

  fetchTemplateTaskResources() {
    this.props.get(
      `processtemplate/${this.state.template.ID}/task/${this.state.templateTask.ID}/resource/list`,
      {
        onSuccess: (response) => {
          this.setState({
            templateTaskResourcesLoaded: true,
            templateTaskResources: response.ProcessTemplateTaskResourceList
          })
        }
      }
    )
  }

  saveAdvancedSettings(settings) {
    const body = JSON.stringify({
      ProcessTemplateTask: settings
    })

    this.props.post(
      `processtemplate/${this.state.template.ID}/task/${this.state.templateTask.ID}`,
      body,
      {
        onSuccess: (response) => {
          this.setState({
            templateTask: response.ProcessTemplateTask
          })
        }
      }
    )
  }

  fetchChecklist() {
    this.props.get(
      `processtemplate/${this.state.template.ID}/task/${this.state.templateTask.ID}/checklistitem/list`,
      {
        onSuccess: (response) => {
          this.setState({
            checklistLoaded: true,
            checklist: response.ProcessTemplateTaskChecklistItemList
          })
        }
      }
    )
  }

  fetchChecklistRequirementOptions() {
    this.props.get('processtemplate/task/checklist/requirementoption/list', {
      onSuccess: (response) => {
        this.setState({
          checklistRequirementOptionsLoaded: true,
          checklistRequirementOptions: response.TextValueList
        })
      }
    })
  }

  fetchAccountTags() {
    this.props.get('tag/list', {
      onSuccess: (response) => {
        this.setState({
          tagListLoaded: true,
          tagList: response.TagList
        })
      }
    })
  }

  addTag(tagId) {
    const { templateTask, template } = this.state
    const body = JSON.stringify({})

    this.props.post(`processtemplate/${template.ID}/task/${templateTask.ID}/tag/${tagId}/apply`, body, {
      onSuccess: (response) => {
        templateTask.TagList = response.TagList
        this.setState({ templateTask })
      }
    })
  }

  deleteTag(tagId) {
    const { templateTask, template } = this.state
    const body = JSON.stringify({})

    this.props.post(`processtemplate/${template.ID}/task/${templateTask.ID}/tag/${tagId}/delete`, body, {
      onSuccess: (response) => {
        templateTask.TagList = response.TagList
        this.setState({ templateTask })
      }
    })
  }

  saveChecklist(list) {
    const body = JSON.stringify({
      ProcessTemplateTaskChecklistItemList: list
    })

    this.props.post(
      `processtemplate/${this.state.template.ID}/task/${this.state.templateTask.ID}/checklistitem/list`,
      body,
      {
        onSuccess: (response) => {
          this.setState({
            checklist: response.ProcessTemplateTaskChecklistItemList
          })
        }
      }
    )
  }

  addNewChecklistItem() {
    const { templateTask, checklist } = this.state

    // get highest DisplayOrder property from checklist items
    const highestDisplayOrder = checklist.reduce((max, checkItem) => (checkItem.DisplayOrder > max) ? checkItem.DisplayOrder : max, (checklist.length) ? checklist[0].DisplayOrder : 0)

    const newItem = {
      ItemDescription: '',
      ProcessTemplateID: templateTask.ProcessTemplateID,
      ProcessTemplateTaskID: templateTask.ID,
      DisplayOrder: highestDisplayOrder + 1
    }

    const newChecklist = checklist.concat(newItem)

    this.setState({ checklist: newChecklist })
  }

  reorderChecklist(fromIndex, toIndex) {
    const { checklist } = this.state
    const dragField = checklist[fromIndex]

    this.setState(update(this.state, {
      checklist: {
        $splice: [
          [fromIndex, 1],
          [toIndex, 0, dragField]
        ]
      }
    }))
  }

  saveChecklistItem(item, index) {
    const { checklist, template, templateTask } = this.state

    const body = JSON.stringify({
      ProcessTemplateTaskChecklistItem: item
    })

    const endpoint = (item.ID) ? `/${item.ID}` : ''

    this.props.post(
      `processtemplate/${template.ID}/task/${templateTask.ID}/checklistitem${endpoint}`,
      body,
      {
        onSuccess: (response) => {
          checklist[index] = response.ProcessTemplateTaskChecklistItem

          this.setState({
            checklist
          })
        }
      }
    )
  }

  deleteChecklistItem(item) {
    const { language } = this.context

    const body = JSON.stringify({})

    this.props.post(
      `processtemplate/${this.state.template.ID}/task/${this.state.templateTask.ID}/checklistitem/${item.ID}/delete`,
      body,
      {
        onSuccess: (response) => {
          this.fetchChecklist()

          this.props.showUndoMessage(
            language.translate('application.checklistItemRemoved'),
            () => {
              this.props.post(
                `processtemplate/${this.state.template.ID}/task/${this.state.templateTask.ID}/checklistitem/${item.ID}/delete/undo`,
                body,
                {
                  onSuccess: (response) => {
                    this.fetchChecklist()
                  }
                }
              )
            }
          )
        }
      }
    )
  }

  saveResourceList(list) {
    const body = JSON.stringify({
      ProcessTemplateTaskResourceList: list
    })

    this.props.post(
      `processtemplate/${this.state.template.ID}/task/${this.state.templateTask.ID}/resource/list`,
      body,
      {
        onSuccess: (response) => {
          this.setState({
            templateTaskResources: response.ProcessTemplateTaskResourceList
          })
        }
      }
    )
  }

  saveResourceItem(index, resource) {
    const { templateTaskResources } = this.state

    const body = JSON.stringify({
      ProcessTemplateTaskResource: resource
    })

    const endpoint = (resource.ID) ? `/${resource.ID}` : ''

    this.props.post(
      `processtemplate/${this.state.template.ID}/task/${this.state.templateTask.ID}/resource${endpoint}`,
      body,
      {
        onSuccess: (response) => {
          templateTaskResources[index] = response.ProcessTemplateTaskResource

          this.setState({
            templateTaskResources
          })
        },
        onError: (error) => {
          this.fetchTemplateTaskResources()
        }
      }
    )
  }

  deleteResourceListItem(item) {
    const body = JSON.stringify({})

    this.props.post(
      `processtemplate/${this.state.template.ID}/task/${this.state.templateTask.ID}/resource/${item.ID}/delete`,
      body,
      {
        onSuccess: (response) => {
          this.fetchTemplateTaskResources()

          /* this.props.showUndoMessage(
            'Resource removed',
            () => {
            this.props.post('processtemplate/' + this.state.template.ID + '/task/' + this.state.templateTask.ID + '/resource/' + item.ID + '/delete/undo')
            .then(response => {
            this.fetchTemplateTaskResources();
            })
            .catch(error => {
            });
            }
            ); */
        }
      }
    )
  }

  reorderFields(fromIndex, toIndex) {
    const templateFields = this.state.templateFields
    const dragField = templateFields[fromIndex]

    this.setState(update(this.state, {
      templateFields: {
        $splice: [
          [fromIndex, 1],
          [toIndex, 0, dragField]
        ]
      }
    }))
  }

  updateDisplayProperties() {
    const { templateFields, templateTask } = this.state
    let newOrder = []

    templateFields.map((item, index) => {
      newOrder.push(item.ID)
    })

    templateTask.ProcessTemplateFieldID_DisplayOrder = newOrder
    this.saveAdvancedSettings(templateTask)
  }

  render() {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const {
      template, templateTask, templateFields, templateFieldsLoaded, checklistLoaded, checklist,
      templateTaskResources, templateTaskResourcesLoaded, isLoaded, checklistRequirementOptionsLoaded,
      checklistRequirementOptions, tokens, tokensLoaded, tagListLoaded, tagList, tagSearchText, settingsExpanded,
      advancedSettingsExpanded, startDueDateSettingsExpanded, checklistExpanded, resourcesExpanded, tagsExpanded,
      viewingTaskFieldsExpanded, completingTaskFieldsExpanded, readOnlyOverrideFieldsExpanded
    } = this.state
    const { push, user } = this.props

    let taskColor = palette.accent1Color

    if (isLoaded && templateTask.TagList) {
      const coloredTag = templateTask.TagList.find((tag) => !!(tag.BackgroundColor))
      if (coloredTag) {
        taskColor = coloredTag.BackgroundColor
      }
    }

    return (
      <RightPanelContent
        leftToolbarAvatar={
          (isLoaded)
            ? <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '5px' }}>
              <Avatar
                className='clickable'
                src={templateTask.Default_AssignedTo_ProfilePicURL}
                style={{ height: '40px', width: '40px', float: 'left' }}
                onClick={() => this.setState({ taskAssignedToDialogOpen: true })}
              />
              <div style={{
                fontSize: '10px',
                color: palette.accent2Color,
                float: 'left',
                position: 'absolute',
                backgroundColor: palette.disabledColor,
                top: '35px',
                whiteSpace: 'nowrap'
              }}
              >{language.translate('application.assignedTo')}
              </div>
            </div>
            : null
        }
        title={(isLoaded) ? FormHelper.decodeHTML(templateTask.TaskText) : null}
        closePanel={() => this.props.close()}
      >
        <Divider style={{ height: '10px', backgroundColor: taskColor }} />
        {(isLoaded && templateFieldsLoaded, tokensLoaded)
          ? <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '15px' }}>
            <Card className='card' style={{ width: '100%' }}>
              <List style={{ padding: '0px' }}>
                <ListItem
                  button
                  onClick={() => { this.setState({ settingsExpanded: !settingsExpanded }) }}
                >
                  <ListItemText primary={language.translate('application.settings')} />
                  {settingsExpanded ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={settingsExpanded} timeout='auto' unmountOnExit>
                  {(isLoaded)
                    ? <TemplateTaskSettings
                      key='settings'
                      task={templateTask}
                      onSave={this.saveAdvancedSettings.bind(this)}
                      tokens={tokens}
                    />
                    : null}
                </Collapse>
                <Divider />

                <ListItem
                  button
                  onClick={() => { this.setState({ advancedSettingsExpanded: !advancedSettingsExpanded }) }}
                >
                  <ListItemText primary={language.translate('application.advancedSettings')} />
                  {advancedSettingsExpanded ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={advancedSettingsExpanded} timeout='auto' unmountOnExit>
                  {(isLoaded)
                    ? <TemplateTaskAdvancedSettings
                      key='advanced-settings'
                      task={templateTask}
                      onSave={this.saveAdvancedSettings.bind(this)}
                      timeFormat={user.timeTrackingUnit}
                    />
                    : null}
                </Collapse>
                <Divider />
                <ListItem
                  button
                  onClick={() => { this.setState({ startDueDateSettingsExpanded: !startDueDateSettingsExpanded }) }}
                >
                  <ListItemText primary={language.translate('application.startAndDueDateSettings')} />
                  {startDueDateSettingsExpanded ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={startDueDateSettingsExpanded} timeout='auto' unmountOnExit>
                  {(isLoaded)
                    ? <TemplateTaskStartDueDateSettings
                      key='date-settings'
                      task={templateTask}
                      onSave={this.saveAdvancedSettings.bind(this)}
                    />
                    : null}
                </Collapse>
                <Divider />
                <ListItem
                  button
                  onClick={() => { this.setState({ checklistExpanded: !checklistExpanded }) }}
                >
                  <ListItemText primary={language.translate('application.checklist')} />
                  {checklistExpanded ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={checklistExpanded} timeout='auto' unmountOnExit>
                  {(checklistLoaded && checklistRequirementOptionsLoaded)
                    ? <TemplateTaskChecklist
                      key='checklist'
                      checklist={checklist}
                      checklistRequirementOptions={checklistRequirementOptions}
                      task={templateTask}
                      onSaveTask={this.saveAdvancedSettings.bind(this)}
                      onSaveList={this.saveChecklist.bind(this)}
                      onSaveItem={this.saveChecklistItem.bind(this)}
                      onDelete={this.deleteChecklistItem.bind(this)}
                      addNewItem={this.addNewChecklistItem.bind(this)}
                      reorderChecklist={this.reorderChecklist.bind(this)}
                    />
                    : null}
                </Collapse>
                <Divider />
                <ListItem
                  button
                  onClick={() => { this.setState({ resourcesExpanded: !resourcesExpanded }) }}
                >
                  <ListItemText primary={language.translate('application.resources')} />
                  {resourcesExpanded ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={resourcesExpanded} timeout='auto' unmountOnExit>
                  {(templateTaskResourcesLoaded)
                    ? <TemplateTaskResourceList
                      key='resource-list'
                      resources={templateTaskResources}
                      taskId={templateTask.ID}
                      templateId={template.ID}
                      onSaveList={this.saveResourceList.bind(this)}
                      onSaveResource={this.saveResourceItem.bind(this)}
                      onDelete={this.deleteResourceListItem.bind(this)}
                    />
                    : null}
                </Collapse>
                <Divider />
                <ListItem
                  button
                  onClick={() => { this.setState({ tagsExpanded: !tagsExpanded }) }}
                >
                  <ListItemText primary={language.translate('application.tags')} />
                  {tagsExpanded ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={tagsExpanded} timeout='auto' unmountOnExit>
                  {(isLoaded && tagListLoaded)
                    ? <TemplateTaskTags
                      key='task-tags'
                      taskTagList={templateTask.TagList}
                      tagList={tagList}
                      addTag={this.addTag.bind(this)}
                      removeTag={this.deleteTag.bind(this)}
                      manageTags={() => {
                        push({
                          pathname: this.props.location.pathname,
                          query: objectAssign({}, this.props.location.query, { ptype: 'atags' })
                        })
                      }}
                    />
                    : null}
                </Collapse>
                <Divider />
                <ListItem
                  button
                  onClick={() => { this.setState({ viewingTaskFieldsExpanded: !viewingTaskFieldsExpanded }) }}
                >
                  <ListItemText primary={language.translate('application.fieldsToDisplayWhenViewingTask')} />
                  {viewingTaskFieldsExpanded ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={viewingTaskFieldsExpanded} timeout='auto' unmountOnExit>
                  <List style={{ backgroundColor: palette.accent2Color, padding: '0px 20px' }}>
                    <ListItem
                      style={{ borderBottom: `1px solid ${palette.borderColor}` }}>
                      <ListItemText primary={language.translate('application.onlyDisplayFieldsWithValues')} />
                      <ListItemSecondaryAction>
                        <Switch
                          color='primary'
                          checked={templateTask.OnlyDisplayFieldsWithValues}
                          onChange={(obj, value) => {
                            templateTask.OnlyDisplayFieldsWithValues = !templateTask.OnlyDisplayFieldsWithValues
                            this.saveAdvancedSettings(templateTask)
                          }}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                  {(isLoaded && templateFieldsLoaded)
                    ? <ProcessFieldsSelectList
                      key='view-fields'
                      toggledFields={templateTask.ProcessTemplateFieldID_ToDisplay}
                      onSave={(requiredList) => {
                        templateTask.ProcessTemplateFieldID_ToDisplay = requiredList
                        this.saveAdvancedSettings(templateTask)
                      }}
                      onReorderDrag={this.reorderFields.bind(this)}
                      onReorderDrop={this.updateDisplayProperties.bind(this)}
                      templateFields={templateFields}
                    />
                    : null}
                </Collapse>
                <Divider />
                <ListItem
                  button
                  onClick={() => { this.setState({ completingTaskFieldsExpanded: !completingTaskFieldsExpanded }) }}
                >
                  <ListItemText primary={language.translate('application.fieldsRequiredWhenCompletingTask')} />
                  {completingTaskFieldsExpanded ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={completingTaskFieldsExpanded} timeout='auto' unmountOnExit>
                  {(isLoaded && templateFieldsLoaded)
                    ? <ProcessFieldsSelectList
                      key='required-fields'
                      toggledFields={templateTask.ProcessTemplateFieldID_Required}
                      onSave={(requiredList) => {
                        templateTask.ProcessTemplateFieldID_Required = requiredList
                        this.saveAdvancedSettings(templateTask)
                      }}
                      templateFields={templateFields}
                    />
                    : null}
                </Collapse>
                <Divider />
                <ListItem
                  button
                  onClick={() => { this.setState({ readOnlyOverrideFieldsExpanded: !readOnlyOverrideFieldsExpanded }) }}
                >
                  <ListItemText primary={language.translate('application.readOnlyFieldOverride')} />
                  {readOnlyOverrideFieldsExpanded ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={readOnlyOverrideFieldsExpanded} timeout='auto' unmountOnExit>
                  {(isLoaded && templateFieldsLoaded)
                    ? <ProcessFieldsSelectList
                      key='override-fields'
                      toggledFields={templateTask.ProcessTemplateFieldID_ReadOnlyOverride}
                      onSave={(requiredList) => {
                        templateTask.ProcessTemplateFieldID_ReadOnlyOverride = requiredList
                        this.saveAdvancedSettings(templateTask)
                      }}
                      templateFields={templateFields}
                    />
                    : null}
                </Collapse>
              </List>
              <TaskAssignmentDialog
                open={this.state.taskAssignedToDialogOpen}
                close={() => this.setState({ taskAssignedToDialogOpen: false })}
                templateId={template.ID}
                userId={templateTask.Default_AssignedTo_UserID}
                groupId={templateTask.Default_AssignedTo_GroupID}
                dynamicUserId={templateTask.Default_AssignedTo_TemplateFieldID || null}
                dynamicTaskId={templateTask.Default_AssignedTo_TemplateTaskID || null}
                onSubmit={(groupId, userId, dynamicId, dynamicProperty) => {
                  templateTask.Default_AssignedTo_GroupID = groupId
                  templateTask.Default_AssignedTo_UserID = userId

                  if (dynamicId) { templateTask[dynamicProperty] = dynamicId }

                  this.saveAdvancedSettings(templateTask)
                  this.setState({ taskAssignedToDialogOpen: false })
                }}
              />
            </Card>
            <Button
              color='primary'
              onClick={() => {
                push({
                  pathname: '/statistics',
                  query: {
                    type: 'task',
                    taskId: templateTask.ID,
                    templateId: template.ID,
                    by: 'month',
                    time: 'days'
                  }
                })
              }}
              variant='contained'
            >
              {language.translate('application.taskStatistics')}
            </Button>
          </div>
          : null}
      </RightPanelContent>
    )
  }
}

TemplateTaskSettingsPanel.propTypes = {
  templateTaskId: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  showUndoMessage: PropTypes.func.isRequired,
  location: PropTypes.object,
  user: PropTypes.object.isRequired
}

TemplateTaskSettingsPanel.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

const mapStateToProps = state => ({
  user: state.auth
})

const mapDispatchToProps = dispatch => ({
  dispatch,
  post: bindActionCreators(post, dispatch),
  get: bindActionCreators(get, dispatch),
  push: bindActionCreators(push, dispatch),
  showUndoMessage: bindActionCreators(showUndoMessage, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(TemplateTaskSettingsPanel)
