import PropTypes from 'prop-types'
import React from 'react'
import { FormsyText, FormsySelect } from 'formsy-material-ui'
import {
  Button, Checkbox, Divider, FormControlLabel, IconButton, ListItem, ListItemSecondaryAction, ListItemText, Menu,
  MenuItem, TextField
} from '@material-ui/core'
import { Input, MoreVert, ExpandLess, Add, KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons'
import { MenuItem as OldMenuItem } from 'material-ui'
import FormHelper from '../../businessLogic/formHelper'
import ReorderableListItem from '../Layout/ReorderableListItem'
import ReorderableList from '../Layout/ReorderableList'
import { Form } from 'formsy-react'
import FormsyUpload from '../Forms/FormsyUpload'
import CustomFieldItemOption from './CustomFieldItemOption'
import { apiServer } from '../../config'
import DefaultVisibilityList from '../ProcessTemplate/DefaultVisibilityList'
import DeleteMenuItem from '../Layout/DeleteMenuItem'
import FormsyTemplateAutoComplete from '../Forms/FormsyTemplateAutoComplete'
import FieldTokenList from '../ProcessTemplate/FieldTokenList'
import CustomFieldInstanceFilterList from './CustomFieldInstanceFilterList'

class CustomFieldItem extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      canSubmit: true,
      newOption: '',
      showFilterList: false,
      showVisibilityList: false,
      visibilityList: [],
      visibilityListLoaded: false,
      tokenFieldProperty: '',
      processFields: [],
      processFieldsLoaded: false,
      menuAnchor: null
    }
  }

  componentDidUpdate (prevProps, prevState) {
    let instanceTypes = ['INSTANCE', 'INSTCHLS', 'INSTLINK']

    if (!prevProps.editMode && this.props.editMode && instanceTypes.includes(this.props.field.FieldType)) {
      this.fetchProcessFields()
    }

    if (!instanceTypes.includes(prevProps.field.FieldType) && instanceTypes.includes(this.props.field.FieldType) && this.props.editMode) {
      this.fetchProcessFields()
    }

    if (!prevState.tokenFieldProperty && this.state.tokenFieldProperty && this.props.getFieldTokens) {
      this.props.getFieldTokens()
    }
  }

  fetchProcessFields () {
    const { getTemplateFields, field } = this.props

    if (!field.ProcessInstanceLookupTemplateID || !getTemplateFields) {
      return false
    }

    getTemplateFields(field.ProcessInstanceLookupTemplateID, (response) => {
      let processFields = response.ProcessTemplateFieldList

      processFields.map((processField) => {
        processField.filterProperty = `ProcessInstanceFieldList[${processField.ID}].value`
        return processField
      })

      this.setState({
        processFields: response.ProcessTemplateFieldList,
        processFieldsLoaded: true
      })
    })
  }

  enableButton () {
    this.setState({ canSubmit: true })
  }

  disableButton () {
    this.setState({ canSubmit: false })
  }

  fieldTypeOptions () {
    const { customFieldTypes } = this.props
    const menu = []
    const style = { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }

    customFieldTypes.map((field) => {
      menu.push(<OldMenuItem
        innerDivStyle={style}
        value={field.Identifier}
        key={field.Identifier}
        primaryText={field.Description}
      />)
    })

    return menu
  }

  userGroupOptions () {
    const { language } = this.context
    const options = []

    let userGroups = this.props.userGroups.filter((group) => (!group.IsOnDemand))
    let odsGroups = this.props.userGroups.filter((group) => (group.IsOnDemand))
    let odsGroupsGrouped = odsGroups.reduce((acc, group) => {
      const odsCompanyName = group.AccountID
      acc[odsCompanyName] = acc[odsCompanyName] || []
      acc[odsCompanyName].push(group)
      return acc
    }, {})

    userGroups.map((group) => {
      options.push(<OldMenuItem value={group.ID} key={group.ID} primaryText={group.GroupName} />)
    })

    if (odsGroups.length) {
      Object.keys(odsGroupsGrouped).sort().map((groupId, index) => {
        let groups = odsGroupsGrouped[groupId]

        options.push(
          <OldMenuItem
            style={{ color: 'rgba(0,0,0,0.6)' }}
            disabled
            value={'ODS'}
            key={`ODS-${groupId}`}
            primaryText={`-- ${language.translate('application.servicesBy')} ${groups[0].AccountName} --`} />)

        groups.map((group) => {
          options.push(<OldMenuItem value={group.ID} key={group.ID} primaryText={group.GroupName} />)
        })
      })
    }

    return options
  }

  handleFieldNameChange (event) {
    this.props.onChange('FieldName', event.currentTarget.value)
  }

  handleFieldTypeChange (event, key) {
    this.props.onChange('FieldType', key)
  }

  handleUserGroupChange (event, key) {
    this.props.onChange('UserGroupID', key)
  }

  handleInstanceLookupTemplateChange (event, key) {
    this.props.onChange('ProcessInstanceLookupTemplateID', key)
  }

  handleExternalDataSourceChange (event, key) {
    this.props.onChange('ExternalDataSourceID', key)
  }

  handleRequiredCheckboxChange (event, isChecked) {
    this.props.onChange('RequiredField', isChecked)
  }

  handleSecureCheckboxChange (event, isChecked) {
    this.props.onChange('SecureField', isChecked)
  }

  handleUniqueCheckboxChange (event, isChecked) {
    this.props.onChange('UniqueField', isChecked)
  }

  handleReadOnlyCheckboxChange (event, isChecked) {
    this.props.onChange('ReadOnlyField', isChecked)
  }

  handleMetaDataChange (value = null) {
    this.props.onChange('MetaData', value || '')
  }

  handleOptionChange (option, index) {
    const options = this.props.field.FieldOptionList.slice()
    options[index] = option

    this.props.onChange('FieldOptionList', options)
  }

  removeOption (optionIndex) {
    const { field, onRemoveFieldOption } = this.props

    onRemoveFieldOption(field.ID, optionIndex)
  }

  addOption () {
    const { onAddFieldOption } = this.props

    onAddFieldOption(this.state.newOption)

    this.setState({ newOption: '' })
  }

  updateDisplayProperties () {
    const options = this.props.field.FieldOptionList

    options.map((item, index) => {
      item.DisplayOrder = index + 1
    })

    this.props.onChange('FieldOptionList', options)
  }

  parseImageUpload (event) {
    const files = event.target.files
    const reader = new window.FileReader()
    const me = this
    const { onSave } = this.props

    reader.onload = (function (file) {
      return function (e) {
        me.props.onChange('Base64EncodedFile', e.target.result.split(',')[1], false)
        me.props.onChange('MetaData', file.name, false)
        onSave()
      }
    })(files[0])

    reader.readAsDataURL(files[0])
  }

  getReadableAttributes (field) {
    const attributes = []

    if (field.RequiredField) { attributes.push('Required') }

    if (field.SecureField) { attributes.push('Secure') }

    if (field.UniqueField) { attributes.push('Unique') }

    if (field.ReadOnlyField) { attributes.push('Read Only') }

    return attributes.join(', ')
  }

  getExternalDataSourceOptions () {
    const { field } = this.props

    const options = []

    field.ExternalDataSourceList.map((dataSource) => {
      options.push(<OldMenuItem value={dataSource.ID} key={dataSource.ID} primaryText={dataSource.Description} />)
    })

    return options
  }

  convertFieldTokenTextToValues (text) {
    const { tokens } = this.props
    const tokensUsed = []

    debugger;
    
    // iterate over the text to find the embedded tokens
    // accommodate tokens that may use "brackets" within the field name
    let startIndex = 0
    let nestedCount = 0

    for (let character = 0; character < text.length; character += 1) {
      if (text[character] === '[') {
        if (!nestedCount) { startIndex = character }

        nestedCount += 1
      }

      if (text[character] === ']') {
        if (nestedCount === 1) {
          tokensUsed.push(text.substring(startIndex, character + 1))
        }

        nestedCount -= 1
      }
    }

    // loop through to tokens that were found and attempt
    // to match them up with process fields
    tokensUsed.map((tokenUsed) => {
      let tokenObject = tokens.filter((token) => (`[${FormHelper.decodeHTML(token.Text)}]` === tokenUsed))[0]

      if (tokenObject) { text = text.replace(tokenUsed, tokenObject.Value) }
    })

    return text
  }

  convertFieldTokenValuesToText (text = '', makeHTML = false) {
    const myRe = /\[(.*?)\]/g
    const { tokens } = this.props

    let tokensUsed = text.match(myRe)
debugger;
    if (tokensUsed) {
      tokensUsed.map((tokenUsed) => {
        let tokenObject = tokens.filter((token) => (FormHelper.decodeHTML(token.Value) === tokenUsed))[0]
        if (tokenObject) {
          let noBracketsToken = tokenUsed.substr(1, tokenUsed.length - 2)
          if (makeHTML) { text = text.replace(`[${noBracketsToken}]`, `<span class="field-token" id="[$${noBracketsToken}]" contenteditable="false">${tokenObject.Text}</span>`) } else { text = text.replace(noBracketsToken, tokenObject.Text) }
        }
      })
    }

    return text
  }

  render () {
    const { language, muiTheme: { palette }, user } = this.context
    const {
      field, index, onReorderRequest, droppedItem, onReorderOptionsRequest, style, editMode,
      onEndEdit, disableReadOnlyOption, disableRequiredOption, disableSecureOption, disableUniqueOption,
      onSave, userGroups, onItemClick, onAddFieldOption, customFieldTypes, onRemoveField, onRemoveFieldOption,
      getFieldVisibility, showVisibilityEdit, saveVisibilityList, deleteVisibilityListItem, disableDrag, tokens,
      getTemplateFields, getFieldTokens, ...rest
    } = this.props
    const { newOption, showVisibilityList, visibilityList, visibilityListLoaded, tokenFieldProperty, processFields, showFilterList, menuAnchor } = this.state
    const options = this.fieldTypeOptions()
    const groupOptions = this.userGroupOptions()
    const externalDataSourceOptions = (field.ExternalDataSourceList) ? this.getExternalDataSourceOptions() : []

    let metaDataText = field.MetaData

    if (['1', '3', '5', 'U'].includes(field.FieldType)) {
      metaDataText = this.convertFieldTokenValuesToText(field.MetaData)
    }

    return (
      (!editMode)
        ? (disableDrag)
        ? <ListItem style={style}>
          <ListItemText
            primary={FormHelper.decodeHTML(field.FieldName)}
            secondary={this.getReadableAttributes(field)}
          />
          <ListItemSecondaryAction>
            <IconButton
              onClick={(e) => { this.setState({ menuAnchor: e.currentTarget }) }}><MoreVert /></IconButton>
            <Menu
              anchorEl={menuAnchor}
              open={Boolean(menuAnchor)}
              onClose={() => { this.setState({ menuAnchor: null }) }}
              getContentAnchorEl={null}
              anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
              <MenuItem
                onClick={() => {
                  this.setState({ menuAnchor: null })
                  this.props.onItemClick()
                }}
              >{language.translate('application.edit')}</MenuItem>
              <DeleteMenuItem
                onDelete={() => {
                  this.setState({ menuAnchor: null })
                  this.props.onRemoveField()
                }}
              />
            </Menu>
          </ListItemSecondaryAction>
        </ListItem>
        : <ReorderableListItem
          primaryText={FormHelper.decodeHTML(field.FieldName)}
          secondaryText={this.getReadableAttributes(field)}
          index={index}
          id={field.ID}
          style={style}
          moveItem={data => onReorderRequest(data.dragIndex, data.hoverIndex)}
          droppedItem={droppedItem}
          rightIconButton={
            <div>
              <IconButton onClick={(e) => { this.setState({ menuAnchor: e.currentTarget }) }}><MoreVert /></IconButton>
              <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={() => { this.setState({ menuAnchor: null }) }}
                getContentAnchorEl={null}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              >
                <MenuItem
                  onClick={() => {
                    this.setState({ menuAnchor: null })
                    this.props.onItemClick()
                  }}
                >{language.translate('application.edit')}</MenuItem>
                <DeleteMenuItem
                  onDelete={() => {
                    this.setState({ menuAnchor: null })
                    this.props.onRemoveField()
                  }}
                />
              </Menu>
            </div>
          }
          {...rest}
        />
        : <Form onValid={this.enableButton.bind(this)} onInvalid={this.disableButton.bind(this)} autoComplete='off'>
          <ListItem>
            <ListItemText primary={
              <div>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  alignItems: 'flex-start'
                }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column', width: '70%', marginRight: '20px' }}>
                    <FormsyText
                      fullWidth
                      floatingLabelText={language.translate('application.fieldName')}
                      required
                      name={`${field.FieldName}-name`}
                      defaultValue={FormHelper.decodeHTML(field.FieldName)}
                      onBlur={this.handleFieldNameChange.bind(this)}
                      validationErrors={language.messages.validationErrors}
                    />
                    <FormsySelect
                      autoWidth
                      fullWidth
                      inputStyle={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                      floatingLabelText={language.translate('application.fieldType')}
                      value={field.FieldType}
                      onChange={this.handleFieldTypeChange.bind(this)}
                      name={`${field.FieldName}-type`}
                      required
                      validationErrors={language.messages.validationErrors}
                    >
                      {options}
                    </FormsySelect>
                  </div>
                  <div style={{ width: '150px', marginTop: '25px' }}>
                    {(!disableRequiredOption)
                      ? <FormControlLabel
                        control={<Checkbox
                          checked={field.RequiredField}
                          style={{ padding: '6px' }}
                          color='primary'
                          onChange={this.handleRequiredCheckboxChange.bind(this)}
                        />}
                        label={language.translate('application.required')}
                      /> : null}
                    {(!disableSecureOption)
                      ? <FormControlLabel
                        control={<Checkbox
                          checked={field.SecureField}
                          style={{ padding: '6px' }}
                          color='primary'
                          onChange={this.handleSecureCheckboxChange.bind(this)}
                        />}
                        label={language.translate('application.secure')}
                      /> : null}
                    {(!disableUniqueOption)
                      ? <FormControlLabel
                        control={<Checkbox
                          checked={field.UniqueField}
                          style={{ padding: '6px' }}
                          color='primary'
                          onChange={this.handleUniqueCheckboxChange.bind(this)}
                        />}
                        label={language.translate('application.unique')}
                      /> : null}
                    {(!disableReadOnlyOption)
                      ? <FormControlLabel
                        control={<Checkbox
                          checked={field.ReadOnlyField}
                          style={{ padding: '6px' }}
                          color='primary'
                          onChange={this.handleReadOnlyCheckboxChange.bind(this)}
                        />}
                        label={language.translate('application.readOnly')}
                      /> : null}
                  </div>
                </div>
                {(field.FieldType === 'P')
                  ? <FormsySelect
                    autoWidth
                    style={{ width: '250px', marginLeft: '12px' }}
                    inputStyle={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                    floatingLabelText={language.translate('application.userGroup')}
                    value={field.UserGroupID}
                    onChange={this.handleUserGroupChange.bind(this)}
                    name={`${field.FieldName}-group`}
                    required
                    validationErrors={language.messages.validationErrors}
                  >
                    {groupOptions}
                  </FormsySelect>
                  : null}
                {(['1', '3', '5', 'U'].includes(field.FieldType))
                  ? <div style={{
                    fontSize: '16px',
                    margin: '5px 5px 5px 5px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                  >
                    <FormsyText
                      fullWidth
                      style={{ width: '250px' }}
                      floatingLabelText={(field.ReadOnlyField) ? language.translate('application.calculatedValue') : language.translate('application.defaultValue')}
                      value={metaDataText}
                      onBlur={(event) => {
                        let newValue = this.convertFieldTokenTextToValues(event.currentTarget.value)

                        if (field.FieldType === 'U' && !field.ReadOnlyField && newValue && newValue.substring(0, 4) !== 'http') {
                          newValue = `http://${newValue}`
                        }

                        this.handleMetaDataChange(newValue)
                      }}
                      name={`${field.FieldName}-meta`}
                      validationErrors={language.messages.validationErrors}
                    />
                    <Button
                      onClick={(e) => {
                        this.setState({
                          tokenFieldProperty: 'MetaData'
                        })
                      }}
                    >
                      <Input nativeColor={palette.accent4Color} style={{ transform: 'rotate(180deg)' }} />
                    </Button>
                  </div>
                  : null}
                {(field.FieldType === 'INSTANCE' || field.FieldType === 'INSTCHLS' || field.FieldType === 'INSTLINK')
                  ? <div>
                    <FormsyTemplateAutoComplete
                      required
                      templateId={field.ProcessInstanceLookupTemplateID}
                      name={`${field.FieldName}-template`}
                      floatingLabelText={language.translate('application.processTemplate')}
                      onTemplateChange={(selectedId) => { this.handleInstanceLookupTemplateChange(null, selectedId) }}
                      sortString='Title ASC'
                    />
                    <div style={{
                      border: `1px solid ${palette.borderColor}`,
                      borderRadius: '5px',
                      marginTop: '20px',
                      width: (showFilterList) ? 'inherit' : 'fit-content'
                    }}>
                      <Button
                        onClick={() => {
                          this.setState({ showFilterList: !showFilterList })
                        }}
                        style={{ margin: '5px' }}
                      >
                        {(showFilterList) ? language.translate('application.hideFilterList') : language.translate('application.showFilterList')}
                        {(showFilterList) ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                      </Button>
                      {(showFilterList)
                        ? <CustomFieldInstanceFilterList
                          processFields={processFields}
                          onChange={(value) => { this.handleMetaDataChange(value) }}
                          filters={field.MetaData}
                        />
                        : null}
                    </div>
                  </div>
                  : null}
                {(field.FieldType === 'T')
                  ? <div>
                    <FormsyText
                      multiLine
                      rows={3}
                      fullWidth
                      style={{ marginLeft: '12px' }}
                      floatingLabelText={language.translate('application.textToDisplay')}
                      defaultValue={field.MetaData}
                      onBlur={(event) => { this.handleMetaDataChange(event.currentTarget.value) }}
                      name={`${field.FieldName}-text`}
                      required
                      validationErrors={language.messages.validationErrors}
                    />
                    <Button
                      target='_blank'
                      href='https://commonmark.org/help/'
                      color='primary'
                    >
                      {language.translate('application.markdownEnabled')}
                    </Button>
                  </div>
                  : null}
                {(field.FieldType === 'X')
                  ? <FormsySelect
                    autoWidth
                    style={{ width: '250px' }}
                    inputStyle={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                    floatingLabelText={language.translate('application.externalDataSource')}
                    value={field.ExternalDataSourceID}
                    onChange={this.handleExternalDataSourceChange.bind(this)}
                    name={`${field.FieldName}-data-source`}
                    required
                    validationErrors={language.messages.validationErrors}
                  >
                    {externalDataSourceOptions}
                  </FormsySelect>
                  : null}
                {(field.FieldType === 'S')
                  ? <FormsyText
                    style={{ width: '250px', marginLeft: '12px' }}
                    floatingLabelText={language.translate('application.url')}
                    defaultValue={field.MetaData}
                    onBlur={(event) => { this.handleMetaDataChange(event.currentTarget.value) }}
                    name={`${field.FieldName}-url`}
                    required
                    validationErrors={language.messages.validationErrors}
                  />
                  : null}
                {(field.FieldType === 'ID')
                  ? <FormsyText
                    style={{ width: '250px', marginLeft: '12px' }}
                    floatingLabelText={language.translate('application.startingSequenceNumber')}
                    defaultValue={field.MetaData}
                    type='tel'
                    onBlur={(event) => { this.handleMetaDataChange(event.currentTarget.value) }}
                    name={`${field.FieldName}-ID`}
                    validationErrors={language.messages.validationErrors}
                    validations={'isMoney'}
                  />
                  : null}
                {(field.FieldType === 'F')
                  ? <div style={{ marginTop: '10px', marginLeft: '12px' }}>
                    {(field.PrivateFileKey)
                      ? <a
                        target='_blank'
                        href={`${apiServer}/open.aspx?filekey=${field.PrivateFileKey}&token=${user.token}&email=${encodeURIComponent(user.userName)}&`}
                      >
                        {field.MetaData}
                      </a>
                      : null}
                    <FormsyUpload
                      name={`${field.FieldName}-file`}
                      style={{ marginTop: '10px' }}
                      onChange={e => this.parseImageUpload(e)}
                      multiple={false}
                      defaultValue={field.MetaData}
                    />
                  </div>
                  : null}
                {(field.FieldOptionList)
                  ? <div style={{
                    width: '90%',
                    display: 'flex',
                    flexDirection: 'column',
                    margin: 'auto',
                    backgroundColor: palette.canvasColor,
                    border: `1px solid ${palette.borderColor}`,
                    borderRadius: '10px'
                  }}
                  >
                    <ReorderableList style={{ padding: '0px' }}>
                      {(field.FieldOptionList) ? field.FieldOptionList.map((option, optionIndex) => (
                        <CustomFieldItemOption
                          key={option.ID || option.OptionDescription}
                          option={option}
                          type={field.FieldType}
                          onDelete={this.removeOption.bind(this)}
                          onChange={editedOption => this.handleOptionChange(editedOption, optionIndex)}
                          index={optionIndex}
                          droppedItem={this.updateDisplayProperties.bind(this)}
                          onReorderRequest={(from, to) => onReorderOptionsRequest(field.ID, from, to)}
                          style={{
                            borderBottom: `1px solid ${palette.borderColor}`
                          }}
                        />)) : null}
                    </ReorderableList>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-around',
                      marginBottom: '20px',
                      marginLeft: '10px'
                    }}
                    >
                      <TextField
                        style={{ width: '200px' }}
                        label={language.translate('application.addItem')}
                        onChange={event => this.setState({ newOption: event.target.value })}
                        name={`${field.FieldName}-add-option`}
                        value={newOption}
                      />
                      <Button
                        onClick={this.addOption.bind(this)}
                        disabled={!newOption}
                        style={{ marginLeft: '20px' }}
                        color='primary'
                        variant='contained'
                      >
                        <Add />
                        {language.translate('application.add')}
                      </Button>
                    </div>
                  </div>
                  : null}
                {(showVisibilityEdit)
                  ? <div style={{
                    border: `1px solid ${palette.borderColor}`,
                    borderRadius: '5px',
                    marginTop: '20px',
                    width: (showVisibilityList) ? 'inherit' : 'fit-content'
                  }}>
                    <Button
                      onClick={() => {
                        if (!showVisibilityList) {
                          if (!visibilityListLoaded) {
                            getFieldVisibility(field.ProcessTemplateID, field.ID, (visibilityList) => {
                              this.setState({
                                visibilityList,
                                visibilityListLoaded: true
                              })
                            })
                          }

                          this.setState({ showVisibilityList: true })
                        } else {
                          this.setState({ showVisibilityList: false })
                        }
                      }}
                      style={{ margin: '5px' }}
                    >
                      {(showVisibilityList) ? language.translate('application.hideVisibilityList') : language.translate('application.showVisibilityList')}
                      {(showVisibilityList) ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </Button>
                    {(showVisibilityList && visibilityListLoaded)
                      ? <div style={{ marginTop: '5px' }}>
                        <div style={{ marginLeft: '10px', color: palette.accent3Color }}>
                          {language.translate('application.onlyTheseUsersCanSeeThisFieldsValue')}:
                        </div>
                        <DefaultVisibilityList
                          defaultVisibilityList={visibilityList}
                          onSaveList={(list) => {
                            saveVisibilityList(list, field.ID, (visibilityList) => {
                              this.setState({ visibilityList })
                            })
                          }}
                          noResultsText={language.translate('application.allUsers')}
                          onDelete={(item) => {
                            deleteVisibilityListItem(item, field.ID, () => {
                              getFieldVisibility(field.ProcessTemplateID, field.ID, (visibilityList) => {
                                this.setState({
                                  visibilityList
                                })
                              })
                            })
                          }}
                        />
                      </div>
                      : null}
                  </div>
                  : null}
              </div>
            } />
            <ListItemSecondaryAction style={{ top: '10%' }}>
              <IconButton
                onClick={() => {
                  if (!field.ID) { this.props.onRemoveField() }

                  onEndEdit()
                }}
              >
                <ExpandLess nativeColor={palette.accent4Color} />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
          <FieldTokenList
            tokens={tokens}
            open={!!(tokenFieldProperty)}
            close={() => this.setState({ tokenFieldProperty: null })}
            onSelect={(tokenValue) => {
              let newText = field[tokenFieldProperty]

              if (newText) {
                newText += ' '
              }

              newText = `${newText}[${FormHelper.decodeHTML(tokenValue)}]`
              newText = this.convertFieldTokenTextToValues(newText)

              this.handleMetaDataChange(newText)

              this.setState({
                tokenFieldProperty: null
              })
            }}
          />
        </Form>
    )
  }
}

CustomFieldItem.propTypes = {
  field: PropTypes.object.isRequired,
  style: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onAddFieldOption: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  showVisibilityEdit: PropTypes.bool,
  saveVisibilityList: PropTypes.func,
  deleteVisibilityListItem: PropTypes.func,
  disableRequiredOption: PropTypes.bool,
  disableSecureOption: PropTypes.bool,
  disableUniqueOption: PropTypes.bool,
  disableReadOnlyOption: PropTypes.bool,
  disableDrag: PropTypes.bool,
  onItemClick: PropTypes.func.isRequired,
  onEndEdit: PropTypes.func.isRequired,
  onRemoveField: PropTypes.func.isRequired,
  droppedItem: PropTypes.func,
  index: PropTypes.number,
  onReorderRequest: PropTypes.func,
  userGroups: PropTypes.array,
  customFieldTypes: PropTypes.array,
  onReorderOptionsRequest: PropTypes.func,
  onRemoveFieldOption: PropTypes.func,
  getFieldVisibility: PropTypes.func,
  getTemplateFields: PropTypes.func,
  getFieldTokens: PropTypes.func,
  tokens: PropTypes.array
}

CustomFieldItem.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object,
  user: PropTypes.object
}

export default CustomFieldItem
