import React from 'react'
import PropTypes from 'prop-types'
import ProcessInfoTableRow from './ProcessInfoTableRow'
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'
import { Done } from '@material-ui/icons'
import palette from '../../styles/palette'

class ProcessInfoTable extends React.Component {
  render () {
    const { processes, onProcessClick, showLastProgress, showWaitingOn, viewProgressClick,
      cancelProcessClick, viewRelationshipsClick, oldestPendingTaskClick, addImpromptuTaskClick } = this.props
    const { muiTheme, language } = this.context

    return (
      <Table
        component='div'
        //headerStyle={{ position: 'sticky', top: '64px' }}
        style={{ border: `1px solid ${muiTheme.palette.borderColor}`, backgroundColor: palette.canvasColor, borderCollapse: 'separate' }}
        //wrapperStyle={{ marginBottom: '20px', overflow: 'initial' }}
      >
        <TableHead id='tableHeader' component='div'>
          <TableRow component='div'>
            <TableCell
              component='div'
              style={{
                position: 'sticky',
                top: -20,
                zIndex: 10,
                backgroundColor: palette.canvasColor,
                textAlign: 'center',
                fontSize: '16px',
                fontWeight: 600
              }}
            />
            <TableCell
              component='div'
              colSpan='6'
              style={{ position: 'sticky', top: -20, zIndex: 10, backgroundColor: palette.canvasColor, fontSize: '16px', fontWeight: 600 }}
            >
              {language.translate('application.process')}
            </TableCell>
            {(showWaitingOn)
              ? <TableCell
                component='div'
                style={{
                  position: 'sticky',
                  top: -20,
                  zIndex: 10,
                  backgroundColor: palette.canvasColor,
                  textAlign: 'center',
                  fontSize: '16px',
                  fontWeight: 600
                }}
              >{language.translate('application.waitingOn')}</TableCell>
              : null}
            {(showLastProgress)
              ? <TableCell
                component='div'
                style={{
                  position: 'sticky',
                  top: -20,
                  zIndex: 10,
                  backgroundColor: palette.canvasColor,
                  textAlign: 'center',
                  fontSize: '16px',
                  fontWeight: 600
                }}
              >{language.translate('application.lastProgress')}</TableCell>
              : null}
            <TableCell
              component='div'
              tooltip={language.translate('application.percentCompleted')}
              style={{
                position: 'sticky',
                top: -20,
                zIndex: 10,
                backgroundColor: palette.canvasColor,
                textAlign: 'center',
                fontSize: '16px',
                fontWeight: 600
              }}
            ><Done nativeColor='rgb(158, 158, 158)' /></TableCell>
          </TableRow>
        </TableHead>
        <TableBody component='div'>
          {processes.map(process => (<ProcessInfoTableRow
            process={process}
            onProcessClick={() => onProcessClick(process)}
            showLastProgress={showLastProgress}
            showWaitingOn={showWaitingOn}
            key={process.ID}
            viewProgressClick={() => { viewProgressClick(process) }}
            cancelProcessClick={() => { cancelProcessClick(process) }}
            viewRelationshipsClick={() => { viewRelationshipsClick(process) }}
            oldestPendingTaskClick={() => { oldestPendingTaskClick(process) }}
            addImpromptuTaskClick={() => { addImpromptuTaskClick(process) }}
          />))}
        </TableBody>
      </Table>
    )
  }
}

ProcessInfoTable.propTypes = {
  processes: PropTypes.array,
  onProcessClick: PropTypes.func,
  showLastProgress: PropTypes.bool,
  viewProgressClick: PropTypes.func,
  cancelProcessClick: PropTypes.func,
  viewRelationshipsClick: PropTypes.func,
  oldestPendingTaskClick: PropTypes.func,
  addImpromptuTaskClick: PropTypes.func,
  showWaitingOn: PropTypes.bool
}

ProcessInfoTable.contextTypes = {
  muiTheme: PropTypes.object,
  language: PropTypes.object
}

export default ProcessInfoTable
