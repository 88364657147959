import PropTypes from 'prop-types'
import React from 'react'
import { Avatar, Card, CardContent, CardHeader, Checkbox, FormControlLabel, Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import moment from 'moment'
import { CheckCircle } from '@material-ui/icons'
import FormHelper from '../../businessLogic/formHelper'
import ReactMarkdown from 'react-markdown'

class ClosedInfoCard extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const task = this.props.task

    return (
      <Card className='card'>
        <CardHeader
          title={<div
            dangerouslySetInnerHTML={{ __html: FormHelper.encodeHTML(task.TaskText_Display) }}
          />}
          subheader={language.translate('application.taskIsClosed')}
        />
        <CardContent>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell style={{ width: '170px' }}>
                  {language.translate('application.responseBy')}:
                </TableCell>
                <TableCell>
                  <ul style={{ listStyleType: 'none', padding: '0px', display: 'inline-block' }}>
                    <li style={{ textAlign: 'center' }}>
                      <Avatar style={{ height: '60px', width: '60px' }} src={task.Completed_ProfilePicURL} />
                    </li>
                    <li style={{ textAlign: 'left' }}>
                      {task.Completed_FullName}
                    </li>
                  </ul>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {language.translate('application.response')}:
                </TableCell>
                <TableCell>
                  {task.SelectedResponse_Text}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {language.translate('application.responseDate')}:
                </TableCell>
                <TableCell>
                  {moment(task.Completed_Date_Local).format('LLLL')}
                </TableCell>
              </TableRow>
              {(task.Instructions)
                ? <TableRow>
                  <TableCell>
                    {language.translate('application.instructions')}:
                  </TableCell>
                  <TableCell style={{ whiteSpace: 'inherit' }}>
                    <ReactMarkdown source={task.Instructions} linkTarget='_blank' />
                  </TableCell>
                </TableRow>
                : null}

              {(this.props.checklist && this.props.checklist.length)
                ? <TableRow>
                  <TableCell>
                    {language.translate('application.checklist')}:
                  </TableCell>
                  <TableCell style={{ whiteSpace: 'inherit', paddingTop: '7px', display: 'flex', flexDirection: 'column' }}>
                    {this.props.checklist.map(item => (
                      <FormControlLabel
                        key={item.ProcessTemplateTaskChecklistID}
                        control={<Checkbox
                          style={{ padding: '6px' }}
                          checked={item.Checked}
                          disabled
                          color='primary'
                        />}
                        label={item.ItemDescription}
                      />
                    ))}
                  </TableCell>
                </TableRow>
                : null
              }

              {(this.props.resources && this.props.resources.length)
                ? <TableRow>
                  <TableCell>
                    {language.translate('application.resources')}:
                  </TableCell>
                  <TableCell style={{ whiteSpace: 'inherit' }}>

                    {this.props.resources.map(resource => (
                      <div key={resource.ID}>
                        <a
                          href={`//${resource.ResourceURL}`}
                          target='_blank'
                        >
                          {resource.ResourceDescription}
                        </a>
                        <br />
                      </div>
                    ))}
                  </TableCell>
                </TableRow>
                : null
              }

              <TableRow>
                <TableCell>
                  {language.translate('application.notes')}:
                </TableCell>
                <TableCell style={{ whiteSpace: 'inherit' }}>
                  {task.Notes}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {language.translate('application.hours')}:
                </TableCell>
                <TableCell>
                  {task.HoursSpent}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <div style={{ width: '100%', textAlign: 'center', marginBottom: '30px' }}>
            <CheckCircle style={{ height: '100px', width: '100px' }} nativeColor={palette.successColor} />
          </div>
        </CardContent>
      </Card>
    )
  }
}

ClosedInfoCard.propTypes = {
  task: PropTypes.object.isRequired,
  checklist: PropTypes.array,
  resources: PropTypes.array
}

ClosedInfoCard.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default ClosedInfoCard
