import PropTypes from 'prop-types'
import React from 'react'
import { IconButton, Menu, MenuItem, Typography } from '@material-ui/core'
import { Close, MoreVert } from '@material-ui/icons'
import { getEmptyImage } from 'react-dnd-html5-backend'
import objectAssign from 'object-assign'
import { DragSource } from 'react-dnd'
import ReactDOM from 'react-dom'
import DeleteMenuItem from './DeleteMenuItem'
import ErrorBoundary from './ErrorBoundary'

const itemSource = {
  beginDrag (props) {
    return {}
  }
}

class RightPanelContent extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      elementHeight: 0,
      menuAnchor: null
    }
  }

  componentDidMount () {
    this.setState({ elementHeight: ReactDOM.findDOMNode(this).getElementsByClassName('right-panel-content')[0].clientHeight })

    this.props.connectDragPreview(getEmptyImage(), {
      // IE fallback: specify that we'd rather screenshot the node
      // when it already knows it's being dragged so we can hide it with CSS.
      captureDraggingState: true
    })
  }

  render () {
    const palette = this.context.muiTheme.palette
    const {
      children, toolbarStyle, title, closePanel, leftToolbarAvatar, toolbarMenuItems, rightToolbarItem,
      connectDragSource, isDragging, titleStyle, titleContainerStyle
    } = this.props
    const { elementHeight, menuAnchor } = this.state

    return (
      <div style={{ width: '100%', minHeight: '100%', marginBottom: '20px' }}>
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          {connectDragSource(
            <div style={{
              opacity: (isDragging) ? 0 : 1,
              width: '15px',
              cursor: 'e-resize',
              height: '100%',
              position: 'absolute',
              borderLeft: `1px solid ${palette.borderColor}`
            }}
            />
          )}
          <div className='right-panel-content'>
            <div style={objectAssign({}, {
              backgroundColor: palette.test,
              borderBottom: `1px solid ${palette.borderColor}`,
              padding: '3px 10px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }, toolbarStyle)}
            >
              {(leftToolbarAvatar)
                ? <div style={{ padding: '0px', marginLeft: '0px' }}>
                  {leftToolbarAvatar}
                </div>
                : <div />}
              {(title)
                ? <div style={titleContainerStyle}>
                  <Typography
                    variant='h6'
                    style={objectAssign({}, {
                      maxWidth: '300px',
                      color: palette.accent3Color,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }, titleStyle)}>
                    {title}
                  </Typography>
                </div>
                : null}
              <div style={{ display: 'flex' }}>
                {(rightToolbarItem) || null}
                {(toolbarMenuItems && toolbarMenuItems.filter(Boolean).length)
                  ? <div>
                    <IconButton onClick={(e) => { this.setState({ menuAnchor: e.currentTarget }) }}>
                      <MoreVert />
                    </IconButton>
                    <Menu
                      anchorEl={menuAnchor}
                      open={Boolean(menuAnchor)}
                      onClose={() => { this.setState({ menuAnchor: null }) }}
                      getContentAnchorEl={null}
                      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    >
                      {toolbarMenuItems.map(({ label, onClick, isDelete }, index) => {
                        if (isDelete) {
                          return (<DeleteMenuItem
                            onDelete={() => {
                              this.setState({ menuAnchor: null })
                              onClick()
                            }}
                            deleteLabel={label}
                            key={index}
                          />)
                        } else {
                          return (<MenuItem key={label} onClick={() => {
                            this.setState({ menuAnchor: null })
                            onClick()
                          }}>{label}</MenuItem>)
                        }
                      })}
                    </Menu>
                  </div>
                  : null}
                {(closePanel)
                  ? <IconButton
                    onClick={() => closePanel()}
                  >
                    <Close />
                  </IconButton>
                  : null}
              </div>
            </div>
            <ErrorBoundary>
              {children}
            </ErrorBoundary>
          </div>
        </div>
      </div>)
  }
}

RightPanelContent.propTypes = {
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  toolbarStyle: PropTypes.object,
  toolbarMenuItems: PropTypes.array,
  leftToolbarAvatar: PropTypes.node,
  rightToolbarItem: PropTypes.node,
  closePanel: PropTypes.func,
  titleStyle: PropTypes.object,
  titleContainerStyle: PropTypes.object,

  isDragging: PropTypes.bool.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  connectDragPreview: PropTypes.func.isRequired
}

RightPanelContent.contextTypes = {
  muiTheme: PropTypes.object
}

/**
 * Specifies the props to inject into your component.
 */
const collect = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging()
})

export default DragSource('rightPanelResize', itemSource, collect)(RightPanelContent)
