import {
  DISABLE_FULLSCREEN, ENABLE_FULLSCREEN, LOG_LAST_ACTIVITY, RIGHT_PANEL_CLOSED, RIGHT_PANEL_OPEN,
  SET_MAIN_CONTENT_WIDTH, UPDATE_SCREEN_WIDTH, UPDATE_SCROLLBAR_WIDTH, UPDATE_TITLE_REQUEST, SET_RIGHT_PANEL_WIDTH,
  UPDATE_SCREEN_HEIGHT, SET_API_SERVER, ENABLE_DEBUG, DISABLE_DEBUG, SET_MAIN_APP_REF, SET_RIGHT_PANEL_REF
} from '../constants/ApplicationTypes'

export function updateTitle (title) {
  return function (dispatch, state) {
    if (state().application.title !== title) {
      document.title = title
      dispatch(updateTitleRequest(title))
    }
  }
}

export function updateTitleRequest (title) {
  return {
    type: UPDATE_TITLE_REQUEST,
    payload: {
      title
    }
  }
}

export function setApiServer (server) {
  if (server && !server.includes('null') && !server.includes('undefined')) {
    window.localStorage.setItem('apiServer', server)
    return {
      type: SET_API_SERVER,
      payload: {
        server
      }
    }
  } else {
    return null
  }
}

export function enableFullScreen () {
  return function (dispatch) {
    dispatch(setFullScreen())
  }
}

export function disableFullScreen () {
  return function (dispatch) {
    dispatch(unsetFullScreen())
  }
}

export function enableDebug () {
  return {
    type: ENABLE_DEBUG
  }
}

export function disableDebug () {
  return {
    type: DISABLE_DEBUG
  }
}

export function setFullScreen () {
  return {
    type: ENABLE_FULLSCREEN
  }
}

export function unsetFullScreen () {
  return {
    type: DISABLE_FULLSCREEN
  }
}

export function updateScreenWidth (width) {
  return {
    type: UPDATE_SCREEN_WIDTH,
    payload: {
      width
    }
  }
}

export function updateScreenHeight (height) {
  return {
    type: UPDATE_SCREEN_HEIGHT,
    payload: {
      height
    }
  }
}

export function updateScrollBarWidth (width) {
  return {
    type: UPDATE_SCROLLBAR_WIDTH,
    payload: {
      width
    }
  }
}

export function rightPanelOpen () {
  return {
    type: RIGHT_PANEL_OPEN
  }
}

export function rightPanelClosed () {
  return {
    type: RIGHT_PANEL_CLOSED
  }
}

export function setRightPanel (isOpen) {
  return function (dispatch) {
    if (isOpen) { dispatch(rightPanelOpen()) } else { dispatch(rightPanelClosed()) }
  }
}

export function setMainContentWidth (width) {
  return {
    type: SET_MAIN_CONTENT_WIDTH,
    payload: {
      width
    }
  }
}

export function setRightPanelWidth (width) {
  if (width < 400 && document.body.clientWidth > 400) { width = 400 }

  window.localStorage.setItem('rightPanelWidth', width)
  return {
    type: SET_RIGHT_PANEL_WIDTH,
    payload: {
      width
    }
  }
}

export function logLastActivity () {
  const time = new Date().getTime()
  window.localStorage.setItem('lastActivity', time)

  return {
    type: LOG_LAST_ACTIVITY,
    payload: {
      time
    }
  }
}

export function setMainAppRef (ref) {
  return {
    type: SET_MAIN_APP_REF,
    payload: {
      ref
    }
  }
}

export function setRightPanelRef (ref) {
  return {
    type: SET_RIGHT_PANEL_REF,
    payload: {
      ref
    }
  }
}
