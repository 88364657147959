import PropTypes from 'prop-types'
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  Avatar, Button, Card, CardActions, CardContent, CardHeader, LinearProgress, Typography
} from '@material-ui/core'
import moment from 'moment'
import FormHelper from '../../businessLogic/formHelper'
import { Icon, GeneratedIcon } from '../Icon'

const styles = theme => ({
  colorSuccess: {
    backgroundColor: theme.palette.borderColor
  },
  barColorSuccess: {
    backgroundColor: theme.palette.successColor
  }
})

class ProcessInfoCard extends React.Component {
  getPriorityColor (pastDueTaskCount, completedDate, cancelledDate) {
    const palette = this.context.muiTheme.palette

    if (completedDate) { return palette.primary3Color }

    if (cancelledDate) { return palette.textColor }

    if (pastDueTaskCount > 0) { return palette.errorColor }

    return palette.successColor
  }

  getProcessHeaderFields () {
    const { process } = this.props
    const items = []

    for (let i = 1; i <= process.NumberOfHeaderFieldsToDisplay; i++) {
      if (process[`HeaderField${i}_Name`]) {
        items.push(
          <div key={i} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
            {
              `${FormHelper.decodeHTML(process[`HeaderField${i}_Name`])}: ${
                FormHelper.decodeHTML(process[`HeaderField${i}_Value`])}`
            }
          </div>)
      }
    }

    return (
      <div style={{ display: 'grid' }}>
        {
          items.map(item => item)
        }
      </div>
    )
  }

  render () {
    const palette = this.context.muiTheme.palette
    const process = this.props.process
    const { classes } = this.props
    const { language } = this.context

    const headerFields = this.getProcessHeaderFields()

    return (
      <Card
        className='card clickable'
        onClick={() => this.props.onClick(process.ID)}
        style={{
          margin: '10px',
          borderLeft: `7px solid ${this.getPriorityColor(process.Tasks_PastDueCount, process.CompletedDate_Local, process.CanceledDate_Local)}`
        }}
      >
        <CardHeader
          title={FormHelper.decodeHTML(process.ProcessTemplateTitle)}
          subheader={headerFields}
          avatar={
            (process.ProcessTemplateIconName)
              ? <Icon icon={process.ProcessTemplateIconName} size={40} />
              : <GeneratedIcon
                text={process.ProcessTemplateTitle}
                randomizer={process.ProcessTemplateID}
              />
          }
        />
        <CardContent>
          <div style={{ paddingBottom: '12px' }}>
            <Typography>
              {process.Tasks_ProgressPercentage || 0}% Complete
            </Typography>
            <div>
              <LinearProgress
                variant='determinate'
                value={process.Tasks_ProgressPercentage || 0}
                classes={{ colorPrimary: classes.colorSuccess, barColorPrimary: classes.barColorSuccess }}
              />
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ flex: 2 }}>
              {(process.OldestPendingTask_AssignedTo_Name)
                ? <div>
                  <Typography
                    style={{ color: palette.accent4Color }}>{language.translate('application.waitingOn')}:</Typography>
                  <div style={{
                    padding: '0 5px 0 0px',
                    textAlign: 'right',
                    float: 'left'
                  }}
                  >
                    <Avatar
                      src={process.OldestPendingTask_AssignedTo_ProfilePicURL}
                      style={{ marginLeft: '5px', height: '60px', width: '60px' }}
                    />
                  </div>
                  <Typography style={{ padding: '0 5px' }}>
                    {process.OldestPendingTask_AssignedTo_Name}<br />
                    {moment(process.OldestPendingTask_CreatedDate_Local).format('ddd, MMM Do, YYYY')}<br />
                    {moment(process.OldestPendingTask_CreatedDate_Local).fromNow(true)}
                  </Typography>
                </div>
                : ''}
              {(process.CanceledDate_Local &&
                <Typography variant='h6' style={{ color: palette.errorColor, paddingLeft: '10px', paddingTop: '10px' }}>
                  {language.translate('application.canceled')}
                </Typography>)}
            </div>
            <Typography style={{ flex: 1, padding: '0 5px' }}>
              {(process.LastCompletedTask_CompletedUser_FullName || process.LastCompletedTask_CompletedDate_Local)
                ? <span
                  style={{ color: palette.accent4Color }}>{language.translate('application.lastProgress')}<br /></span> : ''}
              {process.LastCompletedTask_CompletedUser_FullName}<br />
              {(process.LastCompletedTask_CompletedDate_Local) ? moment(process.LastCompletedTask_CompletedDate_Local).format('ddd, MMM Do, YYYY') : ''}<br />
              {(process.LastCompletedTask_CompletedDate_Local) ? moment(process.LastCompletedTask_CompletedDate_Local).fromNow() : ''}
            </Typography>
          </div>
        </CardContent>
        <CardActions>
          <Button
            onClick={(e) => {
              e.stopPropagation()
              this.props.onViewProgressClick(process.ID)
            }}
          >
            {language.translate('application.viewProgress')}
          </Button>
          {(process.OldestPendingTask_ID && this.props.onPendingTaskClick)
            ? <Button
              onClick={(e) => {
                e.stopPropagation()
                this.props.onPendingTaskClick(process)
              }}
            >
              {language.translate('application.pendingTask')}
            </Button>
            : null}
          <Button
            onClick={(e) => {
              e.stopPropagation()
              this.props.onAddImpromptuTaskClick()
            }}
          >
            {language.translate('application.addImpromptuTask')}
          </Button>
        </CardActions>
      </Card>
    )
  }
}

ProcessInfoCard.propTypes = {
  process: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onViewProgressClick: PropTypes.func.isRequired,
  onAddImpromptuTaskClick: PropTypes.func.isRequired,
  onPendingTaskClick: PropTypes.func,
  classes: PropTypes.object.isRequired
}

ProcessInfoCard.contextTypes = {
  muiTheme: PropTypes.object,
  language: PropTypes.object
}

export default withStyles(styles)(ProcessInfoCard)
