import PropTypes from 'prop-types'
import React from 'react'
import { Button, List } from '@material-ui/core'
import WebHookListItem from './WebHookListItem'
import { Add } from '@material-ui/icons'

class WebHookList extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      webHookList: null
    }
  }

  componentDidMount () {
    this.setState({ webHookList: this.props.webHookList })
  }

  componentDidUpdate (prevProps) {
    if (this.props.webHookList !== prevProps.webHookList) { this.setState({ webHookList: this.props.webHookList }) }
  }

  addNewItem () {
    const newItem = {
      PurposeDescription: '',
      ProcessTemplateID: this.props.templateId
    }

    const newWebHookList = this.state.webHookList.concat(newItem)

    this.props.onSaveList(newWebHookList)
  }

  handleChange (index, item) {
    const webHookList = this.state.webHookList
    webHookList[index] = item

    this.props.onSaveList(webHookList)
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const { webHookList } = this.state
    const { onDelete, displayMessage, fetchWebHookMappings, saveWebHookMap, deleteWebHookMapItem } = this.props

    return (
      <div>
        {(!webHookList || !webHookList.length)
          ? <div style={{ margin: '10px 20px' }}>{language.translate('application.noWebhooks')}</div>
          : <div style={{ padding: '0px 20px', backgroundColor: palette.accent2Color }}>
            <List>
              {webHookList.map((item, index) => (
                <WebHookListItem
                  item={item}
                  key={item.ID}
                  onRemoveField={() => onDelete(item)}
                  fetchWebHookMappings={fetchWebHookMappings}
                  saveWebHookMap={saveWebHookMap}
                  deleteWebHookMapItem={deleteWebHookMapItem}
                  onSave={value => this.handleChange(index, value)}
                  style={{ backgroundColor: (index % 2 === 0) ? palette.accent2Color : palette.canvasColor }}
                  displayMessage={message => displayMessage(message)}
                />
              ))}
            </List>
          </div>}
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
          <Button
            onClick={this.addNewItem.bind(this)}
            color='primary'
            variant='contained'
            style={{ margin: '10px' }}
          >
            <Add />
            {language.translate('application.addItem')}
          </Button>
        </div>
      </div>
    )
  }
}

WebHookList.propTypes = {
  templateId: PropTypes.string.isRequired,
  webHookList: PropTypes.array,
  fetchWebHookMappings: PropTypes.func,
  saveWebHookMap: PropTypes.func,
  deleteWebHookMapItem: PropTypes.func,
  onSaveList: PropTypes.func,
  onDelete: PropTypes.func,
  displayMessage: PropTypes.func
}

WebHookList.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default WebHookList
