import PropTypes from 'prop-types'
import React from 'react'
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core'
import { MenuItem, AutoComplete } from 'material-ui'
import { Clear } from '@material-ui/icons'
import { Form } from 'formsy-react'
import { FormsySelect, FormsyDate } from 'formsy-material-ui'
import FormsyAutoComplete from '../Forms/FormsyAutoComplete'
import moment from 'moment'
import CancelButton from '../Forms/CancelButton'
import FormsyTemplateAutoComplete from '../Forms/FormsyTemplateAutoComplete'

class FilterPanel extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      startDate: props.startDate,
      endDate: props.endDate,
      time: props.time,
      by: props.by,
      userID: props.userID || '',
      userSearchText: (props.userID) ? props.userList.filter(user => (user.ID === props.userID))[0].FullName : '',
      userGroupID: props.userGroupID || '',
      userGroupSearchText: (props.userGroupID) ? props.userGroupList.filter(userGroup => (userGroup.ID === props.userGroupID))[0].GroupName : '',
      templateID: props.templateID || '',
      taskID: props.taskID || '',
      taskSearchText: (props.taskID) ? props.taskList.filter(task => (task.ID === props.taskID))[0].TaskText : '',
      canSubmit: true
    }
  }

  getUserMenu () {
    const { userList } = this.props
    const menu = []

    userList.map((user) => {
      menu.push(
        { key: user.ID, text: user.FullName, value: user.ID }
      )
    })

    return menu
  }

  getUserGroupMenu () {
    const { userGroupList } = this.props
    const menu = []

    userGroupList.map((userGroup) => {
      menu.push(
        { key: userGroup.ID, text: userGroup.GroupName, value: userGroup.ID }
      )
    })

    return menu
  }

  getTaskMenu () {
    const { taskList } = this.props
    const menu = []

    taskList.map((task) => {
      menu.push(
        { key: task.ID, text: task.TaskText, value: task.TaskText }
      )
    })

    return menu
  }

  render () {
    const { onSubmit, close, type, onTemplateSelect, onUserSearch, onUserGroupSearch } = this.props
    const { startDate, endDate, by, time, userID, userGroupID, templateID, taskID, userSearchText, userGroupSearchText, taskSearchText } = this.state
    const palette = this.context.muiTheme.palette
    const { language, user } = this.context

    const taskOptions = this.getTaskMenu()
    const userOptions = this.getUserMenu()
    const userGroupOptions = this.getUserGroupMenu()

    return (
      <Dialog
        open={this.props.open}
        onClose={() => this.props.close()}
      >
        <DialogTitle
          style={{
            backgroundColor: palette.headerBackgroundColor
          }}
          disableTypography
        >
          <Typography
            variant='h6'
            style={{ color: palette.alternateTextColor }}>
            {language.translate('application.filter')}
          </Typography>
        </DialogTitle>
        <DialogContent style={{ width: '350px' }}>
          <Form
            onValid={() => this.setState({ canSubmit: true })}
            onInvalid={() => this.setState({ canSubmit: false })}
            style={{ display: 'flex', flexDirection: 'column' }}
            autoComplete='off'
          >
            {(type === 'user')
              ? <FormsyAutoComplete
                fullWidth
                name='user'
                floatingLabelText={language.translate('application.selectAUser')}
                dataSource={userOptions}
                searchText={userSearchText}
                onNewRequest={(option) => {
                  this.setState({ userID: option.key, userSearchText: option.text })
                }}
                onUpdateInput={(text) => {
                  setTimeout(() => {
                    var lastCharacterOfSearch = text.substr(text.length - 1)
                    var searchText = this.state.userSearchText

                    if (lastCharacterOfSearch === searchText.substr(searchText.length - 1) && text.length === searchText.length) {
                      onUserSearch(text)
                    }
                  }, 500)

                  this.setState({ userSearchText: text })
                }}
                value={userID}
                filter={AutoComplete.caseInsensitiveFilter}
                openOnFocus
                maxSearchResults={10}
                required
                validationErrors={language.messages.validationErrors}
              />
              : null}
            {(type === 'user_backlog')
              ? <FormsyAutoComplete
                fullWidth
                name='userGroup'
                floatingLabelText={language.translate('application.selectAGroup')}
                dataSource={userGroupOptions}
                searchText={userGroupSearchText}
                onNewRequest={(option) => {
                  this.setState({ userGroupID: option.key, userGroupSearchText: option.text })
                }}
                onUpdateInput={(text) => {
                  setTimeout(() => {
                    var lastCharacterOfSearch = text.substr(text.length - 1)
                    var searchText = this.state.userGroupSearchText

                    if (lastCharacterOfSearch === searchText.substr(searchText.length - 1) && text.length === searchText.length) {
                      onUserGroupSearch(text)
                    }
                  }, 500)

                  this.setState({ userGroupSearchText: text })
                }}
                value={userGroupID}
                filter={AutoComplete.caseInsensitiveFilter}
                openOnFocus
                maxSearchResults={10}
                required
                validationErrors={language.messages.validationErrors}
              />
              : null}
            {(type === 'template' || type === 'task')
              ? <FormsyTemplateAutoComplete
                required
                templateId={templateID}
                name='template'
                floatingLabelText={language.translate('application.selectTemplate')}
                onTemplateChange={(selectedId) => {
                  if (selectedId) {
                    this.setState({ templateID: selectedId, taskID: '' })
                    onTemplateSelect(selectedId)
                  }
                }}
                sortString='Title ASC'
              />
              : null}

            {(type === 'task')
              ? <FormsyAutoComplete
                fullWidth
                name='task'
                floatingLabelText={language.translate('application.selectTask')}
                dataSource={taskOptions}
                searchText={taskSearchText}
                onNewRequest={(option) => {
                  this.setState({ taskID: option.key, taskSearchText: option.text })
                }}
                onUpdateInput={(text) => {
                  this.setState({ taskSearchText: text })
                }}
                value={taskID}
                filter={AutoComplete.caseInsensitiveFilter}
                openOnFocus
                maxSearchResults={10}
                required
                validationErrors={language.messages.validationErrors}
              />
              : null}
            {(type !== 'user_backlog' &&
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
              >
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'baseline'
                }}
                >
                  <FormsyDate
                    floatingLabelText={language.translate('application.startDateLabel')}
                    firstDayOfWeek={0}
                    textFieldStyle={{ width: '120px' }}
                    name={'start_date'}
                    value={(startDate) ? moment(startDate, user.dateFormat).toDate() : null}
                    validationErrors={language.messages.validationErrors}
                    formatDate={date => moment(date).format(user.dateFormat)}
                    onChange={(event, date) => {
                      this.setState({ startDate: moment(date).format(user.dateFormat) })
                    }}
                  />
                  {(startDate)
                    ? <IconButton
                      style={{
                        float: 'right',
                        right: '25px',
                        marginRight: '-20px'
                      }}
                      onClick={() => this.setState({ startDate: null })}
                    >
                      <Clear />
                    </IconButton>
                    : null}
                </div>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'baseline'
                }}
                >
                  <FormsyDate
                    floatingLabelText={language.translate('application.endDateLabel')}
                    firstDayOfWeek={0}
                    textFieldStyle={{ width: '120px' }}
                    name={'end_date'}
                    value={(endDate) ? moment(endDate, user.dateFormat).toDate() : null}
                    validationErrors={language.messages.validationErrors}
                    formatDate={date => moment(date).format(user.dateFormat)}
                    onChange={(event, date) => {
                      this.setState({ endDate: moment(date).format(user.dateFormat) })
                    }}
                  />
                  {(endDate)
                    ? <IconButton
                      style={{
                        float: 'right',
                        right: '25px',
                        marginRight: '-20px'
                      }}
                      onClick={() => this.setState({ endDate: null })}
                    >
                      <Clear />
                    </IconButton>
                    : null}
                </div>
              </div>)}
            {(type !== 'user_backlog' && <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
            >
              <FormsySelect
                style={{ marginRight: '5px' }}
                name='time_in'
                value={time}
                onChange={(e, value) => {
                  this.setState({ time: value })
                }}
                floatingLabelText={language.translate('application.showTimeChartsIn')}
                validationErrors={language.messages.validationErrors}
              >
                {[
                  <MenuItem value='days' key={1} primaryText={language.translate('application.day').ucFirst()} />,
                  <MenuItem value='hours' key={2} primaryText={language.translate('application.hour').ucFirst()} />,
                  <MenuItem value='minutes' key={3} primaryText={language.translate('application.minute').ucFirst()} />
                ]}
              </FormsySelect>
              <FormsySelect
                style={{ marginLeft: '5px' }}
                name='list_by'
                value={by}
                onChange={(e, value) => {
                  this.setState({ by: value })
                }}
                floatingLabelText={language.translate('application.listResultsBy')}
                validationErrors={language.messages.validationErrors}
              >
                {[
                  <MenuItem value='day' key={1} primaryText={language.translate('application.day')} />,
                  <MenuItem value='month' key={2} primaryText={language.translate('application.month')} />,
                  <MenuItem value='year' key={3} primaryText={language.translate('application.year')} />
                ]}
              </FormsySelect>
            </div>)}
            <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}>
              <Button
                color='primary'
                onClick={() => {
                  onSubmit(this.state)
                  close()
                }}
                variant='contained'
              >
                {language.translate('application.submit')}
              </Button>
              <CancelButton
                onClick={() => close()}
                variant='contained'
              />
            </div>
          </Form>
        </DialogContent>
      </Dialog>
    )
  }
}

FilterPanel.propTypes = {
  type: PropTypes.string,
  userID: PropTypes.string,
  templateID: PropTypes.string,
  taskID: PropTypes.string,
  userList: PropTypes.array,
  taskList: PropTypes.array,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  by: PropTypes.string,
  time: PropTypes.string,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  onTemplateSelect: PropTypes.func,
  onSubmit: PropTypes.func,
  onUserSearch: PropTypes.func,
  onUserGroupSearch: PropTypes.func
}

FilterPanel.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object,
  user: PropTypes.object
}

export default FilterPanel
