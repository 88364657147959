import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormsyDate, FormsySelect, FormsyText, FormsyTime } from 'formsy-material-ui'
import { goBack, push } from 'react-router-redux'
import { Clear, ExpandMore } from '@material-ui/icons'
import {
  Button, Card, CardContent, CardHeader, Checkbox, Collapse, Divider, FormControlLabel, IconButton
} from '@material-ui/core'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { MenuItem } from 'material-ui'
import ReactMarkdown from 'react-markdown'
import { Form } from 'formsy-react'
import { Base64 } from 'js-base64'
import moment from 'moment'
import { get, post } from '../actions/base'
import GroupUserSelect from '../components/Forms/GroupUserSelect'
import CustomField from '../components/CustomField'
import RightPanelContent from '../components/Layout/RightPanelContent'
import DefaultVisibilityList from '../components/ProcessTemplate/DefaultVisibilityList'
import ProcessFieldHelper from '../businessLogic/processFieldHelper'
import theme from '../styles/theme'

const newTheme = JSON.parse(JSON.stringify(theme))
newTheme.palette.primary.main = 'rgb(60, 184, 117)'
const greenButtonTheme = createMuiTheme(newTheme)

class StartProcessView extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      canSubmit: true,
      expandFields: true,
      process: {
        Recurring_RepeatInterval: 1,
        Recurring_InitiatorUserGroupID: props.user.groupIDs[0],
        Recurring_InitiatorUserID: props.user.userid,
        Recurring_OnMonday: true,
        Recurring_OnTuesday: true,
        Recurring_OnWednesday: true,
        Recurring_OnThursday: true,
        Recurring_OnFriday: true,
        Recurring_StartTime: (process.Recurring_StartTime) ? process.Recurring_StartTime : 800,
        Recurring_EndTime: (process.Recurring_EndTime) ? process.Recurring_EndTime : 1700
      },
      fieldList: null,
      isLoaded: false,
      template: null,
      nextOccurrence: null,
      workScheduleList: [],
      visibilityList: [],
      visibilityLoaded: false
    }
  }

  // MIGRATED TO componentDidMount
  // componentWillMount () {
  //   this.fetchWorkSchedules()
  // }

  componentDidMount() {
    if (this.props.instanceId) { this.fetchProcessInstance() } else { this.fetchProcessTemplate() }
    this.fetchWorkSchedules()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.instanceId) {
      if (prevProps.instanceId !== this.props.instanceId) { this.fetchProcessInstance() }
    } else if (prevProps.templateId !== this.props.templateId) { this.fetchProcessTemplate() }

    if (!prevProps.recurring && this.props.recurring) {
      this.fetchVisibilityList()
    }
  }

  fetchProcessTemplate() {
    const { templateId, recurring } = this.props
    const { location } = this.context

    this.setState({ isLoaded: false })

    this.props.get(`processtemplate/${templateId}/field/list/visibleatstart`, {
      onSuccess: (response) => {
        const fields = response.ProcessTemplateFieldList.map((field, index) => {
          index += 1
          if (location.query[`fld${index}`] || location.query[`fld${field.ID}`]) {
            let queryStringValue = location.query[`fld${index}`] || location.query[`fld${field.ID}`]
            if (field.FieldOptionList) {
              field.ValueID = ProcessFieldHelper.getIdsFromOptionListArray(queryStringValue, field)
            } else {
              field.Value = queryStringValue
              field.ValueID = queryStringValue
            }
            field.isDirty = true
          }

          return field
        })

        this.setState({
          template: response.ProcessTemplate,
          fieldList: fields,
          isLoaded: true
        }, () => {
          if (recurring) {
            this.fetchVisibilityList()
          }
        })
      }
    })
  }

  fetchProcessInstance() {
    const { instanceId, location, recurring } = this.props

    this.setState({ isInstanceLoaded: false })

    this.props.get(`processinstance/${instanceId}/field/list/visibleatstart`, {
      onSuccess: (response) => {
        const fields = response.ProcessInstanceFieldList.map((field, index) => {
          index += 1
          if (location.query[`fld${index}`]) {
            if (field.FieldOptionList) {
              field.ValueID = ProcessFieldHelper.getIdsFromOptionListArray(location.query[`fld${index}`], field)
            } else {
              field.Value = location.query[`fld${index}`]
              field.ValueID = location.query[`fld${index}`]
            }
            field.isDirty = true
          }

          return field
        })

        this.setState({
          process: response.ProcessInstance,
          fieldList: fields,
          nextOccurrence: response.ProcessInstance.Recurring_NextDate_Local,
          isLoaded: true
        }, () => {
          if (recurring) {
            this.fetchVisibilityList()
          }
        })
      }
    })
  }

  fetchNextOccurrence() {
    setTimeout(() => { // inject a 500ms delay to give the validation state time to update
      const { instanceId } = this.props
      const { process, template, canSubmit } = this.state
      const endpoint = (instanceId) ? `processinstance/${instanceId}` : `processtemplate/${template.ID}`

      if (!canSubmit) { return false }

      const body = JSON.stringify({
        ProcessInstance: process
      })

      this.props.dispatch(post(`${endpoint}/schedule/calculatenextoccurrence`, body, {
        onSuccess: (response) => {
          this.setState({ nextOccurrence: response.ProcessInstance.Recurring_NextDate_Local })
        }
      }))
    }, 500)
  }

  fetchWorkSchedules() {
    const { get, recurring } = this.props
    const endpoint = `workschedule/list`

    if (!recurring) { return false }

    get(endpoint, {
      onSuccess: (response) => {
        this.setState({
          workSchedules: response.WorkScheduleList
        })
      }
    })
  }

  fetchVisibilityList() {
    const { process, template } = this.state
    const { get } = this.props
    const endpoint = (process.ID) ? `processinstance/${process.ID}/visibility/list` : `processtemplate/${template.ID}/defaultinstancevisibility/list`

    get(endpoint, {
      onSuccess: (response) => {
        this.setState({
          visibilityLoaded: true,
          visibilityList: response.ProcessInstanceVisibilityList || response.ProcessTemplateDefaultInstanceVisibilityList
        })
      }
    })
  }

  saveVisibilityList(process) {
    const { visibilityList } = this.state

    const body = JSON.stringify({
      ProcessInstanceVisibilityList: visibilityList
    })

    this.props.post(`processinstance/${process.ID}/visibility/list`, body, {
      onSuccess: (response) => {
        this.setState({
          visibilityList: response.ProcessInstanceVisibilityList
        })
      }
    })
  }

  startProcess(temporaryInstanceCallback) {
    const { process, template, fieldList } = this.state
    const { instanceId, recurring, location, post, returnTo, push } = this.props
    const templateId = (instanceId) ? process.ProcessTemplateID : template.ID
    let endpoint = ''

    if (temporaryInstanceCallback) {
      endpoint = (instanceId) ? `processinstance/${instanceId}/field/list` : `processtemplate/${templateId}/starttemporaryinstance`
    } else {
      endpoint = (recurring) ? (instanceId) ? `processinstance/${instanceId}/schedule` : `processtemplate/${templateId}/schedule` : (instanceId) ? `processinstance/${instanceId}/start` : `processtemplate/${templateId}/start`
    }

    // make sure they can't submit while it's submitting
    this.disableButton()

    // gather form data
    const processFieldValues = JSON.parse(JSON.stringify(fieldList))

    // convert the field ID's to ProcessTemplateFieldID and delete the ID's since this is creating new instances
    processFieldValues.map((field) => {
      if (!field.ProcessTemplateFieldID) {
        field.ProcessTemplateFieldID = field.ID
        delete field.ID
      }
    })

    const body = JSON.stringify({
      ProcessInstanceFieldList: processFieldValues,
      ProcessInstance: process,
      POSTOptions: {
        ValidateRequiredProcessFields: !(temporaryInstanceCallback)
      }
    })

    post(endpoint, body, {
      onSuccess: (response) => {
        if (temporaryInstanceCallback) {
          temporaryInstanceCallback(response.ProcessInstance.ID)
          return
        }

        if (returnTo) {
          const { newFieldIndex, ...rest } = JSON.parse(Base64.decode(returnTo))
          let query = { ...rest }

          if (newFieldIndex) {
            query[`fld${newFieldIndex}`] = response.ProcessInstanceFieldList[0].Value
          }

          push({
            pathname: location.pathname,
            query
          })
          return
        }
        this.closePanel()

        if (!recurring) {
          // take the user to the dashboard and if the first task of the
          // started process is assigned to the user, open that task
          const task = response.ProcessInstanceTask

          // redirect person their task view and open first task if it's assigned to them

          if (task && task.AssignedTo_CurrentUser) {
            this.props.push({
              pathname: location.pathname.includes('process-instances-grid') ? location.pathname : '/',
              query: { task: task.ID, ptype: 'task', pid: task.ID }
            })
          }
        }
      },

      onError: (error) => {
        this.enableButton()
      }
    })
  }

  enableButton() {
    this.setState({
      canSubmit: true
    })
  }

  disableButton() {
    this.setState({
      canSubmit: false
    })
  }

  getSelectedWeekday() {
    const { process } = this.state
    let value = ''
    const daysOfTheWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

    if (process.Recurring_OnMonday && process.Recurring_OnTuesday && process.Recurring_OnWednesday &&
      process.Recurring_OnThursday && process.Recurring_OnFriday && !process.Recurring_OnSaturday && !process.Recurring_OnSunday) {
      return 'weekday'
    }

    daysOfTheWeek.map((day) => {
      if (process[`Recurring_On${day.ucFirst()}`]) { value = day.ucFirst() }
    })

    return value
  }

  deleteScheduledItem() {
    const { process } = this.state

    const body = JSON.stringify({})

    this.props.post(`processinstance/${process.ID}/cancel`, body, {
      onSuccess: (response) => {
        this.closePanel()
      }
    })
  }

  closePanel() {
    const { onRequestClose, push, location, returnTo } = this.props

    const queryString = location.query

    Object.keys(queryString).map((query) => {
      if (query.substr(0, 3) === 'fld') {
        delete queryString[query]
      }
    })

    push({
      pathname: location.pathname,
      query: queryString
    })

    if (returnTo) {
      const { ...query } = JSON.parse(Base64.decode(returnTo))

      push({
        pathname: location.pathname,
        query
      })
      return
    }

    onRequestClose()
  }

  workScheduleMenu() {
    const { workSchedules } = this.state
    let { language } = this.context
    const menu = [<MenuItem value={null} key={0}></MenuItem>]

    //menu.push(<MenuItem value='' key='' primaryText={''} />)

    if (workSchedules) {

      workSchedules.map((workSchedule) => {
        menu.push(<MenuItem value={workSchedule.ID} key={workSchedule.ID} primaryText={workSchedule.ScheduleName} />)
      })
    }

    return menu
  }

  render() {
    if (!this.state.isLoaded) { return false }

    const { language } = this.context
    const palette = this.context.muiTheme.palette
    const { process, template, fieldList, nextOccurrence, visibilityLoaded, visibilityList, expandFields, workSchedules } = this.state
    const { push, recurring, instanceId, dispatch, user, location, returnTo } = this.props
    const daysOfTheWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

    let fieldToAutoFocus = null

    return (
      <RightPanelContent
        closePanel={() => this.closePanel()}
        title={language.translate('application.startProcess')}
        toolbarMenuItems={[
          (user.canManageProcessTemplates)
            ? {
              onClick: () => {
                if (instanceId) { push(`/process-template/${process.ProcessTemplateID}`) } else { push(`/process-template/${template.ID}`) }
              },
              label: language.translate('application.editTemplate')
            }
            : null,
          (recurring && process.ID && !process.CanceledDate_Local && user.canCancelProcesses)
            ? {
              onClick: () => {
                this.deleteScheduledItem()
              },
              isDelete: true,
              label: language.translate('application.cancelRecurringProcess')
            }
            : null,
          {
            onClick: () => {
              const newSearchItem = Base64.encodeURI(JSON.stringify({
                property: 'ProcessTemplateID',
                value: template.ID,
                displayText: language.translate('application.processTemplate'),
                operator: '(EQUALTO_ID)'
              }))

              push({ pathname: '/scheduled-processes', query: { type: 'active', search: newSearchItem } })
            },
            label: language.translate('application.viewExistingSchedules')
          }
        ].filter(Boolean)}
      >
        <div style={{ padding: '15px' }}>
          <Form
            onValid={this.enableButton.bind(this)}
            onInvalid={this.disableButton.bind(this)}
            autoComplete='off'
          >
            <Card className='card'>
              <CardHeader
                title={(instanceId) ? process.ProcessTemplateTitle : template.Title}
                subheader={(instanceId) ? process.ProcessTemplateDescription : template.Description}
                action={
                  <IconButton
                    style={{ transform: (expandFields) ? 'rotate(180deg)' : 'rotate(0deg)', marginLeft: 'auto' }}
                    onClick={() => this.setState({ expandFields: !expandFields })}
                    aria-expanded={expandFields}
                    aria-label='Show more'
                  >
                    <ExpandMore />
                  </IconButton>
                }
              />
              <Collapse in={expandFields}>
                <CardContent>
                  {(template && template.FormInstructions)
                    ? <div>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div
                          style={{ flex: 1 }}
                        >{language.translate('application.instructions')}:
                        </div>
                        <div style={{ flex: 3 }}>
                          <ReactMarkdown source={template.FormInstructions} escapeHtml linkTarget='_blank' />
                        </div>
                      </div>
                      <div style={{ margin: '10px 0px 10px 0px' }}>
                        <Divider />
                      </div>
                    </div>
                    : null}
                  {
                    fieldList.map((field, index) => {
                      let filter = null

                      if (!fieldToAutoFocus && ['1', '3', '5', 'N'].includes(field.FieldType)) {
                        fieldToAutoFocus = field
                      }

                      if (field.DependantOnFieldType) {
                        filter = ProcessFieldHelper.createFilterFromDependantFieldValue(field.DependantOnFieldType, fieldList)
                      }

                      if (ProcessFieldHelper.isInstanceLookupField(field.FieldType) && field.MetaData) {
                        filter = ProcessFieldHelper.createFilterFromParsedMetaData(field.MetaData, fieldList)
                      }

                      return <CustomField
                        key={field.ID || field.ProcessTemplateFieldID}
                        field={field}
                        fieldList={fieldList}
                        ref={(input) => { this[field.ID] = input }}
                        dispatch={this.props.dispatch}
                        filter={filter}
                        onUpdate={(field) => {
                          fieldList[index] = field
                          fieldList[index].isDirty = true

                          this.setState({ fieldList })
                        }}
                        autoFocus={(fieldToAutoFocus && field.ID === fieldToAutoFocus.ID)}
                        actionButton={(field.FieldType === 'INSTANCE' || field.FieldType === 'INSTCHLS') ? () => {
                          this.startProcess((instanceId) => {
                            let newReturnTo = {
                              ...this.props.location.query,
                              newFieldIndex: index + 1
                            }

                            if (instanceId) {
                              newReturnTo.piid = instanceId
                            } else {
                              newReturnTo.pid = instanceId
                            }

                            let query = {
                              ptype: 'process_template',
                              pid: field.ProcessInstanceLookupTemplateID,
                              ptsid: field.ProcessInstanceLookupTemplateID,
                              returnTo: Base64.encodeURI(JSON.stringify(newReturnTo))
                            }

                            // parse the correct ID's out of the MetaData and push them into the query string
                            let filterArray = ProcessFieldHelper.parseFiltersFromString(field.MetaData)
                            filterArray.map((filter) => {
                              if (filter.includes('ProcessInstanceFieldList')) {
                                let { instanceFieldId, templateFieldId } = ProcessFieldHelper.parseFieldDependencyFilter(filter)

                                let foundField = fieldList.filter((fieldToSearch) => (fieldToSearch.ID === instanceFieldId || fieldToSearch.ProcessTemplateFieldID === instanceFieldId))[0]

                                if (foundField.Value) {
                                  query[`fld${templateFieldId}`] = foundField.Value
                                }
                              }
                            })

                            push({
                              pathname: location.pathname,
                              query
                            })
                          })
                        } : null}
                      />
                    })
                  }
                </CardContent>
              </Collapse>
            </Card>

            {(recurring)
              ? <div>
                <Card className='card'>
                  <CardHeader
                    title={language.translate('application.schedule')}
                    subheader={language.translate('application.scheduleProcessSubtitle')}
                  />
                  <CardContent>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <FormsySelect
                        fullWidth
                        required
                        name='schedule_type'
                        value={process.Recurring_RepeatType || ''}
                        onChange={(e, value) => {
                          process.Recurring_RepeatType = value
                          if (value === 'MonthlyOnWeekday' || value === 'YearlyOnWeekday') {
                            daysOfTheWeek.map((day) => {
                              (day.ucFirst() === 'Monday') ? process[`Recurring_On${day.ucFirst()}`] = true : process[`Recurring_On${day.ucFirst()}`] = false
                            })
                          }

                          this.setState({ process }, () => this.fetchNextOccurrence())
                        }}
                        floatingLabelText={language.translate('application.scheduleType')}
                        validationErrors={language.messages.validationErrors}
                      >
                        {[
                          <MenuItem
                            value={'Date'}
                            key={2}
                            primaryText={language.translate('application.onSpecificDate')}
                          />,
                          <MenuItem value={'Hourly'} key={3} primaryText={language.translate('application.hourly')} />,
                          <MenuItem value={'Daily'} key={4} primaryText={language.translate('application.daily')} />,
                          <MenuItem value={'Weekly'} key={5} primaryText={language.translate('application.weekly')} />,
                          <MenuItem
                            value={'MonthlyOnDate'}
                            key={6}
                            primaryText={language.translate('application.monthlyOnDate')}
                          />,
                          <MenuItem
                            value={'MonthlyOnWeekday'}
                            key={7}
                            primaryText={language.translate('application.monthlyOnWeekday')}
                          />,
                          <MenuItem
                            value={'YearlyOnDate'}
                            key={8}
                            primaryText={language.translate('application.yearlyOnDate')}
                          />,
                          <MenuItem
                            value={'YearlyOnWeekday'}
                            key={9}
                            primaryText={language.translate('application.yearlyOnWeekday')}
                          />
                        ]}
                      </FormsySelect>
                      <FormsyDate
                        name='start_date'
                        firstDayOfWeek={0}
                        required
                        fullWidth
                        value={(process.Recurring_StartDate_Local) ? moment(process.Recurring_StartDate_Local).toDate() : undefined}
                        ref='start_date'
                        validationErrors={language.messages.validationErrors}
                        formatDate={date => moment(date).format((user.dateFormat) ? user.dateFormat : 'YYYY-MM-DD')}
                        floatingLabelText={language.translate('application.startDateLabel')}
                        onChange={(event, value) => {
                          process.Recurring_StartDate_Local = moment(value).format('YYYY-MM-DD')
                          delete process.Recurring_StartDate_UTC
                          this.setState({ process }, () => this.fetchNextOccurrence())
                        }}
                      />
                      {(process.Recurring_RepeatType && process.Recurring_RepeatType !== 'Date')
                        ? <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'baseline'
                          }}
                        >
                          <div>{language.translate('application.repeatEvery')}</div>
                          <FormsyText
                            style={{ width: '40px', margin: '0px 20px' }}
                            name='template_title'
                            ref='template_title'
                            value={process.Recurring_RepeatInterval}
                            onBlur={(event) => {
                              process.Recurring_RepeatInterval = event.currentTarget.value
                              this.setState({ process }, () => this.fetchNextOccurrence())
                            }}
                            validationErrors={language.messages.validationErrors}
                          />
                          <div>
                            {(process.Recurring_RepeatType === 'Hourly') ? language.translate('application.hour') : null}
                            {(process.Recurring_RepeatType === 'Daily') ? language.translate('application.day') : null}
                            {(process.Recurring_RepeatType === 'Weekly') ? language.translate('application.week') : null}
                            {(process.Recurring_RepeatType === 'MonthlyOnDate' || process.Recurring_RepeatType === 'MonthlyOnWeekday') ? language.translate('application.month') : null}
                            {(process.Recurring_RepeatType === 'YearlyOnDate' || process.Recurring_RepeatType === 'YearlyOnWeekday') ? language.translate('application.year') : null}
                          </div>
                        </div>
                        : null}
                      {
                        (process.Recurring_RepeatType === 'MonthlyOnDate' || process.Recurring_RepeatType === 'YearlyOnDate')
                          ? <FormsySelect
                            style={{ width: '200px' }}
                            required
                            name='month_day'
                            value={process.Recurring_DayNumber}
                            floatingLabelText={language.translate('application.onThe')}
                            onChange={(e, value) => {
                              process.Recurring_DayNumber = value
                              this.setState({ process }, () => this.fetchNextOccurrence())
                            }}
                            validationErrors={language.messages.validationErrors}
                          >
                            {[
                              <MenuItem value={1} key={1} primaryText='1st' />,
                              <MenuItem value={2} key={2} primaryText='2nd' />,
                              <MenuItem value={3} key={3} primaryText='3rd' />,
                              <MenuItem value={4} key={4} primaryText='4th' />,
                              <MenuItem value={5} key={5} primaryText='5th' />,
                              <MenuItem value={6} key={6} primaryText='6th' />,
                              <MenuItem value={7} key={7} primaryText='7th' />,
                              <MenuItem value={8} key={8} primaryText='8th' />,
                              <MenuItem value={9} key={9} primaryText='9th' />,
                              <MenuItem value={10} key={10} primaryText='10th' />,
                              <MenuItem value={11} key={11} primaryText='11th' />,
                              <MenuItem value={12} key={12} primaryText='12th' />,
                              <MenuItem value={13} key={13} primaryText='13th' />,
                              <MenuItem value={14} key={14} primaryText='14th' />,
                              <MenuItem value={15} key={15} primaryText='15th' />,
                              <MenuItem value={16} key={16} primaryText='16th' />,
                              <MenuItem value={17} key={17} primaryText='17th' />,
                              <MenuItem value={18} key={18} primaryText='18th' />,
                              <MenuItem value={19} key={19} primaryText='19th' />,
                              <MenuItem value={20} key={20} primaryText='20th' />,
                              <MenuItem value={21} key={21} primaryText='21st' />,
                              <MenuItem value={22} key={22} primaryText='22nd' />,
                              <MenuItem value={23} key={23} primaryText='23rd' />,
                              <MenuItem value={24} key={24} primaryText='24th' />,
                              <MenuItem value={25} key={25} primaryText='25th' />,
                              <MenuItem value={26} key={26} primaryText='26th' />,
                              <MenuItem value={27} key={27} primaryText='27th' />,
                              <MenuItem value={28} key={28} primaryText='28th' />,
                              <MenuItem value={29} key={29} primaryText='29th' />,
                              <MenuItem value={30} key={30} primaryText='30th' />,
                              <MenuItem value={31} key={31} primaryText='31st' />,
                              <MenuItem value={-1} key={32} primaryText={language.translate('application.lastDay')} />,
                              <MenuItem
                                value={-2}
                                key={33}
                                primaryText={language.translate('application.secondToLastDay')}
                              />
                            ]}
                          </FormsySelect>
                          : null}
                      {
                        (process.Recurring_RepeatType === 'MonthlyOnWeekday' || process.Recurring_RepeatType === 'YearlyOnWeekday')
                          ? <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <FormsySelect
                              style={{ width: '150px' }}
                              name='month_weekday'
                              required
                              value={process.Recurring_WeekNumber}
                              floatingLabelText={language.translate('application.onThe')}
                              onChange={(e, value) => {
                                process.Recurring_WeekNumber = value

                                this.setState({ process }, () => this.fetchNextOccurrence())
                              }}
                              validationErrors={language.messages.validationErrors}
                            >
                              {[
                                <MenuItem value={1} key={1} primaryText='1st' />,
                                <MenuItem value={2} key={2} primaryText='2nd' />,
                                <MenuItem value={3} key={3} primaryText='3rd' />,
                                <MenuItem value={4} key={4} primaryText='4th' />,
                                <MenuItem
                                  value={-2}
                                  key={5}
                                  primaryText={language.translate('application.2ndToLast')}
                                />,
                                <MenuItem value={-1} key={6} primaryText={language.translate('application.last')} />
                              ]}
                            </FormsySelect>

                            <FormsySelect
                              style={{ width: '200px' }}
                              name='weekday'
                              required
                              value={this.getSelectedWeekday()}
                              floatingLabelText={language.translate('application.weekday')}
                              onChange={(e, value) => {
                                if (value === 'weekday') {
                                  daysOfTheWeek.map((day) => {
                                    (['Saturday', 'Sunday'].includes(day.ucFirst())) ? process[`Recurring_On${day.ucFirst()}`] = false : process[`Recurring_On${day.ucFirst()}`] = true
                                  })
                                } else {
                                  daysOfTheWeek.map((day) => {
                                    (value === day.ucFirst()) ? process[`Recurring_On${day.ucFirst()}`] = true : process[`Recurring_On${day.ucFirst()}`] = false
                                  })
                                }

                                this.setState({ process }, () => this.fetchNextOccurrence())
                              }}
                              validationErrors={language.messages.validationErrors}
                            >
                              {daysOfTheWeek.map(day => (
                                <MenuItem
                                  value={day.ucFirst()}
                                  key={day}
                                  primaryText={day.ucFirst()}
                                />
                              )).concat([<MenuItem
                                value={'weekday'}
                                key={'weekday'}
                                primaryText={language.translate('application.anyWeekday')}
                              />])}
                            </FormsySelect>
                          </div>
                          : null
                      }
                      {
                        (process.Recurring_RepeatType === 'YearlyOnDate' || process.Recurring_RepeatType === 'YearlyOnWeekday')
                          ? <FormsySelect
                            style={{ width: '200px' }}
                            required
                            name='month_number'
                            value={process.Recurring_MonthNumber}
                            floatingLabelText={language.translate('application.of')}
                            onChange={(e, value) => {
                              process.Recurring_MonthNumber = value
                              this.setState({ process }, () => this.fetchNextOccurrence())
                            }}
                            validationErrors={language.messages.validationErrors}
                          >
                            {[
                              <MenuItem value={1} key={1} primaryText={language.translate('calendar.months.january')} />,
                              <MenuItem value={2} key={2} primaryText={language.translate('calendar.months.february')} />,
                              <MenuItem value={3} key={3} primaryText={language.translate('calendar.months.march')} />,
                              <MenuItem value={4} key={4} primaryText={language.translate('calendar.months.april')} />,
                              <MenuItem value={5} key={5} primaryText={language.translate('calendar.months.may')} />,
                              <MenuItem value={6} key={6} primaryText={language.translate('calendar.months.june')} />,
                              <MenuItem value={7} key={7} primaryText={language.translate('calendar.months.july')} />,
                              <MenuItem value={8} key={8} primaryText={language.translate('calendar.months.august')} />,
                              <MenuItem
                                value={9}
                                key={9}
                                primaryText={language.translate('calendar.months.september')}
                              />,
                              <MenuItem
                                value={10}
                                key={10}
                                primaryText={language.translate('calendar.months.october')}
                              />,
                              <MenuItem
                                value={11}
                                key={11}
                                primaryText={language.translate('calendar.months.november')}
                              />,
                              <MenuItem
                                value={12}
                                key={12}
                                primaryText={language.translate('calendar.months.december')}
                              />
                            ]}
                          </FormsySelect>
                          : null}
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <FormsyTime
                          name='start_time'
                          textFieldStyle={{ width: '180px' }}
                          required
                          pedantic
                          value={moment(process.Recurring_StartTime, (process.Recurring_StartTime < 100) ? 'mm' : 'Hmm').toDate()}
                          floatingLabelText={language.translate('application.startingAt')}
                          validationErrors={language.messages.validationErrors}
                          onChange={(event, value) => {
                            process.Recurring_StartTime = parseInt(moment(value).format('HHmm'))
                            this.setState({ process }, () => this.fetchNextOccurrence())
                          }}
                        />
                        {
                          (process.Recurring_RepeatType === 'Hourly')
                            ? <FormsyTime
                              name='end_time'
                              textFieldStyle={{ width: '180px' }}
                              required
                              pedantic
                              value={moment(process.Recurring_EndTime, (process.Recurring_EndTime < 100) ? 'mm' : 'Hmm').toDate()}
                              floatingLabelText={language.translate('application.endingAt')}
                              validationErrors={language.messages.validationErrors}
                              onChange={(event, value) => {
                                process.Recurring_EndTime = parseInt(moment(value).format('HHmm'))
                                this.setState({ process }, () => this.fetchNextOccurrence())
                              }}
                            />
                            : null}
                      </div>
                      {
                        (process.Recurring_RepeatType === 'Hourly' ||
                          process.Recurring_RepeatType === 'Weekly' ||
                          process.Recurring_RepeatType === 'Daily' ||
                          process.Recurring_RepeatType === 'MonthlyOnDate' ||
                          process.Recurring_RepeatType === 'YearlyOnDate')
                          ? <div
                            className='field-label'
                            style={{
                              display: 'flex',
                              fontSize: '14px'
                            }}
                          >
                            <div style={{ marginRight: '20px' }}>{language.translate('application.onlyOn')}</div>
                            <div>
                              {daysOfTheWeek.map(day => (<FormControlLabel
                                key={day}
                                control={<Checkbox
                                  checked={!!(process[`Recurring_On${day.ucFirst()}`])}
                                  color='primary'
                                  onChange={(e, isChecked) => {
                                    process[`Recurring_On${day.ucFirst()}`] = isChecked
                                    this.setState({ process }, () => this.fetchNextOccurrence())
                                  }}
                                />}
                                label={day.ucFirst()}
                              />))}
                            </div>
                          </div>
                          : null}
                      <FormsySelect
                        name='work_schedule'
                        ref='work_schedule'
                        value={process.Recurring_WorkScheduleID}
                        floatingLabelText={language.translate('application.workSchedule')}
                        onChange={(event, value) => {
                          process.Recurring_WorkScheduleID = value || ''
                          this.setState({ process }, () => this.fetchNextOccurrence())
                        }}
                        inputStyle={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                        fullWidth
                        validationErrors={language.messages.validationErrors}
                      >
                        {this.workScheduleMenu()}
                      </FormsySelect>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        width: '100%'
                      }}>
                        <FormsyDate
                          name='end_date'
                          firstDayOfWeek={0}
                          fullWidth //SO THE CLEAR BUTTON WILL APPEAR TO THE RIGHT OF THE DATE ON THE SAME LINE.
                          value={(process.Recurring_EndDate_Local) ? moment(process.Recurring_EndDate_Local).toDate() : undefined}
                          ref='end_date'
                          validationErrors={language.messages.validationErrors}
                          formatDate={date => moment(date).format((user.dateFormat) ? user.dateFormat : 'YYYY-MM-DD')}
                          floatingLabelText={language.translate('application.endDateLabel')}
                          onChange={(event, value) => {
                            process.Recurring_EndDate_Local = moment(value).format('YYYY-MM-DD')
                            delete process.Recurring_EndDate_UTC
                            this.setState({ process }, () => this.fetchNextOccurrence())
                          }}
                        />
                        {(process.Recurring_EndDate_Local)
                          ? <IconButton
                            onClick={() => {
                              process.Recurring_EndDate_Local = ''
                              process.Recurring_EndDate_UTC = ''
                              this.setState({ process })
                            }}
                          >
                            <Clear />
                          </IconButton>
                          : null
                        }
                      </div>
                      <div
                        className='field-label'
                        style={{
                          marginTop: '20px',
                          padding: '5px',
                          fontSize: '16px',
                          textAlign: 'center',
                          borderRadius: '10px',
                          color: palette.primary1Color,
                          backgroundColor: palette.accent2Color
                        }}
                      >
                        {(nextOccurrence)
                          ? <div>
                            {`${language.translate('application.nextOccurrence')}: ${moment(nextOccurrence).format(user.dateFormat)
                              } ${language.translate('application.at').lcFirst()} ${moment(nextOccurrence).format('hh:mm a')}`}
                          </div>
                          : <div>{language.translate('application.noMoreOccurrences')}</div>
                        }
                      </div>
                    </div>
                    <GroupUserSelect
                      name={language.translate('application.processInitiator')}
                      required
                      dispatch={dispatch}
                      groupValue={process.Recurring_InitiatorUserGroupID}
                      userValue={process.Recurring_InitiatorUserID}
                      onGroupChange={(groupId) => {
                        if (process.Recurring_InitiatorUserGroupID !== groupId) {
                          process.Recurring_InitiatorUserGroupID = groupId
                          this.setState({ process })
                        }
                      }}
                      onUserChange={(userId) => {
                        if (process.Recurring_InitiatorUserID !== userId) {
                          process.Recurring_InitiatorUserID = userId
                          this.setState({ process })
                        }
                      }}
                    />
                  </CardContent>
                </Card>

                {(visibilityLoaded)
                  ? <Card style={{ marginBottom: '20px' }}>
                    <CardHeader
                      title={language.translate('application.defaultInstanceVisibilityTitle')}
                      subheader={language.translate('application.defaultInstanceVisibilitySubtitle')}
                    />
                    <CardContent>
                      <DefaultVisibilityList
                        key='default-visibility'
                        defaultVisibilityList={visibilityList}
                        onSaveList={(list) => {
                          this.setState({ visibilityList: list })
                        }}
                        onDelete={(item) => {
                          let itemIndex = 0

                          visibilityList.map((listItem, index) => {
                            if (listItem.ID === item.id) { itemIndex = index }
                          })

                          visibilityList.splice(itemIndex, 1)
                          this.setState({ visibilityList })
                        }}
                      />
                    </CardContent>
                  </Card>
                  : null}
              </div>
              : null}
            <MuiThemeProvider theme={greenButtonTheme}>
              <Button
                disabled={!this.state.canSubmit}
                fullWidth
                variant='contained'
                color='primary'
                style={{ color: palette.alternateTextColor }}
                onClick={this.startProcess.bind(this, false)}
              >
                {(recurring) ? (process.ID) ? language.translate('application.updateScheduledProcesses') : language.translate('application.scheduleProcess') : language.translate('application.startProcess')}
              </Button>
            </MuiThemeProvider>
          </Form>
        </div>
      </RightPanelContent>
    )
  }
}

StartProcessView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  templateId: PropTypes.string.isRequired,
  returnTo: PropTypes.string,
  onRequestClose: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  recurring: PropTypes.bool,
  instanceId: PropTypes.string,
  user: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

StartProcessView.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object,
  location: PropTypes.object,
  user: PropTypes.object
}

const mapStateToProps = state => ({
  user: state.auth
})

const mapDispatchToProps = dispatch => ({
  dispatch,
  push: bindActionCreators(push, dispatch),
  goBack: bindActionCreators(goBack, dispatch),
  get: bindActionCreators(get, dispatch),
  post: bindActionCreators(post, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(StartProcessView)
