import { checkHttpStatus, parseJSON } from '../utils'
import { goBack } from 'react-router-redux'
import { logout, logoutAndRedirect } from './authentication'
import { showSnackbar } from './snackbar'
import objectAssign from 'object-assign'
import { logLastActivity } from './application'
import axios from 'axios'

export function get (url, requestObject) {
  return function (dispatch, state) {
    let { headers, isHandshake, isSecondary, onSuccess, onError, cancelRequest } = requestObject
    // make sure no contact with the api is made unless the user is logged in unless it's to authenticate, handshake or a public url
    if (!state().auth.userid && !isHandshake && urlRequiresAuthentication(url)) {
      return new Error('User must be authenticated')
    }

    const { apiServer } = state().application
    const finalHeaders = generateHeaders(headers, state().auth)

    // encode '#' symbols since they are reserved
    url = url.replace('#', '%23')

    // encode url encodes '%' signs so we need to put them back
    url = encodeURI(url)
    url = url.replace('%25', '%')

    axios(`${apiServer}/3.0/${url}`, {
      mode: 'cors',
      method: 'GET',
      headers: finalHeaders,
      cancelToken: new axios.CancelToken(function (cancel) {
        if (cancelRequest) {
          cancelRequest(cancel)
        }
      })
    })
      .then(checkHttpStatus)
      .then(parseJSON)
      .then((response) => {
        //dispatch(logAPIActivity(response, `${apiServer}/3.0/${url}`, 'get', finalHeaders))

        dispatch(showInformationalMessages(response.ResultList.filter(message => (message.Code > 99999 && message.Code < 200000))))

        onSuccess(response)
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          const responseBody = error.response.data
          //dispatch(logAPIActivity(responseBody, `${apiServer}/3.0/${url}`, 'get', finalHeaders))
          dispatch(handleError(error, responseBody, 'get', isSecondary))
          if (onError) {
            onError(error, responseBody)
          }
        }
      })
  }
}

export function post (url, body, requestObject = {}) {
  return function (dispatch, state) {
    let { headers = {}, onSuccess, onError } = requestObject
    // make sure no contact with the api is made unless the user is logged in or it's a publicly available endpoint
    if (!state().auth.userid && urlRequiresAuthentication(url)) { return new Error('User must be authenticated') }

    const { apiServer } = state().application
    const finalHeaders = generateHeaders(headers, state().auth)

    // encode '#' symbols since they are reserved
    url = url.replace('#', '%23')

    axios(`${apiServer}/3.0/${url}`, {
      mode: 'cors',
      method: 'POST',
      headers: finalHeaders,
      data: body
    })
      .then(checkHttpStatus)
      .then(parseJSON)
      .then((response) => {
        //dispatch(logAPIActivity(response, `${apiServer}/3.0/${url}`, 'post', finalHeaders, body))

        dispatch(showErrorMessages(response.ResultList.filter(message => (message.Code > 299999))))

        dispatch(showSuccessMessages(response.ResultList.filter(message => (message.Code > 199999 && message.Code < 300000))))

        dispatch(showInformationalMessages(response.ResultList.filter(message => (message.Code > 99999 && message.Code < 200000))))

        if (onSuccess) {
          onSuccess(response)
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          const responseBody = error.response.data
          //dispatch(logAPIActivity(responseBody, `${apiServer}/3.0/${url}`, 'post', finalHeaders, body))
          dispatch(handleError(error, responseBody, 'post'))
          if (onError) {
            onError(error, responseBody)
          }
        }
      })
  }
}

function handleError (error, body, method, isSecondary) {
  return function (dispatch, state) {
    const errorMessages = body.ResultList.filter(message => (message.Code > 299999))

    if (!isSecondary) { dispatch(showErrorMessages(errorMessages)) }

    dispatch(showInformationalMessages(body.ResultList.filter(message => (message.Code > 99999 && message.Code < 200000))))

    const status = error.response.status

    switch (status) {
      case 429:
        dispatch(logout())
        window.location.reload(true)
        break
      case 403:
        if (!errorMessages[0].Code === 403027 && method === 'get' && !isSecondary) { dispatch(goBack()) }
        break
      case 401:
        dispatch(logoutAndRedirect(state().routing.locationBeforeTransitions.pathname, state().routing.locationBeforeTransitions.query, (method === 'get' && isSecondary)))
        break
      default:
        if (window.Rollbar) {
          window.Rollbar.error(`${errorMessages[0].Code}: ${errorMessages[0].Message}`, error)
        }
    }
  }
}

function showErrorMessages (messages) {
  return function (dispatch) {
    messages.map((message) => {
      dispatch(showSnackbar({
        message: message.Message,
        duration: 8000
      }))
    })
  }
}

function showSuccessMessages (messages) {
  return function (dispatch) {
    const messageCount = messages.length

    if (messageCount) { dispatch(showSnackbar(messages[messageCount - 1].Message)) }
  }
}

function showInformationalMessages (messages) {
  return function (dispatch) {
    messages.map((message) => {
      dispatch(showSnackbar(message.Message))
    })
  }
}

// function logAPIActivity (response, url, method, headers = null, body = null) {
//   return function (dispatch, state) {
//     dispatch(logLastActivity())
//     if (state().application.debug) {
//       const logObject = JSON.stringify({
//         url,
//         method,
//         headers,
//         body,
//         response
//       }, null, 2)

//       const chunkedLogObject = logObject.match(/(.|[\r\n]){1,10000}/g) // upscope limits to 10,000 characters so we need to chunk and dump
//       chunkedLogObject.map(chunk => console.log(chunk))
//     }

//     return response
//   }
// }

function urlRequiresAuthentication (url) {
  return (url !== 'authenticate' && url.substring(0, 6) !== 'public')
}

export function generateHeaders (optionalHeaders = {}, user) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'ProcessPlan-Application': 'true'
  }

  if (user.isAuthenticated) {
    headers.token = user.token
    headers.username = user.userName
  }

  return objectAssign(headers, optionalHeaders)
}
