import PropTypes from 'prop-types'
import React from 'react'
import objectAssign from 'object-assign'
import {
  Button, Dialog, DialogContent, DialogTitle, Divider, List, ListItem, ListItemSecondaryAction, ListItemText,
  Typography, Switch
} from '@material-ui/core'
import AppStoreBadgeBlk from '~/images/AppStoreBadgeBlk'
import PlayStoreBadgeBlk from '~/images/PlayStoreBadgeBlk'

class Preferences extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      showTwoFactorInstruction: false
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (!prevProps.user.TwoFactorEnabledForUser && this.props.user.TwoFactorEnabledForUser) {
      this.setState({ showTwoFactorInstruction: true })
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    return this.props.user !== nextProps.user || nextState.showTwoFactorInstruction !== this.state.showTwoFactorInstruction
  }

  render () {
    const { language, muiTheme: { palette } } = this.context
    const { onSave } = this.props
    const { showTwoFactorInstruction } = this.state

    const user = objectAssign({}, this.props.user)

    return (
      <List style={{ padding: '0px 20px', backgroundColor: palette.accent2Color }}>
        <ListItem>
          <ListItemText primary={language.translate('application.receiveNewTaskNotifications')} />
          <ListItemSecondaryAction>
            <Switch
              color='primary'
              checked={(user.ReceiveTaskEmailNotifications)}
              onChange={(obj, value) => {
                user.ReceiveTaskEmailNotifications = !user.ReceiveTaskEmailNotifications
                onSave(user)
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary={language.translate('application.receiveDailySummaryEmails')} />
          <ListItemSecondaryAction>
            <Switch
              color='primary'
              checked={(user.ReceiveDailySummaryEmails)}
              onChange={(obj, value) => {
                user.ReceiveDailySummaryEmails = !user.ReceiveDailySummaryEmails
                onSave(user)
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary={language.translate('application.twoFactorAuthEnforced')}
            secondary={language.translate('application.twoFactorAuthEnforcedMessage')}
          />
          <ListItemSecondaryAction>
            <Switch
              color='primary'
              checked={(user.TwoFactorEnabledForUser)}
              onChange={(obj, value) => {
                user.TwoFactorEnabledForUser = !user.TwoFactorEnabledForUser
                onSave(user)
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary={language.translate('application.enableSSO')}
          />
          <ListItemSecondaryAction>
            <Switch
              color='primary'
              checked={(user.SSOEnabled)}
              onChange={(obj, value) => {
                user.SSOEnabled = !user.SSOEnabled
                onSave(user)
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Dialog
          open={showTwoFactorInstruction}
          onClose={() => this.setState({ showTwoFactorInstruction: false })}
        >
          <DialogTitle
            style={{
              backgroundColor: palette.headerBackgroundColor
            }}
            disableTypography
          >
            <Typography
              variant='h6'
              style={{ color: palette.alternateTextColor }}>
              {language.translate('application.twoFactorAuthEnforced')}
            </Typography>
          </DialogTitle>
          <DialogContent style={{ width: '350px' }}>
            <div style={{ paddingTop: '10px' }}>
              {language.translate('application.twoFactorInstructions')}
            </div>
            <div
              style={{
                display: (document.location.host !== 'mobile.processplan.com') ? 'flex' : 'none',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingBottom: '10px'
              }}
            >
              <a
                href='https://play.google.com/store/apps/details?id=com.processplan.mobile&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                <PlayStoreBadgeBlk />
              </a>
              <a
                href='https://itunes.apple.com/us/developer/processplan-llc/id1214386317?app=itunes&ign-mpt=uo%3D4'>
                <AppStoreBadgeBlk />
              </a>
            </div>
            <Button
              onClick={() => this.setState({ showTwoFactorInstruction: false })}
              variant='contained'
            >
              {language.translate('application.close')}
            </Button>
          </DialogContent>
        </Dialog>
      </List>
    )
  }
}

Preferences.propTypes = {
  user: PropTypes.object,
  onSave: PropTypes.func
}

Preferences.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default Preferences
