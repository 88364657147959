import PropTypes from 'prop-types'
import React from 'react'
import { Button, Divider, List, ListItem, ListItemSecondaryAction, ListItemText, TextField } from '@material-ui/core'
import { Input } from '@material-ui/icons'
import FormHelper from '~/businessLogic/formHelper'
import FieldTokenList from './FieldTokenList'

class TemplateTaskSettings extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      tokenFieldProperty: null,
      task: {}
    }
  }

  componentDidMount () {
    this.setState({
      task: this.props.task
    })
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (this.props.task !== nextProps.task) { return true }

    return true
  }

  componentDidUpdate (prevProps, prevState) {
    if (JSON.stringify(prevProps.task) !== JSON.stringify(this.props.task)) { this.setState({ task: this.props.task }) }
  }

  convertFieldTokenTextToValues (text) {
    const { tokens } = this.props
    const tokensUsed = []

    // iterate over the text to find the embedded tokens
    // accommodate tokens that may use "brackets" within the field name
    let startIndex = 0
    let nestedCount = 0

    for (let character = 0; character < text.length; character += 1) {
      if (text[character] === '[') {
        if (!nestedCount) { startIndex = character }

        nestedCount += 1
      }

      if (text[character] === ']') {
        if (nestedCount === 1) {
          tokensUsed.push(text.substring(startIndex, character + 1))
        }

        nestedCount -= 1
      }
    }

    // loop through to tokens that were found and attempt
    // to match them up with process fields
    tokensUsed.map((tokenUsed) => {
      let tokenObject = tokens.filter((token) => (`[${FormHelper.decodeHTML(token.Text)}]` === tokenUsed))[0]

      if (tokenObject) { text = text.replace(tokenUsed, tokenObject.Value) }
    })

    return text
  }

  convertFieldTokenValuesToText (text = '', makeHTML = false) {
    const myRe = /\[(.*?)\]/g
    const { tokens } = this.props

    let tokensUsed = text.match(myRe)

    if (tokensUsed) {
      tokensUsed.map((tokenUsed) => {
        let tokenObject = tokens.filter((token) => (FormHelper.decodeHTML(token.Value) === tokenUsed))[0]
        if (tokenObject) {
          let noBracketsToken = tokenUsed.substr(1, tokenUsed.length - 2)
          if (makeHTML) { text = text.replace(`[${noBracketsToken}]`, `<span class="field-token" id="[$${noBracketsToken}]" contenteditable="false">${task}</span>`) } else { text = text.replace(noBracketsToken, tokenObject.Text) }
        }
      })
    }

    return text
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const { onSave, tokens } = this.props
    const { tokenFieldProperty, task } = this.state

    // fix bug caused by toggles not updating if they were already set to empty when loaded (caused by no task data loaded yet)
    if (!Object.keys(task).length) {
      return null
    }

    const taskText = this.convertFieldTokenValuesToText(task.TaskText)
    const taskInstructions = this.convertFieldTokenValuesToText(task.TaskInstructions)

    return (
      <List key={1} style={{ padding: '0px 20px', backgroundColor: palette.accent2Color }}>
        <ListItem>
          <ListItemText style={{ marginRight: '10px' }} primary={
            <TextField
              multiline
              rows={5}
              fullWidth
              label={language.translate('application.task')}
              name='description'
              value={taskText}
              onChange={(e) => {
                task.TaskText = this.convertFieldTokenTextToValues(e.currentTarget.value)
                this.setState({ task })
              }}
              onBlur={(e) => {
                task.TaskText = this.convertFieldTokenTextToValues(e.currentTarget.value)
                onSave(task)
              }}
            />
          } />
          <ListItemSecondaryAction>
            <Button
              onClick={(e) => {
                this.setState({
                  tokenFieldProperty: 'TaskText'
                })
              }}
            >
              <Input nativeColor={palette.accent4Color} style={{ transform: 'rotate(180deg)' }} />
            </Button>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemText style={{ marginRight: '10px' }} primary={
            <div>
              <TextField
                multiline
                rows={5}
                fullWidth
                label={language.translate('application.taskInstructions')}
                name='instructions'
                value={taskInstructions}
                onChange={(e) => {
                  task.TaskInstructions = this.convertFieldTokenTextToValues(e.currentTarget.value)
                  this.setState({ task })
                }}
                onBlur={(e) => {
                  task.TaskInstructions = this.convertFieldTokenTextToValues(e.currentTarget.value)
                  onSave(task)
                }}
              />
              <Button
                target='_blank'
                href='https://commonmark.org/help/'
                color='primary'
              >
                {language.translate('application.markdownEnabled')}
              </Button>
            </div>
          } />
          <ListItemSecondaryAction>
            <Button
              onClick={(e) => {
                this.setState({
                  tokenFieldProperty: 'TaskInstructions'
                })
              }}
            >
              <Input nativeColor={palette.accent4Color} style={{ transform: 'rotate(180deg)' }} />
            </Button>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary={
            <div>
              <div>
                {language.translate('application.enterMilestoneDescriptionText')}
              </div>
              <TextField
                name='milestone_description'
                value={task.MilestoneDescription}
                fullWidth
                style={{ marginTop: '10px' }}
                label={language.translate('application.description')}
                onChange={(e) => {
                  task.MilestoneDescription = e.currentTarget.value
                  this.setState({ task })
                }}
                onBlur={(e) => {
                  task.MilestoneDescription = e.currentTarget.value
                  onSave(task)
                }}
              />
            </div>
          } />
        </ListItem>
        <FieldTokenList
          tokens={tokens}
          open={!!(tokenFieldProperty)}
          close={() => this.setState({ tokenFieldProperty: null })}
          onSelect={(tokenValue) => {
            let newText = task[tokenFieldProperty]

            if (newText) {
              newText += ' '
            }

            newText = `${newText}[${FormHelper.decodeHTML(tokenValue)}]`
            task[tokenFieldProperty] = this.convertFieldTokenTextToValues(newText)

            onSave(task)

            this.setState({
              tokenFieldProperty: null
            })
          }}
        />
      </List>
    )
  }
}

TemplateTaskSettings.propTypes = {
  task: PropTypes.object,
  tokens: PropTypes.array,
  onSave: PropTypes.func
}

TemplateTaskSettings.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default TemplateTaskSettings
