import PropTypes from 'prop-types'
import React from 'react'
import FormHelper from '../../businessLogic/formHelper'
import { List, ListItem, ListItemSecondaryAction, ListItemText, Switch, TextField } from '@material-ui/core'
import ReorderableList from '../Layout/ReorderableList'
import ReorderableListItem from '../Layout/ReorderableListItem'

class ProcessFieldSelectList extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      searchText: ''
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (this.props.toggledFields !== nextProps.toggledFields) { return true }

    if (this.state.searchText !== nextState.searchText) { return true }

    return this.props.templateFields !== nextProps.templateFields
  }

  /*
   * Returns whether the field ID exists in the array
   */
  shouldItBeToggled (id) {
    const arrayOfFieldsToShow = this.props.toggledFields

    return (arrayOfFieldsToShow.indexOf(id) >= 0)
  }

  saveFields (id, isRequired) {
    this.updateFieldValues(id, isRequired)
  }

  updateFieldValues (id, addField) {
    let array = this.props.toggledFields.filter(Boolean)

    if (addField) {
      array = array.concat([id])
    } else {
      const indexOfField = array.indexOf(id)
      if (indexOfField >= 0) array.splice(indexOfField, 1)
    }

    this.props.onSave(array)
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { templateFields, onReorderDrag, onReorderDrop } = this.props
    const { searchText } = this.state
    const { language } = this.context

    const filteredFields = templateFields.filter((field) => {
      return (!searchText || field.FieldName.toLowerCase().indexOf(searchText.toLowerCase()) >= 0)
    })

    return (
      <div key={1} style={{ backgroundColor: palette.accent2Color }}>
        {(templateFields.length > 10 && <TextField
          label={language.translate('application.filter')}
          style={{ margin: '5px 35px' }}
          value={searchText}
          onChange={(e) => {
            this.setState({ searchText: e.target.value })
          }}
        />)}
        {(!filteredFields.length &&
          <div style={{ padding: '10px 35px' }}>{language.translate('application.nothingToShow')}</div>
        )}
        {(onReorderDrag && onReorderDrop && !searchText)
          ? <ReorderableList style={{ padding: '0px 20px' }}>
            {filteredFields.map((field, index) => {
              const isToggled = (this.shouldItBeToggled(field.ID))
              return (
                <ReorderableListItem
                  key={field.ID}
                  primaryText={FormHelper.decodeHTML(field.FieldName)}
                  index={index}
                  id={field.ID}
                  moveItem={data => onReorderDrag(data.dragIndex, data.hoverIndex)}
                  droppedItem={onReorderDrop}
                  rightIconButton={<Switch
                    color='primary'
                    checked={isToggled}
                    onChange={(obj, value) => {
                      this.saveFields(field.ID, !isToggled)
                    }}
                  />} />
              )
            })}
          </ReorderableList>
          : <List style={{ padding: '0px 20px' }}>
            {filteredFields.map((field, index) => {
              const isToggled = (this.shouldItBeToggled(field.ID))
              return (
                <ListItem key={index}>
                  <ListItemText primary={FormHelper.decodeHTML(field.FieldName)} />
                  <ListItemSecondaryAction>
                    <Switch
                      color='primary'
                      checked={isToggled}
                      onChange={(obj, value) => {
                        this.saveFields(field.ID, !isToggled)
                      }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              )
            })}
          </List>}
      </div>
    )
  }
}

ProcessFieldSelectList.propTypes = {
  templateFields: PropTypes.array,
  toggledFields: PropTypes.array,
  onSave: PropTypes.func,
  onReorderDrag: PropTypes.func,
  onReorderDrop: PropTypes.func
}

ProcessFieldSelectList.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default ProcessFieldSelectList
