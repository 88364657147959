import { createReducer } from '../utils'
import { ADD_NOTIFICATION, SET_NOTIFICATIONS } from '../constants/NotificationTypes'
import objectAssign from 'object-assign'

const initialState = {
  notifications: []
}

export default createReducer(initialState, {
  [SET_NOTIFICATIONS]: (state, payload) => objectAssign({}, state, {
    notifications: payload.notifications
  }),

  [ADD_NOTIFICATION]: (state, payload) => objectAssign({}, state, {
    notifications: state.notifications.concat([payload.notification])
  })
})
