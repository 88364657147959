import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get, post } from '../actions/base'
import { push } from 'react-router-redux'
import {
  Card, CardContent, CardHeader, Checkbox, CircularProgress, FormControl, FormControlLabel, InputLabel, MenuItem, Select
} from '@material-ui/core'
import { MenuItem as OldMenuItem } from 'material-ui'
import RightPanelContent from '../components/Layout/RightPanelContent'
import GroupUserSelect from '../components/Forms/GroupUserSelect'
import { Form } from 'formsy-react'
import { FormsySelect, FormsyText } from 'formsy-material-ui'
import FormsyUpload from '../components/Forms/FormsyUpload'
import DeleteIconButton from '../components/Layout/DeleteIconButton'
import HelpIcon from '../components/Layout/HelpIcon'

class SAMLConfiguration extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      isLoaded: false,
      samlConfig: {},
      canSubmit: true
    }
  }

  componentDidMount () {
    this.fetchSamlConfiguration()
  }

  fetchSamlConfiguration () {
    this.props.get('account/samlconfiguration', {
      onSuccess: (response) => {
        const config = (response.SAMLConfiguration) ? response.SAMLConfiguration : {}

        this.setState({
          samlConfig: config,
          isLoaded: true
        })
      }
    })
  }

  saveSamlConfiguration () {
    const { samlConfig, canSubmit } = this.state

    if (!canSubmit) { return }

    const body = JSON.stringify({
      SAMLConfiguration: samlConfig
    })

    this.props.post('account/samlconfiguration', body, {
      onSuccess: (response) => {
        this.setState({
          samlConfig: response.SAMLConfiguration
        })
      }
    })
  }

  getIDFormatMenu () {
    const menu = []
    const options = [
      'urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified',
      'urn:oasis:names:tc:SAML:1.1:nameid-format:emailAddress',
      'urn:oasis:names:tc:SAML:1.1:nameid-format:X509SubjectName',
      'urn:oasis:names:tc:SAML:1.1:nameid-format:WindowsDomainQualifiedName',
      'urn:oasis:names:tc:SAML:2.0:nameid-format:Kerberos',
      'urn:oasis:names:tc:SAML:2.0:nameid-format:entity',
      'urn:oasis:names:tc:SAML:2.0:nameid-format:persistent',
      'urn:oasis:names:tc:SAML:2.0:nameid-format:transient'
    ]

    options.map((option, index) => {
      menu.push(<OldMenuItem value={option} key={index} primaryText={option} />)
    })

    return menu
  }

  parseCertificateUpload (event) {
    const files = event.target.files
    let uploadedFile = {}
    const reader = new window.FileReader()
    const me = this

    reader.onload = (function (file) {
      return function (e) {
        uploadedFile = {
          FileName: file.name,
          Base64EncodedFile: e.target.result.split(',')[1],
          FileType: file.type
        }
        me.saveCertificate(uploadedFile)
      }
    })(files[0])

    reader.readAsDataURL(files[0])
  }

  saveCertificate (certificate) {
    const body = JSON.stringify({
      SAMLCertificate: certificate
    })

    this.props.post('account/samlconfiguration/certificate', body, {
      onSuccess: (response) => {
        this.setState({
          samlConfig: response.SAMLConfiguration
        })
      }
    })
  }

  deleteCertificate () {
    const { samlConfig } = this.state

    const body = JSON.stringify({})

    this.props.post('account/samlconfiguration/certificate/delete', body, {
      onSuccess: (response) => {
        samlConfig.CertificateFileName = ''

        this.setState({ samlConfig })
      }
    })
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const { samlConfig, isLoaded } = this.state
    const { close, dispatch, user } = this.props

    return (
      <RightPanelContent
        title={language.translate('application.samlConfiguration')}
        closePanel={() => close()}
      >
        {(!isLoaded)
          ? <CircularProgress className='loader' />
          : <Form
            onValid={() => this.setState({ canSubmit: true })}
            onInvalid={() => this.setState({ canSubmit: false })}
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}
          >
            {/* fake fields are a workaround for chrome autofill getting the wrong fields */}
            <input
              style={{
                visibility: 'hidden',
                height: 0,
                width: '1px',
                position: 'absolut‌​e',
                left: 0,
                top: 0
              }}
              type='text'
              name='fakeusernameremembered'
            />
            <input
              style={{
                visibility: 'hidden',
                height: 0,
                width: '1px',
                position: 'absolut‌​e',
                left: 0,
                top: 0
              }}
              type='password'
              name='fakepasswordremembered'
            />
            <Card
              className='card'
              style={{
                width: '90%',
                margin: '10px'
              }}
            >
              <CardHeader
                title={
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>{language.translate('application.samlInformation')}</div>
                    <HelpIcon helpUrl={'setting-up-single-sign-on'} />
                  </div>
                }
                // subtitle={'Processes started this month: ' + processesStartedThisMonth}
              />
              <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
                <FormsyText
                  required
                  name='description'
                  fullWidth
                  floatingLabelText={language.translate('application.description')}
                  floatingLabelFixed
                  hintText='ADFS, Okta, Google Apps, etc...'
                  value={samlConfig.Description}
                  onChange={(event) => {
                    samlConfig.Description = event.currentTarget.value
                    this.setState({ samlConfig })
                  }}
                  onBlur={() => {
                    this.saveSamlConfiguration()
                  }}
                  validationErrors={language.messages.validationErrors}
                />
                <FormsyText
                  required
                  name='entityId'
                  fullWidth
                  floatingLabelText={language.translate('application.entityID')}
                  value={samlConfig.EntityID}
                  onChange={(event) => {
                    samlConfig.EntityID = event.currentTarget.value
                    this.setState({ samlConfig })
                  }}
                  onBlur={() => {
                    this.saveSamlConfiguration()
                  }}
                  validationErrors={language.messages.validationErrors}
                />
                <FormsySelect
                  required
                  name='nameIdFormat'
                  fullWidth
                  floatingLabelText={language.translate('application.nameIdFormat')}
                  labelStyle={{ textOverflow: 'inherit' }}
                  autoWidth
                  onChange={(e, value) => {
                    samlConfig.NameIDFormat = value
                    this.setState({ samlConfig }, () => this.saveSamlConfiguration())
                  }}
                  value={samlConfig.NameIDFormat}
                  validationErrors={language.messages.validationErrors}
                >
                  {this.getIDFormatMenu()}
                </FormsySelect>
                <FormsyText
                  required
                  name='signOnUrl'
                  fullWidth
                  floatingLabelText={language.translate('application.signOnUrl')}
                  value={samlConfig.SingleSignOnUrl}
                  onChange={(event) => {
                    samlConfig.SingleSignOnUrl = event.currentTarget.value
                    this.setState({ samlConfig })
                  }}
                  onBlur={() => {
                    this.saveSamlConfiguration()
                  }}
                  validationErrors={language.messages.validationErrors}
                />
                <FormsyText
                  name='signOutUrl'
                  fullWidth
                  floatingLabelText={language.translate('application.logoutUrl')}
                  value={samlConfig.SingleLogoutUrl}
                  onChange={(event) => {
                    samlConfig.SingleLogoutUrl = event.currentTarget.value
                    this.setState({ samlConfig })
                  }}
                  onBlur={() => {
                    this.saveSamlConfiguration()
                  }}
                  validationErrors={language.messages.validationErrors}
                />
                <FormControl>
                  <InputLabel style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {language.translate('application.keyEncryptionMethod')}
                  </InputLabel>
                  <Select
                    name='keyEncryptionMethod'
                    fullWidth
                    autoWidth
                    onChange={(e) => {
                      samlConfig.KeyEncryptionMethod = e.target.value
                      this.setState({ samlConfig }, () => this.saveSamlConfiguration())
                    }}
                    value={samlConfig.KeyEncryptionMethod || ''}
                  >
                    {[
                      <MenuItem
                        value='http://www.w3.org/2001/04/xmlenc#rsa-1_5'
                        key={1}>
                        RSA-v1.5
                      </MenuItem>,
                      <MenuItem
                        value='http://www.w3.org/2001/04/xmlenc#rsa-oaep-mgf1p'
                        key={2}>
                        RSAES-OAEP
                      </MenuItem>
                    ]}
                  </Select>
                </FormControl>
                <FormControl>
                  <InputLabel style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {language.translate('application.dataEncryptionMethod')}
                  </InputLabel>
                  <Select
                    name='dataEncryptionMethod'
                    fullWidth
                    autoWidth
                    onChange={(e) => {
                      samlConfig.DataEncryptionMethod = e.target.value
                      this.setState({ samlConfig }, () => this.saveSamlConfiguration())
                    }}
                    value={samlConfig.DataEncryptionMethod || ''}
                  >
                    {[
                      <MenuItem
                        value='http://www.w3.org/2001/04/xmlenc#tripledes-cbc'
                        key={1}>
                        Triple DES
                      </MenuItem>,
                      <MenuItem
                        value='http://www.w3.org/2001/04/xmlenc#aes128-cbc'
                        key={2}>
                        AES-128
                      </MenuItem>,
                      <MenuItem
                        value='http://www.w3.org/2001/04/xmlenc#aes192-cbc'
                        key={3}>
                        AES-192
                      </MenuItem>,
                      <MenuItem
                        value='http://www.w3.org/2001/04/xmlenc#aes256-cbc'
                        key={4}>
                        AES-256
                      </MenuItem>
                    ]}
                  </Select>
                </FormControl>
                <FormControl>
                  <InputLabel style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {language.translate('application.digestMethod')}
                  </InputLabel>
                  <Select
                    name='digestMethod'
                    fullWidth
                    autoWidth
                    onChange={(e) => {
                      samlConfig.DigestMethod = e.target.value
                      this.setState({ samlConfig }, () => this.saveSamlConfiguration())
                    }}
                    value={samlConfig.DigestMethod || ''}
                  >
                    {[
                      <MenuItem
                        value='http://www.w3.org/2000/09/xmldsig#sha1'
                        key={1}>
                        SHA-1
                      </MenuItem>,
                      <MenuItem
                        value='http://www.w3.org/2001/04/xmlenc#sha256'
                        key={2}>
                        SHA-256
                      </MenuItem>,
                      <MenuItem
                        value='http://www.w3.org/2001/04/xmldsig-more#sha384'
                        key={3}>
                        SHA-384
                      </MenuItem>,
                      <MenuItem
                        value='http://www.w3.org/2001/04/xmlenc#sha512'
                        key={4}>
                        SHA-512
                      </MenuItem>
                    ]}
                  </Select>
                </FormControl>
                <FormControl>
                  <InputLabel style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {language.translate('application.signatureMethod')}
                  </InputLabel>
                  <Select
                    name='signatureMethod'
                    fullWidth
                    autoWidth
                    onChange={(e) => {
                      samlConfig.SignatureMethod = e.target.value
                      this.setState({ samlConfig }, () => this.saveSamlConfiguration())
                    }}
                    value={samlConfig.SignatureMethod || ''}
                  >
                    {[
                      <MenuItem
                        value='http://www.w3.org/2000/09/xmldsig#rsa-sha1'
                        key={1}>
                        SHA-1
                      </MenuItem>,
                      <MenuItem
                        value='http://www.w3.org/2001/04/xmldsig-more#rsa-sha256'
                        key={2}>
                        SHA-256
                      </MenuItem>,
                      <MenuItem
                        value='http://www.w3.org/2001/04/xmldsig-more#rsa-sha384'
                        key={3}>
                        SHA-384
                      </MenuItem>,
                      <MenuItem
                        value='http://www.w3.org/2001/04/xmldsig-more#rsa-sha512'
                        key={4}>
                        SHA-512
                      </MenuItem>
                    ]}
                  </Select>
                </FormControl>
                {(samlConfig.ID)
                  ? <div style={{
                    padding: '10px',
                    backgroundColor: palette.borderColor,
                    borderRadius: '5px',
                    margin: '10px 0px'
                  }}
                  >
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                    >
                      <div className='field-label' style={{ flex: 1, fontSize: '15px' }}>
                        {language.translate('application.certificate')}:
                      </div>
                      <div style={{ flex: 1, textAlign: 'center', maxWidth: '70%' }}>
                        {(samlConfig.CertificateFileName)
                          ? <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center'
                            }}
                          >
                            <div style={{
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis'
                            }}
                            >
                              {samlConfig.CertificateFileName}
                            </div>
                            <DeleteIconButton onDelete={() => this.deleteCertificate()} />
                          </div>
                          : null}
                        <FormsyUpload
                          name='certificate'
                          onChange={e => this.parseCertificateUpload(e)}
                          multiple={false}
                          accept='.crt, .cer, .der, .p7b, .p7r, .spc, .pfx, .p12'
                          defaultValue={samlConfig.CertificateFileName}
                        />
                      </div>
                    </div>
                    <FormsyText
                      floatingLabelText={language.translate('application.certificatePassword')}
                      type='password'
                      fullWidth
                      name='certificatePassword'
                      value={samlConfig.CertificatePassword}
                      onChange={(event) => {
                        samlConfig.CertificatePassword = event.currentTarget.value
                        this.setState({ samlConfig })
                      }}
                      onBlur={(event) => {
                        this.saveSamlConfiguration()
                      }}
                    />
                  </div>
                  : null}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={samlConfig.SignAuthnRequest}
                      color='primary'
                      onChange={() => {
                        samlConfig.SignAuthnRequest = !samlConfig.SignAuthnRequest
                        this.setState({ samlConfig }, () => this.saveSamlConfiguration())
                      }}
                    />}
                  label={language.translate('application.signAuthenticationRequest')}
                />
                <FormControlLabel
                  control={<Checkbox
                    checked={samlConfig.WantSAMLResponseSigned}
                    color='primary'
                    onChange={() => {
                      samlConfig.WantSAMLResponseSigned = !samlConfig.WantSAMLResponseSigned
                      this.setState({ samlConfig }, () => this.saveSamlConfiguration())
                    }}
                  />}
                  label={language.translate('application.wantSamlResponseSigned')}
                />
                <FormControlLabel
                  control={<Checkbox
                    checked={samlConfig.WantAssertionSigned}
                    color='primary'
                    onChange={() => {
                      samlConfig.WantAssertionSigned = !samlConfig.WantAssertionSigned
                      this.setState({ samlConfig }, () => this.saveSamlConfiguration())
                    }}
                  />}
                  label={language.translate('application.wantAssertionSigned')}
                />
                <FormControlLabel
                  control={<Checkbox
                    checked={samlConfig.WantAssertionEncrypted}
                    color='primary'
                    onChange={() => {
                      samlConfig.WantAssertionEncrypted = !samlConfig.WantAssertionEncrypted
                      this.setState({ samlConfig }, () => this.saveSamlConfiguration())
                    }}
                  />}
                  label={language.translate('application.wantAssertionEncrypted')}
                />
                <a
                  style={{ marginTop: '10px' }}
                  download
                  href='https://api.processplan.com/saml/metadata.xml'
                >
                  {language.translate('application.downloadOur')} metadata.xml
                </a>
              </CardContent>
            </Card>
            <Card
              className='card'
              style={{
                width: '90%',
                margin: '10px'
              }}
            >
              <CardHeader
                title={language.translate('application.samlAttributes')}
                subheader={language.translate('application.samlAttributesMessage')}
              />
              <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
                <FormsyText
                  name='accountid'
                  fullWidth
                  floatingLabelText={'accountid'}
                  value={user.accountID}
                  disabled
                  inputStyle={{ cursor: 'default', color: 'inherit' }}
                />
                <FormsyText
                  name='attributeEmail'
                  required
                  fullWidth
                  floatingLabelText={language.translate('application.email')}
                  value={samlConfig.AttributeName_Email}
                  onChange={(event) => {
                    samlConfig.AttributeName_Email = event.currentTarget.value
                    this.setState({ samlConfig })
                  }}
                  onBlur={() => {
                    this.saveSamlConfiguration()
                  }}
                  validationErrors={language.messages.validationErrors}
                />
                <FormsyText
                  name='attributeFirstName'
                  required
                  fullWidth
                  floatingLabelText={language.translate('application.firstName')}
                  value={samlConfig.AttributeName_FirstName}
                  onChange={(event) => {
                    samlConfig.AttributeName_FirstName = event.currentTarget.value
                    this.setState({ samlConfig })
                  }}
                  onBlur={() => {
                    this.saveSamlConfiguration()
                  }}
                  validationErrors={language.messages.validationErrors}
                />
                <FormsyText
                  name='attributeLastName'
                  required
                  fullWidth
                  floatingLabelText={language.translate('application.lastName')}
                  value={samlConfig.AttributeName_LastName}
                  onChange={(event) => {
                    samlConfig.AttributeName_LastName = event.currentTarget.value
                    this.setState({ samlConfig })
                  }}
                  onBlur={() => {
                    this.saveSamlConfiguration()
                  }}
                  validationErrors={language.messages.validationErrors}
                />
                <FormsyText
                  name='attributePosition'
                  fullWidth
                  floatingLabelText={language.translate('application.position')}
                  value={samlConfig.AttributeName_Position}
                  onChange={(event) => {
                    samlConfig.AttributeName_Position = event.currentTarget.value
                    this.setState({ samlConfig })
                  }}
                  onBlur={() => {
                    this.saveSamlConfiguration()
                  }}
                  validationErrors={language.messages.validationErrors}
                />
                <FormsyText
                  name='attributeDepartment'
                  fullWidth
                  floatingLabelText={language.translate('application.department')}
                  value={samlConfig.AttributeName_Department}
                  onChange={(event) => {
                    samlConfig.AttributeName_Department = event.currentTarget.value
                    this.setState({ samlConfig })
                  }}
                  onBlur={() => {
                    this.saveSamlConfiguration()
                  }}
                  validationErrors={language.messages.validationErrors}
                />
                <FormsyText
                  name='attributeTelephone'
                  fullWidth
                  floatingLabelText={language.translate('application.telephone')}
                  value={samlConfig.AttributeName_Telephone}
                  onChange={(event) => {
                    samlConfig.AttributeName_Telephone = event.currentTarget.value
                    this.setState({ samlConfig })
                  }}
                  onBlur={() => {
                    this.saveSamlConfiguration()
                  }}
                  validationErrors={language.messages.validationErrors}
                />
                <FormsyText
                  name='attributeCellNumber'
                  fullWidth
                  floatingLabelText={language.translate('application.cellNumber')}
                  value={samlConfig.AttributeName_MobileTelephone}
                  onChange={(event) => {
                    samlConfig.AttributeName_MobileTelephone = event.currentTarget.value
                    this.setState({ samlConfig })
                  }}
                  onBlur={() => {
                    this.saveSamlConfiguration()
                  }}
                  validationErrors={language.messages.validationErrors}
                />
                <FormsyText
                  name='attributeSupervisorName'
                  fullWidth
                  floatingLabelText={language.translate('application.supervisorName')}
                  value={samlConfig.AttributeName_SupervisorName}
                  onChange={(event) => {
                    samlConfig.AttributeName_SupervisorName = event.currentTarget.value
                    this.setState({ samlConfig })
                  }}
                  onBlur={() => {
                    this.saveSamlConfiguration()
                  }}
                  validationErrors={language.messages.validationErrors}
                />
                <FormsyText
                  name='attributeSupervisorID'
                  fullWidth
                  floatingLabelText={language.translate('application.supervisorId')}
                  value={samlConfig.AttributeName_SupervisorID}
                  onChange={(event) => {
                    samlConfig.AttributeName_SupervisorID = event.currentTarget.value
                    this.setState({ samlConfig })
                  }}
                  onBlur={() => {
                    this.saveSamlConfiguration()
                  }}
                  validationErrors={language.messages.validationErrors}
                />
              </CardContent>
            </Card>
            <Card
              className='card'
              style={{
                width: '90%',
                margin: '10px'
              }}
            >
              <CardHeader
                title={language.translate('application.userModelNewAccountsAfter')}
              />
              <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
                <GroupUserSelect
                  name={language.translate('application.selectGroupUser')}
                  style={{ width: '100%' }}
                  required
                  dispatch={dispatch}
                  groupValue={samlConfig.AutoProvisionUserGroupID}
                  userValue={samlConfig.AutoProvisionUserID}
                  onGroupChange={(groupId) => {
                    samlConfig.AutoProvisionUserGroupID = groupId
                    this.setState({ samlConfig })
                  }}
                  onUserChange={(userId) => {
                    samlConfig.AutoProvisionUserID = userId
                    this.setState({ samlConfig }, () => this.saveSamlConfiguration())
                  }}
                />
              </CardContent>
            </Card>
          </Form>
        }
      </RightPanelContent>
    )
  }
}

SAMLConfiguration.propTypes = {
  dispatch: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  user: PropTypes.object,
  push: PropTypes.func.isRequired
}

SAMLConfiguration.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object,
  location: PropTypes.object
}

const mapStateToProps = state => ({
  user: state.auth
})

const mapDispatchToProps = dispatch => ({
  dispatch,
  get: bindActionCreators(get, dispatch),
  post: bindActionCreators(post, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(SAMLConfiguration)
