import { SET_PUSHER_INSTANCE } from '../constants/PusherTypes'
import Pusher from 'pusher-js'

export function setPusherInstance (pusher) {
  return {
    type: SET_PUSHER_INSTANCE,
    payload: {
      pusher
    }
  }
}

export function connectToPusher (shouldAuthenticate = true) {
  return function (dispatch, state) {
    const pusherKey = state().application.apiServer.includes('dev') ? '1c0adb89e798dc8f1a1f' : '297847083879d2c4878f'
    dispatch(disconnectPusher())
    const pusher = new Pusher(pusherKey, {
      encrypted: true,
      cluster: 'mt1',
      authEndpoint: (shouldAuthenticate) ? `${state().application.apiServer}/3.0/pusher/authenticate` : null,
      auth: {
        headers: {
          token: (shouldAuthenticate) ? state().auth.token : null,
          username: (shouldAuthenticate) ? state().auth.userName : null
        }
      }
    })

    dispatch(setPusherInstance(pusher))
  }
}

export function disconnectPusher () {
  return function (dispatch, state) {
    const pusher = state().pusher.pusher

    if (pusher) { pusher.disconnect() }

    dispatch(setPusherInstance(null))
  }
}
