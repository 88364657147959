import PropTypes from 'prop-types'
import React from 'react'
import { Divider } from '@material-ui/core'

class FieldWrapper extends React.Component {
  render () {
    const { label, children, showDivider, required, hideLabel } = this.props

    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '10px', paddingBottom: '10px' }}>
          <div
            className='field-label'
            style={{ flex: 1, width: '33%', display: (hideLabel) ? 'none' : 'initial' }}
          >
            {label}{(required) ? '*' : ''}
          </div>
          <div style={{ flex: 2, width: '66%' }}>
            {children}
          </div>
        </div>
        {(showDivider && !hideLabel)
          ? <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ flex: 1 }} />
            <div style={{ flex: 2 }}>
              <Divider />
            </div>
          </div>
          : null}
      </div>
    )
  }
}

FieldWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  showDivider: PropTypes.bool,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  hideLabel: PropTypes.bool
}

export default FieldWrapper
