import PropTypes from 'prop-types'
import React from 'react'
import { Button, Checkbox, FormControlLabel } from '@material-ui/core'
import { MenuItem } from 'material-ui'
import { FormsySelect, FormsyText } from 'formsy-material-ui'
import { Input } from '@material-ui/icons'
import FormHelper from '../../../businessLogic/formHelper'
import FieldTokenList from '../FieldTokenList'
import HTMLEditor from '../../HTMLEditor/HTMLEditor'
import ExternalSource from '../../Forms/ExternalSource'

class PropertyComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = ({
      value: '',
      text: '',
      showTokenDialog: false
    })
  }

  componentDidMount() {
    this.setState({
      value: this.convertFieldTokenValuesToText(this.props.property.PropertyValue),
      text: this.convertFieldTokenValuesToText(this.props.property.PropertyText)
    })
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (nextState.value !== this.state.value) { return true }

    if (nextState.text !== this.state.text) { return true }

    if (nextState.showTokenDialog !== this.state.showTokenDialog) { return true }

    if (nextProps.property !== this.props.property) { return true }

    if (nextProps.screenHeight !== this.props.screenHeight) { return true }

    if (nextState.text !== this.state.text) { return true }

    return nextProps.tokens !== this.props.tokens
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.property.PropertyValue !== this.props.property.PropertyValue) {
      this.setState({
        value: this.convertFieldTokenValuesToText(this.props.property.PropertyValue)
      })
    }

    if (prevProps.property.PropertyText !== this.props.property.PropertyText) {
      this.setState({
        text: this.convertFieldTokenValuesToText(this.props.property.PropertyText)
      })
    }
  }

  generatePropertyComponent(property) {
    const { onSave, tokens, actionID, screenHeight, dependencyValue } = this.props
    const { language } = this.context
    const { text } = this.state
    const palette = this.context.muiTheme.palette

    const options = [/* <MenuItem value={''} key={0} primaryText={property.FieldHelp}/> */]

    if (property.FieldOptionList) {
      property.FieldOptionList.map((option) => {
        options.push(<MenuItem value={option.Value} key={option.Value} primaryText={option.Text} />)
      })
    }

    if (property.FieldTypeHasExternalOptionList) {
      return (<ExternalSource
        fullWidth
        name={property.PropertyName}
        floatingLabelText={property.FieldLabel}
        onChange={(value, text) => onSave(property, text, value)}
        value={property.PropertyValue}
        valueText={property.PropertyText}
        required={property.FieldRequired}
        dependencyValue={dependencyValue}
        dependencyId={property.ParentFieldTypeIdentifier}
        responseProperty={'TextValueList'}
        searchUrl={`/processtemplate/${property.ProcessTemplateID}/automatedaction/${actionID}/property/${property.ID}/externaloption/list`}
        menuProps={{ maxHeight: 100 }}
      />)
    }

    switch (property.FieldTypeIdentifier) {
      case 'L':
        return (
          <FormsySelect
            fullWidth
            key={property.ID}
            value={property.PropertyValue || ''}
            hintText={(property.PropertyValue === '') ? '' : property.FieldHelp}
            hintStyle={{ height: '25px', overflow: 'hidden', textOverflow: 'ellipsis' }}
            floatingLabelText={property.FieldLabel}
            floatingLabelFixed={!!(property.FieldHelp || (property.FieldOptionList.length && property.FieldOptionList[0].Value === ''))}
            onChange={(e, value) => onSave(property, e.target.textContent, value)}
            name={property.PropertyName}
            required={property.FieldRequired}
            inputStyle={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
            validationErrors={language.messages.validationErrors}
          >
            {options}
          </FormsySelect>
        )
      case 'C':
        return (<FormControlLabel
          key={property.ID}
          control={<Checkbox
            style={{ paddingTop: '5px', paddingBottom: '5px' }}
            checked={(property.PropertyValue === 'true')}
            color='primary'
            onChange={(e, isChecked) => onSave(property, isChecked.toString(), isChecked.toString())}
          />}
          label={property.FieldLabel}
        />)
      case '1':
      case '3':
      case '5':
      case '10':
        return (
          <div style={{ width: '100%' }}>
            {(property.PropertyName === 'VC_Number' || property.PropertyName === 'SMS_Number')
              ? <div style={{ color: palette.accent6Color }}>
                {language.translate('application.phoneNumberInputInstructions')}
              </div>
              : null}
            <FormsyText
              key={property.ID}
              required={property.FieldRequired}
              floatingLabelText={property.FieldLabel}
              name={property.PropertyName}
              ref={property.PropertyName}
              value={text}
              fullWidth
              multiLine={(parseInt(property.FieldTypeIdentifier, 10) > 1)}
              rows={parseInt(property.FieldTypeIdentifier, 10)}
              onChange={e => this.setState({ text: e.currentTarget.value })}
              onBlur={(e) => {
                if (e.currentTarget.value !== property.PropertyText) {
                  const text = this.convertFieldTokenTextToValues(e.currentTarget.value)
                  onSave(property, text, text)
                }
              }}
              validationErrors={language.messages.validationErrors}
            />
          </div>
        )
      case 'H':
        return (
          <HTMLEditor
            key={property.ID}
            name={property.PropertyName}
            tokens={tokens}
            value={this.convertFieldTokenValuesToText(property.PropertyText, true)}
            labelText={property.FieldLabel}
            onBlur={(html) => {
              if (this.convertFieldTokenHTMLToValues(html) !== property.PropertyText) {
                const text = this.convertFieldTokenHTMLToValues(html)

                onSave(property, text, '')

                this.setState({ value: html })
              }
            }}
            required={property.FieldRequired}
            validationErrors={language.messages.validationErrors}
          />
        )
      case 'T':
        return (
          <div>{property.FieldHelp}</div>
        )
      default:
        return null
    }
  }

  convertFieldTokenTextToValues(text) {
    const { tokens } = this.props
    const tokensUsed = []

    // iterate over the text to find the embedded tokens
    // accommodate tokens that may use "brackets" within the field name
    let openBracketLoopIndex = 0
    let openBracketCharacterIndex = 0
    let closeBracketLoopIndex = 0
    let closeBracketCharacterIndex = 0
    let textTokenFound = '';

    for (let openBracketLoopIndex = 0; openBracketLoopIndex < text.length; openBracketLoopIndex += 1) {
      if (text[openBracketLoopIndex] === '[') {
        openBracketCharacterIndex = openBracketLoopIndex;
        debugger;

        //LOOP THROUGH THE REMAIND OF THE STRING LOOKING FOR CLOSING BRACKETS
        //USE THE MAX FIELD TOKEN SIZE TO LIMIT THE SEARCH.  FIELD TOKENS CANNOT BE BIGGER THAN X
        for (let closeBracketLoopIndex = openBracketLoopIndex; closeBracketLoopIndex < text.length; closeBracketLoopIndex += 1) {
          if (((closeBracketLoopIndex - openBracketCharacterIndex) > 50)) {
            //TOKENS SHOULD NOT BE LONGER THAN 50 CHARACTERS
            closeBracketLoopIndex = text.length + 1; //FOR EXIT LOOP

          } else if (text[closeBracketLoopIndex] === ']') {
            debugger;
            closeBracketCharacterIndex = closeBracketLoopIndex;
            textTokenFound = text.substring(openBracketCharacterIndex, closeBracketCharacterIndex + 1);
            tokensUsed.push(textTokenFound);
          }
        }
      }
    }

    // loop through to tokens that were found and attempt
    // to match them up with process fields
    tokensUsed.map((tokenUsed) => {
      let tokenObject = tokens.filter((token) => (`[${FormHelper.decodeHTML(token.Text)}]` === tokenUsed))[0]

      if (tokenObject) { text = text.replace(tokenUsed, tokenObject.Value) }
    })

    return text
  }

  convertFieldTokenHTMLToValues(text) {
    const { tokens } = this.props
    const container = document.createElement('div')
    container.innerHTML = text
    const tokensUsed = container.getElementsByClassName('field-token')

    Array.from(tokensUsed).forEach((tempToken) => {
      let tempTokenString = tempToken.innerHTML.replace('&amp;', '&')
      let tokenObject = tokens.filter((token) => (FormHelper.decodeHTML(token.Text) === tempTokenString))[0]
      if (tokenObject) {
        tempToken.classList.remove('invalid-token')
        tempToken.outerHTML = tokenObject.Value
      } else {
        tempToken.classList.add('invalid-token')
      }
    })

    return container.innerHTML
  }

  convertFieldTokenValuesToText(text = '', makeHTML = false) {
    const myRe = /\[fld_0(.*?)\]/g ///\[(.*?)\]/g
    const { tokens } = this.props

    let tokensUsed = text.match(myRe)

    if (tokensUsed) {
      tokensUsed.map((tokenUsed) => {
        let tokenObject = tokens.filter((token) => (FormHelper.decodeHTML(token.Value) === tokenUsed))[0]
        if (tokenObject) {
          let noBracketsToken = tokenUsed.substr(1, tokenUsed.length - 2)
          if (makeHTML) { text = text.replace(`[${noBracketsToken}]`, `<span class="field-token" id="[$${noBracketsToken}]" contenteditable="false">${tokenObject.Text}</span>`) } else { text = text.replace(noBracketsToken, tokenObject.Text) }
        }
      })
    }

    return text
  }

  render() {
    const palette = this.context.muiTheme.palette
    const { property, tokens, onSave } = this.props
    const { text, showTokenDialog } = this.state

    return (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '20px' }}>
        {this.generatePropertyComponent(property)}
        <FieldTokenList
          tokens={tokens}
          open={showTokenDialog}
          close={() => this.setState({ showTokenDialog: false })}
          onSelect={(tokenValue) => {
            let newText = text

            if (newText) {
              if (property.ValueType === 'Email' || property.ValueType === 'Phone') { newText += '; ' } else { newText += ' ' }
            }

            newText = `${newText}[${FormHelper.decodeHTML(tokenValue)}]`
            const convertedText = this.convertFieldTokenTextToValues(newText)

            onSave(property, convertedText, convertedText)

            this.setState({
              text: newText,
              showTokenDialog: false
            })
          }}
        />

        {(['1', '3', '5', '10'].indexOf(property.FieldTypeIdentifier) > -1)
          ? <Button
            onClick={(e) => {
              this.setState({
                showTokenDialog: true,
                tokenFieldRef: property.PropertyName
              })
            }}
          >
            <Input nativeColor={palette.accent4Color} style={{ transform: 'rotate(180deg)' }} />
          </Button>
          : null}
      </div>
    )
  }
}

PropertyComponent.propTypes = {
  property: PropTypes.object.isRequired,
  actionID: PropTypes.string.isRequired,
  tokens: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  screenHeight: PropTypes.number,
  dependencyValue: PropTypes.string
}

PropertyComponent.contextTypes = {
  muiTheme: PropTypes.object,
  location: PropTypes.object,
  language: PropTypes.object
}

export default PropertyComponent
