import PropTypes from 'prop-types'
import React from 'react'

class RequireHttps extends React.Component {
  isLocalHost (hostname) {
    return !!(hostname === 'localhost' ||
    hostname === '[::1]' ||
    hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/) ||
    process.env.NODE_ENV === 'development')
  }

  render () {
    if (window.location.protocol === 'http:' && !this.isLocalHost(window.location.hostname)) {
      window.location.protocol = 'https:'
    }

    return this.props.children
  }
}

RequireHttps.propTypes = {
  children: PropTypes.node
}

export default RequireHttps
