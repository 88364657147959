export default class ProcessFieldHelper {
  /**
   * Provided with a string, this function returns that string once converting it to valid HTML
   *
   * @param string
   * @returns {*}
   */
  static createFilterFromParsedMetaData (metaData, processFieldList) {
    let regex = RegExp(/(\[fld_[0-z]+)/, 'g')

    // get the items that match the regex and dedupe the array
    let matches = metaData.match(regex)

    if (!matches) {
      return metaData
    }

    let newFilter = metaData

    matches.map((match) => {
      let templateFieldID = match.split('_')[1]
      let parentField = processFieldList.filter((field) => {
        let idToCheck = field.ProcessTemplateFieldID || field.ID
        return (idToCheck === templateFieldID)
      })[0]

      if (parentField) {
        newFilter = newFilter.replace(match, parentField.ValueID || '')
      } else {
        newFilter = newFilter.replace(match, '')
      }
    })

    return newFilter
  }

  static createFilterFromDependantFieldValue (dependantOnFieldType, processFieldList) {
    let filter = ''
    const dependencyFields = processFieldList.filter(tempField => (tempField.FieldType === dependantOnFieldType))

    let dependencyValue = (dependencyFields.length) ? dependencyFields[0].ValueID || '' : ''
    filter += `${dependantOnFieldType}=${dependencyValue}`

    return filter
  }

  /**
   * Provided with a field type, will determine if the field is an instance lookup field
   *
   * @param string
   * @returns {*}
   */
  static isInstanceLookupField (fieldType) {
    return (['INSTANCE', 'INSTCHLS', 'UL'].includes(fieldType))
  }

  /**
   * Provided with a query string, will return an array of filters
   *
   * @param string
   * @returns {*}
   */
  static parseFiltersFromString (queryString) {
    if (!queryString) {
      return []
    }

    if (['&', '?'].includes(queryString.charAt(0))) {
      queryString = queryString.slice(1)
    }

    let queryStringArray = queryString.split('&')
    let filterArray = []

    if (queryStringArray.length) {
      queryStringArray.map((item) => {
        if (item.substr(0, 6) === 'filter') {
          filterArray.push(item.split('=')[1])
        }
      })
    }

    return filterArray
  }

  /**
   * Provided with a filter string, will return an an object containing the filter data
   *
   * @param string
   * @returns {*}
   */
  static parseFieldDependencyFilter (filter) {
    let filterObject = {}
    // parse field ID's from metadata
    let regex = RegExp(/(\[[0-z]+)/, 'g')

    // get the items that match the regex and dedupe the array
    let matches = filter.match(regex) || []

    filterObject.templateFieldId = matches[0].replace('[', '').replace(']', '')
    filterObject.instanceFieldId = matches[1].split('_')[1]

    return filterObject
  }

  /**
   * Provided with a string/array of values, get the ID's of those values from the FieldOptionList array
   *
   * @param values
   * @param field
   * @returns string
   */
  static getIdsFromOptionListArray (values, field) {
    const { FieldOptionList } = field

    if (!Array.isArray(values)) { values = [values] }

    const idArray = []

    values.map((value) => {
      const foundOption = FieldOptionList.filter(option => (option.OptionDescription === value))[0]
      if (!foundOption) {
        return
      }

      // make sure we don't allow multiple values to be set for drop down lists or radio groups
      if (['R', 'L'].includes(field.FieldType) || idArray.length < 1) {
        idArray.push(foundOption.ID)
      }
    })

    return idArray.toString()
  }
}
