import PropTypes from 'prop-types'
import React from 'react'
import { geolocated } from 'react-geolocated'
import { Button, CircularProgress } from '@material-ui/core'
import { GpsFixed, GpsOff } from '@material-ui/icons'
import { showSnackbar } from '../../actions/snackbar'

class Geolocation extends React.Component {
  componentDidUpdate (prevProps, prevState) {
    if (prevProps.isGeolocationEnabled && !this.props.isGeolocationEnabled) { this.showNotification(this.context.language.translate('application.geolocationNotEnabled')) }

    if (prevProps.isGeolocationAvailable && !this.props.isGeolocationAvailable) { this.showNotification(this.context.language.translate('application.geolocationNotEnabled')) }

    if (!this.props.coords) { return }

    const { coords: { latitude, longitude }, onLocationChange } = this.props

    if (!onLocationChange) { return }

    if (latitude && !prevProps.coords) { onLocationChange({ latitude, longitude }) } else if (latitude && latitude !== prevProps.coords.latitude) { onLocationChange({ latitude, longitude }) }
  }

  showNotification (message) {
    const { dispatch } = this.props

    dispatch(showSnackbar(message))
  }

  render () {
    const { isGeolocationAvailable, isGeolocationEnabled, coords } = this.props
    const { language, muiTheme: { palette } } = this.context

    const buttonStyle = {
      position: 'absolute',
      right: '4px',
      padding: '0',
      width: '24px',
      minWidth: '48px'
    }

    if (!isGeolocationAvailable || !isGeolocationEnabled) {
      return (
        <Button
          style={buttonStyle}
          disabled
          variant='contained'
        >
          <GpsOff nativeColor={palette.alternateTextColor} />
        </Button>
      )
    }

    if (coords) {
      return (
        <Button
          style={buttonStyle}
          disabled
          variant='contained'
        >
          <GpsFixed nativeColor={palette.alternateTextColor} />
        </Button>
      )
    }
    return (
      <Button
        style={buttonStyle}
        disabled
        variant='contained'
      >
        <CircularProgress thickness={2} size={25} />
      </Button>
    )
  }
}

Geolocation.propTypes = {
  onLocationChange: PropTypes.func,
  coords: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    altitude: PropTypes.number,
    accuracy: PropTypes.number,
    altitudeAccuracy: PropTypes.number,
    heading: PropTypes.number,
    speed: PropTypes.number
  }),
  isGeolocationAvailable: PropTypes.bool,
  isGeolocationEnabled: PropTypes.bool,
  positionError: PropTypes.object,
  dispatch: PropTypes.func
}

Geolocation.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false
  },
  userDecisionTimeout: 5000
})(Geolocation)
