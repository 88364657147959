import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { CircularProgress } from '@material-ui/core'
import { push } from 'react-router-redux'
import { get } from '../actions/base'
import { loginUserSuccess } from '../actions/authentication'
import objectAssign from 'object-assign'
import { showSnackbar } from '../actions/snackbar'

class Handshake extends React.Component {
  constructor (props, context) {
    super(props, context)

    const query = this.context.location.query

    this.state = {
      token: query.token,
      username: query.email,
      userId: query.user_id,
      redirect: query.redirect || '/',
      user: [],
      account: []
    }
  }

  componentDidMount () {
    const { userId, username, token } = this.state
    const { language } = this.context

    if (!userId || !username || !token) {
      this.props.showSnackbar(language.translate('application.pleaseProvideValidEmailIdToken'))
      this.props.push('/')
    } else {
      this.fetchUserData()
    }
  }

  componentDidUpdate () {
    const { user, account, redirect } = this.state

    /*
     * IF THE COMPONENT UPDATES AND THE USER AND ACCOUNT INFO HAS BEEN SET, THEN LOG THE USER IN AND REDIRECT
     */
    if (Object.keys(user).length && Object.keys(account).length) {
      const payload = objectAssign({ User: user }, { Account: account })

      this.props.loginUserSuccess(payload)
      this.props.push(redirect)
    }
  }

  fetchUserData () {
    const { username, token, userId } = this.state
    const { dispatch } = this.props

    const headers = {
      token,
      username
    }

    dispatch(get(`user/${userId}`, {
      onSuccess: (response) => {
        response.User.SessionToken = token

        this.setState({ user: response.User })

        this.fetchAccountData()
      },

      headers: headers,
      isHandshake: true,

      onError: (error) => {
        this.props.push('/')
      }
    }))
  }

  fetchAccountData () {
    const { username, token } = this.state
    const { dispatch } = this.props

    const headers = {
      token,
      username
    }

    dispatch(get('account', {
      onSuccess: (response) => {
        this.setState({ account: response.Account })
      },

      headers: headers,
      isHandshake: true,

      onError: (error) => {
        this.props.push('/')
      }
    }))
  }

  render () {
    return <CircularProgress className='loader' />
  }
}

Handshake.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  loginUserSuccess: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired
}

Handshake.contextTypes = {
  location: PropTypes.object,
  language: PropTypes.object
}

const mapStateToProps = state => ({
  user: state.auth
})

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  loginUserSuccess: bindActionCreators(loginUserSuccess, dispatch),
  showSnackbar: bindActionCreators(showSnackbar, dispatch),
  dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(Handshake)
