import PropTypes from 'prop-types'
import React from 'react'
import { Avatar } from '@material-ui/core'
import ColorHelper from '../../businessLogic/colorHelper'

class GeneratedIcon extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      number: (Number.isInteger(props.randomizer) ? props.randomizer : this.convertStringToNumber(props.randomizer))
    }
  }

  generateAvatar (text) {
    const textArray = text.split(' ')

    let result = textArray[0].substring(0, 1)

    if (textArray[1]) { result += textArray[1].substring(0, 1) }

    return result
  }

  convertStringToNumber (string) {
    let number = 0

    string.split('').map((character, index) => {
      number += string.charCodeAt(index)
    })

    return number
  }

  render () {
    const { text, randomizer, style, ...rest } = this.props
    const { number } = this.state
    const palette = this.context.muiTheme

    let avatarStyle = { ...style, backgroundColor: ColorHelper.getRandom((number) || text.length) }

    return (
      <Avatar
        color={palette.accent2Color}
        style={avatarStyle}
        {...rest}
      >
        {this.generateAvatar(text)}
      </Avatar>
    )
  }
}

GeneratedIcon.propTypes = {
  text: PropTypes.string.isRequired,
  randomizer: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object
}

GeneratedIcon.defaultProps = {
  text: ''
}

GeneratedIcon.contextTypes = {
  muiTheme: PropTypes.object
}

export default GeneratedIcon
