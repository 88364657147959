import PropTypes from 'prop-types'
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { styles } from './styles.scss'
import { post } from '../../actions/base'
import { push } from 'react-router-redux'
import { showSnackbar } from '../../actions/snackbar'
import { Form } from 'formsy-react'
import { FormsyText } from 'formsy-material-ui'
import { Button } from '@material-ui/core'

const mStyles = {
  labelStyle: {
    color: '#e2e2e2',
    WebkitTextFillColor: '#e2e2e2'
  },
  underline: {
    borderColor: '#e2e2e2'
  }
}

class ResetPassword extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      canSubmit: true,
      token: context.location.query.token,
      email: context.location.query.username,
      password: ''
    }
  }

  // pass the endpoint just the username and the api sends reset email
  // pass username, token in header and User object in body with the password attribute to change password

  handleForgotPassword () {
    const { showSnackbar, post, push } = this.props
    const { email, token, password } = this.state

    const body = JSON.stringify({
      User: {
        Password: password
      }
    })

    const headers = {
      username: email,
      token
    }

    /* let token_array = atob(token).replace(/=/g, ':').replace(/&/g, ',');

     console.log(JSON.parse(token_array));

     let account = parseInt(atob(token).split('&')[1].split('=')[1]);

     console.log(account); */

    // figure out a way to extract the users account id so you can automatically log them into the app

    post('public/passwordreset', body, {
      onSuccess: (response) => {
        push('/login')
        showSnackbar('Password reset successful')
      },

      headers: headers
    })
  }

  render () {
    const { language, location } = this.context

    if (!location.query.token || !location.query.username) return null // they must submit a token and email address to load this page

    return (
      <div
        className='login-box'
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          minHeight: '500px'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <div className='logo'>
            <img
              className='img-responsive'
              style={{ maxWidth: '200px' }}
              src='https://s3.amazonaws.com/ppwebsitefiles/logo_stacked.png'
            />
          </div>
          <Form
            onValid={() => this.setState({ canSubmit: true })}
            onInvalid={() => this.setState({ canSubmit: false })}
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}
            autoComplete='off'
          >
            <FormsyText
              fullWidth
              required
              type='password'
              floatingLabelText='New password'
              onChange={(e) => {
                this.setState({ password: e.currentTarget.value })
              }}
              name='pw'
              validationErrors={language.messages.validationErrors}
              underlineFocusStyle={mStyles.underline}
              inputStyle={mStyles.labelStyle}
              floatingLabelStyle={mStyles.labelStyle}
            />
            <FormsyText
              fullWidth
              required
              type='password'
              floatingLabelText='Confirm new password'
              name='repeatPassword'
              validations={'equalsField:pw'}
              validationErrors={language.messages.validationErrors}
              underlineFocusStyle={mStyles.underline}
              inputStyle={mStyles.labelStyle}
              floatingLabelStyle={mStyles.labelStyle}
            />
            <Button
              style={{ marginTop: '30px' }}
              onClick={this.handleForgotPassword.bind(this)}
              disabled={!this.state.canSubmit}
              color='primary'
              variant='contained'
            >
              Reset password
            </Button>
          </Form>
        </div>
      </div>
    )
  }
}

ResetPassword.propTypes = {
  params: PropTypes.object.isRequired,
  post: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired
}

ResetPassword.contextTypes = {
  language: PropTypes.object,
  location: PropTypes.object,
  muiTheme: PropTypes.object
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  post: bindActionCreators(post, dispatch),
  push: bindActionCreators(push, dispatch),
  showSnackbar: bindActionCreators(showSnackbar, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
