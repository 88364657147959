import PropTypes from 'prop-types'
import React from 'react'
import CustomField from '../../CustomField'

class CustomFieldAdapter extends React.Component {
  shouldComponentUpdate (nextProps, nextState, nextContext) {
    return (JSON.stringify(this.props) !== JSON.stringify(nextProps))
  }

  render () {
    const { field, value, isSelected, apiServer, ...rest } = this.props

    field.Value = value
    if (field.Value && field.Value.length > 200 && field.Value.includes(' ') && !isSelected) {
      field.Value = `${field.Value.substr(0, 200)}...`
    }

    if (field.FieldType === 'SIG' && field.ValueID && !isSelected) {
      field.ProcessInstanceFieldFileList = [{}]
      field.ProcessInstanceFieldFileList[0].PrivateFileKey = field.ValueID
      field.ProcessInstanceFieldFileList[0].ID = field.ValueID
      field.ReadOnlyField = false
    }

    if (field.FieldType === 'A' && field.Value && !isSelected) {
      const attachments = field.Value.split(',')
      field.ProcessInstanceFieldFileList = []

      attachments.map((attachment) => {
        field.ProcessInstanceFieldFileList.push({
          ID: attachment,
          FileName: attachment
        })
      })
    }

    return (<CustomField
      field={field}
      wrapperProps={{ hideLabel: true }}
      autoFocus={isSelected}
      {...rest}
    />)
  }
}

CustomFieldAdapter.propTypes = {
  field: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  onUpdate: PropTypes.func,
  value: PropTypes.string,
  apiServer: PropTypes.string,
  isSelected: PropTypes.bool
}

export default CustomFieldAdapter
