import PropTypes from 'prop-types'
import React from 'react'
import go from 'gojs'
import './styles.scss'

go.licenseKey = '73fe4fe3b41c28c702d95d76423d38f919a42d609e8416a30c0412f7e909611c279cbf7101d78bc287f848fb1d7fc1cbcdc2607a974c0368b263d1df44e38ef0e13272bb400d1589a50223c49df878f2f97876a5c2b675a58d6a9cf4bef8c59c0eb8f2c658c90fbb2067052e56'
const $ = go.GraphObject.make

const styles = {
  contextMenuTextStyle: {
    margin: new go.Margin(6, 18, 6, 6),
    textAlign: 'left',
    font: '400 10pt Source Sans Pro, Arial',
    alignment: go.Spot.Left
  },
  normalTextStyle: {
    margin: 6,
    width: 300,
    wrap: go.TextBlock.WrapFit,
    textAlign: 'left',
    font: '400 12pt Source Sans Pro, Arial',
    alignment: go.Spot.Left
  },
  boldTextStyle: {
    margin: 6,
    width: 300,
    wrap: go.TextBlock.WrapFit,
    textAlign: 'left',
    font: '400 14pt Source Sans Pro, Arial',
    alignment: go.Spot.Left,
    stroke: '#ffffff'
  }
}

class Diagram extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      myModel: null,
      myDiagram: null
    }
  }

  componentDidMount() {
    this.initDiagram()
  }

  // componentWillUpdate(nextProps, nextState) {
  //   const nextStateDiagramModel = {
  //     nodeDataArray: nextState.myModel.nodeDataArray,
  //     linkDataArray: nextState.myModel.linkDataArray
  //   }
  //   if (this.state.myModel &&
  //     JSON.stringify(this.props.diagramModel) !== JSON.stringify(nextProps.diagramModel) &&
  //     JSON.stringify(nextProps.diagramModel) !== JSON.stringify(nextStateDiagramModel)) {
  //     const model = nextState.myModel
  //     const diagram = nextState.myDiagram
  //     model.nodeDataArray = nextProps.diagramModel.nodeDataArray
  //     model.linkDataArray = nextProps.diagramModel.linkDataArray
  //     diagram.model = model
  //     if (this.props.diagramRef) {
  //       this.props.diagramRef(diagram)
  //     }
  //     this.setState({
  //       myDiagram: diagram,
  //       myModel: model
  //     }, () => { this.reformatDiagram() })
  //   }
  // }

  componentDidUpdate(prevProps, prevState) {

    const nextStateDiagramModel = {
      nodeDataArray: this.state.myModel.nodeDataArray,
      linkDataArray: this.state.myModel.linkDataArray
    }
    if (this.state.myModel &&
      JSON.stringify(prevProps.diagramModel) !== JSON.stringify(this.props.diagramModel) &&
      JSON.stringify(this.props.diagramModel) !== JSON.stringify(nextStateDiagramModel)) {
      const model = this.state.myModel
      const diagram = this.state.myDiagram
      model.nodeDataArray = this.props.diagramModel.nodeDataArray
      model.linkDataArray = this.props.diagramModel.linkDataArray
      diagram.model = model
      if (prevProps.diagramRef) {
        prevProps.diagramRef(diagram)
      }
      this.setState({
        myDiagram: diagram,
        myModel: model
      }, () => { this.reformatDiagram() })
    }

    if (prevProps.searchString !== this.props.searchString) {
      this.diagramSearch(this.props.searchString)
    }
  }

  onDiagramChange() {
    const { onChange } = this.props
    const { myModel } = this.state

    const newModel = { nodeDataArray: myModel.nodeDataArray, linkDataArray: myModel.linkDataArray }

    onChange(newModel)
  }

  diagramSearch(searchString) {
    let { myDiagram } = this.state
    myDiagram.startTransaction('highlight search')

    if (searchString) {
      searchString = searchString.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') // eslint-disable-line no-useless-escape
      let regex = new RegExp(searchString, 'i')
      let results = myDiagram.findNodesByExample({ text: regex }, { subText: regex })
      myDiagram.highlightCollection(results)
    } else {
      myDiagram.clearHighlighteds()
    }
    myDiagram.commitTransaction('highlight search')
  }

  initDiagram() {
    const { language } = this.context
    const {
      showErrorMessage, isForImport, displayContactCard, displayGroupCard, taskMenuItems, responseMenuItems,
      startNodeMenuItems, subProcessMenuItems, diagramMenuItems, onTaskDoubleClicked, onResponseDoubleClicked,
      onProcessDoubleClicked, onTaskSingleClicked, onResponseSingleClicked, onProcessSingleClicked, fastRender,
      onBackgroundSingleClicked, onStartNodeSingleClicked, disableEditing, isVertical, disableDragTree, canCopyPaste
    } = this.props
    const me = this
    /*
        function BackIgnoringLayeredDigraphLayout () {
          go.LayeredDigraphLayout.call(this)
        }
  
        go.Diagram.inherit(BackIgnoringLayeredDigraphLayout, go.LayeredDigraphLayout)
  
        BackIgnoringLayeredDigraphLayout.prototype.makeNetwork = function (coll) {
          let net = go.LayeredDigraphLayout.prototype.makeNetwork.call(this, coll)
          net.findAllParts().each(function (link) {
            if (!(link instanceof go.Link)) return
            if (link.data.loopBack) {
              net.deleteLink(link)
              link.routing = go.Link.AvoidsNodes
              link.curve = go.Link.None
            }
          })
          return net
        }
    */
    // ==================================================================================================================================================================
    // INITIALIZE AND SETUP THE DIAGRAM
    // ==================================================================================================================================================================
    const myDiagram =
      $(go.Diagram, this.refs.goJsTemplateDiv,
        {
          // ==================================================================================================================================================================
          // HAVE THE MOUSE WHEEEL EVENT ZOOM IN AND OUT INSTEAD OF SCROLLING THE DIAGRAM UP AND DOWN
          // =================================================================================================================================================================+
          'toolManager.mouseWheelBehavior': go.ToolManager.WheelZoom,
          'draggingTool.dragsTree': !disableDragTree,
          initialAutoScale: go.Diagram.Uniform,
          'linkingTool.direction': go.LinkingTool.ForwardsOnly,
          initialContentAlignment: go.Spot.Center,
          autoScrollRegion: 50,
          allowClipboard: canCopyPaste,
          allowCopy: canCopyPaste,
          allowDragOut: false,
          allowInsert: canCopyPaste,
          hasHorizontalScrollbar: false,
          hasVerticalScrollbar: false,
          padding: new go.Margin(300, 800, 300, 300),
          layout: $((fastRender) ? go.TreeLayout : go.LayeredDigraphLayout,
            {
              angle: (isVertical) ? 90 : 0,
              isOngoing: false,
              layerSpacing: 50
            }
          ),
          'undoManager.isEnabled': true
        })

    // ==================================================================================================================================================================
    // DISABLE THE MAGENTA SELECTING RECTANGLE SO WE CAN MOVE THE DIAGRAM AROUND WITH THE MOUSE INITIATING A SELECTION RECTANGLE.
    // ==================================================================================================================================================================
    myDiagram.toolManager.dragSelectingTool.isEnabled = false
    myDiagram.animationManager.isEnabled = false

    // ==================================================================================================================================================================
    // EVENT:  WE NEED TO FORCE A SAVE WHEN A PART IS CREATED.
    // ==================================================================================================================================================================
    myDiagram.addDiagramListener('PartCreated', (e) => {
      me.onDiagramChange()
    })

    myDiagram.addDiagramListener('ClipboardPasted', (e) => {
      let newNodes = e.diagram.selection.iterator
      let nodeCount = -1

      while (newNodes.next()) {
        let node = newNodes.value

        if (node.data.category === 'ResponseNodeTemplate' || node.data.category === 'TaskNodeTemplate' || node.data.category === 'ProcessNodeTemplate') {
          nodeCount++
          node.data.status = 'copy'
          node.location.x = (myDiagram.documentBounds.width < node.location.x) ? myDiagram.documentBounds.width + 400 * nodeCount : node.location.x + 50
          node.location.y = (myDiagram.documentBounds.height < node.location.y) ? myDiagram.documentBounds.height + 400 * nodeCount : node.location.y + 50

          myDiagram.model.setKeyForNodeData(node.data, `-${node.data.key}`)
        }
      }
    })

    // ==================================================================================================================================================================
    // EVENT:  WHEN DOUBLE CLICKING ON A NODE.  OPEN THE TASK EDITOR
    // ==================================================================================================================================================================
    myDiagram.addDiagramListener('ObjectDoubleClicked', (e) => {
      const clickedGraphObject = e.subject
      if (!clickedGraphObject) return false

      const parentPanelObject = clickedGraphObject.panel
      if (!parentPanelObject) return false

      const parentPartObject = parentPanelObject.part
      if (!parentPartObject) return false

      const nodeDataObject = parentPartObject.data
      if (!nodeDataObject) return false

      const nodeKeyString = nodeDataObject.key
      if (!nodeKeyString || nodeKeyString < 0) return false

      if (nodeDataObject.category === 'ProcessNodeTemplate' && typeof nodeKeyString === 'string') {
        if (nodeDataObject.isExternalProcess) {
          showErrorMessage(language.translate('application.youCannotOpenOtherTemplateAccounts'))
          return
        }
        if (nodeDataObject.templateID) {
          onProcessDoubleClicked(nodeDataObject)
        }
      }

      if (nodeDataObject.category === 'TaskNodeTemplate' && typeof nodeKeyString === 'string') {
        onTaskDoubleClicked(nodeDataObject)
      }

      if (nodeDataObject.category === 'ResponseNodeTemplate' && typeof nodeKeyString === 'string') {
        onResponseDoubleClicked(nodeDataObject)
      }
    })

    // ==================================================================================================================================================================
    // EVENT:  WHEN SINGLE CLICK ON PROFILE IMAGE, OPEN CONTACT CARD
    // ==================================================================================================================================================================
    myDiagram.addDiagramListener('ObjectSingleClicked', (e) => {
      const clickedGraphObject = e.subject
      if (clickedGraphObject === null) return false

      const parentPanelObject = clickedGraphObject.panel
      if (parentPanelObject === null) return false

      const parentPartObject = parentPanelObject.part
      if (parentPartObject === null) return false

      if (parentPartObject.category === 'ContextMenu') return false // if the click was triggered by a context menu, do nothing

      const nodeDataObject = parentPartObject.data
      if (nodeDataObject === null) return false

      const clickedElementObject = clickedGraphObject.element

      if (nodeDataObject.category === 'TaskNodeTemplate' && !clickedElementObject) {
        onTaskSingleClicked(nodeDataObject)
      }

      if (nodeDataObject.category === 'ResponseNodeTemplate') {
        onResponseSingleClicked(nodeDataObject)
      }

      if (nodeDataObject.category === 'ProcessNodeTemplate') {
        onProcessSingleClicked(nodeDataObject)
      }

      if (nodeDataObject.category === 'StartNodeTemplate') {
        onStartNodeSingleClicked(nodeDataObject)
      }

      if (!clickedElementObject) return false

      if (clickedElementObject.tagName !== 'IMG') return false

      if (clickedElementObject.src.indexOf('cloudinary') < 0) return false

      const userRecIdString = nodeDataObject.assignedToUserID
      const userGroupRecIdString = nodeDataObject.assignedToUserGroupID

      if (userRecIdString !== '') {
        displayContactCard(userRecIdString)
      } else if (userGroupRecIdString !== '') {
        displayGroupCard(userGroupRecIdString)
      }
    })

    // ==================================================================================================================================================================
    // EVENT:  WHEN SINGLE CLICKING ON THE BACKGROUND OF THE DIAGRAM
    // ==================================================================================================================================================================
    myDiagram.addDiagramListener('BackgroundSingleClicked', (e) => {
      onBackgroundSingleClicked(null, null)
    })

    // ==================================================================================================================================================================
    // EVENT:  HOW TO HANDLE DELETIONS WITHIN THE GRAPH
    // ==================================================================================================================================================================
    myDiagram.addDiagramListener('SelectionDeleting', (e) => {
      const selectedItemsToBeDeleted = e.subject
      const it = selectedItemsToBeDeleted.iterator
      while (it.next()) {
        const partVar = it.value
        if (partVar.data.category === 'StartNodeTemplate') {
          showErrorMessage(language.translate('application.processBeginningNodeCannotBeDeleted'))
          return false
        }
      }
      me.onDiagramChange()
    })

    // ==================================================================================================================================================================
    // EVENT:  a text box has been edited.  update the node's status property
    // ==================================================================================================================================================================
    myDiagram.addDiagramListener('TextEdited', (e) => {
      const editedtextbox = e.subject

      if (editedtextbox.text.length > 1500) {
        showErrorMessage(language.translate('application.nodeCharacterLimit', [editedtextbox.text.length]))
        return false
      }

      if (editedtextbox.text.trim() === '') {
        editedtextbox.text = language.translate('application.noTextEntered')
      }

      let containingpanel = editedtextbox.panel
      // ==================================================================================================================================================================
      // work up the node parent stack until we find the parent node with all the data definitions
      // ==================================================================================================================================================================
      while (containingpanel.data === null) {
        containingpanel = containingpanel.panel
      }
      // ==================================================================================================================================================================
      // capture the found data definitions
      // ==================================================================================================================================================================
      const editednodedata = containingpanel.data

      // ==================================================================================================================================================================
      // WE SHOULD NOT CHANGE THE STATUS OF NEW NODES. THE "NEW" STATUS IS WHAT TELLS THE CODE WE NEED TO SAVE THIS NODE AND GET A RECID FOR IT
      // IF THE CODE SEE THE STATUS AS "CHANGED" INSTEAD OF "NEW" IT WILL ASSUME WE ALREADY HAVE A RECID FOR IT AND WILL TRY TO "CHANGE" THE TEXT INSTEAD OF CREATE IT.
      // ==================================================================================================================================================================
      if (editednodedata.status !== 'new' && editednodedata.status !== 'copy') {
        // ==================================================================================================================================================================
        // MARKING THE NODE STATUS "CHANGED" TELLS THE CODE WE NEED TO UPDATE THE TEXT IN THE DATABASE THAT IS STORED WITHIN THIS NODE
        // ==================================================================================================================================================================
        const model = myDiagram.model
        model.startTransaction('change status')
        model.setDataProperty(editednodedata, 'status', 'changed')
        model.commitTransaction('change status')
      }

      me.onDiagramChange()
    })

    // ==================================================================================================================================================================
    // EVENT:  A LINK WAS DRAWN MAKE SURE IT'S VALID
    // ==================================================================================================================================================================
    myDiagram.addDiagramListener('LinkDrawn', (e) => {
      const newlink = e.subject
      const fromNodeCategory = newlink.fromNode.category
      const toNodeCategory = newlink.toNode.category

      // ==================================================================================================================================================================
      // WE CANNOT DRAW LINKS BETWEEN TWO NODES OF THE SAME TYPE (TASK TO TASK OR RESPONSE TO RESPONSE)
      // REMEMBER StartNodeTemplate IS ALSO A RESPONSE SO WE MUST INCLUDE IT IN THE CHECK ALSO
      // ==================================================================================================================================================================
      if ((fromNodeCategory === 'ResponseNodeTemplate' || fromNodeCategory === 'StartNodeTemplate') && (toNodeCategory === 'ResponseNodeTemplate' || toNodeCategory === 'StartNodeTemplate')) {
        myDiagram.remove(newlink)
        showErrorMessage(language.translate('application.youCannotLinkResponses'))
        return false
      } else if (fromNodeCategory === 'TaskNodeTemplate' && (toNodeCategory === 'TaskNodeTemplate' || toNodeCategory === 'ProcessNodeTemplate')) {
        myDiagram.remove(newlink)
        showErrorMessage(language.translate('application.youCannotLinkTasks'))
        return false
      } else if (fromNodeCategory === 'ProcessNodeTemplate' && (toNodeCategory === 'ResponseNodeTemplate' || toNodeCategory === 'StartNodeTemplate')) {
        myDiagram.remove(newlink)
        showErrorMessage(language.translate('application.youCannotLinkTasks'))
        return false
      }

      // ==================================================================================================================================================================
      // when we're finished drawing the link revert the node back to nonlinkable so it can be dragged around
      //  also center the diagram on the linked node
      // ==================================================================================================================================================================
      newlink.fromNode.fromLinkable = false
      me.reformatDiagram()
      me.onDiagramChange()
    })

    // ==================================================================================================================================================================
    // WHEN THE NODE IS FOCUSED THIS WILL DISPLAY THE ADD BUTTON
    // ==================================================================================================================================================================
    function addNodeAdornment(callback) {
      return ($(go.Adornment, 'Spot',
        $(go.Panel, 'Auto',
          $(go.Shape,
            {
              fill: null,
              stroke: 'dodgerblue',
              strokeWidth: 4
            }),
          $(go.Placeholder)),
        // ==================================================================================================================================================================
        // the button to create a "next" node, at the top-right corner
        // ==================================================================================================================================================================
        $('Button',
          {
            alignment: go.Spot.TopRight,
            click: (e, obj) => callback(obj)
          },
          new go.Binding('visible', '', a => !a.diagram.isReadOnly && !disableEditing).ofObject(),
          $(go.Shape, 'PlusLine',
            {
              strokeWidth: 4,
              desiredSize: new go.Size(35, 35)
            })
        )
      ))
    }

    // ===========================================================================================
    // SVG IMAGES DISPLAYED IN DIAGRAM NODES
    // ===========================================================================================
    const checklistSvg = 'F M21 7H9V5h12v2zM7 6c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm14 7H9v-2h12v2zM7 12c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm14 7H9v-2h12v2zM7 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2z'
    const paperclipSvg = 'M5.587,31.867c2.015,0,4.183-1.026,5.707-2.551l16.883-17.169c0.866-0.866,1.348-2.014,1.356-3.231   c0.009-1.22-0.459-2.364-1.316-3.222c-1.768-1.767-4.661-1.751-6.462,0.048L8.814,19.272c-0.203,0.213-0.196,0.552,0.018,0.756   c0.213,0.204,0.55,0.196,0.756-0.017L22.52,6.489c1.373-1.374,3.591-1.391,4.941-0.04c0.653,0.653,1.01,1.526,1.003,2.458   c-0.006,0.935-0.377,1.816-1.046,2.486L10.535,28.563c-1.776,1.775-5.426,3.385-7.615,1.194c-1.037-1.037-1.557-2.309-1.503-3.679   c0.053-1.36,0.693-2.72,1.807-3.833L22.52,2.661c1.026-1.026,2.629-1.62,4.396-1.626c0.011,0,0.021,0,0.032,0   c1.752,0,3.333,0.577,4.342,1.586c1.016,1.015,1.594,2.609,1.587,4.374c-0.007,1.767-0.601,3.369-1.634,4.402L16.348,26.882   c-0.205,0.213-0.198,0.551,0.015,0.756c0.212,0.204,0.551,0.198,0.755-0.015l14.888-15.478c1.225-1.224,1.932-3.1,1.939-5.146   c0.009-2.047-0.684-3.919-1.899-5.134c-1.208-1.208-3.064-1.9-5.098-1.9c-0.012,0-0.024,0-0.036,0   c-2.047,0.008-3.923,0.715-5.15,1.942L2.465,21.492c-2.701,2.701-2.827,6.495-0.301,9.021C3.111,31.459,4.318,31.867,5.587,31.867z'
    const starSvg = 'F M500,10C229.8,10,10,229.8,10,500c0,270.2,219.8,490,490,490s490-219.8,490-490C990,229.8,770.2,10,500,10z M725.9,809.2L499.6,691L274.2,809.2L317.4,558l-183-178.8l252.1-36.6l113.1-228.8l113.1,228.8l252.9,36.6L682.6,558L725.9,809.2z'
    const actionSvg = 'F M985.7,605.4l-88.6-26.2c0.4-8.2,1-16.3,1-24.4c0-217.4-149-406-354.2-448.3l-16.1,83.8C694.7,224.8,815.7,378,815.7,554.8v0.4l-80.2-23.7c-16.4,9.5-22.3,31.1-13.1,48.1l72.8,135.9c9.2,17,29.9,23.1,46.4,13.7l131-75.7C989,644,994.8,622.6,985.7,605.4L985.7,605.4z x F M92.4,537c0-144.9,81.6-274.3,203.2-335l37.7,102.1c18.5,8.5,40.3-0.1,48.5-19.3l65.9-153.3c8.1-19.3-0.1-41.8-18.7-50.4L281.2,12.7C262.6,4,241,12.8,232.7,32l33.3,90.2C113.3,195.7,10,356.5,10,537c0,50.7,8.3,101.4,24.9,150.4l77.7-28.1C99.2,619.2,92.4,578.1,92.4,537L92.4,537z x F M478.8,905.2c-64.4,0-127.2-16.2-182-49.9l69.4-73.2c-0.2-21.1-16.7-36.8-37.1-36.6H167.4c-20.4,0.1-36.7,17.3-36.5,38.5l1.1,168c0.2,21.1,16.7,38.1,37.2,37.8l68.7-72.4c71.2,48.5,154.7,73.3,240.9,73.3c124,0,243-55.1,326.7-151.1l-61.1-57.3C676.2,860.4,579.4,905.2,478.8,905.2L478.8,905.2z'
    const noSymbolSvg = 'F M150,0A150 150 0 1 0 150,300A150 150 0 1 0 150,0Z       M75.586,54.102A121.429 121.429 0 0 1 150,28.571A121.429 121.429 0 0 1 271.429,150A121.429 121.429 0 0 1 245.898,224.414Z       M224.414,245.898A121.429 121.429 0 0 1 150,271.429A121.429 121.429 0 0 1 28.571,150A121.429 121.429 0 0 1 54.102,75.586Z'
    const calendarSvg = 'F M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z'
    const publicSvg = 'F M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z'

    // ==================================================================================================================================================================
    // NODE TEMPLATE FOR ALL NODES
    // ==================================================================================================================================================================
    function getNodeTemplate(type, adornmentCallback, contextMenu) {
      return (
        $(go.Node, 'Horizontal',
          new go.Binding('location', 'loc', go.Point.parse).makeTwoWay(go.Point.stringify),
          new go.Binding('deletable', '', () => !(type === 'startNode')),
          {
            portId: '',
            toSpot: go.Spot.Left,
            fromSpot: go.Spot.Right,
            toEndSegmentLength: 60,
            fromEndSegmentLength: 40,
            alignment: go.Spot.Left,
            fromLinkable: false,
            toLinkable: true,
            cursor: 'pointer',
            selectionObjectName: 'BODY',
            selectionAdornmentTemplate: addNodeAdornment(adornmentCallback),
            isShadowed: true,
            shadowBlur: 1,
            shadowColor: '#666666',
            shadowOffset: new go.Point(0, 1)
          },
          {
            // ==================================================================================================================================================================
            // DEFINE THE CONTEXT MENU FOR THE TEXT NODE
            // ==================================================================================================================================================================
            contextMenu: (!isForImport)
              ? contextMenu : null// end Adornment
          },
          // ==================================================================================================================================================================
          // THE RoundedRectangle PANEL THAT WILL HOLD THE TASK TEXT
          // ==================================================================================================================================================================
          $(go.Panel, 'Auto', // task body panel
            {
              name: 'BODY',
              alignment: go.Spot.Left
            }
            , $(go.Shape, (type === 'process') ? 'Subroutine' : (type === 'startNode') ? 'Ellipse' : 'RoundedRectangle'
              , {
                stroke: (type === 'process') ? 'black' : null
              }
              , new go.Binding('fill', 'backgroundColor')
              , new go.Binding('stroke', 'isHighlighted', (h, node) => {
                return h ? 'red' : (node.part.data.category === 'ProcessNodeTemplate') ? 'black' : null
              }).ofObject()
              , new go.Binding('strokeWidth', 'isHighlighted', (h) => { return h ? 10 : 1 }).ofObject()
            )
            , $(go.Panel, 'Horizontal',
              {
                alignment: go.Spot.Right,
                padding: (type === 'startNode') ? new go.Margin(10, 10, 10, 0) : new go.Margin(10, 30, 10, 10)
              }
              // Render an SVG icon if one is present on the node
              , $(go.Panel, 'Viewbox'
                , new go.Binding('visible', 'svgIcon', (svg) => !!(svg))
                , new go.Binding('desiredSize', 'svgIcon', (svg) => (svg ? new go.Size(64, 64) : new go.Size(0, 0)))
                , { margin: new go.Margin(0, 10, 0, 0) }
                , $(go.Panel, 'Position'
                  , new go.Binding('itemArray', 'svgIcon', (svgIcon) => { return svgIcon || [] })
                  , {
                    itemTemplate: $(go.Panel, 'Auto'
                      , $(go.Shape,
                        {
                          strokeWidth: 0
                        }
                        , new go.Binding('geometry', 'path', (path) => (go.Geometry.parse(path, true)))
                        , new go.Binding('fill', 'fill')
                        , new go.Binding('stroke', 'stroke')
                        , new go.Binding('strokeWidth', 'strokeWidth')
                        , new go.Binding('strokeLinejoin', 'strokeLinejoin')
                        , new go.Binding('strokeLinecap', 'strokeLinecap')
                        , new go.Binding('strokeMiterlimit', 'strokeMiterlimit')
                      )
                    )
                  }
                )
              )

              , $(go.Panel, 'Auto',
                {
                  alignment: go.Spot.TopCenter,
                  visible: false
                }
                , new go.Binding('visible', 'profilePictureURL', (img) => !!(img)) // DISPLAY PROFILE PIC
                , $(go.Picture
                  , {
                    source: '',
                    width: 64,
                    height: 64,
                    background: null,
                    margin: 6
                  }
                  , { sourceCrossOrigin: function (pict) { return 'anonymous' } }
                  , new go.Binding('source', 'profilePictureURL') // PROFILE PICTURE SOURCE
                  , {
                    mouseHover(e, obj) {
                      const clickedElementObject = obj.element
                      if (clickedElementObject === null) return false

                      if (clickedElementObject.tagName !== 'IMG') return false

                      if (clickedElementObject.src.indexOf('cloudinary') < 0) return false

                      const parentPartObject = obj.part
                      if (parentPartObject === null) return false

                      const nodeDataObject = parentPartObject.data
                      if (nodeDataObject === null) return false

                      const userRecIdString = nodeDataObject.assignedToUserID
                      const userGroupRecIdString = nodeDataObject.assignedToUserGroupID

                      if (userRecIdString !== '') {
                        displayContactCard(userRecIdString)
                      } else if (userGroupRecIdString !== '') {
                        displayGroupCard(userGroupRecIdString)
                      }
                    }
                  }
                )
              )
              , $(go.Panel, 'Vertical', // task text build out panel
                {
                  alignment: go.Spot.TopLeft
                }
                , $(go.TextBlock // task text
                  , ''
                  , styles.boldTextStyle
                  , new go.Binding('textAlign', '', () => (type === 'startNode') ? 'center' : 'left')
                  , new go.Binding('stroke', 'foregroundColor')
                  , new go.Binding('editable', 'isEditable', (isEditable) => (isEditable && type !== 'process'))
                  , new go.Binding('text', 'text').makeTwoWay()
                )
                , $(go.TextBlock
                  , ''
                  , styles.normalTextStyle
                  , {
                    editable: false
                  }
                  , new go.Binding('stroke', 'foregroundColor')
                  , new go.Binding('text', 'subText')
                  , new go.Binding('visible', '', (node) => !!(node.subText && node.subText !== ''))
                )
                , $(go.Panel, 'Horizontal',
                  {
                    alignment: go.Spot.Right
                  }
                  , $(go.Shape
                    , {
                      geometryString: starSvg,
                      alignment: go.Spot.Right,
                      height: 18,
                      width: 18,
                      visible: false,
                      margin: new go.Margin(0, 6, 0, 6)
                    }
                    , new go.Binding('stroke', 'foregroundColor')
                    , new go.Binding('fill', 'foregroundColor')
                    , new go.Binding('visible', 'milestoneExists') // SHOW MILESTONE ICON
                  )
                  , $(go.Shape
                    , {
                      geometryString: checklistSvg,
                      alignment: go.Spot.Right,
                      height: 18,
                      width: 18,
                      visible: false,
                      margin: new go.Margin(0, 6, 0, 6)
                    }
                    , new go.Binding('stroke', 'foregroundColor')
                    , new go.Binding('fill', 'foregroundColor')
                    , new go.Binding('visible', 'checklistExists') // SHOW CHECKLIST ICON
                  )
                  , $(go.Shape
                    , {
                      geometryString: paperclipSvg,
                      alignment: go.Spot.Right,
                      height: 18,
                      width: 18,
                      visible: false,
                      margin: new go.Margin(0, 6, 0, 6)
                    }
                    , new go.Binding('stroke', 'foregroundColor')
                    , new go.Binding('fill', 'foregroundColor')
                    , new go.Binding('visible', 'attachmentExists') // SHOW ATTACHMENT ICON
                  )
                  , $(go.Shape
                    , {
                      geometryString: actionSvg,
                      alignment: go.Spot.Right,
                      height: 18,
                      width: 18,
                      visible: false,
                      margin: new go.Margin(0, 6, 0, 6)
                    }
                    , new go.Binding('stroke', 'foregroundColor')
                    , new go.Binding('fill', 'foregroundColor')
                    , new go.Binding('visible', 'automatedActionExists') // SHOW ACTION ICON
                  )
                  , $(go.Shape
                    , {
                      geometryString: calendarSvg,
                      alignment: go.Spot.Right,
                      height: 18,
                      width: 18,
                      visible: false,
                      margin: new go.Margin(0, 6, 0, 6)
                    }
                    , new go.Binding('stroke', 'foregroundColor')
                    , new go.Binding('fill', 'foregroundColor')
                    , new go.Binding('visible', 'dateExists') // SHOW CALENDAR ICON
                  )
                  , $(go.Shape
                    , {
                      geometryString: publicSvg,
                      alignment: go.Spot.Right,
                      height: 18,
                      width: 18,
                      visible: false,
                      margin: new go.Margin(0, 6, 0, 6)
                    }
                    , new go.Binding('stroke', 'foregroundColor')
                    , new go.Binding('fill', 'foregroundColor')
                    , new go.Binding('visible', 'publicTask') // SHOW PUBLIC ICON
                  )
                  , $(go.Shape
                    , {
                      geometryString: noSymbolSvg,
                      alignment: go.Spot.Right,
                      height: 16,
                      width: 16,
                      visible: false,
                      stroke: '#f00',
                      fill: '#f00'
                    }
                    , new go.Binding('visible', 'stopicon')
                  )
                )
              )
            )
          ) // end task body panel
        ) // end node
      )
    }

    // ==================================================================================================================================================================
    // NODE TEMPLATE FOR TASKS
    // ==================================================================================================================================================================
    taskMenuItems.push({
      label: language.translate('application.addResponse'),
      action: this.addResponseNodeAndLink.bind(this),
      disableNodeExtract: true,
      shouldDisplay: () => (!disableEditing)
    })

    taskMenuItems.push({
      label: language.translate('application.drawPathExistingResponse'),
      action: this.enableDrawLink.bind(this),
      disableNodeExtract: true,
      shouldDisplay: () => (!disableEditing)
    })

    taskMenuItems.push({
      label: language.translate('application.deleteTask'),
      action: this.deleteSelectedNode.bind(this),
      disableNodeExtract: true,
      shouldDisplay: () => (!disableEditing)
    })

    myDiagram.nodeTemplateMap.add('TaskNodeTemplate'
      , getNodeTemplate('task', this.addResponseNodeAndLink.bind(this)
        , $(go.Adornment, 'Vertical',
          me.prepareContextMenuItems(taskMenuItems)
        )
      )
    ) // end template map

    // ==================================================================================================================================================================
    // NODE TEMPLATE FOR RESPONSES
    // ==================================================================================================================================================================
    responseMenuItems.push({
      label: language.translate('application.addFollowupTask'),
      action: this.addTaskNodeAndLink.bind(this),
      disableNodeExtract: true,
      shouldDisplay: () => (!disableEditing)
    })

    responseMenuItems.push({
      label: language.translate('application.drawPathExistingTask'),
      action: this.enableDrawLink.bind(this),
      disableNodeExtract: true,
      shouldDisplay: () => (!disableEditing)
    })

    responseMenuItems.push({
      label: language.translate('application.deleteResponse'),
      action: this.deleteSelectedNode.bind(this),
      disableNodeExtract: true,
      shouldDisplay: () => (!disableEditing)
    })

    myDiagram.nodeTemplateMap.add('ResponseNodeTemplate',
      getNodeTemplate('response', this.addTaskNodeAndLink.bind(this)
        , $(go.Adornment, 'Vertical'
          , me.prepareContextMenuItems(responseMenuItems)
        )
      )
    )

    // ==================================================================================================================================================================
    // NODE TEMPLATE FOR THE FIRST START NODE
    // ==================================================================================================================================================================
    startNodeMenuItems.unshift({
      label: language.translate('application.drawPathExistingTask'),
      action: this.enableDrawLink.bind(this),
      disableNodeExtract: true,
      shouldDisplay: () => (!disableEditing)
    })

    startNodeMenuItems.unshift({
      label: language.translate('application.addFollowupTask'),
      action: this.addTaskNodeAndLink.bind(this),
      disableNodeExtract: true,
      shouldDisplay: () => (!disableEditing)
    })

    myDiagram.nodeTemplateMap.add('StartNodeTemplate',
      getNodeTemplate('startNode', this.addTaskNodeAndLink.bind(this)
        , $(go.Adornment, 'Vertical'
          , me.prepareContextMenuItems(startNodeMenuItems)
        )
      )
    )

    // ==================================================================================================================================================================
    // THE NODE TEMPLATE FOR A LINKED EXTERNAL PROCESS NODE
    // ==================================================================================================================================================================
    subProcessMenuItems.unshift({
      label: language.translate('application.drawPathExistingTask'),
      action: this.enableDrawLink.bind(this),
      disableNodeExtract: true,
      shouldDisplay: () => (!disableEditing)
    })

    subProcessMenuItems.unshift({
      label: language.translate('application.addFollowupTask'),
      action: this.addTaskNodeAndLink.bind(this),
      disableNodeExtract: true,
      shouldDisplay: () => (!disableEditing)
    })

    myDiagram.nodeTemplateMap.add('ProcessNodeTemplate',
      getNodeTemplate('process', this.addTaskNodeAndLink.bind(this)
        , $(go.Adornment, 'Vertical'
          , me.prepareContextMenuItems(subProcessMenuItems)
        )
      )
    )

    // ==================================================================================================================================================================
    //   DEFINE THE CONTEXT MENU FOR THE BACKGROUND
    // ==================================================================================================================================================================
    diagramMenuItems.push({
      label: language.translate('application.formatDiagram'),
      action: this.reformatDiagram.bind(this)
    })

    diagramMenuItems.push({
      label: language.translate('application.downloadDiagramImage'),
      action: this.downloadDiagramImage.bind(this)
    })

    myDiagram.contextMenu = (!isForImport)
      ? $(go.Adornment, 'Vertical'
        , me.prepareContextMenuItems(diagramMenuItems)
      ) : null

    // ==================================================================================================================================================================
    // replace the default Link template in the linkTemplateMap.  THIS TEMPLATE WILL BE USED BY ALL LINKS IN THE SYSTEM
    // ==================================================================================================================================================================
    myDiagram.linkTemplate =
      $(go.Link, // the whole link panel
        new go.Binding('points').makeTwoWay(),
        {
          curve: (fastRender) ? null : go.Link.Bezier,
          toShortLength: (fastRender) ? null : 15,
          toEndSegmentLength: (fastRender) ? 25 : 'NaN',
          isShadowed: false,
          layerName: 'Background'
        },
        new go.Binding('fromEndSegmentLength', '', (linkData) => {
          if (linkData.loopBack) { return 25 }
          if (fastRender) { return 2 } else { return 'NaN' }
        }),
        new go.Binding('curviness', 'curviness')
        , $(go.Shape, // the link shape
          {
            isPanelMain: true,
            stroke: '#303435',
            strokeWidth: 2.5
          })
        , $(go.Shape, // the arrowhead
          {
            toArrow: 'Triangle',
            fill: '#303435',
            stroke: null,
            scale: 2
          })
      )

    const model = myDiagram.model

    model.nodeDataArray = this.props.diagramModel.nodeDataArray
    model.linkDataArray = this.props.diagramModel.linkDataArray
    myDiagram.model = model
    if (this.props.diagramRef) {
      this.props.diagramRef(myDiagram)
    }
    this.setState({ myModel: model, myDiagram })
  }

  prepareContextMenuItems(menuArray) {
    return (menuArray.map((menuItem) => {
      return (
        $('ContextMenuButton',
          $(go.TextBlock,
            menuItem.label,
            styles.contextMenuTextStyle,
            new go.Binding('stroke', '', (data) => {
              if (menuItem.disabled && menuItem.disabled(data)) { return '#aaaaaa' } else { return '#000000' }
            })
          ),
          {
            click: (e, obj) => {
              if (menuItem.disabled && menuItem.disabled(obj.part.data)) {
                return
              }
              this.handleContextMenuClick(obj, menuItem.action, menuItem.validateSave, menuItem.disableNodeExtract)
            }
          },
          new go.Binding('visible', '', (data) => {
            if (menuItem.shouldDisplay) { return menuItem.shouldDisplay(data) } else { return true }
          })
        )
      )
    }))
  }

  // ==================================================================================================================================================================
  // ENABLE A NODE TO ALLOW US TO ESTABLISH LINKS FROM IT TO ANOTHER NODE
  // ==================================================================================================================================================================
  enableDrawLink(obj) {
    const myDiagram = this.state.myDiagram

    myDiagram.startTransaction('change fromlinkable')
    const nodedata = this.extractNodeData(obj)
    const node = myDiagram.findNodeForData(nodedata)
    const tool = myDiagram.toolManager.linkingTool
    tool.startObject = node
    myDiagram.currentTool = tool
    node.fromLinkable = true
    tool.doActivate()
    myDiagram.commitTransaction('change fromlinkable')
  }

  deleteSelectedNode(obj) {
    const myDiagram = this.state.myDiagram

    myDiagram.startTransaction('delete node')
    const nodedata = this.extractNodeData(obj)
    const node = myDiagram.findNodeForData(nodedata)
    myDiagram.remove(node)
    myDiagram.commitTransaction('delete node')
  }

  handleContextMenuClick(obj, callback, validateSave = false, disableNodeExtract = false) {
    const { language } = this.context
    const { showErrorMessage } = this.props
    const nodeData = this.extractNodeData(obj)

    if (typeof nodeData.key === 'string' || !validateSave) {
      if (!disableNodeExtract) {
        callback(nodeData)
      } else {
        callback(obj)
      }
    } else {
      showErrorMessage(language.translate('application.youMustSaveYourChangesFirst'))
      return false
    }
  }

  extractNodeData(obj) {
    // ==================================================================================================================================================================
    // get the context menu that holds the button that was clicked
    // ==================================================================================================================================================================
    const contextmenu = obj.part
    // ==================================================================================================================================================================
    // get the node data to which the Node is data bound
    // ==================================================================================================================================================================
    return contextmenu.data
  }

  reformatDiagram() {
    const { myDiagram } = this.state
    const { disableReformatValidation } = this.props

    // iterate over all the nodes and their links to check for broken links,
    // if there are any broken links, do not reformat
    let isBroken = false
    let nodeIndex = 0
    for (let it = myDiagram.nodes; it.next();) {
      let n = it.value

      let linkCount = 0
      for (let links = n.findNodesInto(); links.next();) {
        linkCount++
      }

      if (linkCount === 0 && nodeIndex > 0) {
        isBroken = true
      }

      nodeIndex++
    }

    if (!isBroken || disableReformatValidation) {
      myDiagram.layout.isValidLayout = false
      this.setState({ myDiagram })
    }
  }

  downloadDiagramImage() {
    const { myDiagram } = this.state
    const { downloadDiagramImage } = this.props

    const svg = myDiagram.makeSvg({
      scale: 1
    })

    downloadDiagramImage(svg)
  }

  // ==================================================================================================================================================================
  // THIS FUNCTION ADDS A NEW TASK NODE AND COORESPONDING RESPONSE NODE AND GENERATES THE LINKS TO LINK THEM TOGETHER
  // ==================================================================================================================================================================
  addTaskNodeAndLink(obj, taskText) {
    const { language } = this.context

    const adorn = obj.part
    if (adorn === null) return
    //e.handled = true
    const diagram = adorn.diagram
    const model = diagram.model

    diagram.startTransaction('Add Task and Response Nodes')
    // ==================================================================================================================================================================
    // get the response node data for which the user clicked the add task button (adornment)
    // ==================================================================================================================================================================
    const fromResponseNode = adorn.adornedPart
    const fromResponseData = fromResponseNode.data

    // ==================================================================================================================================================================
    // THIS IS THE DATA FOR THE NEW TASK NODE WE WILL ADD OUT TO THE RIGHT OF THE ADORNED RESPONSE NODE
    // ==================================================================================================================================================================
    const toTaskData = {
      category: 'TaskNodeTemplate',
      text: taskText || `${language.translate('application.newTask')}\n\n${language.translate('application.doubleClickToEdit')}`,
      status: 'new',
      isEditable: true,
      backgroundColor: '#227eae'
    }
    // ==================================================================================================================================================================
    // DETERMINE WHERE WE WANT TO PUT THE NEW TASK NODE WE CREATE.  WE WANT TO THROW IT OUT TO THE RIGHT OF THE RESPONSE NODE
    // ==================================================================================================================================================================
    const fromResponseNodeLoc = fromResponseNode.location
    const newTaskLocationXcoord = fromResponseNodeLoc.x + fromResponseNode.part.actualBounds.width + 100
    toTaskData.loc = `${newTaskLocationXcoord} ${fromResponseNodeLoc.y}` // the "loc" property is a string, not a Point object
    // ==================================================================================================================================================================
    // ADD THE TASK NODE WE CREATED TO THE DIAGRAM MODEL
    // ==================================================================================================================================================================
    model.addNodeData(toTaskData)
    // ==================================================================================================================================================================
    // NOW WE'RE GOING TO CREATE A LINK FROM THE ORIGINATING RESPONSE NODE TO THE NEWLY CREATED TASK NODE
    // ==================================================================================================================================================================
    let linkdata = {}
    linkdata[model.linkFromKeyProperty] = model.getKeyForNodeData(fromResponseData)
    linkdata[model.linkToKeyProperty] = model.getKeyForNodeData(toTaskData)
    // ==================================================================================================================================================================
    // ADD THE RESPONSE TO TASK LINK TO THE DIAGRAM MODEL
    // ==================================================================================================================================================================
    model.addLinkData(linkdata)

    // ==================================================================================================================================================================
    // THIS IS THE DATA FOR THE NEW RESPONSE NODE THAT MUST BE APPENDED TO THE NEW TASK NODE
    // TASKS CANNOT EXIST WITHOUT A RESPONSE SO WE GO AHEAD AND CREATE A RESPONSE FOR THE USER WHEN THEY ADD A TASK
    // ==================================================================================================================================================================
    const toResponseData = {
      category: 'ResponseNodeTemplate',
      text: language.translate('application.complete'),
      status: 'new',
      isEditable: true,
      backgroundColor: '#8a8a8a'
    }
    // ==================================================================================================================================================================
    // DETERMINE THE LOCATION FOR THE NEWLY CREATED RESPONSE NODE.  THROW IT OUT A LITTLE FURTHER THAN THE TASK NODE.
    // ==================================================================================================================================================================
    toResponseData.loc = `${newTaskLocationXcoord + 350} ${fromResponseNodeLoc.y}` // the "loc" property is a string, not a Point object
    model.addNodeData(toResponseData)
    // ==================================================================================================================================================================
    // NOW WE CREATE THE LINK FROM THE NEWLY CREATED TASK NODE TO IT'S NEWLY CREATED COORESPONDING RESPONSE NODE
    // ==================================================================================================================================================================
    linkdata = {}
    linkdata[model.linkFromKeyProperty] = model.getKeyForNodeData(toTaskData)
    linkdata[model.linkToKeyProperty] = model.getKeyForNodeData(toResponseData)
    // ==================================================================================================================================================================
    // ADD THE LINK TO THE DIAGRAM MODEL
    // ==================================================================================================================================================================
    model.addLinkData(linkdata)

    // ==================================================================================================================================================================
    // FIND THE NEWLY CREATED TASK NODE SO WE CAN SELECT IT AND CENTER IT IN THE UI
    // ==================================================================================================================================================================
    const newTaskNode = diagram.findNodeForData(toTaskData)
    diagram.select(newTaskNode)
    diagram.commitTransaction('Add Task and Response Nodes')
    // ==================================================================================================================================================================
    // CENTER THE NEWLY CREATED TASK NODE IN THE UI
    // ==================================================================================================================================================================
    const rectCoord = `${newTaskNode.position.x} ${newTaskNode.position.y} 10 10 `
    const centerOnRect = go.Rect.parse(rectCoord)
    diagram.centerRect(centerOnRect)
    this.onDiagramChange()

    // ==================================================================================================================================================================
    // AUTOMATICALLY PUT THE NEW TASK IN TEXT EDIT MODE
    // ==================================================================================================================================================================
    //let textBox = newTaskNode.findObject('TEXT')
    //diagram.commandHandler.editTextBlock(textBox)
  }

  // ==================================================================================================================================================================
  // THIS FUNCTION ADDS A NEW RESPONSE NODE AND GENERATES THE LINK FROM THE ORGINATING TASK
  // ==================================================================================================================================================================
  addResponseNodeAndLink(obj) {
    const { language } = this.context
    const adorn = obj.part
    if (adorn === null) return
    //e.handled = true
    const diagram = adorn.diagram
    const model = diagram.model
    diagram.startTransaction('Add Response Node')
    // ==================================================================================================================================================================
    // get the response node data for which the user clicked the add task button (adornment)
    // ==================================================================================================================================================================
    const fromTaskNode = adorn.adornedPart
    const fromTaskData = fromTaskNode.data

    // ==================================================================================================================================================================
    // THIS IS THE DATA FOR THE NEW RESPONSE NODE WE WILL ADD OUT TO THE RIGHT OF THE ADORNED TASK NODE
    // ==================================================================================================================================================================
    const toResponseData = {
      category: 'ResponseNodeTemplate',
      text: `${language.translate('application.newResponse')}\n\n${language.translate('application.doubleClickToEdit')}`,
      status: 'new',
      isEditable: true,
      backgroundColor: '#8a8a8a'
    }
    // ==================================================================================================================================================================
    // DETERMINE WHERE WE WANT TO PUT THE NEW RESPONSE NODE WE CREATE.  WE WANT TO THROW IT OUT TO THE RIGHT OF THE TASK NODE
    // ==================================================================================================================================================================
    const fromTaskNodeLoc = fromTaskNode.location
    const newLocationXcoord = fromTaskNodeLoc.x + fromTaskNode.part.actualBounds.width + 100
    toResponseData.loc = `${newLocationXcoord} ${fromTaskNodeLoc.y}` // the "loc" property is a string, not a Point object
    // ==================================================================================================================================================================
    // ADD THE TASK NODE WE CREATED TO THE DIAGRAM MODEL
    // ==================================================================================================================================================================
    model.addNodeData(toResponseData)
    // ==================================================================================================================================================================
    // NOW WE'RE GOING TO CREATE A LINK FROM THE ORIGINATING TASK NODE TO THE NEWLY CREATED RESPONSE NODE
    // ==================================================================================================================================================================
    const linkdata = {}
    linkdata[model.linkFromKeyProperty] = model.getKeyForNodeData(fromTaskData)
    linkdata[model.linkToKeyProperty] = model.getKeyForNodeData(toResponseData)
    // ==================================================================================================================================================================
    // ADD THE TASK TO RESPONSE LINK TO THE DIAGRAM MODEL
    // ==================================================================================================================================================================
    model.addLinkData(linkdata)

    // ==================================================================================================================================================================
    // FIND THE NEWLY CREATED TASK NODE SO WE CAN SELECT IT AND CENTER IT IN THE UI
    // ==================================================================================================================================================================
    const newResponseNode = diagram.findNodeForData(toResponseData)
    diagram.select(newResponseNode)
    diagram.commitTransaction('Add Response Node')
    // ==================================================================================================================================================================
    // CENTER THE NEWLY CREATED RESPONSE NODE IN THE UI
    // ==================================================================================================================================================================
    const rectCoord = `${newResponseNode.position.x} ${newResponseNode.position.y} 10 10 `
    const centerOnRect = go.Rect.parse(rectCoord)
    diagram.centerRect(centerOnRect)
    this.onDiagramChange()

    // ==================================================================================================================================================================
    // AUTOMATICALLY PUT THE NEW RESPONSE IN TEXT EDIT MODE
    // ==================================================================================================================================================================
    //let textBox = newResponseNode.findObject('TEXT')
    //diagram.commandHandler.editTextBlock(textBox)
  }

  // ==================================================================================================================================================================
  // LAYOUT OR RE-LAYOUT THE DIAGRAM SO ALL THE NODES AND LINKS REROUT AND LOOK PRETTY
  // ==================================================================================================================================================================
  layout() {
    const myDiagram = this.state.myDiagram
    myDiagram.layoutDiagram(true)
  }

  render() {
    return (
      <div
        ref='goJsTemplateDiv'
        className='goJsCanvas goJsCanvas2'
        style={{
          height: 'calc(100% - 80px)',
          background: 'transparent',
          overflow: 'hidden',
          margin: '5px'
        }}
      />
    )
  }
}

Diagram.propTypes = {
  diagramModel: PropTypes.object,
  diagramNeedsToBeSaved: PropTypes.bool,
  disableEditing: PropTypes.bool,
  diagramType: PropTypes.oneOf(['template', 'instance']),
  onChange: PropTypes.func,
  showErrorMessage: PropTypes.func,
  displayContactCard: PropTypes.func,
  displayGroupCard: PropTypes.func,
  downloadDiagramImage: PropTypes.func,
  isForImport: PropTypes.bool,
  isVertical: PropTypes.bool,
  disableDragTree: PropTypes.bool,
  taskMenuItems: PropTypes.array,
  responseMenuItems: PropTypes.array,
  startNodeMenuItems: PropTypes.array,
  subProcessMenuItems: PropTypes.array,
  diagramMenuItems: PropTypes.array,
  onTaskDoubleClicked: PropTypes.func,
  onResponseDoubleClicked: PropTypes.func,
  onProcessDoubleClicked: PropTypes.func,
  onTaskSingleClicked: PropTypes.func,
  onResponseSingleClicked: PropTypes.func,
  onProcessSingleClicked: PropTypes.func,
  onBackgroundSingleClicked: PropTypes.func,
  onStartNodeSingleClicked: PropTypes.func,
  disableReformatValidation: PropTypes.bool,
  diagramRef: PropTypes.func,
  canCopyPaste: PropTypes.bool,
  fastRender: PropTypes.bool,
  searchString: PropTypes.string
}

Diagram.defaultProps = {
  disableEditing: false,
  isVertical: false,
  disableDragTree: false,
  canCopyPaste: false,
  fastRender: false,
  taskMenuItems: [],
  responseMenuItems: [],
  startNodeMenuItems: [],
  subProcessMenuItems: [],
  diagramMenuItems: [],
  onTaskDoubleClicked: () => { },
  onResponseDoubleClicked: () => { },
  onProcessDoubleClicked: () => { },
  onTaskSingleClicked: () => { },
  onResponseSingleClicked: () => { },
  onProcessSingleClicked: () => { },
  onBackgroundSingleClicked: () => { },
  onStartNodeSingleClicked: () => { },
  displayContactCard: () => { },
  displayGroupCard: () => { }
}

Diagram.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object,
  user: PropTypes.object
}

export default Diagram
