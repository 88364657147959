import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get, post } from '~/actions/base'
import { showUndoMessage } from '~/actions/snackbar'
import { goBack, push } from 'react-router-redux'
import GroupUserSelect from '../Forms/GroupUserSelect'
import { Button, Card, CircularProgress, FormControlLabel, Switch } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { MenuItem } from 'material-ui'
import { CheckCircle, Error } from '@material-ui/icons'
import RightPanelContent from '../Layout/RightPanelContent'
import { FormsyText, FormsySelect, FormsyTime } from 'formsy-material-ui'
import { Form } from 'formsy-react'
import objectAssign from 'object-assign'
import moment from 'moment'
import TreeView from '../Layout/TreeView/TreeView'

const styles = theme => ({
  label: {
    fontSize: '16px',
    color: theme.palette.accent3Color
  }
})

const initialState = {
  externalDataSource: {},
  isLoaded: false,
  canSubmit: true,
  timeZonesLoaded: false,
  timeZones: [],
  testingConnection: false,
  connectionTestPassed: null,
  testingQuery: false,
  queryTestPassed: null,
  queryResults: null,
  templates: [],
  templatesLoaded: false,
  templatesLoading: false
}

class ExternalDataSourcePanel extends Component {
  constructor (props, context) {
    super(props, context)

    this.state = initialState
  }

  componentDidMount () {
    this.fetchExternalDataSource()
    this.fetchTimeZones()
  }

  componentDidUpdate (prevProps, prevState) {
    if (!prevState.externalDataSource.ID && this.state.externalDataSource.ID) {
      this.props.push({
        pathname: this.context.location.pathname,
        query: objectAssign({},
          this.context.location.query,
          {
            dsid: this.state.externalDataSource.ID
          })
      })
    }

    const { externalDataSource, templatesLoaded, templatesLoading } = this.state

    if (externalDataSource.Purpose && externalDataSource.Purpose === 'Launch' && !templatesLoaded && !templatesLoading) { this.fetchTemplates() }

    if (prevProps.dataSourceId && this.props.dataSourceId && prevProps.dataSourceId !== this.props.dataSourceId) { this.setState(initialState, () => this.fetchExternalDataSource()) }
  }

  generateNewExternalDataSource () {
    const { location, location: { query: { pid } } } = this.context

    const type = (pid) ? 'Launch' : location.pathname.split('/').slice(-1)[0]

    const externalDataSource = {
      Purpose: (type === 'dropDownLists') ? 'DDL' : (type === 'automatedActions') ? 'Action' : 'Launch',
      IsLive: true,
      ProcessTemplateID: pid || null
    }

    this.setState({ externalDataSource, isLoaded: true })
  }

  fetchExternalDataSource () {
    const { dataSourceId } = this.props
    const { location } = this.context

    this.setState({ isLoaded: false })

    if (!dataSourceId) {
      this.generateNewExternalDataSource()
      return false
    }

    this.props.get(`externaldatasource/${dataSourceId}`, {
      onSuccess: (response) => {
        if (!response.ExternalDataSource.ProcessTemplateID && location.query.pid) { response.ExternalDataSource.ProcessTemplateID = location.query.pid }

        this.setState({
          externalDataSource: response.ExternalDataSource,
          isLoaded: true
        })
      }
    })
  }

  saveExternalDataSource () {
    const { externalDataSource, canSubmit } = this.state
    const { post } = this.props

    if (!canSubmit) { return false }

    const body = JSON.stringify({
      ExternalDataSource: externalDataSource
    })

    const endpoint = (externalDataSource.ID) ? `externaldatasource/${externalDataSource.ID}` : 'externaldatasource'

    post(endpoint, body, {
      onSuccess: (response) => {
        this.setState({
          externalDataSource: response.ExternalDataSource
        })
      }
    })
  }

  deleteDataSource () {
    const { dataSourceId } = this.props
    const { language } = this.context
    const body = JSON.stringify({})

    this.props.post(`externaldatasource/${dataSourceId}/delete`, body, {
      onSuccess: (response) => {
        this.props.close()

        this.props.showUndoMessage(
          language.translate('application.externalDataSourceRemoved'),
          () => {
            this.props.post(`externaldatasource/${dataSourceId}/delete/undo`, body, {
              onSuccess: (response) => {
                this.props.goBack()
              }
            })
          }
        )
      }
    })
  }

  fetchTimeZones () {
    this.props.get('timezone/list', {
      onSuccess: (response) => {
        this.setState({
          timeZonesLoaded: true,
          timeZones: response.TimeZoneList
        })
      }
    })
  }

  fetchTemplates () {
    this.setState({ templatesLoading: true })

    this.props.get('processtemplate/list', {
      onSuccess: (response) => {
        this.setState({
          templates: response.ProcessTemplateList,
          templatesLoaded: true,
          templatesLoading: false
        })
      }
    })
  }

  testConnection () {
    const { dataSourceId } = this.props

    this.setState({
      testingConnection: true
    })

    this.props.get(`externaldatasource/${dataSourceId}/testconnection`, {
      onSuccess: (response) => {
        this.setState({
          testingConnection: false,
          connectionTestPassed: true
        })
      },
      onError: (error) => {
        this.setState({
          testingConnection: false,
          connectionTestPassed: false
        })
      }
    })
  }

  testQuery () {
    const { dataSourceId } = this.props

    this.setState({
      testingQuery: true
    })

    this.props.get(`externaldatasource/${dataSourceId}/executequery`, {
      onSuccess: (response) => {
        this.setState({
          testingQuery: false,
          queryTestPassed: true,
          queryResults: JSON.parse(response.Text)
        })
      },
      onError: (error) => {
        this.setState({
          testingQuery: false,
          queryTestPassed: false
        })
      }
    })
  }

  render () {
    const {
      externalDataSource, isLoaded, timeZones, timeZonesLoaded, testingConnection, connectionTestPassed,
      testingQuery, queryTestPassed, queryResults, templates, templatesLoaded
    } = this.state
    const { dataSourceId, dispatch, classes } = this.props
    const { muiTheme, language } = this.context
    const { palette } = muiTheme

    const timeZoneOptions = []
    const templateOptions = []

    if (timeZonesLoaded) {
      timeZones.map((option) => {
        timeZoneOptions.push(<MenuItem value={option.ID} key={option.ID} primaryText={option.Description} />)
      })
    }

    if (templatesLoaded) {
      templates.map((template) => {
        templateOptions.push(
          <MenuItem value={template.ID} key={template.ID} primaryText={template.Title} />)
      })
    }

    return (
      <RightPanelContent
        title={language.translate('application.externalDataSource')}
        closePanel={() => this.props.close()}
        toolbarMenuItems={
          (dataSourceId)
            ? [{
              onClick: () => this.deleteDataSource(),
              isDelete: true
            }]
            : null
        }
      >
        <div style={{ padding: '15px' }}>
          {(isLoaded)
            ? <Card
              className='card'
              style={{ padding: '10px' }}
            >
              <Form
                onValid={() => this.setState({ canSubmit: true })}
                onInvalid={() => this.setState({ canSubmit: false })}
                style={{ display: 'flex', flexDirection: 'column' }}
                autoComplete='off'
              >
                <FormControlLabel
                  control={<Switch
                    color='primary'
                    checked={externalDataSource.IsLive}
                    disabled={(!dataSourceId)}
                    onChange={(obj, value) => {
                      externalDataSource.IsLive = !externalDataSource.IsLive
                      this.setState({ externalDataSource }, () => {
                        this.saveExternalDataSource()
                      })
                    }}
                  />}
                  style={{ justifyContent: 'space-between' }}
                  label={language.translate('application.enabled')}
                  labelPlacement='start'
                  classes={{ label: classes.label }}
                />
                <FormsyText
                  fullWidth
                  floatingLabelText={language.translate('application.description')}
                  required
                  name='description'
                  defaultValue={externalDataSource.Description}
                  onBlur={(e) => {
                    externalDataSource.Description = e.currentTarget.value
                    this.setState({ externalDataSource }, () => {
                      this.saveExternalDataSource()
                    })
                  }}
                  validationErrors={language.messages.validationErrors}
                />
                <FormsySelect
                  autoWidth
                  fullWidth
                  inputStyle={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                  floatingLabelText={language.translate('application.purpose')}
                  value={externalDataSource.Purpose}
                  onChange={(e, value) => {
                    externalDataSource.Purpose = value
                    this.setState({ externalDataSource }, () => {
                      this.saveExternalDataSource()
                    })
                  }}
                  name='purpose'
                  required
                  validationErrors={language.messages.validationErrors}
                >
                  {[
                    <MenuItem
                      value='Action'
                      key={0}
                      primaryText={language.translate('application.automatedActions')}
                    />,
                    <MenuItem
                      value='DDL'
                      key={1}
                      primaryText={language.translate('application.dropDownLists')}
                    />,
                    <MenuItem
                      value='Launch'
                      key={2}
                      primaryText={language.translate('application.launchingProcesses')}
                    />
                  ]}
                </FormsySelect>
                <FormsySelect
                  autoWidth
                  fullWidth
                  inputStyle={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                  floatingLabelText={language.translate('application.driverName')}
                  value={externalDataSource.DriverName}
                  onChange={(e, value) => {
                    externalDataSource.DriverName = value
                    this.setState({ externalDataSource }, () => {
                      this.saveExternalDataSource()
                    })
                  }}
                  name='driver_name'
                  required
                  validationErrors={language.messages.validationErrors}
                >
                  {[
                    <MenuItem value='MSSQL' key={0} primaryText='MSSQL' />,
                    <MenuItem value='MYSQL' key={1} primaryText='MySQL' />,
                    <MenuItem value='PGSQL' key={2} primaryText='PostgreSQL' />
                  ]}
                </FormsySelect>
                <FormsyText
                  fullWidth
                  floatingLabelText={language.translate('application.hostName')}
                  required
                  name='host_name'
                  defaultValue={externalDataSource.HostName}
                  onBlur={(e) => {
                    externalDataSource.HostName = e.currentTarget.value
                    this.setState({ externalDataSource }, () => {
                      this.saveExternalDataSource()
                    })
                  }}
                  validationErrors={language.messages.validationErrors}
                />
                <FormsyText
                  fullWidth
                  floatingLabelText={language.translate('application.databaseName')}
                  required
                  name='database_name'
                  defaultValue={externalDataSource.DatabaseName}
                  onBlur={(e) => {
                    externalDataSource.DatabaseName = e.currentTarget.value
                    this.setState({ externalDataSource }, () => {
                      this.saveExternalDataSource()
                    })
                  }}
                  validationErrors={language.messages.validationErrors}
                />
                <FormsyText
                  fullWidth
                  floatingLabelText={language.translate('application.userName')}
                  required
                  name='dataSourceUser_name'
                  defaultValue={externalDataSource.Username}
                  onBlur={(e) => {
                    externalDataSource.Username = e.currentTarget.value
                    this.setState({ externalDataSource }, () => {
                      this.saveExternalDataSource()
                    })
                  }}
                  validationErrors={language.messages.validationErrors}
                />
                <FormsyText
                  fullWidth
                  floatingLabelText={language.translate('application.password')}
                  required
                  type='password'
                  name='dataSourcePassword'
                  disabled={(!externalDataSource.Username)}
                  defaultValue={externalDataSource.Password}
                  onBlur={(e) => {
                    externalDataSource.Password = e.currentTarget.value
                    this.setState({ externalDataSource }, () => {
                      this.saveExternalDataSource()
                    })
                  }}
                  validationErrors={language.messages.validationErrors}
                />
                <div>
                  <FormsyText
                    floatingLabelText={language.translate('application.portNumber')}
                    required
                    style={{ width: '150px' }}
                    name='port_number'
                    defaultValue={externalDataSource.PortNumber}
                    onBlur={(e) => {
                      externalDataSource.PortNumber = e.currentTarget.value
                      this.setState({ externalDataSource }, () => {
                        this.saveExternalDataSource()
                      })
                    }}
                    validationErrors={language.messages.validationErrors}
                  />
                  <Button
                    style={{
                      marginLeft: '30px',
                      backgroundColor: (connectionTestPassed !== null) ? (connectionTestPassed) ? palette.successColor : palette.errorColor : null,
                      color: palette.alternateTextColor
                    }}
                    variant='contained'
                    onClick={this.testConnection.bind(this)}
                    disabled={(!externalDataSource.ID || testingConnection)}
                    color={connectionTestPassed === null && 'primary'}
                  >
                    {(testingConnection)
                      ? <CircularProgress thickness={2} size={25} /> : (connectionTestPassed)
                        ? <CheckCircle
                          nativeColor={palette.alternateTextColor}
                        /> : (connectionTestPassed === null) ? null
                          : <Error nativeColor={palette.alternateTextColor} />}
                    {language.translate('application.testConnection')}
                  </Button>
                </div>
                {(externalDataSource.Purpose === 'Launch')
                  ? <div>
                    <FormsySelect
                      fullWidth
                      inputStyle={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                      maxHeight={300}
                      value={externalDataSource.ProcessTemplateID}
                      name='template'
                      floatingLabelText={language.translate('application.processToLaunch')}
                      onChange={(e, templateId) => {
                        externalDataSource.ProcessTemplateID = templateId
                        this.setState({ externalDataSource }, () => {
                          this.saveExternalDataSource()
                        })
                      }}
                    >
                      {templateOptions}
                    </FormsySelect>
                    <GroupUserSelect
                      name={language.translate('application.processInitiator')}
                      required
                      dispatch={dispatch}
                      groupValue={externalDataSource.ProcessInitiatorUserGroupID}
                      userValue={externalDataSource.ProcessInitiatorUserID}
                      onGroupChange={(groupId) => {
                        if (externalDataSource.ProcessInitiatorUserGroupID !== groupId) {
                          externalDataSource.ProcessInitiatorUserGroupID = groupId
                          this.setState({ externalDataSource }, () => {
                            this.saveExternalDataSource()
                          })
                        }
                      }}
                      onUserChange={(userId) => {
                        if (externalDataSource.ProcessInitiatorUserID !== userId) {
                          externalDataSource.ProcessInitiatorUserID = userId
                          this.setState({ externalDataSource }, () => {
                            this.saveExternalDataSource()
                          })
                        }
                      }}
                    />
                    <FormsyTime
                      name='start_time'
                      textFieldStyle={{ width: '180px' }}
                      required
                      pedantic
                      minutesStep={60}
                      value={moment(externalDataSource.RepeatBeginHour || 8, 'H').toDate()}
                      floatingLabelText={language.translate('application.startingAt')}
                      validationErrors={language.messages.validationErrors}
                      onChange={(event, value) => {
                        externalDataSource.RepeatBeginHour = parseInt(moment(value).format('H'))

                        this.setState({ externalDataSource }, () => {
                          this.saveExternalDataSource()
                        })
                      }}
                    />
                    <FormsyTime
                      name='end_time'
                      textFieldStyle={{ width: '180px' }}
                      required
                      pedantic
                      minutesStep={60}
                      value={moment(externalDataSource.RepeatEndHour || 17, 'H').toDate()}
                      floatingLabelText={language.translate('application.endingAt')}
                      validationErrors={language.messages.validationErrors}
                      onChange={(event, value) => {
                        externalDataSource.RepeatEndHour = parseInt(moment(value).format('H'))

                        this.setState({ externalDataSource }, () => {
                          this.saveExternalDataSource()
                        })
                      }}
                    />
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                      <div>{language.translate('application.repeatEvery')}</div>
                      <FormsyText
                        style={{ width: '40px', margin: '0px 10px' }}
                        name='repeat_every'
                        value={externalDataSource.RepeatEveryMinutes}
                        onBlur={(event) => {
                          externalDataSource.RepeatEveryMinutes = parseInt(event.currentTarget.value || 1440)

                          this.setState({ externalDataSource }, () => {
                            this.saveExternalDataSource()
                          })
                        }}
                        validationErrors={language.messages.validationErrors}
                      />
                      <div>{language.translate('application.minute')}</div>
                    </div>
                    <FormsySelect
                      name='timezone'
                      value={externalDataSource.TimeZoneID}
                      onChange={(e, value) => {
                        externalDataSource.TimeZoneID = value

                        this.setState({ externalDataSource }, () => {
                          this.saveExternalDataSource()
                        })
                      }}
                      floatingLabelText={language.translate('application.timeZone')}
                      validationErrors={language.messages.validationErrors}
                      required
                    >
                      {timeZoneOptions}
                    </FormsySelect>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                      <div>{language.translate('application.maxProcessesLaunchedByThis')}</div>
                      <FormsyText
                        style={{ width: '40px', margin: '0px 10px' }}
                        name='max_processes'
                        value={externalDataSource.MaxProcesses}
                        onBlur={(event) => {
                          if (parseInt(event.currentTarget.value) < 100) { externalDataSource.MaxProcesses = parseInt(event.currentTarget.value) } else { externalDataSource.MaxProcesses = 99 }

                          this.setState({ externalDataSource }, () => {
                            this.saveExternalDataSource()
                          })
                        }}
                        validationErrors={language.messages.validationErrors}
                      />
                    </div>
                    <FormsyText
                      fullWidth
                      floatingLabelText={language.translate('application.uniqueKeyColumn')}
                      required
                      name='unique_key_column'
                      defaultValue={externalDataSource.UniqueKeyColumn}
                      onBlur={(e) => {
                        externalDataSource.UniqueKeyColumn = e.currentTarget.value

                        this.setState({ externalDataSource }, () => {
                          this.saveExternalDataSource()
                        })
                      }}
                      validationErrors={language.messages.validationErrors}
                    />
                  </div>
                  : null}
                {(externalDataSource.Purpose !== 'Action')
                  ? <div>
                    <FormsyText
                      fullWidth
                      required
                      floatingLabelText={language.translate('application.query')}
                      name='query'
                      multiLine
                      rows={5}
                      onBlur={(event) => {
                        externalDataSource.Query = event.currentTarget.value

                        this.setState({ externalDataSource }, () => {
                          this.saveExternalDataSource()
                        })
                      }}
                      value={externalDataSource.Query}
                      validationErrors={this.context.language.messages.validationErrors}
                    />
                    {(externalDataSource.Purpose === 'DDL')
                      ? <div style={{ fontSize: '12px', margin: '0px 10px' }}>
                        {`*${language.translate('application.ddlQueryHelpText')}`}
                      </div>
                      : null}
                    <Button
                      style={{
                        marginTop: '10px',
                        backgroundColor: (queryTestPassed !== null) ? (queryTestPassed) ? palette.successColor : palette.errorColor : null,
                        color: palette.alternateTextColor
                      }}
                      onClick={this.testQuery.bind(this)}
                      disabled={(!externalDataSource.ID || testingQuery || !externalDataSource.Query)}
                      color={queryTestPassed === null && 'primary'}
                      variant='contained'
                    >
                      {(testingQuery)
                        ? <CircularProgress thickness={2} size={25} /> : (queryTestPassed)
                          ? <CheckCircle
                            nativeColor={palette.alternateTextColor}
                          /> : (queryTestPassed === null) ? null
                            : <Error nativeColor={palette.alternateTextColor} />}
                      {language.translate('application.testQuery')}
                    </Button>
                    {(queryResults && queryResults.length)
                      ? <div
                        style={{
                          margin: '20px 0px',
                          padding: '10px',
                          border: `1px solid ${palette.borderColor}`
                        }}
                      >
                        <TreeView
                          nodeLabel={`${language.translate('application.results')} (${queryResults.length})`}
                          defaultCollapsed
                        >
                          {
                            queryResults.map((row, index) => (
                              <TreeView
                                key={index}
                                nodeLabel={index.toString()}
                                defaultCollapsed
                              >
                                {Object.keys(row).map((column, index) => (
                                  <div
                                    key={index}
                                  >{column}: {row[column]}</div>
                                ))}
                              </TreeView>
                            ))
                          }
                        </TreeView>
                      </div>
                      : null}
                  </div>
                  : null}
              </Form>
            </Card>
            : <CircularProgress className='loader' />
          }
        </div>
      </RightPanelContent>
    )
  }
}

ExternalDataSourcePanel.propTypes = {
  dataSourceId: PropTypes.string,
  get: PropTypes.func,
  post: PropTypes.func,
  close: PropTypes.func,
  dispatch: PropTypes.func,
  showUndoMessage: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
}

ExternalDataSourcePanel.contextTypes = {
  muiTheme: PropTypes.object,
  language: PropTypes.object,
  location: PropTypes.object
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  get: bindActionCreators(get, dispatch),
  post: bindActionCreators(post, dispatch),
  showUndoMessage: bindActionCreators(showUndoMessage, dispatch),
  goBack: bindActionCreators(goBack, dispatch),
  push: bindActionCreators(push, dispatch),
  dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExternalDataSourcePanel))
