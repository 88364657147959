import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import objectAssign from 'object-assign'
import { Base64 } from 'js-base64'
import * as actionHeader from '../actions/header'
import { updateTitle } from '../actions/application'
import CountCard from '../components/Tasks/CountCard'
import DataList from '../components/Layout/DataList'
import { goBack, push, replace } from 'react-router-redux'
import { get } from '../actions/base'
import { Form } from 'formsy-react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { History, HourglassEmpty, ViewWeek, Warning } from '@material-ui/icons'
import TaskList from '../components/Tasks/TaskList'
import TagCloud from '../components/Tags/TagCloud'
import { ingestSearchFromUrl } from '../components/Layout/Search/Selectors'
import GroupUserSelect from '../components/Forms/GroupUserSelect'
import { AST_Return } from 'terser'

class MyTasksView extends React.Component {
  constructor(props) {
    super(props)

    this.state = ({
      componentDidMountRanOnce: false,
      tasks: [],
      filter: '',
      entityId: '',
      entityType: 'user',
      refresh: false,
      pendingTasks: null,
      pastDueTasks: null,
      dueThisWeekTasks: null,
      pendingProcesses: null,
      tagList: [],
      templateList: [],
      selectFormUser: null,
      selectFormGroup: null
    })
  }

  //migrated from componentWillMount using componentDidMountRanOnce to ensure one run
  componentDidMount() {

    if (this.state.componentDidMountRanOnce === true) {
      return;
    }
    this.setState({ componentDidMountRanOnce: true });

    const { language } = this.context

    // Initialize and show filter options in the right header menu
    const listOptions = [
      language.translate('application.current'),
      language.translate('application.pending'),
      language.translate('application.dueThisWeek'),
      language.translate('application.completed'),
      language.translate('application.pastDue')
    ]

    this.props.headerActions.showFilterList(listOptions, (currentValue) => {
      switch (currentValue) {
        case language.translate('application.dueThisWeek'):
          this.applyFilter(language.translate('application.dueThisWeek'), 'duethisweek')
          break
        case language.translate('application.completed'):
          this.applyFilter(language.translate('application.completed'), 'completed')
          break
        case language.translate('application.pastDue'):
          this.applyFilter(language.translate('application.pastDue'), 'pastdue')
          break
        case language.translate('application.pending'):
          this.removeTaskFilter()
          break

        default:
          this.applyFilter(language.translate('application.noFutureTasks'), 'current')
      }
    })

    let user = this.props.searchItemsWithDisplayOnly.filter((item) => (item.operator === 'user-filter'))
    let group = this.props.searchItemsWithDisplayOnly.filter((item) => (item.operator === 'group-filter'))
    let entityId = (group.length) ? group[0].value : (user.length) ? user[0].value : this.props.user.userid

    this.setState({ entityId, entityType: (group.length) ? 'usergroup' : 'user' }, () => { this.loadCards() })

    this.initialize()
    this.setDefaultFilter()

    // check to see if a taskid is being passed on load
    if (this.props.params.taskid) { this.fetchTaskData(this.props.params.processid, this.props.params.taskid) }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.query.filter !== prevProps.location.query.filter) {
      this.initialize()
    }

    if (this.props.location.query.search !== prevProps.location.query.search) {
      this.initialize()
      this.loadCards()
    }

    if (JSON.stringify(this.props.searchItemsWithDisplayOnly) !== JSON.stringify(prevProps.searchItemsWithDisplayOnly)) {
      let user = this.props.searchItemsWithDisplayOnly.filter((item) => (item.operator === 'user-filter'))
      let group = this.props.searchItemsWithDisplayOnly.filter((item) => (item.operator === 'group-filter'))
      let entityId = (group.length) ? group[0].value : (user.length) ? user[0].value : this.props.user.userid

      this.setState({ entityId, entityType: (group.length) ? 'usergroup' : 'user' })
    }

    if ((this.state.entityId !== prevState.entityId && this.state.entityId)) {
      this.loadCards()
    }
  }

  componentWillUnmount() {
    // hide selectfilter in header bar
    this.props.headerActions.hideFilterList()
  }

  setDefaultFilter() {
    const { language } = this.context
    const { replace, location } = this.props
    let { location: { query: { search = [] } } } = this.props
    let hasFilter = false

    if (!Array.isArray(search)) {
      search = [search]
    }

    search.map((searchItem) => {
      searchItem = JSON.parse(Base64.decode(searchItem))

      if (searchItem.operator === 'task-filter') {
        hasFilter = true
      }
    })

    if (!hasFilter) {
      const newSearchItem = Base64.encodeURI(JSON.stringify({
        displayText: language.translate('application.noFutureTasks'),
        text: 'current',
        operator: 'task-filter',
        displayOperator: ' ',
        displayOnly: true
      }))

      search.push(newSearchItem)

      replace({
        pathname: location.pathname,
        query: objectAssign({}, location.query, { search })
      })
    }
  }

  removeTaskFilter() {
    const { push, location } = this.props
    let { location: { query: { search = [] } } } = this.props

    let newQuery = objectAssign({}, location.query)

    if (!Array.isArray(search)) {
      search = [search]
    }

    let newSearchItems = []

    search.map((searchItem) => {
      searchItem = JSON.parse(Base64.decode(searchItem))

      if (searchItem.operator !== 'task-filter') {
        newSearchItems.push(Base64.encodeURI(JSON.stringify(searchItem)))
      }
    })

    delete newQuery.search

    push({
      pathname: location.pathname,
      query: objectAssign({}, newQuery, { search: newSearchItems })
    })
  }

  initialize() {
    const { language } = this.context

    let filter = this.props.searchItemsWithDisplayOnly.filter((item) => (item.operator === 'task-filter'))

    if (filter.length) {
      switch (filter[0].text) {
        case 'duethisweek':
          this.setFilter(language.translate('application.dueThisWeek'), 'dueThisWeek', false)
          break
        case 'completed':
          this.setFilter(language.translate('application.completed'), 'completed', false)
          break
        case 'pastdue':
          this.setFilter(language.translate('application.pastDue'), 'pastDue', false)
          break
        case 'current':
          this.setFilter(language.translate('application.current'), 'current', true)
          break

        default:
          this.setFilter(language.translate('application.pending'), 'pending', true)
      }
    } else {
      this.setFilter(language.translate('application.pending'), 'pending', true)
    }
  }

  loadCards() {
    const { entityId, pendingTasks } = this.state
    const { user, isRightPanelOpen } = this.props

    if (pendingTasks == null) {
      //CONTINUE LOADING...
      //ALWAY LOAD IF THE CARDS ARE CURRENTLY NULL
    } else if (isRightPanelOpen) {
      return; //DON'T RELOAD WHILE THE RIGHT PANEL IS OPEN TO CUT DONW API CALLS
    }

    if (!entityId) {
      return;
    }

    this.setState({
      pendingTasks: null,
      pastDueTasks: null,
      dueThisWeekTasks: null,
      pendingProcesses: null
    })
    this.fetchDueThisWeekTaskCount()
    this.fetchPastDueTaskCount()
    this.fetchPendingTaskCount()

    if (entityId === user.userid) {
      this.fetchPendingProcessesCount()
    }
  }

  fetchTagList(endpoint, responseProperty, stateProperty) {
    const { get, isRightPanelOpen, searchItems, location: { query: { filter = 'current' } } } = this.props
    const { entityId, entityType } = this.state

    if (isRightPanelOpen) {
      return //DON'T RELOAD WHILE THE RIGHT PANEL IS OPEN TO CUT DONW API CALLS
    }

    if (entityType !== 'user') {
      return
    }

    let url = `${entityType}/${entityId}/task/list/${filter}/${endpoint}`

    if (searchItems.length) {
      url += '?'
    }

    searchItems.map((filter, index) => {
      if (index > 0) { url += '&' }

      const filterIndex = index + 1
      const operator = filter.operator || '(CONTAINS_TEXT)'

      url += `filter${filterIndex}=${filter.property}${operator}${filter.value}`
    })

    get(url, {
      onSuccess: (response) => {
        this.setState({
          [stateProperty]: response[responseProperty]
        })
      }
    })
  }

  fetchPastDueTaskCount() {
    const { get, searchItems } = this.props
    const { entityId, entityType } = this.state

    let endpoint = `${entityType}/${entityId}/task/list/pastdue/count`

    if (searchItems.length) {
      searchItems.map((searchItem, index) => {
        endpoint += (index) ? '&' : '?'
        endpoint += `filter${index + 1}=${searchItem.property}(CONTAINS_TEXT)${searchItem.value}`
      })
    }

    get(endpoint, {
      onSuccess: (response) => {
        this.setState({
          pastDueTasks: response.Count
        })
      }
    })
  }

  fetchPendingTaskCount() {
    const { get, searchItems } = this.props
    const { entityId, entityType } = this.state

    let endpoint = `${entityType}/${entityId}/task/list/pending/count`

    if (searchItems.length) {
      searchItems.map((searchItem, index) => {
        endpoint += (index) ? '&' : '?'
        endpoint += `filter${index + 1}=${searchItem.property}(CONTAINS_TEXT)${searchItem.value}`
      })
    }

    get(endpoint, {
      onSuccess: (response) => {
        this.setState({
          pendingTasks: response.Count
        })
      }
    })
  }

  fetchPendingProcessesCount() {
    const { get } = this.props
    const { entityId, entityType } = this.state

    let endpoint = `${entityType}/${entityId}/processinstance/list/pending/count`

    get(endpoint, {
      onSuccess: (response) => {
        this.setState({
          pendingProcesses: response.Count
        })
      }
    })
  }

  fetchDueThisWeekTaskCount() {
    const { get, searchItems } = this.props
    const { entityId, entityType } = this.state

    let endpoint = `${entityType}/${entityId}/task/list/duethisweek/count`

    if (searchItems.length) {
      searchItems.map((searchItem, index) => {
        endpoint += (index) ? '&' : '?'
        endpoint += `filter${index + 1}=${searchItem.property}(CONTAINS_TEXT)${searchItem.value}`
      })
    }

    get(endpoint, {
      onSuccess: (response) => {
        this.setState({
          dueThisWeekTasks: response.Count
        })
      }
    })
  }

  setFilter(readable, filter) {
    const { language } = this.context
    const { dispatch } = this.props

    dispatch(updateTitle(`${language.translate('application.task', [], true)}: ${language.translate(`application.${filter}`)}`))
    this.props.headerActions.selectedFilterValue(readable)
    this.setState({ filter })
  }

  dataLoadCallback(data) {
    this.setState({ tasks: data })
    this.fetchTagList('tag/list', 'TagList', 'tagList')
    this.fetchTagList('processtemplate/list', 'ProcessTemplateList', 'templateList')
  }

  getCountCardSize() {
    const { mainContentWidth } = this.props

    if (mainContentWidth > 944) { return 'large' }
    if (mainContentWidth > 420) { return 'medium' }

    return 'small'
  }

  handleTagClicked(value, property, displayText) {
    const { push, location } = this.props
    let { location: { query: { search = [] } } } = this.props

    const newSearchItem = Base64.encodeURI(JSON.stringify({
      property,
      value,
      displayText
    }))

    if (!Array.isArray(search)) {
      search = [search]
    }

    // dedupe search
    if (search.includes(newSearchItem)) {
      return
    }

    search.push(newSearchItem)

    push({
      pathname: location.pathname,
      query: objectAssign({}, location.query, { search })
    })
  }

  getTagNamesUsed(property) {
    const { searchItems } = this.props

    return searchItems.map((item) => { if (item.property === property) { return item.value } })
  }

  applyFilter(displayText, text) {
    const { push, location } = this.props
    let { location: { query: { search = [] } } } = this.props

    const newSearchItem = Base64.encodeURI(JSON.stringify({
      displayText: displayText,
      text: text,
      operator: 'task-filter',
      displayOperator: ' ',
      displayOnly: true
    }))

    if (!Array.isArray(search)) {
      search = [search]
    }

    // dedupe search
    if (search.includes(newSearchItem)) {
      return
    }

    let dedupedSearch = search.map((searchItem) => {
      searchItem = JSON.parse(Base64.decode(searchItem))

      if (searchItem.operator !== 'task-filter') {
        return Base64.encodeURI(JSON.stringify(searchItem))
      }
    })

    dedupedSearch.push(newSearchItem)

    push({
      pathname: location.pathname,
      query: objectAssign({}, location.query, { search: dedupedSearch })
    })
  }

  render() {
    const { language } = this.context
    const palette = this.context.muiTheme.palette
    const { push, user, location, mainContentWidth } = this.props
    const countCardSize = this.getCountCardSize()
    const { tagList, templateList, entityId, entityType, filter, selectFormUser, selectFormGroup } = this.state
    const tagNamesUsed = this.getTagNamesUsed('TagList')
    const templatesUsed = this.getTagNamesUsed('ProcessTemplateTitle')
    const tagsToDisplay = tagList.filter((tag) => !(tagNamesUsed.includes(tag.TagName)))
    const templatesToDisplay = templateList.filter((template) => !(templatesUsed.includes(template.Title)))

    if (!entityId) {
      return null
    }

    return (
      <div>
        <div style={{
          paddingTop: '35px',
          paddingBottom: '35px',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap'
        }}
        >
          <CountCard
            count={this.state.pendingTasks}
            color={palette.successColor}
            accentColor={palette.successAccentColor}
            onClick={() => {
              this.removeTaskFilter()
            }}
            title={language.translate('application.pendingTasks')}
            size={countCardSize}
            icon={<HourglassEmpty
              nativeColor={palette.canvasColor}
              style={{
                width: (countCardSize === 'small') ? '25px' : '60px',
                height: (countCardSize === 'small') ? '25px' : '58px',
                marginTop: '5px'
              }}
            />}
          />
          <CountCard
            count={this.state.pastDueTasks}
            color={palette.errorColor}
            accentColor={palette.errorAccentColor}
            onClick={() => {
              this.applyFilter(language.translate('application.pastDue'), 'pastdue')
            }}
            title={language.translate('application.countPastDueTitle')}
            size={countCardSize}
            icon={<Warning
              nativeColor={palette.canvasColor}
              style={{
                width: (countCardSize === 'small') ? '25px' : '60px',
                height: (countCardSize === 'small') ? '25px' : '58px',
                marginTop: '5px'
              }}
            />}
          />
          <CountCard
            count={this.state.dueThisWeekTasks}
            color={palette.warningColor}
            accentColor={palette.warningAccentColor}
            onClick={() => {
              this.applyFilter(language.translate('application.dueThisWeek'), 'duethisweek')
            }}
            title={language.translate('application.countDueThisWeekTitle')}
            size={countCardSize}
            icon={<ViewWeek
              nativeColor={palette.canvasColor}
              style={{
                width: (countCardSize === 'small') ? '25px' : '60px',
                height: (countCardSize === 'small') ? '25px' : '58px',
                marginTop: '5px'
              }}
            />}
          />
          {(entityId === user.userid &&
            <CountCard
              count={this.state.pendingProcesses}
              color={palette.primary1Color}
              accentColor={palette.accent1Color}
              onClick={() => push('/processes-in-progress')}
              title={language.translate('application.process', [], true)}
              size={countCardSize}
              icon={<History
                nativeColor={palette.canvasColor}
                style={{
                  width: (countCardSize === 'small') ? '25px' : '60px',
                  height: (countCardSize === 'small') ? '25px' : '58px',
                  marginTop: '5px'
                }}
              />}
            />)}
        </div>
        <DataList
          url={`${entityType}/${entityId}/task/list/${filter}`}
          dataCallback={this.dataLoadCallback.bind(this)}
          responseProperty='ProcessInstanceTaskList'
          filterableColumns={[{
            name: language.translate('application.processField', [], true),
            property: 'ProcessInstance_HeaderFieldAll_Value'
          }, {
            name: language.translate('application.processTitle'),
            property: 'ProcessTemplateTitle'
          }, {
            name: language.translate('application.taskTitle'),
            property: 'TaskText_Display'
          }, {
            name: language.translate('application.processGroup'),
            property: 'ProcessTemplateGroupName'
          }, {
            name: language.translate('application.notes'),
            property: 'Notes'
          }]}
          channelName={`Private-${user.accountID}-${entityType.ucFirst()}-${entityId}`}
          events={['InstanceTaskCreated', 'InstanceTaskUpdated', 'InstanceTaskCompleted', 'InstanceTaskCanceled']}
          location={location}
          eventCallback={this.loadCards.bind(this)}
        >
          <div style={{ display: 'flex', width: '100%' }}>
            <TaskList
              tasks={this.state.tasks}
              location={location}
              showAssignedTo={(entityType === 'usergroup')}
              containerStyle={{ marginRight: '20px', height: 'fit-content', flex: 'auto', overflow: 'hidden' }} />
            <div style={{ maxWidth: '350px', display: 'flex', flexDirection: 'column' }}>
              <TagCloud
                tags={tagsToDisplay}
                onTagClick={(tag) => { this.handleTagClicked(tag.TagName, 'TagList', 'Tags') }}
                containerStyle={{
                  display: (tagsToDisplay.length && mainContentWidth > 700) ? 'initial' : 'none'
                }}
                title={language.translate('application.tags')}
              />
              <TagCloud
                tags={templatesToDisplay.map((template) => {
                  template.TagName = template.Title
                  return template
                })}
                onTagClick={(template) => { this.handleTagClicked(template.Title, 'ProcessTemplateTitle', 'Template') }}
                containerStyle={{
                  display: (templatesToDisplay.length && mainContentWidth > 700) ? 'initial' : 'none'
                }}
                title={language.translate('application.process', [], true)}
              />
            </div>
          </div>
        </DataList>
        {location.query.showFilter &&
          <Dialog
            open
          >
            <DialogTitle
              style={{
                backgroundColor: palette.headerBackgroundColor
              }}
              disableTypography
            >
              <Typography
                variant='h6'
                style={{ color: palette.alternateTextColor }}>
                {language.translate('application.selectGroupUser')}
              </Typography>
            </DialogTitle>
            <DialogContent style={{ width: '350px' }}>
              <Form>
                <GroupUserSelect
                  name={language.translate('application.selectGroupUser')}
                  dispatch={this.props.dispatch}
                  ref='selectGroupUser'
                  allowAllUsers
                  allowAllGroups
                  onGroupChange={(groupId, group) => { this.setState({ selectFormGroup: group }) }}
                  onUserChange={(userId, user) => { this.setState({ selectFormUser: user }) }}
                />
              </Form>
            </DialogContent>
            <DialogActions>
              <Button
                key='submit'
                color='primary'
                disabled={(!selectFormGroup)}
                onClick={() => {
                  let query = []

                  query.search = [Base64.encodeURI(JSON.stringify({
                    displayOnly: true,
                    value: (selectFormUser) ? selectFormUser.ID : selectFormGroup.ID,
                    displayText: (selectFormUser) ? 'Users' : 'Groups',
                    displayValue: (selectFormUser) ? selectFormUser.FullName : selectFormGroup.GroupName,
                    operator: (selectFormUser) ? 'user-filter' : 'group-filter'
                  })), Base64.encodeURI(JSON.stringify({
                    displayText: language.translate('application.noFutureTasks'),
                    text: 'current',
                    operator: 'task-filter',
                    displayOperator: ' ',
                    displayOnly: true
                  }))]

                  push({
                    pathname: `/`,
                    query
                  })
                }}
              >{language.translate('application.submit')}</Button>,
            <Button
                key='cancel'
                color='primary'
                onClick={() => {
                  let query = JSON.parse(JSON.stringify(this.props.location.query))
                  delete query.showFilter

                  push({
                    pathname: `/`,
                    query
                  })
                }}
              >{language.translate('application.cancel')}</Button>
            </DialogActions>
          </Dialog>}
      </div>
    )
  }
}

MyTasksView.propTypes = {
  headerActions: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.string,
  header: PropTypes.object.isRequired,
  params: PropTypes.object,
  push: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  screenWidth: PropTypes.number,
  mainContentWidth: PropTypes.number,
  isNavOpen: PropTypes.bool,
  location: PropTypes.object,
  scrollBarWidth: PropTypes.number.isRequired,
  searchItems: PropTypes.array,
  searchItemsWithDisplayOnly: PropTypes.array
}

MyTasksView.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

const mapStateToProps = (state, ownProps) => ({
  user: state.auth,
  title: state.application.title,
  header: state.header,
  screenWidth: state.application.screenWidth,
  isNavOpen: state.navigation.open,
  scrollBarWidth: state.application.scrollBarWidth,
  mainContentWidth: state.application.mainContentWidth,
  isRightPanelOpen: state.application.rightPanelOpen,
  location: ownProps.location,
  searchItems: ingestSearchFromUrl(ownProps.location.query.search),
  searchItemsWithDisplayOnly: ingestSearchFromUrl(ownProps.location.query.search, true)
})

const mapDispatchToProps = dispatch => ({
  headerActions: bindActionCreators(actionHeader, dispatch),
  push: bindActionCreators(push, dispatch),
  replace: bindActionCreators(replace, dispatch),
  goBack: bindActionCreators(goBack, dispatch),
  get: bindActionCreators(get, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(MyTasksView)
