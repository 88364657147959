import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get, post } from '~/actions/base'
import { showSnackbar, showUndoMessage } from '~/actions/snackbar'
import { push } from 'react-router-redux'
import { Card, Collapse, Divider, List, ListItem, ListItemSecondaryAction, ListItemText, Switch } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import DefaultVisibilityList from '../ProcessTemplate/DefaultVisibilityList'
import CopyToClipboard from 'react-copy-to-clipboard'
import RightPanelContent from '../Layout/RightPanelContent'
import PublicFormList from '../ProcessTemplate/PublicFormList'

class ProcessInstanceSettings extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      isLoaded: false,
      process: null,
      visibilityLoaded: false,
      visibilityList: null,
      publicFormList: [],
      publicFormListLoaded: false,
      visibilityListExpanded: false,
      publicFormListExpanded: false
    }
  }

  // MIGRATED TO componentDidMount
  // componentWillMount () {
  //   this.fetchAllData()
  // }

  componentDidMount() {
    if (this.state.isLoaded === false) {
      this.fetchAllData()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.processId !== this.props.processId) {
      this.fetchAllData()
    }
  }

  fetchAllData() {
    this.fetchProcessSettings()
    this.fetchVisibilityList()
  }

  fetchProcessSettings() {
    const { processId } = this.props

    this.props.get(`processinstance/${processId}`, {
      onSuccess: (response) => {
        this.setState({
          isLoaded: true,
          process: response.ProcessInstance
        }, () => this.fetchPublicFormList())
      }
    })
  }

  saveSettings(settings) {
    const body = JSON.stringify({
      ProcessInstance: settings
    })

    this.props.post(`processinstance/${this.props.processId}`, body, {
      onSuccess: (response) => {
        this.setState({
          process: response.ProcessInstance
        })
      }
    })
  }

  fetchVisibilityList() {
    this.props.get(`processinstance/${this.props.processId}/visibility/list`, {
      onSuccess: (response) => {
        this.setState({
          visibilityLoaded: true,
          visibilityList: response.ProcessInstanceVisibilityList
        })
      }
    })
  }

  saveVisibilityList(list) {
    const body = JSON.stringify({
      ProcessInstanceVisibilityList: list
    })

    this.props.post(`processinstance/${this.props.processId}/visibility/list`, body, {
      onSuccess: (response) => {
        this.setState({
          visibilityList: response.ProcessInstanceVisibilityList
        })
      }
    })
  }

  deleteVisibilityListItem(item) {
    const { language } = this.context
    const body = JSON.stringify({})

    this.props.post(
      `processinstance/${this.props.processId}/visibility/${item.ID}/delete`,
      body,
      {
        onSuccess: (response) => {
          this.fetchVisibilityList()

          this.props.showUndoMessage(
            language.translate('application.visibilityRemoved'),
            () => {
              this.props.post(
                `processinstance/${this.props.processId}/visibility/${item.ID}/delete/undo`,
                body,
                {
                  onSuccess: (response) => {
                    this.fetchVisibilityList()
                  }
                }
              )
            }
          )
        }
      }
    )
  }

  fetchPublicFormList() {
    const { process } = this.state

    this.props.get(`processinstance/${process.ID}/publicform/list`, {
      onSuccess: (response) => {
        this.setState({
          publicFormListLoaded: true,
          publicFormList: response.ProcessTemplatePublicFormList
        })
      }
    })
  }

  render() {
    const { language } = this.context
    const { process, isLoaded, visibilityLoaded, visibilityList, publicFormList, publicFormListLoaded, visibilityListExpanded, publicFormListExpanded } = this.state
    const { showSnackbar, push } = this.props

    if (!isLoaded) { return null }

    const milestoneURL = `/milestone-report?instancetoken=${process.Token}`

    return (
      <RightPanelContent
        title={language.translate('application.processSettings')}
        closePanel={() => this.props.close()}
      >
        <div style={{ padding: '15px' }}>
          <Card className='card'>
            <List style={{ padding: '0px' }}>
              <ListItem
                button
                onClick={() => { this.setState({ visibilityListExpanded: !visibilityListExpanded }) }}
              >
                <ListItemText
                  primary={language.translate('application.defaultInstanceVisibilityTitle')}
                  secondary={language.translate('application.defaultTemplateVisibilitySubtitle')} />
                {visibilityListExpanded ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={visibilityListExpanded} timeout='auto' unmountOnExit>
                {(visibilityLoaded)
                  ? <DefaultVisibilityList
                    key='default-visibility'
                    defaultVisibilityList={visibilityList}
                    onSaveList={this.saveVisibilityList.bind(this)}
                    onDelete={this.deleteVisibilityListItem.bind(this)}
                  />
                  : null}
              </Collapse>
              <Divider />
              <ListItem>
                <ListItemText primary={language.translate('application.showInLookupFields')} />
                <ListItemSecondaryAction>
                  <Switch
                    color='primary'
                    checked={(process.AvailableInLookups)}
                    onChange={(obj, value) => {
                      process.AvailableInLookups = !process.AvailableInLookups
                      this.saveSettings(process)
                    }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
              <CopyToClipboard
                text={window.location.origin + milestoneURL}
                onCopy={() => showSnackbar(language.translate('application.urlCopiedToClipboard'))}
              >
                <ListItem button>
                  <ListItemText
                    primary={language.translate('application.copyMilestoneReportUrl')}
                    secondary={window.location.origin + milestoneURL}
                  />
                </ListItem>
              </CopyToClipboard>
              <Divider />
              <ListItem
                button
                onClick={() => {
                  push(milestoneURL)
                }}
              >
                <ListItemText primary={language.translate('application.goToMilestoneReport')} />
              </ListItem>
              {(publicFormListLoaded && publicFormList.length)
                ? <div>
                  <Divider />
                  <ListItem
                    button
                    onClick={() => { this.setState({ publicFormListExpanded: !publicFormListExpanded }) }}
                  >
                    <ListItemText primary={language.translate('application.publicForm', [], true)} />
                    {publicFormListExpanded ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={publicFormListExpanded} timeout='auto' unmountOnExit>
                    <PublicFormList
                      key='public-forms'
                      publicFormList={publicFormList}
                      templateId={process.ProcessTemplateID}
                      displayMessage={showSnackbar}
                    />
                  </Collapse>
                </div>
                : null}
            </List>
          </Card>
        </div>
      </RightPanelContent>
    )
  }
}

ProcessInstanceSettings.propTypes = {
  processId: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  showUndoMessage: PropTypes.func.isRequired
}

ProcessInstanceSettings.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  dispatch,
  post: bindActionCreators(post, dispatch),
  get: bindActionCreators(get, dispatch),
  showSnackbar: bindActionCreators(showSnackbar, dispatch),
  push: bindActionCreators(push, dispatch),
  showUndoMessage: bindActionCreators(showUndoMessage, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ProcessInstanceSettings)
