import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get, post } from '~/actions/base'
import { Button, Card, Collapse, Divider, List, ListItem, ListItemText } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { push } from 'react-router-redux'
import objectAssign from 'object-assign'
import { Base64 } from 'js-base64'
import { showSnackbar, showUndoMessage } from '~/actions/snackbar'
import Icon from '../Icon/Icon'
import LaunchCodeList from './LaunchCodeList'
import LaunchEmailList from './LaunchEmailList'
import PublicFormList from './PublicFormList'
import WebHookList from './WebHookList'
import RecurringScheduleList from './RecurringScheduleList'
import RightPanelContent from '../Layout/RightPanelContent'
import ScheduledDatabaseQueryList from './ScheduledDatabaseQueryList'

class ProcessLaunchSettings extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      isLoaded: false,
      templateFieldList: null,
      templateFieldListLoaded: false,
      template: null,
      publicFormListLoaded: false,
      publicFormList: null,
      launchCodeListLoaded: false,
      launchCodeList: null,
      launchEmailListLoaded: false,
      launchEmailList: null,
      webHookListLoaded: false,
      webHookList: null,
      recurringScheduleListLoaded: false,
      recurringScheduleList: null,
      scheduledQueryList: null,
      scheduledQueryListLoaded: false,
      recurringScheduleExpanded: false,
      emailExpanded: false,
      desktopShortcutExpanded: false,
      scheduledQueryExpanded: false,
      publicFormExpanded: false,
      launchCodeExpanded: false,
      webhookExpanded: false,
      zapierExpanded: false,
      restApiExpanded: false

    }
  }

  // MIGRATED TO componentDidMount
  // componentWillMount () {
  //   this.fetchAllData()
  // }

  componentDidMount() {
    if (this.state.isLoaded === false) {
      this.fetchAllData()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.templateId !== this.props.templateId) {
      this.fetchAllData()
    }
  }

  fetchAllData() {
    this.fetchProcessTemplateSettings()
    this.fetchTemplateFields()
    this.fetchPublicFormList()
    this.fetchLaunchCodeList()
    this.fetchLaunchEmailList()
    this.fetchWebHookList()
    this.fetchRecurringScheduleList()
    this.fetchScheduledQueryList()
  }

  fetchProcessTemplateSettings() {
    const { templateId } = this.props

    this.props.get(`processtemplate/${templateId}`, {
      onSuccess: (response) => {
        this.setState({
          isLoaded: true,
          template: response.ProcessTemplate
        })
      }
    })
  }

  fetchTemplateFields() {
    this.props.get(`processtemplate/${this.props.templateId}/field/list`, {
      onSuccess: (response) => {
        this.setState({
          templateFieldListLoaded: true,
          templateFieldList: response.ProcessTemplateFieldList
        })
      }
    })
  }

  fetchPublicFormList() {
    this.props.get(`processtemplate/${this.props.templateId}/publicform/list`, {
      onSuccess: (response) => {
        this.setState({
          publicFormListLoaded: true,
          publicFormList: response.ProcessTemplatePublicFormList
        })
      }
    })
  }

  savePublicFormList(list, callback) {
    const body = JSON.stringify({
      ProcessTemplatePublicFormList: list
    })

    this.props.post(`processtemplate/${this.props.templateId}/publicform/list`, body, {
      onSuccess: (response) => {
        this.setState({
          publicFormList: response.ProcessTemplatePublicFormList
        }, () => {
          if (callback) {
            callback(response.ProcessTemplatePublicFormList)
          }
        })
      }
    })
  }

  deletePublicFormListItem(item) {
    const { language } = this.context

    const body = JSON.stringify({})

    this.props.post(
      `processtemplate/${this.props.templateId}/publicform/${item.ID}/delete`,
      body,
      {
        onSuccess: (response) => {
          this.fetchPublicFormList()
          this.props.showUndoMessage(
            language.translate('application.publicFormRemoved'),
            () => {
              this.props.post(
                `processtemplate/${this.props.templateId}/publicform/${item.ID}/delete/undo`,
                body,
                {
                  onSuccess: (response) => {
                    this.fetchPublicFormList()
                  }
                }
              )
            }
          )
        }
      }
    )
  }

  fetchLaunchCodeList() {
    this.props.get(`processtemplate/${this.props.templateId}/launchcode/list`, {
      onSuccess: (response) => {
        this.setState({
          launchCodeListLoaded: true,
          launchCodeList: response.ProcessTemplateLaunchCodeList
        })
      }
    })
  }

  saveLaunchCodeList(list) {
    const body = JSON.stringify({
      ProcessTemplateLaunchCodeList: list
    })

    this.props.post(`processtemplate/${this.props.templateId}/launchcode/list`, body, {
      onSuccess: (response) => {
        this.setState({
          launchCodeList: response.ProcessTemplateLaunchCodeList
        })
      }
    })
  }

  deleteLaunchCodeListItem(item) {
    const { language } = this.context

    const body = JSON.stringify({})

    this.props.post(
      `processtemplate/${this.props.templateId}/launchcode/${item.ID}/delete`,
      body,
      {
        onSuccess: (response) => {
          this.fetchLaunchCodeList()

          this.props.showUndoMessage(
            language.translate('application.launchCodeRemoved'),
            () => {
              this.props.post(
                `processtemplate/${this.props.templateId}/launchcode/${item.ID}/delete/undo`,
                body,
                {
                  onSuccess: (response) => {
                    this.fetchLaunchCodeList()
                  }
                }
              )
            }
          )
        }
      }
    )
  }

  fetchWebHookList() {
    this.props.get(`processtemplate/${this.props.templateId}/webhook/list`, {
      onSuccess: (response) => {
        this.setState({
          webHookListLoaded: true,
          webHookList: response.ProcessTemplateWebhookList
        })
      }
    })
  }

  fetchWebHookMappings(webhookId, callback) {
    const { templateId } = this.props

    this.props.get(`processtemplate/${templateId}/webhook/${webhookId}/fieldmap/list`, {
      onSuccess: (response) => {
        callback(response)
      }
    })
  }

  saveWebHookMap(mapList, webhookId, callback) {
    const { templateId } = this.props

    const body = JSON.stringify({
      ProcessTemplateWebHookFieldMapList: mapList
    })

    this.props.post(
      `processtemplate/${templateId}/webhook/${webhookId}/fieldmap/list`,
      body,
      {
        onSuccess: (response) => {
          callback(response)
        }
      }
    )
  }

  deleteWebHookMapItem(webhookId, mapId, callback, undoCallback) {
    const { templateId } = this.props
    const { language } = this.context

    const body = JSON.stringify({})

    this.props.post(
      `processtemplate/${templateId}/webhook/${webhookId}/fieldmap/${mapId}/delete`,
      body,
      {
        onSuccess: (response) => {
          callback(response)

          this.props.showUndoMessage(
            language.translate('application.webhookMappingRemoved'),
            () => {
              this.props.post(
                `processtemplate/${templateId}/webhook/${webhookId}/fieldmap/${mapId}/delete/undo`,
                body,
                {
                  onSuccess: (response) => {
                    undoCallback()
                  }
                }
              )
            }
          )
        }
      }
    )
  }

  saveWebHookList(list) {
    const body = JSON.stringify({
      ProcessTemplateWebhookList: list
    })

    this.props.post(`processtemplate/${this.props.templateId}/webhook/list`, body, {
      onSuccess: (response) => {
        this.setState({
          webHookList: response.ProcessTemplateWebhookList
        })
      }
    })
  }

  deleteWebHookListItem(item) {
    const { language } = this.context

    const body = JSON.stringify({})

    this.props.post(
      `processtemplate/${this.props.templateId}/webhook/${item.ID}/delete`,
      body,
      {
        onSuccess: (response) => {
          this.fetchWebHookList()

          this.props.showUndoMessage(
            language.translate('application.webhookRemoved'),
            () => {
              this.props.post(
                `processtemplate/${this.props.templateId}/webhook/${item.ID}/delete/undo`,
                body,
                {
                  onSuccess: (response) => {
                    this.fetchWebHookList()
                  }
                }
              )
            }
          )
        }
      }
    )
  }

  fetchRecurringScheduleList() {
    this.props.get(
      `processtemplate/${this.props.templateId}/instance/list/scheduled/active`,
      {
        onSuccess: (response) => {
          this.setState({
            recurringScheduleListLoaded: true,
            recurringScheduleList: response.ProcessInstanceList
          })
        }
      }
    )
  }

  deleteRecurringScheduleListItem(item) {
    const body = JSON.stringify({})

    this.props.post(`processinstance/${item.ID}/cancel`, body, {
      onSuccess: (response) => {
        this.fetchRecurringScheduleList()
      }
    })
  }

  fetchScheduledQueryList() {
    this.props.get(`processtemplate/${this.props.templateId}/launchdatasource/list`, {
      onSuccess: (response) => {
        this.setState({
          scheduledQueryListLoaded: true,
          scheduledQueryList: response.ExternalDataSourceList
        })
      }
    })
  }

  deleteScheduledQueryListItem(item) {
    const { language } = this.context

    const body = JSON.stringify({})

    this.props.post(
      `processtemplate/${this.props.templateId}/launchdatasource/${item.ID}/delete`,
      body,
      {
        onSuccess: (response) => {
          this.fetchScheduledQueryList()

          this.props.showUndoMessage(
            language.translate('application.externalDataSourceRemoved'),
            () => {
              this.props.post(
                `processtemplate/${this.props.templateId}/launchdatasource/${item.ID}/delete/undo`,
                body,
                {
                  onSuccess: (response) => {
                    this.fetchScheduledQueryList()
                  }
                }
              )
            }
          )
        }
      }
    )
  }

  fetchLaunchEmailList() {
    this.props.get(`processtemplate/${this.props.templateId}/launchemail/list`, {
      onSuccess: (response) => {
        this.setState({
          launchEmailListLoaded: true,
          launchEmailList: response.ProcessTemplateLaunchEmailList
        })
      }
    })
  }

  saveLaunchEmailList(list) {
    const body = JSON.stringify({
      ProcessTemplateLaunchEmailList: list
    })

    this.props.post(`processtemplate/${this.props.templateId}/launchemail/list`, body, {
      onSuccess: (response) => {
        this.setState({
          launchEmailList: response.ProcessTemplateLaunchEmailList
        })
      }
    })
  }

  deleteLaunchEmailListItem(item) {
    const { language } = this.context

    const body = JSON.stringify({})

    this.props.post(
      `processtemplate/${this.props.templateId}/launchemail/${item.ID}/delete`,
      body,
      {
        onSuccess: (response) => {
          this.fetchLaunchEmailList()

          this.props.showUndoMessage(
            language.translate('application.launchCodeRemoved'),
            () => {
              this.props.post(
                `processtemplate/${this.props.templateId}/launchemail/${item.ID}/delete/undo`,
                body,
                {
                  onSuccess: (response) => {
                    this.fetchLaunchEmailList()
                  }
                }
              )
            }
          )
        }
      }
    )
  }

  savePublicFormLogo(logo, publicFormId) {
    const body = JSON.stringify({
      ProcessTemplatePublicFormLogo: logo
    })

    this.props.post(`processtemplate/${this.props.templateId}/publicform/${publicFormId}/logo`, body, {
      onSuccess: (response) => {
        const { publicFormList } = this.state

        publicFormList.map((publicForm) => {
          if (publicForm.ID === publicFormId) {
            publicForm.PublicFormLogo_FileName = response.ProcessTemplatePublicForm.PublicFormLogo_FileName
            publicForm.PublicFormLogo_URL = response.ProcessTemplatePublicForm.PublicFormLogo_URL
          }
        })

        // make public form view pull this image if present and fallback to account logo

        this.setState({ publicFormList })
      }
    })
  }

  deletePublicFormLogo(publicFormId) {
    const body = JSON.stringify({})

    this.props.post(`processtemplate/${this.props.templateId}/publicform/${publicFormId}/logo/delete`, body, {
      onSuccess: (response) => {
        const { publicFormList } = this.state

        publicFormList.map((publicForm) => {
          if (publicForm.ID === publicFormId) {
            publicForm.PublicFormLogo_FileName = response.ProcessTemplatePublicForm.PublicFormLogo_FileName
            publicForm.PublicFormLogo_URL = null
          }
        })

        this.setState({ publicFormList })
      }
    })
  }

  render() {
    const palette = this.context.muiTheme.palette
    const { language, location, user } = this.context
    const {
      template, isLoaded, launchCodeList, launchCodeListLoaded, launchEmailList, launchEmailListLoaded,
      templateFieldList, templateFieldListLoaded, publicFormList, publicFormListLoaded, webHookListLoaded,
      webHookList, recurringScheduleList, recurringScheduleListLoaded, scheduledQueryList, scheduledQueryListLoaded,
      recurringScheduleExpanded, emailExpanded, desktopShortcutExpanded, scheduledQueryExpanded, publicFormExpanded,
      launchCodeExpanded, webhookExpanded, zapierExpanded, restApiExpanded
    } = this.state
    const { showSnackbar, push } = this.props

    return (
      <RightPanelContent
        closePanel={() => this.props.close()}
        title={language.translate('application.startOptions')}
      >
        {(isLoaded)
          ? <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Button
              color='primary'
              onClick={() => {
                const templateId = template.ID

                push({
                  pathname: location.pathname,
                  query: objectAssign({},
                    location.query,
                    {
                      ptype: 'process_template',
                      pid: templateId
                    })
                })
              }}
              style={{ margin: '20px auto' }}
              variant='contained'
            >
              {language.translate('application.startThisProcessNow')}
            </Button>
            <div>
              {language.translate('application.orStartThisProcess')}
            </div>
            <Card className='card' initiallyExpanded style={{ width: '95%' }}>
              <List>
                <ListItem
                  button
                  onClick={() => { this.setState({ recurringScheduleExpanded: !recurringScheduleExpanded }) }}
                >
                  <ListItemText
                    primary={language.translate('application.viaRecurringSchedule')} />
                  {recurringScheduleExpanded ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={recurringScheduleExpanded} timeout='auto' unmountOnExit>
                  {(recurringScheduleListLoaded)
                    ? <RecurringScheduleList
                      key='recurring-schedule'
                      recurringScheduleList={recurringScheduleList}
                      pushToRecurring={(instanceId) => {
                        const templateId = template.ID
                        const queryAttributes = {
                          ptype: 'process_template',
                          pid: templateId,
                          recurring: true
                        }

                        if (instanceId) { queryAttributes.piid = instanceId }

                        push({
                          pathname: location.pathname,
                          query: objectAssign({},
                            location.query,
                            queryAttributes
                          )
                        })
                      }}
                      pushToExpiredProcesses={() => {
                        const newSearchItem = Base64.encodeURI(JSON.stringify({
                          property: 'ProcessTemplateID',
                          value: template.ID,
                          displayText: language.translate('application.processTemplate'),
                          operator: '(EQUALTO_ID)'
                        }))

                        push({ pathname: '/scheduled-processes', query: { type: 'expired', search: newSearchItem } })
                      }}
                      onDelete={this.deleteRecurringScheduleListItem.bind(this)}
                    />
                    : null}
                </Collapse>
                <Divider />
                <ListItem
                  button
                  onClick={() => { this.setState({ emailExpanded: !emailExpanded }) }}
                >
                  <ListItemText
                    primary={language.translate('application.viaEmail')} />
                  {emailExpanded ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={emailExpanded} timeout='auto' unmountOnExit>
                  {(launchEmailListLoaded)
                    ? <LaunchEmailList
                      key='launch_email'
                      templateId={template.ID}
                      launchEmailList={launchEmailList}
                      onSaveList={this.saveLaunchEmailList.bind(this)}
                      onDelete={this.deleteLaunchEmailListItem.bind(this)}
                      displayMessage={message => showSnackbar(message)}
                    />
                    : null}
                </Collapse>
                <Divider />
                <ListItem
                  button
                  onClick={() => { this.setState({ desktopShortcutExpanded: !desktopShortcutExpanded }) }}
                >
                  <ListItemText
                    primary={language.translate('application.viaDesktopShortcut')} />
                  {desktopShortcutExpanded ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={desktopShortcutExpanded} timeout='auto' unmountOnExit>
                  <div
                    key='desktop_shortcut'
                    style={{
                      padding: '10px 20px',
                      backgroundColor: palette.accent2Color,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <div>
                      {language.translate('application.desktopShortcutText')}
                    </div>
                    <div style={{ marginTop: '20px' }}>
                      <div>
                        <Button
                          href={`${window.location.origin}/process-templates?pid=${template.ID}&ptsid=${template.ID}&ptype=process_template`}
                          style={{
                            fontSize: '18px',
                            fontWeight: '600',
                            color: palette.primary1Color
                          }}
                        >
                          {template.Title}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Collapse>
                <Divider />
                <ListItem
                  button
                  onClick={() => { this.setState({ scheduledQueryExpanded: !scheduledQueryExpanded }) }}
                >
                  <ListItemText
                    primary={language.translate('application.viaScheduledDatabaseQuery')} />
                  {scheduledQueryExpanded ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={scheduledQueryExpanded} timeout='auto' unmountOnExit>
                  <ScheduledDatabaseQueryList
                    key='scheduled-query'
                    scheduledQueryList={scheduledQueryList}
                    pushToDataSource={(dataSourceId) => {
                      const templateId = template.ID
                      const queryAttributes = {
                        ptype: 'externalds',
                        pid: templateId
                      }

                      if (dataSourceId) { queryAttributes.dsid = dataSourceId }

                      push({
                        pathname: location.pathname,
                        query: objectAssign({},
                          location.query,
                          queryAttributes
                        )
                      })
                    }}
                    onDelete={this.deleteScheduledQueryListItem.bind(this)}
                  />
                </Collapse>
                <Divider />
                <ListItem
                  button
                  onClick={() => { this.setState({ publicFormExpanded: !publicFormExpanded }) }}
                >
                  <ListItemText
                    primary={language.translate('application.viaPublicForm')} />
                  {publicFormExpanded ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={publicFormExpanded} timeout='auto' unmountOnExit>
                  {(publicFormListLoaded && templateFieldListLoaded)
                    ? <PublicFormList
                      key='public-form'
                      publicFormList={publicFormList}
                      templateId={template.ID}
                      processFields={templateFieldList}
                      onSaveList={this.savePublicFormList.bind(this)}
                      onDelete={this.deletePublicFormListItem.bind(this)}
                      displayMessage={message => showSnackbar(message)}
                      onSaveLogo={this.savePublicFormLogo.bind(this)}
                      onDeleteLogo={this.deletePublicFormLogo.bind(this)}
                    />
                    : null}
                </Collapse>
                <Divider />
                <ListItem
                  button
                  onClick={() => { this.setState({ launchCodeExpanded: !launchCodeExpanded }) }}
                >
                  <ListItemText
                    primary={language.translate('application.viaLaunchCode')} />
                  {launchCodeExpanded ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={launchCodeExpanded} timeout='auto' unmountOnExit>
                  {(launchCodeListLoaded)
                    ? <LaunchCodeList
                      key='launch_code'
                      templateId={template.ID}
                      launchCodeList={launchCodeList}
                      onSaveList={this.saveLaunchCodeList.bind(this)}
                      onDelete={this.deleteLaunchCodeListItem.bind(this)}
                      displayMessage={message => showSnackbar(message)}
                    />
                    : null}
                </Collapse>
                <Divider />
                <ListItem
                  button
                  onClick={() => { this.setState({ webhookExpanded: !webhookExpanded }) }}
                >
                  <ListItemText
                    primary={language.translate('application.viaWebhook')} />
                  {webhookExpanded ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={webhookExpanded} timeout='auto' unmountOnExit>
                  {(webHookListLoaded)
                    ? <WebHookList
                      key='web_hook'
                      templateId={template.ID}
                      webHookList={webHookList}
                      onSaveList={this.saveWebHookList.bind(this)}
                      onDelete={this.deleteWebHookListItem.bind(this)}
                      fetchWebHookMappings={this.fetchWebHookMappings.bind(this)}
                      deleteWebHookMapItem={this.deleteWebHookMapItem.bind(this)}
                      saveWebHookMap={this.saveWebHookMap.bind(this)}
                      displayMessage={message => showSnackbar(message)}
                    />
                    : null}
                </Collapse>
                <Divider />
                <ListItem
                  button
                  onClick={() => { this.setState({ zapierExpanded: !zapierExpanded }) }}
                >
                  <ListItemText
                    primary={language.translate('application.viaZapier')} />
                  {zapierExpanded ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={zapierExpanded} timeout='auto' unmountOnExit>
                  <div
                    key='zapier'
                    style={{
                      padding: '10px 20px',
                      backgroundColor: palette.accent2Color,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <div>
                      {language.translate('application.zapierLaunchText')}
                    </div>
                    <div style={{ marginTop: '20px' }}>
                      <Button
                        href='https://zapier.com/app/explore'
                        target='_blank'
                        style={{
                          fontSize: '18px',
                          fontWeight: '600'
                        }}
                      >
                        {language.translate('application.goToZapier')}
                        <Icon style={{ paddingLeft: '5px' }} icon='ZP' size={30} />
                      </Button>
                    </div>
                  </div>
                </Collapse>
                <Divider />
                <ListItem
                  button
                  onClick={() => { this.setState({ restApiExpanded: !restApiExpanded }) }}
                >
                  <ListItemText
                    primary={language.translate('application.viaRestApi')} />
                  {restApiExpanded ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={restApiExpanded} timeout='auto' unmountOnExit>
                  <div
                    key='rest_api'
                    style={{
                      padding: '10px 20px',
                      backgroundColor: palette.accent2Color,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <div>
                      {language.translate('application.restApiText')}
                    </div>
                    <div style={{ marginTop: '20px' }}>
                      <div>
                        <Button
                          onClick={() => push('/api-endpoints')}
                          style={{
                            fontSize: '18px',
                            fontWeight: '600',
                            color: palette.primary1Color
                          }}
                        >
                          {language.translate('application.restApi')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Collapse>
                {user.canScheduleOrLaunchMultipleProcesses &&
                  <Divider />}
                {user.canScheduleOrLaunchMultipleProcesses &&
                  <ListItem
                    button
                    onClick={() => {
                      push({
                        pathname: location.pathname,
                        query: objectAssign({},
                          location.query,
                          {
                            ptype: 'process_template_import',
                            pid: template.ID,
                            ptsid: template.ID
                          })
                      })
                    }}
                  >
                    <ListItemText primary={language.translate('application.viaCsvFile')} />
                  </ListItem>}
              </List>
            </Card>
          </div>
          : null}
      </RightPanelContent>
    )
  }
}

ProcessLaunchSettings.propTypes = {
  templateId: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func,
  showUndoMessage: PropTypes.func.isRequired
}

ProcessLaunchSettings.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object,
  user: PropTypes.object,
  location: PropTypes.object
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  dispatch,
  post: bindActionCreators(post, dispatch),
  get: bindActionCreators(get, dispatch),
  showSnackbar: bindActionCreators(showSnackbar, dispatch),
  push: bindActionCreators(push, dispatch),
  showUndoMessage: bindActionCreators(showUndoMessage, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ProcessLaunchSettings)
