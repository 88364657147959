import React from 'react'
import 'es6-shim'
import 'babel-polyfill'
import 'url-search-params-polyfill'
import objectAssign from 'object-assign'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  setMainContentWidth, updateScreenWidth, updateScreenHeight, setRightPanelWidth, enableDebug, disableDebug,
  setMainAppRef
} from '../actions/application'
import { hideNavigation, showNavigation } from '../actions/navigation'
import { addViewedHelpPage, hasViewedHelpPage } from '../actions/authentication'
import { initializeScript } from '../actions/livechat'
import { setJoyrideObject } from '../actions/joyride'
import { connectToPusher } from '../actions/pusher'
import { push } from 'react-router-redux'
import { Header, Navigation } from '../components/Layout'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { Snackbar, Button, CssBaseline } from '@material-ui/core'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import { MuiThemeProvider as V0MuiThemeProvider } from 'material-ui'
import { hideSnackbar } from '../actions/snackbar'
import * as languages from '../lang'
import moment from 'moment'
import { setLocale } from 'yup'
import ScrollBarDetector from '../components/Layout/ScrollBarDetector'
import RightPanel from '../components/Layout/RightPanel'
import classNames from 'classnames'
import GuidedTour from '../components/Layout/GuidedTour'
import InactiveAccountMessage from '../components/Layout/InactiveAccountMessage'
import PropTypes from 'prop-types'
import deepmerge from 'deepmerge'
import Formsy from 'formsy-react'
import TranslationHelper from '../businessLogic/translationHelper'
import { DragDropContext, DropTarget } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import StopUpscope from '../components/Layout/StopUpscope'
import theme from '../styles/theme'
import themeV0 from '../styles/themeV0'
import palette from '../styles/palette'
import IdleSessionMonitor from '../components/Layout/IdleSessionMonitor'
import ErrorBoundary from '../components/Layout/ErrorBoundary'
import Bowser from 'bowser'

const browser = Bowser.getParser(window.navigator.userAgent)

const itemTarget = {
  drop (props, monitor) {
    const { setRightPanelWidth, rightPanelWidth } = props
    const xOffset = monitor.getDifferenceFromInitialOffset().x

    const newWidth = rightPanelWidth - xOffset

    setRightPanelWidth(newWidth)
  }
}

class App extends React.Component {
  getChildContext () {
    const languageFile = languages[this.props.language] || languages.en_US
    const messages = deepmerge(languages.en_US, languageFile) // fallback to english if a translation doesn't exist in file
    let user = objectAssign({}, this.props.user)
    user.theme = objectAssign({}, theme)

    const { customizations } = user
    if (customizations.theme && customizations.theme.palette && Object.keys(customizations.theme.palette).length) {
      user.theme.palette = objectAssign({}, theme.palette, customizations.theme.palette)
    } else {
      user.theme.palette = theme.palette
    }

    return {
      location: this.props.location,
      language: {
        translate: (messageId, values, isPlural) => TranslationHelper.translate(messages, messageId, values, isPlural),
        messages
      },
      user
    }
  }

  componentDidMount () {
    const { connectToPusher, isAuthenticated, user, initializeLiveChat, enableDebug, disableDebug } = this.props

    if (isAuthenticated) {
      connectToPusher()
      initializeLiveChat(user)
    }

    this.setBackgroundColor()
    this.calculateMainContentWidth()
    this.calculateScreenSize()

    // **************** CONSOLE HELPER METHODS *******************
    window.ppGetUserInfo = () => {
      return JSON.stringify(this.props.user, null, 2)
    }

    window.ppGetBrowserInfo = () => {
      return JSON.stringify(browser.getBrowser())
    }

    window.ppGetLocalStorage = () => {
      return JSON.stringify(window.localStorage, null, 2)
    }

    window.ppClearLocalStorage = () => {
      return window.localStorage.clear()
    }

    window.ppGetURL = () => {
      return this.props.location.pathname + this.props.location.search
    }

    window.ppDebug = () => {
      const { debug } = this.props

      if (debug) {
        if (disableDebug()) {
          return 'DEBUG MODE OFF'
        }
      } else {
        if (enableDebug()) {
          return 'DEBUG MODE ON'
        }
      }

      return 'UNEXPECTED ERROR'
    }

    window.ppCommands = () => {
      let commandsString = '\n'

      const commands = [
        {
          command: 'ppDebug',
          description: 'Turn on/off debug mode which logs interactions to the console'
        }, {
          command: 'ppGetURL',
          description: 'View the current URL'
        }, {
          command: 'ppClearLocalStorage',
          description: 'Clear the users local storage'
        }, {
          command: 'ppGetLocalStorage',
          description: 'View the users local storage data'
        }, {
          command: 'ppGetUserInfo',
          description: 'View the current users info'
        }, {
          command: 'ppGetBrowserInfo',
          description: 'View the current users browser name and version'
        }
      ]

      commands.map((command) => {
        const commandCall = command.command + '()'
        commandsString += `${commandCall.padEnd(25)} - ${command.description}\n`
      })

      return commandsString
    }

    // Set moments default location language
    moment.locale(this.props.language)

    // Set yups default locale dictionary
    const languageFile = languages[this.props.language] || languages.en_US
    const messages = deepmerge(languages.en_US, languageFile)

    setLocale({
      mixed: {
        required: TranslationHelper.translate(messages, 'validationErrors.isDefaultRequiredValue')
      },
      string: {
        email: TranslationHelper.translate(messages, 'validationErrors.isEmail')
      }
    })

    // enable sentry error tracking
    // if (['staging', 'production'].indexOf(process.env.NODE_ENV) >= 0) {
    //   var _rollbarConfig = {
    //     accessToken: '7b6b834dab394fe89363296f4c5a5998',
    //     captureUncaught: true,
    //     payload: {
    //       environment: process.env.NODE_ENV
    //     }
    //   }

    //   !(function (r) {
    //     function o (n) {
    //       if (e[n]) return e[n].exports
    //       var t = e[n] = { exports: {}, id: n, loaded: !1 }
    //       return r[n].call(t.exports, t, t.exports, o), t.loaded = !0, t.exports
    //     }

    //     var e = {}
    //     return o.m = r, o.c = e, o.p = '', o(0)
    //   }([function (r, o, e) {
    //     'use strict'
    //     var n = e(1), t = e(4)
    //     _rollbarConfig = _rollbarConfig || {}, _rollbarConfig.rollbarJsUrl = _rollbarConfig.rollbarJsUrl || 'https://cdnjs.cloudflare.com/ajax/libs/rollbar.js/2.3.1/rollbar.min.js', _rollbarConfig.async = void 0 === _rollbarConfig.async || _rollbarConfig.async
    //     var a = n.setupShim(window, _rollbarConfig), l = t(_rollbarConfig)
    //     window.rollbar = n.Rollbar, a.loadFull(window, document, !_rollbarConfig.async, _rollbarConfig, l)
    //   }, function (r, o, e) {
    //     'use strict'

    //     function n (r) { return function () { try { return r.apply(this, arguments) } catch (r) { try { console.error('[Rollbar]: Internal error', r) } catch (r) {} } } }

    //     function t (r, o) {
    //       this.options = r, this._rollbarOldOnError = null
    //       var e = s++
    //       this.shimId = function () { return e }, window && window._rollbarShims && (window._rollbarShims[e] = {
    //         handler: o,
    //         messages: []
    //       })
    //     }

    //     function a (r, o) {
    //       var e = o.globalAlias || 'Rollbar'
    //       if ('object' == typeof r[e]) return r[e]
    //       r._rollbarShims = {}, r._rollbarWrappedError = null
    //       var t = new p(o)
    //       return n(function () {
    //         o.captureUncaught && (t._rollbarOldOnError = r.onerror, i.captureUncaughtExceptions(r, t, !0), i.wrapGlobals(r, t, !0)), o.captureUnhandledRejections && i.captureUnhandledRejections(r, t, !0)
    //         var n = o.autoInstrument
    //         return (void 0 === n || n === !0 || 'object' == typeof n && n.network) && r.addEventListener && (r.addEventListener('load', t.captureLoad.bind(t)), r.addEventListener('DOMContentLoaded', t.captureDomContentLoaded.bind(t))), r[e] = t, t
    //       })()
    //     }

    //     function l (r) {
    //       return n(function () {
    //         var o = this, e = Array.prototype.slice.call(arguments, 0),
    //           n = { shim: o, method: r, args: e, ts: new Date() }
    //         window._rollbarShims[this.shimId()].messages.push(n)
    //       })
    //     }

    //     var i = e(2), s = 0, d = e(3), c = function (r, o) { return new t(r, o) }, p = d.bind(null, c)
    //     t.prototype.loadFull = function (r, o, e, t, a) {
    //       var l = function () {
    //         var o
    //         if (void 0 === r._rollbarDidLoad) {
    //           o = new Error('rollbar.js did not load')
    //           for (var e, n, t, l, i = 0; e = r._rollbarShims[i++];) for (e = e.messages || []; n = e.shift();) for (t = n.args || [], i = 0; i < t.length; ++i) if (l = t[i], 'function' == typeof l) {
    //             l(o)
    //             break
    //           }
    //         }
    //         'function' === typeof a && a(o)
    //       }, i = !1, s = o.createElement('script'), d = o.getElementsByTagName('script')[0], c = d.parentNode
    //       s.crossOrigin = '', s.src = t.rollbarJsUrl, e || (s.async = !0), s.onload = s.onreadystatechange = n(function () {
    //         if (!(i || this.readyState && 'loaded' !== this.readyState && 'complete' !== this.readyState)) {
    //           s.onload = s.onreadystatechange = null
    //           try { c.removeChild(s) } catch (r) {}
    //           i = !0, l()
    //         }
    //       }), c.insertBefore(s, d)
    //     }, t.prototype.wrap = function (r, o, e) {
    //       try {
    //         var n
    //         if (n = 'function' === typeof o ? o : function () { return o || {} }, 'function' != typeof r) return r
    //         if (r._isWrap) return r
    //         if (!r._rollbar_wrapped && (r._rollbar_wrapped = function () {
    //           e && 'function' === typeof e && e.apply(this, arguments)
    //           try { return r.apply(this, arguments) } catch (e) {
    //             var o = e
    //             throw 'string' == typeof o && (o = new String(o)), o._rollbarContext = n() || {}, o._rollbarContext._wrappedSource = r.toString(), window._rollbarWrappedError = o, o
    //           }
    //         }, r._rollbar_wrapped._isWrap = !0, r.hasOwnProperty)) for (var t in r) r.hasOwnProperty(t) && (r._rollbar_wrapped[t] = r[t])
    //         return r._rollbar_wrapped
    //       } catch (o) { return r }
    //     }
    //     for (var u = 'log,debug,info,warn,warning,error,critical,global,configure,handleUncaughtException,handleUnhandledRejection,captureDomContentLoaded,captureLoad'.split(','), f = 0; f < u.length; ++f) t.prototype[u[f]] = l(u[f])
    //     r.exports = { setupShim: a, Rollbar: p }
    //   }, function (r, o) {
    //     'use strict'

    //     function e (r, o, e) {
    //       if (r) {
    //         var t
    //         'function' === typeof o._rollbarOldOnError ? t = o._rollbarOldOnError : r.onerror && !r.onerror.belongsToShim && (t = r.onerror, o._rollbarOldOnError = t)
    //         var a = function () {
    //           var e = Array.prototype.slice.call(arguments, 0)
    //           n(r, o, t, e)
    //         }
    //         a.belongsToShim = e, r.onerror = a
    //       }
    //     }

    //     function n (r, o, e, n) { r._rollbarWrappedError && (n[4] || (n[4] = r._rollbarWrappedError), n[5] || (n[5] = r._rollbarWrappedError._rollbarContext), r._rollbarWrappedError = null), o.handleUncaughtException.apply(o, n), e && e.apply(r, n) }

    //     function t (r, o, e) {
    //       if (r) {
    //         'function' == typeof r._rollbarURH && r._rollbarURH.belongsToShim && r.removeEventListener('unhandledrejection', r._rollbarURH)
    //         var n = function (r) {
    //           var e = r.reason, n = r.promise, t = r.detail
    //           !e && t && (e = t.reason, n = t.promise), o && o.handleUnhandledRejection && o.handleUnhandledRejection(e, n)
    //         }
    //         n.belongsToShim = e, r._rollbarURH = n, r.addEventListener('unhandledrejection', n)
    //       }
    //     }

    //     function a (r, o, e) {
    //       if (r) {
    //         var n, t,
    //           a = 'EventTarget,Window,Node,ApplicationCache,AudioTrackList,ChannelMergerNode,CryptoOperation,EventSource,FileReader,HTMLUnknownElement,IDBDatabase,IDBRequest,IDBTransaction,KeyOperation,MediaController,MessagePort,ModalWindow,Notification,SVGElementInstance,Screen,TextTrack,TextTrackCue,TextTrackList,WebSocket,WebSocketWorker,Worker,XMLHttpRequest,XMLHttpRequestEventTarget,XMLHttpRequestUpload'.split(',')
    //         for (n = 0; n < a.length; ++n) t = a[n], r[t] && r[t].prototype && l(o, r[t].prototype, e)
    //       }
    //     }

    //     function l (r, o, e) {
    //       if (o.hasOwnProperty && o.hasOwnProperty('addEventListener')) {
    //         for (var n = o.addEventListener; n._rollbarOldAdd && n.belongsToShim;) n = n._rollbarOldAdd
    //         var t = function (o, e, t) { n.call(this, o, r.wrap(e), t) }
    //         t._rollbarOldAdd = n, t.belongsToShim = e, o.addEventListener = t
    //         for (var a = o.removeEventListener; a._rollbarOldRemove && a.belongsToShim;) a = a._rollbarOldRemove
    //         var l = function (r, o, e) { a.call(this, r, o && o._rollbar_wrapped || o, e) }
    //         l._rollbarOldRemove = a, l.belongsToShim = e, o.removeEventListener = l
    //       }
    //     }

    //     r.exports = { captureUncaughtExceptions: e, captureUnhandledRejections: t, wrapGlobals: a }
    //   }, function (r, o) {
    //     'use strict'

    //     function e (r, o) { this.impl = r(o, this), this.options = o, n(e.prototype) }

    //     function n (r) {
    //       for (var o = function (r) {
    //         return function () {
    //           var o = Array.prototype.slice.call(arguments, 0)
    //           if (this.impl[r]) return this.impl[r].apply(this.impl, o)
    //         }
    //       }, e = 'log,debug,info,warn,warning,error,critical,global,configure,handleUncaughtException,handleUnhandledRejection,_createItem,wrap,loadFull,shimId,captureDomContentLoaded,captureLoad'.split(','), n = 0; n < e.length; n++) r[e[n]] = o(e[n])
    //     }

    //     e.prototype._swapAndProcessMessages = function (r, o) {
    //       this.impl = r(this.options)
    //       for (var e, n, t; e = o.shift();) n = e.method, t = e.args, this[n] && 'function' === typeof this[n] && ('captureDomContentLoaded' === n || 'captureLoad' === n ? this[n].apply(this, [t[0], e.ts]) : this[n].apply(this, t))
    //       return this
    //     }, r.exports = e
    //   }, function (r, o) {
    //     'use strict'
    //     r.exports = function (r) {
    //       return function (o) {
    //         if (!o && !window._rollbarInitialized) {
    //           r = r || {}
    //           for (var e, n, t = r.globalAlias || 'Rollbar', a = window.rollbar, l = function (r) { return new a(r) }, i = 0; e = window._rollbarShims[i++];) n || (n = e.handler), e.handler._swapAndProcessMessages(l, e.messages)
    //           window[t] = n, window._rollbarInitialized = !0
    //         }
    //       }
    //     }
    //   }]))

    //   this.setRollbarContext()
    // }

    // Add custom validation rules to Formsy
    // TODO: pull this out into a seperate file
    Formsy.addValidationRule('isMoreThan', (values, value, argument) => {
      const isNumeric = (!isNaN(parseFloat(argument)) && isFinite(argument))

      if (isNumeric) { return Number(value) > argument }

      return Number(value) > Number(values[argument])
    })

    Formsy.addValidationRule('isEmailMultiple', (values, value) => {
      if (!value) {
        return true
      }

      let emails = value.split(';')
      let isValid = true
      const emailRegex = RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i)

      emails.map(email => {
        if (!emailRegex.test(email.trim())) {
          isValid = false
        }
      })

      return isValid
    })

    Formsy.addValidationRule('isMoney', (values, value) => {
      if (!value) {
        return true
      }

      const moneyRegex = RegExp(/^(?=.*?\d)^[\$\¥\£\€]?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d+)?$/i)

      return (moneyRegex.test(value.trim()))
    })

    Formsy.addValidationRule('isIPAddress', (values, value) => {
      if (!value) {
        return true
      }

      const ipRegex = RegExp(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/i)

      return (ipRegex.test(value.trim()))
    })
  }

  componentDidUpdate (prevProps) {
    const { user, initializeLiveChat, connectToPusher, isLiveChatLoaded } = this.props
    this.setBackgroundColor()

    if (prevProps.isNavOpen !== this.props.isNavOpen || prevProps.isRightPanelOpen !== this.props.isRightPanelOpen || prevProps.screenWidth !== this.props.screenWidth) { this.calculateMainContentWidth() }

    if (!prevProps.isAuthenticated && this.props.isAuthenticated) {
      connectToPusher()

      // if (['staging', 'production'].indexOf(process.env.NODE_ENV) >= 0) { this.setRollbarContext() }

      if (!isLiveChatLoaded) { initializeLiveChat(user) }

      // inject an "auth" query parameter for 1 second then remove it
      // this is so the go-native wrapper can key off this url and
      // provide the api with the authenticated users device id
      let { location: { query: { next, ...query } }, push } = this.props
      query = objectAssign({}, query, { auth: true })
      push({ pathname: next, query })
      setTimeout(() => {
        let newQuery = this.props.location.query

        delete newQuery.auth

        push({ pathname: this.props.location.pathname, query: newQuery })
      }, 1000)
    }
  }

  // setRollbarContext () {
  //   const { user } = this.props

  //   window.Rollbar.configure({
  //     payload: {
  //       person: {
  //         id: user.userid || 0,
  //         email: user.userName || null,
  //         accountId: user.accountID || 0,
  //         accountName: user.accountName || null
  //       }
  //     }
  //   })
  // }

  setBackgroundColor () {
    const uri = this.props.location.pathname

    document.body.style.backgroundColor = uri.startsWith('login') || uri.startsWith('/login') || uri.startsWith('reset-password') || uri.startsWith('/reset-password') ? '#303435' : '#eee'
  }

  calculateMainContentWidth () {
    const { rightPanelWidth, isNavOpen, isRightPanelOpen, setMainContentWidth } = this.props

    let width = document.body.clientWidth

    if (isNavOpen) { width -= 255 }

    if (isRightPanelOpen) { width -= rightPanelWidth }

    setMainContentWidth(width)
  }

  calculateScreenSize () {
    const { rightPanelWidth, updateScreenWidth, updateScreenHeight, setRightPanelWidth } = this.props
    /*
     * Update screen size in application state if it changes
     */
    updateScreenWidth(document.body.clientWidth)
    updateScreenHeight(window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight)

    window.addEventListener('resize', () => {
      const width = document.body.clientWidth
      const height = document.body.clientHeight

      if (this.props.screenWidth !== width) {
        updateScreenWidth(width)
        this.calculateMainContentWidth()

        // automatically show and hide the nav panel if needed when the screen changes size
        if (width > 1199) { this.props.dispatch(showNavigation()) } else { this.props.dispatch(hideNavigation()) }

        if (width < rightPanelWidth) { setRightPanelWidth(width) }
      }

      if (this.props.screenHeight !== height) {
        updateScreenHeight(height)
      }
    })
  }

  render () {
    const {
      dispatch, isAuthenticated, isNavOpen, children, snackbar, isFullScreen, joyride, addViewedHelpPage,
      hasViewedHelpPage, setJoyrideObject, hideSnackbar, push, user, isRightPanelOpen, rightPanelWidth, mainContentWidth,
      connectDropTarget, setMainAppRef, mainAppRef
    } = this.props

    let v0Theme = objectAssign({}, themeV0)
    let newTheme = objectAssign({}, theme)

    if (user.customizations.theme && user.customizations.theme.palette) {
      v0Theme.palette = objectAssign({}, v0Theme.palette, user.customizations.theme.palette)
      newTheme.palette = objectAssign({}, newTheme.palette, user.customizations.theme.palette)
    }

    return (
      <MuiThemeProvider theme={createMuiTheme(newTheme)}>
        <V0MuiThemeProvider muiTheme={getMuiTheme(v0Theme)}>
          <div style={{ height: '100%' }}>
            <CssBaseline />
            {/* fake fields are a workaround for chrome autofill getting the wrong fields */}
            <input
              style={{ visibility: 'hidden', height: 0, width: '1px', position: 'absolute', left: 0, top: 0 }}
              type='text'
              name='fakeusernameremembered'
            />
            <input
              style={{ visibility: 'hidden', height: 0, width: '1px', position: 'absolute', left: 0, top: 0 }}
              type='password'
              name='fakepasswordremembered'
            />
            <ScrollBarDetector />
            {(isAuthenticated && !this.props.location.pathname.includes('handshake'))
              ? connectDropTarget(
                <div style={{ height: '100%' }}>
                  <GuidedTour
                    addViewedHelpPage={addViewedHelpPage}
                    hasViewedHelpPage={hasViewedHelpPage}
                    joyride={joyride}
                    onInit={joyrideObject => setJoyrideObject(joyrideObject)}
                  />
                  <Header location={this.props.location} dispatch={dispatch} />
                  <Navigation dispatch={dispatch} location={this.props.location} />
                  <InactiveAccountMessage onMessageClick={() => push('plan')} />
                  <IdleSessionMonitor location={this.props.location} />
                  <div
                    ref={(ref) => {
                      if (ref && !mainAppRef) {
                        setMainAppRef(ref)
                      }
                    }}
                    className={
                      classNames(
                        'main-content', 'animate',
                        { 'nav-opened': (isNavOpen) }
                      )}
                    style={{
                      paddingRight: (isRightPanelOpen && mainContentWidth > 500) ? `${rightPanelWidth + 20}px` : '20px'
                    }}
                  >
                    <div style={{
                      width: (mainContentWidth > 1200 && !isFullScreen) ? '1200px' : '100%',
                      height: '100%',
                      display: 'grid'
                    }}
                    >
                      <ErrorBoundary>
                        {children}
                      </ErrorBoundary>
                    </div>
                  </div>
                  <RightPanel
                    location={this.props.location}
                  />
                  <StopUpscope />
                </div>
              )
              : <div
                className={'container-fluid'}
                style={{ paddingTop: '20px', height: '100%', overflowY: 'auto' }}
              >
                {children}
              </div>

            }
            <Snackbar
              open={snackbar.isOpen}
              message={snackbar.message.message || ''}
              action={(snackbar.message.action)
                ? <Button
                  style={{ color: palette.accent5Color }}
                  size='small'
                  onClick={() => {
                    snackbar.message.actionEvent()
                    hideSnackbar()
                  }}
                >
                  {snackbar.message.action}
                </Button>
                : null}
              style={{
                marginBottom: '20px'
              }}
              autoHideDuration={snackbar.message.duration}
              disableWindowBlurListener
              onClose={(e, reason) => {
                if (reason === 'clickaway' && snackbar.message.duration) {
                  return false
                }
                hideSnackbar()
              }}
            />
          </div>
        </V0MuiThemeProvider>
      </MuiThemeProvider>
    )
  }
}

App.childContextTypes = {
  muiTheme: PropTypes.object,
  location: PropTypes.object,
  language: PropTypes.object,
  user: PropTypes.object
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  children: PropTypes.object.isRequired,
  isNavOpen: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  location: PropTypes.object,
  snackbar: PropTypes.object,
  language: PropTypes.string,
  isFullScreen: PropTypes.bool,
  updateScreenWidth: PropTypes.func.isRequired,
  updateScreenHeight: PropTypes.func.isRequired,
  screenWidth: PropTypes.number.isRequired,
  screenHeight: PropTypes.number.isRequired,
  isRightPanelOpen: PropTypes.bool.isRequired,
  setMainContentWidth: PropTypes.func.isRequired,
  setRightPanelWidth: PropTypes.func.isRequired,
  mainContentWidth: PropTypes.number,
  rightPanelWidth: PropTypes.number,
  joyride: PropTypes.object,
  user: PropTypes.object,
  addViewedHelpPage: PropTypes.func.isRequired,
  hasViewedHelpPage: PropTypes.func.isRequired,
  setJoyrideObject: PropTypes.func,
  hideSnackbar: PropTypes.func,
  push: PropTypes.func.isRequired,
  connectToPusher: PropTypes.func.isRequired,
  initializeLiveChat: PropTypes.func.isRequired,
  isLiveChatLoaded: PropTypes.bool,
  debug: PropTypes.bool,
  enableDebug: PropTypes.func.isRequired,
  disableDebug: PropTypes.func.isRequired,
  setMainAppRef: PropTypes.func.isRequired,
  mainAppRef: PropTypes.object,

  connectDropTarget: PropTypes.func,
  canDrop: PropTypes.bool
}

function mapStateToProps (state) {
  return {
    errorMessage: state.errorMessage,
    isAuthenticated: state.auth.isAuthenticated,
    isNavOpen: state.navigation.open,
    snackbar: state.snackbar,
    language: state.auth.language,
    isFullScreen: state.application.fullScreen,
    screenWidth: state.application.screenWidth,
    screenHeight: state.application.screenHeight,
    isRightPanelOpen: state.application.rightPanelOpen,
    mainContentWidth: state.application.mainContentWidth,
    debug: state.application.debug,
    joyride: state.joyride,
    user: state.auth,
    isLiveChatLoaded: state.livechat.isLoaded,
    rightPanelWidth: state.application.rightPanelWidth,
    mainAppRef: state.application.mainAppRef
  }
}

const mapDispatchToProps = dispatch => ({
  updateScreenWidth: bindActionCreators(updateScreenWidth, dispatch),
  updateScreenHeight: bindActionCreators(updateScreenHeight, dispatch),
  setMainContentWidth: bindActionCreators(setMainContentWidth, dispatch),
  dispatch,
  addViewedHelpPage: bindActionCreators(addViewedHelpPage, dispatch),
  hasViewedHelpPage: bindActionCreators(hasViewedHelpPage, dispatch),
  setJoyrideObject: bindActionCreators(setJoyrideObject, dispatch),
  hideSnackbar: bindActionCreators(hideSnackbar, dispatch),
  push: bindActionCreators(push, dispatch),
  connectToPusher: bindActionCreators(connectToPusher, dispatch),
  initializeLiveChat: bindActionCreators(initializeScript, dispatch),
  setRightPanelWidth: bindActionCreators(setRightPanelWidth, dispatch),
  enableDebug: bindActionCreators(enableDebug, dispatch),
  disableDebug: bindActionCreators(disableDebug, dispatch),
  setMainAppRef: bindActionCreators(setMainAppRef, dispatch)
})

const connectTarget = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  canDrop: monitor.canDrop()
})

export default connect(mapStateToProps, mapDispatchToProps)(
  DragDropContext(HTML5Backend)(
    DropTarget('rightPanelResize', itemTarget, connectTarget)(App)))
