import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get } from '../../actions/base'
import { Card, CardContent, CardHeader, CircularProgress, Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import Masonry from 'react-masonry-component'
import BarCard from './BarCard'
import AreaCard from './AreaCard'

const masonryOptions = {
  transitionDuration: '0.0s'
}

class User extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      userId: props.userId,
      averageResponse: [],
      averageResponseLoaded: false,
      executionCount: [],
      executionCountLoaded: false,
      user: null,
      userLoaded: false,
      numTasksCompleted: [],
      numTasksCompletedLoaded: false,
      averageTaskResponseTime: [],
      averageTaskResponseTimeLoaded: false,
      tasksCompleted: [],
      tasksCompletedLoaded: false,
      tasksPending: [],
      tasksPendingLoaded: false,
      pendingTaskCount: [],
      pendingTaskCountLoaded: false
    }
  }

  componentDidMount () {
    this.fetchData()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.userId && this.props.userId !== prevProps.userId) {
      this.setState({ userId: this.props.userId }, this.fetchData)
    }

    if (this.props.endDate !== prevProps.endDate ||
      this.props.startDate !== prevProps.startDate ||
      this.props.by !== prevProps.by ||
      this.props.time !== prevProps.time) {
      this.fetchData()
    }
  }

  fetchData () {
    const { by, time, startDate, endDate, userId } = this.props
    const dateFilter = this.props.parseDateFilter(startDate, endDate)

    if (!userId) {
      return
    }

    this.fetchUserData('', 'user', 'User')
    this.fetchUserData(`/report/averageresponsetime/in${time}/by${by}${dateFilter}`, 'averageResponse')
    this.fetchUserData(`/report/processexecutioncount/byprocess${dateFilter}`, 'executionCount')
    this.fetchUserData(`/report/taskscompleted/by${by}${dateFilter}`, 'numTasksCompleted')
    this.fetchUserData(`/report/averageresponsetime/in${time}/bytask${dateFilter}`, 'averageTaskResponseTime')
    this.fetchUserData(`/report/taskscompleted/bytask${dateFilter}`, 'tasksCompleted')
    this.fetchUserData('/report/taskspending/bytask', 'tasksPending')
    this.fetchUserData('/task/list/pending/count', 'pendingTaskCount', 'Count')
  }

  fetchUserData (endpoint, stateAttribute, responseAttribute = 'ReportSeriesList') {
    const { userId } = this.props

    this.setState({ [`${stateAttribute}Loaded`]: false })

    this.props.get(`user/${userId}${endpoint}`, {
      onSuccess: (response) => {
        this.setState({
          [stateAttribute]: response[responseAttribute],
          [`${stateAttribute}Loaded`]: true
        })
      }
    })
  }

  render () {
    const palette = this.context.muiTheme.palette
    const { language } = this.context
    const {
      averageResponse, averageResponseLoaded, executionCount, executionCountLoaded, numTasksCompleted,
      numTasksCompletedLoaded, user, userLoaded, averageTaskResponseTime, averageTaskResponseTimeLoaded,
      tasksCompleted, tasksCompletedLoaded, tasksPending, tasksPendingLoaded, pendingTaskCount,
      pendingTaskCountLoaded
    } = this.state
    const { mainContentWidth, handleProcessClick, handleTaskClick, time } = this.props

    const styles = {
      cardStyles: {
        width: (mainContentWidth < 800) ? '95%' : '45%',
        margin: '10px'
      },
      processInfoTableRow: {
        border: 'none',
        height: '20px'
      },
      processInfoLabel: {
        textAlign: 'right',
        width: '180px',
        height: 'inherit',
        padding: '0px'
      },
      processInfoData: {
        height: 'inherit'
      }
    }

    return (
      <Masonry options={masonryOptions}>
        <Card style={styles.cardStyles}>
          <CardHeader
            title={language.translate('application.userInformation')}
          />
          <CardContent>
            {(userLoaded && pendingTaskCountLoaded)
              ? <Table>
                <TableBody>
                  <TableRow style={styles.processInfoTableRow}>
                    <TableCell style={styles.processInfoLabel}>
                      {language.translate('application.user', [], true)}:
                    </TableCell>
                    <TableCell style={styles.processInfoData}>
                      {user.FullName}
                    </TableCell>
                  </TableRow>
                  <TableRow style={styles.processInfoTableRow}>
                    <TableCell style={styles.processInfoLabel}>
                      {language.translate('application.position')}:
                    </TableCell>
                    <TableCell style={styles.processInfoData}>
                      {user.Position}
                    </TableCell>
                  </TableRow>
                  <TableRow style={styles.processInfoTableRow}>
                    <TableCell style={styles.processInfoLabel}>
                      {language.translate('application.department')}:
                    </TableCell>
                    <TableCell style={styles.processInfoData}>
                      {user.Department}
                    </TableCell>
                  </TableRow>
                  <TableRow style={styles.processInfoTableRow}>
                    <TableCell style={styles.processInfoLabel}>
                      {language.translate('application.currentPendingTasks')}:
                    </TableCell>
                    <TableCell style={styles.processInfoData}>
                      {pendingTaskCount}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              : <CircularProgress className='loader' />}
          </CardContent>
        </Card>

        <AreaCard
          title={language.translate('application.averageResponseTimeIn', [time])}
          subtitle={language.translate('application.averageResponseTimeInForUserMessage')}
          data={averageResponse}
          isDataLoaded={averageResponseLoaded}
          tooltipPrefix={time}
          mainContentWidth={mainContentWidth}
        />

        <BarCard
          title={language.translate('application.currentlyPendingTasksForUser')}
          subtitle={language.translate('application.currentlyPendingTasksForUserMessage')}
          data={tasksPending}
          isDataLoaded={tasksPendingLoaded}
          onClick={handleTaskClick}
          limit={10}
          tooltipPrefix={language.translate('application.task', [], true)}
          mainContentWidth={mainContentWidth}
        />

        <BarCard
          title={language.translate('application.processesInitiatedByUser')}
          subtitle={language.translate('application.processesInitiatedByUserMessage')}
          data={executionCount}
          isDataLoaded={executionCountLoaded}
          onClick={handleProcessClick}
          limit={10}
          tooltipPrefix={language.translate('application.executions')}
          mainContentWidth={mainContentWidth}
        />

        <AreaCard
          title={language.translate('application.tasksCompletedByUser')}
          subtitle={language.translate('application.tasksCompletedByUserMessage')}
          data={numTasksCompleted}
          isDataLoaded={numTasksCompletedLoaded}
          tooltipPrefix={language.translate('application.task', [], true)}
          mainContentWidth={mainContentWidth}
          fillColor={palette.primary2Color}
        />

        <BarCard
          title={language.translate('application.slowestTaskResponseTimes')}
          subtitle={language.translate('application.slowestTaskResponseTimesMessage')}
          data={averageTaskResponseTime}
          isDataLoaded={averageTaskResponseTimeLoaded}
          onClick={handleTaskClick}
          limit={10}
          tooltipPrefix={time}
          mainContentWidth={mainContentWidth}
        />

        <BarCard
          title={language.translate('application.topTasksCompletedByUser')}
          subtitle={language.translate('application.topTasksCompletedByUserMessage')}
          data={tasksCompleted}
          isDataLoaded={tasksCompletedLoaded}
          onClick={handleTaskClick}
          limit={10}
          tooltipPrefix={language.translate('application.completed')}
          mainContentWidth={mainContentWidth}
        />
      </Masonry>
    )
  }
}

User.propTypes = {
  userId: PropTypes.string,
  get: PropTypes.func.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  by: PropTypes.string,
  time: PropTypes.string,
  parseDateFilter: PropTypes.func.isRequired,
  mainContentWidth: PropTypes.number,
  handleProcessClick: PropTypes.func.isRequired,
  handleTaskClick: PropTypes.func.isRequired
}

User.contextTypes = {
  muiTheme: PropTypes.object,
  language: PropTypes.object
}

const mapStateToProps = state => ({
  mainContentWidth: state.application.mainContentWidth
})

const mapDispatchToProps = dispatch => ({
  get: bindActionCreators(get, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(User)
