import PropTypes from 'prop-types'
import React from 'react'
import { Avatar, IconButton, Tooltip } from '@material-ui/core'
import { MenuItem } from 'material-ui'
import { Edit } from '@material-ui/icons'
import objectAssign from 'object-assign'
import FormHelper from '../../businessLogic/formHelper'
import { get } from '../../actions/base'
import { FormsySelect } from 'formsy-material-ui'
import FieldWrapper from './FieldWrapper'
import ContactCardDialog from '../User/ContactCardDialog'

class PPlanUsersAll extends React.Component {
  constructor (props) {
    super(props)

    this.state = this.setInitialValues()
  }

  componentDidMount () {
    if (!this.props.field.ReadOnlyField && this.state.groupValue) { this.fetchGroupUsers(this.state.groupValue) }
  }

  componentDidUpdate (prevProps, prevState, prevContext) {
    if (prevProps.field.ReadOnlyField && !this.props.field.ReadOnlyField && this.state.groupValue) {
      this.fetchGroupUsers(this.state.groupValue)
    }
  }

  componentWillUnmount () {
    this.isUnmounted = true
  }

  fetchGroupUsers (groupID) {
    if (!groupID || groupID === '0') {
      return false
    }

    const { publicFormToken } = this.props
    const endpoint = (publicFormToken) ? `public/publicform/usergroup/${groupID}/user/list` : `usergroup/${groupID}/user/list`
    const headers = (publicFormToken) ? { token: publicFormToken } : {}

    this.props.dispatch(get(endpoint, {
      onSuccess: (response) => {
        if (!this.isUnmounted) {
          this.setState({
            users: response.UserList
          })
        }
      },

      headers: headers,
      isHandshake: null,
      isSecondary: true
    }))
  }

  handleGroupChange (event, groupValue, index) {
    let { userValue } = this.state
    const { field, onChange } = this.props

    userValue = (groupValue !== '0') ? userValue : '0'

    field.UserGroupID = groupValue
    field.ValueID = `${groupValue} \\ ${userValue}`

    if (groupValue === '0') {
      field.Value = ''
    }

    this.fetchGroupUsers(groupValue)

    this.setState({ groupValue, userValue }, () => {
      if (onChange) { onChange(field) }
    })
  }

  handleUserChange (event, userValue, index) {
    const { groupValue } = this.state
    const { field, onChange } = this.props

    field.UserID = userValue

    if (userValue === '0' && field.Value) {
      field.Value = field.Value.split('\\')[0]
    }

    field.ValueID = `${groupValue} \\ ${userValue}`

    this.setState({ userValue }, () => {
      if (onChange) { onChange(field) }
    })
  }

  setInitialValues () {
    let selectedGroupValue = ''
    let selectedUserValue = ''

    if (this.props.field.ValueID) {
      const values = this.props.field.ValueID.split('\\')
      selectedGroupValue = (values[0]) ? values[0].trim() : ''
      selectedUserValue = (values[1]) ? values[1].trim() : ''
    }

    return {
      groupValue: selectedGroupValue,
      userValue: selectedUserValue,
      users: [],
      contactCardOpen: false,
      edit: (!this.props.field.ProcessInstanceID || !this.props.field.Value)
    }
  }

  getGroupOptions () {
    const { language } = this.context
    const { field } = this.props

    const groupOptions = [
      <MenuItem
        value='0'
        key={0}
        primaryText={(this.state.groupValue === '') ? '' : language.translate('application.selectAGroup')}
      />]

    if (field.UserGroupOptionList && field.UserGroupOptionList.length) {
      let userGroups = field.UserGroupOptionList.filter((group) => (!group.IsOnDemand))
      let odsGroups = field.UserGroupOptionList.filter((group) => (group.IsOnDemand))
      let odsGroupsGrouped = odsGroups.reduce((acc, group) => {
        const odsCompanyName = group.AccountID
        acc[odsCompanyName] = acc[odsCompanyName] || []
        acc[odsCompanyName].push(group)
        return acc
      }, {})

      userGroups.map((group) => {
        groupOptions.push(<MenuItem value={group.ID} key={group.ID} primaryText={group.GroupName} />)
      })

      if (odsGroups.length) {
        Object.keys(odsGroupsGrouped).sort().map((groupId, index) => {
          let groups = odsGroupsGrouped[groupId]

          groupOptions.push(
            <MenuItem
              style={{ color: 'rgba(0,0,0,0.6)' }}
              disabled
              value={'ODS'}
              key={`ODS-${groupId}`}
              primaryText={`-- ${language.translate('application.servicesBy')} ${groups[0].AccountName} --`} />)

          groups.map((group) => {
            groupOptions.push(<MenuItem value={group.ID} key={group.ID} primaryText={group.GroupName} />)
          })
        })
      }
    }

    return groupOptions
  }

  render () {
    const { language, muiTheme: { palette } } = this.context
    const { field, wrapperProps } = this.props
    const { edit } = this.state

    const userOptions = [
      <MenuItem
        value='0'
        key={0}
        primaryText={(this.state.userValue === '') ? '' : language.translate('application.selectAUser')}
      />]

    if (this.state.users) {
      this.state.users.map((option) => {
        userOptions.push(
          <MenuItem
            value={option.ID}
            key={option.ID}
            primaryText={option.FullName}
            leftIcon={<Avatar src={option.ProfilePicURL} style={{ height: '40px', width: '40px' }} />}
            innerDivStyle={{ padding: '5px 0px 10px 75px' }}
          />)
      })
    }

    return (
      <FieldWrapper
        label={FormHelper.decodeHTML(field.FieldName)}
        required={field.RequiredField}
        showDivider={(!edit)}
        {...wrapperProps}
      >
        {(!edit || field.ReadOnlyField)
          ? <div
            style={{
              position: 'relative',
              padding: '5px 0px',
              fontSize: '15px',
              display: 'flex',
              flexDirection: 'row'
            }}>
            <div style={{ flex: 5, paddingTop: '15px', width: '80%' }}>
              <div style={{ display: 'flex' }}>
                <div style={{ paddingRight: '10px' }}>
                  {language.translate('application.group')}:
                </div>
                <a
                  onClick={(e) => {
                    this.props.push({
                      pathname: this.context.location.pathname,
                      query: objectAssign({},
                        this.context.location.query,
                        {
                          ptype: 'group',
                          pid: this.state.groupValue,
                          gsid: this.state.groupValue
                        })
                    })
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                  style={{ cursor: 'pointer' }}>{field.Value.split('\\')[0]}
                </a>
              </div>
              <div style={{ display: 'flex' }}>
                <div style={{ paddingRight: '10px' }}>
                  {language.translate('application.user')}:
                </div>
                <a
                  onClick={(e) => {
                    this.setState({ contactCardOpen: true })
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                  style={{ cursor: 'pointer' }}>{field.Value.split('\\')[1]}
                </a>
              </div>
            </div>
            {(!field.ReadOnlyField)
              ? <div style={{ flex: 1 }}>
                <Tooltip title='Edit'>
                  <IconButton
                    onClick={() => this.setState({ edit: true })}
                  >
                    <Edit nativeColor={palette.accent3Color} />
                  </IconButton>
                </Tooltip>
              </div>
              : null}
          </div>
          : <div>
            <FormsySelect
              name={`${field.FieldName}-group`}
              value={this.state.groupValue}
              onChange={this.handleGroupChange.bind(this)}
              floatingLabelText={language.translate('application.group')}
              validationErrors={this.context.language.messages.validationErrors}
              required={field.RequiredField}
              style={{ width: '100%', maxWidth: '256px' }}
            >
              {this.getGroupOptions()}
            </FormsySelect>
            <br />
            {this.state.groupValue !== 0
              ? <FormsySelect
                name={`${field.FieldName}-users`}
                value={this.state.userValue}
                onChange={this.handleUserChange.bind(this)}
                floatingLabelText={language.translate('application.user')}
                validationErrors={this.context.language.messages.validationErrors}
                required={field.RequiredField}
                style={{ width: '100%', maxWidth: '256px' }}
              >
                {userOptions}
              </FormsySelect>
              : ''
            }
          </div>}
        {(this.state.contactCardOpen)
          ? <ContactCardDialog
            open
            userID={this.state.userValue}
            close={() => this.setState({ contactCardOpen: false })}
          />
          : null}
      </FieldWrapper>
    )
  }
}

PPlanUsersAll.propTypes = {
  field: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  push: PropTypes.func,
  publicFormToken: PropTypes.string,
  wrapperProps: PropTypes.object
}

PPlanUsersAll.contextTypes = {
  language: PropTypes.object,
  muiTheme: PropTypes.object,
  location: PropTypes.object
}

export default PPlanUsersAll
